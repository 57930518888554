import React, { Fragment } from 'react'
import { Container, Col, Row, Button, Card, CardBody, Input } from 'reactstrap';
import { ModalBacterium } from '../../../components/micro/bacterium/ModalBacterium';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useBacterium } from '../../../hooks/micro/useBacterium';

export const IndexBacter = () => {

    const {
        bacteriums, method, validaciones,
        bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch,
    } = useBacterium();


    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de bacterias
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        bacteriums.length === 0 ?
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "24%" }} alt="" />   <br />
                                                <br /> <p className="f-18">{"No se ha registrado ninguna bacteria aún."}</p>
                                            </div>
                                            :
                                            <TableStriped
                                                notMaped={["visibleTable", "antibiograms", "id_mic_morphology"]}
                                                methodsActions={true}
                                                cabeceras={["Bacteria", "Gram", "Morfologia","No. Antibiogramas"]}
                                                items={bacteriums}
                                                {...{ handleUpdate, handleDelete }}
                                                nextPage={nextPage}
                                                previousPage={previousPage}
                                                totalPageCount={totalPageCount}
                                                currentPage={currentPage}
                                                goToPage={goToPage}
                                            />


                                    }

                                </div>
                            </CardBody>
                        </Card>
                        <ModalBacterium
                            {...{
                                modal, modalTitle, toggle, method,
                                validaciones,
                                bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram,
                                handleInputChange, loading,
                                saveCreate, saveUpdate, saveDelete
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
