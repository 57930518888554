import React, { Fragment, useState } from 'react';
import classnames from "classnames";
import {Container,Card,CardBody,Col,NavItem,NavLink,Nav,TabContent,TabPane,Row} from "reactstrap";
import { IndexPrecodedTexts } from '../../precodedTexts/IndexPrecodedTexts';
import { IndexTypePrecodedTexts } from '../../precodedTexts/IndexTypePrecodedTexts';
import Breadcrumb from '../../../layout//breadcrumb'
import { Column } from 'react-base-table';

export const IndexPrecodedText = () => {

    const [activeTab, setActiveTab] = useState(1);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Administración de textos precodificados" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="nav-wrapper">
                                    <Nav
                                        className="nav-fill flex-column flex-md-row"
                                        id="tabs-icons-text"
                                        pills
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 1}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 1
                                                })}
                                                onClick={() => { toggle(1); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="icofont icofont-file-document"></i>
                                                Tipos de textos precodificados
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 2}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 2
                                                })}
                                                onClick={() => { toggle(2); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="icofont icofont-file-exe"></i>
                                                Textos precodificados
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <TabContent activeTab={"tabs" + activeTab}>
                                    <TabPane tabId="tabs1">
                                        <IndexTypePrecodedTexts />
                                    </TabPane>
                                    <TabPane tabId="tabs2">
                                        <IndexPrecodedTexts {...{ activeTab }} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}