import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { IndexBrands } from './IndexBrands';
import { IndexInfoAnalytes } from './IndexInfoAnalytes';
import { IndexAppCodes } from './IndexAppCodes';
import { IndexMethods } from './IndexMethods';
import { IndexCharacteristic } from './IndexCharacteristic';
// import { IndexMachine as ComponentMachine } from '../../machine/IndexMachine';


export const IndexAnalytes = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Diseño" title="Diseño de analitos" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        {/* <Card> */}
                        <Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
                            <NavItem>
                                <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className="fa fa-cog"></i> Marcas</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" role="tab" className={activeTab === "4" ? 'active' : ''} onClick={() => toggleTab("4")}><i className="fa fa-building-o"></i>Métodos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="fa fa-building-o"></i>Analitos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" role="tab" className={activeTab === "5" ? 'active' : ''} onClick={() => toggleTab("5")}><i className="fa fa-building-o"></i>Caracteristicas</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" role="tab" className={activeTab === "3" ? 'active' : ''} onClick={() => toggleTab("3")}><i className="fa fa-building-o"></i>Vinculación de analitos</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane className="fade show" tabId="1"> <br />
                                <IndexBrands
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="2"> <br />
                                <IndexInfoAnalytes
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="3"> <br />
                                <IndexAppCodes
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="4"> <br />
                                <IndexMethods
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="5"> <br />
                                <IndexCharacteristic
                                    {
                                    ...{
                                        ...[props, activeTab]
                                    }
                                    }
                                />
                            </TabPane>
                        </TabContent>
                        {/* </Card> */}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
