import React, { Fragment } from 'react'
import { Button, Card, CardBody, Col, Container, Row, Table,CardHeader,Label ,Input} from 'reactstrap';


export const IndexSatisfaction = () => {
  return (
    <Fragment>
        <Col sm="12">
            <Row>
                <Col sm="3">
                    <Card className="shadow ">
                        <CardHeader className="shadow bg-secondary p-3">
                            <Label>Acciones</Label>
                        </CardHeader>

                    </Card>
                </Col>
                <Col sm="9">
                    <Card className='shadow'>
                    <CardHeader className="shadow bg-secondary p-3">
                            <Label>Configuración</Label>
                        </CardHeader> <br />
                        <Col sm="8">
                            <label >Ingresa la pregunta</label> <br />
                            <Input className="form-control form-control-sm input-air-primary" type="text" />
                        </Col> <br /> <br />

                    </Card>
                </Col>
            </Row>
        </Col>



    </Fragment>
  )
}
