import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteDoctor } from './DeleteDoctor';
import { FormDoctor } from './FormDoctor';

export const ModalDoctor = (props) => {

    const {
        method, handleInputChange, validaciones, validaciones1,
        specialties, id_specialty, name, professional_license, phone, address, email,
        toggle, modal, loading,
        saveCreate,
        saveUpdate,
        saveDelete,
        nameInputs, addSpecialty, name_specialty, isCommercialPartner
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' size="lg" keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Registrar Médico"
                        : (method === "update") ?
                            "Actualizar Médico"
                            : "Eliminar Médico"
                }
            </ModalHeader>
            {
                method === "create" ? 
                    <FormDoctor
                        button="Crear nuevo médico"
                        {...{ addSpecialty, name_specialty, toggle, loading, nameInputs, specialties, id_specialty, name, professional_license, phone, address, email, handleInputChange, validaciones, validaciones1, methodAction: saveCreate, isCommercialPartner }}
                    />
                    : (method === "update") ?
                        <FormDoctor
                            button="Actualizar médico"
                            {...{ toggle, nameInputs, loading, specialties, id_specialty, name, professional_license, phone, address, email, handleInputChange, validaciones, methodAction: saveUpdate, isCommercialPartner }}
                        />
                        : <DeleteDoctor
                            {...{ toggle, loading, name, saveDelete }}
                        />
            }
        </Modal>
    )
}
