import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ResetPassword } from '../../constant';
import { useForm } from '../../hooks/forms/useForm';
import { useHistory } from "react-router-dom";
import '../auth/RecoverPassword.scss';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';

const RecoverPassword = (props) =>{
    const [togglePassword, setTogglePassword] = useState(false)
    const [loading, setLoading] = useState(false);
    const [createSweet, sweetConfirmation] = useSweetAlert();


    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }


    const [formValues, handleInputChange] = useForm({
        password:"",
        repeatedPassword:""
    });

    const [showText, setShowText] = useState(false);

    const{ password, repeatedPassword } = formValues;
    let history = useHistory();

    const resetPassword = async (e) =>{
        e.preventDefault();
        setShowText(false);
        setLoading(true);
        if( password != repeatedPassword || password.length==0 || repeatedPassword.length==0){
            setShowText(true);
            setLoading(false);
        } else{
            createSweet("update", "success", "Contraseña actualizada", "");
            setTimeout(() => {
                setLoading(false);
                history.push("/Login");
            }, 2000);
        }
    }

    return(
        <div className="recover-password-container">
            <div className="headerRecover"><h4><img src={require("../../assets/images/login/logo2.png")} alt="helenLogo" style={{width:"40px", minWidth:"4%"}}/>HELEN</h4></div>
            <div className="recoverContainer">
                <Form className="theme-form" onSubmit={resetPassword}>
                    <FormGroup>
                        <Label className="col-form-label">{`Nueva contraseña`}</Label>
                        <div className="input-show-hide">
                            <div className="show-hide" onClick={() => HideShowPassword(togglePassword)} ><span className={togglePassword ? "" : "show"}></span></div>
                            <Input className="form-control" style={showText? {border:"2px solid rgba(226, 35, 26, 0.8)", }: {borderColor:"none"}} type={togglePassword ? "text" : "password"} value={password} name="password" onChange={handleInputChange} required="" />
                        </div>  
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">{`Confirmar nueva contraseña`}</Label>
                        <Input className="form-control" style={showText? {border:"2px solid rgba(226, 35, 26, 0.8)", }: {borderColor:"none"}} type="password" value={repeatedPassword} name="repeatedPassword" onChange={handleInputChange} required="" />
                    </FormGroup>                           
                    <p className={`incorrect-login ${!showText && "d-none"}`}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Las contraseñas no coinciden</p>
                    <FormGroup className="mb-0">
                        <Button type="submit" color="" className={(loading && " disabled progress-bar-animated progress-bar-striped")} onClick={resetPassword}>{`Actualizar`}</Button>
                    </FormGroup>
                </Form>
            </div>
            
        </div>
        

    
    );
}

export default RecoverPassword;