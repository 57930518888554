import React from 'react'
import {Button, Modal, ModalBody, Row,Col, Form, Input, CardBody, Media } from 'reactstrap';



export const ModalLiquidacion = (props) => {

    const {loadingPayment, modal, toggle, handleInputChangeLiquided, saveLiquided, listPayMethod, loading,validatesaveLiquided, id_method_liquided, amount_liquided, id_method_pay, amount_liq} = props;


    const {
                id_method_pay_valid,
                amount_liquided_valid,
        
            } = validatesaveLiquided;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                   
                        <>
                        <Media body>
                            <Row>
                                <Col sm="12">
                                <label className='f-w-600 txt-secondary f-16'>Liquidar Saldo</label>
                                </Col>
                            </Row>
                                    <Row className='pt-2'>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="fa fa-money"> </i><span className="px-2">{"Método de Pago"}
                                        <Input
                                        type='select'
                                        name="id_method_pay"
                                        required={true}
                                        invalid={id_method_pay_valid}
                                        onChange={handleInputChangeLiquided}
                                        className="form-control form-control-sm input-air-primary p-1"
                                        defaultValue={id_method_pay}
                                        style={{fontSize :"12px"}}
                                    >
                                        <option style={{fontSize :"12px"}} value={0}>Selecciona una opción</option>

                                        {
                                            listPayMethod.length > 0 && listPayMethod.map(pm => {
                                                if (pm.name !== "CREDITO") {
                                                    return (
                                                        <option style={{fontSize :"12px"}} key={'method-' + pm.id_pay_method} value={pm.id_pay_method}>{`${pm.abbreviation} - ${pm.name}`}</option>
                                                    )
                                                }


                                            })
                                        }
                                    </Input>
                                      
                                    </span></span></span>
                                        </Col>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="fa fa-money"> </i><span className="px-2">{"Monto"}
                                        <Input
                                         type="number" name='amount_liquided'
                                         value={amount_liquided}
                                         required={true}
                                         invalid={amount_liquided_valid}
                                         placeholder="$ 0.0" step="any" className='form-control input-air-primary form-control-sm'
                                         onChange={handleInputChangeLiquided}
                                        />
                                       
                                        </span></span></span>
                                        </Col>
                                    </Row>
                                   
                                 
                                </Media>
                            
                        <Col md="12" className='pt-4' >
                            <h6 className={amount_liq < amount_liquided ? "text-center" : "d-none"}><b>Cambio ${amount_liq < amount_liquided ? (amount_liquided - amount_liq) : ""}</b></h6>
                             <h6 className={amount_liq > amount_liquided ? "text-center" : "d-none"}><b>Faltan ${amount_liq > amount_liquided ? (amount_liq - amount_liquided) : ""}</b></h6>
                        </Col>

                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' >  <br />
                        <Row>
                            <Col sm="5">
                            </Col>
                            <Col sm="3">
                            <Button disabled={loadingPayment ? true : false} color="danger text-center" type="button" onClick={toggle}>{"Cancelar"}</Button>
                            </Col>
                            <Col sm="4">
                            <Button disabled={loadingPayment ? true : false}  color="success text-center" type="button" onClick={saveLiquided} >{"Autorizar"}</Button> 
                            </Col>
                           
                        </Row>
                           
                        </Col>
                        </>
                       
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}











