import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { usePagination } from '../pagination/usePagination';


export const useSamplingItems = () => {

    const history = useHistory();

 


    //#region Insumos Sample

    //state isumos
    const [samplingItems, setSamplingItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    //method
    const getSamplingItems = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        let ListIndication = [];


        const respuesta = await sendRequest(requestOptions, "Sampling/ListSamplingItems");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1 ) {

                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                respuesta.data.forEach((element,index) => {
                    
                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    ListIndication.push({
                        id_sampling_item: element.id_sampling_item,
                        name: element.name,
                        code: element.code,
                        price: element.price,
                        id_sampling_items_inventorys: element.id_sampling_items_inventorys,
                        visibleTable: visibleTable
                    });
                });
                setSamplingItems(ListIndication);
                setSamplingData(ListIndication);
            }
        } else {
            validarSesion(history, respuesta.code, getSamplingItems);
        }

    }, [history]);

    useEffect(() => {
        getSamplingItems();
    }, [getSamplingItems]);



    //#endregion Insumos Sample

    //#region Paginations 

    const [samplingData, setSamplingData] = useState([]);


    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(samplingItems.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listSamplingItems = [];
        samplingItems.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSamplingItems.push({
                ...data,
                visibleTable: visibleTable
            });
        });
        setSamplingItems(listSamplingItems);
      

    }, [totalPageCount, currentPage])



    const handleSearchSampling = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        samplingData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setSamplingItems(newMethod);
    }



    //#endregion  Pagination 




    return {
        samplingItems,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchSampling

    }
}
