import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Input, CardHeader, Label, FormGroup } from 'reactstrap';
import './styleDashboard.css';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useDashboardExam } from '../../../hooks/dashboard/useDashboardExam';


export const IndexDashboardExam = () => {

    const {

        handleSearch, metaData, goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,

    } = useDashboardExam();


    return (
        <Fragment>
            <Container fluid={true} >
                <Col sm="12">
                    <div className="table-responsive"> <br />
                        <Col sm="12">
                            <Row>
                                <Col sm="4">
                                    <Label className="col-12">Ingresa el nombre del Exámen o Perfil</Label>
                                    <FormGroup className="col-md-12">
                                        <Input className='form-control form-control-sm input-air-primary b-r-9' placeholder='Busqueda...' onChange={handleSearch} />
                                    </FormGroup>
                                </Col>


                            </Row>
                        </Col>

                        <TableStriped
                            responsive={true}
                            notMaped={["visibleTable", "id_rate", "id_profile", "id_exam", "curvePrices", "from", "to"]}
                            methodsActions={false}
                            cabeceras={["Exámen / Perfil", "Precio"]}
                            items={metaData}
                            nextPage={goNextPage}
                            previousPage={goPreviousPage}
                            totalPageCount={totalPages}
                            currentPage={actualPage}
                            goToPage={goToPage}
                            paginingBack={true}
                        />
                    </div>
                </Col>
            </Container>
        </Fragment>
    )
}
