import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { ModalButtonDelete, ModalButtonCancel } from '../../constant';
 

export const DeleteMicTypeSample = (props) => {

    const{
        toggle, loading, name, saveDelete
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el siguiente tipo de muestra:</p>                
                <b>{ name}</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            
            <ModalFooter>
                <Button size="sm" color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {ModalButtonDelete}
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}