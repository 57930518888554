import React from 'react';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';
import { ExportInvoiceDefaultValues } from '../../data/const';

export const TabAdditionalInformation = (props) => {

    const {
        type_document, listPayForms, listPayMethods, listPeriodicities, listCfdiMonths, listTaxes, listTypeRelationship,
        
        filterTypeClient, 
        
        handleChangePeriodicity, 

        listCoins, handleInputChange, handleSelectValues,
        listUseCfdi,
        expedition_place, id_pay_form, id_method_pay, id_coin, id_use_cfdi, id_periodicity, id_month, tax_iva, payment_conditions,
        id_type_releationship, handleChangeTypeMethods, validationsInvoice,id_work_order_r
    } = props;

    const {
        _id_use_cfdi_valid,
        _expedition_place_valid,
        _id_method_pay_valid,
        _id_pay_form_valid,
        _id_coin_valid,
        _tax_iva_value,
        _id_month_valid, 
        _id_periodicity_valid
    } = validationsInvoice;

    return (
        <Row>
            <Col sm="12" md="12" lg="12">
                <Card>
                    <CardHeader className='p-3'>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Uso del CFDI <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix="select"
                                            name="id_use_cfdi"
                                            value={id_use_cfdi}
                                            options={listUseCfdi}
                                            onChange={(e) => handleChangeTypeMethods(e, "id_use_cfdi")}
                                            placeholder={PlaceHolderOption}
                                        />
                                        <FormText color='danger' hidden={!_id_use_cfdi_valid}>Selecciona una uso de cfdi</FormText>
                                    </FormGroup>
                                </Col>
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Lugar de expedición <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='number' className='form-control form-control-sm input-air-primary' name="expedition_place" value={expedition_place} onChange={handleInputChange} invalid={_expedition_place_valid}/>
                                        <FormFeedback>Ingresa un código postal valido</FormFeedback>
                                    </FormGroup>
                                </Col>                                
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Método de pago <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix="select"
                                            name="id_method_pay"
                                            value={id_method_pay}
                                            options={listPayMethods}
                                            onChange={(e) => handleChangeTypeMethods(e, "id_method_pay")}
                                            placeholder={PlaceHolderOption}
                                            isDisabled={(type_document === "P" || filterTypeClient === "3") ? true : false}
                                        />
                                        <FormText color='danger' hidden={!_id_method_pay_valid}>Selecciona un método de pago</FormText>
                                    </FormGroup>
                                </Col>
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Forma de pago <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix="select"
                                            name="id_pay_form"
                                            value={id_pay_form}
                                            options={listPayForms}
                                            onChange={(e) => handleSelectValues(e, "id_pay_form")}
                                            placeholder={PlaceHolderOption}
                                        />      
                                        <FormText color='danger' hidden={!_id_pay_form_valid}>Selecciona una forma de pago</FormText>                                                      
                                    </FormGroup>
                                </Col>                                
                            </Row>
                            <Row>
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Moneda <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix="select"
                                            name="id_coin"
                                            value={id_coin}
                                            onChange={(e) => handleSelectValues(e, "id_coin")}
                                            options={listCoins}
                                            placeholder={PlaceHolderOption}
                                        />
                                        <FormText color='danger' hidden={!_id_coin_valid}>Selecciona una moneda</FormText>
                                    </FormGroup>
                                </Col>                                                    
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Aplicar IVA <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix="select"
                                            name="tax_iva"
                                            value={tax_iva}
                                            onChange={(e) => handleSelectValues(e, "tax_iva")}
                                            options={listTaxes}
                                            placeholder={PlaceHolderOption}
                                            isDisabled={ (id_work_order_r === undefined ? (filterTypeClient === "1" || filterTypeClient === "2" || filterTypeClient === "3" ? true : false)   : false   )     }
                                        />
                                        <FormText color='danger' hidden={!_tax_iva_value}>Selecciona una opción</FormText>
                                    </FormGroup>
                                </Col>
                                {
                                    filterTypeClient === "3" 
                                    ?
                                        <>
                                            <Col sm="3" md="3" lg="3">
                                                <FormGroup>
                                                    <Label className='col-form-label'>Periodicidad <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <Select
                                                        className="input-air-primary input-xs"
                                                        classNamePrefix="select"
                                                        name="id_periodicity"
                                                        value={id_periodicity}
                                                        onChange={(e) => handleChangePeriodicity(e, "id_periodicity")}
                                                        options={listPeriodicities}
                                                        placeholder={PlaceHolderOption}
                                                    />
                                                    <FormText color='danger' hidden={!_id_periodicity_valid}>Selecciona una opción</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3" md="3" lg="3">
                                                <FormGroup>
                                                    <Label className='col-form-label'>Mes de facturación <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <Select
                                                        className="input-air-primary input-xs"
                                                        classNamePrefix="select"
                                                        name="id_month"
                                                        value={id_month}
                                                        onChange={(e) => handleSelectValues(e, "id_month")}
                                                        options={listCfdiMonths}
                                                        placeholder={PlaceHolderOption}
                                                    />
                                                    <FormText color='danger' hidden={!_id_month_valid}>Selecciona una opción</FormText>
                                                </FormGroup>
                                            </Col>
                                        </>
                                    :
                                        <Col sm="6" md="6" lg="6">
                                            <FormGroup>
                                                <Label className='col-form-label'>Condiciones de pago <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                                <Input type='text' className='form-control form-control-sm input-air-primary' name='payment_conditions' value={payment_conditions} onChange={handleInputChange} disabled={(type_document === "I" || type_document === "E" ) ? false : true} />
                                            </FormGroup>
                                        </Col>
                                }
                                
                            </Row>
                            {
                                type_document === "E" 
                                ?
                                    <Row>
                                        <Col sm="4" md="4" lg="4">
                                            <FormGroup>
                                                <Label className='col-form-label'>Tipo de relación <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                <Select
                                                    className="input-air-primary input-xs"
                                                    classNamePrefix="select"
                                                    name="id_type_releationship"
                                                    value={id_type_releationship}
                                                    onChange={(e) => handleSelectValues(e, "id_type_releationship")}
                                                    options={listTypeRelationship}
                                                    placeholder={PlaceHolderOption}                                                    
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                : ""
                            }
                        </CardBody>
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    )
}
