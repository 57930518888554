import React, { useEffect, useState } from 'react';
//import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
//import { validarSesion } from '../tokens/useToken';
import { useForm } from '../forms/useForm';
import moment from 'moment';
import { useDebounce } from '../debounce/useDebounce';
//import { validarSesion } from '../../hooks/tokens/useToken';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from 'react-router-dom';





export const useInputAnalyte = ({ analyte, age, days_age, id_gender = null, exam_method, exam_method_main, app_code_or_method_exam, saveIndividualAnalyte, validateAnalyte, dataExam, analyteData, dataCurve = null, changeLocalMethod, previewWorkOrder }) => {

    //const history = useHistory();
    const history = useHistory();

    const id_analyte = analyte.id_analyte;
    const id_type_analyte = analyte.id_type_analyte;
    const id_exam_method = exam_method;
    const id_app_code_or_method_exam = app_code_or_method_exam;

    // ////console.log(analyteData);

    var cont = 0;

    const [createSweet] = useSweetAlert();

    useEffect(() => {
        if (id_analyte !== undefined && id_gender !== undefined && age !== undefined && id_app_code_or_method_exam !== undefined) {
            if (id_type_analyte === 1) {
                getPrecodedTexts(id_analyte);
                getAnalytesAgeGenderRangesFromAnalyte(id_analyte, id_gender, id_app_code_or_method_exam);
            } else if (id_type_analyte === 6 || id_type_analyte === 14) {
                if (id_type_analyte === 6) {
                    getAnalytesAgeGenderRangesFromAnalyte(id_analyte, id_gender, id_app_code_or_method_exam)
                }
                setCaptureData();
            }
            else {
                getAnalytesAgeGenderRangesFromAnalyte(id_analyte, id_gender, id_app_code_or_method_exam);
            }
        }
    }, [id_analyte, id_gender, age, id_app_code_or_method_exam])


    const reload = () => {
        window.location.reload(true);
    }



    // ////console.log("El principal", id_app_code_or_method_exam);


    //getPrecodedTexts


    const [rangos, setRangos] = useState([]);
    const [rango, setRango] = useState({});
    const [alerts, setAlerts] = useState({});
    const [textAlerts, setTextAlerts] = useState([]);
    const [typeAlert, setTypeAlert] = useState("danger");
    const [inputState, setInputState] = useState("");
    const [options, setOptions] = useState([]);
    const [viewButton, setViewButton] = useState(false);
    const [users, setUsers] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [tooltipRelease, setTooltipRelease] = useState(false);
    const [maskFormat, setMaskFormat] = useState([]);
    const [userValidate, setUserValidate] = useState(null);
    const [idAnalyteAgeGenderRange, setIdAnalyteAgeGenderRange] = useState(0);
    const [objeto, setObjeto] = useState(null);
    const [isInterface, setIsInterface] = useState(false);
    // const [createSweet] = useSweetAlert();
    const [viewComments, setViewComments] = useState(false);
    const [objectComments, setObjectComments] = useState("");
    const [print_chart, setPrint_chart] = useState(false);
    // const [createSweet] = useSweetAlert();

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    const toggleComments = () => {
        setViewComments(!viewComments);
    }

    const toggleTooltip = () => setTooltip(!tooltip);
    const toggleTooltipRelease = () => setTooltipRelease(!tooltipRelease);

    useEffect(() => {
        if (analyte.capture !== null && analyteData !== undefined) {
            if (analyteData.id_type_analyte === 7) {
                analyteData.capture = analyte.capture.number;
            }
        }
    }, [analyteData, analyte])



    useEffect(() => {
        // ////console.log(analyte)
        // if (analyte.id_analyte == 71) {

        //     ////console.log("Veamos que es esto");
        //     ////console.log(analyte);
        //     // ////console.log(analyte.usersCapture);
        //     // ////console.log(analyte.capture);
        //     ////console.log("Veamos que es esto");
        // }

        if (analyte.usersCapture !== null) {
            // debugger;
            setUsers(analyte.usersCapture);
            setMaskFormat(false);
            if (analyte.id_analyte_references > 0) {
                try{
                    let e = { target: { value: analyte.capture.id_exam_method } }
                    changeLocalMethod(e);
                    let id_exam_method_aux = analyte.capture.id_exam_method;
                    setTimeout(() => {
                        setCaptureData(id_exam_method_aux);
                    }, 2000);
                }
                catch(error){
                    
                }
            }
        } else {
            // ////console.log("pasa????");
            if (analyte.id_type_analyte !== 6 && analyte.id_type_analyte !== 6 && analyte.id_type_analyte !== 1 && analyte.id_type_analyte !== 14) {
                validarMascara();
            }
        }

        if (analyte.userValidate !== null) {
            setUserValidate(analyte.userValidate);
        }

    }, [])

    useEffect(() => {
        if (analyte.usersCapture !== null) {
            if (analyte.capture !== null) {
                if (analyte.capture.is_interface) {
                    setIsInterface(true);
                    let nuevo = analyte.usersCapture.map(x => {
                        x.is_interface = true;
                        return x;
                    })
                    setUsers(nuevo);
                } else {
                    setUsers(analyte.usersCapture);
                }
            }
        } else {
            setUsers([]);
        }
    }, [analyte.usersCapture])

    useEffect(() => {
        if (analyte.userValidate !== null) {
            setUserValidate(analyte.userValidate);
        } else {
            setUserValidate(null);
        }
    }, [analyte.userValidate])

    useEffect(() => {
        if (alerts !== undefined && alerts.id_analyte_age_gender_range !== undefined && alerts.id_analyte_age_gender_range > 0 && analyte.isCaptured) {
            setCaptureData();
        }
        else if (analyte.capture !== null) {
            setCaptureData();
        }
        else {
            // ////console.log(inputState);
            if (analyte.id_type_analyte === 7) {
                if (analyteData.id !== "") {
                    validarRango(analyte.id_type_analyte, parseFloat(analyteData.capture));
                }
                // validarRango(analyte.id_type_analyte, inputState);
            } else {
                validarRango(analyte.id_type_analyte, inputState);
            }
        }
    }, [alerts])

    const validarMascara = (valor = 0) => {
        // ////console.log("pasa por aca?", analyte);
        if (valor === "") {
            return true
        }
        else {
            let expresion = null;
            let respuesta = true;
            if (analyte.integers > 0 && analyte.decimals > 0) {
                // expresion = /^([-])?([0-9]){1,3}([.]([0-9]){1,2})?$/;
                expresion = new RegExp("^([-])?([0-9]){1," + analyte.integers + "}([.]([0-9]){1," + analyte.decimals + "})?$");
            }
            else if (analyte.integers > 0) {
                expresion = new RegExp("^([-])?([0-9]){1," + analyte.integers + "}?$");
            } else if (analyte.decimals > 0) {
                expresion = new RegExp("^([-])?([0-9]){1," + analyte.integers + "}([.]([0-9]){1," + analyte.decimals + "})?$");

            }
            // ////console.log(expresion);
            if (expresion.test(valor)) {
                setMaskFormat(true)
                respuesta = true;
            } else {
                setMaskFormat(false)
                respuesta = false;
            }
            return respuesta;
        }
        // ////console.log(expresion);
        // ////console.log(expresion.test(valor));
        // let re = /^([-])?([0-9]){1,3}([.]([0-9]){1,2})?$/;
        // let re2 = /^([-])?([0-9]){1,3}(([.]{1,1})([0-9]){1,2})?$/;
        // ////console.log(re.test(1111.1111));
        // ////console.log(re.test(11.11));
        // ////console.log(re.test(-11.11));
    }

    const getAnalytesAgeGenderRangesFromAnalyte = async (id_analyte, id_gender, id_exam_method) => {

        id_exam_method = parseInt(id_exam_method);
        let requestOptions = {
            method: 'GET'
        };
        // ////console.log(id_analyte, id_gender, id_exam_method, "Data analito ");
        let respuesta;
        if (analyte.userValidate === null) {
            respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteAgeGenderRangesFromAnalyte/" + id_analyte);
        }
        else {
            respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteAgeGenderRangesFromAnalyteHistory/" + analyte.userValidate.id_work_order_exam_analyte);
        }
        // const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteAgeGenderRangesFromAnalyte/" + id_analyte);
        if (id_analyte === 36) {
            ////console.log(age, "Edad analito 36");
        }
        let age_days = age > 0 ? age * 365 : days_age;
        // ////console.log(days_age);
        // ////console.log("respuesta.data", analyte);
        // ////console.log("respuesta.idexammethod", id_exam_method);
        // ////console.log(id_analyte, respuesta.data, "respuesta");
        // ////console.log("DAME EL OBJETO", objeto);
        if (respuesta.code === 200) {
            let rangos = respuesta.data;
            if (rangos.length > 0) {
                let rango_seleccionado = null;
                // ////console.log("rangos", id_analyte);
                // ////console.log(rangos);

                rangos.forEach(element => {
                    // ////console.log("-----element.id_exam_method, id_exam_method, analyte---------");
                    // ////console.log(element.id_exam_method, id_exam_method, analyte);
                    // ////console.log(element, id_exam_method);
                    // if (element.id_method === id_exam_method) {

                    if (element.id_exam_method === (analyte.id_analyte_references !== null ? analyte.id_exam_method_references : id_exam_method)) {
                        if (age_days >= element.days_from && age_days < element.days_to) {
                            if (element.id_gender !== null) {
                                if (element.id_gender == id_gender) {
                                    rango_seleccionado = element;
                                }
                            } else {
                                rango_seleccionado = element;
                            }
                        } else if (age_days > element.days_from && age_days <= element.days_to) {
                            if (element.id_gender !== null) {
                                if (element.id_gender == id_gender) {
                                    rango_seleccionado = element;
                                }
                            } else {
                                rango_seleccionado = element;
                            }
                        }
                    }
                });
                // ////console.log(rango_seleccionado, id_analyte);

                setRango(rango_seleccionado);

                if (rango_seleccionado !== null) {
                    setIdAnalyteAgeGenderRange(rango_seleccionado.id_analyte_age_gender_range);
                    await getAnalyteNumberAlerts(id_analyte, rango_seleccionado.id_analyte_age_gender_range);

                    await getAnalyteTextNumbers(rango_seleccionado.id_analyte_age_gender_range);
                }
            }
            setRangos(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAnalytesAgeGenderRangesFromAnalyte);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Analito", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Analito", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getAnalytesAgeGenderRangesFromAnalyte);
        // }
    }

    const getPrecodedTexts = async (id_analyte) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetAvailablesPrecodedText/" + id_analyte);

        let opciones = [];
        if (respuesta.data.length > 0) {
            respuesta.data.forEach(element => {
                opciones = [
                    ...opciones,
                    {
                        "id": element.id_precoded_text,
                        "value": element.text
                    }
                ]
            });
        }
        setInputState("-1");
        setOptions(opciones);
        setCaptureData();

    }

    const getAnalyteNumberAlerts = async (id_analyte, id_analyte_age_gender_range) => {
        let requestOptions = {
            method: 'GET'
        };
        ////console.log(analyte, "ANALITO");
        let valores;
        // debugger;
        if (analyte.userValidate === null) {
            const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteNumberAlerts/" + id_analyte);
            valores = respuesta.data;
        } else {
            // debugger;
            const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteNumberAlertsHistory/" + analyte.userValidate.id_work_order_exam_analyte);
            valores = respuesta.data;
        }
        // ////console.log("valores", valores);
        if (valores.length > 0) {
            let valor = valores.find(x => x.id_analyte_age_gender_range === id_analyte_age_gender_range);
            // ////console.log("----------");
            // ////console.log("pasa?", id_analyte, id_analyte_age_gender_range);
            // ////console.log(valor);
            setAlerts(valor);
            return valor;
        }
        return {};
    }

    const getAnalyteTextNumbers = async (id_analyte_age_gender_range) => {
        let requestOptions = {
            method: 'GET'
        };

        let respuesta;
        if (analyte.userValidate === null) {
            respuesta = await sendRequest(requestOptions, "Exam/GetAnalytesTextAlertsByAgeGender/" + id_analyte_age_gender_range);
        } else {
            respuesta = await sendRequest(requestOptions, "Exam/GetAnalytesTextAlertsByAgeGenderHistory/" + analyte.userValidate.id_work_order_exam_analyte);
        }

        if (respuesta.code === 200) {
            // ////console.log(respuesta.data, "assd");
            let values = respuesta.data;

            if (values.length > 0) {
                setTextAlerts(values);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAnalyteTextNumbers);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Analito", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Analito", respuesta.data.msg);
        }
    }

    const debounceValue = useDebounce(inputState, 1000);

    useEffect(() => {
        if (objeto !== null) {
            // ////console.log(objeto, "El objeto que se envia");
            // validarRango(analyte.id_type_analyte, objeto.valor, "Se envio");
            saveIndividualAnalyte(objeto);
        }
    }, [debounceValue])

    const changeInputSelect = (e) => {
        let valor = e.target.value;
        setInputState(valor);
        let objeto = {};
        if (dataCurve === null) {
            objeto = {
                "id_analyte": id_analyte,
                "id_work_order_exam": analyte.id_work_order_exam,
                "valor": valor,
                "id_exam_method_main": exam_method_main,
                "id_app_code_or_method_exam": id_app_code_or_method_exam,
                "id_exam_method": id_exam_method,
                "is_file": false
            }
        } else {
            objeto = {
                "id_analyte": id_analyte,
                "id_work_order_exam": analyte.id_work_order_exam,
                "sub_id": dataCurve.time.value,
                "valor": valor,
                "id_exam_method_main": exam_method_main,
                "id_app_code_or_method_exam": id_app_code_or_method_exam,
                "id_exam_method": id_exam_method,
                "is_file": false
            }

        }
        // ////console.log(objeto, "Este es el onnbjeto que sale en eotro archvio");
        setObjeto(objeto);
        return;
        saveIndividualAnalyte(objeto);
    }

    const changeInputFile = async (e) => {
        let valor = e.target.files[0];

        if (valor.type !== "application/pdf") {
            createSweet("error", "error", "Analito", "Selecciona unicamente archivos PDF");
        }
        else {

            let fileBase64 = await encodeFileBase64(valor);

            setInputState(fileBase64);

            let objeto = {};

            if (dataCurve === null) {
                objeto = {
                    "id_analyte": id_analyte,
                    "id_work_order_exam": analyte.id_work_order_exam,
                    "valor": fileBase64,
                    "id_exam_method_main": exam_method_main,
                    "id_app_code_or_method_exam": id_app_code_or_method_exam,
                    "id_exam_method": id_exam_method,
                    "is_file": true
                };
            }
            else {
                objeto = {
                    "id_analyte": id_analyte,
                    "id_work_order_exam": analyte.id_work_order_exam,
                    "sub_id": dataCurve.time.value,
                    "valor": fileBase64,
                    "id_exam_method_main": exam_method_main,
                    "id_app_code_or_method_exam": id_app_code_or_method_exam,
                    "id_exam_method": id_exam_method,
                    "is_file": true
                };
            }

            setObjeto(objeto);
            return;
        }
    }

    const changeInput = (e, validate = true, examMethod = null, appCodeOrMethodExam = null) => {

        let exam_method_id = examMethod === null ? id_exam_method : examMethod;
        let app_code_or_method_exam_id = appCodeOrMethodExam === null ? id_app_code_or_method_exam : appCodeOrMethodExam;
        let exam_method_main_id = examMethod === null ? id_exam_method : examMethod;
        let valor = "";

        valor = e.target.value;

        let validacion_mascara = true;
        if (validate) {
            validacion_mascara = validarMascara(valor);
        }
        if (validacion_mascara) {
            // console.group("DATOS")
            // ////console.log(rango);
            // ////console.log(alerts);
            // ////console.log(textAlerts);
            // console.groupEnd();
            setInputState(valor);

            validarRango(analyte.id_type_analyte, valor);
            let objeto = {};
            if (dataCurve === null) {
                objeto = {
                    "id_analyte": id_analyte,
                    "id_work_order_exam": analyte.id_work_order_exam,
                    "valor": valor,
                    "id_exam_method_main": exam_method_main_id,
                    "id_app_code_or_method_exam": app_code_or_method_exam_id,
                    "id_exam_method": exam_method_id,
                    "validate_format": validacion_mascara,
                    "is_file": false,
                    "id_analyte_age_gender_range": (rango !== undefined && rango !== null) ? rango.id_analyte_age_gender_range : 0,
                    "id_analyte_number_alert": (alerts !== undefined && alerts !== null) ? alerts.id_analyte_number_alert : 0,
                    "ids_analyte_text_alerts": (textAlerts !== undefined && textAlerts !== null && textAlerts.length > 0) ? JSON.stringify(textAlerts.map(x => x.id_analyte_text_alert)) : ""
                }
            } else {
                objeto = {
                    "id_analyte": id_analyte,
                    "id_work_order_exam": analyte.id_work_order_exam,
                    "sub_id": dataCurve.time.value,
                    "valor": valor,
                    "id_exam_method_main": exam_method_main_id,
                    "id_app_code_or_method_exam": app_code_or_method_exam_id,
                    "id_exam_method": exam_method_id,
                    "validate_format": validacion_mascara,
                    "is_file": false,
                    "id_analyte_age_gender_range": (rango !== undefined && rango !== null) ? rango.id_analyte_age_gender_range : 0,
                    "id_analyte_number_alert": (alerts !== undefined && alerts !== null) ? alerts.id_analyte_number_alert : 0,
                    "ids_analyte_text_alerts": (textAlerts !== undefined && textAlerts !== null && textAlerts.length > 0) ? JSON.stringify(textAlerts.map(x => x.id_analyte_text_alert)) : ""
                }
            }
            setObjeto(objeto);
            return;
        }
        return;
        saveIndividualAnalyte(objeto);
    }

    const validarRango = async (id_type_analyte, valor) => {
        let alertas = alerts;
        if (alerts !== undefined && alerts.normal_max === undefined) {
            //TODO: SI ALGO LLEGARA A FALLAR, QUITAR EL RETURN, LO AGREGUE POR QUE SE LANZABAN MUCHAS PETICIONES, DE IGUAL FORMA HAY QUE REVISARLO
            return;
            getAnalytesAgeGenderRangesFromAnalyte(id_analyte, id_gender, id_app_code_or_method_exam).then(x => {
                alertas = alerts;
            })
        }
        // if (id_type_analyte === 7) {
        //     ////console.log(valor);
        //     ////console.log(alertas);
        //     ////console.log("--------");
        // }
        if (alerts !== undefined && alertas.normal_max !== undefined) {
            if (valor === "") {
                setTypeAlert("danger");
            } else {
                if (id_type_analyte !== 1 && id_type_analyte !== 6 && id_type_analyte !== 14) {
                    if (valor >= alertas.normal_min && valor < alertas.normal_max) {
                        setTypeAlert("success");
                    } else if (valor > alertas.normal_min && valor <= alertas.normal_max) {
                        setTypeAlert("success");
                    } else if (valor == alertas.normal_min == alertas.normal_max) {
                        setTypeAlert("success");
                    } else if (valor >= alertas.low && valor < alertas.normal_min) {
                        setTypeAlert("warning");
                    } else if (valor > alertas.normal_max && valor <= alertas.high) {
                        setTypeAlert("warning");
                    } else if (valor >= alertas.low_alert && valor < alertas.low) {
                        setTypeAlert("danger");
                    } else if (valor > alertas.high && valor <= alertas.high_alert) {
                        setTypeAlert("danger");
                    } else {
                        setTypeAlert("alert");
                    }
                }
            }
        }
    }
    useEffect(() => {
        if (analyteData !== null && analyteData !== undefined && analyteData.capture) {
            if (analyteData.id_type_analyte === 7) {
                if (analyteData.capture !== "") {
                    let resultado = parseFloat(analyteData.capture);
                    if (!isNaN(resultado)) {
                        if (isFinite(resultado)) {
                            // ////console.log(analyteData.id_type_analyte, resultado);
                            // ////console.log("--------");
                            validarRango(analyteData.id_type_analyte, resultado);
                        }
                    }
                }
            }
        }
    }, [analyteData.capture])
    // ////console.log(analyteData.capture);



    const changeComplex = (valor) => {
        // validarRango(analyte.id_type_analyte, valor);
        setInputState(valor);
    }

    const toggleButton = (e) => {
        if (id_type_analyte === 7) {
            setViewButton(true);
        } else {
            if (e.type === "focus") {
                setViewButton(true);
            } else {
                setTimeout(() => {

                    setViewButton(false);
                }, 200);
            }
        }
    }

    const guardarDatosIndividuales = () => {
        // ////console.log("Cuanto pasa?");
        let objeto = {
            "id_analyte": id_analyte,
            "id_work_order_exam": analyte.id_work_order_exam,
            "valor": inputState,
            "id_exam_method_main": exam_method_main,
            "id_app_code_or_method_exam": id_app_code_or_method_exam,
            "id_exam_method": id_exam_method
        }
        saveIndividualAnalyte(objeto);
    }

    const validarAnalito = async () => {
        let sub_id = null;
        if (dataCurve !== null) {
            sub_id = dataCurve.time.value;
        }
        // ////console.log(analyteData);
        // alert("entro");
        await validateAnalyte(dataExam.id_work_order_exam, dataExam.id_exam, id_analyte, sub_id, analyteData).then(result => guardarDatosIndividuales());
    }

    const setCaptureData = (id_exam_method_aux = 0) => {
        // alert("erntyro")
        if (analyte.isCaptured) {
            // ////console.log("entra?--------------");
            if (analyte.id_type_analyte === 1) {
                let value = parseInt(analyte.capture.id_precoded_text);
                setInputState(value);
                let e = {
                    target: {
                        value: value
                    }
                }
                changeInput(e, false, id_exam_method_aux)
            } else if (analyte.id_type_analyte === 6) {
                let value = analyte.capture.text;
                setInputState(value);
                let e = {
                    target: {
                        value: value
                    }
                }
                changeInput(e, false, id_exam_method_aux)
            }
            else if (analyte.id_type_analyte === 14) {
                let value = "";
                setInputState(value);
                let e = {
                    target: {
                        value: value
                    }
                };
                changeInputFile(e);
            }
            else {
                let value = parseFloat(analyte.capture.number);
                // ////console.log(value);
                setInputState(value);
                let e = {
                    target: {
                        value: value
                    }
                }
                changeInput(e, false, id_exam_method_aux)
                validarRango(analyte.id_type_analyte, value);
            }

            //Validacion para el comentario
            if (analyte.capture.comments !== '' && analyte.capture.comments !== undefined) {
                setObjectComments(analyte.capture.comments);
                setViewComments(true);
            }
            setPrint_chart(analyte.capture.print_chart);
        }
    }


    const changeComments = (e) => {
        let valor = e.target.value;

        setObjectComments(valor);
    }

    const guardarComentarios = async () => {

        let data = {
            'id_work_order_exam': dataExam.id_work_order_exam,
            'id_exam': dataExam.id_exam,
            'id_analyte': id_analyte,
            'comment': objectComments
        };

        data = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: data
        }

        const respuesta = await sendRequest(requestOptions, 'Exam/SaveCommentByAnalyte');

        if (respuesta.code === 200) {
            // ////console.log("Ëxito");
            createSweet("success", "success", "Se ha guardado tu comentario");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, guardarComentarios);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    //METODO PARA GUARDAR SI SE HABILITA LA IMPRESION DE LA GRAFICA
    const handleChangePrintChart = async (printChart) => {
        setPrint_chart(printChart);

        let data = {
            'id_work_order_exam': dataExam.id_work_order_exam,
            'id_exam': dataExam.id_exam,
            'id_analyte': id_analyte,
            'print_chart': printChart
        }

        data = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: data
        }

        const respuesta = await sendRequest(requestOptions, 'Exam/SaveAnalytePrinChart');

        if (respuesta.code === 200) {
            // ////console.log("Ëxito");
            previewWorkOrder();
            createSweet("success", "success", "Se ha guardado.");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, guardarComentarios);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }


    const downloadPdf = () => {
        if (analyte.capture !== null && analyte.capture.file_base64 !== undefined) {

            let linkDocument = 'data:application/pdf;base64,' + analyte.capture.file_base64;
            let downloadLink = document.createElement('a');
            let fileName = analyte.capture.text;
            downloadLink.href = linkDocument;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    }

    const encodeFileBase64 = async (file) => {

        return await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let tmpArray = [];
                let result = reader.result;

                tmpArray = result.split(',');
                resolve(tmpArray[1]);
            }
        })
    }

    return {
        users, alerts, typeAlert, changeInput, changeInputSelect, changeInputFile, inputState, options,
        toggleButton, viewButton,
        guardarDatosIndividuales,
        tooltip, toggleTooltip,
        tooltipRelease, toggleTooltipRelease, maskFormat,
        validarAnalito, userValidate,
        changeComplex, textAlerts,
        isInterface,
        toggle, modal,
        toggleComments, viewComments,
        changeComments, objectComments,
        guardarComentarios, downloadPdf,
        print_chart, handleChangePrintChart
    }
}