import React from 'react'
import { Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { useInputImagenology } from '../../../hooks/captureResults/useInputImagenology';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import gato from '../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg';

export const InputImagenology = ({ dataExam, imagenologyData, releaseData, saveFileExamImagenology, sectionProfileId, handleGetDocumentImagenology }) => {

    const release_data = releaseData;

    const {
        inputState, userValidate, users,
        changeInput, saveFile
    } = useInputImagenology({ dataExam, imagenologyData, saveFileExamImagenology, sectionProfileId });

    const ButtonSaveDocument = <Button className='p-1' outline color='info' size='xs' tabIndex="-1" disabled={(userValidate !== null) ? true : (inputState.type !== undefined ? false : true)} onClick={saveFile}>
        <i className='icofont icofont-save txt-secondary' id={"tooltip-save-document-" + dataExam.id_exam}></i>
        &nbsp; Guardar documento
        <ToolTips
            dataPlacement='top'
            dataTarget={"tooltip-save-document-" + dataExam.id_exam}
            dataText="Guardar archivo"
        />
    </Button>

    const ButtonDownloadPdf = <InputGroupText>
        <Button className='p-0' color='transparent' size='xs' tabIndex="-1" onClick={() => handleGetDocumentImagenology(dataExam.id_exam, dataExam.id_work_order_exam)}>
            <i className='icofont icofont-file-pdf text-secondary' id={"tooltip-download-" + dataExam.id_exam}></i>
            <ToolTips
                dataPlacement="top"
                dataTarget={"tooltip-download-" + dataExam.id_exam}
                dataText="Previsualizar archivo"
            />
        </Button>
    </InputGroupText>

    const ViewReleaseImg = <>
        {
            release_data !== null
                ?
                <InputGroupText>
                    <i style={{ cursor: "pointer" }} id={`tooltipRelaeseImg-${dataExam.id_exam}`} className="icofont icofont-ui-lock text-success"></i>
                    <ToolTips
                        dataPlacement="top"
                        dataTarget={`tooltipRelaeseImg-${dataExam.id_exam}`}
                        dataText={`Liberado el ${release_data.date} por ${release_data.user}`}
                    />
                </InputGroupText>
                :
                (userValidate !== null)
                    ?
                    <InputGroupText>
                        <i style={{ cursor: "pointer" }} id={`tooltipRelaeseImg-${dataExam.id_exam}`} className="icofont icofont-ui-lock text-success"></i>
                        <ToolTips
                            dataPlacement="top"
                            dataTarget={`tooltipRelaeseImg-${dataExam.id_exam}`}
                            dataText={`Validado el ${userValidate.date_capture} por ${userValidate.full_name}`}
                        />
                    </InputGroupText>
                    : ""
        }
    </>

    return (
        <Row className='border-top pt-2 mt-2'>
            <Col sm="4" className="align-self-center p-r-0">
                <p></p>
                <p className='f-w-600 f-12 badge badge-light-primary' >Selecciona un archivo con extención <code>&lt;.PDF&gt;</code> </p>

            </Col>
            <Col sm="4" className='align-self-center'> <br />
                <InputGroup className='input-group-sm'>
                    <Input
                        className={`form-control-search `}
                        style={{ width: '280px' }}
                        type='file'
                        accept='application/pdf'
                        placeholder='Selecciona un archivo'
                        onChange={(e) => changeInput(e)}
                    />
                    <InputGroupAddon addonType='append' tabIndex="-1">
                        {ViewReleaseImg}
                    </InputGroupAddon>
                    {
                        imagenologyData.capture !== null &&
                        <InputGroupAddon addonType='append' tabIndex="-1">
                            {ButtonDownloadPdf}
                        </InputGroupAddon>
                    }
                </InputGroup>
            </Col>
            <Col sm="2"> <br />
                {ButtonSaveDocument}
            </Col>
            <Col sm="2" className='align-self-left p-l-0 p-r-0'>
                <div className='media'>
                    <Row>
                        <Col sm="4">
                            {
                                users.length > 0 &&
                                <>
                                    <img style={{ cursor: "pointer" }} className="img-30 mr-1 rounded-circle" id={'TooltipUserCapture-' + users[0].id_work_order_exam_imagenology} src={gato} alt="" />
                                    <ToolTips
                                        dataPlacement="top"
                                        dataTarget={'TooltipUserCapture-' + users[0].id_work_order_exam_imagenology}
                                        dataText={users[0].date_capture + ' - Capturado por: ' + users[0].full_name}
                                    />
                                </>
                            }
                        </Col>
                        <Col sm="8">
                            {
                                users.length > 1 &&
                                <div className='d-flex'>
                                    <div className='customers'>
                                        <ul>
                                            {
                                                users.length === 2
                                                    ?
                                                    <>
                                                        <li className='d-inline-block mr-2'><p className='f-12'>2</p></li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                    </>
                                                    :
                                                    <>
                                                        <li className="d-inline-block mr-2">
                                                            <p className="f-12">{users.length > 3 ? "+3" : "3"}</p>
                                                        </li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block" style={{ marginLeft: "-10%" }}><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                    </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}
