import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col} from 'reactstrap';
import { sendRequest } from '../../hooks/requests/useRequest';
import Chart from 'react-apexcharts'
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import './StyleResult.css'
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { ModalChartResult } from './ModalChartResult';



export const ComponentChart = ({analyte2,index,idPatient}) => {



  const [chartOptions, setChartOptions] = useState({});
  const [series, setSeries] = useState([])
  const [show, setShow] = useState(true);
  const [createSweet] = useSweetAlert();

  const history = useHistory();


  useEffect(() => {

    if (idPatient !== 0) {
      chartCaptureresul(idPatient);
    }

}, [idPatient])


  const chartCaptureresul = async (idP) =>{

  
    let requestOptions = {
        method: 'GET'
    };

     
    const respuesta = await sendRequest(requestOptions, `Exam/charthistoricAnalitValues/${idPatient}/${analyte2.id_analyte}`);
    //console.log(respuesta);

    if (respuesta.code === 200) {

        let options ={
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false
                    },
                    toolbar:{
                      tools:{
                          download: false
                        },
                    },
                    dropShadow: {
                      enabled: true,
                      color: '#000',
                      top: 18,
                      left: 7,
                      blur: 10,
                      opacity: 0.2
                    },
                  },
                  colors: ['#0079C7'],
                  dataLabels: {
                    enabled: true
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                
                  grid: {
                    row: {
                      colors: ['#46AFE5', 'transparent'], 
                      opacity: 0.5
                    },
                  },
                  xaxis: {
                      categories: respuesta.data.categories,
                  },
                  yaxis: {
                       title: {
                                 text: (analyte2.unit)
                              },
                          }
                    }
             setChartOptions(options);
             setSeries(respuesta.data.series)
            }
            else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code, chartCaptureresul);
            }
            else if (respuesta.code === 500) {
                //createSweet("error", "error", "Resultados", "Ocurrio un error en el servidor");
            }
            else {
                //createSweet("error", "warning", "Resultados", respuesta.data.msg);
            }
    }


    let namm = analyte2.name
    let namm2 = namm.toLowerCase()
                                                                    
    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1);
      }


         //#region modalChart

    const [titleChart, setTitleChart] = useState("");
    const [typeModalChart, setTypeModalChart] = useState("viewChart");
    const [modalChart, setModalChart] = useState(false);
    const [nameChart, setNameChart] = useState(analyte2.name)



    
    const toggleChart = () =>{
      setModalChart(!modalChart)
    }

    const handleChart = () => {

      setTypeModalChart("viewChart");
      setTitleChart("Grafica");
      toggleChart();   
      chartCaptureresul(idPatient);

      }


  
    //#endregion modalChart




    return (
        <>
        <tr key={index} style={{ textAlign : "-webkit-center"  }} >
             <td style={{ textAlignLast: "", width: "8px" }}>
             <i className="icofont icofont-chart-line txt-primary f-28 pointer" onClick={handleChart}  ></i>
            </td>
            <td style={{ textAlign: "-webkit-left", width: "18px" }}>
                {
                    analyte2.print_format_transform === 0 ? 
                    <div>
                        {
                            analyte2.print_bold_font === true ?
                            <div>
                                <b><label className='labelExams'>{capitalize(namm2)}</label></b> <br />
                                {/* {
                                    analyte2.method === null ? ""
                                    :
                                    <div>
                                        <label className='labelMethod'>METODO:{analyte2.method}</label> <br />
                                    </div>
                                } */}
                                {
                                    analyte2.comment.length ===0 ? "" 
                                    :
                                    <label>{analyte2.comment}</label>
                                }
                            </div>
                            :
                            
                            <div>
                            <label className='labelExams'>{capitalize(namm2)}</label><br />
                          
                            {
                                analyte2.comment === "" ? ""
                                :
                                <label>{analyte2.comment}</label>
                            }
                        </div>
                        }
                    </div>
                    :
                    //print_underline_font
                    (analyte2.print_format_transform === 1 ) ? 
                    <div>
                        {
                            analyte2.print_italics_font === true ?
                            <div>
                                <em><label className='labelExams'>{analyte2.name.toUpperCase()}</label></em> <br />
                                {/* {
                                    analyte2.method === null ? ""
                                    :
                                    <div>
                                        <label className='labelMethod'>METODO:{analyte2.method}</label> <br />
                                    </div>
                                } */}
                                {
                                    analyte2.comment.length === 0 ? ""
                                    :
                                    <label>{analyte2.comment}</label>
                                }
                            </div>
                            :
                            <div>
                                <label className='labelExams'>{analyte2.name.toUpperCase()}</label> <br />
                                {/* {
                                    analyte2.method === null ? ""
                                    :
                                    <div>
                                        <label className='labelMethod'>METODO:{analyte2.method}</label> <br />
                                    </div>
                                } */}
                                {
                                    analyte2.comment.length === 0 ? ""
                                    :
                                    <label>{analyte2.comment}</label>
                                }
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {
                            analyte2.print_underline_font === true ? 
                            <div>
                                <label className='labelExams'><u>{analyte2.name.toLowerCase}</u></label> <br />
                                {/* {
                                    analyte2.method === null ? ""
                                    :
                                    <div>
                                        <label className='labelmethod'>METODO:{analyte2.method}</label> <br />
                                    </div>
                                } */}
                                {
                                    analyte2.comment.length === 0 ? ""
                                    :
                                    <label>{analyte2.comment}</label>
                                }

                            </div>
                            :
                            <div>
                                <label className='labelExams'>{analyte2.name.toLowerCase()}</label> <br />
                                {/* {
                                    analyte2.method === null ? ""
                                    :
                                    <div>
                                        <label className='labelmethod'>METODO:{analyte2.method}</label>
                                    </div>
                                } */}
                                {
                                    analyte2.comment.length === 0 ? ""
                                    :
                                    <label>{analyte2.comment}</label>
                                }

                            </div>
                        }
                    </div>
                }
            </td>
            <td style={{ textAlignLast: "", width: "18px" }} >
                {
                    analyte2.alert === null ?
                    <label>{analyte2.result}</label>
                    :
                    <b>{analyte2.result}</b>
                } 
            </td>
            <td style={{ textAlignLast: "", width: "18px" }}>
                {
                    analyte2.alert === null ? 
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;{analyte2.unit}</label>
                    :
                    (analyte2.alert === "danger") ?
                    <label ><i className="fa fa-arrow-up"></i>&nbsp;&nbsp;{analyte2.unit} </label>
                    :
                    <label ><i className="fa fa-arrow-down"></i>&nbsp;&nbsp;{analyte2.unit}</label>
                }
            </td>
            <td style={{ textAlignLast: "", width: "10px" }}>
                {
                    (analyte2.range === undefined) ? "" 
                    :
                    (analyte2.text_alerts === null ) ?
                    <div>
                        <label>{analyte2.range}</label>
                    </div>
                    :
                    (
                        (analyte2.range === "" || analyte2.text_alerts >=1) ? 
                        <div>
                            {
                                analyte2.text_alerts === null || analyte2.text_alerts >=1 || analyte2.text_alerts.map((obj,i) =>{
                                    return <div key={i}>
                                        <u className='labelTxtAlert'>{obj}</u>
                                    </div>
                                })
                            }
                        </div>
                        :
                        ""
                    )
                }
            </td>
        </tr>
        <ModalChartResult
          {
            ...{titleChart,typeModalChart,modalChart,toggleChart,series,chartOptions,nameChart}
          }

          />
       


        </>

  )
}
