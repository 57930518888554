import React, { Fragment, useState } from 'react';
import classnames from "classnames";
import {
    Container,
    Card,
    CardBody,
    CardHeader,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row
} from "reactstrap";
import Breadcrumb from '../../../layout//breadcrumb'

import { IndexUnits } from '../../units/IndexUnits';
import { IndexUnitConversions } from '../../units/IndexUnitConversions';

export const IndexUnit = (props) => {

    const [activeTab, setActiveTab] = useState(1);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Administración de unidades" /> */}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="nav-wrapper">
                                    <Nav
                                        className="nav-fill flex-column flex-md-row"
                                        id="tabs-icons-text"
                                        pills
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 1}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 1
                                                })}
                                                onClick={() => { toggle(1); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="fa fa-percent"></i> Unidades
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 2}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 2
                                                })}
                                                onClick={() => { toggle(2); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="fa fa-exchange"></i> Unidades de conversión
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <TabContent activeTab={"tabs" + activeTab}>
                                    <TabPane tabId="tabs1">
                                        <IndexUnits {...props} />
                                    </TabPane>
                                    <TabPane tabId="tabs2">
                                        <IndexUnitConversions {...{ ...props, activeTab }} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    )
}
