import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteAntibiotic } from './DeleteAntibiotic';
import { FormAntibiotic } from './FormAntibiotic';

export const ModalAntibiotic = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        antibiotic,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormAntibiotic
                        button={"Crear antibiotico"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormAntibiotic
                            button={"Actualizar antibiotico"}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, antibiotic, }} />
                        :
                        <DeleteAntibiotic {...{ toggle, loading, antibiotic, saveDelete }} />
            }
        </Modal>
    )
}
