import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useMicSiteArea = () => {

    const history = useHistory();

    const [sitesAreas, setSitesAreas] = useState([]);
    const [siteAreaId, setSiteAreaId] = useState(0);
    const [siteAreaSearch, setSiteAreaSearch] = useState([]);

    const [sites,setSites]=useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues,] = useForm({
        id_mic_site: "",
        name: "",
    });

    const { id_mic_site, name, } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(sitesAreas.length, numberItems);

    useEffect(() => {
        getSites().then(result => {
            getSiteArea(result);
        });
    }, [history.location]);



    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listsSitesAreas = [];
        sitesAreas.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listsSitesAreas.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSitesAreas(listsSitesAreas);
    }, [totalPageCount, currentPage])

    const [validaciones, setValidaciones] = useState({
        id_mic_site_valid: false,
        name_valid: false,
    });

   


    const getSiteArea = async (result = null) => {
        //////console.log(result, "dfdsf");
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SitesAreas/List");
        //////console.log(respuesta);
        let sitios = result === null ? sites : result;


        if (respuesta.code === 200) {
            let listsSitesAreas = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
    
                respuesta.data.forEach((data, index) => {
                    

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
                    let sitio = sitios.find(x => x.id_mic_site == data.id_mic_site);

                    listsSitesAreas.push({
                        ...data,
                        nombre_sitio: sitio.name,
                        visibleTable: visibleTable,
                    });
                });
                ////console.log(listsSitesAreas);
                setSitesAreas(listsSitesAreas);
                setSiteAreaSearch(listsSitesAreas);
            }
            else {
                // createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSitesAreas([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSiteArea);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);

        }
        // else {
        //     validarSesion(history, respuesta.code, getRooms);
        // }
    }

    const getSites= async()=>{
        let requestOptions={
            method: 'GET'
        };
        const respuesta=await sendRequest(requestOptions,'Sites/List');
        if (respuesta.code === 200) {
            setSites(respuesta.data);
            return respuesta.data;
        } 
    }


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_mic_site: "",
            name: "",
        });

        setValidaciones({
            id_mic_site_valid: false,
            name_valid: false,
        });

        //setRoomId(0);
        setMethod("create");
        setModalTitle("Crear área");
        toggle();
        getSites();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }        

        let mValues = JSON.stringify(formValues);


        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SitesAreas/Create");

        if (respuesta !==null) {
            //getRooms();
            //reset();
            createSweet("create", "success", "Éxito!", "Sitio creado con éxito");
            setLoading(false);
            toggle();
            handleUpdateValues({
                id_mic_site:"",
                name:"",
            })
            getSiteArea();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_mic_site_area) => {
        let add = sitesAreas.find(s => s.id_mic_site_area === parseInt(id_mic_site_area));

        handleUpdateValues({
            id_mic_site: add.id_mic_site,
            name: add.name,
        });

        setValidaciones({
            id_mic_site_valid: false,
            name_valid: false,
        });

        setSiteAreaId(id_mic_site_area);
        setMethod("update");
        setModalTitle("Actualización de área");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_mic_site_area: siteAreaId,
            id_mic_site:sites,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SitesAreas/Update");

        if (respuesta.code === 200) {
            getSiteArea();
            createSweet("create", "info", "Éxito!", "Área actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_mic_site_area) => {
        let siteArea = sitesAreas.find(s => s.id_mic_site_area === parseInt(id_mic_site_area));

        if (siteArea) {
            handleUpdateValues({
                id_mic_site: siteArea.id_mic_site,
                name: siteArea.name,
            });
        }

        setSiteAreaId(id_mic_site_area);
        setMethod("delete");
        setModalTitle("Eliminar área");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `SitesAreas/${siteAreaId}`);
        if (respuesta!==null) {
            getSiteArea();
            setSiteAreaId(0);
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Área eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);
            setLoading(false)
        }
    }


    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 2) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }
        if (formValues.id_mic_site === "" || formValues.id_mic_site <= 0) {
            newValidations = {
                ...newValidations,
                id_mic_site_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_mic_site_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const handleSearchSiteArea = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        siteAreaSearch.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setSitesAreas(newMethod);
    }

    return {
        sitesAreas, method, validaciones,
        name, id_mic_site, sites,
        handleInputChange, loading, handleSearchSiteArea,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }
}
