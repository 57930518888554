import React, { useState, Fragment } from 'react';
import { Button, ButtonGroup, Table, FormGroup, Input, Label, Collapse, Card, Col, CardBody, Row } from 'reactstrap';
import Select from "react-select";
import { DataGridComponent } from './DataGridComponent';
import { SearchAsync } from '../../../../components/elements/SearchAsync';
import { Key } from 'react-feather';

export const CollapseData = (props) => {

  const {
    items,id_delete, handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times,
    handleCheckSpecimen, viewButtons, handleViewExamsProfile, deleteSample, removecant, addcant, listNom,
    nomSelected, list_nom_det, handleValuePeriod, handleInputChange, changenom, changeobservacionesinput, checkTypeClient,
    id_exam_temp, handleSelectExamChange, examProfileState,AddNewDetermination,changediscount_part
    //860 useaddagremet

  } = props;
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 999
    }),
  }

  return (
    <Col md="12">
      <Card key={id_delete} className="shadow">
        <div className="p-4 b-b-primary card-header" style={{ cursor: "pointer" }}>
          <div className="align-items-center row">
            <div onClick={toggle} className="col-12">
              <h6 class="f-w-600" style={{ color: "#007bff" }}>
                {items.name_sample}
              </h6>
              {/* <div class="media">
                <div class="media-body">
                  {items.name_human != "" ?
                    <p className="mt-1 f-w-300" style={{ color: "#007bff" }}>
                      Nombre: {items.name_human} {items.paternal_human} {items.maternal_human}
                    </p>
                    : ""}
                </div>
              </div> */}

            </div>


            <div className="text-right col-12">
              <Row>
                <Col sm="4" md="4" lg="4" xl="4">
                  <Label>{"Determinación"} <span style={{ color: "#0079C7" }}>*</span></Label>
                  <FormGroup>
                    <SearchAsync
                      className="col-md-12"
                      value={id_exam_temp}
                      method={handleSelectExamChange}
                      loincState={examProfileState}
                      url="Profiles/SearchProfileExam"
                      maxLenght={3}
                      selectSize="col-md-12"
                    />

                  </FormGroup>
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                  <Button outline color='primary' size='sm' onClick={() => AddNewDetermination(id_delete)}>
                    Agregar
                  </Button>
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                  
                </Col>
                <Col sm="2" md="2" lg="2" xl="2" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                  
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                  <ButtonGroup>
                    <Button
                      className="badge badge-success"
                      size="xs"
                      onClick={() => removecant(id_delete)}>
                      -
                    </Button>
                    &nbsp;<b>{items.cant}</b>&nbsp;
                    <Button
                      className="badge badge-danger"
                      size="xs"
                      onClick={() => addcant(id_delete)}>
                      +
                    </Button>
                    &nbsp;&nbsp;
                  </ButtonGroup>
                </Col>
                <Col sm="1" md="1" lg="1" xl="1" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
                  <span class="badge badge-danger f-right" onClick={() => deleteSample(id_delete)} >Eliminar</span>
                <i className={`icofont icofont-arrow-${isOpen ? "up" : "down"}`}></i>
                </Col>
               
              </Row>
              
              {/* <button type="button" class="btn-air-danger btn btn-outline-danger btn-xs">
                  <i class="fa fa-trash-o"></i>
                </button> */}
            </div>
          </div>

        </div>
        <Collapse isOpen={isOpen}>
          <CardBody className="pt-0 pl-4 pr-4 crm-activity" >
            <Col sm="12">
              {items.list_determinations!=null ?
                items.list_determinations.length > 0 ?
                  <DataGridComponent
                    key={"table"}
                    indexs={items.list_determinations}
                    {...{
                      handleCheckUrgent, changeSelectSamplings, changeValueTimeSampling, times,
                      handleCheckSpecimen, deleteExamProfile, viewButtons, handleViewExamsProfile, id_type_sample: items.id,
                      listNom, nomSelected, list_nom_det, handleValuePeriod, handleInputChange, changenom, changeobservacionesinput,
                      checkTypeClient,indexmuestra:id_delete,changediscount_part
                    }}
                  /> : ""
                  :""
              }
            </Col>
            {/* <Col sm="12" className="text-right">
              {
                items.price_sample > 0 ?
                  <h5 className="mt-2 f-w-600">
                    Costo: ${parseFloat(items.price_sample.toFixed(2) * items.cant)}
                  </h5>
                  : ""
              }
            </Col> */}
          </CardBody>
        </Collapse>
      </Card>


    </Col>
  )

}
