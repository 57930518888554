export const ListOptionsStatus = [
    {
        value: 0,
        label: "Sin Estatus"
    },
    {
        value: 1,
        label: "Abierta"
    },
    {
        value: 2,
        label: "En Progreso"
    },
    {
        value: 4,
        label: "Validado"
    },
    {
        value: 5,
        label: "Parcial"
    },
    {
        value: 6,
        label: "Liberado"
    },
    {
        value: 7,
        label: "Enviado"
    },
    {
        value: 8,
        label: "Entregado"
    },
    {
        value: 9,
        label: "Entregado-Enviado"
    },
    {
        value: 10,
        label: "Autorizado"
    },

    {
        value: 13,
        label: "Impreso"
    },
    {
        value: 17,
        label: "Desvalidado"
    },
    {
        value: 20,
        label: "Cancelada"
    }
];



