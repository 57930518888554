import React from 'react'
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

export const ModalViewExams = (props) => {

    const {
        modalViewExamsP, toggleModalViewExams, viewExamsProfile
    } = props;

    return (
        <Modal isOpen={modalViewExamsP} toggle={toggleModalViewExams} keyboard={false} centered={true} className="">
            <ModalHeader toggle={toggleModalViewExams} className='bg-primary'>
                Listado de exámenes 
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <h6 class="disableLabel" for="u">Exámenes que conforman el Perfil</h6> 
                    </Col>
                </Row>
                <Row className='m-t-10'>                    
                    <Col sm="12" md="12" lg="12" xl="12">
                        <ul className='cardExam-content'>
                            {
                                viewExamsProfile.length === 0
                                ?
                                    <div>
                                        <br />
                                        <Label>No hay examenes para el perfil seleccionado</Label>
                                    </div>
                                :
                                    <div>
                                        {
                                            viewExamsProfile.map( (obj, index) => {
                                                return (
                                                    <li key={'exam-'+index}>
                                                        <p><i className="icofont icofont-laboratory font-primary"></i>&nbsp;&nbsp;{obj.name}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </ul>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}
