import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate,  } from '../../constant';
import { DeleteSpecimenProvenances } from './DeleteSpecimenProvenances';
import { FormSpecimenProvenances } from './FormSpecimenProvenances';

export const ModalSpecimenProvenances = (props) => {

    const{
        modal, modalTitle, toggle, method, loading,
        name, abbreviation, handleInputChange,
        validaciones, saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={ toggle } className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ? 
                    <FormSpecimenProvenances
                        button={ModalButtonSave}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                :
                    (method === "update")
                        ?
                            <FormSpecimenProvenances
                                button={ModalButtonUpdate}
                                {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, abbreviation }}
                            />
                        :
                            <DeleteSpecimenProvenances {...{ toggle, loading, name, abbreviation, saveDelete }} />
            }
        </Modal>
    )
}
