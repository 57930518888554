import React, { Fragment, useState} from 'react'
import Breadcrumb from '../../layout/breadcrumb';
import Select from 'react-select';
import { Button, Card, CardHeader,CardBody, CardFooter, Col, Container, FormFeedback, FormGroup, FormText, Input, Label, Nav, NavLink, NavItem, Row, Table, TabContent, TabPane, Badge, InputGroup, InputGroupAddon, InputGroupText, Collapse } from 'reactstrap';
import { useInvoiceCompany } from '../../hooks/invoice/useInvoiceCompany';
import { SearchAsync } from '../../components/elements/SearchAsync';
import './StyleInvoiceClient.css';
import { ModalInvoiceCompany } from './ModalInvoiceCompany';

export const IndexInvoiceCompany = () => {
    const {
        id_company, id_company_business_name, id_commercial_line, id_exam_profile, id_branch, id_use_cfdi, checkPrint, checkSend, date_ini, date_end,
        companies, businessNames, commercialLines, branches, useCfdi, businessNameData,
        handleSelectValues, handleInputChange,
        handleChangeCompany, handleChangeCommercialLine, handleChangeBusinessName, handleFilterInvoice,
        removeExamProfile,
        examProfileTest,inputTest, number_format,
        handleCheckInvoice ,
        email, phone,saveCreate,
        total, discount, priceIVA, priceTotal,
        toggle, handleCreateInvoice, method, modal,loading,arrayExamProfileCheck,
        examProfile

    } = useInvoiceCompany();

    const [filterExam, setFilterExam] = useState(true);
    const [filterClient, setFilterClient] = useState(true);
    const [filterP, setFilterP] = useState(true);
    const [filterDate, setFilterDate] = useState(true);


    return (
        <Fragment>
        {/* <Breadcrumb parent="Facturación" title="Empresas" /> */}
        <Container fluid={true}>
            <Row>
                <Col md={12}>
                    <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="12" xl="12">
                    <Row className="position-sticky" style={{ top: 140 }}>
                        <Col xl="12">
                            <Card>
                                <CardHeader className=' bg-primary '>
                                    <h6 className="mb-0">
                                        <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterClient(!filterClient)}
                                            data-target="#collapseicon" aria-expanded={filterClient} aria-controls="collapseicon" ><i className="icofont icofont-search-alt-1"></i> &nbsp; &nbsp; &nbsp; &nbsp; Busqueda por Cliente</Button>
                                    </h6>
                                </CardHeader>
                                <Collapse isOpen={filterClient}>
                                    <CardBody style={{ "fontSize": "12px" }} className='p-3 p-l-0'>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <label htmlFor="">Empresa</label> <br />
                                                            <FormGroup className='m-0'>
                                                                    <Select
                                                                        value={id_company}
                                                                        classNamePrefix="select"
                                                                        name="id_company"
                                                                        placeholder="Selecciona una opción"
                                                                        isClearable={false}
                                                                        noOptionsMessage={() => "Sin opciones"}
                                                                        options={companies}      
                                                                        onChange={(e) => handleChangeCompany(e)}                                                           
                                                                    />
                                                                </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <label htmlFor="">Razón Social</label> <br />
                                                    <FormGroup className='m-0'>
                                                                    <Select
                                                                        value={id_company_business_name}
                                                                        classNamePrefix="select"
                                                                        name="id_company_business_name"
                                                                        placeholder="Selecciona una opción"
                                                                        isClearable={false}
                                                                        noOptionsMessage={() => "Sin opciones"}     
                                                                        options={businessNames}
                                                                        onChange={(e) => handleChangeBusinessName(e, "id_company_business_name")}
                                                                        />
                                                                </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col> <br />
                                        <Col sm="12"> <br />
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                    <Row>
                                                        <Col sm="6" style={{ textAlign: "right"}}>
                                                        <i className="fa fa-building-o f-18 icon-color-Company "></i> <label className='f-14'>Sucursal:</label>
                                                        </Col>
                                                        <Col sm="6" className='p-l-0'>
                                                        <label className='f-14'>{ businessNameData.rfc !== undefined ? businessNameData.rfc : "" } </label>
                                                        </Col>
                                                    </Row>
                                                </Col> <br />
                                                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                    <Row>
                                                        <Col sm="6" style={{ textAlign: "right"}}>
                                                        <i className="icofont icofont-ui-call f-18 icon-color-Company "></i>  <label className='f-14'>Telefono:</label>
                                                        </Col>
                                                        <Col sm="6" className='p-l-0'>
                                                        < label className='f-14' >{ businessNameData.phone !== undefined ? businessNameData.phone : "" } </label>
                                                        </Col>
                                                    </Row>
                                                </Col> <br />
                                                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                    <Row>
                                                        <Col sm="6" style={{ textAlign : "right"}}>
                                                        <i className="icofont icofont-ui-email f-18 icon-color-Company " ></i> <label className='f-14'>Correo:</label>
                                                        </Col>
                                                        <Col sm="6" className='p-l-0'>
                                                        <label className='f-14'>{ businessNameData.email !== undefined ? businessNameData.email : "" } </label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col> <br /> <br /> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <div>
                                    <Table style={{ "fontSize":"11px" }}  className='' >
                                        <thead>
                                            <tr className='arcoiris'>
                                                <th className='txt-light' colSpan={2}>  <i className="icofont icofont-user-male f-16 mr-2"></i>  {"Datos Generales"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-bottom-primary">
                                            <td ><Label>Razón social</Label></td>
                                               <td> 
                                               <FormGroup className='m-0'>
                                                <Select
                                                value={id_commercial_line}
                                                classNamePrefix="select"
                                                name="id_commercial_line"
                                                placeholder="Selecciona una opción"
                                                isClearable={false}
                                                noOptionsMessage={() => "Sin opciones"}     
                                                options={commercialLines}
                                                onChange={(e) => handleChangeCommercialLine(e, "id_commercial_line")}
                                                />
                                            </FormGroup>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom-primary">
                                            <td>Sucursal</td>
                                               <td>
                                               <FormGroup>
                                                <Select
                                                value={id_branch}
                                                classNamePrefix="select"
                                                name="id_branch"
                                                placeholder="Selecciona una opción"
                                                isClearable={false}
                                                noOptionsMessage={() => "Sin opciones"}     
                                                options={branches}
                                                onChange={(e) => handleSelectValues(e, "id_branch")}
                                                />
                                             </FormGroup>
                                              </td> 
                                            </tr>
                                            <tr className="border-bottom-primary">
                                            <td>Correo Electrónico</td>
                                               <td>
                                                <FormGroup className='m-0'>  
                                               <Input 
                                                 type='text' 
                                                 name="email" 
                                                 value={email}
                                                 onChange={handleInputChange}
                                                 className="form-control form-control-sm input-air-primary" 
                                                 />   
                                                  <FormFeedback>Número invalido</FormFeedback>  
                                                 </FormGroup>  
                                            </td>
                                            </tr>
                                            <tr >
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                             </Col>

                             <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                <div>
                                    <Table style={{ "fontSize":"11px" }}  className='form theme-form' >
                                        <thead>
                                            <tr className='arcoiris'>
                                                <th className='txt-light' colSpan={2}>  <i className="icofont icofont-user-male f-16 mr-2"></i>  {"Datos Generales"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-bottom-primary">
                                            <td ><Label>Uso del CFDI</Label></td>
                                               <td> 
                                               <FormGroup className='m-0'>
                                                 <Select
                                                value={id_use_cfdi}
                                                classNamePrefix="select"
                                                name="id_use_cfdi"
                                                placeholder="Selecciona una opción"
                                                isClearable={false}
                                                noOptionsMessage={() => "Sin opciones"}     
                                                options={useCfdi}
                                                onChange={(e) => handleSelectValues(e, "id_use_cfdi")}
                                                />
                                            </FormGroup>
                                                </td>
                                            </tr>
                                            <td> <br /> <Label>Metodo de envío</Label></td>
                                                            <td>
                                                                <Row>
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "end" }}>                                                    
                                                                      <Label > <br /> Imprimir</Label>
                                                                    </Col>
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                        <FormGroup> <br />
                                                                            <Label className='switch'>
                                                                            <br />    <Input 
                                                                                    type='checkbox'
                                                                                    name='checkPrint'
                                                                                    onChange={handleInputChange}
                                                                                    checked={checkPrint}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "end" }}>                                                    
                                                                        <Label > <br /> Enviar</Label>
                                                                    </Col>
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                        <FormGroup> <br />
                                                                            <Label className='switch'>
                                                                               <br /> <Input 
                                                                                    type='checkbox'
                                                                                    name='checkSend'
                                                                                    onChange={handleInputChange}
                                                                                    checked={checkSend}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                            <tr >
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                    </Col> 
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                    </Row>
                </Col> <br /> <br />
                <Col sm="12" className={businessNameData.rfc !== undefined ? "animate__animated animate__backInRight ribbon-wrapper" : "d-none ribbon-wrapper"}>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="7" xl="7">
                        <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
                    <Row className="position-sticky" style={{ top: 140 }}>
                        <Col xl="12">
                            <Card>
                                <CardHeader className=' bg-primary '>
                                    <h6 className="mb-0">
                                        <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterExam(!filterExam)}
                                            data-target="#collapseicon" aria-expanded={filterExam} aria-controls="collapseicon" ><i className="fa fa-filter"></i> &nbsp; &nbsp; &nbsp; &nbsp; Examenes Disponibles
                                            </Button>
                                    </h6>
                                </CardHeader>
                                <Collapse isOpen={filterExam}>
                                {/* style={{ "fontSize": "12px" }} */}
                                    <CardBody  className='p-3'>
                                        <Row>
                                            
                                            <Col sm="10" >
                                                <FormGroup>
                                                    <Label>Examen / Perfil</Label>
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        value={id_exam_profile}
                                                        name="id_exam_profile"
                                                        method={handleInputChange}
                                                        loincState={inputTest}
                                                        url="Profiles/SearchProfileExam"
                                                        maxLenght={3}
                                                        placeholder="Búsqueda por codigo o nombre"
                                                        className="select input-air-primary input-sm"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            {/* <Col sm="1"></Col>
                                            */}
                                            <Col xs="10" sm="12" md="10" lg="10" xl="10" >
                                                <Label>Fecha</Label>
                                                <FormGroup className='p-0'>
                                                    <Row>
                                                        <Col md="4" sm="4" xl="4">
                                                            <Input className='form-control form-control-sm input-air-primary' onChange={handleInputChange} value={date_ini} name="date_ini" type='date' />                                          
                                                        </Col>
                                                        <Col md="4" sm="4" xl="4">
                                                            <Input className='form-control form-control-sm input-air-primary' onChange={handleInputChange} value={date_end} name="date_end" type='date' />                                          
                                                        </Col>
                                                        <Col md="4" sm="4" xl="4">
                                                        <Button outline color='info' className='text-center' onClick={handleFilterInvoice}>Filtrar</Button>  

                                                        </Col>
                                                    </Row>                                                                                          
                                                </FormGroup>
                                            </Col>
                                        </Row>

                    <div className='table-responsive'> <br /> <br />
                        <Table className=''>
                            <thead className=''>
                                <tr className='border-bottom-info'>
                                    <th>Nombre</th>
                                    <th>Nim</th>
                                    <th>Examen/Perfil</th>
                                    <th>Precios</th>
                                    <th>Facturar</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    arrayExamProfileCheck.length >=0 && arrayExamProfileCheck.map((obj,index) =>{
                                        ////console.log(obj);
                                        return(
                                            <tr key={index} className='border-bottom-info middle'>
                                                <td>{obj.name+" "+obj.paternal_surname+" "+obj.maternal_surname}</td>
                                                <td>{obj.nim}</td>
                                                <td style={{ "vertical-align": "middle", width: "10%" }}>
                                                    {                                                                
                                                    obj.examsProfiles.length >= 1 && obj.examsProfiles.map( (test, i) => {
                                                        return (
                                                            <Row key={i}>
                                                               <Col className='p-1'>
                                                                - {test.name_test}
                                                                </Col>
                                                               </Row>
                                                             )
                                                          })
                                                        }
                                                </td>
                                                <td>
                                                    {
                                                        obj.examsProfiles.length >= 1 && obj.examsProfiles.map( (test, i) => {
                                                            return (
                                                                <Row key={i}>
                                                                    <Col className='p-1'>
                                                                        $ {number_format((test.price / 1.16) , 2)}
                                                                    </Col>
                                                                </Row>                                                                                                                               
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td style={{ textAlign: "-webkit-center"}}>
                                                    {
                                                        obj.examsProfiles.length >= 1 && obj.examsProfiles.map( (test, i) => {
                                                            return (                                                                                                                         
                                                                <Row key={i+1}>
                                                                    <Col className="checkbox checkbox-dark mt-n2 p-1">
                                                                        <Input id={`checkbox-${obj.nim}-${i}`} 
                                                                            type="checkbox"
                                                                            onChange={(e) => handleCheckInvoice(e,index, i) }   
                                                                            checked={ test.checked != undefined ? ( test.checked ? true : false ) : false }     
                                                                        />
                                                                        <Label for={`checkbox-${obj.nim}-${i}`}>
                                                                            {test.checked ? "" : ""}
                                                                        </Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                                                                                 
                                    </CardBody>                    
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                </Col>    
                
                        </Col>
                        <Col  xs="12" sm="12" md="12" lg="5" xl="5" className={examProfile.length >0? "animate__animated animate__fadeInRight " : "d-none "}> 
                              <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc"  xs="12" sm="12" md="12" lg="12" xl="12" >
                                  <Row className="position-sticky" style={{ top: 140 }}>
                                      <Col  xs="12" sm="12" md="12" lg="12" xl="12"  className='p-l-0 p-r-0'>
                                          <Card className="form theme-form">
                                              <CardHeader className='bg-primary'>
                                                  <h5 className='mb-0'>
                                                      <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon">
                                                      <i className="icofont icofont-prescription f-22"></i> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Exámenes a facturar
                                                      </Button>
                                                  </h5>
                                              </CardHeader>
                                              <Collapse isOpen={filterDate}>
                                              <Col sm="12" className='p-l-0 p-r-0'> <br />
                                                {
                                                examProfile.length >0 &&
                                                <table className="table table-xs table-stripped">
                                                <tbody>
                                                <tr className="table-Encabezado border-bottom-info">
                                                    <th style={{ textAlign: "-webkit-center" }}>Nombre</th>
                                                    <th style={{ textAlign: "-webkit-center" }}>Nim</th>
                                                    <th style={{ textAlign: "-webkit-center" }}>Exámen / Perfil</th>
                                                    <th style={{ textAlign: "-webkit-center" }}>Cancelar</th>
                                                </tr>
                                                {examProfile !== undefined && examProfile.map((x, key) => {
                                                    return (
                                                        <tr key={ key} className="border-bottom-info" >
                                                            <td style={{ verticalAlign:"middle"}}>{x.name+" "+x.paternal_surname+" "+x.maternal_surname}</td>
                                                            <td style={{ verticalAlign:"middle"}}> {x.nim}</td>
                                                            <td style={{ verticalAlign:"middle"}} >
                                                                {                                                                
                                                                    x.examsProfiles.length >= 1 && x.examsProfiles.map( (test, i) => {
                                                                        return (
                                                                            <Row key={i}>
                                                                                {
                                                                                    (test.checked !== undefined && test.checked) 
                                                                                        ?
                                                                                            <Col className='p-1'>
                                                                                                <i className="icofont icofont-ui-press kit-icon f-8"></i>&nbsp;{test.name_test}
                                                                                            </Col>
                                                                                        :
                                                                                            ""
                                                                                }
                                                                            </Row>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td style={{ textAlign: "center"}}> <br />
                                                                <i className="icofont icofont-ui-close f-14 text-danger pointer" onClick={ () => removeExamProfile(key)}></i>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                            }
                                    <br />
                                </Col>

                                                 
                                              </Collapse>
                                          </Card>
                                      </Col> 
                                  </Row>
                              </Col>    
                              <Col sm="12">
                                <Row>
                                    
                                    <Col sm="12" >
                                    <Card>
                                        <Badge className="f-12" color="primary ">Resúmen</Badge>
                                        <Row>
                                            <Col md="12">
                                                <div className="table-responsive"> 
                                                    <Table className="table table-xs">
                                                        <thead>
                                                             <tr className="border-bottom-info" >
                                                                <td>Subtotal</td>
                                                                <td className="text-right">$ {number_format(total, 2)}</td>
                                                                </tr>
                                                                <tr className="border-bottom-info" >
                                                                <td>Descuento</td>
                                                                <td className="text-right text-danger border-bottom-info" >$ {number_format(discount, 2)}</td>
                                                                </tr>
                                                                <tr className="border-bottom-info" >
                                                                <td>IVA&nbsp;(.16%)</td>
                                                                <td className="text-right">$ {number_format(priceIVA, 2)}</td>
                                                                </tr>
                                                                <tr className="border-bottom-info" >
                                                                <td><b>Total</b></td>
                                                                <td className="text-right border-bottom-info" ><b>$ {number_format(priceTotal, 2)}</b></td>
                                                                </tr>
                                                        </thead>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        <div className='col-sm-12' style={{ textAlign: "-webkit-center"}}>
                                            <Button 
                                            outline 
                                            color="primary"    
                                            size="sm" 
                                            onClick={ handleCreateInvoice }
                                            >
                                            {"Facturar"} 
                                            </Button>
                                    </div>
                                </Col>
                                </Row>
                              </Col>
                          </Col>
                    </Row>
                </Col> 
            </Row>
        </Container>
        <ModalInvoiceCompany
        {
            ...{
                toggle, handleCreateInvoice, method, modal,saveCreate,examProfileTest,loading,examProfile
            }
        }




        />
    </Fragment>
    )
}
