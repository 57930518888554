import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const AuthorizedSpecialDiscount = (props) => {

    const {
        toggle, loading, saveAuthorized, idSpecialDiscount,
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveAuthorized}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de autorizar el descuento especial:</p>
                <b>{`COD-00${idSpecialDiscount}`}</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Sí, autorizar
                </Button>
                <Button className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
