import React, { Fragment } from 'react'
import { Card, CardBody, Col, Container, Row, Button,Input } from 'reactstrap';
import { ModalPrecodedText } from '../../components/precodedTexts/ModalPrecodedText';
import { TableStriped } from '../../components/tables/TableStriped';
import { usePrecodedText } from '../../hooks/precodedTexts/usePrecodedText';

export const IndexPrecodedTexts = ({ activeTab }) => {
    const {
        precodedTexts, typePrecodedTexts, method,
        id_type_precoded_text, text, handleInputChange, validaciones,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchTypePrecod
    } = usePrecodedText({ activeTab });

   

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de textos precodificados
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchTypePrecod} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["id_type_precoded_text","visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Tipo de texto precodificado", "Nombre"]}
                                        items={precodedTexts}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalPrecodedText
                {...{ modal, toggle, loading, method, id_type_precoded_text, text, typePrecodedTexts, handleInputChange, validaciones, saveCreate, saveUpdate, saveDelete }}
            />
        </Fragment>
    )
}
