import React from 'react'
import { Button, Col, Form, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormCurveTimes = (props) => {

    const {
        button, toggle, loading, handleInputChange, validations, methodAction, name
    } = props;

    const {
        name_valid
    } = validations;

    return (
        
        <Form className='' role='form' onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className='col-form-label'>Nombre: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type='text'
                                autoComplete='off'
                                name='name'
                                value={name}
                                invalid={name_valid}
                                onChange={handleInputChange}
                                className='form-control form-control-sm input-air-primary'
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" outline color='primary' type='submit' className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    { button }
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
