import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import { useLogin } from '../../../hooks/tomaMuestra/useLogin';
import '../../../assets/css/pantalla1.css'
import { ModalIDcard } from './ModalIDcard';
import { usePantalla1 } from '../../../hooks/tomaMuestra/usePantalla1';
import 'animate.css';


const Sample = (props) => {

	let name = localStorage.getItem('flebotomistaUser');
	//////console.log(name);
	let name2 = localStorage.getItem('flebotomisaNombre');
	let name5 = localStorage.getItem('willson');


	const { time, reload, cerrarSesion2 } = useLogin();



	//HIDE USER
	const [show, setShow] = useState(true);



	const {
		closeModal, handleCardID, method, modalTitle, modal, loading, handleInputChangeNim, nim, getDataNim, data, setFocusNim, nimAutoFocus, onKeyPressNim, handleSelectValuesNim
	} = usePantalla1();

	return (
		<Fragment  >
			<Container fluid={true} className='bg-barra1 cardSampleD  p-0'   >
				<Col xs="12" sm="12" md="12" lg="12" xl="12" className="">
					<Row>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" className=" p-l-0 p-r-0"> <br />
							<div className='box-departamento'>
								<Col sm="12" style={{ textAlign: "-webkit-center" }}>
									<Row>
										<Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0'>
											<Label className='labelToma1'>{time}</Label>
										</Col>
										<Col xs="9" sm="9" md="9" lg="9" xl="9">
											<label className='labelToma1'>Toma de Muestra&nbsp;/&nbsp;{name5} </label>
										</Col>
									</Row>
								</Col>
							</div>
						</Col>
					</Row>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<Row>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }} >
							<div style={{ position: 'absolute', left: "-800", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
								<input
									id='inputFocusNim'
									autoFocus={setFocusNim}
									value={nim}
									onChange={(e) => handleSelectValuesNim(e.target.value, "nim")}
									onKeyDown={onKeyPressNim}
									onBlur={nimAutoFocus}
								/>
							</div>
							<div style={{ zIndex: 1 }}>
								<img className="" src={require("../../../assetsToma/imagenes/Barras.gif")} style={{ width: "25%" }} alt="" />
							</div>
							<div className='a-line'>
								<label className='labelIniciarMuestra'>Escanea el <b className=''>Código de barras </b> de las muestras  </label> <br />
							</div>
							<label className='labelCode' >o</label> <br />
							<button className='bottonIniciarMuestra' onClick={handleCardID}> Ingresálo manualmente</button> <br /><br /><br />
							<div className='tap-top' style={{ display: "block" }} onClick={() => { setShow(!show); }}><i className="icon-angle-double-up f-24"></i>
							</div>
						</Col>
						<Col sm="" style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0'>
							<Col xs="12" sm="12" md="12" lg="12" xl="12"> <br /> <br />
								<br /> <br /> <br />
								{
									show ?
										(
											<div></div>
										)
										:
										(
											<Col xs="12" sm="12" md="12" lg="12" xl="12">

												<div className='username-Active-Muestra'>
													<Col xs="12" sm="12" md="12" lg="12" xl="12">
														<Row>
															<Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }} >
																<br />
																<i className="fa fa-circle f-30 icon-active"></i>
															</Col>
															<Col xs="10" sm="10" md="10" lg="10" xl="10" className='p-l-0 p-r-0 ' > <br />
																<label className='label-menuToma p-0'>{name2}</label>
															</Col>
														</Row>
													</Col>
												</div> <br />

												<div className='username-Active-Muestra pointer' onClick={reload}>
													<Col xs="12" sm="12" md="12" lg="12" xl="12">
														<Row>
															<Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }} > <br />
																<img className=" " src={require("../../../assetsToma/imagenes/Recargar.png")} style={{ width: "50%" }} alt="" />
															</Col>
															<Col xs="10" sm="10" md="10" lg="10" xl="10" className='p-l-0 p-r-0 ' > <br />
																<label className='label-menuToma p-0 pointer'>{"Recargar"}</label>
															</Col>
														</Row>
													</Col>
												</div> <br />
												<div className='username-Active-Muestra pointer' onClick={() => cerrarSesion2(`${process.env.PUBLIC_URL}`)}>
													<Col xs="12" sm="12" md="12" lg="12" xl="12">
														<Row>
															<Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0' style={{ textAlign: "-webkit-center" }}> <br />
																<img className=" " src={require("../../../assetsToma/imagenes/Salir.png")} style={{ width: "40%" }} alt="" />
															</Col>
															<Col xs="10" sm="10" md="10" lg="10" xl="10" className='p-l-0 p-r-0 ' > <br />
																<label className='label-menuToma p-0 pointer' >{"Cerrar Sesión"}</label>
															</Col>
														</Row>
													</Col>
												</div>
											</Col>
										)
								}
							</Col>
						</Col>
					</Row>
				</Col>
			</Container>
			<ModalIDcard
				{
				...{
					method, modalTitle, closeModal, modal, loading, getDataNim, data, handleInputChangeNim, nim
				}
				}
			/>
		</Fragment>
	);
}

export default Sample;