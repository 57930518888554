import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useForm } from '../forms/useForm';
import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';


export const useStatusWorkDay = () => {

    const history = useHistory();

    let id_branch = localStorage.getItem('branchId');

    const sectionsDefault = JSON.parse(localStorage.getItem("filter_sections"));
    const [dataPending, setDataPending] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();


    useEffect(() => {
        getBranches();
        getSections().then(resp => {
            getWorkOrdersDay(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), id_branch, 0, false, "", resp, true);
        });
    }, [])

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        date_from2: moment().format("YYYY-MM-DD"),
        date_to2: moment().format("YYYY-MM-DD"),
        //este es el nuevo
        mDateRange1: moment().format("YYYY-MM-DD"),
        mDateRange2: moment().format("YYYY-MM-DD"),
        mSelectBranch: 0,
        nim: "",
        mSelectStatus: 0,
        mSelectMuestra: null,

    });


    const {
        //este es el pasado
        date_from, date_to, date_from2, date_to2,
        //este es el nuevo
        mDateRange1, mDateRange2, mSelectBranch, nim, mSelectStatus, mSelectMuestra

    } = formValues;


    const [validacionesSearch, setValidacionesSearch] = useState({
        mDateRange1valid: false,
        mDateRange2valid: false
    });

    //filter

    const [viewFormFilters, setViewFormFilters] = useState(false);

    const handleViewFilters = () => {
        setViewFormFilters(!viewFormFilters);
    }


    //#region status

    const [week, setweek] = useState([
        {
            value: 0,
            label: "Sin flitro"
        },
        {
            value: 1,
            label: "abierta"
        },
        {
            value: 2,
            label: "progeso"
        },
        {
            value: 3,
            label: "capturado"
        },
        {
            value: 4,
            label: "validado"
        },
        {
            value: 5,
            label: "parcial"
        },
        {
            value: 6,
            label: "liberado"
        },
        {
            value: 7,
            label: "enviado"
        },
    ]);

    const [specimen, setSpecimen] = useState([
        {
            value: null,
            label: "Todos"
        },
        {
            value: true,
            label: "Con Muestra"
        },
        {
            value: false,
            label: "Sin Muestra"
        }
    ])







    //#endregion status

    //#region Branch 

    const [branches, setBranches] = useState([]);

    const getBranches = async () => {

        let listTemp = await handleRequest("GET", "Branch/List", "Sucursal");

        if (listTemp !== null && listTemp.length > 0) {

            let newBranches = [];

            listTemp.forEach((element) => {
                newBranches.push({
                    value: element.id_branch,
                    label: element.name,
                });
            });
            setBranches(newBranches);
        }
    }

    useEffect(() => {
        if (branches.length > 0) {

            let search = branches.find(x => x.value == parseInt(id_branch))

            handleUpdateValues({
                ...formValues,
                mSelectBranch: search
            });
        }
    }, [branches]);





    //#endregion Branch

    //#region Sections 
    const [sections, setSections] = useState([]);
    const [sectiondefault, setSectiondefault] = useState(null);
    const [selectedSections, setselectedSections] = useState(null);


    const getSections = async () => {

        let listTemp = await handleRequest("GET", "Section/List/false", "Secciones");

        if (listTemp !== null && listTemp.length > 0) {

            let secciones_vinculadas = JSON.parse(localStorage.getItem("sections"));
            let secciones_guardadas = localStorage.getItem("filter_sections");
            if (secciones_guardadas !== undefined && secciones_guardadas !== null) {
                secciones_guardadas = JSON.parse(secciones_guardadas);
            }

            let secciones = [];
            let sectionmultiselect = [];

            listTemp.forEach(({ id_section, name }) => {
                secciones_vinculadas.forEach(function (element, index, secciones_vinculadas) {
                    if (element == id_section) {
                        let checkAux = true;
                        secciones.push({
                            id_section: id_section,
                            name: name,
                            check: checkAux,
                            label: name,
                            value: id_section
                        })
                        sectionmultiselect.push(
                            { name: name, code: id_section },
                        )
                    }
                });
            });
            setSections([...secciones]);
            setSectiondefault(sectionmultiselect);
            setselectedSections(sectionmultiselect);
            localStorage.setItem("filter_sections", JSON.stringify(secciones));
            return secciones;
        }
    }

    const changeCheckbox = (e) => {
        let array = sections;
        let sectionfind = [];
        array.map(obj => {
            if (e.some(x => x.code === obj.id_section)) {
                sectionfind.push(
                    { name: obj.name, code: obj.id_section },
                )
            }
            return obj;
        });
        let status = true;
        array.map(obj => {
            if (e.some(x => x.code === obj.id_section)) {
                obj.check = true;
            } else {
                obj.check = false;
            }
            return obj;
        });

        let filter_sections = array.filter(x => x.check === true);
        localStorage.setItem("sectionfind", JSON.stringify(filter_sections));
        setselectedSections(sectionfind);
        setSections(array);
    }



    //#endregion Sections

    //#region nuevo metodo



    const getWorkOrders = (e, search = false) => {
        e.preventDefault();

        if (!search) {
            if (validarBusqueda()) {
                setLoading(false);
                return;
            }
            let idBranch = 0;
            let idStatus = 0;
            let checkspecimen = "";

            if (formValues.mSelectBranch !== null) {
                if (formValues.mSelectBranch.value !== 0) {
                    idBranch = mSelectBranch.value;
                }
                else {
                    idBranch = 0;
                }
            }
            else {
                idBranch = 0;
            }

            if (formValues.mSelectStatus !== null) {
                if (formValues.mSelectStatus.value !== 0) {
                    idStatus = mSelectStatus.value;
                }
                else {
                    idStatus = 0;
                }
            }
            else {
                idStatus = 0;
            }

            if (formValues.mSelectMuestra !== null) {
                if (formValues.mSelectMuestra.value !== null) {
                    checkspecimen = "?specimen=" + mSelectMuestra.value;
                }
                else {
                    checkspecimen = "";
                }
            }
            else {
                checkspecimen = "";
            }


            getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (idBranch !== undefined ? idBranch : 0), (idStatus !== undefined ? idStatus : 0), search, checkspecimen);
        } else {

            if (nim.length <= 0) {
                alert("Debes ingresar minimo un caracter para buscar");
                return;
            }

            let idBranch = 0;
            let idStatus = 0;

            getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (idBranch !== undefined ? idBranch : 0), (idStatus !== undefined ? idStatus : 0), search);
        }

    }
    const getWorkOrdersDay = async (date1, date2, id_branch, id_estatus, search = false, alternativeUrl = "", arraySections = null, allSections = false, first = false) => {

        setLoading(true)

        let formulario = (allSections) ? arraySections : (arraySections === null ? sections.filter(x => x.check === true) : arraySections.filter(x => x.check === true));

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(formulario)
        };

        setLoading(true);

        if (nim !== "") {

        }

        if (date1._d !== undefined) {
            date1 = moment(date1._d).format("YYYY-MM-DD");
        }
        else {
            date1 = moment(date1).format("YYYY-MM-DD");
        }

        if (date2._d !== undefined) {
            date2 = moment(date2._d).format("YYYY-MM-DD");
        }
        else {
            date2 = moment(date2).format("YYYY-MM-DD");
        }

        var respuesta = {};

        if (search === false) {

            respuesta = await sendRequest(requestOptions, `WorkOrder/ListPending/${date1},${date2},${id_branch},${id_estatus}${alternativeUrl}`);
            ////console.log(respuesta);
        }

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                setDataPending(respuesta.data)
            }
            else {
                if (!first) {
                    //createSweet("error", "info", "No existen resultados para su busqueda");
                }
                setDataPending({});
            }
            setLoading(false)

        }
    }



    //#endregion nuevo metodo


    //#region PDF AND EXCEL
    const downdloadpdf = async () => {

        let token = localStorage.getItem("token");
        let mfrom = (date_from2 === moment().format("YYYY-MM-DD")) ? moment().format("YYYY-MM-DD") : moment(date_from2).format("YYYY-MM-DD");
        let mto = (date_to2 === moment().format("YYYY-MM-DD")) ? moment().format("YYYY-MM-DD") : moment(date_to2).format("YYYY-MM-DD");

        await fetch(`/api/WorkOrder/DownloadReportNimNotValidation/true/false?date_from=${mfrom}&date_to=${mto}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte_de_Ordenes.pdf")
                document.body.appendChild(link)
                    ;
                link.style.display = 'none';
                link.click();

            })
    }

    const downdloadExcel = async () => {

        let token = localStorage.getItem("token");
        let mfromm = (date_from2 === moment().format("YYYY-MM-DD")) ? moment().format("YYYY-MM-DD") : moment(date_from2).format("YYYY-MM-DD");
        let mtoo = (date_to2 === moment().format("YYYY-MM-DD")) ? moment().format("YYYY-MM-DD") : moment(date_to2).format("YYYY-MM-DD");

        await fetch(`/api/WorkOrder/DownloadReportNimNotValidation/false/true?date_from=${mfromm}&date_to=${mtoo}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/vnd.ms-excel"

            })
        })

            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte_De_Ordenes.xlsx")
                document.body.appendChild(link)
                    ;
                link.style.display = 'none';
                link.click();

            })
    }


    //#endregion PDF AND EXCEL


    const validarBusqueda = () => {

        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.mDateRange1 === '' || formValues.mDateRange1 === '0000-00-00') {
            newValidaciones = {
                ...newValidaciones,
                mDateRange1valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                mDateRange1valid: false
            };
        }

        if (formValues.mDateRange2 === '' || formValues.mDateRange2 === '0000-00-00') {
            newValidaciones = {
                ...newValidaciones,
                mDateRange2valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                mDateRange2valid: false
            };
        }

        setValidacionesSearch(newValidaciones);

        return statusValidacion;
    }

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }


    return {
        //DATOS PASADOS
        date_from, date_to, handlePickerValues, downdloadExcel, downdloadpdf, date_from2, date_to2,
        //branch and sections 
        branches, sections, selectedSections, sectiondefault, changeCheckbox,

        getWorkOrders, mDateRange1, mDateRange2, dataPending, loading, handleSelectValues, mSelectBranch, week,
        mSelectStatus, specimen, mSelectMuestra, handleViewFilters, viewFormFilters

    }
}
