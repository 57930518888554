import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateProductionPrice, ModalUpdateProductionPrice, ModalDeleteProductionPrice } from '../../constant';
import { useForm } from '../forms/useForm';
import moment from 'moment';


export const useExamProductionCost = () => {

    const { examId } = useParams();

    const history = useHistory();

    useEffect(() => {

        getProductionCost();

    }, [history.location]);

    const [productionPrices, setProductionPrices] = useState([]);
    const [productionPriceId, setProductionPriceId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [tabGeneral, setTabGeneral] = useState('1');
    const changeTabGeneral = (number) => {
        setTabGeneral(number);
    }

    const [examName, setExamName] = useState("");
    const [sectionId, setSectionId] = useState(0);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        cost: 0.00
    });

    const { cost } = formValues;

    const [validationsProduction, setValidationsProduction] = useState({
        cost_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getExam = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + examId);

        if (respuesta.code === 200) {
            setSectionId(respuesta.data.id_section);
            setExamName(`${respuesta.data.name} [${respuesta.data.code}] `);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }, [history]);

    useEffect(() => {

        getExam();

    }, [getExam])

    const getProductionCost = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let newProductionCost = [];

        const respuesta = await sendRequest(requestOptions, "ExamProductionCost/List/" + examId);

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((obj) => {
                    newProductionCost.push({
                        id_exam_production_cost: obj.id_exam_production_cost,
                        id_exam: obj.id_exam,
                        cost: '$ ' + number_format(obj.cost, 2),
                        from: (obj.from !== null) ? moment(obj.from).format("YYYY-MM-DD HH:mm:ss") : "",
                        to: (obj.to !== null) ? moment(obj.to).format("YYYY-MM-DD HH:mm:ss") : "Sin definir"
                    });
                });

                setProductionPrices(newProductionCost);
            }
            else {
                setProductionPrices([]);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getProductionCost);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            cost: 0.00
        });

        setValidationsProduction({
            cost_valid: false
        });

        setProductionPriceId(0);
        setMethod("create");
        setModalTitle(ModalCreateProductionPrice);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validationFormProduction()) {
            setLoading(false);
            return;
        }

        let data = {
            id_exam_production_cost: productionPriceId,
            id_exam: examId,
            ...{ ...formValues }
        };

        let body = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: body
        };

        const respuesta = await sendRequest(requestOptions, "ExamProductionCost/Create");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Se agrego correctamente");
            getProductionCost();

            reset();
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_exam_production_cost) => {
        let foundProductionCost = productionPrices.find(p => p.id_exam_production_cost === parseInt(id_exam_production_cost));

        if (foundProductionCost !== null) {
            handleUpdateValues({
                cost: foundProductionCost.cost
            });

            setValidationsProduction({
                cost_valid: false
            });

            setProductionPriceId(id_exam_production_cost);
            setMethod("update");
            setModalTitle(ModalUpdateProductionPrice);
            toggle();
        }
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(false);

        if (validationFormProduction()) {
            setLoading(false);
            return;
        }

        let body = {
            id_exam_production_cost: productionPriceId,
            id_exam: examId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        let respuesta = await sendRequest(requestOptions, "ExamProductionCost/Update");

        if (respuesta.code === 200) {
            getProductionCost();
            createSweet("update", "info", "Precio modificado");
            reset();
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_exam_production_cost) => {
        let foundProductionCost = productionPrices.find(p => p.id_exam_production_cost === parseInt(id_exam_production_cost));

        if (foundProductionCost !== null) {

            handleUpdateValues({
                cost: foundProductionCost.cost
            });

            setProductionPriceId(id_exam_production_cost);
            setMethod("delete");
            setModalTitle(ModalDeleteProductionPrice);
            toggle();
        }
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: "DELETE"
        };

        let respuesta = await sendRequest(requestOptions, `ExamProductionCost/${productionPriceId}`);

        if (respuesta.code === 200) {
            getProductionCost();

            setLoading(false);
            createSweet("delete", "info", "Se elimino correctamente");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveDelete);
        // }
    }

    const validationFormProduction = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.cost < 0) {
            newValidations = {
                ...newValidations,
                cost_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                cost_valid: false
            };
        }

        setValidationsProduction(newValidations);

        return statusValidation;
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    return {
        examId, examName, sectionId,
        productionPrices, method, handleInputChange, validationsProduction,
        cost,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        tabGeneral, changeTabGeneral
    }
}
