import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { TableWorkOrderItem } from './TableWorkOrderItem';

export const TableWorkOrder = (props) => {

    const { items, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
        changeValueTimeSampling, times, handleViewExamsProfile, listid, enable_tracking_toma, removeExamProfileByCampaign, handleChangeExternalLaboratories } = props;


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }

    let wTest = [];
    let wCampaign = [];
    let wCampaignTmp = [];
    let wTestReferenced = [];

    if (items.length > 0) {
        wTest = items.filter(x => x.is_campaign === false && x.is_referenced === false);
        wCampaignTmp = items.filter(x => x.is_campaign === true);
        wTestReferenced = items.filter(x => x.is_referenced === true)
    }

    if (wCampaignTmp.length > 0) {

        wCampaignTmp.forEach(c => {
            let _find = wCampaign.find(x => x.id_common_discount === c.id_common_discount)

            if (_find === undefined) {
                wCampaign.push({
                    id_common_discount: c.id_common_discount,
                    name_campaign: c.name_campaign,
                    list_test: [c]
                });
            }
            else {
                _find.list_test.push(c);
            }
        });
    }



    return (
        <Table striped className="table-sm"  >
            <thead className="">
                <tr className='border-bottom-info' >
                    <th style={{ width: "10px" }}><b>Código</b> </th>
                    <th style={{ textAlign: "-webkit-center" }}> <b>Exámen / Perfil</b> </th>
                    <th style={{ width: "10px" }}><b>Abreviatura</b> </th>
                    <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Costo</b> </th>
                    <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>¿Es urgente el exámen?</b> </th>

                    <th className={enable_tracking_toma == true || enable_tracking_toma == "True" ? "d-none" : ""}><b>¿Cuenta con la muestra?</b> </th>
                    <th><b>¿Remitida?</b> </th>
                    {
                        checkTypeClient === 3 &&
                        <th> <b>Convenio</b> </th>
                    }
                    <th><b>Acciones</b> </th> 
                </tr>
            </thead>
            <tbody className="f-13 text-left border-bottom-info" style={{ paddingBottom: 30 }} >
                {
                    wTest.length > 0 && wTest.map((itemTest, index) => {

                        let tmp_key = Date.now() + 1.5;
                        return (
                            <TableWorkOrderItem key={tmp_key + index}
                                {
                                ...{
                                    itemTest, index, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
                                    changeValueTimeSampling, times, handleViewExamsProfile, listid, enable_tracking_toma, removeExamProfileByCampaign,
                                    uniqueKey: tmp_key
                                }
                                }
                            />
                        )
                    })
                }
                {
                    wCampaign.length > 0 && wCampaign.map((itemCampaign, i) => {
                        return (
                            <>
                                {
                                    itemCampaign.list_test.length > 0 && itemCampaign.list_test.map((itemTest, index) => {
                                        let tmp_key_compaign = Date.now() + 1.5;
                                        return (
                                            <TableWorkOrderItem key={tmp_key_compaign + index}
                                                rowsSpan={itemCampaign.list_test.length}
                                                availableRowsSpan={true}
                                                availableByIndex={index}
                                                nameCampaign={itemCampaign.name_campaign}
                                                {
                                                ...{
                                                    itemTest, index: Date.now(), removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
                                                    changeValueTimeSampling, times, handleViewExamsProfile, listid, enable_tracking_toma, removeExamProfileByCampaign
                                                }
                                                }
                                            />
                                        )
                                    })
                                }
                            </>
                        )
                    })
                }                
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={9}>Estudios de referencia</td>
                </tr>
                {
                    wTestReferenced.length > 0 && wTestReferenced.map((itemReferenced, indexRef) => {
                        let tmp_key_ref = Date.now() + 1.5;
                        return (
                            <TableWorkOrderItem key={tmp_key_ref + indexRef}
                                {
                                    ...{
                                        itemTest: itemReferenced, index: indexRef, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, 
                                        handleCheckUrgent, changeSelectSamplings, changeValueTimeSampling, times, handleViewExamsProfile, listid, enable_tracking_toma, 
                                        removeExamProfileByCampaign, uniqueKey: tmp_key_ref, handleChangeExternalLaboratories
                                    }
                                }
                            />         
                        )
                    })
                }
            </tfoot>
        </Table>
    )
}

