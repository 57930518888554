import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { LabelBrandMachine, LabelModelMachine, PlaceholderBrandMachine, PlaceholderModelMachine, ModalButtonCancel } from '../../constant';

export const FormMachine = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, brand, model, button
    } = props;

    const {
        brand_valid, model_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">{ LabelBrandMachine } <span className='f-16' style={ { color: " #0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="brand"
                                onChange={handleInputChange}
                                value={brand}
                                autoComplete="nope"
                                invalid={brand_valid}
                                className="form-control form-control-sm input-air-primary" 
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">{ LabelModelMachine } <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="model"
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={model_valid}
                                value={model}
                                className="form-control form-control-sm input-air-primary" 

                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody> <br />
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}
