import moment from 'moment';
import React from 'react';
import {Modal, ModalHeader, Form,  Input, Col,Button,Row,Label,FormGroup,ModalFooter, ModalBody, FormFeedback, Table} from 'reactstrap';
import 'animate.css';

export const ModalQuoter = (props) => {

    const {

        modal, toggle, modalTitle, method, loading, handleGetQuoter, code_quoter, date_quoter,
        handleInputChangeQuoter, validationsQuoter, listQuoter, searchQuoterByCode,
        enableViewTest, viewTestQuoter, handleViewTest, handleCloseViewTest,
        
    }= props;

    const {
        code_quoter_valid,
        date_quoter_valid
    } = validationsQuoter;

    return (
        <Modal isOpen={modal} toggle={toggle} keyboard={false} centered={true}  className='modal-lg'  >
            <ModalHeader className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <Form  onSubmit={handleGetQuoter}>
                <ModalBody>
                    <Row>
                        <Col sm="6" md="6" lg="6" xl="6" className="box-col-6  pr-0 file-spacing  p-0 m-0 t-0 ">
                            <div className="file-sidebar  p-0 m-0  t-0  ">                        
                                <Label className="col-12">Ingresa el código o el nombre del paciente</Label>
                                <FormGroup className="col-md-12">
                                    <Input  name="code_quoter" invalid={code_quoter_valid}  className='form-control form-control-sm input-air-primary' autoComplete='off' value={code_quoter} onChange={handleInputChangeQuoter} />
                                    <FormFeedback>Ingresa más de tres caracteres</FormFeedback>
                                </FormGroup>                                                                             
                            </div>
                        </Col>
                        <Col sm="6" md="3" lg="3" xl="3">
                            <FormGroup>
                                <Label>Fecha</Label>
                                <Input type='date' name='date_quoter' invalid={date_quoter_valid} value={date_quoter} onChange={handleInputChangeQuoter} min={moment().format("DD/MM/YYYY")} className='form-control form-control-sm  input-air-primary' />
                                <FormFeedback>Ingresa una fecha</FormFeedback>
                            </FormGroup> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" style={{"textAlign": "-webkit-center"}}>
                            <Button type="submit" outline color="primary"  size="xs" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-align: -webkit-right;" : "text-align: -webkit-right;mt-1")} ><i className= "icon-search"></i> &nbsp;Buscar</Button>
                        </Col>  
                    </Row>
                    <Row className={`${!enableViewTest ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={enableViewTest}>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <div className='table-responsive'>
                                <Table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Paciente</th>
                                            <th>Fecha</th>
                                            <th>Pruebas</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listQuoter.length > 0 && listQuoter.map((obj, i) => {
                                                return (
                                                    <tr key={`tr_quoter-${obj.id_quoter}`}>
                                                        <td>{obj.code}</td>
                                                        <td>{obj.name+" "+obj.paternal_surname+" "+obj.maternal_surname}</td>
                                                        <td>{obj.date}</td>
                                                        <td style={{ width: "10px", whiteSpace: "break-spaces" }}>
                                                            <Button type='button' size='xs' outline color='primary' onClick={() => handleViewTest(obj.id_quoter)}>
                                                                <i className="fa fa-flask"></i>
                                                            </Button>
                                                        </td>
                                                        <td style={{ width: "10px", whiteSpace: "break-spaces" }}>
                                                            <Button type='button' size='xs' outline color='primary' onClick={() => searchQuoterByCode(obj.code)}>
                                                                Seleccionar
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row className={`${enableViewTest ? 'animate__animated animate__flipInX m-t-10' : 'd-none'} `}>
                        <Col sm="12" md="12" lg="12" xl="12" className='m-b-10' style={{"textAlign": "-webkit-right"}}>
                            <Button type="button" color="warning"  size="xs" className={"text-align: -webkit-right;mt-1"} onClick={() => handleCloseViewTest()}>
                                <i className="fa fa-times"></i> &nbsp;Cerrar
                            </Button>                            
                        </Col>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <div className='table-responsive'>
                                <Table className='table table-sm'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2} className="text-center">
                                                <h5>Listado de exámenes / perfiles</h5>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Código</th>
                                            <th>Nombre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            viewTestQuoter.id_quoter !== undefined && viewTestQuoter.listProfilePrices.map(obj => {
                                                return (
                                                    <tr key={`tr-profile-${obj.id_profile}`}>
                                                        <td><b>{obj.code}</b></td>
                                                        <td><b>{obj.name}</b></td>
                                                    </tr>
                                                )
                                            })                                            
                                        }
                                        {
                                            viewTestQuoter.id_quoter !== undefined && viewTestQuoter.listExamPrices.map(obj => {
                                                return (
                                                    <tr key={`tr-exam-${obj.id_exam}`}>
                                                        <td><b>{obj.code}</b></td>
                                                        <td><b>{obj.name}</b></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <br />
                <ModalFooter>
                    <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Form >
           {/* {
            <FormQuoter
                { 
                    ...{
                        toggle, loading, handleGetQuoter, code_quoter, handleInputChangeQuoter, validationsQuoter
                    }
                }
            />
           } */}
        </Modal>   
    )
 
}
