import React from 'react'
import { Col, Form, FormGroup, Label, ModalBody, Row, Input, FormFeedback, FormText, ModalFooter, Button } from 'reactstrap';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ModalButtonCancel } from '../../constant';
 
export const FormProductionCostMicrobiology = (props) => {

    const { 
        toggle, loading, handleInputChange, validationsProduction, methodAction,
        cost,  button
    } = props;

    const {
        cost_valid
    } = validationsProduction;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label>Costo</Label>
                            <Input className="form-control" type="number" step="any" placeholder="$ 0.00" name="cost" value={cost} onChange={handleInputChange} invalid={cost_valid} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>            
            </ModalBody>
            <ModalFooter>
            <Button
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Form>
    )
}
