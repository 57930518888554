import React from 'react'
import { Col, Form, FormGroup, FormText, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';

export const FormUser = (props) => {

    const {
        toggle, loading, handleInputChange, handleSelectValues, validaciones, methodAction, 
        id_helen_users, id_external, name, paternal_surname, maternal_surname,username, job, 
        card_code, button, areas, departaments, 
    } = props;

    const {
        name_valid,
        paternal_surname_valid,
        maternal_surname_valid,
        areas_valid,
        username_valid,
    } = validaciones;


    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary" 
                            name="name" value={name} invalid={name_valid} onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary" 
                                name="paternal_surname" value={paternal_surname} invalid={paternal_surname_valid} onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Apellido materno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" autoComplete="off"  className="form-control form-control-sm input-air-primary" 
                                name="maternal_surname" value={maternal_surname} invalid={maternal_surname_valid} onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Puesto <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                            <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary" 
                                name="job" value={job} onChange={handleInputChange} />

                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Usuario<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary" 
                                    name="username" value={username} invalid={username_valid} onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Código de tarjeta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary" 
                                name="card_code" value={card_code} onChange={handleInputChange} />

                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <hr />
                    </Col>
                    <Col sm={12}>
                    <Label className='col-form-label'>Acceso a los departamentos <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                    <Select
                            isMulti
                            name="areas"
                            defaultValue={areas}
                            onChange={(e) => handleSelectValues(e, "areas")}
                            options={departaments}
                            //className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            //isSearchable={true}
                            //invalid={areas_valid}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />
                        <FormText color='danger' hidden={!areas_valid}>Debes seleccionar almenos una opción</FormText>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} disabled={loading}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
