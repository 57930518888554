import React from 'react'
import { Button, Card, CardBody, Input} from 'reactstrap';
import { ModalMicSiteArea } from '../../../components/micSiteArea/ModalMicSiteArea';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useMicSiteArea } from '../../../hooks/micSiteArea/useMicSiteArea';


export const IndexMicSiteArea = () => {

    const {
        sitesAreas, method, validaciones,
        name, id_mic_site, sites,
        handleInputChange, loading, handleSearchSiteArea,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    } = useMicSiteArea();

    //////console.log(id_departament);
    //////console.log(rooms);

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de áreas
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSiteArea} />
                        </div>
                    </div>
                    {
                        sitesAreas.length === 0 ?
                         <div style={{ textAlign: "-webkit-center" }}>
                         <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                         <br /> <p className="f-18">{"No hay Registrado ninguna creación de un área"}</p>
                         </div>
                         :
                           <div className="table-responsive">
                        <TableStriped
                            notMaped={[ "visibleTable","id_mic_site"]}
                            methodsActions={true}
                            cabeceras={["Nombre","Sitio"]}
                            items={sitesAreas}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div> 
                    }
                   
                </CardBody>
            </Card>
            <ModalMicSiteArea
                {...{
                    method, validaciones,
                    name, id_mic_site, sites,
                    handleInputChange, loading,
                    toggle, modal, modalTitle,
                    saveCreate, saveUpdate, saveDelete,
                    nextPage, previousPage, goToPage, totalPageCount, currentPage,
                }}
            />
        </>
    )
}
