import React from 'react'
import { Table } from 'reactstrap';

export const DataCurvePrices = ({ data }) => {

    return (
        <Table>
            {
                data.length > 0
                    ?
                    <thead>
                        <tr>
                            <td>Curva</td>
                            <td>Precio</td>
                        </tr>
                    </thead>
                    :
                    ""
            }
            {
                data.length > 0
                    ?
                    <tbody>
                        {
                            data.map((obj, index) => {
                                return (
                                    <tr key={'tdCurves-' + index}>
                                        <td>{obj.name_profile + " - Curva " + obj.number_sampling}</td>
                                        <td>$ {obj.price}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    : ""
            }
        </Table>
    )
}
