import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col,Row } from 'reactstrap';

export const ModalInformation = (props) => {

    const {
        modalInformation, toggleInformation, validCode, password, handleInputChange, getInformationSpecialDiscount,validName,valid_percentage
    } = props;

    return (
        <Modal isOpen={modalInformation} centered={true} toggle={toggleInformation}>
            <ModalHeader className="bg-primary" toggle={toggleInformation}>Visualizar Código</ModalHeader>
            <ModalBody>
                <b className='f-14' >Estas apunto de mostrar información protegida de este descuento especial</b>
                <br /> 
                <Col sm="12" className='p-l-0 p-r-0' style={{textAlign :"center"}} > <br /> <br />
                <Row>
                    <Col sm="3">
                    <b htmlFor="">Codigo</b> <br /> 
                    <label htmlFor=""> {validCode}</label>
                    </Col>
                    <Col sm="6">
                    <b htmlFor="">Autorizador por:</b> <br />
                    <label htmlFor=""> {validName}</label>
                    </Col>
                    <Col sm="3">
                    <b htmlFor="">Porcentaje</b> <br />
                    <label htmlFor=""> {valid_percentage}%</label>
                    </Col>
                </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" onClick={getInformationSpecialDiscount}>Aceptar</Button>
                <Button outline color="danger" onClick={toggleInformation}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
