import { TableStriped } from '../../../components/tables/TableStriped';
import React, { useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Row, Input, } from 'reactstrap';

export const ModalTestFormula = (props) => {

    const {
        toggleTest: toggle, modalTest, loadingTest, testearFormula, compute,
        init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults
    } = props;

    const [checktype, setChecktype] = useState(1);

    const changeCheckType = (type) => {
        resetResults();
        setChecktype(type);
        resetTest();
    }

    return (
        <Modal isOpen={modalTest} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle}>
                Probar fórmula
            </ModalHeader>
            <Form role="form" onSubmit={testearFormula}>
                <ModalBody>
                    <Row>
                        <FormGroup className="mb-3 col-12">
                            <h4>Fórmula: {compute}</h4>
                        </FormGroup>
                        <FormGroup check className="mb-3 col-5 offset-1">
                            <Label check>
                                <Input type="radio" checked={checktype} name="checkType" onChange={() => changeCheckType(1)} />
                                Rango de valores
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mb-3 col-5 offset-1">
                            <Label check>
                                <Input type="radio" checked={!checktype} name="checkType" onChange={() => changeCheckType(0)} />
                                Valor único
                            </Label>
                        </FormGroup>
                        <FormGroup className={`mb-3 col-4 ${checktype ? "" : "d-none"}`}>
                            <Label className="text-sm">Rango inicial</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Rango inicial"
                                type="number"
                                name="init_range"
                                value={init_range}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputTest}
                            />
                        </FormGroup>
                        <FormGroup className={`mb-3 col-4 ${checktype ? "" : "d-none"}`}>
                            <Label className="text-sm">Rango final</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Rango final"
                                type="number"
                                name="end_range"
                                value={end_range}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputTest}
                            />
                        </FormGroup>
                        <FormGroup className={`mb-3 col-4 ${checktype ? "" : "d-none"}`}>
                            <Label className="text-sm">Intervalo</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Intervalo"
                                type="number"
                                name="increment"
                                value={increment}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputTest}
                            />
                        </FormGroup>
                        <FormGroup className={`mb-3 col-6 ${checktype ? "d-none" : ""}`}>
                            <Label className="text-sm">Valor de X</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Valor de X"
                                type="number"
                                name="value"
                                value={value}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputTest}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3 col-12">
                            <h3>Resultados</h3>
                            <ul>
                                {
                                    <TableStriped
                                        indices={false}
                                        methodsActions={false}
                                        cabeceras={["Valor", "Resultado"]}
                                        items={results}
                                    />
                                }
                            </ul>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        type="submit"
                        className={loadingTest ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                        Probar fórmula
                    </Button>
                    <Button color="secondary" type="button" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
