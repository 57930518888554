import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteIndications } from './DeleteIndications';
import { FormIndications } from './FormIndications';

export const ModalIndications = (props) => {

    const {

        method, modalTitle, loading, modal, toggle,
        saveCreate,handleInputChange,saveUpdate,saveDelete,
        id_exam_indication, indication,validaciones
        

    }= props;

    return (
        <Modal isOpen={modal} toggle={toggle}  backdrop='static' keyboard={false} centered={true}>
        <ModalHeader toggle={toggle}  className="bg-primary">
            {modalTitle}
        </ModalHeader>
        {
            method === "create"
                ? <FormIndications
                    button="Guardar"
                    {...{ toggle, loading, handleInputChange,validaciones,methodAction: saveCreate}}
                />
                : (method === "update")

                    ?
                    <FormIndications
                        button="Actualizar"
                        {...{
                            toggle, loading, handleInputChange,id_exam_indication, indication,validaciones,  methodAction: saveUpdate,
                        }}
                    />
                    :
                    <DeleteIndications
                        {...{ toggle, loading,indication,saveDelete}}
                    />
                    
                    
        }
    </Modal>
       
    )
}
