import React from 'react'
import Board ,{moveCard } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import './estilocard.css'

export const ComponenteBoard = ({obj,objj}) => {
  return (
    <Board
    initialBoard={obj}
    onCardDragEnd={objj}
    />
  )
}
