import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteArea } from './DeleteArea';
import { FormArea } from './FormArea';

export const ModalArea = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, id_external,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormArea
                        button={"Crear área"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormArea
                            button={"Actualizar área"}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, id_external }} />
                        :
                        <DeleteArea {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )
}
