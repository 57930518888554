import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button, FormText } from "reactstrap";
import Select from 'react-select';

export const FormCatMachineSanitary = (props) => {
    const {
        toggle, loading, handleInputChange, validaciones, descripcion,clave,
        methodAction, button,
    } = props;

    const {
        descripcion_valid,
    }=validaciones

    return (
        <Form className='' role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className='col-form-label'>Descripción del Equipo/Consumible <span className='f-16' style={{color:"#0079C7"}}>*</span></Label>
                            <Input 
                                className='form-control form-control-sm input-air-primary'
                                type='text'
                                name="descripcion"
                                onChange={handleInputChange}
                                value={descripcion}
                                autoComplete="off"
                                invalid={descripcion_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label className='col-form-label'>Clave:</Label>
                            <Input 
                                className='form-control form-control-sm input-air-primary'
                                type='text'
                                name="clave"
                                onChange={handleInputChange}
                                value={clave}
                                autoComplete="off"
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size='sm' className={loading ? "disabled progress-bar-animated progresss-bar-striped":""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type='button' onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}