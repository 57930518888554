import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, CardBody, Label, FormGroup, Input, } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { TableStriped } from '../../components/tables/TableStriped';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { useInvoiceCFDI } from '../../hooks/invoice/useInvoiceCFDI';
import './StyleInvoiceClient.css';





export const IndexInvoiceCFDI = () => {

  const {
    metaData, goNextPage, goPreviousPage, goToPage, actualPage, totalPages,
    rfc, date_start, date_end, cancellation, type_reception, handleInputChange,
    handleFilters, handlePickerValues,
    //CARDS
    ingress, egress, cCfdi, cCancellation, tCancellation, formatter


  } = useInvoiceCFDI();




  return (
    <Fragment>
      {/* <Breadcrumb parent="Catálogos" title="Listado CFDI" className="m-b-0" /> */}
      <Container fluid={true}>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Row>
            <Col xs="12" sm="6" md="6" lg="2" xl="3">
              <Card className='shadow'>
                <Row>
                  <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-1'>
                    {
                      type_reception === "R" ?
                        <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/TypeReception.svg")} />
                        :
                        <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/TypeReception.svg")} />
                    }
                  </Col>
                  <Col xs="8" sm="8" md="8" lg="8" xl="8">
                    <br />
                    {
                      type_reception === "R" ?
                        <h6 className=' text-left labelEmitos'>{"Recibidos"}</h6>
                        :
                        <h6 className=' text-left labelEmitos'>{"Emitidos"}</h6>
                    }
                    {
                      type_reception === "R" ?
                        <h6 className="text-left"> {cCfdi}</h6>
                        :
                        <h6 className="text-left"> {cCfdi}</h6>
                    }
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3" xl="3">
              <Card className='shadow'>
                <Row>
                  <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                  </Col>
                  <Col xs="8" sm="8" md="8" lg="8" xl="8">
                    <br />
                    <h6 className=' text-left labelEgress'>{"Egreso"}</h6>
                    <h6 className="text-left"> {formatter.format(egress)}</h6>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3" xl="3">
              <Card className='shadow'>
                <Row>
                  <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Ingress.svg")} />
                  </Col>
                  <Col xs="8" sm="8" md="8" lg="8" xl="8">
                    <br />
                    <h6 className=' text-left labelIngress'>{"Ingreso"}</h6>
                    <h6 className="text-left"> {formatter.format(ingress)}</h6>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3" xl="3">
              <Card className='shadow'>
                <Row>
                  <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Cancellation.svg")} />
                  </Col>
                  <Col xs="8" sm="8" md="8" lg="8" xl="8">
                    <br />
                    <h6 className=' text-left labelCancellation'>{"Canceladas"}</h6>
                    <h6 className="text-left"> {cCancellation}</h6>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* cambiar margen minimo  imagen rojo //impresora//razon socail//tarifacrio */}
        <Col>
          <Card>
            <CardBody>
              <div className="ribbon ribbon-clip ribbon-primary">{"Indicadores"}</div>
              <Col sm="12">
                <Row>
                  <Col xs="6" sm="4" md="3" lg="" xl="2">
                    <Label>RFC</Label> <br />
                    <FormGroup className="m-0">
                      <Input
                        type='text'
                        name="rfc"
                        onChange={handleInputChange}
                        value={rfc}
                        className="form-control form-control-sm input-air-primary"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="4" md="3" lg="" xl="2">
                    <Label>Fecha inicio</Label> <br />
                    <FormGroup className="col-md-12 p-r-0 p-l-0 ">
                      <Datetime
                        inputProps={{
                          placeholder: 'dd/mm/yyyy',
                          name: 'from',
                          autoComplete: "off",
                          className: "form-control form-control-sm input-air-primary"
                        }}
                        timeFormat={false}
                        value={date_start}
                        locale="es"
                        onChange={(e) => handlePickerValues(e, "date_start")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="4" md="3" lg="" xl="2">
                    <Label>Fecha fin</Label> <br />
                    <FormGroup className="col-md-12 p-l-0 p-r-0 ">
                      <Datetime
                        inputProps={{
                          placeholder: 'dd/mm/yyyy',
                          name: 'from',
                          autoComplete: "off",
                          className: "form-control form-control-sm input-air-primary"
                        }}
                        timeFormat={false}
                        value={date_end}
                        locale="es"
                        onChange={(e) => handlePickerValues(e, "date_end")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="4" md="3" lg="" xl="2">
                    <Label>Cancelación</Label> <br />
                    <FormGroup className="m-0">
                      <Input
                        type='select'
                        className="form-control form-control-sm input-air-primary"
                        name="cancellation"
                        value={cancellation}
                        onChange={handleInputChange}
                      >
                        <option value={""}>{"-----"}</option>
                        <option value={true}>{"Si"}</option>
                        <option value={false}>{"No"}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="4" md="3" lg="2" xl="2">
                    <Label>Tipo de recepción</Label> <br />
                    <FormGroup className="m-0">
                      <Input
                        type='select'
                        className="form-control form-control-sm input-air-primary"
                        name="type_reception"
                        onChange={handleInputChange}
                        value={type_reception}
                      >
                        <option value="E">{"Emitidos"}</option>
                        <option value="R">{"Recibidos"}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="4" md="3" lg="2" xl="2" className='p-1' style={{ textAlign: "-webkit-center" }} > <br />
                    <Button color='primary' size='SM' outline type='button' onClick={handleFilters}>Buscar</Button>
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </Col>
              <div className="table-responsive">
                {
                  metaData.length === 0 ?
                    <div style={{ textAlign: "-webkit-center" }}>
                      <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "24%" }} alt="" />   <br />
                      <br /> <p className="f-18">{"No se ha registrado ninguna busqueda."}</p>
                    </div>
                    :
                    <TableStriped
                      responsive={true}
                      notMaped={["visibleTable", "fecha_cancelacion",]}
                      methodsActions={false}
                      cabeceras={["UUID", "RFC Emisor", "RFC receptor", "Nombre o Razon Social Emisor", "Nombre o Razon Social Receptor", "Monto Total", "tipo de CFDI", "Estado", "Fecha", "Status"]}
                      items={metaData}
                      nextPage={goNextPage}
                      previousPage={goPreviousPage}
                      totalPageCount={totalPages}
                      currentPage={actualPage}
                      goToPage={goToPage}
                    />
                }
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>

  )
}
