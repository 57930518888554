import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row, Button,Input } from 'reactstrap';
import { useUnits } from '../../hooks/units/useUnits';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalUnit } from '../../components/units/ModalUnit';


export const IndexUnits = (props) => {
    const {
        units,
        method,
        handleCreate,
        unit,
        abbreviation,
        handleInputChange,
        toggle,
        modal,
        loading,
        saveCreate,
        handleUpdate,
        saveUpdate,
        handleDelete,
        saveDelete,
        nextPage, 
        previousPage, 
        goToPage, 
        totalPageCount, 
        currentPage,
        handleSearchUnit
    } = useUnits();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de unidades
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchUnit} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        methodsActions={true}
                                        notMaped={["visibleTable"]}
                                        cabeceras={["Nombre", "Abreviatura"]}
                                        items={units}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalUnit
                {...{ modal, toggle, loading, method, unit, abbreviation, handleInputChange, saveCreate, saveUpdate, saveDelete }}
            />
        </Fragment>
    )
}
