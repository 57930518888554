import React from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';
import './styleDashboard.css';

//Comentario

export const ModalSendEmail = (props) => {

    const {
        modalCheckEmail: modal, toggleExamEmail: toggle, sendResultEmail,
        sendResultForEmailDoctor, sendTo,
        //doctor
        respuestaAPI,
    } = props;
    //////console.log(respuestaAPI)

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true}  >
            <ModalHeader toggle={toggle} className="bg-primary">
                Atención
            </ModalHeader>
            <ModalBody>
                {
                    sendTo.send_doctor && sendTo.send_patient ? <>
                        <Col className='col-12'>
                            <Row>
                                <p className='txt-secondary f-16'>El paciente solicitó enviar a: </p>&nbsp;
                                <p className='f-16' style={{ fontWeight: 'bold' }}>doctor, paciente</p>
                            </Row>
                        </Col>
                    </>
                        : <></>
                }
                {
                    sendTo.send_doctor && sendTo.send_patient === false ? <>

                        <Col className='col-12'>
                            <Row>
                                <p className='txt-secondary f-16'>El paciente solicitó enviar a: </p>&nbsp;
                                <p className='f-16' style={{ fontWeight: 'bold' }}>doctor</p>
                            </Row>
                        </Col>

                    </>
                        : <></>
                }
                {
                    sendTo.send_patient && sendTo.send_doctor === false ? <>
                        <Col className='col-12'>
                            <Row>
                                <p className='txt-secondary f-16'>El paciente solicitó enviar a: </p>&nbsp;
                                <p className='f-16' style={{ fontWeight: 'bold' }}>paciente</p>
                            </Row>
                        </Col>
                    </>
                        : <></>
                }


                <h6 className='text-center txt-secondary' >¿Estás seguro de que deseas enviar los resultados?</h6>
                <p className='text-center'>Ten en cuenta que al realizar esta acción, se enviara al correo del paciente.</p>
            </ModalBody>
            <ModalFooter>


                {
                    respuestaAPI ? <>

                        <UncontrolledDropdown color='danger' className='p-0 m-0'>
                            <DropdownToggle size='sm' block outline color='danger'>
                                &nbsp; Enviar Resultados &nbsp;
                            </DropdownToggle>
                            <DropdownMenu >
                                <DropdownItem header>Selecciona una opción</DropdownItem>
                                <DropdownItem onClick={() => sendResultEmail(false)}>{"Enviar al Paciente"}</DropdownItem>
                                <DropdownItem onClick={() => sendResultEmail(true)}>{"Enviar al Doctor"}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </> : <>
                        <Button block={true} size="sm" onClick={() => sendResultEmail(false)} outline color="danger">
                            Enviar resultados
                        </Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

}
