import React, { Fragment, useRef, useEffect, useState, useMemo } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row, Label, Table, FormText, FormGroup } from 'reactstrap'
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CanvasComponent } from '../../../components/canvas/CanvasComponent';
import { ItemSample } from './ItemSample';
import { ConfigServer } from '../../../data/config';
import { useForm } from '../../../hooks/forms/useForm';

export const IndexMicSampling = () => {

    const [createSweet] = useSweetAlert();
    const history = useHistory();
    const { code } = useParams();

    const [sites, setSites] = useState([])
    const [samples, setSamples] = useState([])
    const [typeSamples, setTypeSamples] = useState([])
    const [thermometers, setThermometers] = useState([])

    const [muestras, setMuestras] = useState([])
    const [is_company, setIsCompany] = useState(false);
    const [cotizacion, setCotizacion] = useState(null)
    const [firmSampler, setFirmSampler] = useState(null)
    const [firmClient, setFirmClient] = useState(null);
    const [firmSuperv, setFirmSuperv] = useState(null)
    const [observations, setObservations] = useState("");
    const [sampling_plan, setSampling_plan] = useState("");

    const [firmSamplerImg, setFirmSamplerImg] = useState(null);
    const [firmClientImg, setFirmClientImg] = useState(null);
    const [firmSupervImg, setFirmSupervImg] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    //VARIABLES AGREGAR PAGO
    const [ShowClassNameC, SetShowClassNameC] = useState("d-none");
    const [totalW, setTotalW] = useState(0.00);
    const [listPayMethod, setListPayMethod] = useState([]);
    const [checkTypeClient, setCheckTypeClient] = useState(1);
    const [isPayForCompany, setIsPayForCompany] = useState(false);
    const [moneyEntered, setMoneyEntered] = useState(0);
    const [formPaymentBills, handleInputChangePaymentBills, resetPaymentBills, handleUpdateValuesPaymentBills] = useForm({
        id_method_liquided: 0,
        amount_liquided: 0.0,
        id_method_pay: 1,
        amount_liq: 0.0,
    });

    const {
        id_method_liquided,
        amount_liquided,
        id_method_pay,
        amount_liq,
    } = formPaymentBills;
    const [listPayMethods_valid, setlistPayMethods_valid] = useState(false);
    const [modalPayBills, setModalPayBills] = useState(false);
    const [showMessageW, setShowMessageW] = useState({
        labelMoney: "",
        amount: 0.0
    });
    const [specialDiscount, setSpecialDiscount] = useState(null);
    const [formSpecialDiscount, handleInputChangeSpecialDiscount, resetSpecialDiscount, handleUpdateValuesSpecialDiscount] = useForm({
        special_discount_code: ""
    });
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);
    const [totalDevolutions, setTotalDevolutions] = useState(0.0);
    const [data, setData] = useState([]);
    const [valueThermometer, setValueThermometer] = useState(null)
    const [valor, setValor] = useState(null)
    const [require_invoice, setRequire_invoice] = useState(false);

    //console.log(valueThermometer);

    //#region TRAER MUESTRAS
    const getInfo = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `QuoterDeterminations/DetailQuoter/${code}`);
        ////console.log('RESPUESTA ->: ', respuesta)
        if (respuesta.code === 200) {

            setCotizacion(respuesta.data.cotizacion);
            setMuestras(respuesta.data.samples);

            respuesta.data.samples.forEach(sample => {
                ////console.log(sample.required_temperature);
            });
            const requiredTemperatureFirstSample = respuesta.data.samples[0].required_temperature;
            ////console.log(requiredTemperatureFirstSample)

            setValor(requiredTemperatureFirstSample)
            //setValor(respuesta.data.samples)
            //////console.log(respuesta.data.cotizacion.id_company,"valor compania");
            //alert(respuesta.data.cotizacion.id_company);
            if (respuesta.data.cotizacion.id_company != 0 && respuesta.data.cotizacion.id_company != null) {
                setIsCompany(true);
            } else {
                setIsCompany(false);
            }
            let totalsamples = 0;
            respuesta.data.samples.forEach(s => {
                totalsamples += s.price;
            });
            setSubTotalW(totalsamples);
            if (respuesta.data.sampling_plan !== null) {
                setSampling_plan(respuesta.data.sampling_plan);
            }
            if (respuesta.data.observations !== null) {
                setObservations(respuesta.data.observations);
            }
            if (respuesta.data.firm_sampler !== null) {
                setFirmSamplerImg(respuesta.data.firm_sampler);
            }
            if (respuesta.data.firm_client !== null) {
                setFirmClientImg(respuesta.data.firm_client);
            }
            if (respuesta.data.firm_superv !== null) {
                setFirmSupervImg(respuesta.data.firm_superv);
            }

            getAll(respuesta.data.samples);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInfo);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Muestreo", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Muestreo", respuesta.data.msg);
        }
    }
    //#endregion


    //#region INICIALIZACION DE SLIDERS
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false
    };
    //#endregion


    //#region CATALOGOS

    const getSites = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const response = await sendRequest(requestOptions, "Sites/List");

        if (response.code === 200) {
            let tmpSites = [];
            response.data.forEach(s => {
                tmpSites.push(
                    {
                        ...s,
                        value: s.id_mic_site,
                        label: s.name
                    }
                );
            });

            setSites(tmpSites);
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, getSites);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }

    const geTypeSample = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const response = await sendRequest(requestOptions, "TypeSamples/List");

        if (response.code === 200) {
            let tmpTypeSamples = [];
            response.data.forEach(s => {
                tmpTypeSamples.push(
                    {
                        ...s,
                        value: s.id_mic_type_sample,
                        label: s.name
                    }
                );
            });

            setTypeSamples(tmpTypeSamples);
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, geTypeSample);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }

    const getSamples = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const response = await sendRequest(requestOptions, "SampleMic/List");

        if (response.code === 200) {
            let sample = [];
            response.data.forEach(s => {
                sample.push(
                    {
                        ...s,
                        value: s.id_mic_sample,
                        label: s.name
                    }
                );
            });

            setSamples(sample);
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, getSamples);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }

    const getThermometer = async () => {
        let requestOptions = {
            method: 'GET'
        };
        const response = await sendRequest(requestOptions, "Thermometers/List");

        if (response.code === 200) {
            let thermometer = [];
            response.data.forEach(s => {
                thermometer.push(
                    {
                        ...s,
                        value: s.id_thermometer,
                        label: s.name_thermometer
                    }
                );
            });

            setThermometers(thermometer);
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, getThermometer);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }

    const addNewTemperature = (e) => {

    }

    const updateStates = (option, type) => {
        switch (type) {
            case "sample":
                setSamples((prev) => [...prev, option]);
                break;
            case "site":
                setSites((prev) => [...prev, option]);
                break;
            case "typeSample":
                setTypeSamples((prev) => [...prev, option]);
                break;
            case "thermometer":
                setThermometers((prev) => [...prev, option]);
                break;
            default:
                break;
        }

    }
    //#endregion


    // #region FIRMAS
    const canvasRef1 = useRef(null);
    const canvasRef2 = useRef(null);
    const canvasRef3 = useRef(null);

    const handleChangeFirm = (myRef, type) => {
        const Draw = myRef.current;
        if (type === "responsable") {
            setFirmSampler(Draw.getDataURL('png'))
        } else if (type === "cliente") {
            setFirmClient(Draw.getDataURL('png'))
        } else if (type === "supervisor") {
            setFirmSuperv(Draw.getDataURL('png'))
        }
    }

    const repeatFirm = (myRef, type) => {
        const Draw = myRef.current;
        Draw.eraseAll();
        if (type === "sampler") {
            setFirmSampler(null);
        } else if (type === "client") {
            setFirmClient(null);
        } else if (type === "superv") {
            setFirmSuperv(null);
        }
    };
    //#endregion FIRMAS

    //#region INICIALIZACION DE CATALOGOS
    useEffect(() => {
        getSamples();
        getSites();
        geTypeSample();
        getInfo();
        handleGetPayMethods();
        getThermometer();
        return true
    }, [])

    //#endregion


    const updateSample = (data) => {
        let newMuestras = muestras.map(x => {
            if (x.id_quoter_type_sample === data.id_quoter_type_sample) {
                x = {
                    ...data
                }
            }
            return x;
        })
        setMuestras([...newMuestras])
    }

    const saveDataQuoter = async () => {
        setIsLoading(true);
        let data = {
            cotizacion: { ...cotizacion },
            observations: observations,
            firm_sampler: firmSampler,
            firm_client: firmClient,
            firm_superv: firmSuperv,
            sampling_plan: sampling_plan,

        }
        ////console.log(data);

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const respuesta = await sendRequest(requestOptions, "QuoterDeterminations/SaveQuoter");
        ////console.log('RESPUESTA: ', respuesta);
        if (respuesta.code === 200) {
            if (respuesta.data.observations !== null) {
                setObservations(respuesta.data.observations);
            }
            if (respuesta.data.sampling_plan !== null) {
                setSampling_plan(respuesta.data.sampling_plan);
            }
            if (respuesta.data.firm_sampler !== null) {
                setFirmSamplerImg(respuesta.data.firm_sampler);
            }
            if (respuesta.data.firm_client !== null) {
                setFirmClientImg(respuesta.data.firm_client);
            }
            if (respuesta.data.firm_superv !== null) {
                setFirmSupervImg(respuesta.data.firm_superv)
            }
            setIsLoading(false);
            window.location.reload();
        } else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDataQuoter);
            setIsLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Muestreo", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Muestreo", respuesta.data.msg);
            setIsLoading(false);
        }
    }

    const finishQuoter = async() => {
        setIsLoading(true);

        if (firmClientImg === null && firmSamplerImg === null && firmClientImg === null) {
            createSweet("warning", "info", "Alerta", "Debes de firmar para poder finalizar");
            setIsLoading(false);
            return;
        }
        let id_commercial_line = localStorage.getItem("commercialLineId");
        ////console.log("formlistpay.listPayMethod", formlistpay.listPayMethods);

        let data = {
            "id_quoter": cotizacion.id_quoter,
            "id_commercial_line": id_commercial_line,
            "listPayMethods": formlistpay.listPayMethods,
            "iva": totalTaxesW,
            "id_branch": id_branch,
            "required_invoice_patient": require_invoice
        }
        //console.log('data: ',data)

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrder/CreteMicro");
        //console.log('_respuesta: ',respuesta)
        if (respuesta.code === 200) {
            createSweet("success", "success", "Éxito", "Muestreo finalizado correctamente");
            setIsLoading(false);
            setTimeout(() => {
                history.push("../../sanitaria/agenda")
            }, 1500);
        } else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, finishQuoter);
            setIsLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Muestreo", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Muestreo", respuesta.data.msg);
            setIsLoading(false);
        }
    }
    //GLOBAL REQUEST metodo de pago
    const handleRequest = async (method, methodUrl, nameMethod, body = null, isMultipartForm = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            if (isMultipartForm !== null && isMultipartForm) {
                requestOptions = {
                    ...requestOptions,
                    body: body
                }
            }
            else {
                requestOptions = {
                    ...requestOptions,
                    body: JSON.stringify(body)
                };
            }
        }

        const response = await sendRequest(requestOptions, methodUrl, ((isMultipartForm !== null && isMultipartForm) ? "multipart/form-data" : "application/json"));
        //console.log('response- ',response)
        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');
    const handleAddPayMethodInputs = () => {
        let listPayMethod = listPayMethods;

        listPayMethod.push({
            id_pay_method: 0,
            amount: 0.0,
            is_edited: false,
            deleted: false,
            id_branch: 0
        });

        handleUpdateValueslistpay({
            ...formlistpay,
            listPayMethods: listPayMethod
        });


    }
    const handleDeletePayMethod = (index) => {
        let copyListPayMethods = listPayMethods;
        let money = 0;

        if (listPayMethods.length > 1) {
            copyListPayMethods.splice(index, 1);
        }

        copyListPayMethods.forEach(obj => {
            money += parseFloat(obj.amount)
        });

        handleUpdateValueslistpay({
            ...formlistpay,
            listPayMethods: copyListPayMethods
        });
        ////console.log(handleDeletePayMethod);
        setMoneyEntered(money.toFixed(2));
    }

    const handleGiveChange = (e, nameInput, nameArraysInput) => {
        handleDinamicInput(e, nameInput, nameArraysInput);

        let totalIngresado = 0;

        listPayMethods.forEach(obj => {
            totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));
        });

        totalIngresado = !isNaN(totalIngresado) ? totalIngresado : 0;

        setMoneyEntered(totalIngresado.toFixed(2));
    }
    const handlePayBill = (id_work_order_pay, amount) => {
        handleUpdateValuesPaymentBills({
            ...formPaymentBills,
            id_method_liquided: id_work_order_pay,
            amount_liq: amount
        });

        toggleModalPayBills();
    }
    const toggleModalPayBills = () => {
        setModalPayBills(!modalPayBills);
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }
    const handleModalSpecialDiscount = () => {

        handleUpdateValuesSpecialDiscount({
            special_discount_code: ""
        });

        toggleSpecialDiscount();
    }
    const toggleSpecialDiscount = () => {
        setModalSpecialDiscounts(!modalSpecialDiscounts);
    }
    const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
    const handleGetPayMethods = async () => {
        let listTmp = await handleRequest("GET", "PayMethod/List", "Metodos de pago");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_pay_method: obj.id_pay_method,
                    name: obj.name,
                    abbreviation: obj.abbreviation
                });
            });

            setListPayMethod(list);
        }
    }

    const [values, setValues] = useState();
    //FORM VALUES WORK ORDER
    const [formlistpay, handleInputlistpay, resetlistpay, handleUpdateValueslistpay, handleSelectValueslistpay, handlePickerValueslistpay, handleDinamicInput] = useForm({

        listPayMethods: [{
            id_pay_method: 0,
            amount: 0.0,
            id_branch: id_branch
        }]
    });

    const {
        listPayMethods,
    } = formlistpay;
    const [validationslistpay, setValidationslistpay] = useState({
        listPayMethods_valid: false,
    });
    const [arrayCommonDiscounts, setArrayCommonDiscounts] = useState([]);
    const [enableBtnSaveWorkOrder, setEnableBtnSaveWorkOrder] = useState(false);
    const format_trunc_number = (_quantity, _decimals) => {
        let _format_number = 0;
        const _base = 10;

        let _number_decimals = Math.pow(_base, _decimals);

        _format_number = Math.round(_quantity * _number_decimals) / _number_decimals;

        return _format_number;
    }
    function getAll(muestras_bd) {

        let percentage_urgency = 0;
        let _take_number_decimals = 2;
        let total = 0.0;
        let subtotal = 0.0;
        let descuento = 0.0;
        let total_iva = 0.0;
        let total_cancellations = 0.0;

        if (muestras_bd.length > 0) {
            muestras_bd.forEach(data => {

                let price = data.price;
                let discount = 0.0;
                let urgency = 0.0;
                let taxIva = 0.0;

                switch (data.config_iva) {
                    //restar el iva
                    case true:
                        let _valueIva = 1 + (data.value_iva / 100);

                        price = (price / _valueIva);
                        price = format_trunc_number(price, _take_number_decimals);

                        if (data.is_urgent) {
                            urgency = (price * percentage_urgency) / 100;
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.urgent_price = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.urgent_price = 0;
                        }

                        // if (data.auto_redeem) {
                        //     discount = (price * data.discount) / 100;
                        //     discount = format_trunc_number(discount, _take_number_decimals);
                        //     data.amountDiscount = discount;
                        // }
                        // else {
                        //     data.amountDiscount = 0;
                        // }
                        discount = data.discount;

                        //RECALCULATE IVA
                        taxIva = ((price - discount) * data.value_iva) / 100;
                        taxIva = format_trunc_number(taxIva, _take_number_decimals);
                        data.tax = taxIva;

                        break;

                    default:

                        if (data.is_urgent) {
                            urgency = (price * percentage_urgency) / 100;
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.urgent_price = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.urgent_price = 0;
                        }

                        // if (data.auto_redeem) {
                        //     discount = (price * data.discount) / 100;
                        //     discount = format_trunc_number(discount, _take_number_decimals);
                        //     data.amountDiscount = discount;
                        // }
                        // else {
                        //     data.amountDiscount = 0;
                        // }

                        discount = data.discount;
                        //RECALCULATE IVA
                        taxIva = ((price - discount) * data.value_iva) / 100;
                        taxIva = format_trunc_number(taxIva, _take_number_decimals);
                        data.tax = taxIva;

                        break;
                }

                if (data.is_canceled) {
                    total_cancellations += ((price - discount) + taxIva);
                }
                else {
                    subtotal += price;
                    descuento += discount;
                    total_iva += taxIva;
                }
            });
        }

        total = Math.round((subtotal - descuento) + total_iva);
        total_cancellations = Math.round(total_cancellations);

        descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
        subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
        total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
        total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
        total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

        setSubTotalW(subtotal);
        setTotalDiscountW(descuento);
        setTotalTaxesW(total_iva);
        setTotalW(total);
        setTotalDevolutions(total_cancellations);
        setArrayCommonDiscounts(arrayCommonDiscounts);
    }
    //#endregion WORK ORDER MODEL 
    //#USE MEMO
    //CALCULATE AMOUNT OF WORK ORDER
    useMemo(() => {
        let percentage_urgency = 0;
        let _take_number_decimals = 2;
        let total = 0.0;
        let subtotal = 0.0;
        let descuento = 0.0;
        let total_iva = 0.0;
        let total_cancellations = 0.0;

        if (muestras.length > 0) {
            muestras.forEach(data => {

                let price = data.price;
                let discount = 0.0;
                let urgency = 0.0;
                let taxIva = 0.0;

                switch (data.config_iva) {
                    //restar el iva
                    case true:
                        let _valueIva = 1 + (data.value_iva / 100);

                        price = (price / _valueIva);
                        price = format_trunc_number(price, _take_number_decimals);

                        if (data.is_urgent) {
                            urgency = (price * percentage_urgency) / 100;
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.urgent_price = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.urgent_price = 0;
                        }

                        // if (data.auto_redeem) {
                        //     discount = (price * data.discount) / 100;
                        //     discount = format_trunc_number(discount, _take_number_decimals);
                        //     data.amountDiscount = discount;
                        // }
                        // else {
                        //     data.amountDiscount = 0;
                        // }
                        discount = data.discount;
                        //RECALCULATE IVA
                        taxIva = ((price - discount) * data.value_iva) / 100;
                        taxIva = format_trunc_number(taxIva, _take_number_decimals);
                        data.tax = taxIva;

                        break;

                    default:

                        if (data.is_urgent) {
                            urgency = (price * percentage_urgency) / 100;
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.urgent_price = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.urgent_price = 0;
                        }

                        // if (data.auto_redeem) {
                        //     discount = (price * data.discount) / 100;
                        //     discount = format_trunc_number(discount, _take_number_decimals);
                        //     data.amountDiscount = discount;
                        // }
                        // else {
                        //     data.amountDiscount = 0;
                        // }
                        discount = data.discount;

                        //RECALCULATE IVA
                        taxIva = ((price - discount) * data.value_iva) / 100;
                        taxIva = format_trunc_number(taxIva, _take_number_decimals);
                        data.tax = taxIva;

                        break;
                }

                if (data.is_canceled) {
                    total_cancellations += ((price - discount) + taxIva);
                }
                else {
                    subtotal += price;
                    descuento += discount;
                    total_iva += taxIva;
                }
            });
        }

        total = Math.round((subtotal - descuento) + total_iva);
        total_cancellations = Math.round(total_cancellations);

        descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
        subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
        total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
        total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
        total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

        setSubTotalW(subtotal);
        setTotalDiscountW(descuento);
        setTotalTaxesW(total_iva);
        setTotalW(total);
        setTotalDevolutions(total_cancellations);
        setArrayCommonDiscounts(arrayCommonDiscounts);

    }, [formlistpay]);

    useMemo(() => {
        if (totalW === 0) {
            setMoneyEntered(0);
            setShowMessageW({
                labelMoney: "",
                amount: 0
            });
            setEnableBtnSaveWorkOrder(true);
        }
        else {
            if (moneyEntered >= totalW) {

                let change = (parseFloat(moneyEntered) - totalW);

                setShowMessageW({
                    labelMoney: "Cambio",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });
                setEnableBtnSaveWorkOrder(false);
            }
            else {
                let change = (totalW - parseFloat(moneyEntered));

                setShowMessageW({
                    labelMoney: "Faltan",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });

                setEnableBtnSaveWorkOrder(true);
            }
        }
    }, [totalW, moneyEntered]);

    const checkIncoive = (event) => {
        const newValue = event.target.checked;
        setRequire_invoice(newValue);
    };

    return (
        <Fragment>
            <Container fluid={true} className="p-2 pb-5">
                <Slider {...settings}>
                    {
                        muestras.map((muestra, key) => {
                            return <div className='pl-2 pr-2' key={key}>
                                <ItemSample
                                    thermometer={thermometers}
                                    cotizacion={cotizacion}
                                    muestra={muestra}
                                    updateSample={updateSample}
                                    updateStates={updateStates}
                                    sites={sites}
                                    samples={[...samples]}
                                    typeSamples={typeSamples}
                                    data={data}
                                    idKey={key}
                                    setValueThermometer={setValueThermometer}
                                    valueThermometer={valueThermometer}
                                    valor={valor}
                                />
                            </div>
                        })
                    }
                    {/* {
                        thermometers.map((ther, key)=>{
                            return <div className='pl-2 pr-2' key={key}>
                            <ItemSample thermometer={ther} idKey={key} />
                        </div>
                        })
                    } */}
                </Slider>

                <div className="pr-2 pl-2 mt-4">
                    <Row>
                        <Col sm="6">
                            <label>Numero de Plan de Muestreo: </label>
                            <Input type='text' value={sampling_plan} onChange={(e) => setSampling_plan(e.target.value)} />
                        </Col>
                        <Col sm="12">
                            <label>Observaciones generales, cambios o desviaciones: </label>
                            <Input type='textarea' value={observations} onChange={(e) => setObservations(e.target.value)} />
                        </Col>
                    </Row>
                </div>
                <div className="pr-2 pl-2 mt-4">

                    <Row>
                        <Col sm="12">
                            <Card className='shadow'>
                                <CardHeader className='p-2 bg-primary text-center'>
                                    Criterios de aceptación
                                </CardHeader>
                                <div className='p-3'>
                                    En matrices de alimentos: los alimentos crudos y cocidos serán aceptados con una cantidad aproximada de 150 g; para productos de la pesca debe ser requerido aproximadamente 300 g; los moluscos bivalbos deberá ser 20 piezas grandes lo suficiente para obtener una masa drenada de 200 g.
                                    <br />
                                    <br />
                                    En matrices de agua, serán recibidas una cantidad aproximada de 300 ml con parámetros de "CTMA", "CT","CF y E.coli"; para Ameba de vida libre será aproximadamente 500 ml; si los parametros son para "Salmonella ssp" se requerirá 1000 ml; en caso de que se solicite el parámetro "Vibrio Cholerae" será aceptada una cantidad aproximada de 3000 ml.
                                    <br />
                                    <br />
                                    El contenedor de la muestra debe ser estéril. En caso de no ser así, se podrá recibir realizando la anotacion correspondiente a la desviación.
                                    <br />
                                    <br />
                                    Condiciones para la clasificación del tipo de alimento con base en su temperatura: Alimentos refrigerados de 2 a 8 °C;  Alimentos congelados o ≤ 0°C;  Alimentos calientes > 60 °C.
                                </div>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <p>Responsable del muestreo</p>
                            {
                                firmSamplerImg !== null ?
                                    <>
                                        <img src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + firmSamplerImg} />
                                    </>
                                    :
                                    <>
                                        <CanvasComponent
                                            canvasRef={canvasRef1}
                                            handleChange={(ref) => handleChangeFirm(ref, "responsable")}
                                            width={'820'}
                                            height={200}
                                            brushColor={"#000"}
                                            brushRadius={1}
                                            lazyRadius={3}
                                        />
                                        <br />
                                        <Button className='btn btn-primary btn-block' onClick={() => repeatFirm(canvasRef1, "sampler")}>Repetir firma</Button>
                                    </>
                            }
                        </Col>
                        <Col sm="4">
                            <p>Firma del contacto/cliente</p>
                            {
                                firmClientImg !== null ?
                                    <>
                                        <img src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + firmClientImg} />
                                    </>
                                    :
                                    <>
                                        <CanvasComponent
                                            canvasRef={canvasRef2}
                                            handleChange={(ref) => handleChangeFirm(ref, "cliente")}
                                            width={'820'}
                                            height={200}
                                            brushColor={"#000"}
                                            brushRadius={1}
                                            lazyRadius={3}
                                        />
                                        <br />
                                        <Button className='btn btn-primary btn-block' onClick={() => repeatFirm(canvasRef2, "client")}>Repetir firma</Button>
                                    </>
                            }
                        </Col>
                        <Col sm="4">
                            <p>Firma de quien supervisa</p>
                            {firmSupervImg !== null ?
                                <>
                                    <img src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + firmSupervImg} />
                                </>
                                :
                                <>
                                    <CanvasComponent
                                        canvasRef={canvasRef3}
                                        handleChange={(ref) => handleChangeFirm(ref, "supervisor")}
                                        width={'820'}
                                        height={200}
                                        brushColor={"#000"}
                                        brushRadius={1}
                                        lazyRadius={3}
                                    />
                                    <br />
                                    <Button className='btn btn-primary btn-block' onClick={() => repeatFirm(canvasRef3, "superv")}>Repetir firma</Button>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row className='mt-4 text-center mb-5'>
                        <Col sm="4">

                        </Col>
                        <Col sm="4">
                            <Button disabled={isLoading} className={`${isLoading ? "progress-bar-animated progress-bar-striped" : ""}`} onClick={saveDataQuoter} size='lg' color='secondary'>GUARDAR DATOS</Button>
                        </Col>
                        <Col sm="4">

                        </Col>
                    </Row>
                    <Row className={is_company ? 'mt-3 d-none' : 'mt-3'}>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <Card className='shadow'>
                                <CardHeader className='bg-primary p-1'>
                                    <br />
                                    &nbsp; &nbsp; <i className="fa fa-usd f-20"></i>&nbsp; &nbsp;
                                    <label className='labelDateGenerales'>Información de pago</label>
                                </CardHeader>
                                <CardBody className='p-l-2 p-t-0'>
                                    <Row className='m-t-10 p-1'>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-l-0 mt-2'>
                                            <Row>
                                                {/* {
                                                    listTest.length > 0
                                                        ?
                                                        <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                                            <Label className="labelCheckPrint">{"Emitir factura"}</Label> <br />
                                                            <Label className="switch">
                                                                <Input type="checkbox" name="checkInvoice" onChange={(e) => handleCreateInvoice(e)} checked={checkInvoice} />
                                                                <span className="slider round"></span>
                                                            </Label>
                                                        </Col>
                                                        : ""
                                                } */}
                                                <Col xs="12" sm="7" md="7" lg="10" xl="7">
                                                    <Button type='button' className='btn-air-primary' outline color='primary' size='sm' onClick={handleAddPayMethodInputs} style={{ marginRight: '50px' }}>
                                                        <i className='fa fa-plus'></i> Método de pago
                                                    </Button>
                                                    <Label className='col-form-label'>Requiere factura: <span className='f-16' style={{ color: "#0079C7" }}></span>
                                                        <Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input type="checkbox" name="require_invoice" onClick={checkIncoive} checked={require_invoice}/>
                                                            <span className="slider round"></span>
                                                        </Label>
                                                    </Label>

                                                </Col>
                                            </Row>
                                            <Row className='m-t-15'>
                                                <Table>
                                                    <thead>
                                                        <tr className='text-center border-bottom-info'>
                                                            <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Método de pago</label></th>
                                                            <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Monto</label></th>
                                                            <th className='p-1'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            listPayMethods.map((_paymethod, keyPayment) => {

                                                                return (
                                                                    <tr key={`method-${keyPayment}`} className={_paymethod.deleted ? 'd-none' : ''}>
                                                                        <td>
                                                                            <Input type="select" required={true} className='form-control form-control-sm input-air-primary p-1' value={_paymethod.id_pay_method} data-id={keyPayment}
                                                                                onChange={(e) => handleDinamicInput(e, "id_pay_method", "listPayMethods")}
                                                                                disabled={subTotalW > 0 ? false : true}>
                                                                                <option value={0}>Selecciona una opción</option>
                                                                                {
                                                                                    listPayMethod.map(_pMethod => {

                                                                                        if (checkTypeClient === 3 && isPayForCompany) {
                                                                                            if (_pMethod.name === "CREDITO") {
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                        else if (_pMethod.abbreviation !== "CONV") {
                                                                                            return (
                                                                                                <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </td>
                                                                        <td>
                                                                            <Input type='number' name="amount" data-id={keyPayment} value={_paymethod.amount} placeholder='$ 0.0' step="any"
                                                                                className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                                disabled={subTotalW > 0 ? false : true}
                                                                            />
                                                                        </td>
                                                                        <td className='p-1 text-center' style={{ verticalAlign: "middle" }}>
                                                                            {
                                                                                (_paymethod.fully_paid !== undefined && !_paymethod.fully_paid) ?
                                                                                    <Button type='button' className='btn-air-success' outline color='success' size='xs' onClick={() => handlePayBill(_paymethod.id_work_order_pay, _paymethod.amount)}>
                                                                                        <i className="fa fa-money"></i>
                                                                                    </Button>
                                                                                    :
                                                                                    <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(keyPayment)}
                                                                                        disabled={subTotalW > 0 ? false : true}
                                                                                    >
                                                                                        <i className='icofont icofont-ui-delete'></i>
                                                                                    </Button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan={3} className="text-xs-center">
                                                                <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>
                                                                <b className='labelChange'>{showMessageW.labelMoney}</b>
                                                            </td>
                                                            <td>
                                                                <b className='labelChange'>$ {`${number_format(showMessageW.amount, 2)}`}</b>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Row>
                                        </Col>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-r-0 mt-2'>
                                            <Row>

                                                <Col xs="12" sm="7" md="7" lg="10" xl="7">
                                                    {/* {
                                                        specialDiscount === null ?
                                                            <Button type='button' className='float-right btn-air-primary' outline color='primary' size='sm' onClick={() => handleModalSpecialDiscount()}  >    
                                                                <i className='fa fa-plus'></i> Descuento especial
                                                            </Button>
                                                            :
                                                            <Button type='button' disabled className='float-right btn-air-primary disabled' outline color='primary' size='sm' onClick={null} >
                                                                <i className='fa fa-check'></i> Descuento especial aplicado
                                                            </Button>
                                                    } */}
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                                <Col sm="12">
                                                    <Table className="table-xs">
                                                        <thead>
                                                            <tr>
                                                                <th className='p-1 f-12' colSpan={2}>
                                                                    <b className='text-WorkOrder f-14'>Resúmen</b>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14'><label className='labelPrices'>Subtotal</label></td>
                                                                <td className='text-right f-14'><label className='labelPrices'>$ {number_format(subTotalW, 2)}</label></td>
                                                            </tr>
                                                            {/* <tr style={{ cursor: "pointer" }} className="border-bottom-primary">
                                                                <td className='f-14'><label className='labelPrices'>Descuento</label>  </td>
                                                                <td className="text-right f-14">
                                                                    <b className="text-danger text-WorkOrderDanger">(-) ${number_format(totalDiscountW, 2)}</b>
                                                                   
                                                                </td>
                                                            </tr> */}
                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14'><label>I.V.A</label></td>
                                                                <td className='text-right f-14'><label><b>${number_format(totalTaxesW, 2)}</b></label></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='f-14'> <b className='f-14'>Total</b></td>
                                                                <td className="text-right f-14"><b className='f-14'>$ {number_format(totalW, 2)}</b></td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td className='f-14'> <b className='f-14'>Muestras sin tomar</b></td>
                                                                <td className="text-right f-14"><b className='f-14'>$ {number_format(totalDevolutions, 2)}</b></td>
                                                            </tr> */}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-4 text-center mb-5'>
                        <Col sm="2">
                            <hr />
                        </Col>
                        <Col sm="2">
                        </Col>

                        <Col sm="4">
                            <Button disabled={isLoading} className={`${isLoading ? "progress-bar-animated progress-bar-striped" : ""}`} onClick={finishQuoter} size='lg' color='success'>FINALIZAR MUESTREO</Button>
                        </Col>
                        <Col sm="4">
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}
