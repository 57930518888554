import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import Select from 'react-select';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { Date } from '../../../constant';

export const InputAntibiogram = ({
    userValidate,
    idWorkOrderExam,
    bacterias,
    microAntibiograms,
    addAntibiogram,
    changeDataAntibiogram,
    indice
}) => {

    // ////console.log("bacterias", bacterias);

    // ////console.log("antibiogramas", microAntibiograms);


    const history = useHistory();

    const [createSweet] = useSweetAlert();

    const [antibiograms, setAntibiograms] = useState([]);
    const [antibiogramSelected, setAntibiogramSelected] = useState(null);
    const [microSelected, setMicroSelected] = useState(null)

    const [bacteriumId, setBacteriumId] = useState(0);

    const changeAntibiogram = ({ label, value }) => {
        let antibiograma = antibiograms.find(x => x.id_mic_antibiogram === parseInt(value));
        setAntibiogramSelected(antibiograma);
        addAntibiogram(idWorkOrderExam, "section", antibiograma.antibiotics, parseInt(value), bacteriumId)
    }

    const changeMicro = ({ label, value }) => {
        getAntibiograms(value);
        setBacteriumId(value);
        setMicroSelected({ "value": value, "label": label })
    }

    const getAntibiograms = async (id) => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Antibiogram/GetAntibiogramFromBacterium/" + id);

        if (respuesta.code === 200) {
            let antibiogramas = respuesta.data.map(x => {
                x.label = x.antibiogram;
                x.value = x.id_mic_antibiogram;
                return x;
            });
            setAntibiograms(antibiogramas);
            return antibiogramas;
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAntibiograms);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Alerta", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Alerta", respuesta.data.msg);
        }
    }

    useEffect(() => {
         ////console.log("entra a los anti",idWorkOrderExam);
        ////console.log("microinputanti",microAntibiograms);
        // debugger;
        if (microAntibiograms.length > 0) {
            let bacteria = bacterias.find(x => x.id_bacterium === microAntibiograms[0].id_mic_bacterium);
            setMicroSelected({ "value": bacteria.value, "label": bacteria.label })
            getAntibiograms(bacteria.value).then(resp => {
                let anti = resp.find(x => x.id_mic_antibiogram === microAntibiograms[0].id_mic_antibiogram);
                anti.antibiotics = microAntibiograms[0].antibiotics;
                setAntibiogramSelected(anti);
            })
        } else {
            setMicroSelected("");
            setAntibiogramSelected(null);
        }
    }, [microAntibiograms])

    // ////console.log(antibiogramSelected, "Esto el antibiograma");

    return (
        <Row className="border-top pt-1 mt-4" key={indice}>
            <Col sm="12">
                <h5>Antibiograma</h5>
            </Col>
            <Col sm="6">
                <label>Selección de microorganismo</label>
                <Select
                    isDisabled={userValidate === null ? false : true}
                    menuPlacement={"top"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="gram"
                    options={bacterias}
                    value={microSelected}
                    onChange={changeMicro}
                    placeholder={"Selecciona la un microorganismo"}
                    theme={
                        (theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#898989'
                            }
                        })
                    }
                />
            </Col>
            <Col sm="6">
                <label>Selección de antibiograma</label>
                <Select
                    isDisabled={userValidate === null ? false : true}
                    menuPlacement={"top"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="gram"
                    options={antibiograms}
                    value={antibiogramSelected}
                    onChange={changeAntibiogram}
                    placeholder={"Selecciona un antibiograma"}
                    theme={
                        (theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#898989'
                            }
                        })
                    }
                />
            </Col>
            {
                (antibiogramSelected !== null && antibiogramSelected !== undefined) ?
                    <Col sm="12" className='mt-3'>
                        <Row>
                            <Col sm="4">MEDICAMENTO</Col>
                            <Col sm="1"></Col>
                            <Col sm="1" className='text-center'>
                                {
                                    antibiogramSelected.is_cim ? "CIM" : ""
                                }
                            </Col>
                            <Col sm="6" className='text-center'>Interpretación</Col>
                            <Col sm="12"><hr /></Col>
                        </Row>
                        {
                            antibiogramSelected.antibiotics.map(x => {

                                return (
                                    
                                    <Row className='mt-1' key={x.id_mic_antibiotic+"-"+idWorkOrderExam}>
                                        <Col sm="4">
                                            {x.antibiotic}
                                            
                                        </Col>
                                        <Col sm="1">
                                            {/* Validacion */}
                                        </Col>
                                        <Col sm="1">
                                            {
                                                antibiogramSelected.is_cim ? <Input
                                                    disabled={userValidate === null ? false : true}
                                                    value={x.cim}
                                                    onChange={(e) => changeDataAntibiogram("cim", e.target.value, x.id_mic_antibiotic, null, antibiogramSelected.id_mic_antibiogram, microAntibiograms[0].id_mic_bacterium,idWorkOrderExam)}
                                                    bsSize={"sm"} /> : ""
                                            }
                                        </Col>
                                        <Col sm="6">
                                            <Row className='text-center'>
                                                <Col sm="3">
                                                    <FormGroup tag="fieldset">
                                                            <Input
                                                                disabled={userValidate === null ? false : true}
                                                                checked={(x.interpretacion === "Suseptible" || x.interpretacion === "Susceptible") ? true : false}
                                                                onChange={(e) => changeDataAntibiogram("interpretacion", "Susceptible", x.id_mic_antibiotic, null, antibiogramSelected.id_mic_antibiogram, microAntibiograms[0].id_mic_bacterium,idWorkOrderExam)}
                                                                name={`radio-${x.id_mic_antibiotic}-${microAntibiograms[0].id_mic_bacterium}-${idWorkOrderExam}`}
                                                                type="radio"
                                                            />
                                                            
                                                            {' '}
                                                            <Label check>
                                                                Susceptible
                                                            </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup tag="fieldset">
                                                        <FormGroup check>
                                                            <Input
                                                                disabled={userValidate === null ? false : true}
                                                                checked={x.interpretacion === "Resistente" ? true : false}
                                                                onChange={(e) => changeDataAntibiogram("interpretacion", "Resistente", x.id_mic_antibiotic, null, antibiogramSelected.id_mic_antibiogram, microAntibiograms[0].id_mic_bacterium,idWorkOrderExam)}
                                                                name={`radio-${x.id_mic_antibiotic}-${microAntibiograms[0].id_mic_bacterium}-${idWorkOrderExam}`}
                                                                type="radio"
                                                            />
                                                            {' '}
                                                            <Label check>
                                                                Resistente
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup tag="fieldset">
                                                        <FormGroup check>
                                                            <Input
                                                                disabled={userValidate === null ? false : true}
                                                                checked={x.interpretacion === "Intermedio" ? true : false}
                                                                onChange={(e) => changeDataAntibiogram("interpretacion", "Intermedio", x.id_mic_antibiotic, null, antibiogramSelected.id_mic_antibiogram, microAntibiograms[0].id_mic_bacterium,idWorkOrderExam)}
                                                                name={`radio-${x.id_mic_antibiotic}-${microAntibiograms[0].id_mic_bacterium}-${idWorkOrderExam}`}
                                                                type="radio"
                                                            />
                                                            {' '}
                                                            <Label check>
                                                                Intermedio
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup tag="fieldset">
                                                        <FormGroup check>
                                                            <Input
                                                                disabled={userValidate === null ? false : true}
                                                                checked={x.interpretacion === "No evaluado" ? true : false}
                                                                onChange={(e) => changeDataAntibiogram("interpretacion", "No evaluado", x.id_mic_antibiotic, null, antibiogramSelected.id_mic_antibiogram, microAntibiograms[0].id_mic_bacterium,idWorkOrderExam)}
                                                                name={`radio-${x.id_mic_antibiotic}-${microAntibiograms[0].id_mic_bacterium}-${idWorkOrderExam}`}
                                                                type="radio"
                                                            />
                                                            {' '}
                                                            <Label check>
                                                                No evaluado
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                    : ""
            }
        </Row>
    )
}
