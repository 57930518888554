import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { PlaceHolderOption, ModalButtonCancel } from '../../constant';

export const FormBranchMachine = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, 
        id_machine_model, id_branch, id_section, serial_number, machines, 
        branches, sections, button
    } = props;

    const {
        id_machine_model_valid, id_branch_valid, id_section_valid, serial_number_valid
    } = validaciones;
    const txtsection = localStorage.getItem('TextAS');
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Modelo del equipo <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="select" 
                            name="id_machine_model" 
                            onChange={handleInputChange}
                            value={id_machine_model}
                            className="form-control form-control-sm input-air-primary" 
                            >
                                <option value={0}>{ PlaceHolderOption }</option>
                                {
                                    machines.length >= 1 && machines.map( (m, key) => {
                                        return <option key={key} value={m.id_machine_model} selected={ (id_machine_model === m.id_machine_model) ? true : false }>
                                            { m.brand+' '+m.model }
                                        </option>
                                    })
                                }
                            </Input>
                            <FormText color="danger" hidden={ !id_machine_model_valid }>{ PlaceHolderOption }</FormText>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Sucursal <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="select" 
                            name="id_branch" 
                            onChange={handleInputChange}
                            value={id_branch}
                            className="form-control form-control-sm input-air-primary" 
                            >
                                <option value={0}>{ PlaceHolderOption }</option>
                                {
                                    branches.length >= 1 && branches.map( (b, key) => {
                                        return <option key={key} value={b.id_branch} selected={ (id_branch === b.id_branch) ? true : false }>
                                            { b.name+' ('+b.code+')' }
                                        </option>
                                    })
                                }
                            </Input>
                            <FormText color="danger" hidden={ !id_branch_valid }>{ PlaceHolderOption }</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">{txtsection} <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="select" 
                            name="id_section" 
                            onChange={handleInputChange}
                            value={id_section}
                            className="form-control form-control-sm input-air-primary" 
                            >
                                <option value={0}>{ PlaceHolderOption }</option>
                                {
                                    sections.length >= 1 && sections.map( (s, key) => {
                                        return <option key={key} value={s.id_section} selected={ (id_section === s.id_section) ? true : false }>
                                            { s.name }
                                        </option>
                                    })
                                }
                            </Input>
                            <FormText color="danger" hidden={ !id_section_valid }>{ PlaceHolderOption }</FormText>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Número de serie <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="serial_number"
                                autoComplete="nope"
                                onChange={handleInputChange}
                                invalid={serial_number_valid}
                                value={serial_number}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe contener al menos 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" outline color="primary" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}
