import React from 'react'
import { Modal, Form, Label, Row,Col} from 'reactstrap';

export const ModalExams = (props) => {

    const {

        modal3, typeModalList="listNim",arrayExams,cerrarModalNimExam,labelNim
       
    } = props;



    return (
        <Modal isOpen={modal3} toggle={cerrarModalNimExam} backdrop='static' keyboard={false} centered={true} >
            {
                typeModalList === "listNim" ?
                    <>
                        <div className="colorModalIDmuestra" style={{ textAlignLast: "center" }}>
                            <Col sm="12">
                                <Row>
                                    <Col sm="2"></Col>
                                    <Col sm="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{labelNim}</Label>
                                    </Col>
                                    <Col sm="2" className='p-3'>
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={cerrarModalNimExam} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Form className="theme-form" >
                            <Col sm="12" style={{ border: "#46AFE5 3px solid" }} >
                                <Row>  
                                    <Col sm="12">
                                    {
                                        arrayExams.map((obj,i) =>{
                                            return(
                                                <div key={i} className="">
                                                   <i className="fa fa-circle statusIcon   f-12"  ></i> &nbsp; <label className='label-modal-examNim'>{obj.name_exam}</label>
                                                </div>
                                            )
                                        })
                                    }
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    </>
                    :
                    ""
            }
        </Modal>
    )
}
