import { Select } from '@mui/material';
import React from 'react'
import {Button, Modal, ModalBody, CardBody, Media,Col, Form, Row, Label, Input, FormFeedback, FormGroup } from 'reactstrap';

export const ModalAuthorize = (props) => {
    const {
        modal, toggle, user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        validationsCancelWorkOrder, totalCancellationWorkOrder, listDevolutions, loading,

        handleInputChangeCancelWorkOrder, handleValidateReturnAmount,
        saveCancelWorkOrderUpdate,aunthentication,validatePasswordCancell
    } = props;

    const {
        user_authorize_valid,
        password_authorize_valid,
        id_return_reason_cancel_valid,
        percentage_return_cancel_valid        
    } = validationsCancelWorkOrder;
    
    
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
        <Form className="form theme-form" role="form">
            <ModalBody>   
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                {
                    <>
                     <Media> <br /> <br /><Media style={{paddingTop:"50px"}}  className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />

                            <Media body>
                                <Row>
                                    <Col sm="6">
                                    <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                <Input
                                    type="text"
                                    name="user_authorize"
                                    value={user_authorize}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeCancelWorkOrder}
                                    invalid={user_authorize_valid}
                                    className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}
                                />
                               </span></span></span>
                                    </Col>
                                    <Col sm="6">
                                    <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-key"> </i><span className="px-2">{"Contraseña"}

                               <Input
                                    type="password"
                                    name="password_authorize"
                                    value={password_authorize}
                                    required={true}
                                    autoComplete="nope"
                                    invalid={password_authorize_valid}
                                    onChange={handleInputChangeCancelWorkOrder}
                                    className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}

                                />

                            </span></span></span>

                                    </Col>
                                </Row>
                                <span className="d-block"><span><i className="fa fa-money"></i><span className="px-2">{"Mótivo de devolución"}
                            <Input
                                    type="select"
                                    name="id_return_reason_woc"
                                    required={true}
                                    invalid={id_return_reason_cancel_valid}
                                    onChange={handleInputChangeCancelWorkOrder}
                                    className="form-control form-control-sm input-air-primary p-1"
                                    defaultValue={id_return_reason_woc}
                                    style={{fontSize:"13px"}}

                                    >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                            listDevolutions.length >= 1 && listDevolutions.map((sg, key) => {
                                                return <option value={sg.value} key={key}>{sg.label}</option>
                                            })
                                    }
                                </Input>
 
                            </span></span></span>
                            <Row>
                                <Col sm="4">
                                <span className="d-block"><span><i className="fa fa-percent"></i><span className="px-2">{"Porcentaje"}
                                <Input
                                    type="number"
                                    name="percentage_return"
                                    value={percentage_return}
                                    required={true}
                                    autoComplete="nope"
                                    invalid={percentage_return_cancel_valid}
                                    min={0}
                                    max={100}
                                    onChange={(e) =>handleValidateReturnAmount(e)}
                                    className="form-control form-control-sm input-air-primary"
                                />

                                </span></span></span>
                                </Col>
                                <Col sm="8" className='p-l-0 p-r-0' style={{paddingTop:"20px"}} > 
                                <b style={{fontSize:"11px"}} class="primary text-center">La cantidad a devolver es: ${totalCancellationWorkOrder}</b>

                                </Col>
                            </Row>
                            

                            </Media>
                    </Media>
                    <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                    <Row>
                        <Col sm="5">
                        </Col>
                        <Col sm="3">
                        <Button color="danger text-center" type="button" onClick={toggle}>{"Cancelar"}</Button>
                        </Col>
                        <Col sm="3">
                        <Button color="success text-center" type="button" onClick={validatePasswordCancell} >{"Autorizar"}</Button>  
                        </Col>
                    </Row>
                        
                    </Col>
                    </>
                }
            </CardBody>   
            </ModalBody>
        </Form>
    </Modal>
    )
}
