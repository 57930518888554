import React from 'react'

import { Modal, Col, Form, Label, Row,  } from 'reactstrap';

export const ModalTrackingUser = (props) => {

    const {

         modal2, typeModalLogin = "fromCard", loading,card_code, LoginTrankingFormCard,handleInputChangeTFC,cerrarModalUsuario
    } = props;

    

    return (
        <Modal isOpen={modal2} toggle={cerrarModalUsuario} backdrop='static' keyboard={false} centered={true} onSubmit={LoginTrankingFormCard}>
            {
                typeModalLogin === "fromCard" ?
                    <>
                        <div className="colorModalIDmuestraTracking" style={{ textAlignLast: "center" }}>
                            <Col sm="12">
                                <Row>
                                    <Col sm="2"></Col>
                                    <Col sm="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Tarjeta de ID"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-3'>
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={cerrarModalUsuario} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Form className="theme-form" role="form" >
                            <Col sm="12" style={{ border: "#0079C7 3px solid" }} >
                                <Row>  
                                    <Col sm="12" style={{ textAlign: "-webkit-center" }}> <br /><br />
                                        <div>
                                            <input
                                                className="tamañoinputID text-center input-air-primary "
                                                placeholder="Ingresa el número de tu ID"
                                                type='text'
                                                name='card_code'
                                                value={card_code}
                                                onChange={handleInputChangeTFC}
                                                autoComplete="off"
                                            /> <br /><br />
                                        </div>  
                                        <div style={{ textAlign : "-webkit-center" }} >
                                            <button className={loading ? "modalButton" : "modalButton"} type="submit">Ingresar</button>
                                        </div> <br />
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Form>
                    </>
                    :
                    ""
            }
        </Modal>
    )
}
