import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { ModalCreateSpecimen, ModalUpdateSpecimen, ModalDeleteSpecimen, LostConnection } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { usePagination} from '../pagination/usePagination';


export const useSpecimens = ({activeTab}) => {

    const history = useHistory();
    
    const [specimens, setSpecimens] = useState([]);
    const [specimenId, setSpecimenId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        abbreviation: ""
    });

    const { name, abbreviation } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        abbreviation_valid: false
    });

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(specimens.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listspecimens = [];
        specimens.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listspecimens.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSpecimens(listspecimens);
    }, [totalPageCount, currentPage])


    const toggle = () => {
        setModal(!modal);
    }

    useEffect( () => {
        getSpecimens();
    }, [activeTab]);

    const getSpecimens = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Specimen/List");

        if (respuesta.code === 200) {
            let listSpecimen = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            ////console.log(respuesta);

            if (respuesta.data.length >= 1) {


                respuesta.data.forEach( (data,index) => {
                    
                let posicion = index + 1;
                let visibleTable = false;
                
                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                    listSpecimen.push({
                        id_specimen: data.id_specimen,
                        name: data.name,
                        abbreviation: data.abbreviation,
                        visibleTable: visibleTable

                    });
                });

                setSpecimens(listSpecimen);
                setSpecimenData(listSpecimen);
            }
            else{
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSpecimens([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSpecimens);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {
        
        getSpecimens();

    }, [getSpecimens]);

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation: ""
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSpecimenId(0);
        setMethod("create");
        setModalTitle(ModalCreateSpecimen);
        toggle();
    }   

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Specimen");
        if (respuesta.code === 200) {
            getSpecimens();
            reset();
            createSweet("create", "success", "Exito!", "Especímen creado con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_specimen) => {
        let specimen = specimens.find(s => s.id_specimen === id_specimen);

        handleUpdateValues({
            name: specimen.name,
            abbreviation: specimen.abbreviation
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSpecimenId(id_specimen);
        setMethod("update");
        setModalTitle(ModalUpdateSpecimen);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_specimen: specimenId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Specimen/Update");

        if (respuesta.code === 200) {
            getSpecimens();
            createSweet("create", "info", "Exito!", "Especímen actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }
    }

    const handleDelete = (id_specimen) => {
        let specimen = specimens.find(s => s.id_specimen === id_specimen);

        handleUpdateValues({
            name: specimen.name,
            abbreviation: specimen.abbreviation
        });

        setSpecimenId(id_specimen);
        setMethod("delete");
        setModalTitle(ModalDeleteSpecimen);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Specimen/${specimenId}`);

        if (respuesta.code === 200) {
            getSpecimens();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Especímen eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                name_valid: false
            };
        }

        if (formValues.abbreviation.length < 2) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            };
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const [specimenData, setSpecimenData] = useState([]);



    const handleSearchSpecimen = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        specimenData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setSpecimens(newMethod);
    }





    
    return {
        specimens, method,
        handleInputChange, validaciones,
        name, abbreviation,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchSpecimen

    }
}
