import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormCard } from './FormCard';


export const ModalCard = (props) => {
    const {
        modalCard, toggleCard , modalTitleCard, loading, method, typeCard = "typeCard", checkbox, check, 
        handleCheckboxChange, handleInputChangeT, id_departament, departament, handleSectionUser,
        section, changeTree, treeviewRef, tree, onRenderItem, flag, processes, saveCardCode, 
        handleListSectionsCard, card_code, handleInputChange, checkSection, handleCheckChange,
        listDepartament, handleSelectValues, formUser, id_departaments, listDepa,

    } = props; 
 
    return (

        <Modal isOpen={modalCard} toggle={toggleCard} backdrop='static' keyboard={true} centered={false}>
            <ModalHeader toggle={toggleCard} className="bg-primary">
                {modalTitleCard}
            </ModalHeader>
            {
                typeCard === "typeCard"
                    ?
                    <FormCard button='Guardar' {...{ toggleCard, loading, modalCard, modalTitleCard, 
                        handleCheckboxChange, method, typeCard, checkbox, check, handleInputChangeT, 
                        id_departament, departament, handleSectionUser, section, changeTree, treeviewRef, tree,
                        onRenderItem, processes, methodAction:saveCardCode, handleListSectionsCard,
                        card_code, handleInputChange, checkSection, handleCheckChange, listDepartament,
                        handleSelectValues, formUser, id_departaments, listDepa,
                    }} />
                    : " "
            }

        </Modal>
    )
}
