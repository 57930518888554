import React, { Fragment, useState } from 'react';
import { Card, Col, Container, Row, Button, CardBody,Label,FormGroup,Input,Collapse ,CardHeader } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useLogs } from '../../../hooks/logss/useLogs'
import Datetime from 'react-datetime';
import { SearchAsync } from '../../../components/elements/SearchAsync';


export const Indexlog = () => {

    const {

        status_code, method, url,handleFilters,handleInputChange,dataLog,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages,handlePickerValues,from,to,ids_users,username,
        handleChangeUser,userState
    
        } = useLogs();


    const [filterLogs, setFilterLogs] = useState(true);

  

    return (
        <Fragment>
            <Container fluid={true}>
              <Row>
              <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="3"> 
                    <Row className="position-sticky" style={{ top: 140 }}>
                        <Col xl="12">
                            <Card>
                                <CardHeader className=' bg-primary '>
                                    <h5 className="mb-0">
                                    <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterLogs(!filterLogs)}
                                      data-target="#collapseicon" aria-expanded={filterLogs} aria-controls="collapseicon"><i className="icofont icofont-laboratory"></i>&nbsp; &nbsp; &nbsp; &nbsp; Filtro</Button>
                                    </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filterLogs}>
                                        <Col sm="12">  <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                          <label>Fecha Inicio</label>
                                          <FormGroup className="col-md-12 p-r-0 p-l-0 ">
                                            <Datetime
                                              inputProps={{
                                              placeholder: 'dd/mm/yyyy',
                                              name: 'from',
                                              autoComplete: "off",
                                              className: "form-control form-control-sm input-air-primary"
                                            }}
                                              timeFormat={false}
                                              value={from}
                                              locale="es"
                                              onChange={(e) => handlePickerValues(e, "from")} 
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                          <label>Fecha fin</label>
                                          <FormGroup className="col-md-12 p-r-0 p-l-0 ">
                                            <Datetime
                                              inputProps={{
                                              placeholder: 'dd/mm/yyyy',
                                              name: 'to',
                                              autoComplete: "off",
                                              className: "form-control form-control-sm input-air-primary"
                                            }}
                                              timeFormat={false}
                                              value={to}
                                              locale="es"
                                              onChange={(e) => handlePickerValues(e, "to")} 
                                            />
                                          </FormGroup>
                                         </Col>
                                       
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-t-0'>
                                        <label>Usuarios</label>
                                        <FormGroup>
                                        <SearchAsync
                                            value={ids_users}
                                            name="ids_users"
                                            method={handleChangeUser}
                                            loincState={userState}
                                            url="Users/SearchUser"
                                            maxLenght={3}
                                            mMultiple={true}
                                          />
                                        </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <Label>Metódo</Label> <br />
                                          <FormGroup className="m-0">
                                            <Input 
                                              type='text' 
                                              name="method" 
                                              onChange={handleInputChange}
                                              value={method}  
                                              className="form-control form-control-sm input-air-primary"
                                            />  
                                          </FormGroup>
                                        </Col>   <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label>Estatus</label>
                                            <FormGroup className="m-0">
                                            <Input 
                                              type='text' 
                                              name="status_code" 
                                              onChange={handleInputChange}
                                              value={status_code}  
                                              className="form-control form-control-sm input-air-primary"
                                            />  
                                          </FormGroup>
                                        </Col> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label>Url</label>
                                            <FormGroup className="m-0">
                                            <Input 
                                              type='text' 
                                              name="url" 
                                              onChange={handleInputChange}
                                              value={url}  
                                              className="form-control form-control-sm input-air-primary"
                                            />  
                                          </FormGroup>
                                        </Col> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-1' style={{ textAlignLast: "center"}} > <br />
                                        <Button color='primary'  size='sm' outline type='button' onClick={handleFilters}>Buscar</Button>
                                        </Col> 
                                        </Col> <br />
                                    </Collapse>
                                </Card>
                        </Col>  
                    </Row>
                </Col>
                <Col sm="9">
                  <Card>
                    <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">
                          {"Listado de Logs"}
                      </div>
                      <div className='table-responsive'>
                            {
                                dataLog.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "20%" }} alt="" />   <br />                     
                                <br /> <p className="f-18">{"No hay registrado ningun cambio ."}</p>
                                </div>
                                :
                                <div>
                                    <TableStriped
                                     notMaped={["visibleTable"]}
                                     responsive={true}
                                     methodsActions={false}
                                     cabeceras={["Usuario", "Url", "Metódo", "Estatus","Fecha Ingresada"]}
                                     items={dataLog}
                                     nextPage={goNextPage}
                                     previousPage={goPreviousPage}
                                     totalPageCount={totalPages}
                                     currentPage={actualPage}
                                     goToPage={goToPage}
                                     paginingBack={true}
                                    />
                                </div>
                            }
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>

           
        
        </Fragment>
    )
}

  //ver lo del calendario que se escriba lo que quiero