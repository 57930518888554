import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Label, FormGroup, Row, Input, Button, Collapse } from 'reactstrap';
import Datetime from 'react-datetime';
import { useInformedConsent } from '../../hooks/InformedConsent/useInformedConsent';
import { RibbonInformedConsent } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';

export const IndexInformedConsent = () => {

    const {
        filters, setFilters, loadingFilters,
        searchNim, date_ini, date_end,
        handleChangeValue,
        handleFilter,
        listInformedConsent,
        printPdfConsentInformed,
        nextPage, previousPage, totalPageCount, currentPage, goToPage,
        handleSearchSample
    } = useInformedConsent();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12" md="3" lg="3">
                        <Row className='position-sticky mt-3'>
                            <Col sm="12" md="12" lg="12" xl="12">
                                <Card>
                                    <CardHeader className='bg-primary'>
                                        <h5 className='mb-2'>
                                            <Button color='link pl-0' data-toggle="collapse" onClick={() => setFilters(!filters)} data-target="#collapseicon" aria-expanded={filters} aria-controls="collapseicon">
                                                <i className='fa fa-filter'></i>&nbsp; &nbsp; &nbsp; &nbsp;<label htmlFor="">Filtros</label>
                                            </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filters}>
                                        <CardBody className="p-2">
                                            <Row>
                                                <Col sm="12" md="12" lg="12" xl="12" className="p-initial">
                                                    <FormGroup>
                                                        <Label>Fecha de inicio <span className='text-secondary f-16'>*</span></Label>
                                                        <Datetime
                                                            inputProps={
                                                                {
                                                                    placeholder: 'DD-MM-YYYY',
                                                                    name: 'date_ini',
                                                                    autoComplete: "nope",
                                                                    className: 'form-control digits input-air-primary form-control-sm'
                                                                }
                                                            }
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            locale="es"
                                                            value={date_ini}
                                                            onChange={(e) => handleChangeValue(e, 'date_ini')}
                                                            closeOnSelect={true}
                                                            nextPage={nextPage}
                                                            previousPage={previousPage}
                                                            totalPageCount={totalPageCount}
                                                            currentPage={currentPage}
                                                            goToPage={goToPage}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12" xl="12" className="p-initial">
                                                    <FormGroup>
                                                        <Label>Fecha de fin <span className='text-secondary f-16'>*</span></Label>
                                                        <Datetime
                                                            inputProps={
                                                                {
                                                                    placeholder: 'DD-MM-YYYY',
                                                                    name: 'date_end',
                                                                    autoComplete: "nope",
                                                                    className: 'form-control digits input-air-primary form-control-sm'
                                                                }
                                                            }
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            locale="es"
                                                            value={date_end}
                                                            onChange={(e) => handleChangeValue(e, 'date_end')}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12" md="12" lg="12" xl="12" className="p-initial">
                                                    <FormGroup>
                                                        <Label>Buscar por NIM <span className='text-secondary f-16'>*</span></Label>
                                                        <Input type='search' name='searchNim' className='form-control digits input-air-primary form-control-sm' onChange={(e) => handleChangeValue(e)} value={searchNim} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12" xl="12" className="p-initial">
                                                    <Button type='button' outline color='primary' className={loadingFilters ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1"} onClick={handleFilter}>Filtrar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9" md="9" lg="9" xl="9">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12">
                                <Card>
                                    <CardBody className='p-1'>
                                        <div className='ribbon ribbon-clip ribbon-primary'>
                                            {RibbonInformedConsent}
                                        </div>
                                        <Row>
                                            <Col sm="12" md="12" lg="12" xl="12">
                                                <div className='table-responsive pt-5'>
                                                    {
                                                        listInformedConsent.length === 0
                                                            ?
                                                            <div className='pt-5' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />

                                                                <br />
                                                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No ha registrado una Búsqueda aún.</p>
                                                            </div>
                                                            :
                                                            <TableStriped
                                                                notMaped={["visibleTable"]}
                                                                methodsActions={true}
                                                                cabeceras={["NIM", "Paciente", "Fecha de admisión"]}
                                                                items={listInformedConsent}
                                                                nextPage={nextPage}
                                                                previousPage={previousPage}
                                                                totalPageCount={totalPageCount}
                                                                currentPage={currentPage}
                                                                goToPage={goToPage}
                                                                methodsAbsolutes={
                                                                    [
                                                                        {
                                                                            type: "linkId",
                                                                            method: printPdfConsentInformed,
                                                                            icon: "icofont icofont-file-pdf",
                                                                            backgroundColor: "#0ba1d6",
                                                                            color: "#fff",
                                                                            name: "printPdfConsentInformed",
                                                                            tooltip: "Consentimiento informado"
                                                                        }
                                                                    ]
                                                                }
                                                            />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
