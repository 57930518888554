import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export const IntegerAndRealNumbersForm = (props) => {

    const {
        activeTab, isSimple, changeUnit,
        id_type_analyte, id_unit, id_unit_to,
        units, unitsTo,
        handleInputChange
    } = props;

    return (
        <>
            <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"} ${(id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "6" || id_type_analyte === "7") ? "" : "d-none"}`}>
                <Label className="text-sm">Unidad de origen </Label>
                <Input
                    disabled={activeTab === 2}
                    className="form-control form-control-sm input-air-primary" 
                    bsSize="sm"
                    type="select"
                    name="id_unit"
                    value={id_unit}
                    onChange={(e) => changeUnit(e)}
                    required={true}
                >
                    <option value={0}>Selecciona una opción</option>
                    {
                        units.length > 0 &&
                        units.map((u, key) => {
                            return <option
                                key={key}
                                value={u.id_unit}
                            >
                                {`${u.unit} (${u.abbreviation})`}
                            </option>
                        })
                    }
                </Input>
            </FormGroup>
            <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"} ${(id_type_analyte === "2" || id_type_analyte === "3") ? "" : "d-none"}`}>
                <Label className="text-sm">Unidad de destino</Label>
                <Input
                    disabled={activeTab === 2}
                    className="form-control form-control-sm input-air-primary" 
                    bsSize="sm"
                    type="select"
                    name="id_unit_to"
                    value={id_unit_to}
                    onChange={handleInputChange}
                    required={true}
                >
                    <option value={0}>Selecciona una opción</option>
                    {
                        unitsTo.length > 0 &&
                        unitsTo.map((u, key) => {
                            return <option
                                key={key}
                                value={u.id_unit_to}
                            >
                                {`${u.name_unit_to} (${u.abbreviation_unit_to})`}
                            </option>
                        })
                    }
                </Input>
            </FormGroup>
        </>
    )
}
