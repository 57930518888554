import React,{ useState } from 'react'
import {  Form,  Input, Col,Card,Button,Row} from 'reactstrap';
import { labelAdeuda,labelAgreementAbonado, labelAgreementVencido,Table} from '../../../constant';
import './styleBalan.css';





export const FormPrice = (props) => {

    let handleView = true;

    const {

         balanceTotal,nameAgreement,amount,handleInputChangeAgreement,methodAction,priceTotal,totales,convertir,metodo,

       }=props;

    if (metodo === "SALDO") {
        handleView = false;
    }

    return (
        <Form role="form" onSubmit={methodAction}>
            <Col  xl="12" className="box-col-6 pr-0 file-spacing  p-0 m-0 t-0 ">
                 <div className="file-sidebar  p-0 m-0   t-0   ">
                             <ul>
                             <li>
                             <div className=" p-2">
                                 <Row>
                                 <Col className="col-md-12 text-center labelAgreementM">
                                 <h5 className='labelAgreementM'>{metodo ==='Saldo' ? 'Abonar adeudo del convenio' : 'Pagar adeudo del convenio'}</h5>
                                 <p className='txt-primary'><h5>{nameAgreement}</h5> </p>
                                 <hr/>
                                  </Col>
                                 </Row>
                                 <br/>
                                <Row>
                                    <Col className={ handleView ? 'col-md-4' : 'd-none' }>
                                            <Row>
                                                <Col className='col-md-4 p-3'>
                                                <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/BalanceCompanyD/AgreementV.svg")} alt="" />
                                                </Col>
                                                <Col className='col-md-8 '>
                                                    <br/>
                                                    <h6 className='labelAgreementM'>{labelAgreementVencido}:</h6><h5 className='txt-danger'>$ { convertir(balanceTotal,2)}</h5>
                                                </Col>
                                            </Row>
                                        <br/>
                                    </Col>
                                    <Col className={ handleView ? 'col-md-4' : 'd-none' } >
                                            <Row>
                                                <Col className='col-md-4  p-3'>
                                                <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/BalanceCompanyD/AgreementA.svg")} alt="" />
                                                </Col>
                                                <Col className='col-md-8 '>
                                                    <br/>
                                                    <h6 className='labelAgreementM'>{labelAgreementAbonado}</h6><h5 className='text-success'>$ { convertir(priceTotal,2)}</h5>
                                                </Col>
                                            </Row>  
                                    <Col/>
                                    </Col>
                                    <Col className={ handleView ? 'col-md-4' : 'd-none' }>
                                            <Row>
                                                <Col className='col-md-4  p-3'>
                                                <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/BalanceCompanyD/AgreementM.svg")} alt="" />
                                                </Col>
                                                <Col className='col-md-8 '>
                                                    <br/>
                                                    <h6 className='labelAgreementM'>{labelAdeuda}:</h6><h5>$ {convertir(totales,2)} </h5>
                                                </Col>
                                            </Row>
                                    </Col>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <Col className=" col-md-5 p-4" style={{textAlign: "-webkit-right"}}>
                                      <h6 className='labelChart '>{metodo === 'Saldo' ? 'Cantidad a abonar ' : 'Cantidad a pagar '}:</h6> 
                                  </Col>
                                  <Col className='col-md-3 p-3'>
                                      <Row>
                                      <Input
                                        className="form-control form-control-sm input-air-primary"
                                        placeholder="Ingresa la cantidad"
                                        value={amount}
                                        onChange={handleInputChangeAgreement}
                                        name='amount'
                                     />
                                      </Row>
                                  </Col>
                                  <br/>
                                  <br/>
                                  </Row>
                                  <Col className='col-md-12' style={{"text-align": "-webkit-right"}}>
                                  <Button outline color="primary "  type="submit" size="sm" >{metodo ==='Saldo' ? 'Abonar' : 'Pagar'}</Button>
                                  </Col>
                        </div>
                       </li>
                    </ul>
                 </div>
             </Col> 
    </Form >
         
    )
}
