import React from 'react';
import { Card, Col,Button } from 'reactstrap'
import { TooltpTable } from '../tables/TooltpTable';


export const CardItemRoles = (props) => {

    const {//
        items, objId, methodsActions, methodsAbsolutes, handleUpdate, handleDelete
    } = props;

    //////console.log(items);

    return (
        <>
            <Col sm="12" md="3" lg="3" xl="3" className={`${!items.visibleTable && 'd-none'} `  }>
                <Card className='shadow custom-card widget-profile b-info border-3 p-1'  style={{ width: "250px" , height: "130px"  }}  >
                    <div className='text-center profile-details mb-0 p-b-0'>
                        <h6 className='f-w-700'>{items.name}</h6>
                    </div>
                    {
                        methodsActions === true 
                        ?
                        <ul className="card-social m-t-0">
                            {
                                methodsAbsolutes !== null &&
                                methodsAbsolutes.map((obj, keyIdAbsolutes) => {
                                    if (obj.type === 'linkId') {
                                        return (
                                            <li key={'list'+keyIdAbsolutes} className="p-1">
                                                <Button type='button' outline color="primary" className='btn-icon btn pt-1 pb-1 pr-3 pl-3 btn-sm'
                                                   // style={{backgroundColor: obj.backgroundColor, color: obj.color}}
                                                    onClick={() => obj.method(items.id_role)}
                                                >
                                                    <i className={obj.icon} id={'tooltip-'+obj.name+items.id_role}></i>
                                                    <TooltpTable
                                                        obj={obj}
                                                        idTool={items.id_role}
                                                    />
                                                </Button>
                                            </li>
                                        )
                                    }
                                })
                            }
                            {
                                handleUpdate && <li className="p-1">
                                    <Button outline color="info" className='pt-1 pb-1 pr-3 pl-3'  onClick={() => handleUpdate(items.id_role)}>
                                        <i className='fa fa-edit f-16' id={'tooltip-'+objId+"-edit"}></i>
                                        <TooltpTable
                                            obj={{
                                                name: objId,
                                                tooltip: "Editar"
                                            }}
                                            idTool={"-edit"}
                                        />
                                    </Button>
                                </li>
                            }
                            {
                                handleDelete && <li className="p-1">
                                    <Button outline color="danger" className='pt-1 pb-1 pr-3 pl-3' onClick={() => handleDelete(items.id_role)}>
                                        <i className='fa fa-trash f-16' id={'tooltip-'+objId+"--delete"}></i>
                                        <TooltpTable
                                            obj={{
                                                name: objId,
                                                tooltip: "Eliminar"
                                            }}
                                            idTool={"--delete"}
                                        />
                                    </Button>
                                    {/* <a href='#javascript' onClick={() => handleDelete(items.id_role)} className="text-danger">
                                        <i className='fa fa-trash f-16' id={'tooltip-'+items.name+"delete"}></i>
                                        <TooltpTable
                                            obj={{
                                                name: items.name,
                                                tooltip: "Eliminar"
                                            }}
                                            idTool={"delete"}
                                        />
                                    </a> */}
                                </li>
                            }
                        </ul>
                        :
                        ""
                    }
                </Card>
            </Col>
        </>
    )
}
