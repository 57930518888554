import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card } from 'reactstrap';
export const ModalViewInvoice = (props) => {

    const {

        toggleInvoice, typeModalInvoice = "viewInvoice", modalInvoice, InvoicePDF, loadingPdf
    } = props;

    return (
        <Modal isOpen={modalInvoice} toggle={toggleInvoice} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggleInvoice} className="bg-primary">
                Previsualización PDF
            </ModalHeader>
            {
                typeModalInvoice === "viewInvoice" ?
                    <ModalBody>
                        {
                            loadingPdf === true ?
                                <Card className='shadow' >
                                    <Row className='mt-2'> <br />
                                        <Col sm="12" md="12" lg="12" className='text-center'>  <br /> <br /> <br /> <br />
                                            <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm="3" md="3" lg="3" className='text-center'></Col>
                                        <Col sm="2" md="2" lg="2" className='text-left p-r-0'>
                                            <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                    </Row>
                                </Card>
                                :

                                <div>
                                    <Col sm="12">
                                        <embed src={'data:application/pdf;base64,' + InvoicePDF} title="Fcturación" type="application/pdf" width="100%" height="600px" />
                                    </Col>

                                </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}