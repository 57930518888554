import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap'

export const DeleteUnitConversion = (props) => {
    const { toggle, loading, saveDelete } = props;

    return (
        <Form role="form" onSubmit={saveDelete}>
            <ModalBody>
                <h6>Estas a punto de eliminar una unidad de conversión
                    <br />
                    <b>¿Estás seguro de hacerlo?</b></h6>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar unidad de conversión
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
