import { ConfigServer } from "../../data/config";

export const sendRequest = async (requestOptions, endpoint, contentType = 'application/json') => {


    let myHeaders = new Headers();

    let token = localStorage.getItem("token");

    myHeaders.append("Authorization", `Bearer ${token}`);
    if (contentType !== 'multipart/form-data') {
        myHeaders.append("Content-Type", contentType);
        // myHeaders.append("Cache-Control", "no-cache,no-store");
    }

    requestOptions = { ...requestOptions, headers: myHeaders }

    //TODO: Esto se debe regresar para que funcione
    const response = await fetch(ConfigServer.serverUrl + "/api/" + endpoint, requestOptions)
        // const response = await fetch("http://localhost:5000/api/" + endpoint, requestOptions)
        .catch(function () {
            return {
                status: 0,
                msg: "Error fatal"
            }
        })
    let code = await response.status;
    let respuesta = { code };

    if (code === 200) {
        let data;
        try {
            data = await response.json();
        } catch (error) {
            data = error;
        }
        respuesta = { ...respuesta, data };
    }
    else {
        let data;

        try {
            data = await response.json();

        }
        catch (error) {

            data = error;
        }

        respuesta = { ...respuesta, data };
    }

    return respuesta;

}