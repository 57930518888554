import React from 'react'
import { useState, useEffect } from 'react'
import { LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';


export const useIndications = () => {


    const [createSweet] = useSweetAlert();
    const history = useHistory();

    //MODAL INDICATION CREATE
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);

    //MODAL SEND CSV
    const [modal2, setModal2] = useState(false);
    const [method2, setMethod2] = useState("");
    const [modalTitle2, setModalTitle2] = useState("");

    const [listIndications, setListIndications] = useState([]);
    const [indicationId, setIndicationId] = useState(0)



    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({

        id_exam_indication: 0,
        indication: "",
        //file: ""

    });

    const { id_exam_indication, indication } = formValues;

    // ////console.log(formValues);

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listIndications.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listInd = [];
        listIndications.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listInd.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListIndications(listInd);

    }, [totalPageCount, currentPage])

    const [validaciones, SetValidaciones] = useState({
        id_exam_indication_valid: false,
        indication_valid: false,
    });


    useEffect(() => {

        getExamIndication();

    }, [history.location])


    const toggle = () => {
        setModal(!modal);
    }

    const toggle2 = () => {
        setModal2(!modal2)

    }


    const getExamIndication = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListIndication = [];

        const respuesta = await sendRequest(requestOptions, "ExamIndication/List");


        if (respuesta.code === 200) {


            if (respuesta.data.length >= 1) {

                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                respuesta.data.forEach((value, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    ListIndication.push({
                        id_exam_indication: value.id_exam_indication,
                        indication: value.indication,
                        visibleTable: visibleTable
                    });
                });
                setListIndications(ListIndication);
                setIndicationData(ListIndication);
            }
            else {
                //createSweet("error", "error", "Hubo un error!", "No se encontraron las indicaciones");
                setListIndications([]);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExamIndication);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Indicaciones", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Indicaciones", respuesta.data.msg);
        }
    };


    //CREATE  INDICATION
    const handleCreate = () => {

        handleUpdateValues({
            id_exam_indication: 0,
            indication: ""
        });

        setIndicationId(0);
        setMethod("create");
        setModalTitle("Crear Indicaciones para el exámen");
        toggle();
    };

    //SEND CSV
    const handleSendCSV = () => {

        setMethod2("listCSV");
        setModalTitle2("Cargar archivo CSV")
        toggle2();

    }



    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);


        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mData = formValues;

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(mData)
        };

        const respuesta = await sendRequest(requestOptions, "ExamIndication/Create");
        if (respuesta.code === 200) {

            getExamIndication();
            reset();
            createSweet("create", "success", "Exito!", "Indicaciones creadas exitosamente");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Indicaciones", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Indicaciones", respuesta.data.msg);
        }
    }


    const handleUpdate = (id_exam_indication) => {

        let reason = listIndications.find(b => b.id_exam_indication === parseInt(id_exam_indication));

        handleUpdateValues({
            id_exam_indication: reason.id_exam_indication,
            indication: reason.indication
        });

        setIndicationId(id_exam_indication);
        setMethod("update");
        setModalTitle("Modificar las indicaciones del exámen");
        toggle();
    }


    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }


        let body = {
            id_exam_indication: indicationId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "ExamIndication/Update");

        if (respuesta.code === 200) {
            getExamIndication();
            createSweet("create", "info", "Exito!", "Indicación actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Grafica", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Grafica", respuesta.data.msg);
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    };

    const handleDelete = (id_exam_indication) => {

        let reason = listIndications.find(b => b.id_exam_indication === parseInt(id_exam_indication));

        handleUpdateValues({
            indication: reason.indication
        });
        setIndicationId(id_exam_indication);
        setMethod("delete");
        setModalTitle("Eliminar indicación");
        toggle();
    };

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `ExamIndication/Delete/${indicationId}`);

        if (respuesta.code === 200) {
            getExamIndication();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Indicación Eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Indicaciones", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Indicaciones", respuesta.data.msg);
        }
    };

    //SUBIR ARCHIVO CSS
    const ExamIndication = async (e) => {

        e.preventDefault();
        setLoading(true);

        var formData = new FormData();

        //formData.append('file', file);
        formData.append('csvFile', file);
        formData.append('type', 0);


        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const respuesta = await sendRequest(requestOptions, "Exam/UploadCsvExamIndications", 'multipart/form-data');

        if (respuesta.code === 200) {
            getExamIndication();
            createSweet("create", "info", "Exito!", "Indicaciones actualizadas");
            setLoading(false);
            toggle2();

        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, ExamIndication);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Indicaciones", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Indicaciones", respuesta.data.msg);
        }
    }


    const [file, setFile] = useState("");


    const handleOnChange = (e) => {
        setFile(e.target.files[0]);

    };


    const validarForm = () => {

        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.indication === "") {
            newValidaciones = {
                ...newValidaciones,
                indication_valid: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                indication_valid: false
            };
        }

        SetValidaciones(newValidaciones);
        return statusValidacion;
    };
    
    const [indicationData, setIndicationData] = useState([]);

    const handleSearchIndication = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        indicationData.forEach(element => {            

            let cadena = element.indication.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setListIndications(newMethod);
    }



    return {
        //LIST INDICATIONS
        listIndications,
        //MODAL CREATE INDICATIONS
        method, modal, modalTitle, loading, toggle,
        handleCreate, saveCreate, handleUpdate, saveUpdate, handleDelete, saveDelete, validaciones,
        id_exam_indication, indication, handleInputChange,
        //PAGINATION FRONT-END
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        //MODAL SEND CSV
        handleSendCSV, modal2, method2, modalTitle2, toggle2, file,
        handleOnChange, ExamIndication,
        handleSearchIndication


    }
}
