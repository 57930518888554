import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormPrinterPoint = (props) => {

    const {
        toggle, loading, name, username, password, password_confirmation,
        handleInputChange, validaciones, methodAction, button
    } = props;
    const {
        name: validacionName,
        username: validacionUsername,
        password: validacionPassword,
        password_confirmation: validacionPasswordConfirmation
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Nombre del punto de impresión <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={name}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validacionName}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Nombre del usuario <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="username"
                                value={username}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validacionUsername}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El nombre de usuario debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>   
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Contraseña <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="password"
                                name="password"
                                value={password}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validacionPassword}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>La contraseña debe debe tener 5 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Confirmación de contraseñá <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="password"
                                name="password_confirmation"
                                value={password_confirmation}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validacionPasswordConfirmation}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Las contraseñas no coinciden, por favor verifique su información</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                        {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
