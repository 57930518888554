import React from 'react'
import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router';
// import { usePagination } from '../pagination/usePagination';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';
import { LostConnection } from '../../constant';


export const useExamMicrobiology = (isSimple = false) => {
    const history = useHistory();
    const { examEditId } = useParams();
    const { examEditIm } = useParams();


    const goToBack = () => {
        history.goBack();
    }


    const [filters, setfilters] = useState("&is_referenced=false&is_micro=true");


    const [formValuesFilter, handleInputChangeFilter, resetFilter, handleUpdateValuesFilter, handleSelectValuesFilter, handlePickerValuesFilter] = useForm({
        is_referencedf: "false",
        is_simplef: "",
        individual_salef: "",
        is_criticf: "",
        is_completef: "",
        id_sectionf: "0",
        is_microf: "false"
    });

    const { is_referencedf, is_simplef, individual_salef, is_criticf, is_completef, id_sectionf, is_microf } = formValuesFilter;


    const handleFilters = () => {

        let query = "";

        if (is_referencedf !== "") {
            query += `&is_referenced=${is_referencedf}`;
        }

        if (is_simplef !== "") {
            query += `&is_simple=${is_simplef}`;
        }

        if (individual_salef !== "") {
            query += `&individual_sale=${individual_salef}`;
        }

        if (is_criticf !== "") {
            query += `&is_critic=${is_criticf}`;
        }

        if (is_completef !== "") {
            query += `&is_complete=${is_completef}`;
        }

        if (id_sectionf !== "0") {
            query += `&id_section=${id_sectionf}`;
        }
        if (is_microf !== "") {
            query += `&is_micro=${is_microf}`;
        }

        setfilters(query);
    }



    // TODO: PAGINACIÓN
    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 500);

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])

    useEffect(() => {

        searchAsync();

    }, [filters])

    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Exam/List",
        history,
        searchQuery,
        filters
    );


    useEffect(() => {

        let listExams = [];

        results.forEach((data, key) => {
            let icon = <i className="fa fa-check txt-success"></i>
            let icon2 = <i className="fa fa-times text-danger"></i>

            data.is_complete = (data.is_complete === true ? icon : icon2)

            let visibleTable = true;

            listExams.push({
                ...data,
                typeExam: data.is_simple ? "Sencillo" : (data.is_imagenology === true ? "Imagenologia" : (data.is_micro === true ? "Micro" : "Complejo")),
                visibleTable: visibleTable
            });
        });
        setExamsData(results);
        setExams(listExams);

    }, [results]);


    const [listSection, setListSection] = useState([]);

    const getSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListSectionn = [];

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListSectionn.push({
                        id_section: value.id_section,
                        name: value.name,
                    });
                });
                setListSection(ListSectionn);
            }
            else {
                setListSection([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSection);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Exámen", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Exámen", respuesta.data.msg);
        }
    }

    const handleChangeSection = (e) => {

        handleInputChangeFilter(e)

        let p = e.target.value

        getSection(p)

    }

    const clearFilter = () => {

        handleUpdateValuesFilter({

            is_referencedf: "false",
            is_simplef: "",
            individual_salef: "",
            is_criticf: "",
            is_completef: "",
            id_sectionf: "0",

        })

        // ({...formValuesFilter})
    }


    useEffect(() => {
        getCommercialLines();
        getSections();
        // getSpecimenOrigins();
        getSpecimens();
        getSpecimenProvenances();
        // getTypeTransports();
        getTransports();
        getSection();
        getSections2();
    }, []);


    const [sections, setSections] = useState([]);
    const [sections2, setSections2] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const [specimenOrigins, setSpecimenOrigins] = useState([]);
    const [commercialLines, setCommercialLines] = useState([]);
    const [specimens, setSpecimens] = useState([]);
    const [specimenProvenances, setSpecimenProvenances] = useState([]);
    const [typeTransports, setTypeTransports] = useState([]);
    const [transports, setTransports] = useState([]);

    const [examsData, setExamsData] = useState([]);
    const [exams, setExams] = useState([]);
    const [examId, setExamId] = useState(0);
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [tabGeneral, setTabGeneral] = useState('1');

    //MODAL INDICATIONS
    const [modal2, setModal2] = useState(false);
    const [listIndications, setListIndications] = useState([]);
    const [indicationn, setIndicationn] = useState([]);
    const [selectIndication, setSelectIndication] = useState([]);

    //MODAL IMAGENOLOGÍA
    const [isImagenologyy, setIsImagenologyy] = useState(false)
    const [methodImagenolofia, setMethodImagenolofia] = useState("");
    const [titleImagenologia, setTitleImagenologia] = useState("");
    const [modalImagenologia, setModalImagenologia] = useState(false);


    //VALID RELEASE EXAM
    const [releaseExam, setReleaseExam] = useState({
        exams_transports: false,
        production_cost: false,
        valid_method_default: false,
        valid_analyte: false,
        valid_analyte_age_gender_range: false,
        valid_reference_values: false,
        valid_indications: false
    });
    const [modalReleaseExam, setModalReleaseExam] = useState(false);
    const [loadingReleaseExam, setLoadingReleaseExam] = useState(false);

    const changeTabGeneral = (number) => {
        setTabGeneral(number);
    }

    const toggle = () => {
        setModal(!modal);
    }

    //MODAL INDICATIONS
    const toggle2 = () => {
        setModal2(!modal2)
    }
    //datos encontrados del examen de iamgenologias
    //MODAL RELEASE EXAM
    const toggleRelease = () => {
        setModalReleaseExam(!modalReleaseExam)
    }

    //MODAL IMAGENOLOGIA

    const toggleImagenologia = () => {
        setModalImagenologia(!modalImagenologia);
    }



    const [formValues, handleInputChange, , handleUpdateValues, handleSelectValues] = useForm({
        id_section: 0,
        id_subsection: 0,
        id_specimen_origin: 0,
        id_specimen: 0,
        id_specimen_provenance: 0,
        code: "",
        alias: "",
        name: "",
        abbreviation: "",
        delivery_time: "",
        individual_sale: false,
        cost: 0.0,
        is_referenced: false,
        is_critic: false,
        use_antibiogram: false,

        is_curve: false,
        listExternalLaboratories: [],
        list_transports: [],
        //nuevo_campo: 0,
        //nuevo_campo2: 0
        number_projections: 0,
        enable_analyte_configuration: false


    });

    const { id_section, id_subsection, id_specimen_origin, id_specimen, id_specimen_provenance, code, alias, name,
        abbreviation, delivery_time, individual_sale, cost, is_referenced, is_critic, is_curve, listExternalLaboratories,
        list_transports, number_projections, enable_analyte_configuration, use_antibiogram
        //nuevo_campo, nuevo_campo2
    } = formValues;

    const [validaciones, setValidaciones] = useState({
        id_section: false,
        id_specimen_origin: false,
        code: false,
        alias: false,
        name: false,
        abbreviation: false
    });

    useEffect(() => {

        // getExamIndication();
        getExamIndicationSelect();


    }, [history.location]);



    // METODOS PARA LA SELECCIÓN DEL METODO
    const [appCodeMethodId, setAppCodeMethodId] = useState(0);
    const [dataAppCodeMethod, setDataAppCodeMethod] = useState(null);

    const changeMethodDefault = ({ target }) => {
        setAppCodeMethodId(target.value);
    }

    const saveAppCodeMethodFromExam = async () => {
        setLoading(true);
        let formData = {
            "id_app_code_method": appCodeMethodId,
            "id_exam": examId
        }

        setLoading(true);

        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods/AddExamMethodMachine");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Método seleccionado correctamente");
            let name_method = respuesta.data.name_method;
            setDataAppCodeMethod(name_method);
            changeTabGeneral("3");
            let location = {
                pathname: '../examenes/simple/' + examId,
                state: { changeTab: 2 }
            }
            history.replace(location);
            ////console.log(history);
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveAppCodeMethodFromExam);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveAppCodeMethodFromExam);
        //     setLoading(false);
        // }
    }

    const updateAppCodeMethodExam = async () => {

        setLoading(true);

        let formData = {
            "id_app_code_method": appCodeMethodId,
            "id_exam": examId
        }

        setLoading(true);

        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'PUT',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods/UpdateExamMethodMachine");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Método seleccionado correctamente");
            let name_method = respuesta.data.name_method;
            setDataAppCodeMethod(name_method);

        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, updateAppCodeMethodExam);
        }
        else if (respuesta.code === 500) {
            setLoading(false);

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, updateAppCodeMethodExam);
        //     setLoading(false);
        // }
    }

    const getSections = async () => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {
            setSections(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getSections);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }
    const getSections2 = async () => {
        let requestOptions = {
            method: 'GET'
        }


        const respuesta = await sendRequest(requestOptions, "Section/List/true");
        if (respuesta.code === 200) {
            setSections2(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getSections2);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }


    const getSubsections = async (id_section) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `SubSection/GetRelationSubsection/${id_section}`);
        if (respuesta.code === 200) {
            setSubSections(respuesta.data);
        }
    }

    const getSpecimenOrigins = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenOrigins");
        if (respuesta.code === 200) {
            setSpecimenOrigins(respuesta.data);
        }
        else {
            validarSesion(history, respuesta.code, getSpecimenOrigins);
        }
    }

    const getCommercialLines = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code === 200) {
            let newCommercialLine = [];
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((item) => {
                    newCommercialLine.push({
                        id_commercial_line: item.id_commercial_line,
                        long_name: item.long_name,
                        short_name: item.short_name,
                        price_commercial_line: 0
                    });
                });

                setCommercialLines(newCommercialLine);
            }
            else {
                setCommercialLines([]);
            }
        }
        else {
            setCommercialLines([]);
            validarSesion(history, respuesta.code, getCommercialLines);
        }
    }

    const getSpecimens = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Specimen/List");
        if (respuesta.code === 200) {

            let newSpecimens = respuesta.data;
            newSpecimens.map(function (x) {
                x.value = x.id_specimen;
                x.label = x.name;
                return x;
            })

            setSpecimens(newSpecimens);
        }
        else {
            validarSesion(history, respuesta.code, getSpecimens);
        }
    }


    const getSpecimenProvenances = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenProvenances/List");
        if (respuesta.code === 200) {
            let newSpecimenProvences = respuesta.data;
            newSpecimenProvences.map(function (x) {
                x.value = x.id_specimen_provenance;
                x.label = x.name;
                return x;
            })

            setSpecimenProvenances(newSpecimenProvences);
        }
        else {
            validarSesion(history, respuesta.code, getSpecimenProvenances);
        }
    }

    const getTransports = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Transport");
        if (respuesta.code === 200) {
            let newTransports = respuesta.data;
            newTransports.map(function (x) {
                x.value = x.id_transport;
                x.label = `${x.name}[${x.abbreviation}]`;
                return x;
            })

            setTransports(newTransports);
        }
        else {
            validarSesion(history, respuesta.code, getTransports);
        }
    }

    const getTypeTransports = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "TypeTransports");
        if (respuesta.code === 200) {
            let newTypeTransports = respuesta.data;
            newTypeTransports.map(function (x) {
                x.value = x.id_type_transport;
                x.label = x.name;
                return x;
            })

            setTypeTransports(newTypeTransports);
        }
        else {
            validarSesion(history, respuesta.code, getTypeTransports);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_section: 0,
            id_subsection: 0,
            id_specimen_origin: 0,
            code: "",
            alias: "",
            name: "",
            abbreviation: "",
            delivery_time: "",
            individual_sale: false,
            cost: 0.00,
            is_referenced: false,
            is_critic: false,
            is_curve: false,
            use_antibiogram: false
        });

        setValidaciones({
            id_section: false,
            id_specimen_origin: false,
            code: false,
            alias: false,
            name: false,
            abbreviation: false,
            individual_sale: false,
            is_referenced: false
        });

        setExamId(0);
        setMethod("create");
        setModalTitle("Crear nuevo exámen");
        getSections();
        getCommercialLines();
        getSpecimens();
        getSpecimenProvenances();
        getTransports();
        toggle();
        setIsImagenologyy(false);

    }


    const handleCreateExamImagenologia = () => {


        handleUpdateValues({
            id_section: 0,
            id_subsection: 0,
            id_specimen_origin: 0,
            code: "",
            alias: "",
            name: "",
            abbreviation: "",
            delivery_time: "",
            individual_sale: false,
            cost: 0.00,
            is_referenced: false,
            is_critic: false,
            number_projections: 0,

        });

        setExamId(0);
        getSections2();
        setMethodImagenolofia("createImagen")
        setTitleImagenologia("Crear exámen nuevo")
        toggleImagenologia();
        setIsImagenologyy(true);
        // getExam()

    }


    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let body = {
            list_prices: commercialLines,
            ...{ ...formValues },
            list_transports: (isImagenologyy ? [] : formValues.list_transports),
            "id_specimen": id_specimen.value,
            "id_specimen_provenance": id_specimen_provenance.value,
            "is_simple": isSimple,
            //TODO: SI LA VARIABLE isImagenologyy == true -> hay que poner enable_analyte_configuration en 'false' -> si no es true
            //YA QUE LOS EXAMENES DE IMAGENOLOGIA NO LLEVAN CONFIGURACION DE ANALITOS
            "enable_analyte_configuration": (isImagenologyy ? false : true)
        }


        let raw = JSON.stringify(body);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "Exam");
        if (respuesta.code === 200) {
            createSweet("create", "success");
            setLoading(false);

            if (isImagenologyy) {
                setExamId(respuesta.data.id_exam);
                toggleImagenologia();
                searchAsync();


            }
            else if (isSimple) {
                setExamId(respuesta.data.id_exam);
                changeTabGeneral('2');
                return respuesta.data.id_exam;
            } else {
                toggle();
                navigateConfigExam(respuesta.data.id_exam);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        setLoading(false);

    }

    const handleUpdate = (id_exam) => {
        let exam = exams.find(e => e.id_exam === parseInt(id_exam));

        ////console.log(exam);

        handleUpdateValues({
            id_section: exam.id_section,
            id_subsection: exam.id_subsection === null ? 0 : exam.id_subsection,
            id_specimen_origin: exam.id_specimen_origin,
            code: exam.code,
            alias: exam.alias,
            name: exam.name,
            abbreviation: exam.abbreviation,
            delivery_time: exam.delivery_time,
            individual_sale: exam.individual_sale,
            is_referenced: exam.is_referenced,
            is_critic: exam.is_critic,
            use_antibiogram: exam.use_antibiogram,
            is_curve: exam.is_curve,
            listExternalLaboratories: exam.listExternalLaboratories,
            id_specimen: specimens.find(x => x.value === exam.id_specimen),
            id_specimen_provenance: specimenProvenances.find(x => x.value === exam.id_specimen_provenance),
            list_transports: exam.select_transports
        });

        setValidaciones({
            id_section: false,
            id_specimen_origin: false,
            code: false,
            alias: false,
            name: false,
            abbreviation: false
        });

        setExamId(id_exam);
        setMethod("update");
        setModalTitle("Modificar exámen");
        getSections();
        getSubsections(id_section);
        getCommercialLines();
        toggle();
        setIsImagenologyy(false);
    }

    const handleUpdateImagenology = (id_exam) => {
        let exam = exams.find(e => e.id_exam === parseInt(id_exam));

        handleUpdateValues({
            id_specimen_origin: exam.id_specimen_origin,
            id_specimen_provenance: specimenProvenances.find(x => x.value === exam.id_specimen_provenance),
            id_specimen: specimens.find(x => x.value === exam.id_specimen),
            id_section: exam.id_section,
            id_subsection: exam.id_subsection === null ? 0 : exam.id_subsection,
            code: exam.code,
            alias: exam.alias,
            name: exam.name,
            abbreviation: exam.abbreviation,
            delivery_time: exam.delivery_time,
            individual_sale: exam.individual_sale,
            is_referenced: exam.is_referenced,
            is_critic: exam.is_critic,
            number_projections: exam.number_projections,

            //nuevo_campo: exam.nuevo_campo
            // is_curve: exam.is_curve,
            // listExternalLaboratories: exam.listExternalLaboratories,
            //id_specimen: specimens.find(x => x.value === exam.id_specimen),
            // id_specimen_provenance: specimenProvenances.find(x => x.value === exam.id_specimen_provenance),
            // list_transports: exam.select_transports

        });

        // setValidaciones({
        //     id_section: false,
        //     id_specimen_origin: false,
        //     code: false,
        //     alias: false,
        //     name: false,
        //     abbreviation: false
        // });

        setExamId(id_exam);
        setMethodImagenolofia("updateImagen")
        setTitleImagenologia("Actualizar el exámen")
        getSections2();
        getSubsections(id_section);
        getCommercialLines();
        toggleImagenologia();
        setIsImagenologyy(true);
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let body = {
            id_exam: examId,
            ...{ ...formValues },
            list_prices: commercialLines,
            "id_specimen": id_specimen.value,
            "id_specimen_provenance": id_specimen_provenance.value,
            "enable_analyte_configuration": isImagenologyy

        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

       const respuesta = await sendRequest(requestOptions, "Exam/Update");
        ////console.log(respuesta);
        ////console.log(body);

        if (respuesta.code === 200) {
            if (isSimple) {
                createSweet("update", "success");
                setLoading(false);

            }
            else if (!isSimple && isImagenologyy) {
                createSweet("update", "success")
                setLoading(false)
                toggleImagenologia()
                searchAsync();

            }
            else if (!isSimple && !isImagenologyy) {
                exams.map(e => {
                    if (e.id_exam === parseInt(examId)) {
                        e.select_transports = formValues.list_transports;
                    }
                    return e;
                });

                createSweet("update", "success");
                setLoading(false);

                toggle();
                searchAsync();
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    const handleDelete = (id_exam) => {
        let exam = exams.find(e => e.id_exam === parseInt(id_exam));

        handleUpdateValues({
            name: exam.name,
            code: exam.code,
            alias: exam.alias
        });

        setExamId(id_exam);
        setMethod("delete");
        setModalTitle("Eliminar exámen");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/${examId}`);

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("delete", "success");
            let examenes = exams;
            examenes = examenes.filter(x => x.id_exam !== parseInt(examId));
            setExams([...examenes])
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveDelete);
        // }
    }

    const handleListProfile = (id_exam) => {

        let exam = exams.find(e => e.id_exam === parseInt(id_exam));
        ////console.log(exam);
        setExamId(id_exam);
        setMethod("list");
        setModalTitle("Asignar indicaciones al exámen");
        toggle2();
        getListIndicationsByExam(id_exam)

    }

    // const getExamIndication = async () => {

    //     let requestOptions = {
    //         method: 'GET'
    //     };

    //     let ListCdfi = [];

    //     const respuesta = await sendRequest(requestOptions, "ExamIndication/List");

    //     if (respuesta.code === 200) {

    //         if (respuesta.data.length >= 1) {
    //             respuesta.data.forEach((value) => {
    //                 ListCdfi.push({
    //                     id_exam_indication: value.id_exam_indication,
    //                     indication: value.indication,
    //                 });
    //             });
    //             setListIndications(ListCdfi);
    //         }
    //         else {
    //             // createSweet("error", "error", "Hubo un error!", "No se encontro ningun Cdfi");
    //             setListIndications([]);
    //         }
    //     }
    //     else if (respuesta.code === 401) {

    //         validarSesion(history, respuesta.code, getExamIndication);
    //     }
    //     else if (respuesta.code === 500) {

    //         createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
    //     }
    //     else {

    //         createSweet("error", "warning", "Examen", respuesta.data.msg);
    //     }
    // }

    const [dataIndication, setDataIndication] = useState([])


    const getExamIndicationSelect = async () => {

        let copyList = listIndication;

        let requestOptions = {
            method: 'GET'
        };


        const respuesta = await sendRequest(requestOptions, "ExamIndication/List");
        let new_array = [];

        if (respuesta.data.length >= 1) {

            respuesta.data.forEach((element) => {

                let obj = {
                    "value": element.id_exam_indication,
                    "label": element.indication,
                    "name": element.indication,
                }

                new_array.push(obj);
                //  copyList

            });
            setDataIndication(new_array);

        }

    }



    const [formIndication, handleInputChangeIndication, resetIndication, handleUpdateValuesIndication, handleSelectValuesIndication] = useForm({
        listIndication: [],
    });



    const { indication, id_exam_indication, listIndication } = formIndication;

    // const handleChangeIndication = (e) => {

    //     handleInputChangeIndication(e);

    //     let copyList = listIndication;

    //     let p = e.target.value

    //     let find = copyList.find(x => x.id_exam_indication == p);

    //     if (!find) {
    //         handleGetIndication(p);
    //     }
    //     else {
    //         createSweet("error", "error", "Ya existe la indicación seleccionada");
    //         setSelectIndication({});
    //     }
    // };

    // const handleGetIndication = async (id_exam_indication) => {
    //     let copyList = listIndication;

    //     if (id_exam_indication != 0) {

    //         let requestOptions = {
    //             method: 'GET'
    //         };

    //         const respuesta = await sendRequest(requestOptions, "ExamIndication/FindIndicationId/" + id_exam_indication);

    //         if (respuesta.code === 200) {

    //             copyList.push(respuesta.data);
    //         }
    //         else if (respuesta.code === 401) {

    //             validarSesion(history, respuesta.code, handleGetIndication);
    //         }
    //         else if (respuesta.code === 500) {

    //             createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
    //         }
    //         else {

    //             createSweet("error", "warning", "Examen", respuesta.data.msg);
    //         }

    //         handleUpdateValuesIndication({
    //             ...formIndication,
    //             listIndication: [...copyList]
    //         });
    //     }
    // };

    // const removeExamProfile = (indice) => {

    //     let find = listIndication;
    //     find.splice(indice, 1)

    //     handleUpdateValuesIndication({
    //         ...formIndication,
    //         listIndication: [...find]
    //     })
    // };

    const getListIndicationsByExam = async (id_exam) => {

        if (id_exam != 0) {

            let requestOptions = {
                method: 'GET'
            };

            const respuesta = await sendRequest(requestOptions, "Exam/ListIndicationsByExam/" + id_exam);

            if (respuesta.code === 200) {

                let arrTmp = [];

                if (respuesta.data.length > 0) {
                    respuesta.data.forEach(element => {
                        arrTmp.push({
                            "value": element.id_exam_indication,
                            "label": element.indication,
                            "name": element.indication,
                        })
                    });
                }

                handleUpdateValuesIndication({
                    ...formIndication,
                    listIndication: [...arrTmp]
                });
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, getListIndicationsByExam);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen", respuesta.data.msg);
            }
        }
    };


    const CreateOrUpdateExamIndications = async (e) => {

        e.preventDefault();
        setLoading(true);

        let newList = formIndication.listIndication;

        newList.map(element => {
            element.id_exam_indication = element.value;
        });

        formIndication.listIndication = newList

        let mData = {
            id_exam: examId,
            listIndications: formIndication.listIndication//robin
        };




        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(mData)
        };

        const respuesta = await sendRequest(requestOptions, "Exam/CreateOrUpdateExamIndications");
        ////console.log(respuesta);
        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "info", "Exito!", "Se han asignado correctamente las indicaciones");
            toggle2();

        }
        // else if (respuesta.code === 401) {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, CreateOrUpdateExamIndications);
        // }
        // else if (respuesta.code === 500) {
        //     setLoading(false);
        //     createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
        // }
        // else {
        //     setLoading(false)
        //     createSweet("error", "warning", "Analitos", respuesta.data.msg);
        // }
        // else {
        //     setLoading(false);
        //     createSweet("error", "error", "Hubo un error!");
        // }
    }



    const handleChangePrice = (e) => {

        const _tmpPrices = [...commercialLines];

        _tmpPrices[e.target.dataset.id][e.target.name] = e.target.value;

        setCommercialLines(_tmpPrices);
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.id_section <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_section: false
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_section: false
            }
        }

        if (formValues.id_specimen_origin <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_origin: true
            }
            statusValidacion = false;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_origin: false
            }
        }

        if (formValues.code.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                code: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code: false
            }
        }

        if (formValues.alias.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                alias: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                alias: false
            }
        }

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        if (formValues.abbreviation.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                abbreviation: true
            }
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                abbreviation: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    const changeSelect = (e) => {
        handleInputChange(e);

        let mValue = e.target.value;

        getSubsections(mValue);
    }

    const navigateMicroAnalytes = (examId) => {
        history.push("../../micro/diseno/" + examId + "/analitos");
    }

    // const navigateCreateOrUpdateAnalytes = (examId) => {
    //     history.push("../diseno/examenes/" + examId + "/analitos");
    // }

    const navigateConfigExam = (examId) => {//metodo deanalito
        history.push("../../diseno/examenes/" + examId + "/metodos/Micro");
    }

    // const navigateCostProduction = (examId) => {//metodo de costo
    //     history.push("../diseno/examenes/" + examId + "/precios");
    // }
    const navigateCostProduction = (examId) => {//metodo de costo
        history.push("../../diseno/examenes/Micro/" + examId + "/precios");
    }


    const navigateCreateExamSimple = () => {
        history.push("../diseno/examenes/simple");
    }

    const navigateEditExamSimple = (examId) => {
        let find_exam = exams.find(x => x.id_exam === examId);


        let is_simple = find_exam.is_simple;
        let is_imagenology = find_exam.is_imagenology;

        if (is_simple) {
            history.push("../diseno/examenes/simple/" + examId);

        }
        else if (!is_simple && is_imagenology) {
            handleUpdateImagenology(examId)
        }
        else if (!is_simple && !is_imagenology) {
            handleUpdate(examId)
        }
    }

    useEffect(() => {
        if (examEditId > 0) {
            setExamId(examEditId);
            setMethod("update");
            getExam(examEditId);
        }
    }, [examEditId])


    useEffect(() => {
        if (examEditIm > 0) {
            setExamId(examEditIm)
            setMethodImagenolofia("updateImagen")
            getExam(examEditIm);
        }
    }, [examEditIm])


    const getExam = async (examId) => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + examId);

        if (respuesta.code === 200) {
            handleUpdateValues({
                ...formValues,
                id_section: respuesta.data.id_section,
                id_subsection: respuesta.data.id_subsection === null ? 0 : respuesta.data.id_subsection,
                // id_specimen_origin: respuesta.data.id_specimen_origin,
                id_specimen: respuesta.data.select_specimen,
                id_specimen_provenance: respuesta.data.select_specimen_provenance,
                code: respuesta.data.code,
                alias: respuesta.data.alias,
                name: respuesta.data.name,
                abbreviation: respuesta.data.abbreviation,
                delivery_time: respuesta.data.delivery_time,
                individual_sale: respuesta.data.individual_sale,
                // cost: respuesta.data.cost,
                is_referenced: respuesta.data.is_referenced,
                is_critic: respuesta.data.is_critic,
                use_antibiogram: respuesta.data.use_antibiogram,
                is_curve: respuesta.data.is_curve,
                listExternalLaboratories: respuesta.data.listExternalLaboratories,
                list_transports: respuesta.data.select_transports,
                is_imagenology: respuesta.data.is_imagenology,
                enable_analyte_configuration: respuesta.data.enable_analyte_configuration,
                number_projections: respuesta.data.number_projections,


            })
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }


    const getUpdateDefaultExternalLaboratory = async (examId, id_external_laboratory) => {

        let requestOptions = {
            method: 'GET'
        };
        //14

        const respuesta = await sendRequest(requestOptions, `Exam/UpdateDefaultExternalLaboratory/${examId},${id_external_laboratory}`);

        if (respuesta.code === 200) {

            let copy = exams;
            let arrayExam = copy.find(e => e.id_exam === parseInt(examId));

            arrayExam.listExternalLaboratories.forEach((obj, i) => {
                if (obj.id_external_laboratory === parseInt(id_external_laboratory)) {
                    obj.is_default = true;
                }
                else {
                    obj.is_default = false;
                }
                //////console.log(copy); 
            })
            setExams([...copy]);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getUpdateDefaultExternalLaboratory);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Analitos", respuesta.data.msg);
        }
    }



    const handleGetValidReleaseExam = async (id_exam) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/ValidReleaseExam/${id_exam}`);

        if (respuesta.code === 200) {
            setReleaseExam({
                exams_transports: respuesta.data.exams_transports,
                production_cost: respuesta.data.production_cost,
                valid_method_default: respuesta.data.valid_method_default,
                valid_analyte: respuesta.data.valid_analyte,
                valid_analyte_age_gender_range: respuesta.data.valid_analyte_age_gender_range,
                valid_reference_values: respuesta.data.valid_reference_values,
                valid_indications: respuesta.data.valid_indications,
                is_imagenology: respuesta.data.is_imagenology,
                enable_analyte_configuration: respuesta.data.enable_analyte_configuration,
                valid_number_projections: respuesta.data.valid_number_projections

            });
            setExamId(id_exam);
            toggleRelease();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }


    const saveUpdateStatusComplete = async () => {

        let requestOptions = {
            method: 'GET'
        };

        setLoadingReleaseExam(true);

        const respuesta = await sendRequest(requestOptions, `Exam/UpdateCompleteExam/${examId}`);
        ////console.log("entro");
        ////console.log(respuesta);

        if (respuesta.code === 200) {
            createSweet("success", "success", "Examen", "El estatus del examen se cambio con éxito");

            let examenes = exams;

            let find = examenes.find(x => x.id_exam === examId);

            let icon = <i className="fa fa-check txt-success"></i>
            // let icon2= <i className="fa fa-times text-danger"></i>

            if (find) {

                //  find.is_complete = true; 
                find.is_complete = icon

                setExams(
                    examenes,
                    find
                );
            }

            toggleRelease();
            setLoadingReleaseExam(false);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
            setLoadingReleaseExam(false);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
            setLoadingReleaseExam(false);
        }
        else {
            setLoadingReleaseExam(false);
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    return {
        examEditId, formValues, alias, exams, method, handleInputChange, handleSelectValues, validaciones,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, id_specimen, id_specimen_provenance, code, alias, name, abbreviation, delivery_time,
        individual_sale, cost, is_referenced,
        toggle, modal, modalTitle, loading, listExternalLaboratories, list_transports, examId, is_critic, is_curve, use_antibiogram,
        changeSelect,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        //navigateCreateOrUpdateAnalytes,
        goToBack, navigateConfigExam, navigateCostProduction, navigateCreateExamSimple, navigateEditExamSimple,
        handleChangePrice, getUpdateDefaultExternalLaboratory,
        appCodeMethodId, changeMethodDefault,
        dataAppCodeMethod, saveAppCodeMethodFromExam, updateAppCodeMethodExam,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
        // totalPageCount, currentPage, nextPage, previousPage, goToPage,
        // currentPage, nextPage, previousPage, goToPage,
        tabGeneral, changeTabGeneral,
        handleSearch,
        //MODAL INDICATIONS
        toggle2, modal2, handleListProfile, listIndications, indication,
        id_exam_indication, handleInputChangeIndication, indicationn, listIndication, selectIndication,
        CreateOrUpdateExamIndications,
        handleGetValidReleaseExam, releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
        saveUpdateStatusComplete,
        //filter
        is_referencedf, is_simplef, individual_salef, is_criticf, is_completef,
        handleInputChangeFilter, handleFilters, id_sectionf, listSection, handleChangeSection, clearFilter,
        handleSelectValuesIndication, dataIndication,
        //IMAGENOLOGIA
        handleCreateExamImagenologia, titleImagenologia, methodImagenolofia, modalImagenologia, toggleImagenologia,
        //nuevo_campo,nuevo_campo2,
        sections2, number_projections, enable_analyte_configuration, handleUpdateImagenology,
        isImagenologyy, examEditIm, navigateMicroAnalytes
    };
}