import React, { Fragment, useState } from 'react';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { TabSpecimens, TabProvenancesSpecimen, TabOriginSpecimen } from '../../../constant';
import { IndexSpecimen } from '../../specimens/IndexSpecimen';
import { IndexSpecimenProvenances } from '../../specimenProvenances/IndexSpecimenProvenances';
import { IndexSpecimeOrigins } from '../../specimenOrigins/IndexSpecimeOrigins';


export const IndexSpecimens = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Especímenes" /> */}
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className="icofont icofont-blood-drop"></i> {TabSpecimens}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="icofont icofont-prescription"></i> {TabProvenancesSpecimen}</NavLink>
                                </NavItem>
                              
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <IndexSpecimen
                                        {...{ ...props, activeTab }}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="2">
                                    <IndexSpecimenProvenances
                                        {...{ ...props, activeTab }}
                                    />
                                </TabPane>
                              
                            </TabContent>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
