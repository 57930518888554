import React, { Fragment, useState } from 'react';

import { Modal, ModalHeader, ModalBody, Input, Label, ModalFooter, Button, InputGroup, Col, Row, Alert } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"
import './styleCheck.css';
import Select from 'react-select';

export const ModalCheckSpecimen = (props) => {

    let user = localStorage.getItem('nameUser');

    const {
        modalCheckSpecimen: modal, toggleCheckSpecimen: toggle, loadingSpecimen,
        treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma,
        itemsTree2, treeviewRef2, handleModalRefusedSample, changeTreeRefused,
        enableViewTest, handleCloseViewTest, saveRefusedSample, validationsSG, validationsS,
        handleSelectValuesR, id_return_reason, reason, name_patient, gender

    } = props;

    const themes = ['red']

    const AlertItem = props => {
        const { item } = props;
        const [Open, setOpen] = useState(true);

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={Open}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }


    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true} className={enableViewTest === false ? "modal-lg" : "modal-lg"} >
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    enable_tracking_toma === "True" ?
                        <label className=''>Visualizar Muestras</label>
                        :
                        <label className=''>Actualizar Muestras</label>
                }
            </ModalHeader>
            <ModalBody className='p-t-0' >
            <Col sm="12">
                <ul class="list-group" style={{display:'-webkit-box'}}>
                    <li class="list-group-item list-group-item-primary">Muestra remitida</li>
                    <li class="list-group-item list-group-item-success">Muestra recibida en toma</li>
                    <li class="list-group-item list-group-item-danger">Sin muestra</li>
                </ul>
                </Col>
                <Col sm="12" className='p-l-0 p-r-0' >
                    <Row>
                        <Col sm={enable_tracking_toma === "True" ? "11" : "12"} className={`${!enableViewTest ? 'm-t-10 animate__animated animate__fadeIn' : 'animate__animated animate__fadeIn'}`} hidden={enableViewTest}  >
                            <Col sm="12" style={{ textAlign: "end" }} className='p-r-0' >
                                {
                                    enable_tracking_toma === "True" ? ""
                                        :
                                        <Button outline color='danger' size='xs' onClick={handleModalRefusedSample} >Rechazar muestra</Button>
                                }
                            </Col> <br />
                            <DeniReactTreeView
                                className="deni-react-treeview-container5"
                                ref={treeviewRef}
                                style={{ marginRight: '10px', marginBottom: '10px', width: (enable_tracking_toma === "True" ? "" : "100%"), height: (enable_tracking_toma === "True" ? "200px" : "200px"), textAlign: "center" }}
                                showCheckbox={true}
                                showIcon={false}
                                items={itemsTree}
                                onCheckItem={(e) => changeTree(e)}
                                onRenderItem={onRenderItem}
                                selectRow={false}
                            />
                            <br />
                            <Col sm="12" className='p-l-0 p-r-0'>
                                {
                                    (validationsS.dataPending) &&
                                    <AlertItem
                                        item={
                                            {
                                                alerttxt: "Debes de seleccionar al menos un estudio, para poder actualizar una muestra.",
                                                alertcolor: 'danger inverse',
                                                btnclose: 'default',
                                                icon: <i className="icon-alert"></i>
                                            }
                                        }
                                    />
                                }
                            </Col>
                            <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "end" }} >
                                {
                                    enable_tracking_toma === "True" ?
                                        ""
                                        :
                                        <div>
                                            <Button size="xs" outline color="primary" type="button" onClick={() => savePendingMuestra()} className={loadingSpecimen ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                                                Actualizar muestras
                                            </Button>
                                        </div>
                                }
                            </Col>
                        </Col>
                        <Col sm={enable_tracking_toma === "True" ? "" : "12"} className={`${enableViewTest ? 'animate__animated animate__fadeIn m-t-10' : 'd-none'} `} >
                            <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "end" }}>
                                <Button type="button" color="warning" size="xs" className={"text-align: -webkit-right;mt-1"} onClick={() => handleCloseViewTest()}>
                                    <i className="fa fa-times"></i> &nbsp;Cancelar Rechazo
                                </Button>
                            </Col>
                            <Col sm="12" className='p-l-0 p-r-0 p-1'>
                                {
                                    (validationsSG.dataRefused) &&
                                    <AlertItem
                                        item={
                                            {
                                                alerttxt: "Debes de seleccionar al menos un estudio, para poder rechazar una muestra.",
                                                alertcolor: 'danger inverse',
                                                btnclose: 'default',
                                                icon: <i className="icon-alert"></i>
                                            }
                                        }
                                    />
                                }
                                {
                                    (validationsSG.id_return_reason) &&
                                    <AlertItem
                                        item={
                                            {
                                                alerttxt: "Debes de seleccionar un motivo por el cual estas rechazando estas muestras.",
                                                alertcolor: 'danger inverse',
                                                btnclose: 'default',
                                                icon: <i className="icon-alert"></i>
                                            }
                                        }
                                    />
                                }
                            </Col>
                            <br />
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="2" className='p-r-0' >
                                        {
                                            gender === 2 ?
                                                <img className="" src={require("../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "90%" }} alt="" />
                                                :
                                                <img style={{ width: "90%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                        }
                                    </Col>
                                    <Col sm="9" className='p-l-0' > <br />
                                        <label className='txt-secondary f-14'>Responsable del Rechazo:</label>&nbsp;&nbsp;<b className='f-12'>{user}</b>
                                        <br />
                                        <label className='txt-secondary f-14'>Paciente:</label>&nbsp;&nbsp;<b className='f-12'>{name_patient}</b>
                                        <br />
                                        <label className='txt-secondary f-14'>Origen:</label>&nbsp;&nbsp;<b className='f-12'>{"Tabajo del dia"}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <div>
                                <br />
                                <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                <Select
                                    classNamePrefix="select"
                                    name="id_return_reason"
                                    value={id_return_reason}
                                    options={reason}
                                    onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                    isClearable={true}
                                    placeholder={"Selecciona un motivo..."}
                                />
                            </div>
                            <br /> <br />
                            <b className='txt-secondary f-14'>Selecciona los estudios que rechazaras:</b>
                            <DeniReactTreeView
                                className="deni-react-treeview-container5"
                                ref={treeviewRef2}
                                style={{ marginRight: '10px', marginBottom: '10px', width: (enable_tracking_toma === "True" ? "" : "100%"), height: (enable_tracking_toma === "True" ? "200px" : "200px"), textAlign: "center" }}
                                showCheckbox={true}
                                showIcon={false}
                                items={itemsTree2}
                                onCheckItem={(e) => changeTreeRefused(e)}
                                selectRow={false}
                                theme={themes}
                            />
                            <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "end" }} >

                                <Button size="xs" outline color="danger" type="button" onClick={() => saveRefusedSample()} className={loadingSpecimen ? "disabled progress-bar-animated progress-bar-striped p-1" : "p-1"}>
                                    <i className='icofont icofont-doctor-alt f-16'></i> Rechazar Muestra
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            {
                enable_tracking_toma === "True" ?
                    <ModalFooter>
                        <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
                    </ModalFooter>
                    :
                    ""
            }
        </Modal>
    )
}