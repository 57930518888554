import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';

export const ModalArea = (props) => {

    const { dataAreas, typeModal2 = "viewA", modalArea, toggle3 } = props;


    return (
        <Modal isOpen={modalArea} toggle={toggle3} backdrop='static' keyboard={false} centered={true} className="modal-sm" >
            <ModalHeader toggle={toggle3} className="bg-primary">
                Areás
            </ModalHeader>
            {
                typeModal2 === "viewA" ?
                    <ModalBody>
                        {
                            <div>
                                <ListGroup sm="12">
                                    {
                                        dataAreas.map((obj, key) => {
                                            return (
                                                <ListGroupItem key={`Area-${key}`}>
                                                    <i className="fa fa-angle-double-right text-primary"></i>{obj.name}
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}