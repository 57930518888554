import React from 'react'
import { Form, Modal, ModalHeader } from 'reactstrap';
import { DeleteRol } from './DeleteRol';
import { FormRol } from './FormRol';
import { ModalViewView } from './ModalViewView';

export const ModalRol = (props) => {
     
    const {
        modalRoles, toggleRol, modalTitle, methodRoles, loading,
		handleInputChange, handleSelectValues, validationsRole,
        listViews, listRolesData,
		name, ids_views, 
		saveCreateRol, saveUpdateRole, saveDeleteRol
    } = props;

    return (
        <Modal isOpen={modalRoles} toggle={toggleRol} backdrop='static' keyboard={methodRoles === "view" ? true : false} centered={true} className={(methodRoles !== 'delete' && methodRoles !== "view") ? 'modal-lg' : ''}>
            <ModalHeader toggle={toggleRol} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                methodRoles === "create"
                ?
                    <FormRol
                        button='Guardar'
                        {...{ toggleRol, loading, name, ids_views, handleInputChange, handleSelectValues, validationsRole, methodAction: saveCreateRol, listViews }}
                    />
                :
                methodRoles === "update"
                ?
                    <FormRol
                        button='Actualizar'
                        {...{ toggleRol, loading, name, ids_views, handleInputChange, handleSelectValues, validationsRole, methodAction: saveUpdateRole, listViews }}
                    />
                :
                methodRoles === "view"
                ?
                    <ModalViewView  
                        {...{toggleRol, listRolesData}}
                    />
                :
                    <DeleteRol
                        {...{toggleRol, loading, name, saveDeleteRol}}
                    />
            }
        </Modal>
    )
}
