import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteProfile } from './DeleteProfile';


export const ModalDelete = (props) => {

    const { toggle2, loading, saveDelete,abbreviation, name,
        method,modal2,modalTitle, 
    
    } = props;
    
 return (
        <Modal isOpen={modal2} toggle={toggle2} backdrop="static" keyboard={false} centered={true}>
            <ModalHeader toggle={toggle2} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "delete"
                ?
                    <DeleteProfile
                        { ...{toggle2, loading,abbreviation, name,
                            method,modal2,modalTitle, saveDelete} }
                    />
                :
                ""
            }
        </Modal>
    )
}
