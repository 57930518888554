//import React from 'react';
import { Input, Row, Col, FormGroup, InputGroupAddon, InputGroup, InputGroupText, Button, Tooltip, Badge, Label } from 'reactstrap';
import { useInputAnalyte } from '../../../hooks/captureResultsSanitaria/useInputAnalyte';
import MaskedInput from 'react-text-mask'
import man from '../../../assets/images/dashboard/profile.jpg';
import machine from '../../../assets/images/dashboard/machine1.png';
import gato from '../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg'
import { ComponentChart } from './ComponentChart';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../../hooks/requests/useRequest';
import Chart from 'react-apexcharts'
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { ModalTestAlerts } from './ModalTestAlerts';


export const InputAnalyte = ({ analyte, age, days_age, gender, examMethod, appCodeOrMethodExam, saveIndividualAnalyte, releaseData, validateAnalyte, dataExam, analyteData, idPat, namePatiente, nameP, surnameP, surnameM, dataCurve = null, changeMethod, previewWorkOrder }) => {

    // const analyte = analyte;
    // ////console.log(days_age);
    const id_gender = gender;
    const exam_method_main = examMethod;
    const app_code_or_method_exam = (analyte.id_exam_method_references !== null && analyte.id_exam_method_references > 0) ? analyte.id_exam_method_references : appCodeOrMethodExam;
    const release_data = releaseData;
    const history = useHistory();

    const [show, setShow] = useState(true);
    const [series, setSeries] = useState([])
    const [option, setOption] = useState({})
    const [chartOptions, setChartOptions] = useState({})

    const [exam_method, setExam_method] = useState(examMethod);
    const [nameA, setNameA] = useState(analyte.name)

    useEffect(() => {
        chartCaptureresul();
        // ////console.log(analyte, age, days_age, gender, "Analito=");
    }, [history])

    const changeLocalMethod = ({ target }) => {
        ////console.log("se czmbia el metodo", target);
        setExam_method(target.value);
    }


    const chartCaptureresul = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/charthistoricAnalitValues/${idPat === undefined ? 0 : idPat}/${analyte.id_analyte}`);

        if (respuesta.code === 200) {

            let options = {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        tools: {
                            download: false
                        },
                    },
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                },
                colors: ['#0079C7'],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'smooth'
                },
                // title: {
                //     text: ' RESULTADOS ANTERIORES DE' + (analyte.name) + 'DEL PACIENTE' + (nameP) + (surnameP) + (surnameM)
                // },



                grid: {
                    row: {
                        colors: ['#46AFE5', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: respuesta.data.categories,
                },
                yaxis: {
                    title: {
                        text: (analyte.name_unit)
                    },
                }
            }

            setChartOptions(options);
            setSeries(respuesta.data.series)
        }
    }

    function onKeyPressTab(event) {


        // if (event.keyCode === 9 ) {

        //     ////console.log("entra-----");


        // }

        // ////console.log("entra-----");
    }

    // onClick={validarAnalito}  ----> se comento  en el botón de validar analito




    const {
        users, alerts, typeAlert, changeInput, changeInputSelect, changeInputFile, inputState, options,
        toggleButton, viewButton,
        guardarDatosIndividuales,
        tooltip, toggleTooltip,
        tooltipRelease, toggleTooltipRelease, maskFormat,
        validarAnalito, userValidate,
        changeComplex,
        isInterface,
        textAlerts, toggle, modal,
        toggleComments, viewComments, changeComments, objectComments,
        guardarComentarios, downloadPdf,
        print_chart, handleChangePrintChart
    } = useInputAnalyte({ analyte, age, days_age, id_gender, exam_method, exam_method_main, app_code_or_method_exam, saveIndividualAnalyte, validateAnalyte, dataExam, analyteData, dataCurve, changeLocalMethod, previewWorkOrder });
    const BotonGuardado = <Button tabIndex="-1" onClick={guardarDatosIndividuales} style={{ display: `${analyte.id_type_analyte === 7 ? "block" : viewButton ? "block" : "none"}` }} className='btn btn-primary btn-xs'><i className='fa fa-save' /> </Button>;
    const ViewRelease = <InputGroupText>
        {
            release_data !== null ?
                <>
                    <i style={{ cursor: "pointer" }} id={`tooltipRelaese-${analyte.id_analyte}`} className="icofont icofont-ui-lock text-success"></i>
                    <Tooltip
                        placement={"top"}
                        isOpen={tooltipRelease}
                        target={"tooltipRelaese-" + analyte.id_analyte}
                        toggle={toggleTooltipRelease}
                    >
                        {`Liberado el ${releaseData.date} por ${releaseData.user}`}
                    </Tooltip>
                </>
                :
                (userValidate === null ?
                    <Button className='p-0 ' tabIndex="-1" id={`tooltipRelaese-${analyte.id_analyte}`} color='transparent' size='xs'>
                        <i className="icofont icofont-ui-unlock text-danger"></i>
                        <Tooltip
                            placement={"top"}
                            isOpen={tooltipRelease}
                            target={"tooltipRelaese-" + analyte.id_analyte}
                            toggle={toggleTooltipRelease}

                        >
                            {`Validar analito`}
                        </Tooltip>
                    </Button>
                    :
                    <>
                        <i style={{ cursor: "pointer" }} id={`tooltipRelaese-${analyte.id_analyte}`} className="icofont icofont-ui-lock text-success"></i>
                        <Tooltip
                            placement={"top"}
                            isOpen={tooltipRelease}
                            target={"tooltipRelaese-" + analyte.id_analyte}
                            toggle={toggleTooltipRelease}
                        >
                            {`Validado el ${userValidate.date_capture} por ${userValidate.full_name}`}
                        </Tooltip>
                    </>)
        }
    </InputGroupText>;

    const ButtonComments = <InputGroupText>
        <Button tabIndex="-1" className='p-0' color='transparent' size='xs' onClick={() => toggleComments()}>
            <i className='fa fa-comment text-info' id={"tooltip-comments-" + analyte.id_analyte}></i>
            <ToolTips
                dataPlacement='top'
                dataTarget={"tooltip-comments-" + analyte.id_analyte}
                dataText="Añadir un comentario"
            />
        </Button>
    </InputGroupText>

    const ButtonDownloadPdf = <InputGroupText>
        <Button className='p-0' color='transparent' size='xs' tabIndex="-1" onClick={() => downloadPdf()}>
            <i className="icofont icofont-file-pdf text-info" id={"tooltip-download-" + analyte.id_analyte}></i>
            <ToolTips
                dataPlacement='top'
                dataTarget={"tooltip-download-" + analyte.id_analyte}
                dataText="Descargar archivo"
            />
        </Button>
    </InputGroupText>
    return (
        <Row className="border-top pt-2 mt-2">
            <Col sm="3" className="align-self-center p-r-0 ">
                {
                    analyte.is_analyte !== null
                        ?
                        <Badge id={"tooltip-badge-" + analyte.id_analyte} color={analyte.is_analyte ? "info" : "success"} >
                            {
                                (analyte.is_analyte ? "A" : "C")
                            }
                            <ToolTips
                                dataPlacement='top'
                                dataTarget={"tooltip-badge-" + analyte.id_analyte}
                                dataText={analyte.is_analyte ? "Analito" : "Característica"}
                            />
                        </Badge>
                        :
                        ""
                }
                &nbsp;&nbsp;
                {dataCurve !== null ? (analyte.name + " - " + dataCurve.time.label) : analyte.name}
                {
                    (analyte.id_analyte_references !== null &&  analyte.local_methods != null &&  analyte.local_methods.length > 0) ?
                        <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeLocalMethod}>
                            <option selected disabled value={-1}>Selecciona un método</option>
                            {
                                analyte.local_methods.map((metodo, key4) => {
                                    return (
                                        <option
                                            selected={
                                                (analyte.capture !== null ? (analyte.capture.id_exam_method == metodo.id_exam_method ? true : false) : (exam_method_main === metodo.id_exam_method ? true : false))
                                            }
                                            key={key4} value={metodo.id_exam_method}>Método: {metodo.name}</option>
                                    )
                                })
                            }
                        </Input>
                        : ""
                }
                <small className="form-text text-muted text-primary">{analyte.name_group}</small>
            </Col>
            <Col sm="3" className="align-self-center"> <br />
                {
                    (analyte.id_type_analyte === 1) ?
                        <>
                            <InputGroup className='input-group-sm'>
                                <Input
                                    disabled={userValidate === null ? false : true}
                                    className={`form-control ${(inputState === "-1" && analyte.required) && "border-danger"}`}
                                    type="select"
                                    value={inputState}
                                    onBlur={toggleButton}
                                    onFocus={toggleButton}
                                    onChange={changeInputSelect}
                                    onKeyUp={onKeyPressTab}
                                >
                                    <option disabled value="-1">Selecciona una opción</option>
                                    {
                                        options.length > 0 &&
                                        options.map((obj, key) => {
                                            return <option key={key} value={obj.id}>{obj.value}</option>
                                        })
                                    }
                                </Input>
                                <InputGroupAddon addonType="append" tabIndex="-1" >
                                    {BotonGuardado}
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append" tabIndex="-1">
                                    {ViewRelease}
                                </InputGroupAddon>
                                <InputGroupAddon addonType='append' tabIndex="-1">
                                    {ButtonComments}
                                </InputGroupAddon>
                            </InputGroup>
                            <small className="form-text text-muted">{analyte.name_type_analyte}</small>
                        </>
                        :
                        (analyte.id_type_analyte === 6) ?
                            <>
                                <InputGroup className='input-group-sm'>
                                    <Input
                                        disabled={userValidate === null ? false : true}
                                        className={`form-control ${(inputState === "" && analyte.required) && "border-danger"}`}
                                        type="text"
                                        placeholder="Texto libre..."
                                        value={inputState}
                                        onBlur={toggleButton}
                                        onFocus={toggleButton}
                                        onKeyUp={onKeyPressTab}
                                        onChange={(e) => changeInput(e, false, null, null)} />
                                    <InputGroupAddon addonType="append" tabIndex="-1">
                                        {BotonGuardado}
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append" tabIndex="-1">
                                        {ViewRelease}
                                    </InputGroupAddon>
                                    {/* //lleva comentario texto */}
                                    <InputGroupAddon addonType='append' tabIndex="-1">
                                        {ButtonComments}
                                    </InputGroupAddon>
                                </InputGroup>
                                <small className="form-text text-muted">{analyte.name_type_analyte}</small>
                            </>
                            :
                            (analyte.id_type_analyte === 14)
                                ?
                                <>
                                    <InputGroup className='input-group-sm'>
                                        <Input
                                            disabled={userValidate === null ? false : true}
                                            className={`form-control-search ${(inputState === "" && analyte.required) && "border-danger"}`}
                                            style={{ width: '215px' }}
                                            type="file"
                                            accept='application/pdf'
                                            placeholder='Selecciona un archivo'
                                            onBlur={toggleButton}
                                            onFocus={toggleButton}
                                            onChange={changeInputFile}
                                            onKeyUp={onKeyPressTab}

                                        />
                                        <InputGroupAddon addonType='append' tabindex="-1">
                                            {BotonGuardado}
                                        </InputGroupAddon>
                                        <InputGroupAddon addonType='append' tabindex="-1">
                                            {ViewRelease}
                                        </InputGroupAddon>
                                        <InputGroupAddon addonType='append' tabindex="-1">
                                            {ButtonComments}
                                        </InputGroupAddon>
                                        {
                                            (analyte.capture !== null && analyte.capture.file_base64 !== undefined) &&
                                            <InputGroupAddon addonType='append' tabIndex="-1">
                                                {ButtonDownloadPdf}
                                            </InputGroupAddon>
                                        }
                                    </InputGroup>
                                    <small className='form-text text-muted'>{analyte.name_type_analyte}</small>
                                </>
                                :
                                (analyte.id_type_analyte === 7) ?
                                    <>
                                        <InputGroup className='input-group-sm'>
                                            <Input
                                                readOnly={true}
                                                onKeyUp={onKeyPressTab}
                                                // onKeyUp={onKeyPressTab}  
                                                className={`form-control ${(inputState === "" && analyte.required) && "border-danger"}`}
                                                type="text"
                                                placeholder="Calculando..."
                                                value={analyteData !== null && analyteData !== undefined && analyteData.capture} onBlur={toggleButton} onFocus={toggleButton} onChange={(e) => changeInput(e, true, null, null)} />
                                            <InputGroupAddon addonType="append" tabIndex="-1">
                                                {BotonGuardado}
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append" tabIndex="-1">
                                                {ViewRelease}
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append' tabIndex="-1">
                                                {ButtonComments}
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <small className="form-text text-muted">{analyte.name_type_analyte}</small>
                                    </>
                                    :
                                    <>

                                        <small className="form-text text-muted">Enteros máximos: {analyte.integers} - Decimales máximos {analyte.decimals}</small>
                                        <InputGroup className='input-group-sm'>
                                            {
                                                analyte.capture !== null && analyte.usersCapture !== null ?
                                                    releaseData !== null ?
                                                        <Input
                                                            disabled={true}
                                                            className={`form-control ${(inputState === "" && analyte.required) && "border-danger"}`}
                                                            type="number"
                                                            value={inputState}
                                                            onBlur={toggleButton}
                                                            onFocus={toggleButton}
                                                            onKeyUp={onKeyPressTab}
                                                            //onKeyPress={onKeyPressTab} 
                                                            onChange={(e) => changeInput(e, true, null, null)}
                                                        />
                                                        :

                                                        <Input
                                                            className={`form-control ${(inputState === "" && analyte.required) && "border-danger"}`}
                                                            type="number"
                                                            value={inputState}
                                                            onBlur={toggleButton}
                                                            onFocus={toggleButton}
                                                            onKeyUp={onKeyPressTab}
                                                            onChange={(e) => changeInput(e, true, null, null)}
                                                            disabled={userValidate === null ? false : true}
                                                        />
                                                    :

                                                    <Input
                                                        className={`form-control ${(inputState === "" && analyte.required) && "border-danger"}`}
                                                        type="number"
                                                        value={inputState}
                                                        onBlur={toggleButton}
                                                        onFocus={toggleButton}
                                                        onKeyUp={onKeyPressTab}
                                                        onChange={(e) => changeInput(e, true, null, null)}
                                                        disabled={userValidate === null ? false : true}
                                                    />
                                            }
                                            <InputGroupAddon addonType="append" tabIndex="-1">
                                                {BotonGuardado}
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append" tabIndex="-1">
                                                {ViewRelease}
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append' tabIndex="-1">
                                                {ButtonComments}
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <small className="form-text text-muted">{analyte.name_type_analyte}</small>
                                    </>
                }
            </Col>
            {/* <Col sm="1">
                1 col
            </Col> */}
            <Col sm="1" className='align-self-center p-l-0 p-r-0 '>
                <div className="media">
                    <Row>
                        <Col sm="2">
                            {
                                (alerts === undefined || alerts.normal_max === undefined) ?
                                    ""
                                    : typeAlert === "success" ?
                                        <i className="fa fa-check text-success f-16" />
                                        :
                                        typeAlert === "alert" ?
                                            <i className={`fa fa-ban text-danger f-16 `} />
                                            :
                                            <i className={`fa fa-warning  f-16 text-${typeAlert}`} />
                            }
                        </Col>
                        <Col sm="4">
                            {
                                users.length > 0 &&
                                <>
                                    <img style={{ cursor: "pointer" }} className="img-30 mr-1 rounded-circle" id={"Tooltip-" + users[0].id_work_order_exam_analyte} src={users[0].is_interface !== undefined ? machine : gato} alt="" />
                                    <Tooltip
                                        placement={"top"}
                                        isOpen={tooltip}
                                        target={"Tooltip-" + users[0].id_work_order_exam_analyte}
                                        toggle={toggleTooltip}
                                    >
                                        {
                                            users[0].is_interface !== undefined ?
                                                users[0].date_capture + " - Resultado interfazado" :
                                                users[0].date_capture + " - Capturado por: " + users[0].full_name
                                        }
                                    </Tooltip>
                                </>
                            }
                        </Col >
                        <Col sm="6 d-none">
                            {
                                users.length > 1 &&
                                <div className="d-flex">
                                    <div className="customers">
                                        <ul>
                                            {
                                                users.length === 2 ?
                                                    <>
                                                        <li className="d-inline-block mr-2">
                                                            <p className="f-12">2</p>
                                                        </li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                    </>
                                                    :
                                                    <>
                                                        <li className="d-inline-block mr-2">
                                                            <p className="f-12">{users.length > 3 ? "+3" : "3"}</p>
                                                        </li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block"><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                        <li className="d-inline-block" style={{ marginLeft: "-10%" }}><img className="img-20 rounded-circle" src={require("../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} alt="" /></li>
                                                    </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row >
                </div >
            </Col >
            <Col sm="2" className="align-self-center p-l-0 p-r-0"   >
                {
                    //(analyte.id_type_analyte === 1 || analyte.id_type_analyte === 6) ?
                    (analyte.id_type_analyte === 1 || analyte.id_type_analyte === 14) ?
                        "--"
                        :
                        (analyte.id_type_analyte === 6)
                            ? (analyte.name_unit !== null) ? `${analyte.name_unit}` : "---"

                            : `${analyte.name_unit}`
                }
            </Col>
            <Col sm="1" className="align-self-center p-l-0 p-r-0">
                {
                    alerts !== undefined ?
                        (alerts.normal_min !== undefined && alerts.normal_max !== undefined ? `${alerts.normal_min} - ${alerts.normal_max}` : <>Sin rango <br /> númerico</>) : (<>Sin rango <br /> númerico</>)
                }
                {/* {
                    (analyte.id_type_analyte === 6) ?
                        "--"
                        :
                        (
                            alerts.normal_min !== undefined && alerts.normal_max !== undefined ? `${alerts.normal_min} - ${alerts.normal_max}` : ""
                        )
                } */}
            </Col>
            {
                textAlerts.length > 0 && <Col sm="1" className='align-self-center p-l-0 ' style={{ textAlign: "right" }}  >
                    <i id={`tooltip-alert-${analyte.id_analyte}`} className="fa fa-table f-28 pointer text-warning" onClick={toggle}>
                        <ToolTips
                            placement="top"
                            dataTarget={`tooltip-alert-${analyte.id_analyte}`}
                            dataText={'Rangos alfanumericos'}
                        />
                    </i>
                    <ModalTestAlerts
                        {
                        ...{
                            isOpen: modal, toggle, size: "md", data: textAlerts, modalTitle: "Rangos"
                        }
                        }
                    />
                </Col>
            }
            <Col sm="1" className='pointer p-2' > <br />
                <i id={`tooltip-historic-${analyte.id_analyte}`} className="icofont icofont-chart-line txt-primary f-28 pointer" onClick={() => { setShow(!show); }}>
                    <ToolTips
                        placement="top"
                        dataTarget={`tooltip-historic-${analyte.id_analyte}`}
                        dataText={'Resultados historicos'}
                    />
                </i>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i id={`tooltip-printChart-${analyte.id_analyte}`} className={`${print_chart ? 'icofont icofont-printer f-28 pointer text-success' : 'icofont icofont-printer f-28 pointer text-grey'}`} onClick={() => handleChangePrintChart(!print_chart)}>
                    <ToolTips
                        placement="top"
                        dataTarget={`tooltip-printChart-${analyte.id_analyte}`}
                        dataText={'Habilitar impresión de grafica'}
                    />
                </i>
            </Col>
            <Col className={'col-md-4 offset-md-3 ' + (viewComments ? '' : 'd-none')} >
                <InputGroup className='input-group-sm'>
                    <input type="text" className='form-control form-control-sm input-air-primary' placeholder='Escribe tus comentarios' value={objectComments} onChange={(e) => changeComments(e)} />
                    <InputGroupAddon addonType='append'>
                        <InputGroupText>
                            <Button className='p-0' color='transparent' size='xs' onClick={guardarComentarios}>
                                <i className='fa fa-save text-danger' id={"tooltip-savecomments-" + analyte.id_analyte}></i>
                                <ToolTips
                                    dataPlacement='top'
                                    dataTarget={"tooltip-savecomments-" + analyte.id_analyte}
                                    dataText="Guardar comentario"
                                />
                            </Button>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
            <Col sm="12">


                {
                    show ? ""
                        :
                        <div> <br /> <br />
                            {
                                series.map((obj, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                obj.data == 0 ?
                                                    <div className='col-md-12 p-0'>
                                                        <Col md="12 p-0">
                                                            <br />
                                                            <h6 className="sub-title mb-0 text-center txt-primary">{"Sin Información Previa"}</h6>
                                                            <br />
                                                            <br />
                                                        </Col>
                                                    </div> :
                                                    <div>
                                                        <Col sm="12" style={{ textAlign: "center" }}>
                                                            <b className='txt-secondary f-16'>{nameA}:</b>&nbsp; <b className='f-14'>RESULTADOS ANTERIORES DE</b> &nbsp; <b className='f-14' >{nameP}</b> <b className='f-14'>{surnameP}</b> <b className='f-14'>{surnameM}</b>
                                                        </Col>
                                                        <Chart className="flot-chart-placeholder" options={chartOptions} series={series} type="line" width="100%" height={280} />



                                                    </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

            </Col>
        </Row >
    )
}
