import React from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";

export const ListOrderingSubItem = ({ subItems, type }) => {

    const grid = 8;

    let layoutVersion = localStorage.getItem('layout_version');

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 1,
        margin: `5px`,
        width: "100%",
        background: isDragging ? ( layoutVersion === "dark-only" ? "linear-gradient(270deg, #0079C7 0%, #002940 85.94%)" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        color: layoutVersion === "dark-only" ? "#fff" :  "#000" ,
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "3px solid #0079C7" ,
        // display: "inline-flex",
        boxShadow: isDragging ? `0 0 10px` : "",
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background:  isDraggingOver ? ( layoutVersion === "dark-only" ? "#0079C7" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "3px solid #0079C7" ,
        padding: grid,
        margin: "10px 0"
    });

    return (
        <Droppable droppableId={type} type={`droppableSubItem`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {subItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div style={{ display: "flex" }}>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {item.content}
                                        <span><i className="float-right fa fa-bars"></i></span>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
