import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Input, Card, CardBody, Container, Row, Col, Button, FormGroup, Label, List, Table, Media, CardHeader, Collapse, Form, Badge } from 'reactstrap';
import { useStatusWorkDay } from '../../../hooks/workOrder/useStatusWorkDay';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import './styleWorkDay.css';
import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import Select from 'react-select';
import 'animate.css';


export const IndexStatusWorkDay = () => {


    const [filterSections, setFilterSections] = useState(true);
    const [pdfExcel, setPdfExcel] = useState(true)

    const { date_from, date_to, handlePickerValues, nimValidation, getWorkOrder, downdloadExcel, downdloadpdf, date_from2, date_to2,

        //nuevos datos
        branches, branchesSelected, changeMultiselect, sections, selectedSections, sectiondefault, changeCheckbox,
        getWorkOrders, mDateRange1, mDateRange2, loading, dataPending, handleSelectValues, mSelectBranch, week, mSelectStatus,
        specimen, mSelectMuestra, handleViewFilters, viewFormFilters

    } = useStatusWorkDay();

    ////console.log(dataPending);

    const txtsection = localStorage.getItem('TextAS');


    const [filters, setFilters] = useState(true);
    const [filterDate, setFilterDate] = useState(false);
    const [filtersRangeNim, setFiltersRangeNim] = useState(false);

    const changeFilters = (typeFilter) => {
        switch (typeFilter) {
            case "date":
                setFilters(!filters);
                setFilterDate(false);
                setFiltersRangeNim(false);
                break;
            case "search":
                setFilters(false);
                setFilterDate(!filterDate);
                setFiltersRangeNim(false);

                break;
            case "range":
                setFilters(false);
                setFilterDate(false);
                setFiltersRangeNim(!filtersRangeNim);

                break;
            default:
                break;
        }
    }


    return (
        <Fragment>
            <Container fluid={true}> <br />
                <Row>
                    <Col className={!viewFormFilters ? "animate__animated animate__slideOutLeft d-none" : "default-according style-1 faq-accordion job-accordion position-relative"} id="accordionoc" sm="3">
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("date")}
                                                data-target="#collapseicon" aria-expanded={filters} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i> &nbsp; &nbsp; &nbsp; &nbsp;<label className='labelFilter'>Filtros de busqueda</label></Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filters}>
                                        <CardBody className="p-2 p-t-0">
                                            <Form onSubmit={(e) => getWorkOrders(e)}>
                                                <Row>
                                                    <Col md="12" className="p-initial">
                                                        <Label className="">Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <Row className="m-0">
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                                <FormGroup className="">
                                                                    <Datetime
                                                                        inputProps={{
                                                                            placeholder: 'YYYY-MM-DD',
                                                                            name: "workFrom",
                                                                            autoComplete: "false",
                                                                            className: "form-control digits input-air-primary form-control-sm",
                                                                        }}
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        value={mDateRange1}
                                                                        locale="es"
                                                                        initialValue={new Date()}
                                                                        onChange={(e) => handlePickerValues(e, "mDateRange1")}
                                                                        closeOnSelect={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                                <FormGroup >
                                                                    <Datetime
                                                                        inputProps={{
                                                                            placeholder: 'YYYY-MM-DD',
                                                                            name: "workFrom",
                                                                            autoComplete: "false",
                                                                            className: "form-control digits input-air-primary form-control-sm ",
                                                                            size: "sm"
                                                                        }}
                                                                        timeFormat={false}
                                                                        dateFormat={"YYYY-MM-DD"}
                                                                        locale="es"
                                                                        value={mDateRange2}
                                                                        initialValue={new Date()}
                                                                        onChange={(e) => handlePickerValues(e, "mDateRange2")}
                                                                        closeOnSelect={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col md="12 m-0">
                                                        <FormGroup>
                                                            <Label className=" ">Secciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <MultiSelect
                                                                className="basic-multi-select input-air-primary form-control-sm "
                                                                value={selectedSections}
                                                                options={sectiondefault}
                                                                onChange={(e) => changeCheckbox(e.value)}
                                                                optionLabel="name"
                                                                placeholder={"Selecciona una " + txtsection}
                                                                maxSelectedLabels={3}
                                                                selectedItemsLabel="Secciones Agregadas"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12 m-0">
                                                        <Label className=" ">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <FormGroup>
                                                            <Select
                                                                name="mSelectBranch"
                                                                isClearable={true}
                                                                placeholder="Selecciona una sucursal"
                                                                noOptionsMessage="Sin resultados"
                                                                options={branches}
                                                                onChange={(e) => handleSelectValues(e, 'mSelectBranch')}
                                                                value={mSelectBranch}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12 m-0" >
                                                        <Label className=" ">Estatus<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <Select
                                                            name="mSelectStatus"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage="Sin resultados"
                                                            options={week}
                                                            onChange={(e) => handleSelectValues(e, 'mSelectStatus')}
                                                            value={mSelectStatus}
                                                        />
                                                    </Col>
                                                    <Col md="12 m-0" >
                                                        <Label className=" ">Muestra<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <Select
                                                            name="mSelectMuestra"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage="Sin resultados"
                                                            options={specimen}
                                                            onChange={(e) => handleSelectValues(e, 'mSelectMuestra')}
                                                            value={mSelectMuestra}
                                                        />
                                                    </Col>
                                                    <Col md="12">
                                                        <br />
                                                        <Button type="submit" outline color="primary" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")}> Filtrar</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm="12" md={!viewFormFilters ? "12" : "9"} lg={!viewFormFilters ? "12" : "9"} >
                        {/* <Card className='shadow' style={{ height: "120px" }} >
                            <Row>
                                <Col sm="3" className='d-flex align-items-end gap-1'>
                                    <div className='ecommerce-widgets media '>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2"> <br />
                                            <div className='ecommerce-box light-bg-primary' >
                                                <i>datos</i>
s                                            </div>
                                        </Col>
                                        <Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
                                            <label className='txt-secondary f-16'>Admisiones Críticas</label> <br />
                                        </Col>
                                    </div>
                                </Col>
                            </Row>

                        </Card> */}
                        <Card>
                            <div className="ribbon ribbon-clip ribbon-primary">Pendientes Del Día</div>
                            <Col sm="12"> <br /> <br />
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                        </Col>
                                        <Col sm="6" style={{ textAlign: "end" }}>
                                            <Button outline color='secondary' className="btn-pill" onClick={handleViewFilters}>
                                                {
                                                    !viewFormFilters ?
                                                        <div>
                                                            <i className="icofont icofont-filter"></i>&nbsp;Filtros
                                                        </div>
                                                        :
                                                        <div>
                                                            <i className="icofont icofont-eye-blocked f-14"></i>&nbsp;Ocultar
                                                        </div>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col> <br /> <br />
                                <div className='table-responsive'>
                                    {
                                        dataPending.length === undefined ?
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >0 resultados para su busqueda.</p>
                                            </div>
                                            :
                                            <Table striped>
                                                <thead className=" text-center">
                                                    <tr className='border-bottom-info'>
                                                        <th scope="col" >#</th>
                                                        <th scope='col'>Nim</th>
                                                        {/* <th scope='col'>Paciente</th> */}
                                                        <th scope='col'>Estudio</th>
                                                        <th scope="col">Estatus</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loading === true ?
                                                            <tr>
                                                                <td colSpan={10} className="text-center">
                                                                    <Row>
                                                                        <Col md="12" className='text-center'>
                                                                            <div className="loader-box primary" style={{ width: "35px", height: "35px", marginLeft: "48%" }}>
                                                                                <div className="loader-9" color='primary' style={{ height: "30px", width: "30px" }}></div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <p>Cargando...</p>
                                                                </td>
                                                            </tr>
                                                            :
                                                            dataPending.length >= 1 &&
                                                            dataPending.map((obj, index) => {
                                                                return (
                                                                    <tr key={index} className=" border-bottom-info text-center">
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{(index + 1)}</td>
                                                                        <td className='pt-1 pb-1 pl-2 pr-2 align-middle'>{obj.nim}</td>
                                                                        {/* <td className='pt-1 pb-1 pl-2 pr-2 align-middle'>{obj.name_patient}</td>                                                                       */}
                                                                        <td className='pt-1 pb-1 pl-2 pr-2 align-middle'>
                                                                            {
                                                                                obj.listExam.map((item, indexx) => {
                                                                                    return (
                                                                                        <Col key={indexx}>
                                                                                            -{item.name} /
                                                                                            <label className='txt-secondary'>{item.name_status}</label>
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                obj.listProfile.map((items, index) => {
                                                                                    return (
                                                                                        <Col key={items}>
                                                                                            -{items.name_profile}
                                                                                            <br />
                                                                                            {
                                                                                                items.listExam.map((_o) => {
                                                                                                    return (
                                                                                                        <Col>
                                                                                                            -{_o.name} /
                                                                                                            <label className='txt-secondary'>{_o.name_status}</label>
                                                                                                        </Col>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </Col>
                                                                                    )
                                                                                })


                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {obj.id_status == 1 ? <Badge style={{ width: "80px" }} color="info">Abierta</Badge>//
                                                                                : obj.id_status == 2 ? <Badge style={{ width: "80px" }} color="warning">En progreso</Badge>//
                                                                                    : obj.id_status == 4 ? <Badge style={{ width: "80px" }} color="warning">Validada</Badge>//
                                                                                        : obj.id_status == 5 ? <Badge style={{ width: "80px" }} color="warning">Parcial</Badge>//
                                                                                            : obj.id_status == 6 ? <Badge style={{ width: "80px" }} color="warning">Liberado</Badge>//
                                                                                                : obj.id_status == 7 ? <Badge style={{ width: "80px" }} color="success">Enviado</Badge>
                                                                                                    : obj.id_status == 8 ? <Badge style={{ width: "80px" }} color="success">Entregado</Badge>
                                                                                                        : obj.id_status == 9 ? <Badge style={{ width: "80px" }} color="success">Ent/Env</Badge>
                                                                                                            : obj.id_status == 10 ? <Badge style={{ width: "80px" }} color="success">Autorizado</Badge>
                                                                                                                : obj.id_status == 13 ? <Badge style={{ width: "80px" }} color="warning">Impresa</Badge>
                                                                                                                    : <Badge></Badge>}

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </Table>

                                    }

                                </div>




                            </Col>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
