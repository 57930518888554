import React from 'react';
import { Form, ModalBody, Button, ModalFooter, Alert } from 'reactstrap';
import { ModalButtonDelete, ModalButtonCancel } from '../../constant';


export const DeleteRates = (props) => {

    const {
        toggle, loading, name, abbreviation, saveDelete
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el tarifario:</p>                
                <b>{ abbreviation+' - '+name}</b>
                <br />
                <br />
                <Alert className="warning inverse" color="danger" isOpen={true}>
                    <i className="fa fa-exclamation-circle"></i>
                    <p>Esto eliminara la lista de precios</p>
                </Alert>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                    {ModalButtonDelete}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>{ ModalButtonCancel }</Button>
            </ModalFooter>
        </Form>
    )
}
