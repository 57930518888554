import React from 'react';
import { Form, Input, Col, Card, Button, Row, Label, FormGroup, Table, ModalFooter, ModalBody, FormFeedback, CardBody } from 'reactstrap';

export const FormInvoice = (props) => {

    const {
        toggle, loading, listUseCfdi, handleInputChange, validationsInvoice, saveInvoice,
        cancelInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice
    } = props;

    const {
        business_name_valid,
        rfc_valid,
        phone_valid,
        email_valid,
        id_use_cfdi_valid
    } = validationsInvoice;

    return (    
        <>
            <ModalBody>
                <Row>
                    <Col xl="12" className="box-col-6  pr-0 file-spacing  p-0 m-0 t-0 ">
                        <Card>
                            <CardBody className='p-2  p-0 form theme-form '>
                                <Row>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Table style={{ "fontSize": "11px" }} className='form theme-form' >
                                            <thead>
                                                <tr className='arcoiris'>
                                                    <th className='txt-light' colSpan={2}> <center> <i className="icofont icofont-user-male f-16 mr-2"></i>{"Datos para la Facturación"}</center>   </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-bottom-primary">
                                                    <td> Nombre/Razón Social </td>
                                                    <td > <FormGroup className='m-0'>
                                                        <Input
                                                            type='text'
                                                            name="business_name"
                                                            value={business_name}
                                                            onChange={handleInputChange}
                                                            className="form-control form-control-sm input-air-primary"
                                                            invalid={business_name_valid}
                                                        />
                                                        <FormFeedback>Nombre invalido</FormFeedback>
                                                    </FormGroup> </td>
                                                </tr>
                                                <tr className="border-bottom-primary">
                                                    <td>RFC </td>
                                                    <td><FormGroup className='m-0'>
                                                        <Input
                                                            type='text'
                                                            name="rfc"
                                                            value={rfc}
                                                            onChange={handleInputChange}
                                                            className="form-control form-control-sm input-air-primary"
                                                            invalid={rfc_valid}
                                                        />
                                                        <FormFeedback>Longitud incorrecta</FormFeedback>
                                                    </FormGroup> </td>
                                                </tr>
                                                <tr className="border-bottom-primary">
                                                    <td>Télefono</td>
                                                    <td><FormGroup className='m-0'>
                                                        <Input
                                                            type='text'
                                                            name="phone"
                                                            value={phone}
                                                            onChange={handleInputChange}
                                                            className="form-control form-control-sm input-air-primary"
                                                            invalid={phone_valid}
                                                        />
                                                        <FormFeedback>Número invalido</FormFeedback>
                                                    </FormGroup>  </td>
                                                </tr>
                                                <tr  >
                                                    <td>Correo</td>
                                                    <td><FormGroup className='m-0'>
                                                        <Input
                                                            type='email'
                                                            name="email"
                                                            value={email}
                                                            onChange={handleInputChange}
                                                            className="form-control form-control-sm input-air-primary"
                                                            invalid={email_valid}
                                                        />
                                                        <FormFeedback>Correo Inválido</FormFeedback>
                                                    </FormGroup>  </td>
                                                </tr>
                                                <tr className="border-bottom-primary">
                                                    <td>Uso del CFDI </td>
                                                    <td> <FormGroup className='m-0' >
                                                        <Input
                                                            type='select'
                                                            placeholder="Buscar cdfi"
                                                            name="id_use_cfdi"
                                                            onChange={handleInputChange}
                                                            value={id_use_cfdi}
                                                            invalid={id_use_cfdi_valid}
                                                            className="form-control form-control-sm input-air-primary"  >
                                                            <option value={0}>{"Selecciona una opción"}</option>
                                                            {
                                                                listUseCfdi.length >= 1 && listUseCfdi.map((obj, index) => {
                                                                    return <option value={obj.id_use_cfdi}>{obj.description}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        <FormFeedback>Seleccionar CFDI</FormFeedback>
                                                    </FormGroup> </td>
                                                </tr>
                                                <tr >
                                                    <td>Método de envio</td>
                                                    <td>
                                                        <Row>
                                                            <Col md={3} style={{ "text-align": "end" }}>
                                                                <Label >Imprimir </Label> <br />
                                                            </Col>
                                                            <Col md={3}  >
                                                                <FormGroup>
                                                                    <Label className="switch">
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="print_invoice"
                                                                            checked={print_invoice}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={3} style={{ "text-align": "end" }}>
                                                                <Label>Enviar </Label> <br />
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormGroup>
                                                                    <Label className="switch">
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="send_invoice"
                                                                            checked={send_invoice}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <span className="slider round "></span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" disabled={loading} className={loading ? "disabled progress-bar-animated progress-bar-striped progress btn-air-light" : "btn-air-light" } color="light" type="button" onClick={cancelInvoice}>Cancelar</Button>

                <Button type='button' size="sm" color="primary" onClick={() => saveInvoice()}> Guardar</Button>
            </ModalFooter>
        </>    
    )
}
