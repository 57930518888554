import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row, Collapse, Input, Table } from 'reactstrap';

import { ViewsImagen } from './ViewsImagen'

export const CImagen = ({item}) => {
  

  return (
    <div>
      <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0 ' >
        <Row>
            {
            item.list_images_imagenology.map((a,o) =>{
              return(
                <Col xs="12" sm="12" md="12" lg="4" xl="4"  >
                  <ViewsImagen
                  item={a}
                  />
                </Col>
              )
            })
          }
        </Row>
        <Row>
         <p dangerouslySetInnerHTML={{ __html: item.result_imagenology }} />

        </Row>
      </Col>
    </div>
  )
}
