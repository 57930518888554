import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteAgreement } from './DeleteAgreement';
import { FormSelectSecctions } from './FormSelectSecctions';
import { UpdateStatusAgreemet } from './UpdateStatusAgreemet';

export const ModalAgreement = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        name_company, contract_number, validity, status,
        saveDelete,
        sections, selectSecctionsInput, listExamProfile,
        saveSelectExamProfile, handleSelect, handleSelectAll, saveUpdateStatus
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className={(method !== 'delete') ? 'modal-lg' : ''}>
            <ModalHeader toggle={ toggle } className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "listSelectSections" 
                    ? <FormSelectSecctions 
                        {
                            ...{
                                toggle, loading, sections, selectSecctionsInput, listExamProfile, saveSelectExamProfile, handleSelect, handleSelectAll
                            }
                        }                    
                        />
                    : (method === "updateStatus")
                        
                        ? <UpdateStatusAgreemet button="Si, actualizar" {...{ toggle, loading, name_company, contract_number, status, validity, saveUpdateStatus, }} />

                        : <DeleteAgreement {...{ toggle, loading, name_company, contract_number, validity, saveDelete }} />
            }
        </Modal>
    )
}
