import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { useInvoiceSettings } from '../../../hooks/invoice/useInvoiceSettings';
import 'animate.css';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalInvoiceSettings } from '../../../components/commercialLines/ModalInvoiceSettings';

export const InvoiceSettings = () => {

    const {
        //MODALS SETTINGS
        modal, modalTitle, method, loading,

        toggle,

        //INVOICE SETTINGS MODELS
        viewFormFilters,listInvoiceSettings,
        filterTypeDocument,
        id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
        listTypeDocuments,

        //INVOICE SETTINGS METHODS
        handleInputChange, handleChangeFilterTypeDocument,
        handleViewFilters, 
        handleAddInvoiceSettings, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    } = useInvoiceSettings();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col className={ !viewFormFilters ? "animate__animated animate__slideOutLeft d-none" : "default-according style-1 faq-accordion job-accordion position-relative animate__animated animate__slideInLeft" } sm="12" md="3" lg="3"  >
                        <Row className='position-sticky'>
                            <Col sm="12" md="12" lg="12">
                                <Card>
                                    <CardHeader className='bg-primary'>
                                        <h5 className='mb-0'>
                                            <Button color='link pl-0' data-toggle="collapse" data-target="#collapseicon" aria-expanded={true} aria-controls="collapseicon">
                                                Filtros de búsqueda
                                            </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={true}>
                                        <CardBody className='p-3'>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Tipo de documento</Label>
                                                        <Input type='select' onChange={handleChangeFilterTypeDocument} value={filterTypeDocument}>
                                                            <option>Selecciona una opción</option>
                                                            {
                                                                listTypeDocuments.length > 0 && listTypeDocuments.map(obj => {
                                                                    return <option value={obj.id_type_document} selected={ obj.id_type_document === filterTypeDocument }>{ obj.name }</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {/* ::TODO DESCOMENTAR CUANDO YA ESTEN LOS ESTATUS PARA SERIE Y FOLIO */}
                                            {/* <Row className='mt-2'>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Estatus</Label>
                                                        <Input type='select'>
                                                            <option>Selecciona una opción</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                                            <Row className='mt-1'>
                                                <Col sm="12">
                                                    <Button outline color='primary' size='sm' className='text-center'>
                                                        Filtrar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="12" md={!viewFormFilters ? "12" : "9"} lg={ !viewFormFilters ? "12" : "9" } >
                        <Card>
                            <CardBody className='p-1'>
                                <div className='ribbon ribbon-clip ribbon-primary'>
                                    {"Serie y folio para facturas"}
                                </div>
                                <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className='btn btn-pill btn-air-success' color='info' size='sm' onClick={handleViewFilters}>
                                        <i className="icofont icofont-filter"></i> Filtros
                                    </Button>
                                    <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleAddInvoiceSettings}>
                                        <span className='fa fa-plus-circle'></span>
                                    </Button>                                    
                                </div>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <div className='table-responsive'>
                                            {
                                                listInvoiceSettings.length === 0
                                                ?
                                                    <div style={{ textAlign: "-webkit-center" }}>
                                                        <img className='img-fluid' src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "35%" }} alt="" />
                                                        <br />
                                                        <p className='f-18'>{"No hay ningún registro"}</p>
                                                    </div>
                                                :
                                                    <TableStriped
                                                        notMaped={["id_commercial_line", "id_type_document", "id_branch", "name_commercial_line"]}
                                                        methodsActions={true}
                                                        items={listInvoiceSettings}
                                                        cabeceras={["Sucursal", "Tipo de documento", "Código", "Serie", "Folio", "Siguiente folio", "Preferida"]}   
                                                        {...{ handleUpdate, handleDelete }}                                             
                                                    />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>                    
                </Row>
            </Container>
            <ModalInvoiceSettings
                {
                    ...{
                        modal, modalTitle, method, toggle, loading,
                        id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
                        listTypeDocuments,
                        handleInputChange, saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </Fragment>
    )
}
