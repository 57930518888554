import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useMorphology = () => {
    const history = useHistory();

    const [morphologys, setMorphologys] = useState([]);
    const [morphologyId, setMorphologyId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();


    useEffect(() => {
        getMorphologys();
    }, []);


    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        morphology: "",
    });

    const { morphology } = formValues;


    const [validaciones, setValidaciones] = useState({
        morphology_valid: false,
    });

    const getMorphologys = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Morphology/List");

        if (respuesta.code === 200) {
            let listMorphologys = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listMorphologys.push({
                        id_mic_morphology: data.id_mic_morphology,
                        morphology: data.morphology,
                        visibleTable: visibleTable
                    });
                });
                setMorphologys(listMorphologys);
                setMorphologysData(listMorphologys);
            }
            else {
                setMorphologys([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMorphologys);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Morfologias", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Morfologias", respuesta.data.msg);
        }
    }


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            morphology: "",
            gram: "",
            id_mic_morphology: ""
        });

        setValidaciones({
            morphology_valid: false,
            gram_valid: false,
            id_mic_morphology_valid: false,
        });

        setMorphologyId(0);
        setMethod("create");
        setModalTitle("Crear morfologia");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Morphology");

        if (respuesta.code === 200) {
            getMorphologys();
            reset();
            createSweet("create", "success", "Exito!", "Morfologia creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Morfologias", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Morfologias", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_morphology) => {
        let morphology = morphologys.find(s => s.id_mic_morphology === parseInt(id_morphology));

        handleUpdateValues({
            morphology: morphology.morphology,
            gram: morphology.gram,
            id_mic_morphology: morphology.id_mic_morphology,
        });

        setValidaciones({
            morphology_valid: false,
            gram_valid: false,
            id_mic_morphology_valid: false,
        });

        setMorphologyId(id_morphology);
        setMethod("update");
        setModalTitle("Actualización de morfologia");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_mic_morphology: morphologyId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Morphology/Update");

        if (respuesta.code === 200) {
            getMorphologys();
            createSweet("create", "info", "Exito!", "Morfologia actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Morfologias", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Morfologias", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_morphology) => {
        let morphology = morphologys.find(s => s.id_mic_morphology === id_morphology);

        handleUpdateValues({
            morphology: morphology.morphology,
            gram: morphology.gram,
            id_mic_morphology: morphology.id_mic_morphology,
        });

        setMorphologyId(id_morphology);
        setMethod("delete");
        setModalTitle("Eliminar morfologia");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Morphologys/${morphologyId}`);
        if (respuesta.code === 200) {
            getMorphologys();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Morfologia eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Morfologias", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Morfologias", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};
        // morphology, gram, id_mic_morphology
        if (formValues.morphology === undefined || formValues.morphology.length < 1) {
            newValidations = {
                ...newValidations,
                morphology_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                morphology_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(morphologys.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listMorphologys = [];
        morphologys.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listMorphologys.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setMorphologys(listMorphologys);
        // setMorphologysData(listMorphologys);
    }, [totalPageCount, currentPage])






    const [morphologysData, setMorphologysData] = useState([]);
    const handleSearch = (e) => {

        let busqueda = e.target.value;
        let newMorphology = [];

        morphologysData.forEach(element => {

            let cadena = element.morphology.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMorphology.push(element);
            }
        });
        setMorphologys(newMorphology);
    }

    return {
        morphologys, method, validaciones,
        morphology,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch
    }
}
