import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteUser = (props) => {

    const {
        toggle, loading, name, paternal_surname,
        maternal_surname,username, id_external, saveDelete
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el usuario:</p>
                <b>{`( ${username} ) ${name} ${paternal_surname} ${maternal_surname}`}</b>
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Eliminar
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
