import React, { Fragment, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { useDoubleValidation } from '../../../hooks/workOrder/useDoubleValidation'
import DeniReactTreeView from "deni-react-treeview"
import Datetime from 'react-datetime';
import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import { ModalConfirm } from './ModalConfirm';
import './styleDobleValidacion.css';
import Select from 'react-select';
import { ModalPreViewP } from './ModalPreViewP';
//import { ToolTips } from '../../components/tooltips/ToolTips';
import { ToolTips } from '../../../components/tooltips/ToolTips';



export const IndexDoubleValidation = () => {

    const {
        nims, dataWorkOrder, changeCheckBox, treeviewRef, onRenderItem, itemsTree, cancelarDesvalidar, desvalidar, disabled, changeTree,
        getNims, branches, changeMultiselect, branchesSelected, dateInit, dateEnd, changeDate, dataCompany, id_company,
        modalRelease, toggleRelease, loadingRelease, authorize, selectedSections, sectiondefault, changeCheckboxSection,
        //range nim
        changeFiltersRangeNim, getNimsFromRange, loading, handleSelectValues,
        //preview
        previewWorkOrder, preview, typeModal, modal, getViewPreview, toggle

    } = useDoubleValidation();


    const [filters, setFilters] = useState(true);
    const [filterDate, setFilterDate] = useState(false);
    const [filtersRangeNim, setFiltersRangeNim] = useState(false);

    const txtsection = localStorage.getItem('TextAS');

    const changeFilters = (typeFilter) => {
        switch (typeFilter) {
            case "date":
                setFilters(!filters);
                setFilterDate(false);
                setFiltersRangeNim(false);
                break;
            case "search":
                setFilters(false);
                setFilterDate(!filterDate);
                setFiltersRangeNim(false);

                break;
            case "range":
                setFilters(false);
                setFilterDate(false);
                setFiltersRangeNim(!filtersRangeNim);

                break;
            default:
                break;
        }

    }


    ////console.log(dataCompany);




    return (
        <Fragment>
            <Container fluid={true}>
                <Row className='m-b-10'>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="3"  >
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12" className={disabled === true ? "disabled disableLabel" : ""}  >


                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("date")}
                                                data-target="#collapseicon" aria-expanded={filters} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i> &nbsp; &nbsp; &nbsp; &nbsp;<label className='labelFilter'>Filtros de busqueda</label></Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filters}>
                                        <CardBody className="p-2 p-t-0">
                                            <Label>Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Row className="m-0">
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                    <FormGroup className="">
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control-sm",
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat="YYYY-MM-DD"
                                                            value={dateInit}
                                                            locale="es"
                                                            initialValue={new moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => changeDate(e, "init")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                    <FormGroup >
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control-sm ",
                                                                size: "sm"
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat={"YYYY-MM-DD"}
                                                            locale="es"
                                                            value={dateEnd}
                                                            initialValue={new moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => changeDate(e, "end")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="12 m-0">
                                                    <FormGroup>
                                                        <Label className=" labelFilter">Secciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <MultiSelect
                                                            className="basic-multi-select input-air-primary form-control-sm "
                                                            value={selectedSections}
                                                            options={sectiondefault}
                                                            onChange={(e) => changeCheckboxSection(e.value)}
                                                            optionLabel="name"
                                                            placeholder={"Selecciona una " + txtsection}
                                                            maxSelectedLabels={3}
                                                            selectedItemsLabel="Secciones Agregadas"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12 m-0">

                                                    <Label className=" ">Empresas</Label>

                                                    <Select
                                                        name="id_company"
                                                        isClearable={true}
                                                        placeholder="Selecciona una empresa"
                                                        options={dataCompany}
                                                        onChange={(e) => handleSelectValues(e, 'id_company')}
                                                        value={id_company}
                                                        className="b-r-9"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12 m-0">
                                                    <Label className="col-form-label">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <MultiSelect
                                                            className="basic-multi-select"
                                                            value={branchesSelected}
                                                            options={branches}
                                                            onChange={(e) => changeMultiselect(e)}
                                                            optionLabel="name"
                                                            placeholder="Selecciona una sucursal"
                                                            filter={true}
                                                            selectedItemsLabel="Sucursales agregadas"
                                                            emptyFilterMessage="Ninguna coincidencia"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Button type="button" onClick={() => getNims(true)} outline color="primary" className={("text-center mt-1")}> FILTRAR</Button>
                                                </Col> <br />
                                                <Col md="12"><br />
                                                    <Button type="button" onClick={() => toggleRelease()} outline color="success" className={("text-center mt-1")}> AUTORIZAR</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("range")}
                                                data-target="#collapseicon" aria-expanded={filtersRangeNim} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i>&nbsp; &nbsp; &nbsp; &nbsp; <label className='labelNim'>Filtro de rangos de nims</label>   </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filtersRangeNim}>
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label className=" ">Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup className="">
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control",
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat="YYYY-MM-DD"
                                                            // value={mDateRange1}
                                                            locale="es"
                                                            initialValue={new Date()}
                                                            onChange={(e) => changeFiltersRangeNim(e, "date_range_nim")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label className="">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Select
                                                            name="mSelectBranch"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage="Sin resultados"
                                                            options={branches}
                                                            onChange={(e) => changeFiltersRangeNim(e, 'branch_range_nim')}
                                                        // value={mSelectBranch}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="">Inicio<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="init_range_nim"
                                                            // value={nim}
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "init_range_nim")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="">Fin<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="end_range_nim"
                                                            // value={nim}
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "end_range_nim")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Button onClick={getNimsFromRange} type="button" outline color="primary" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} > Buscar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9">
                        <Row>
                            <Col sm="7">
                                <Card className={disabled === true ? "disabled disableLabel" : ""} >
                                    <div className="ribbon ribbon-clip ribbon-primary">
                                        Ordenes de trabajo liberadas
                                    </div>
                                    {
                                        nims.length > 0 ?
                                            <CardBody className='mt-3'>
                                                {
                                                    nims.map((obj, key) => {
                                                        return <Row className={obj.check === true ? 'border mb-4 pt-2 pb-2 b-t-success b-b-success b-l-success b-r-success' : 'border mb-4 pt-2 pb-2 b-t-danger b-b-danger b-l-danger b-r-danger'} key={key}>
                                                            <Col sm="4">
                                                                {
                                                                    obj.check === true ?
                                                                        <label className='txt-secondary f-12'>{obj.nim}</label>
                                                                        :
                                                                        <label className='txt-danger f-12'>{obj.nim}</label>
                                                                }

                                                                <br />
                                                                {obj.branch}
                                                            </Col>
                                                            <Col sm='5'>
                                                                {obj.date} <br />
                                                                <label className='p-1'>                                                                {obj.type_income}
                                                                </label>
                                                            </Col>
                                                            <Col sm="1">

                                                            </Col>
                                                            <Col sm="2" className='text-center p-r-0 '>
                                                                <Input
                                                                    style={{ height: 25, width: 25 }}
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked={obj.check}
                                                                    onChange={() => changeCheckBox(obj.id_work_order)}
                                                                />
                                                                <br />
                                                                <br />
                                                                <Label className=''>
                                                                    Validado
                                                                </Label>
                                                            </Col>

                                                            <Col sm="12">
                                                                <hr className={obj.check === true ? ' b-b-success ' : "b-b-danger"} />
                                                                <Row>

                                                                    <Col sm="1" className='p-l-0 p-r-0' >
                                                                        {
                                                                            (obj.gender === 2) ?
                                                                                <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "100%" }} alt="doubleValidation" /></div>
                                                                                :
                                                                                (obj.gender === 1) ?
                                                                                    < div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "100%" }} alt="doubleValidation" /></div>
                                                                                    : ""
                                                                        }
                                                                    </Col>
                                                                    <Col sm="8" className='p-l-0'> <br />
                                                                        {obj.patient}
                                                                    </Col>
                                                                    <Col sm="1" className='p-r-0 p-l-0' >
                                                                    </Col>
                                                                    <Col sm="1" className='p-l-0' style={{ textAlign: "center" }} > <br />
                                                                        {
                                                                            obj.check === true ?
                                                                                <i className="icofont icofont-lock f-20 txt-success"></i>
                                                                                :
                                                                                <i className="fa fa-unlock-alt f-20 txt-danger" ></i>
                                                                        }
                                                                    </Col>
                                                                    <Col sm="1" className='p-l-0' style={{ textAlign: "center" }} > <br />
                                                                        <Button outline color='secondary' size='xs' onClick={() => previewWorkOrder(obj.nim)}
                                                                        >
                                                                            <i id={`tooltip-preview-${key}`} class="fa fa-eye">
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-preview-${key}`}
                                                                                    dataText={'Previsualizar'}
                                                                                />
                                                                            </i>
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    })
                                                }
                                            </CardBody>
                                            :
                                            <CardBody>
                                                <div className="search-not-found text-center">
                                                    <div>
                                                        <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "100%" }} alt="" /> <br />
                                                        <h1 className='f-w-600 f-14 badge badge-light-danger'>No se a registrado ninguna orden de trabajo en este momento</h1>
                                                    </div>
                                                </div>
                                            </CardBody>
                                    }
                                </Card>
                            </Col>
                            <Col sm="5">
                                <Card style={{ height: "78vh" }}>
                                    {
                                        dataWorkOrder === null ? <>
                                            <CardBody>
                                                <div className="search-not-found text-center">
                                                    <div>
                                                        <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "100%" }} alt="" /> <br />
                                                        <label className='f-w-600 f-12 badge badge-light-primary'>No se  a registrado Ninguna prueba para desvalidar</label>


                                                    </div>
                                                </div>
                                            </CardBody>
                                        </> :
                                            <div className='p-3'>
                                                <label className='f-w-600 f-12 badge badge-light-danger'>Selecciona aquellas pruebas a desvalidar</label>

                                                {/* <h5>Selecciona aquellas pruebas a desvalidar</h5> */}
                                                <DeniReactTreeView
                                                    className="w-100"
                                                    ref={treeviewRef}
                                                    style={{ marginRight: '10px', marginBottom: '10px', height: '440px' }}
                                                    showCheckbox={true}
                                                    showIcon={false}
                                                    onCheckItem={(e) => changeTree(e)}
                                                    items={itemsTree}
                                                    onRenderItem={onRenderItem}
                                                />
                                                <button className='btn btn-block btn-danger' onClick={loadingRelease === true ? null : desvalidar}>DESVALIDAR</button>
                                                <button className='btn btn-block btn-secondary' onClick={cancelarDesvalidar}>CANCELAR</button>
                                            </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
            <ModalConfirm
                {
                ...{
                    modalRelease, toggleRelease, loadingRelease, authorize
                }
                }
            />

            <ModalPreViewP
                {
                ...{ previewWorkOrder, preview, typeModal, modal, getViewPreview, toggle }
                }

            />
        </Fragment >
    )
}