import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteExam = (props) => {

    const { toggle, loading, code, alias, name, saveDelete } = props;

    return (
        <Form role="form" onSubmit={saveDelete}>
            <ModalBody>
                <h6>Estas apunto de eliminar el exámen</h6>
                <br />
                <p>{name}</p>
                <ul>
                    <li>Codigo: {code}</li>
                    <li>Alias: {alias}</li>
                </ul>
                <br />
                <h6>¿Estás seguro de hacerlo?</h6>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar
                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
