import React, { Fragment, useState } from 'react';
import { Col, Container ,Row,NavItem,NavLink,Nav,TabContent,TabPane} from 'reactstrap';
import { ConfigServer } from '../../data/config';
import classnames from "classnames";




export const PrivacyPolicy = () => {

    const [activeTab, setActiveTab] = useState(1);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="b-center bg-size"  >
            <Container className="" fluid={true} style={{ backgroundColor: "#F8F9FA", boxSizing: "border-box" }}>
                <Col sm="12">
                    <Row>
                        <Col sm="6">

                        </Col>
                        <Col sm="6" style={{textAlign : "end"}}>
                        <img className="b-r-10  "
                            src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                            style={{ width: "35%" }}
                            alt="" />   
                        </Col>
                    </Row>
                </Col>
                <br />
                <div className="nav-wrapper">
                <Nav
                                        className="nav-fill flex-column flex-md-row"
                                        id="tabs-icons-text"
                                        pills
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 1}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 1
                                                })}
                                                onClick={() => { toggle(1); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="icofont icofont-file-document"></i>
                                                HOJA DE DESCARGO DE RESPONSABILIDAD

                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 2}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 2
                                                })}
                                                onClick={() => { toggle(2); }}
                                                href="#"
                                                role="tab"
                                            >
                                                Aviso de Privacidad
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                </div>

                <TabContent activeTab={"tabs" + activeTab}>
                                    <TabPane tabId="tabs1"> <br />
                                    <Col sm="12">
                    <Row>
                        <Col sm="6">
                            <label htmlFor="">PRIMERA.- Con la firma de esta solicitud, "EL CLIENTE" acepta que los datos aquí contenidos son correctos.</label> <br />
                            <label htmlFor="">"TERCERA.- En el caso que el paciente solicite cancelar el servicio y este fue pagado con TC/TD, este mismo tendrá que comunicarse a su banco
                             para declarar que no reconoce el cobro realizado y el banco le realizará el reembolso, este proceso tiene un tiempo estimado de 10 días habiles.</label> <br />
                             <label htmlFor="">QUINTA.- "EL LABORATORIO" se reserva el derecho de rechazar una muestra en caso de que no cumpla con los criterios establecidos,
              tales como tipo de muestra, cantidad, volumen, temperatura, condiciones del recipiente y/o envase, etc. Por lo que "EL CLIENTE" deberá tomar" 
                 una nueva muestra para su posterior análisis. De igual manera, "EL LABORATORIO" podrá solicitar más muestras a "EL CLIENTE" en caso de que se requiera verificar por duplicado un resultado.</label> <br />
                 <label htmlFor="">"SEPTIMA.-\"EL CLIENTE\" acepta los procedimientos documentados por "EL LABORATORIO" para la toma de la muestra.</label> <br />
                 <label htmlFor="">"NOVENA.- EXCLUYENTES CON RESPONSABILIDAD: "EL LABORATORIO" no incurrirá en responsabilidad si por causa de fuerza mayor, como fallas en los
                suministros de materia prima, cierre de fronteras, disposiciones de la autoridad sanitaria nacional u otras, se ve imposibilitado para presentar
                 cualquiera de los servicios ofrecidos, pero tendrá la obligación de a "EL CLIENTE" tan pronto como tenga conocimiento de que no podrá prestar el
                 servicio pactado, indicando la fecha más probable de entrega del resultado.</label> <br />
                 <label htmlFor="">DÉCIMA PRIMERA.-"EL LABORATORIO" no está facultado para hacer la interpretación de resultados, el único autorizado para esto es su médico de confianza.</label>
                        </Col>


                        <Col sm="6">
                            <label htmlFor="">"SEGUNDA.- "EL CLIENTE" acepta y autoriza que no se devolverá dinero en efectivo por estudios cancelados tanto de rutina como subrogados.</label> <br />
                            <label htmlFor="">"CUARTA.- "EL CLIENTE" debe solicitar la factura al momento del pago del servicio, ya que al final del día se genera una nota de venta.</label> <br />
                            <label htmlFor="">"SEXTA.- Cuando "EL CLIENTE" requiera la devolución de la(s) muestra(s) y/o contenedor(es) deberá solicitarlo por escrito al laboratorio al momento de contratar el servicio.</label> <br />
                            <label htmlFor="">OCTAVA.- CONFIDENCIALIDAD: "EL LABORATORIO" y "EL CLIENTE" manejarán toda la información, incluyendo la relativa a los resultados de los 
                 análisis de las muestras, en forma estrictamente confidencial y cumplirán con lo establecido en las legislaciones sanitarias aplicables y vigentes 
                 en la industria; excepto cuando alguna autoridad debidamente acreditada lo solicite. Para la entrega de resultados "EL CLIENTE" deberá presentar 
                 contra recibo y/o identificación oficial con fotografía.</label> <br />
                 <label htmlFor="">"DÉCIMA.- SUBCONTRATACIÓN: "EL LABORATORIO" se reserva el derecho de subcontratar el servicio de análisis por razones imprevistas o sobre una
                base de continuidad, dicha subcontratación se hará en laboratorios mexicanos debidamente calificados.</label> <br />

                <label htmlFor="">"Los resultados reactivos, positivos, no reactivos o negativos para VIH son entregados de manera personal a quien se realizó la prueba de detección,
                presentando una identificación oficial. Se podrán entregar resultados a terceros o por correo electrónico con previa autorización expresa de la persona a quien se realizó la prueba de detección."</label>
                        </Col>
                    </Row>
                </Col>
                </TabPane>
                <TabPane tabId="tabs2"> <br /> <br /> <br /> <br />
                    <label className='f-14'>
                    CONSULTA NUESTRO AVISO DE PRIVACIDAD EN:
                                    <a href='https://centroquimico.com.mx/aviso-de-privacidad/'> https://centroquimico.com.mx/aviso-de-privacidad/</a>
                                    ó en cualquiera de nuestras sucursales
                                    </label>
                                    </TabPane>
                                </TabContent>

            </Container >
        </div >
    )
}

