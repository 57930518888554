export const ListOptionsPaymentBy = [
    {
        value: "1",
        label: "Empresa"
    },
    {
        value: "0",
        label: "Paciente"
    }
];
export const OptionPaymentByCompany = {
    value: "1",
    label: "Empresa"
};
export const OptionPaymentByPatient = {
    value: "0",
    label: "Paciente"
};
export const AvailablePayMethodByCompany = "CREDITO";
