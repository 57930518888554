import React, { Fragment, useState } from 'react'
import { Alert, Button, Card, CardBody, Col, Container, FormGroup, Label, Row, Table, Badge } from 'reactstrap'
import { useCashGlobal } from '../../hooks/cash/useCashGlobal'
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import './styleCash.css';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { ModalPreviewGlobalCash } from './ModalPreviewGlobalCash';
import { ModalPreviewGlobalBranch } from './ModalPreviewGlobalBranch';
import { ModalPreviewGlobalSearch } from './ModalPreviewGlobalSearch';


export const IndexCashFlowGlobal = () => {

    //localStorage
    let branchs = localStorage.getItem('branchName');
    let user = localStorage.getItem('nameUser');

    const {
        //filter type cash flow global
        filter, handleChangeTypeClient, loadingData,
        //date
        date1, date2, handlePickerValues, getInformationGlobal, dataGlobal, TotalAmount, TotalAmountCapture, formatter,
        //Amount Total Branch
        branch, amountTotal,
        //modal Preview Global
        typeModal, modal, toggle, previewGlobalCash,
        //capture amount
        handleInputChange, capture_amount, formValues, comments,
        //cretae
        saveCashFlowGlobal,
        //cardInfo
        cardInfo, cardInfoKey,
        //modal Preview
        previewGlobalCashB, toggleB, typeModalB, modalB,
        //validations
        validationsSG,
        //modal Search
        toggleS, typeModalS, modalS, previewGlobalCashS,
        dataHistoric,printTicket

    } = useCashGlobal();

    ////console.log(amountTotal);

    const AlertItem = props => {
        const { item } = props;
        const [Open, setOpen] = useState(true);

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={Open}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }

    return (
        <Fragment>
            <Container fluid={true} >
                <Row className="mt-2 mb-3">
                    <Col sm="2" md="2" lg="2" className="default-according style-1 faq-accordion job-accordion position-relative">
                        <Row className="position-sticky">
                            <Col sm="12" md="12" className='pr-0 file-spacing p-l-0'>
                                <div className='file-sidebar'>
                                    <Card className='' >
                                        <ul>
                                            <li>
                                                <div className={`btn ${filter === '1' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeClient("1")}  ><i className="icofont icofont-list"></i> &nbsp;&nbsp; Cierre Global</div>
                                            </li>
                                            <li>
                                                <div className={`btn ${filter === '2' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={previewGlobalCashB}> Visualización Global</div>
                                            </li>
                                            <li>
                                                <div className={`btn ${filter === '1' ? 'btn-secondary-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeClient("3")}  ><i className="fa fa-filter fIconFilter"></i> &nbsp;&nbsp; Filtro de Búsqueda</div>
                                            </li>
                                            <li>
                                                <div className={`btn ${filter === '3' ? 'btn-dark-gradien' : 'btn-outline-dark-2x'}`} onClick={previewGlobalCashS}   ><i className="icon-search fIcon"></i> &nbsp;&nbsp; Historial</div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <Col sm="12"> <br />
                                            <label className='txt-secondary f-12'>Seleccione la fecha que desea consultar.</label>
                                            <hr />
                                            <FormGroup>
                                                <Label >Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
                                                <Datetime
                                                    inputProps={{
                                                        placeholder: 'DD-MM-YYYY',
                                                        name: 'date1',
                                                        autoComplete: "off",
                                                        className: "form-control form-control-sm input-air-primary"
                                                    }}
                                                    timeFormat={false}
                                                    value={date1}
                                                    locale="es"
                                                    closeOnSelect={true}
                                                    onChange={(e) => handlePickerValues(e, "date1")}
                                                    dateFormat="DD-MM-YYYY"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label >Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                <Datetime
                                                    inputProps={{
                                                        placeholder: 'DD-MM-YYYY',
                                                        name: 'date2',
                                                        autoComplete: "off",
                                                        className: "form-control form-control-sm input-air-primary"
                                                    }}
                                                    timeFormat={false}
                                                    value={date2}
                                                    locale="es"
                                                    closeOnSelect={true}
                                                    onChange={(e) => handlePickerValues(e, "date2")}
                                                    dateFormat="DD-MM-YYYY"
                                                />
                                            </FormGroup>
                                            <br />
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Button outline color='primary' style={{ textAlign: "center" }} onClick={() => getInformationGlobal()} >Buscar</Button>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="10" md="10" lg="10">
                        <Col sm="12">
                            <Row>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />
                                                <label className='txt-primary'>Monto Punto de Venta</label> <br />
                                                <b className='f-14'>{formatter.format(TotalAmount)}</b>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />
                                                <label className='txt-primary'>Monto Recibido</label> <br />
                                                <b className='f-14'>{formatter.format(TotalAmountCapture)}</b>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Card className='shadow'   >
                            <CardBody>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="10">
                                            <label className='f-16'>Sucursales</label>
                                        </Col>
                                        <Col sm="2" style={{ textAlign: "end" }} >
                                            <Button color='primary' id='btn-tooltip-filter-new-patients' type='button' className='btn btn-pill btn-sm' onClick={previewGlobalCash} >
                                                <i className="fa fa-group"></i>
                                                <ToolTips
                                                    placement="top"
                                                    dataTarget={'btn-tooltip-filter-new-patients'}
                                                    dataText='Información Global'
                                                />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <hr />
                                <div className="table-responsive">
                                    <Table className="table-border-vertical">
                                        <thead className="theadA">
                                            <tr>
                                                <th className='labelThead' scope="col">#</th>
                                                <th className='labelThead' scope="col">Sucursal</th>
                                                <th className='labelThead' scope="col">Monto Punto de Venta</th>
                                                <th className='labelThead' scope="col">Monto Recibido</th>
                                            </tr>
                                        </thead>
                                        {
                                            branch.length === 0 ?
                                                <th>
                                                    <th rowSpan={7} colSpan="2" style={{ verticalAlign: "middle", width: '550px' }} className='text-center '>
                                                        <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: '120px' }} />
                                                        <br /> <br />
                                                        <p className='f-12 text-primary'> <b>Realize el filtrado por fecha para poder visualizar los Cortes Globales.</b></p>
                                                    </th>
                                                </th>
                                                :
                                                <tbody>
                                                    {
                                                        branch.length > 0 && branch.sort() && branch.map((a, b) => {
                                                            return (
                                                                <tr>
                                                                    <td>{b + 1}</td>
                                                                    <td>{a}</td>
                                                                    <td>${amountTotal[a].Amount != undefined && amountTotal[a].Amount}</td>
                                                                    <td>${amountTotal[a].AmountCapture != undefined && amountTotal[a].AmountCapture}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                        }
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className={dataGlobal.length === 0 ? 'd-none' : 'm-b-15 animate__animated animate__fadeInDown  '}>
                            <CardBody>
                                <label className='f-16'>Generar Cierre</label>
                                <hr />
                                <Col sm="12">
                                    <Row>
                                        <Col sm="7" md="7" lg="7">
                                            <Badge className='f-12' color='primary' style={{ width: '100%' }}>Datos generales</Badge>
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Card className='shadow  disabled disableLabel ' >
                                                    <CardBody className='p-t-0' > <br />
                                                        <i className="fa fa-circle-o text-info"> </i> <b>Responsable:</b> <label className='text-secondary'>&nbsp;&nbsp;{user}</label>
                                                        <br />
                                                        <i className="fa fa-circle-o text-info"> </i>  <b>Sucursal:</b><label className='text-secondary'>&nbsp;&nbsp;{branchs}</label>
                                                        <br />
                                                        <i className="fa fa-circle-o text-info"> </i> <b>Monto a depositar:</b> <label htmlFor="">{formatter.format(formValues.capture_amount)}</label>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Col>
                                        <Col sm="5" md="5" lg="5" >
                                            {
                                                (validationsSG.capture_amount) &&
                                                <AlertItem
                                                    item={
                                                        {
                                                            alerttxt: "Ingrese una cantidad minima para poder generar un cierre global",
                                                            alertcolor: 'danger inverse',
                                                            btnclose: 'default',
                                                            icon: <i className="icon-alert"></i>
                                                        }
                                                    }
                                                />
                                            }
                                            <tr>
                                                <th> <label htmlFor="">Ingrese la Cantidad</label>  </th>
                                                <th>
                                                    <label className='f-16 txt-secondary'>$</label> <input type="number" className='inputCash f-16' placeholder='cantidad a depositar' value={capture_amount} name="capture_amount" onChange={handleInputChange} />
                                                </th>
                                            </tr>
                                            <hr />
                                            <th> <br /> <label htmlFor="">Observaciones:</label>  </th>
                                            <th style={{ verticalAlign: "middle", width: '550px' }}>
                                                <textarea
                                                    className='form-control form-control-sm input-air-primary'
                                                    name="comments"
                                                    rows="3"
                                                    value={comments}
                                                    onChange={handleInputChange}
                                                >
                                                </textarea>
                                            </th>
                                            <hr />
                                            <br />
                                            <Col sm="12" className='p-r-0' style={{ textAlign: "right" }} >
                                                <div className="btn btn-primary-gradien" onClick={saveCashFlowGlobal} > &nbsp;&nbsp; Generar Cierre Global</div>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </CardBody>
                        </Card>
                        <br /> <br /> <br />
                    </Col>
                </Row>
            </Container>
            <ModalPreviewGlobalCash
                {
                ...{ typeModal, modal, toggle, loadingData, dataGlobal, formatter,printTicket }
                }

            />

            <ModalPreviewGlobalBranch
                {
                ...{ toggleB, typeModalB, modalB, cardInfo, cardInfoKey, loadingData }
                }

            />

            <ModalPreviewGlobalSearch
                {
                ...{ toggleS, typeModalS, modalS, dataHistoric, formatter }
                }

            />

        </Fragment>


    )
}


