import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { FormTransports } from './FormTransports';
import { DeleteTransports } from './DeleteTransports';

export const ModalTransports = (props) => {

    const {
        modal, modalTitle, toggle, method,
        loading, validaciones,
        name, abbreviation, code, handleInputChange,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormTransports
                        button={ModalButtonSave}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormTransports
                            button={ModalButtonUpdate}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, abbreviation, code }} />
                        :
                        <DeleteTransports {...{ toggle, loading, name, abbreviation, code, saveDelete }} />
            }
        </Modal>
    )
}
