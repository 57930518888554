import React from 'react';
import { Button, Form, FormGroup, Input, Label, InputGroup, ModalBody, ModalFooter, Row } from 'reactstrap'

export const FormUnitConversion = (props) => {

    const { toggle, loading, units, id_unit_from, id_unit_to, conversion_factor, handleInputChange, methodAction } = props;

    return (
        <Form role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-12">
                        <Label className="text-sm">Unidad de origen</Label>
                        <InputGroup className="input-group">
                            <Input
                               className="form-control form-control-sm input-air-primary" 
                                type="select"
                                name="id_unit_from"
                                onChange={handleInputChange}
                                value={id_unit_from}
                                required={true}
                            >
                                <option value={0}>Selecciona la unidad de origen</option>
                                {
                                    units.length > 0 &&
                                    units.map((u, key) => {
                                        return <option
                                            key={key}
                                            value={u.id_unit}
                                        >
                                            {u.unit} ({u.abbreviation})
                                        </option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-12">
                        <Label className="text-sm">Unidad de destino</Label>
                        <InputGroup className="input-group">
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="select"
                                name="id_unit_to"
                                onChange={handleInputChange}
                                value={id_unit_to}
                                required={true}
                            >
                                <option value={0}>Selecciona la unidad de destino</option>
                                {
                                    units.length > 0 &&
                                    units.map((u, key) => {
                                        return <option
                                            key={key}
                                            value={u.id_unit}
                                        >
                                            {u.unit} ({u.abbreviation})
                                        </option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-12">
                        <Label className="text-sm">Factor de conversión</Label>
                        <InputGroup className="input-group">
                            <Input
                                //placeholder="Factor de conversión"
                                className="form-control form-control-sm input-air-primary" 
                                type="number"
                                name="conversion_factor"
                                value={conversion_factor}
                                onChange={handleInputChange}
                                required={true}
                                autoComplete="off"
                            />
                        </InputGroup>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Crear nueva unidad de conversión
                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
