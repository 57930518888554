import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card, Button, Form, Label, Input,Badge } from 'reactstrap';

export const ModalListSamplings = (props) => {

    const {
        // modal, toggle, workMedicalOrderPDF, typeModal = "view",
        toggleModalViewListSampling, modalViewListSampling, loading, listSamplesTypeTemp,code_Quoter_Temp
    } = props;
    //console.log(listSamplesTypeTemp);
    return (
        <Modal isOpen={modalViewListSampling} toggle={toggleModalViewListSampling} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggleModalViewListSampling} className="bg-primary">
                Listado de Muestras {code_Quoter_Temp}
            </ModalHeader>

            <ModalBody>
            <Card className='shadow'>
                <Row className='mt-2'>
                    <Col sm="12" md="12" lg="12" className='text-center'>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>TIPO DE MUESTRA</th>
                            <th>MUESTRA</th>
                            <th>DETERMINACIONES</th>
                            <th>OBSERVACIONES</th>
                            <th>PRECIO</th>
                            <th>ESTATUS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listSamplesTypeTemp.map((item, index) => (
                            <tr key={index}>
                            <td>
                                <p className={`task_title_${index}`}>{item.name_type_sample}</p>
                            </td>
                            <td>
                                <p className={`project_name_${index}`}>{item.name_sample!=null?item.name_sample:"Sin registro"}</p>
                            </td>
                            <td>
                                <ul>
                                    {item.list_determinations.map((item2, index2) => (
                                    <li key={index2} className={`task_desc_${index2}`}>*{item2.name}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <p className={`task_desc_${index}`}><b>{item.observations!=""?item.observations:"Sin Observaciones"}</b></p>
                            </td>
                            <td>
                                <p className={`task_desc_${index}`}>${item.price_sample}</p>
                            </td>
                            <td>
                                <p className={`task_desc_${index}`}>{item.estatus_sample ? <Badge color='success' style={{ width: "80px" }}>Registrada</Badge> :  <Badge color='warning' style={{ width: "80px" }} >Pendiente</Badge>}</p>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </Col>
                </Row>
                </Card>
            </ModalBody>

            <ModalFooter>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggleModalViewListSampling}>Cancelar</Button>
            </ModalFooter>

        </Modal>
    )
}