import React, { Fragment,useState } from 'react';
import { Button, Card, CardBody, Container, Row, Col, CardHeader,FormGroup,Collapse} from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import Select from 'react-select';
import { RibbonDetailWorkOrder, ButtonGoBack } from '../../../constant';
import { useViewDetailWorkOrder } from '../../../hooks/viewDetailWorkOrder/useViewDetailWorkOrder';
import { useHistory } from 'react-router-dom';

export const IndexViewDetailWorkOrder = () => {

    const {
        detailWorkOrder, handleCheckPrint, examsPrinters, count, imprimir,
        printerPoints, handleInputChangePrinter, id_printer_point, handleSelectValuesPrinter, loading
    } = useViewDetailWorkOrder();

    const history = useHistory();

    const [cabeceraActions, setCabeceraActions] = useState(true);
    const [cabecerasPrint, setCabecerasPrint] = useState(true);



    return (
        <Fragment>
            {/* <Breadcrumb parent="Busqueda" title="Detalles de la orden" /> */}
            <Container fluid={true}> <br />
                <Button color="light" className=" btn-air-light m-1"  onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button> 
                <div className="edit-profile"> <br />
                    <Row>
                        <Col xl="4">
                                <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
                                    <Row className="position-sticky" style={{ top: 140 }}>
                                        <Col xl="12" className='p-l-0 p-r-0'>
                                            <Card>
                                            <CardHeader className=' bg-primary '>
                                                <h6 className="mb-0">
                                                    <Button color="link pl-0" data-toggle="collapse"  onClick={() => setCabeceraActions(!cabeceraActions)} 
                                                        data-target="#collapseicon" aria-expanded={cabeceraActions} aria-controls="collapseicon" ><i className="fa fa-user"></i> &nbsp; &nbsp; &nbsp; &nbsp; Datos del Peciente
                                                    </Button>
                                                </h6>
                                            </CardHeader>
                                            <Collapse isOpen={cabeceraActions}>
                                                <Col sm="12">
                                                    <Row> 
                                                        <Col sm="3" className='p-r-0 p-l-0'>  <br />
                                                        {
                                                            (detailWorkOrder.gender) ==="Masculino" ?
                                                            <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")}  style={{ width: "90%" }} alt="" /></div>
                                                            :
                                                            <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")}  style={{ width: "90%" }} alt="" /></div>
                                                        }
                                                        </Col>
                                                        <Col sm="9" className='p-2'> <br /> 
                                                        <i className="fa fa-circle iconResults f-12"></i>&nbsp; <b className='f-12'>Nombre:</b> <label className='f-12'>{detailWorkOrder.name_patient}</label>  <br />
                                                        <i className="fa fa-circle iconResults f-12"></i>&nbsp;<b className='f-12'>fecha de nacimiento:</b> <label className='f-12'> {detailWorkOrder.patient_birth} </label><br />
                                                        <i className="fa fa-circle iconResults f-12"></i>&nbsp;<b className='f-12'>NIM:</b> <label className='f-12'>{detailWorkOrder.nim}</label> 
                                                        </Col> 
                                                    </Row>
                                                </Col>
                                                <Col sm="12"> <br />
                                                    <Row>
                                                        <Col sm="6">
                                                            <b className='f-12'>Sucursal</b>
                                                            <p>{detailWorkOrder.name_branch}</p>
                                                        </Col>
                                                        <Col sm="6">
                                                            {
                                                               detailWorkOrder.listDoctorsWorkOrders == 0 ?
                                                                <div>
                                                                    <b className='f-12'>Doctor(es)</b> <br />
                                                                    <label className='f-12'>{"Sin doctor asignado"}</label>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <b className='f-12'>Doctor(es)</b>
                                                                    {
                                                                       detailWorkOrder.listDoctorsWorkOrders !== undefined  && detailWorkOrder.listDoctorsWorkOrders.map((obj, i) =>{
                                                                            return(
                                                                                <div key={obj.id_doctor}>
                                                                                <label className='f-12'>-{obj.name}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col sm="12">
                                                            {
                                                                detailWorkOrder.observations === null ?
                                                                <div>
                                                                   <i className="icofont icofont-warning-alt  f-18 text-primary"></i>  <b className='f-12'>Observaciones:</b> <br /> 
                                                                    <label className='f-12' >{"Sin observaciones"}</label>
                                                                </div> 
                                                                :
                                                                <div>
                                                                      <i className="icofont icofont-warning-alt  f-18 text-danger"></i>&nbsp; <b className='f-12'>Observaciones:</b> <br /> 
                                                                    <label className='f-12' >{detailWorkOrder.observations}</label>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                    </Collapse>
                                </Card>
                               </Col>
                            </Row>
                        </Col>
                        <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc2" sm="12">
                                    <Row className="position-sticky" style={{ top: 140 }}>
                                        <Col xl="12" className='p-l-0 p-r-0'>
                                            <Card>
                                            <CardHeader className=' bg-primary '>
                                                <h6 className="mb-0">
                                                    <Button color="link pl-0" data-toggle="collapse"  onClick={() => setCabecerasPrint(!cabecerasPrint)} 
                                                        data-target="#collapseicon" aria-expanded={cabecerasPrint} aria-controls="collapseicon" ><i className="fa fa-print"></i> &nbsp; &nbsp; &nbsp; &nbsp; Re Imprimir Etiqutas
                                                    </Button>
                                                </h6>
                                            </CardHeader>
                                            <Collapse isOpen={cabecerasPrint}> <br />
                                                <Col sm="12" style={{ textAlign: "-webkit-center"}}>
                                                    <label className=''>Exámenes / Perfiles seleccionados: <label className='f-18 txt-info'>{count}</label>   </label>
                                                </Col><hr />
                                                <Col sm="12">
                                                <FormGroup>
                                                <label>Punto de impresión</label>
                                                <Select
                                                   // className="form-control form-control-plaintext form-control-sm"
                                                    classNamePrefix="select"
                                                    name="id_printer_point"
                                                    value={id_printer_point}
                                                    options={printerPoints}
                                                    onChange={(e) => handleSelectValuesPrinter(e, 'id_printer_point')}
                                                    placeholder="Selecciona una opción"
                                                    isClearable={true}
                                                />
                                            </FormGroup>
                                                </Col> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Col sm="8" style={{ textAlignLast: "center"}}>
                                                <Button 
                                                disabled={(count > 0 && (id_printer_point !== 0 && id_printer_point !== null)) ? false : true}
                                                color="primary"
                                                outline={true} onClick={imprimir}
                                                className={(loading ? "disabled progress-bar-animated progress-bar-striped btn-block" : "btn-block")}>IMPRIMIR</Button> <br /> 
                                                </Col>

                                                </Row>
                                                </Col>

                                    </Collapse>
                                </Card>
                               </Col>
                            </Row>
                        </Col>                   
                        </Col>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary pointer">
                                    {"Listado de Exámenes"}
                                </div>
                                    <br /> <br />
                                    <Row>
                                        <br />
                                        {
                                            detailWorkOrder.listExam !== undefined && detailWorkOrder.listExam.map((data, key) => {
                                                return (
                                                    <Col xl="4" md="6" key={key} classNames="pointer" >
                                                        <div className="prooduct-details-box ribbon-wrapper-bottom" onClick={() => handleCheckPrint(data.id_exam, "exam")}>
                                                            <div className="media b-t-info border-3 pointer">
                                                                {
                                                                    examsPrinters.find(x => x.id === data.id_exam && x.type === "exam") &&
                                                                    <div className="ribbon ribbon-clip-bottom-right ribbon-info"><i className="icofont icofont-print"></i></div>
                                                                }
                                                                <div className="media-body ml-3">
                                                                    <div className="product-name">
                                                                        <h6><a href="#javascript">{data.name}</a></h6>
                                                                    </div>
                                                                    <div className="product-name">
                                                                        <p>{data.abbreviation}</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="text-muted mr-2">Precio:</div> $ {data.price.toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {"Listado de Perfiles"}
                                </div>
                                    <br />
                                    <Row>
                                        <br />
                                        {
                                            detailWorkOrder.listProfile !== undefined && detailWorkOrder.listProfile.map((data, key) => {
                                                ////console.log(data);
                                                return (
                                                    <Col xl="4" md="6" key={key}>
                                                        {
                                                            detailWorkOrder.listProfile.length === 0?
                                                            <label >Sin perfiles</label>
                                                            :
                                                            <div className="prooduct-details-box ribbon-wrapper-bottom" onClick={() => handleCheckPrint(data.id_profile, "profile")}>
                                                            <div className="media b-t-info border-3">
                                                                {
                                                                    examsPrinters.find(x => x.id === data.id_profile && x.type === "profile") &&
                                                                    <div className="ribbon ribbon-clip-bottom-right ribbon-info"><i className="icofont icofont-print"></i></div>
                                                                }
                                                                <div className="media-body ml-3">
                                                                    <div className="product-name">
                                                                        <h6><a href="#javascript">{data.name_profile}</a></h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="text-muted mr-2">Precio:</div> $ {data.price.toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}
