import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Input, Card, CardBody, Container, Row, Col, Button, FormGroup, Label, List, Table, Media, CardHeader, Collapse } from 'reactstrap';
import { useWorkSheetsDay } from '../../../hooks/workOrder/useWorkSheetsDay';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import search from '../../../assets/images/search-not-found.png';
import './styleWorkDay.css';

//MULTISELECT
import '../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { MultiSelect } from 'primereact/multiselect';

export const IndexWorkSheetsDay = () => {

    const { workOrderExams, sections, sectionsDefault, branches, branchesDefault, nim,
        exam_select, date_search, date_search_end,
        handleInputChange, handlePickerValues, handleDate, handleSelectValues, checkTypePrint,
        FilterData, loading, downloadSheetWorkDay, loadingPdf, statusPrint,
        changeCheckbox, changeCheckboxBranch, examsFilter, navigateCaptureResults, setselectedSections, selectedSections, sectiondefault,
        getNimsFromRange, changeFiltersRangeNim
    } = useWorkSheetsDay();

    const [filterSections, setFilterSections] = useState(false);
    const [filterExam, setFilterExam] = useState(true);
    const [filterActions, setFilterActions] = useState(true);
    const txtsection = localStorage.getItem('TextAS');

    if (exam_select !== null) {
    }


    const [filters, setFilters] = useState(true);
    const [filterDate, setFilterDate] = useState(false);
    const [filtersRangeNim, setFiltersRangeNim] = useState(false);

    const changeFilters = (typeFilter) => {
        switch (typeFilter) {
            case "date":
                setFilters(!filters);
                setFilterDate(false);
                setFiltersRangeNim(false);
                break;
            case "search":
                setFilters(false);
                setFilterDate(!filterDate);
                setFiltersRangeNim(false);

                break;
            case "range":
                setFilters(false);
                setFilterDate(false);
                setFiltersRangeNim(!filtersRangeNim);

                break;
            default:
                break;
        }
    }


    return (
        <Fragment>
            <Container fluid={true}> <br />
                <Row className='m-b-10'>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="6" md="6" lg="3" xl="3">
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("date")}
                                                data-target="#collapseicon" aria-expanded={filters} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i> &nbsp; &nbsp; &nbsp; &nbsp;<label className='labelFilter'>Filtros de busqueda</label></Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filters}>
                                        <CardBody className="p-2 p-t-0">
                                            <Row className='m-t-5'>




                                                <Label className="col-12 txt-secondary"><i className="fa fa-filter fIconFilter f-16"></i>Filtro de Rango de nims </Label>
                                                <FormGroup className="col-md-6">
                                                    <Input type='number' className='form-control form-control-sm input-air-primary'
                                                        name="init_range_nim"
                                                        // value={nim}
                                                        onChange={(e) => changeFiltersRangeNim(e.target.value, "init_range_nim")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-md-6">
                                                    <Input type='number' className='form-control form-control-sm input-air-primary'
                                                        name="end_range_nim"
                                                        // value={nim}
                                                        onChange={(e) => changeFiltersRangeNim(e.target.value, "end_range_nim")}
                                                    />
                                                </FormGroup>
                                                <hr />


                                                <Label className="col-12">Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                <FormGroup className="col-md-6">
                                                    <Datetime
                                                        inputProps={{
                                                            placeholder: 'mm-dd-aaaa',
                                                            name: "workFrom",
                                                            autoComplete: "false",
                                                            className: "form-control digits input-air-primary",
                                                            size: "sm",
                                                        }}
                                                        timeFormat={false}
                                                        dateFormat="MM-DD-YYYY"
                                                        value={date_search}
                                                        locale="es"
                                                        initialValue={new Date()}
                                                        onChange={(e) => handlePickerValues(e, "date_search")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-md-6">
                                                    <Datetime
                                                        inputProps={{
                                                            placeholder: 'mm-dd-aaaa',
                                                            name: "workFrom",
                                                            autoComplete: "false",
                                                            className: "form-control digits input-air-primary",
                                                            size: "sm",
                                                        }}
                                                        timeFormat={false}
                                                        dateFormat="MM-DD-YYYY"
                                                        value={date_search_end}
                                                        locale="es"
                                                        initialValue={new Date()}
                                                        onChange={(e) => handlePickerValues(e, "date_search_end")}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup>
                                                    <Label className="col-12">Secciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <Col sm="12">
                                                        <MultiSelect
                                                            className="basic-multi-select"
                                                            value={selectedSections}
                                                            options={sectiondefault}
                                                            onChange={(e) => changeCheckbox(e.value)}
                                                            optionLabel="name"
                                                            placeholder="Selecciona Aquí"
                                                            maxSelectedLabels={3}
                                                            selectedItemsLabel="Secciones Agregadas"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <Col md="12 m-0">
                                                    <Label className="col-form-label">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Select
                                                            isMulti
                                                            name="branches"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage={() => "Sin resultados"}
                                                            options={branches}
                                                            onChange={(e) => changeCheckboxBranch(e)}
                                                            defaultValue={branchesDefault}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-5'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label>Tipo de impresión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-5'>
                                                <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <Label className="labelCheckPrint">{"Lista"}</Label> <br />
                                                    <Label className="switch">
                                                        <Input type="radio" name="checkTypePrint" value="list" checked={checkTypePrint === "list" ? true : false} onChange={(e) => handleDate(e, "checkTypePrint")} />
                                                        <span className="slider round"></span>
                                                    </Label>
                                                </Col>
                                                <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <Label className="labelCheckPrint">{"Detalle"}</Label> <br />
                                                    <Label className="switch">
                                                        <Input type="radio" name="checkTypePrint" value="detail" checked={checkTypePrint === "detail" ? true : false} onChange={(e) => handleDate(e, "checkTypePrint")} />
                                                        <span className="slider round"></span>
                                                    </Label>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="12">
                                                    <Button outline color="primary" className={(loading ? "text-center disabled progress-bar-animated progress-bar-striped" : "text-center")} onClick={FilterData}> Filtrar</Button>
                                                </Col> <br />
                                                <Col md="12"><br />
                                                    <Button outline color="danger" className={(loadingPdf ? "text-center disabled progress-bar-animated progress-bar-striped mt-1" : "text-center")} onClick={downloadSheetWorkDay}> Imprimir</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>

                                {/* <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("range")}
                                                data-target="#collapseicon" aria-expanded={filtersRangeNim} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i>&nbsp; &nbsp; &nbsp; &nbsp; <label className='labelNim'>Filtro de rangos de nims</label>   </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filtersRangeNim}>
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col>
                                                    <Label className=" ">Secciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Col sm="12" className='p-l-0' >
                                                            <MultiSelect
                                                                className="basic-multi-select"
                                                                value={selectedSections}
                                                                options={sectiondefault}
                                                                onChange={(e) => changeCheckbox(e.value)}
                                                                optionLabel="name"
                                                                placeholder="Selecciona Aquí"
                                                                maxSelectedLabels={3}
                                                                selectedItemsLabel="Secciones Agregadas"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label className=" ">Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup className="">
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control",
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat="YYYY-MM-DD"
                                                            locale="es"
                                                            initialValue={new Date()}
                                                            onChange={(e) => changeFiltersRangeNim(e, "date_range_nim")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label className="">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Select
                                                            name="mSelectBranch"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage="Sin resultados"
                                                            options={branches}
                                                            onChange={(e) => changeFiltersRangeNim(e, 'branch_range_nim')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="">Inicio<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="init_range_nim"
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "init_range_nim")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="">Fin<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="end_range_nim"
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "end_range_nim")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Button onClick={getNimsFromRange} type="button" outline color="primary" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} > Buscar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card> */}

                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("search")}
                                                data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon"><i className="icon-search fIcon"></i>&nbsp; &nbsp; &nbsp; &nbsp; <label className='labelNim'>Busqueda por NIM</label>   </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filterDate}>
                                        <CardBody className="p-3">
                                            {/* <Form onSubmit={(e) => getWorkOrders(e, true)}> */}
                                            <Row>
                                                <Col md="12 m-0">
                                                    <Label className="col-form-label">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Select
                                                            isMulti
                                                            name="branches"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage={() => "Sin resultados"}
                                                            options={branches}
                                                            onChange={(e) => changeCheckboxBranch(e)}
                                                            defaultValue={branchesDefault}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <FormGroup className="col-md-12">
                                                    <Label className="">Ingresa el NIM  o Nombre a buscar<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>

                                                    <Input className='form-control form-control-sm input-air-primary' name="nim" value={nim} onChange={handleInputChange} />
                                                </FormGroup>
                                                <Col md="12">
                                                    <Button outline color="primary" className={(loading ? "text-center disabled progress-bar-animated progress-bar-striped" : "text-center")} onClick={FilterData}> Filtrar</Button>
                                                </Col> <br />

                                            </Row>
                                            {/* </Form> */}
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm="9">
                        {
                            Object.entries(workOrderExams).length > 0 ?
                                Object.entries(workOrderExams).map((obj, key) => {
                                    console.log(obj);

                                    let _size =  obj[1][0].doctors === null ? 6 : 4

                                    const _arrayDoctors = obj[1][0].doctors? obj[1][0].doctors.split(',').map(name => name.trim()): [];

                                    return <Row key={obj[0]}>
                                        <Col xl="12" >
                                            <Card className={`'ribbon-vertical-left-wrapper'`}>
                                                <Row className='p-t-2 p-l-5 p-r-1'>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col sm={_size} className='p-l-2 p-t-2'>
                                                                <h6 className="f-w-600 f-14 badge badge-light-primary" style={{ cursor: "pointer" }} onClick={() => navigateCaptureResults(obj[0])}>
                                                                    <strong>{`${obj[0]},${obj[1][0].name_patient}`}</strong>
                                                                </h6>
                                                            </Col>
                                                            <Col sm={_size}  className={obj[1][0].doctors === null ? "d-none" :" p-t-2 p-r-2 txt-primary"} style={{textAlign:"center"}}>
                                                            <b style={{fontSize:"10px"}}>Doctores</b>
                                                            <ul>
                                                                {_arrayDoctors.map((name, index) => (
                                                                <li  style={{fontSize:"10px"}} key={index}> <i className='fa fa-circle'></i> {name}</li>
                                                                ))}
                                                            </ul>                                                          

                                                            </Col>
                                                            <Col sm={_size}  className='p-t-2 p-r-2 txt-primary' style={{ textAlign: "right" }}>
                                                                <b>{obj[1][0].name_branch} - {obj[1][0].date}</b>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className='p-t-2 p-l-3 p-r-3'>
                                                    <Col sm="12">
                                                        <Row className='p-l-2'>
                                                            <Col sm="1" className='p-l-2 p-r-0'>
                                                                {
                                                                    (obj[1][0].gender) === "Femenino" ?
                                                                        <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "100%" }} alt="" /></div>
                                                                        :
                                                                        <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "100%" }} alt="" /></div>
                                                                }
                                                            </Col>
                                                            <Col sm="2"> <br />
                                                                <i className="fa fa-circle iconWorkSheetsDay"></i> <b>Edad:</b> <br />
                                                                <label className=''>{obj[1][0].age}</label>
                                                            </Col>
                                                            <Col sm="2" className='p-1-0'> <br />
                                                                <i className="fa fa-circle iconWorkSheetsDay"></i><b>Genero:</b> <br />
                                                                <label className=''>{obj[1][0].gender}</label>
                                                            </Col>
                                                            <Col sm="4"> <br />
                                                                {
                                                                    obj[1][0].observations == null ?
                                                                        <div>

                                                                            <i className="icofont icofont-warning-alt  f-18 iconWorkSheetsDay"></i>&nbsp; <b>Observaciones:</b> <br />
                                                                            <label >{"Sin observaciones"}</label>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <i className="icofont icofont-warning-alt  f-18 text-danger"></i>&nbsp;  <b>Observaciones:</b> <br />
                                                                            <label >{obj[1][0].observations}</label>
                                                                        </div>
                                                                }
                                                            </Col>
                                                            <Col sm="3"> <br />
                                                                {
                                                                    obj[1][0].income_type === "Empresas" ?
                                                                        <i className="icofont icofont-industries-alt-4 iconWorkSheetsDay f-18"></i> :
                                                                        <i className="icofont icofont-id-card iconWorkSheetsDay f-18"></i>

                                                                }
                                                                <b>Tipo de Ingreso</b> <br />

                                                                <label >{obj[1][0].income_type}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {
                                                    checkTypePrint === "list"
                                                        ?
                                                        <>
                                                            <Row className='mt-2 m-l-3 p-2' key={'list-' + key}>
                                                                {
                                                                    obj[1].map((exam, key2) => {
                                                                        return (
                                                                            <Col sm="3" className='m-b-10' key={key2}>
                                                                                <Row>
                                                                                    <Col sm="12" md="12" className='text-left'>
                                                                                        <small className='label-input-capture text-info f-w-600'>{exam.name_exam}</small>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className=''>
                                                                                    <Col sm="12" md="12" className='text-left'>
                                                                                        <span className='f-12'>
                                                                                            {exam.abbreviation_exam}
                                                                                            &nbsp;
                                                                                            <u style={{ width: 35 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                obj[1].map((exam, key2) => {
                                                                    return (
                                                                        <Row className=' m-l-3 p-2' key={'detail-' + key2}>
                                                                            <Col sm="12" className='typography'>
                                                                                <hr />
                                                                                <label className='label-input-capture txt-primary f-w-600'>{exam.name_exam}</label>
                                                                            </Col>
                                                                            {
                                                                                exam.list_analytes.map((analyte, i) => {
                                                                                    return <Col sm="2" className='m-b-2 text-left' key={'analyte-' + i}>
                                                                                        <span className='f-12'>
                                                                                            {analyte.code}
                                                                                            <u style={{ width: 15 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
                                                                                        </span>
                                                                                    </Col>
                                                                                })
                                                                            }
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </Card>
                                        </Col>
                                    </Row>
                                })
                                :
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <br />
                                                <div className="search-not-found text-center">

                                                    <div>
                                                        <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "60%" }} alt="" /> <br />
                                                        <label className='f-w-600 f-14 badge badge-light-primary'>No se a registrado ninguna hoja de trabajo</label>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                    </Col>
                </Row >
            </Container >
        </Fragment >
    )
}
