import React from 'react'
import DeniReactTreeView from 'deni-react-treeview';
import './styleCalender.css';
import {Col, Row,FormGroup,Input} from 'reactstrap';


export const CaptureAmount = (props) => {

    const { handleChangeAmount,treeViewRef,listBranchesTree,onRenderItem,onChangeItemtree} = props;


    return (
        <div> <br />
            <hr /> 
            <Col sm="12">
                {
                    <div className='border-range-programming '>
                    <Row>
                    <Col sm="4">
                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Sucursales *</span> 
                    <DeniReactTreeView
                        items={listBranchesTree}
                        ref={treeViewRef}
                        showCheckbox={true}
                        showIcon={false}
                        onRenderItem={onRenderItem}
                        style={{ marginRight: '10px', marginBottom: '10px', height: '240px', border: 'none' }}
                        onCheckItem={(e) => onChangeItemtree(e)}
                        className="deni-react-treeview-container-4"
                    />
                    </Col>
                    <Col sm="1">
                    </Col>
                    <Col sm="2">
                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Monto*</span> 
                        {listBranchesTree?.map((_find, _key) => (                            
                            _find.children.map((_branch) => {
                            return (
                                <div key={_branch.id} className={`p-1 `}> 
                                <Input type='text' name="amount" value={_branch.amount} onChange={(e) => handleChangeAmount(e, _branch.id)} autoComplete='off' 
                                 className={`form-control form-control-sm input-air-${_branch.color} ${_branch?.state == 2 ? 'd-none': ''}`} /> 
                                </div>
                                );
                             }) 
                            ))}
                        </Col>
                    </Row>
                    </div> 
                }
            </Col>
        </div>
    )
        
}
