import React from 'react'
import {  Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteCompany = (props) => {

    const {
        toggle, loading, saveDelete, name, code
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar al cliente con nombre:</p>                
                <b>{ code+' '+name }</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                    Sí, eliminar
                </Button>
                <Button className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
