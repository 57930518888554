import React, { Fragment } from 'react'
import { Container } from 'reactstrap';
import './stylePbi.css';
import { usePowerBi } from '../../../hooks/Pbi/usePowerBi';





export const IndexPbi1 = () => {

    const {
        dataLink
    } = usePowerBi();

    return (
        // <Container fluid={true} >
        <div className='embed-container ' >
            <iframe
                id="inlineFrameExample"
                src={dataLink.length > 0 && dataLink[0].link}
                frameborder="0"
            >
            </iframe>
        </div>
        // </Container>

    )
}

