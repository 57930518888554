import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteExamMethods = ({
    toggleModal, loadingModal, name, saveDelete
}) => {
    return (
        <Form role="form" onSubmit={saveDelete}>
            <ModalBody>
                <h4>Estas a punto de eliminar el método: <i>{name}</i>
                    <br />
                    <b>¿Estás seguro de hacerlo?</b></h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loadingModal ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar método
                </Button>
                <Button color="secondary" type="button" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
