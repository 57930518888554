import { Home, Percent, Link, Users, Settings, Menu, Edit, Search, BarChart, DollarSign, File,CreditCard } from 'react-feather';
import { nameGrapPopular } from '../../constant/index';



const pending_branches = localStorage.getItem("pending_branches");

export const MENUITEMSMICROSITIO = [
    {
        menutitle:"",
        menucontent: "",
        Items: [
            {
                title: '', icon: DollarSign, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/defaultMicrositio`,
            }
            // {
            //     path: `${process.env.PUBLIC_URL}/micrositio/index`, title: '', type: 'link'
            // },
        ]
    },
   

]
