import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteRoom } from './DeleteRoom';
import { FormRoom } from './FormRoom';

export const ModalRoom = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, id_departament, code, departaments,
        handleInputChange, loading, handleSelectValues,
        saveCreate, saveUpdate, saveDelete
    } = props;
    //////console.log(id_departament);
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormRoom
                        button={"Crear cuarto"}
                        {...{ toggle, loading, handleInputChange, handleSelectValues, departaments, validaciones, methodAction: saveCreate, departaments}}
                    />
                    : (method === "update")
                        ?
                        <FormRoom
                            button={"Actualizar cuarto"}
                            {...{ toggle, loading, handleInputChange, id_departament, departaments, handleSelectValues, validaciones, methodAction: saveUpdate, name, code, departaments }} />
                        :
                        <DeleteRoom {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )
}
