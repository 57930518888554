import Default from '../components/dashboard/defaultTomaDeMuestra/index'
import { IndexTomaMuestra } from '../components/dashboard/defaultTomaDeMuestra/IndexTomaMuestra'
import { IndexCarta1 } from '../components/dashboard/defaultTomaDeMuestra/ConsentimientoInformado/IndexCarta1'
import { IndexSamplingForm } from '../components/dashboard/defaultTomaDeMuestra/IndexSamplingForm'
import { IndexEncuestaClient } from '../components/dashboard/defaultTomaDeMuestra/ConsentimientoInformado/IndexEncuestaClient'
import { IndexTracking } from '../components/dashboard/defaultTomaDeMuestra/tracking/IndexTracking'
import { IndexSatisfactionQuestion } from '../components/dashboard/defaultTomaDeMuestra/ConsentimientoInformado/IndexSatisfactionQuestion'
import { IndexSend } from '../components/dashboard/defaultTomaDeMuestra/sending/IndexSend'
import { IndexEncuestaInfoEncuesta } from '../components/dashboard/defaultTomaDeMuestra/ConsentimientoInformado/IndexEncuestaInfoEncuesta'

export const RoutesTomaMuestra = [

    { path: `${process.env.PUBLIC_URL}/dashboard/defaultTomaDeMuestra/`, Component: Default },
    { path: `${process.env.PUBLIC_URL}/paciente/:nimm`, Component: IndexTomaMuestra },
    { path: `${process.env.PUBLIC_URL}/concentimiento/:nimm`, Component: IndexCarta1 },
    { path: `${process.env.PUBLIC_URL}/sampling/:nimm`, Component: IndexSamplingForm },
    { path: `${process.env.PUBLIC_URL}/instrucciones/:nimm`, Component: IndexEncuestaInfoEncuesta },
    { path: `${process.env.PUBLIC_URL}/encuesta/:nimm`, Component: IndexEncuestaClient },
    { path: `${process.env.PUBLIC_URL}/dashboard/seguimiento`, Component: IndexTracking },
    { path: `${process.env.PUBLIC_URL}/dinamico`, Component: IndexSatisfactionQuestion },
    { path: `${process.env.PUBLIC_URL}/dashboard/envios`, Component: IndexSend },




]