import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteCompany } from './DeleteCompany';
import { FormCompany } from './FormCompany';
import { FormUpdateBussinessName } from './FormUpdateBussinessName';

export const ModalCompany = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        states, companyTurn, listTaxRegime,
        handleInputChange, handlePickerValues, handleSelectValues, validaciones,
        id_company_turn, code, name, is_maquila, customer_since,
        send_results_pacient, results_email, logo,
        is_block, consecutive_number, microsite, password_microsite,
        username, payment_by_company, payment_by_patient, 
        require_invoice, available_invoice_patient, credit_days,
        imageLogo, imageHandler,
        saveCreate, saveUpdate, saveDelete,
        handleChangePaymentBy,
        handleChangeState,
        previewLogo, category, 
        handleAddCompany,
        listCompanyBusinessNames, handleDinamicInputRFC, removeAlterCompanies,

        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name, 
        email_business_name, tax_regime_business_name, type_person_business_name,
        
        saveUpdateBusinessName, validationsBusinessName,is_requirements,requirements,
        on_check_req_especial,stamp_invoice
    } = props;

  

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className={(method !== 'delete' && method !== 'UpdateBussinessName') ? 'modal-xl' : ''}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ?
                    <FormCompany
                        button="Guardar"
                        {...{
                            toggle, loading, handleInputChange, handlePickerValues, handleSelectValues, validaciones, methodAction: saveCreate,
                            states, companyTurn, listTaxRegime, handleChangeState, previewLogo, imageHandler, microsite, password_microsite,category,is_block, consecutive_number,
                            payment_by_company, payment_by_patient, 
                            require_invoice, available_invoice_patient, credit_days,
                            handleAddCompany,send_results_pacient,
                            listCompanyBusinessNames,handleDinamicInputRFC,removeAlterCompanies,
                            is_requirements,requirements,on_check_req_especial, handleChangePaymentBy,stamp_invoice
                        }}
                    />
                    :
                    (method === "update")
                        ?
                        <FormCompany
                            button="Actualizar"
                            {
                                ...{
                                    toggle, loading, handleInputChange, handlePickerValues, handleSelectValues, validaciones, methodAction: saveUpdate,
                                    states, companyTurn, listTaxRegime, handleChangeState, previewLogo, imageHandler, microsite, password_microsite, category, is_block, consecutive_number,
                                    payment_by_company, payment_by_patient, 
                                    require_invoice, available_invoice_patient, credit_days,
                                    handleAddCompany, send_results_pacient, 
                                    listCompanyBusinessNames,handleDinamicInputRFC,
                                    id_company_turn, code, name, is_maquila, customer_since,
                                    logo, imageLogo, username, results_email,
                                    is_requirements,requirements,on_check_req_especial, handleChangePaymentBy,stamp_invoice
                                }
                            }
                        />
                        :
                        (method === "UpdateBussinessName")
                        ?
                            <FormUpdateBussinessName
                                button="Actualizar datos"
                                {
                                    ...{
                                        toggle, handleInputChange, listTaxRegime, loading,
                                        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name, 
                                        email_business_name, tax_regime_business_name, type_person_business_name,
                                        
                                        saveUpdateBusinessName, validationsBusinessName
                                    }
                                }
                            />
                        :
                        <DeleteCompany
                            {...{ toggle, loading, saveDelete, name, code }}
                        />
            }
        </Modal>
    )
}
