import React, { Fragment, useState } from 'react'
import { useReports } from '../../../hooks/balanceDashboard/useReports'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Container,Button, Collapse } from 'reactstrap'
import Chart from 'react-apexcharts'
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import './styleBalan.css';

export const IndexReports = () => {

	const {
		date_ini, date_end, series, options, obtenerDatos,
		validInputDate, validToInputDate, loading,
		inactiveCompany, newCompany, reactivateCpmany,
		handlePickerValues 

	} = useReports();

	const [filterDate, setFilterDate] = useState(true);


	const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

	const [isOpen1, setIsOpen1] = useState(false);

    const toggle1 = () => setIsOpen1(!isOpen1);

	const [isOpen2, setIsOpen2] = useState(false);

    const toggle2 = () => setIsOpen2(!isOpen2);



	return (
		<Fragment>
			{/* <Breadcrumb parent="Reportes" title="Reportes" className="m-b-0" /> */}
			<Container fluid={true}>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Row>
							<Col className="default-according style-1 faq-accordion job-accordion " id="accordionoc" xs="12" sm="12" md="12" lg="3" xl="3">
								<Row className="position-sticky" style={{ top: 120 }}>
									<Col xl="12">
										<Card>
											<br />
											<CardHeader className=' bg-primary '>
												<h5 className="mb-0">
													<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
														data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="fa fa-building"></i>&nbsp; &nbsp; &nbsp; &nbsp;Empresas</Button>
												</h5>
											</CardHeader>
											<Collapse isOpen={filterDate}>
												<CardBody className="p-3">
													<Form className=''>
														<Row>
															<Col md="12">
																<FormGroup>
																	<Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
																	<Datetime	
																		key={156}																										
																		inputProps={{
																			placeholder: 'DD-MM-YYYY',
																			name: 'date_ini',
																			autoComplete: "off",
																			className: "form-control form-control-sm input-air-primary"
																		}}
																		timeFormat={false}
																		value={date_ini}
																		locale="es"
																		isValidDate={validInputDate}
																		closeOnSelect={true}
																		onChange={(e) => handlePickerValues(e, "date_ini")}
																		dateFormat="DD-MM-YYYY"	
																	/>																	
																</FormGroup>
															</Col>
															<br />
															<Col md="12">
																<FormGroup>
																	<Label className='col-md-12'>Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																	<Datetime	
																		key={"date_end"}																										
																		inputProps={{
																			placeholder: 'DD-MM-YYYY',
																			name: 'date_end',
																			autoComplete: "off",
																			className: "form-control form-control-sm input-air-primary"
																		}}
																		timeFormat={false}
																		value={date_end}
																		locale="es"
																		isValidDate={validToInputDate}
																		closeOnSelect={true}
																		onChange={(e) => handlePickerValues(e, "date_end")}
																		dateFormat="DD-MM-YYYY"	
																	/>
																</FormGroup>
															</Col>
															<Col sm="12">
																<br />
																<Button
																	type='button'
																	className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																	outline
																	color="secondary"
																	style={{ textAlign: "-webkit-center" }}
																	onClick={() => obtenerDatos()}
																>
																	{"Buscar"}
																</Button>
															</Col>
														</Row>
													</Form>
												</CardBody>
											</Collapse>
										</Card>
									</Col>
								</Row>
							</Col>
							<Col xs="12" sm="12" md="12" lg="9" xl="9">
								<Col sm="12">
									<div id="chart-Examenes+Vendidos">
										{
											series.length === 0 ?
											<div style={{ textAlign: "-webkit-center" }}>
												<img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/AlertChart.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
												<br /> <p className="f-18">{"No hay Registrado Ninguna Busqueda de un exámen."}</p>
											</div>
											:
											<Chart options={options} series={series} type="bar" height={290} />					
										}
									</div>
								</Col>
							</Col>
						</Row>
					</Col>
					<Col sm="12">
						<Row className={ (newCompany.length > 0) ? '' : 'd-none' }>
							<Col xs="4" sm="4" md="4" lg="4" xl="4">
							<Card className='shadow' >
							<div onClick={toggle} className="p-3" style={{ cursor: "pointer" }}>
								<div className="align-items-center row">
									<div className="col-12" style={{ textAlign: "-webkit-center"}}>
										<Row>
											<div className='col-9'>
											<h6 className="mb-0 labelNewCompany">Empresas Nuevas</h6> <br />
											<h6>{newCompany.length}</h6>

											</div>
											<div className='col-3 p-l-0 p-r-0'>
											<img className="" src={require("../../../assets/images/loginHelenLabs/ReportC/NewCompany.svg")}  style={{ width: "100%" }} alt="" />   <br />                                         
											</div>
										</Row>
										
									</div>
									<div className="text-right col-12">
										{
											newCompany.length === 0 ? "" : <i className={`icofont icofont-arrow-${isOpen ? "up" : "down"}`}> <label className='labelChart'>Ver Empresa</label> </i>
										}
									</div>
								</div>
							</div>
							<Collapse isOpen={ newCompany.length === 0 ? "" : isOpen }>
							<CardBody className='p-1'>
							<Col sm="12">
								<Row>
									<Col sm="1">
									</Col>
									<Col sm="10" className='p-1'>
										{
										newCompany.length >= 1 && newCompany.map( (obj, i) =>{																
										return <li key={i} className={obj.alert === true ? "text-danger" : ""}>{obj.name}</li>
										})
										}
									</Col>
									<Col sm="1">
									</Col>
								</Row>
							</Col>
							</CardBody>
							</Collapse>
							</Card>
							</Col>
							<Col xs="4" sm="4" md="4" lg="4" xl="4">
								
							<Card className='shadow'>
							<div onClick={toggle1} className="p-4" style={{ cursor: "pointer" }}>
								<div className="align-items-center row">
									<div className="col-12" style={{ textAlign: "-webkit-center"}}>
										<Row>
											<div className='col-9'>
											<h6 className="mb-0 labelDangerCompany">Empresas Inactivas</h6> <br />
											<h6>{inactiveCompany.length}</h6>
											</div>
											<div className='col-3 p-l-0 p-r-0'>
											<img className="" src={require("../../../assets/images/loginHelenLabs/ReportC/DangerCompany.svg")}  style={{ width: "100%" }} alt="" />   <br />                                         
											</div>
										</Row>
										
									</div>
									<div className="text-right col-12">
										{
											inactiveCompany.length === 0 ? "" :  <i className={`icofont icofont-arrow-${isOpen1 ? "up" : "down"}`}>ver Empresa</i>
										}
									</div>
								</div>
							</div>
							<Collapse isOpen={inactiveCompany.length === 0 ? "" : isOpen1 }>
								<CardBody className='p-1'>
									<Row>
									<Col sm="12">
										<Row>
										<Col sm="1">
										</Col>
										<Col sm="10" className='p-1'>
											{
												inactiveCompany.length >= 1 && inactiveCompany.map( (obj, i) =>{																
												return <li key={i} className={obj.alert === true ? "text-danger" : ""}>{obj.name}</li>
												})
											}
										</Col>
										<Col sm="1">
										</Col>
										</Row>
									  </Col>
									</Row>
								</CardBody>
							</Collapse>
							</Card>
							</Col>
							<Col xs="4" sm="4" md="4" lg="4" xl="4">
								<Card className='shadow'>
									<div onClick={toggle2} className="p-4" style={{ cursor: "pointer" }}>
									<div className="align-items-center row">
									<div className="col-12" style={{ textAlign: "-webkit-center"}}>
										<Row>
											<div className='col-9'>
											<h6 className="mb-0 labelReactivateCompany">Empresas Reactivadas</h6> <br />
											<h6>{reactivateCpmany.length}</h6>
											</div>
											<div className='col-3 p-l-0 p-r-0'>
											<img className="" src={require("../../../assets/images/loginHelenLabs/ReportC/ReactivateCompany.svg")}  style={{ width: "100%" }} alt="" />   <br />                                         
											</div>
										</Row>
										
									</div>
									<div className="text-right col-12">
										{
											reactivateCpmany.length === 0 ? "" : <i className={`icofont icofont-arrow-${isOpen2 ? "up" : "down"}`}>ver Empresa</i>
										} 
									</div>
										</div>
									</div>
									<Collapse isOpen={ reactivateCpmany.length === 0 ? "" : isOpen2 } >
										<CardBody className='p-1'>
											<Row>
												<Col sm="1">
												</Col>
												<Col sm="10">
													{
													reactivateCpmany.length >= 1 && reactivateCpmany.map( (obj, i) =>{																
													return <li key={i} className={obj.alert === true ? "text-danger" : ""}>{obj.name}</li>
													})
													}
												</Col>
												<Col sm="1">
												</Col>
											</Row>
										</CardBody>
									</Collapse>
								</Card>
							</Col>
							
						</Row>
					</Col>
				</Row>
			</Container>
		</Fragment>

	)
}
