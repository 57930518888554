import React from 'react'
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button, FormText, } from 'reactstrap';
import Select from 'react-select';

export const FormDepartament = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, areas, specimen, specimens, 
        handleSelectValues,use_consent, methodAction, name, id_area, button
    } = props;

    const {
        name_valid, id_area_valid, specimen_valid,
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="text"
                                //placeholder="Nombre del departamento"
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Área <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="select"
                                //placeholder="Selecciona un área"
                                name="id_area"
                                onChange={handleInputChange}
                                value={id_area}
                                autoComplete="off"
                                invalid={id_area_valid}
                            >
                                <option defaultValue={0}>Selecciona una opción</option>
                                {
                                    areas.map(element => {
                                        return (
                                            <option key={element.id_area} value={element.id_area}>{element.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Tipos de especimen para procesar <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                value={specimens}
                                name="specimens"
                                onChange={(e) => handleSelectValues(e, "specimens")}
                                options={specimen}
                                className=""
                                classNamePrefix="select"
                                placeholder="Selecciona una o varias opciones"
                                noOptionsMessage={() => "Sin opciones"}
                                invalid={specimen_valid}
                               // isSearchable={true}
                                isMulti={true}
                                styles={
                                    {
                                        placeholder: () => ({
                                            color: "#b6bdc4"
                                        })
                                    }
                                }
                            />
                            {/* <FormFeedback>Seleccion almenos una opción</FormFeedback> */}
                            <FormText color='danger' hidden={!specimen_valid}>Debes seleccionar almenos una opción</FormText>
                        </FormGroup>

                    </Col>
                    <Col md="12">
                            <FormGroup>
                                <Label className="col-form-label">¿Cuenta con consentimiento informado? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br />
                                <Label className="switch">
                                <Input type="checkbox" name="use_consent" onChange={handleInputChange} checked={use_consent}  />

                                    {/* <Input type="checkbox" name="enable_reception" onChange={handleInputChange} checked={enable_reception} /> */}
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>


                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form >
    )
}
