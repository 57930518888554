import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormPrices } from './FormPrices';
import { ModalButtonSave, ModalButtonUpdate, ModalButtonDelete } from '../../constant';
import { DeletePrices } from './DeletePrices';

export const ModalPrices = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        handleInputChange, handlePickerValues, validacionesPrice,
        price, from, to, id_examProfile,
        saveCreate, saveUpdate, saveDelete,
        examProfileState,
        validInputDate, validToInputDate,
        isCurve, curves, addCurve, editCurve
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormPrices
                        button={ModalButtonSave}
                        {...{ toggle, loading, handleInputChange, from, validacionesPrice, methodAction: saveCreate, id_examProfile, examProfileState, handlePickerValues, validInputDate, validToInputDate, isCurve, curves, addCurve, editCurve }}
                    />
                    : (method === "update")
                        ? <FormPrices
                            button={ModalButtonUpdate}
                            {...{ toggle, loading, handleInputChange, price, from, to, validacionesPrice, methodAction: saveUpdate, id_examProfile, examProfileState, handlePickerValues, validInputDate, validToInputDate }}
                        />
                        : <DeletePrices {...{ toggle, loading, price, saveDelete }} />
            }
        </Modal>
    )
}
