import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteSubsections } from './DeleteSubsections';  
import { FormSubsections } from './FormSubsections'; 
import { ModalButtonSave, ModalButtonUpdate } from '../../constant'; 

export const ModalSubsections = (props) => {
    
    const {
        sections,
        modal, modalTitle, toggle, method,
        validaciones, 
        id_section, name, abbreviation,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={ toggle } className="bg-primary f-12">
                { modalTitle }
            </ModalHeader>
            {
                method === "create"
                    ? <FormSubsections 
                            button={ModalButtonSave}
                            { ...{toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, sections} }
                        />
                    : (method === "update")
                        ? 
                            <FormSubsections 
                                button={ModalButtonUpdate}
                                { ...{toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, sections,id_section, name, abbreviation} } />
                        : 
                            <DeleteSubsections { ...{toggle, loading, name, abbreviation, saveDelete} }/>
            }
        </Modal>
    )
}
