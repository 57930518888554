import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
// import { MENUITEMS } from './menu';
import { MENUITEMSMICROSITIO } from './menu';
import { ArrowRight, ArrowLeft, Grid } from 'react-feather';
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import configDB from '../../data/customizer/config';
import { DefaultLayout } from '../theme-customizer';

const Sidebar = (props) => {
  const id = window.location.pathname.split('/').pop()
  const defaultLayout = Object.keys(DefaultLayout);//layout   //layoutMicrositio
  const layout = id ? id : defaultLayout
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMSMICROSITIO);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
        document.querySelector(".sidebar-main").className = "sidebar-main hovered"
    } else {
      
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize();
    const currentUrl = window.location.pathname;
   
    window.addEventListener('scroll', handleScroll)
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }

  }, [layout]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }

  const setNavActive = (item) => {
   
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      document.querySelector(".mega-menu-container").classList.remove("d-block")
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }

    if (!item.active) {
    
    }
    item.active = !item.active
  
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
     
    } else {
      setMargin(margin => margin += (-width));
    
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      
    } else {
      setMargin(margin => margin += width);
    
    }
  }

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active")
    document.querySelector(".sidebar-link").classList.remove("active")
  }

  const activeClass = () => {
    document.querySelector(".sidebar-link").classList.add("active")
    document.querySelector(".bg-overlay1").classList.add("active")
  }

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }
  return (
    <Fragment>
      <div className={`bg-overlay1`} onClick={() => { closeOverlay() }} ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
            <img className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="" />
            <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid className="status_toggle middle sidebar-toggle" /></div>
        </div>
        <div className="">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}><img className="img-fluid" src={require("../../assets/images/logo/logo-icon.png")} alt="" /></Link>
        </div>
        {/* <nav className="sidebar-main"> */}
          <div id="sidebar-menu" style={(wrapper.split(' ').includes('horizontal-wrapper') ? { 'marginLeft': margin + 'px', borderTop: "1px solid #7366ff" } : { margin: '0px' })}>
            <ul className="sidebar-links custom-scrollbar" >
              {/* <li className="back-btn">
                <div className="mobile-back text-right"><span>{"Back"}</span><i className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
              </li> */}
             
            </ul>
          </div>
          {/* <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div> */}
        {/* </nav> */}
      </div>
    </Fragment >
  );
}

export default translate(Sidebar);