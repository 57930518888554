import React from 'react';
import { Tooltip } from 'reactstrap';
import { useState } from 'react';

export const ToolTips = (props) => {

    const {
        dataPlacement = "top",
        dataTarget,
        dataText
    } = props;

    const [toolTipRelease, setToolTipRelease] = useState(false);

    const toggleTooltip = () => setToolTipRelease(!toolTipRelease); 
  
    return (
        <Tooltip
            placement={dataPlacement}
            isOpen={ toolTipRelease }
            target={ dataTarget }
            toggle={toggleTooltip}
        >
            { dataText }
        </Tooltip>
    )
    
}
