import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateMachine, ModalUpdateMachine, ModalDeleteMachine, NotFoundDataConn, LostConnection } from '../../constant';
import { usePagination} from '../pagination/usePagination';


export const useMachine = () => {

    const history = useHistory();

    const [machines, setMachines] = useState([]);
    const [machineId, setMachineId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        brand: "",
        model: ""
    });

    const { brand, model } = formValues;

    const [validaciones, setValidaciones] = useState({
        brand_valid: false,
        model_valid: false
    });

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(machines.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listMachine = [];
        machines.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listMachine.push({
                ...data,
                visibleTable: visibleTable
            });
        });

       setMachines(listMachine);
    }, [totalPageCount, currentPage])



    const toggle = () => {
        setModal(!modal);
    }

    const getMachine = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel/List");

        if (respuesta.code === 200) {

            let listMachine = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((data,index) => {
                    let posicion = index + 1;
                    let visibleTable = false;
                    
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listMachine.push({
                        id_machine_model: data.id_machine_model,
                        brand: data.brand,
                        model: data.model,
                        id_external: data.id_external,
                        id_brand: data.id_brand,
                        visibleTable: visibleTable

                    });
                });
                setMachines(listMachine);
                setMachineData(listMachine)
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setMachines([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMachine);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {
        getMachine()
    }, [getMachine]);

    const handleCreate = () => {
        handleUpdateValues({
            brand: "",
            model: ""
        });

        setValidaciones({
            brand_valid: false,
            model_valid: false
        });

        setMachineId(0);
        setMethod("create");
        setModalTitle(ModalCreateMachine);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel");

        if (respuesta.code === 200) {
            getMachine();
            reset();
            createSweet("create", "success", "Exito!", "Equipo creado con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Equipos", respuesta.data.msg);
            setLoading(false);
        }

    }

    // const handleUpdate = (id_machine_model) => {
    //     let machine = machines.find(m => m.id_machine_model === parseInt(id_machine_model));

    //     handleUpdateValues({
    //         brand: machine.brand,
    //         model: machine.model
    //     });

    //     setValidaciones({
    //         brand_valid: false,
    //         model_valid: false
    //     });

    //     setMachineId(id_machine_model);
    //     setMethod("update");
    //     setModalTitle(ModalUpdateMachine);
    //     toggle();
    // }
    const handleUpdate = (id_external) => {
            let machine = machines.find(m => m.id_external === parseInt(id_external));
    
            handleUpdateValues({
                brand: machine.brand,
                model: machine.model,
                id_brand: machine.id_brand
            });
    
            setValidaciones({
                brand_valid: false,
                model_valid: false
            });
    
            setMachineId(id_external);
            setMethod("update");
            setModalTitle(ModalUpdateMachine);
            toggle();
        }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_external: machineId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel/Update");

        if (respuesta.code === 200) {
            getMachine();
            createSweet("update", "success");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const handleDelete = (id_machine_model) => {
        let machine = machines.find(m => m.id_machine_model === parseInt(id_machine_model));

        handleUpdateValues({
            brand: machine.brand,
            model: machine.model
        });

        setMachineId(id_machine_model);
        setMethod("delete");
        setModalTitle(ModalDeleteMachine);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `MachineModel/${machineId}`);

        if (respuesta.code === 200) {
            getMachine();
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.brand !== '' && formValues.brand.length < 2) {
            newValidations = {
                ...newValidations,
                brand_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                brand_valid: false
            };
        }

        if (formValues.model !== '' && formValues.model.length < 2) {
            newValidations = {
                ...newValidations,
                model_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                model_valid: false
            };
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const [machineData, setMachineData] = useState([]);

    const handleSearchMachine = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        machineData.forEach(element => {            

            let cadena = element.brand.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setMachines(newMethod);
    }






    return {
        machines, method, handleInputChange, validaciones,
        brand, model,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchMachine
    }
}
