import React, { useState } from 'react';
import { Form, ModalBody, ModalFooter, Button, Card,CardBody,Input, Label,FormFeedback, FormText,Row,Col,FormGroup} from 'reactstrap';

export const FormTomaMuestraLis = (props) => {

    const {
        
        toggle3,cancellation_reazon, handleInputChange,methodAction,loading,button,

    } = props;


    return (
        <Form className="form theme-form " role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <br/>
                    <br/>
                    <Col md="12">
                        <textarea
                            name="cancellation_reazon"
                            value={cancellation_reazon}
                            onChange={handleInputChange}
                            className='form-control  Timepicker m-l-35'
                            placeholder="Cancelación......"
                        />
                    </Col>
                   </Row>
                  </ModalBody>
                   <ModalBody  style={{textAlign: "-webkit-right"}}>
                   <Button
                   outline
                        color="primary"
                        type="submit"
                        className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                   >
                   {button}
                   </Button> &nbsp;
                    <Button outline color='danger' type="button" onClick={toggle3}>Cancelar</Button>
                  </ModalBody>
        </Form>
    )
}
