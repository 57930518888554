import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ViewListExamProf } from './ViewListExamProf';

export const ModalViewWorkOrder = (props) => {

    const {
        modal, toggle, modalTitle, method, listExam, listProfile
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "list"
                ?
                    <ViewListExamProf
                        { ...{toggle, listExam, listProfile} }
                    />
                :
                ""
            }
        </Modal>
    )
}
