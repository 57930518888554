import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
export const ModalPreViewP = (props) => {

    const {
        //  modal, toggle, workMedicalOrderPDF, typeModal = "view",
        previewWorkOrder, preview, typeModal = "viewP", modal, getViewPreview, toggle

    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggle} className="bg-primary">
                Previsualización
            </ModalHeader>
            {
                typeModal === "viewP" ?
                    <ModalBody>
                        {
                            <div>
                                <Col sm="12">
                                    <embed src={'data:application/pdf;base64,' + preview} title="Previsualizador" type="application/pdf" width="100%" height="600px" />
                                </Col>

                            </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}