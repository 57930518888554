import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import React from 'react';
import { Badge } from 'reactstrap';

export const useCulture = () => {
    const history = useHistory();

    const [cultures, setCultures] = useState([]);
    const [cultureId, setCultureId] = useState(0);

    const [bacteriums, setBacteriums] = useState([]);
    const [bacteriumsSelected, setBacteriumsSelected] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        culture: "",
    });

    const { culture } = formValues;

    
    const [validaciones, setValidaciones] = useState({
        culture_valid: false,
    });

    const getCultures = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Culture/List");

        if (respuesta.code === 200) {
            let listCultures = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }


                    listCultures.push({
                        ...data,
                        no_bacteriums: data.bacteriums !== null ? <Badge color='primary' pill>{data.bacteriums.length}&nbsp;Cultivos</Badge> : <Badge color='primary' pill>0&nbsp;Cultivos</Badge>,
                        visibleTable: visibleTable,

                    });
                });
                setCultures(listCultures);
                setCulturesData(listCultures);

            }
            else {
                setCultures([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCultures);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cultivos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Cultivos", respuesta.data.msg);
        }
    }

    const getBacteriums = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Bacterium/List");

        if (respuesta.code === 200) {
            let list = respuesta.data.map(x => {
                x.value = x.id_mic_bacterium;
                x.label = x.bacterium;
                return x;
            })
            setBacteriums(list);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBacteriums);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Bacteriumas", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Bacteriumas", respuesta.data.msg);
        }
    }

    const onChangeBacterium = (selected) => {
        setBacteriumsSelected(selected)
    }

    useEffect(() => {
        getCultures();
        getBacteriums();
    }, []);


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            culture: "",
        });

        setValidaciones({
            culture_valid: false,
        });

        setCultureId(0);
        setMethod("create");
        setModalTitle("Crear cultivo");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let data = {
            ...formValues,
            bacteriums: bacteriumsSelected
        }

        let mValues = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Culture");

        if (respuesta.code === 200) {
            getCultures();
            reset();
            createSweet("create", "success", "Exito!", "Cultivo creado con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cultivos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cultivos", respuesta.data.msg);
            setLoading(false)
        }
    }

    const handleUpdate = (id_culture) => {
        let culture = cultures.find(s => s.id_mic_culture === parseInt(id_culture));

        handleUpdateValues({
            culture: culture.culture,
        });

        setValidaciones({
            culture_valid: false,
        });

        let bacterias = culture.bacteriums === null ? [] : culture.bacteriums.map(x => {
            x.value = x.id_mic_bacterium;
            x.label = x.bacterium;
            return x;
        })

        setBacteriumsSelected(bacterias);

        setCultureId(id_culture);
        setMethod("update");
        setModalTitle("Actualización de cultivo");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_mic_culture: cultureId,
            ...{ ...formValues },
            bacteriums: bacteriumsSelected
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Culture/Update");

        if (respuesta.code === 200) {
            getCultures();
            createSweet("create", "info", "Exito!", "Bacteria actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cultivos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cultivos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_culture) => {
        let culture = cultures.find(s => s.id_mic_culture === id_culture);

        handleUpdateValues({
            culture: culture.culture,
        });

        setCultureId(id_culture);
        setMethod("delete");
        setModalTitle("Eliminar cultivo");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Culture/${cultureId}`);
        if (respuesta.code === 200) {
            getCultures();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Bacteria eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};
        // culture, gram, id_mic_morphology
        if (formValues.culture === undefined || formValues.culture.length < 1) {
            newValidations = {
                ...newValidations,
                culture_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                culture_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(cultures.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listCultures = [];
        cultures.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listCultures.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setCultures(listCultures);
    }, [totalPageCount, currentPage])


    const [culturesData, setCulturesData] = useState([]);
    const handleSearch = (e) => {

        let busqueda = e.target.value;
        let newCulture = [];

        culturesData.forEach(element => {

            let cadena = element.culture.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newCulture.push(element);
            }
        });
        setCultures(newCulture);
    }

    return {
        cultures, method, validaciones,
        culture, bacteriums, bacteriumsSelected, onChangeBacterium,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch
    }
}
