import React from 'react';
import { Modal,ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeleteCatMachineSanitary } from './DeleteCatMachineSanitary';
import { FormCatMachineSanitary } from './FormCatMachineSanitary';

export const ModalCatMachineSanitarySanitaria = (props) => {
    const{
        method, modalTitle, loading, modal, toggle,
        handleInputChange, saveCreate, saveUpdate, saveDelete,
        validaciones, descripcion,clave,
    }=props;


    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ?<FormCatMachineSanitary
                    button = {ModalButtonSave}
                    {...{toggle, loading, handleInputChange, validaciones, methodAction: saveCreate,}}
                />
                :
                (method === "update")
                ?
                <FormCatMachineSanitary
                    button={ModalButtonUpdate}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, descripcion,clave}}
                />
                :
                <DeleteCatMachineSanitary 
                    {...{toggle, loading, saveDelete ,descripcion}}
                />
            }
        </Modal>
    )
}