import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardBody, Label, Input, Table, Container,CardHeader, Nav,Collapse, NavLink, NavItem, TabContent, TabPane, Button,FormGroup } from 'reactstrap'
import { labelCliente, labelProfit } from '../../../constant';
import Breadcrumb from '../../../layout/breadcrumb';
import { useProfit } from '../../../hooks/profit/useProfit';
import './StyleProfit.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { TableStriped } from '../../../components/tables/TableStriped';

export const IndexProfit = () => {

  const {
    rangeval, handleChange, profitTest, handleChangeSpecial,
    examProfile, handleSelectExamChange, id_exam_profile,
    agreementPercentage,
    number_format,
    //Cards Global Prices
    minPriceSell, lossSellTest, dataTable, perPrice, perSocial, porPice, comercialLine, porPriceS,
    nextPage, previousPage, goToPage, percentage, totalPageCount, currentPage, removeExamProfile,
    loadinTable,
    commercialLine,handleChangeCommercial,id_commercial_line,
    listSection, id_section, handleChangeSection,getSection,getPercentage,
    getPercentageProfit


  } = useProfit();

  const [filterDate, setFilterDate] = useState(true);

  const txtsection = localStorage.getItem('TextAS');

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }



  return (
    <Fragment>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="3" className="tabs-responsive-side">
            <Col sm="12">
              <Card className='shadow'>
                <CardHeader className='p-3 bg-primary'>
                  <h6>
                    Tabuladores
                  </h6>
                </CardHeader>
                  <Nav className="flex-column nav-pills border-tab nav-left">
                    <NavItem>
                        <NavLink
                          href="#javascript"
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => toggleTab("1")}
                          >
                          <i className="icofont icofont-blood-drop"></i>
                          {`Margen Minimo`}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#javascript"
                          className={activeTab === "2" ? "active" : ""}
                          onClick={() => toggleTab("2")}
                          >
                          <i className="icofont icofont-blood"></i>
                          {`Precio de Exámenes/Perfiles`}
                        </NavLink>
                      </NavItem>
                  </Nav>
              </Card>

              <Col sm="12" className={ agreementPercentage.length >= 1 ? "animate__animated animate__backInRight ribbon-wrapper p-0" : "d-none ribbon-wrapper p-0"}>
                    {
                        comercialLine.map((obj, index) => {
                          return (
                            <Col xl="12" className="p-0 left_side_earning " key={index}>
                              <div>
                                <Row>
                                  <Col className='col-md-4 p-1 ' >
                                    <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/ComercialLine.svg")} alt="" />
                                  </Col>
                                  <Col className='col-md-8' style={{textAlignLast: "center"}}>
                                    <br />
                                    <h7 className='text-center text-secundary'>{obj.nameCompany}</h7>
                                    <br />
                                    <h7>Perdida:</h7>
                                     <h7 className="text-center labelPerdida">-$ {number_format(obj.deudas, 2)}</h7>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          )
                        })
                      }
              </Col>
              <br /> <br />
            </Col>
          </Col>

              <Col sm="9">
                <TabContent activeTab={activeTab}>
                  <TabPane className="fade show" tabId="1">
                    <Row className="pt-1">
                      <Col sm="12">
                        <Card className='shadow'>
                          <CardHeader className='p-3 bg-primary'>
                            <h6>
                              Tabuladores por debajo del PMV
                            </h6>
                          </CardHeader> 
                          <br />
                            <Col sm="12">
                              <Row>
                                <Col sm="4">
                                  <Card>
                                    <Row>
                                      <Col className='col-md-5 '>
                                      <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/Rangeval.svg")} alt="" />
                                      </Col>
                                      <Col className='col-md-7 p-l-0 p-r-0 '>
                                        <h3 className='txt-success'>{rangeval} % </h3>
                                        <h8 className="text-center">{labelProfit}</h8>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>

                                <Col sm="4">
                                  <Card>
                                    <Row>
                                      <Col className='col-md-5 '>
                                       <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/MinPrice.svg")} alt="" />
                                      </Col>
                                      <Col className='col-md-7 p-l-0 p-r-0'>
                                      <h3 className=''>{minPriceSell}</h3>
                                      <h8> {"Exámenes/Perfiles"}<br />{"por debajo del P.M.V"}</h8>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>

                                <Col sm="4">
                                  <Card>
                                    <Row>
                                      <Col className='col-md-5'>
                                       <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/LossSell.svg")} alt="" />
                                      </Col>
                                      <Col className='col-md-7 p-l-0 p-r-0'>
                                        <h4 className='txt-danger'>- $ {number_format(lossSellTest, 2)}</h4>
                                        <h8>{"Perdidas registradas P.M.V"}</h8>
                                        <h8 className="labelPerdida text-right"> {number_format(porPice, 2)}%</h8>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Col> 

                            <Col sm="12"> <br />
                            <Col sm="12">
                              <Row> 
                              <Col sm="2">
                                  {
                                    loadinTable === false ? 
                                        <Row>
                                          <label>Margen Minimo</label>
                                          <Input
                                          className="form-control form-control-sm input-air-primary" 
                                          value={rangeval}
                                          onChange={handleChange}
                                        />
                                          </Row>
                                      :""
                                  }   
                              </Col>
                                <Col sm="4">
                                  <label>Nombre o Razón Social</label>
                                  <FormGroup className='m-0'>
                                  <Input
                                    className="form-control form-control-sm input-air-primary" 
                                    type='select' 
                                    placeholder="Selecciona una opción" 
                                    name="id_commercial_line" 
                                    onChange={handleChangeCommercial} 
                                    Value={id_commercial_line} > 
                                    <option value={3}>{"Selecciona una opción"}</option>
                                          {
                                          commercialLine.length >= 1 && commercialLine.map( (obj, index) => {
                                          return <option value={obj.id_commercial_line}>{obj.long_name}</option>
                                        }) 
                                          } 
                                  </Input> 
                                  </FormGroup>
                                </Col>

                                <Col sm="4">
                                  <label>{txtsection}</label>
                                  <FormGroup className='m-0'>
                                  <Input
                                    className="form-control form-control-sm input-air-primary" 
                                    type='select' 
                                    placeholder="Selecciona una opción" 
                                    name="id_section" 
                                    onChange={handleChangeSection} 
                                    Value={id_section} > 
                                    <option value={3}>{"Selecciona una opción"}</option>
                                          {
                                          listSection.length >= 1 && listSection.map( (obj, index) => {
                                          return <option value={obj.id_section}>{obj.name}</option>
                                        }) 
                                          } 
                                      </Input> 
                                  </FormGroup>
                                </Col>

                                <Col sm="2" className='p-2'>
                                  <br />
                                  <Button color='primary'  size='sm' outline type='button' onClick={getPercentage}>Buscar</Button>
                                </Col>
                              </Row>
                            </Col>
                            <br /> <br />
                              <div className="table-responsive">
                              <TableStriped
                                      responsive={true}
                                      notMaped={["visibleTable", "id_commercial_line", "id_branch", "id_exam", "id_profile", "name_branch", "from", "to"]}
                                      methodsActions={false}
                                      cabeceras={["Razón Social", "Tarifario", "Clave del Tarifario", "Código", "Exámen/Perfil", "Precio", "Precio Mínimo de Venta"]}
                                      items={percentage}
                                      nextPage={nextPage}
                                      previousPage={previousPage}
                                      totalPageCount={totalPageCount}
                                      currentPage={currentPage}
                                      goToPage={goToPage}
                                      statusLoading={loadinTable}
                                      isLoadingInformation={true}
                                    />
                              </div>
                            </Col>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>

                  <TabContent activeTab={activeTab}>
                    <TabPane className="fade show" tabId="2">
                      <Row className='pt-1'>
                        <Col sm="12">
                          <Card className='shadow'>
                            <CardHeader className='p-3 bg-primary'>
                              <h6>
                                 Exámenes y Perfiles por debajo del PMV 
                              </h6>
                            </CardHeader> <br />
                            <Col sm="12">
                              <Row>
                                <Col sm="4">
                                    <Card>
                                      <Row>
                                        <Col className='col-md-5 '>
                                        <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/Client.svg")} alt="" />
                                        </Col>
                                        <Col className='col-md-7 p-l-0 p-r-0'>
                                        <h4 className=' text-center'>{perSocial}</h4>
                                        <h8 className="text-left">{labelCliente}</h8>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>

                                  <Col sm="4">
                                    <Card>
                                      <Row>
                                        <Col className='col-md-5 '>
                                        <img className="bg-img " src={require("../../../assets/images/loginHelenLabs/Profit/Chart.svg")} alt="" />
                                        </Col>
                                        <Col className='col-md-7 p-l-0 p-r-0'>
                                        <h4 className='txt-danger text-center'>-$ {number_format(perPrice, 2)}</h4>
                                        <h8 className="txt-center">{"Perdida Total"}</h8><br />
                                        <h7 className="txt-danger text-right">{number_format(porPriceS, 2)}%</h7>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                              </Row>
                            </Col>
                            <Col sm="12">
                            <Row>
                              <Col sm="4">
                                <label>Nombre o Razón Social</label>
                                <FormGroup className='m-0'>
                                  <Input
                                    className="form-control form-control-sm input-air-primary" 
                                    type='select' 
                                    placeholder="Selecciona una opción" 
                                    name="id_commercial_line" 
                                    onChange={handleChangeCommercial} 
                                    Value={id_commercial_line} > 
                                    <option value={3}>{"Selecciona una opción"}</option>
                                          {
                                          commercialLine.length >= 1 && commercialLine.map( (obj, index) => {
                                          return <option value={obj.id_commercial_line}>{obj.long_name}</option>
                                        }) 
                                        } 
                                </Input> 
                                </FormGroup>
                              </Col>

                              <Col sm="4">
                                <label>{txtsection}</label>
                                <FormGroup>
                                <Input
                                  className="form-control form- ontrol-sm input-air-primary" 
                                  type='select' 
                                  placeholder="Selecciona una opción" 
                                  name="id_section" 
                                  onChange={handleChangeSection} 
                                  Value={id_section} > 
                                  <option value={3}>{"Selecciona una opción"}</option>
                                        {
                                        listSection.length >= 1 && listSection.map( (obj, index) => {
                                        return <option value={obj.id_section}>{obj.name}</option>
                                      }) 
                                        } 
                              </Input>
                                </FormGroup>
                              </Col>

                              <Col sm="3" className='p-2'>  <br />
                               <Button color='primary'  size='SM' outline type='button' onClick={getPercentageProfit}>Buscar</Button>
                              </Col>
                            </Row> <br />
                            </Col>
                      
                            <Col sm="12">
                              <div className="table-responsive">
                                    <Table className="table-xs">
                                      <thead className="text-center">
                                        <tr className='border-bottom-info'>
                                          <th >{"Cliente"}</th>
                                          <th >{"Exámen/Perfil"}</th>
                                          <th >{"#Minímo de Pruebas"}</th>
                                          <th >{"#Máximo de Pruebas"}</th>
                                          <th >{"% de Descuento"}</th>
                                          <th >{"Precio en el Convenio"}</th>
                                          <th >{"Precio Mínimo de Venta"}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          agreementPercentage.length >= 0 && agreementPercentage.map((obj, index) => {
                                            return (
                                              <tr key={index} className='border-bottom-info'>
                                                {/* <td ><center>{obj.name_commercial_line}</center></td> */}
                                                <td ><center>{obj.name_company}</center></td>
                                                <td ><center>{obj.name_test}</center></td>
                                                <td ><center>{obj.min_range}</center></td>
                                                <td ><center>{obj.max_range}</center></td>
                                                <td ><center>{obj.percentage}</center></td>
                                                <td><center>{obj.price_agreement}</center></td>
                                                <td ><center>{obj.profit_price}</center></td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                              </Table>
                              </div>
                            </Col>
                            <br />
                            {/* <Col sm="12" className={ agreementPercentage.length >= 1 ? "animate__animated animate__backInRight ribbon-wrapper p-0" : "d-none ribbon-wrapper p-0"}>
                              <Row>
                                <Col sm="4">
                                  <Card>
                                    <Row>
                                      <Col className='col-md-5 p-2 '>
                                      <i className="icofont icofont-chart-bar-graph f-88 text-danger"></i>
                                      </Col>
                                    </Row>

                                  </Card>
                                 
                                </Col>
                                

                              </Row>
                            </Col> */}


                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>

              </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
