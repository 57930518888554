import React from 'react';
import { Modal, Col, Row, Label, ModalBody, Table, Badge, FormGroup, Button } from 'reactstrap';
import 'animate.css';
import Datetime from 'react-datetime';
import moment from 'moment';


export const ModalPendingSend = (props) => {

    const {
        openModalPending, closeModalPending, typePending = "pending", modalPending, historic, loadinPending, dateInit, dateEnd, changeDate, handleHistoricSend
    } = props;

    return (
        <Modal isOpen={modalPending} toggle={closeModalPending} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typePending === "pending" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Muestras Pendientes"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closeModalPending} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadinPending === false ?
                                        <>
                                            <Row className='mt-2'>
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    <p className='f-24 text-primary'><b>Cargando</b></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <Col sm="12" className='text-center'>
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="3" className='p-1' >
                                                        <i className="fa fa-filter txt-secondary f-18"></i><label className='txt-secondary f-14'>Filtro de fechas</label>
                                                    </Col>
                                                    <Col sm="3">
                                                        <FormGroup className="">
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: 'YYYY-MM-DD',
                                                                    name: "workFrom",
                                                                    autoComplete: "false",
                                                                    className: "form-control digits input-air-primary form-control-sm",
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat="YYYY-MM-DD"
                                                                value={dateInit}
                                                                locale="es"
                                                                initialValue={new moment().format('YYYY-MM-DD')}
                                                                onChange={(e) => changeDate(e, "init")}
                                                                closeOnSelect={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <FormGroup >
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: 'YYYY-MM-DD',
                                                                    name: "workFrom",
                                                                    autoComplete: "false",
                                                                    className: "form-control digits input-air-primary form-control-sm ",
                                                                    size: "sm"
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat={"YYYY-MM-DD"}
                                                                locale="es"
                                                                value={dateEnd}
                                                                initialValue={new moment().format('YYYY-MM-DD')}
                                                                onChange={(e) => changeDate(e, "end")}
                                                                closeOnSelect={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <Button type="button" onClick={() => handleHistoricSend(true)} outline color="primary" size='xs' className={("text-center mt-1")}> FILTRAR</Button>

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <div className='table-responsive'>
                                                <Table size='sm' striped bordered hover>
                                                    <thead>
                                                        <tr className='text-center'>
                                                            <th>Nim</th>
                                                            <th>Fecha</th>
                                                            <th>Pendiente</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            historic.length > 0 && historic.map(_obj => {
                                                                return (
                                                                    <tr>
                                                                        <td> <Badge color='primary' className='f-12'>
                                                                            {_obj.nims}
                                                                        </Badge></td>
                                                                        <td>{_obj.fech}</td>
                                                                        <td><i className="icofont icofont-send-mail f-24 text-danger"></i></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }
        </Modal >
    )
}