import React from 'react'
import { useState, useEffect, useRef } from "react";
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import { useForm } from '../forms/useForm';
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from "react-router";
import { LostConnection } from "../../constant";





export const useGraphBar = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});
    const [optionss, setOptionss] = useState({});
    const [seriess, setSeriess] = useState([]);
    //CARD EXAM AND PROFILE
    const [maximo, setMaximo] = useState(0);
    const [nameExam, setNameExam] = useState("");
    const [maximoProfile, setMaximoProfile] = useState(0);
    const [nameProfile, setNameProfile] = useState("");


    const [formValues, handleInputChange, reset, handleUpdateValues, handlePickerValues] = useForm({
        from: "",
        to: "",
        fromm: "",
        too: ""
    });

    const {
        from,
        to,
        fromm,
        too
    } = formValues



    const [validacionesChart, setValidacionesChart] = useState({
        from_valid: false,
    })


    useEffect(() => {
        getPopularExam();
        getPopularProfiles();
    }, [history])


    const getPopularExam = async () => {

        if (from === '' || to === '') {
            return;
        }


        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (from === '') ? moment().format("YYYY-MM-DD") : moment(from).format("YYYY-MM-DD");
        let mto = (to === '') ? moment().format("YYYY-MM-DD") : moment(to).format("YYYY-MM-DD");

        const respuesta = await sendRequest(requestOptions, `Reports/GetExamsPopularExams/${mfrom},${mto}`);

        if (respuesta.code === 200) {

            let info = respuesta.data.series
            let name = respuesta.data.categories
            let max = 0
            let na = ""


            info.forEach(element => {

                let dato = element.data
                max = Math.max(...dato);

                na = name[0]

            });
            setMaximo(max);
            setNameExam(na);


            setOptions(
                {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            borderRadios: 8,
                            horizontal: false,
                        },
                        locales: [{
                            name: 'es',
                        }]
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        colors: ['transparent'],
                        curve: 'smooth',
                        lineCap: 'butt'
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    fill: {
                        colors:[function ({ dataPointIndex }) {
                            let colores = (["#0079C7",
                                "#848484",
                                "#004b7d"
                                
                                ])
            
                            return colores[(dataPointIndex + 3) % colores.length];
                        }],
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            type: 'vertical',
                            shadeIntensity: 0.1,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0.6,
                            stops: [0, 100]
                        }
                    },
                    xaxis: {
                        categories: respuesta.data.categories,
                    },
                }
            );
            setSeries(respuesta.data.series);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getPopularExam);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Grafica", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Grafica", respuesta.data.msg);
        }
    }

    const getPopularProfiles = async () => {

        if (fromm === '' || too === '') {
            return;
        }


        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (formValues.fromm === '') ? moment().format("YYYY-MM-DD") : moment(formValues.fromm).format("YYYY-MM-DD");
        let mto = (formValues.too === '') ? moment().format("YYYY-MM-DD") : moment(formValues.too).format("YYYY-MM-DD");

        const respuesta = await sendRequest(requestOptions, `Reports/GetProfilesPopularProfiles/${mfrom},${mto}`);
        if (respuesta.code === 200) {

            let info = respuesta.data.series
            let name = respuesta.data.categories
            let max = 0;
            let na = ""


            info.forEach(element => {

                let dato = element.data
                max = Math.max(...dato);
                na = name[0]
            });
            setMaximoProfile(max);
            setNameProfile(na);


            setOptionss(
                {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },

                    plotOptions: {
                        bar: {
                            radius: 10,
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        colors: ['transparent'],
                        curve: 'smooth',
                        lineCap: 'butt'
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    fill: {
                        colors:[function ({ dataPointIndex }) {
                            let colores = (["#0079C7",
                                "#848484",
                                "#004b7d"
                                
                                ])
            
                            return colores[(dataPointIndex + 3) % colores.length];
                        }],
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            type: 'vertical',
                            shadeIntensity: 0.1,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0.6,
                            stops: [0, 100]
                        }
                    },
                    
                    
                    xaxis: {
                        categories: respuesta.data.categories,
                    },
                }
            );

            setSeriess(respuesta.data.series);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getPopularProfiles);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Grafica", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Grafica", respuesta.data.msg);
        }
    }


    const validInputDate = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }


    const validToInputDate = (current) => {
        let date = moment(from).subtract(1, "day");

        return current.isAfter(date);
    }

    const validInputDateP = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }

    const validToInputDateP = (current) => {
        
        let date = moment(fromm).subtract(1, "day");

        return current.isAfter(date);
    }    


    const handleCreate = () => {
        handleUpdateValues({
            from: new Date(),
            to: "",
            fromm: new Date(),
            too: ""
        });


        setValidacionesChart({
            from_valid: false,
        });
    }


    const obtenerDatos = () => {
        getPopularExam();
    }

    const obtenerDatosProfile = () => {
        getPopularProfiles();
    }







    const funcionPrueba = (message) => {

        if (message === "actualizar") {
            handleClick();
        }
    }


    const myRefname = useRef(null);

    const handleClick = () => {

        myRefname.current.onClick();

    }



    return {
        from, to, options, handlePickerValues, validInputDate, validToInputDate,
        handleInputChange, handleCreate, obtenerDatos, validacionesChart,
        series, optionss, seriess, funcionPrueba, handleClick, myRefname,
        fromm, too, validInputDateP, validToInputDateP, obtenerDatosProfile,
        maximo, nameExam, maximoProfile, nameProfile
    }
}
