import { sendRequest } from '../../hooks/requests/useRequest'

export async function createComments(_id_WorkOrder,_comment)
{

    let _body = [{
        "id_work_order": _id_WorkOrder,
        "comment_patient": _comment,
    }];


    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'POST',
        body: data

    };

    const response = await sendRequest(requestOptions, "WorkOrder/UpdateComments");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}


export async function modifyStatus(_id_WorkOrder,_comment)
{

    let _body = {
        id_work_order: _id_WorkOrder,
        id_estatus_order: 8,
        commentPatient: _comment, 
    }


    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'PUT',
        body: data

    };

    const response = await sendRequest(requestOptions, "WorkOrder/UpdateStatusWorkOrder");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}


export async function getWhatsApp (id_work_order)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `WorkOrder/SendResultsWhatsapp/${id_work_order}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


export async function DetailInvoices(_id_WorkOrder,_uuid)
{

    let _body = {
        "UUID": _uuid,
        "id_work_order": _id_WorkOrder
    }


    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'POST',
        body: data

    };

    const response = await sendRequest(requestOptions, "Invoice/donwloadinvoice");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}


export async function emailSendInvoice(_id_WorkOrder,_uuid)
{

    let _body = {
        "UUID": _uuid,
        "id_work_order": _id_WorkOrder,
    }

    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'POST',
        body: data

    };

    const response = await sendRequest(requestOptions, "Invoice/SendInvoiceEmail");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}

