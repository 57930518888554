import React from 'react'
import { Modal, Row, Col} from 'reactstrap';
import { FormPrice } from './FormPrice';

export const ModalPrice = (props) => {

    const {
        modal, toggle, balanceTotal, nameAgreement, amount, handleInputChangeAgreement, method,loading,priceTotal,totales, convertir,metodo,
        saveCreate,
    } = props;

    return (                                
        <Modal isOpen={modal} toggle={toggle} transparent={true} keyboard={false} centered={true}  className='modal-lg'  >
            <Row>
                <Col md="12" className='text-right'>
                    <span className='p-1 m-t-5  text-right' style={{ 'cursor':'pointer' }}>
                        <i className="icofont icofont-ui-close" onClick={toggle}></i>
                    </span>
                </Col>
            </Row>
               {
                method === "create"
                 ?
                <FormPrice
                { ...{toggle, balanceTotal,nameAgreement,amount,handleInputChangeAgreement,priceTotal,totales, convertir,metodo, loading,methodAction:saveCreate }}/>
                : (method === "update")
                
               }
         </Modal>   
    )
}
