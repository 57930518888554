import React, { useEffect, useState, useRef } from 'react';
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import './styleDashboard.css';
import { Col, Row, Card, Table, Label } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"
import {Alert} from 'reactstrap';





export const ModalTrazabilidadSampleDashboard = (
    { treeviewRef, close, namePatient, dataSection, dataID
        , idGender, dataInformation, dataSample, dataSendProcess2, dataSendProcess3, dataTrackingRecepetion, itemsTree, refusedGlobal, 
        filterSections,handleFilterSections, ...props }) => {

           const AlertItem = props => {
                const { item } = props;
                const [Open, setOpen] = useState(true);
        
                return (
                    <Alert className="alert-dismissible"
                        color={item.alertcolor}
                        isOpen={true}
                        target={"Alert-" + item.id}
                    >
                        {item.icon}
                        <label style={{fontWeight: 'bold'}}>MOTIVO DE RECHAZO: {item.reason}</label><br />
                        <label style={{fontWeight: 'bold'}}>RESPONSABLE: {item.user}</label><br />
                        <label style={{fontWeight: 'bold'}}>ORIGEN: {item.origen}</label>
                    </Alert>
                );
            }

let dataSendProcess2Copy = dataSendProcess2[0];

let isRefused = '';

let refusedReason = refusedGlobal[0];



itemsTree.forEach(subObject => {
    if (subObject.valueDontSend === 1 && subObject.refused_check_specimen === true) {
        isRefused = true;
        
    }

    
    let _child = subObject.children;
    _child.forEach(child => {
        if (child.valueDontSend === 1 && child.refused_check_specimen === true) {
            isRefused = true;
            
        }
    })
});



    const onRenderItem = (item) => {
       
        
        return (
            <div className="treeview-item-example">
                {
                    item.refused_check_specimen === true ?
                        <div>
                            {
                                item.isLeaf === false && item.valueDontSend == 1?
                                    <span className='text-danger '>
                                        <i className="icofont icofont-doctor-alt f-18 "></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    item.isLeaf === false && item.pending === 1 ?
                                    <span style={{color:'#FF8F00'}}>
                                        <i className="icofont icofont-doctor-alt f-18 "></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    <span className=''>
                                        <i className="icofont icofont-doctor-alt f-18 "></i><label className='f-12' >{item.text}</label> &nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> :
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                            }
                        </div>
                        :
                        <div>
                            {
                                (item.isLeaf === false || item.isLeaf === true) && dataSample.length > 0 && item.check_forwarded === false && item.state === 1 ?
                                
                                    <span className='text-success'>
                                        <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                             (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    item.check_forwarded === true ?
                                    <span className=''>

                                        <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> :
                                            (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                            (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    item.valueDontSend === 1?
                                    <span className='text-danger'>
                                    <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                    {
                                        item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : 
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                </span>
                                :
                                <span style={{color:'#FF8F00'}}>
                                    <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                    {
                                        item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : 
                                        (item.pending === 1 && item.valueDontSend === 2) ?<i className='icofont icofont-ui-clock text-danger f-16' ></i> :
                                        (item.pending === 0 && item.valueDontSend === 1) ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                </span>
                            }
                        </div>
                }
            </div >
        )
    }

    const themes = ['green']

    

    return (

        <Modal {...props} padding={false} closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        padding: "10px 10px",
                    }}
                >
                    <Col sm="12" >
                        <Row>
                            <Col sm="5" xl="4" style={{ textAlign: "center" }}>
                                <b className="txt-secondary f-18">Trazabilidad de la muestra </b>
                            </Col>
                        </Row>
                    </Col>
                    <button className="butto-full-modal" onClick={close}>
                        <label className='f-20'>X</label>
                    </button>
                </div>

                <Col sm="12">
                    <Row>
                        <Col sm="7" xl="6" className='p-l-0' >
                            <Card style={{ backgroundColor: "#4493d11f" }} >
                                <Row>
                                    <Col sm="4">
                                    </Col>
                                    <Col sm="8">
                                        <div className='userTrazabilidad imagen ' >
                                            <img style={{ width: "70%" }} src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                            <br />
                                        </div>
                                    </Col>
                                </Row>

                                <br />
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    <label className='f-w-600 f-14 txt-secondary '>{namePatient}</label>
                                </Col>

                                <Col sm="12" className='p-l-0' >
                                    <div className='table-responsiv'>
                                        <Table size='sm' striped bordered hover>
                                            <thead>
                                                <tr className='text-center'>
                                                    <th>Envio</th>
                                                    <th>Preparación</th>
                                                    <th>Recepción</th>
                                                    <th>Pendiente</th>
                                                    <th>Rechazo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='text-center'>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-times text-danger f-14' ></i> <i className='fa fa-times text-danger f-14' ></i>  </td>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-times text-danger f-14' ></i> </td>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-check txt-success f-14'></i>  </td>
                                                    <td>   <i className='icofont icofont-ui-clock txt-danger f-14'></i> <i className='icofont icofont-ui-clock txt-danger f-14'></i>  <i className='icofont icofont-ui-clock text-danger f-14' ></i> </td>
                                                    <td>   <i className='icofont icofont-ui-block txt-danger f-14'></i> <i className='icofont icofont-ui-block txt-danger f-14'></i>  <i className='icofont icofont-ui-block txt-danger f-14'></i>  </td>
                                                </tr>

                                            </tbody>

                                        </Table>
                                       
                                    </div>
                                </Col>
                                <Col sm="12">
                                    <label className='f-w-600 f-14 txt-secondary '>Estudios a Realizarse:</label>
                                    <br />
                                    <ul class="list-group" style={{display:'-webkit-box'}}>
                                    <li class="list-group-item list-group-item-success">Con muestra</li>
                                        <li class="list-group-item list-group-item-primary">Remitida</li>
                                        <li style={{backgroundColor:'#FF8F00'}} class="list-group-item">Sin muestra</li>
                                        <li class="list-group-item list-group-item-danger">Rechazo</li>
                                    </ul>
                                    
                                    <div className='study-flebotomia mb-3' >
                                        <DeniReactTreeView
                                            style={{width: '100%'}}
                                            className="deni-react-treeview-container5"
                                            ref={treeviewRef}
                                            showCheckbox={false}
                                            showIcon={false}
                                            items={itemsTree}
                                            onRenderItem={onRenderItem}
                                            // theme={themes}
                                            selectRow={false}
                                        />
                                    </div>

                                    {
                                        isRefused === true || isRefused === 'true' ?
                                        <AlertItem
                                        color={'danger'}
                                        item={
                                            {
                                                reason: refusedReason?.reason,
                                                user: refusedReason?.user,
                                                origen: refusedReason?.origen,                                                
                                                alertcolor: 'danger inverse refusedGlobal',
                                                btnclose: 'default',
                                                icon: <i className="icofont icofont-ui-block f-16"></i>,
                                                isOpen: true
                                            }
                                        }
                                    />
                                    :
                                    ''
                                    }
                                  
                                </Col>
                                <br /> <br />
                            </Card>
                        </Col>

                        <Col sm="5" xl="6" className='p-l-0 p-r-0' >

                            <Row>
                                <br />
                                <Col sm="12" style={{ textAlign: "center" }}>
                                    {
                                        dataSample.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0' >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Toma de Muestra</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE TOMA</label>

                                                        </Col>
                                                    </Row>
                                                </Col> <br /> <br />
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Toma de Muestra</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                {
                                                    dataSample.length >= 0 && dataSample.map((_obj) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    _obj.status === true ?
                                                                        <div>
                                                                            <label className='txt-danger f-14'>TOMA CANCELADA</label> <br />
                                                                            <b className='txt-danger' >fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                            <br />
                                                                            <b className='txt-danger'>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                            <br />
                                                                            <b className='txt-danger'>Cancelaciones en toma:</b>  <label>
                                                                                {
                                                                                    _obj.cancellations
                                                                                }
                                                                            </label>
                                                                            <hr className='txt-danger' />
                                                                        </div>
                                                                        :
                                                                        <div className='cuadrado-trazabilidad' >
                                                                            <br />
                                                                            <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                            <br />
                                                                            <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                            <br />
                                                                            <b>Cancelaciones en toma:</b>  <label>
                                                                                {
                                                                                    _obj.cancellations
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                }


                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </Col>
                            </Row>




                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataSendProcess2.length === 0 ?
                                            <div className='disabled disableLabel' >
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Envio a Sucursal</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE ENVIO DE MUESTRA A SUCURSAL</label>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div> :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Envio a Sucursal</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                <div className='cuadrado-trazabilidad' >
                                                    {
                                                        
                                                        dataSendProcess2Copy.id_proces === 2 ?
                                                        dataSendProcess2.length >= 0 && dataSendProcess2.map((_obj) => {
                                                            return (
                                                                <div>
                                                                    <br />
                                                                    <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                    <br />
                                                                    <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                    <br />
                                                                    <b>Cancelaciones en envio de muestras</b>  <label>
                                                                        {
                                                                            _obj.cancellations
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        : dataSendProcess2Copy.id_proces === 3 ?
                                                        dataSendProcess2.length >= 0 && dataSendProcess2.map((_obj) => {
                                                            return (
                                                                <div>
                                                                    <br />
                                                                    <b style={{color:'blue', fontWeight: 'bold'}}>MUESTRA RECIBIDA EN MATRIZ</b> <br />
                                                                    <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                    <br />
                                                                    <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                    <br />
                                                                    <b>Cancelaciones en envio de muestras</b>  <label>
                                                                        {
                                                                            _obj.cancellations
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        }):
                                                        ""
                                                    }

                                                </div>

                                            </div>
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataSendProcess3.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Preparación</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE PREPARACIÓN</label>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Preparación</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                <div className='cuadrado-trazabilidad' >
                                                    {
                                                        dataSendProcess3.length >= 0 && dataSendProcess3.map((_obj) => {
                                                            return (
                                                                <div>
                                                                    <br />
                                                                    <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                    <br />
                                                                    <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                    <br />
                                                                    <b>Cancelaciones en envio de muestras</b>  <label>
                                                                        {
                                                                            _obj.cancellations
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataID.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Analitica(recepción) </b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE ANALITICA </label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div> :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Análitica(recepción)</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </Col>
                                                <Row>
                                                    {
                                                        dataID.length > 0 && dataID.sort() ?
                                                           <Col>
                                                            <div className='text-right'>
                                                            {Object.keys(dataSection).map((key, index) => (
                                                                       <Label type="text" 
                                                                       onClick={() => { handleFilterSections(key) }}
                                                                       className={`f-w-600 f-12 badge badge-pill badge-primary`} 
                                                                       style={{ cursor: "pointer" }} >{key}</Label>
                                                                    ))}
                                                               
                                                               <Label type="text" 
                                                                       onClick={() => { handleFilterSections('TODOS') }}
                                                                       className={`f-w-600 f-12 badge badge-pill badge-primary`} 
                                                                       style={{ cursor: "pointer" }} >TODOS</Label>
                                                            </div>

                                                           <div className='table-wrapper'>
                                                           <Table className='b-r-5 table table-sm'>
                                                                <thead className='b-r-5'>
                                                                    <tr style={{backgroundColor: '#AA99FF'}}>
                                                                        <th className='labeltheadInvoice'>#</th>
                                                                    
                                                                        <th className='labeltheadInvoice'>Examen</th>
                                                                        <th className='labeltheadInvoice'>Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filterSections !== 'TODOS' ?
                                                                    Object.keys(dataSection).filter(key => filterSections.includes(key)).map((key, index) => (
                                                                        <React.Fragment key={`section-${index}`}>
                                                                            <tr style={{ backgroundColor: '#AA99FF' }}>
                                                                                <td className='text-white font-weight-bold' colSpan="4">{key}</td>
                                                                            </tr>
                                                                            {dataSection[key].secciones.map((seccion, seccionIndex) => (
                                                                                <tr key={`section-${index}-item-${seccionIndex}`}>
                                                                                    <td>{seccionIndex + 1}</td>
                                                                                    <td>{seccion.nombre}</td>
                                                                                    <td>{seccion.proces}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ))
                                                                    :
                                                                    Object.keys(dataSection).map((key, index) => (
                                                                        <>
                                                                            <tr style={{backgroundColor: '#AA99FF'}} key={`section-${index}`}>
                                                                                <td className='text-white font-weight-bold' colSpan="4">{key}</td>
                                                                            </tr>
                                                                            {dataSection[key].secciones.map((seccion, seccionIndex) => (
                                                                                <tr key={`section-${index}-item-${seccionIndex}`}>
                                                                                    <td>{seccionIndex + 1}</td>
                                                                                    <td>{seccion.nombre}</td>
                                                                                    <td>{seccion.proces}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </>
                                                                    ))
                                                                    }
                                                                </tbody>
                                                            </Table>



                        </div>
                                                           </Col>
                                                            :
                                                            ""
                                                        
                                                    }

                                                </Row>




                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <br />
            </div>
        </Modal >

    )
}