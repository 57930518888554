import React from 'react';
import { Modal, ModalHeader,Media,ModalFooter,Button,Col} from 'reactstrap';
import { Search, Edit, Globe, BookOpen, FileText, Youtube, MessageCircle, Mail, RotateCcw, DollarSign, Check, Link, Codepen, HelpCircle, Aperture, Settings, CheckSquare } from 'react-feather';






export const ViewSections = (props) => {

    const { 
        modalTitleSection2,modalSection2,typeModalSection ="viewSection",toggleSection2,loading,dataSections
   
} = props;
  
    return (
        <Modal isOpen={modalSection2} toggle={toggleSection2}  backdrop='static' keyboard={false} centered={true} className="sm" >
            <ModalHeader toggle={toggleSection2} className="bg-primary">
            {modalTitleSection2}
            </ModalHeader>
            {
                typeModalSection ==="viewSection" ?
                <div> <br />
                    {
                        dataSections.map((obj,key) => {
                            return(
                                <Col>
                                <div className="faq-body">
                                  <Media className="updates-faq-main">
                                    <div className="updates-faq"><CheckSquare className="font-primary" /></div>
                                    <Media body className="updates-bottom-time">
                                        <p>{obj}</p>
                                    </Media>
                                    </Media>
                                </div>
                                </Col>
                            )
                        })
                    } <br />
                </div>
               
            :""
            }


           
            <ModalFooter>
          
                <Button size="sm" className='btn-air-light' outline color='danger' type='button' onClick={toggleSection2}>
                    Cancelar
                </Button>
            </ModalFooter>
            
        </Modal>
    )
}