import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormPatient } from './FormPatient';
//import { FormPatient } from '../cotizacion/FormPatient';


export const ModalPatient = (props) => {

    const {
        modalPatient, togglePatient, modalTitle, method, loading,
        validationsPatient,

        handleInputChangePatient, handleSelectValuesPatient, handleDatePatient,

        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,
        tax_regime_patient, business_name, 

        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,
        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,

        savePatient, saveUpdate, saveDelete,

        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender, handleChangeTypePatient,
        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE,
        showClassINE, img64File, handleOnChangeINEU, codeP, edad
    } = props;


    return (
        <Modal isOpen={modalPatient} toggle={togglePatient} backdrop='static' keyboard={false} centered={true} className='modal-xl' scrollable={true}>
            <ModalHeader toggle={togglePatient} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ?
                    <FormPatient
                        button="Guardar"
                        {
                        ...{
                            method, togglePatient, loading,
                            validationsPatient,
                            methodAction: savePatient,
                            handleInputChangePatient, handleSelectValuesPatient, handleDatePatient,
                            id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
                            id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
                            curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,
                            listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,
                            handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
                            isInvoiceService, setIsInvoiceService, 
                            viewNewSocialGender, setViewNewSocialGender,
                            scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE
                            }
                        }
                    />
                        : ""
            }
        </Modal>
    )
}