import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col} from 'reactstrap';
import { sendRequest } from '../../hooks/requests/useRequest';
import Chart from 'react-apexcharts'
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import './StyleResult.css'
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { ModalChartResult } from './ModalChartResult';




export const ComponentChartHistories = ({index3,analyte3,idPatient}) => {
  

  
  const [chartOptions, setChartOptions] = useState({});
  const [series, setSeries] = useState([])
  const [show, setShow] = useState(true);
  const [createSweet] = useSweetAlert();


  const history = useHistory();


    useEffect(() => {

      if (idPatient !== 0) {
        chartCaptureresul(idPatient);
      }

  }, [idPatient])


    const chartCaptureresul = async (idP) =>{

        let requestOptions = {
            method: 'GET'
        };
    
         
        const respuesta = await sendRequest(requestOptions, `Exam/charthistoricAnalitValues/${idP}/${analyte3.id_analyte}`);
    
        if (respuesta.code === 200) {
    
            let options ={
                    chart: {
                      height: 350,
                      type: 'line',
                      zoom: {
                        enabled: false
                      },
                      toolbar:{
                        tools:{
                            download: false
                          },
                      },
                      dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                      },
                    },
                    colors: ['#0079C7'],
                    dataLabels: {
                      enabled: true
                    },
                    stroke: {
                      curve: 'smooth'
                    },
                  
                    grid: {
                      row: {
                        colors: ['#46AFE5', 'transparent'], 
                        opacity: 0.5
                      },
                    },
                    xaxis: {
                        categories: respuesta.data.categories,
                    },
                    yaxis: {
                         title: {
                                   text: (analyte3.unit)
                                },
                            }
                        }
                 setChartOptions(options);
                 setSeries(respuesta.data.series)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, chartCaptureresul);
        }
        else if (respuesta.code === 500) {
        }
        else {
        }
    }

    

    let namm = analyte3.name
    let namm2 = namm.toLowerCase()
                                                                    
    function capitalize2(word) {
        return word[0].toUpperCase() + word.slice(1);
      }

      //#region modalChart

    const [titleChart, setTitleChart] = useState("");
    const [typeModalChart, setTypeModalChart] = useState("viewChart");
    const [modalChart, setModalChart] = useState(false);
    const [nameChart, setNameChart] = useState(analyte3.name)



    
    const toggleChart = () =>{
      setModalChart(!modalChart)
    }

    const handleChart = () => {

      setTypeModalChart("viewChart");
      setTitleChart("Grafica");
      toggleChart();   

      }


  
    //#endregion modalChart







        return (
            <>
            <tr key={index3} >
                <td style={{ textAlignLast: "", width: "8px" }}>
                <i className="icofont icofont-chart-line txt-primary f-28 pointer" onClick={handleChart}  ></i>
                </td>
                {/* <td style={{ textAlignLast: "", width: "18px" }}>
                    {
                        analyte3.print_format_transform === 0 ?
                        <div>
                            {
                                analyte3.print_bold_font === true ?
                                <div>
                                    <b><label className='labelExams'>{capitalize2(namm2)}</label></b> <br />
                                    {
                                        analyte3.method === null ? ""
                                        : 
                                        <div>
                                            <label className='labelMethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0  ? ""
                                        :
                                        <label>{analyte3.comment}</label>
                                    }
                                </div>
                                :
                                <div>
                                    <label className='labelExams'>{capitalize2(namm2)}</label> <br />
                                    {
                                        analyte3.method === null ? ""
                                        :
                                        <div>
                                            <label className='labelMethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0 ? ""
                                        :
                                        <label>{analyte3.comment}</label>
                                    }
                                </div>
                            }
                        </div>
                        : 
                        (analyte3.print_format_transform === 1 ) ?
                        <div>
                            {
                                analyte3.print_italics_font === true ?
                                <div>
                                    <em><label className='labelExams'>{analyte3.name}</label></em> <br />
                                    {
                                        analyte3.method === null ? ""
                                        :
                                        <div>
                                            <label className='labelMethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0 ? "" 
                                        :
                                        <label className='labelMethod'></label>
                                    }
                                </div>
                                ://
                                <div>
                                    <label className='labelExams'>{analyte3.name}</label> <br />
                                    {
                                        analyte3.method === null ? ""
                                        :
                                        <div>
                                            <label className='labelMethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0 ? ""
                                        : 
                                        <label >{analyte3.comment}</label>
                                    }
                                </div>
                            }
                        </div>
                        ://
                        <div>
                            {
                                analyte3.print_underline_font === true ? 
                                <div>
                                    <u><label className='labelExams'>{analyte3.name.toLowercase()}</label></u> <br />
                                    {
                                        analyte3.method === null ? ""
                                        :
                                        <div>
                                            <label className='labelmethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0 ? ""
                                        :
                                        <label>{analyte3.comment}</label>
                                    }
                                </div>  
                                :
                                <div>
                                    <label className='labelExams'>{analyte3.name.toLowercase()}</label> <br />
                                    {
                                        analyte3.method === null ? ""
                                        :
                                        <div>
                                            <label className='labelmethod'>METODO:{analyte3.method}</label> <br />
                                        </div>
                                    }
                                    {
                                        analyte3.comment.length === 0 ? ""
                                        :
                                        <label>{analyte3.comment}</label>
                                    }
                                </div>
                            }
                        </div>
                    }
                </td> */}
                <td style={{ textAlign: "-webkit-center" }}>
                    {
                        analyte3.alert === null ? 
                        <label>{analyte3.result}</label>
                        :
                        <b>{analyte3.result}</b>
                    }
                </td>
                <td>
                    {
                        analyte3.alert === null ? 
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;{analyte3.unit}</label>
                        :
                        (analyte3.alert ==="danger") ?
                        <label><i className="fa fa-arrow-up"></i>&nbsp;&nbsp;{analyte3.unit}</label>
                        :
                        <label><i className="fa fa-arrow-down"></i>&nbsp;&nbsp;{analyte3.unit}</label>
                    }
                </td>
                <td style={{ textAlign: "-webkit-center" }}>
                    {
                        (analyte3.text_alerts === null) ? 
                        <div>
                            <label className=''>{analyte3.range}</label>
                        </div>
                        :
                        (
                            (analyte3.range === "" || analyte3.text_alerts >= 1) ?
                            <div>
                                {
                                    analyte3.text_alerts === null || analyte3.text_alerts >=1 || analyte3.text_alerts.map((obj,i) =>{
                                        return <div key={i}>
                                            <u className='labelTxtAlert'>{obj}</u>
                                        </div>
                                    })
                                }
                            </div>
                            :""
                        )
                    }
                </td>
                
            </tr>
            <ModalChartResult
                {
                    ...{titleChart,typeModalChart,modalChart,toggleChart,series,chartOptions,nameChart}
                }
                />
               
            </>
           
        )
}
