import React, { useState, Fragment } from 'react';
import { Button, ButtonGroup, Table, FormGroup, Input, Label, Collapse, Card, Col, CardBody, Row } from 'reactstrap';
import Select from "react-select";
import { MultiSelect } from 'primereact/multiselect';
import '../../../../hooks/componentMultiSelect/MultiSelectDemo.css';


export const DataGridComponent = (props) => {

  const {
    indexs, handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times,
    handleCheckSpecimen, viewButtons, handleViewExamsProfile, id_type_sample, handleSelectValuesWorkOrder,
    listNom, nomSelected, list_nom_det, handleValuePeriod, handleInputChange, changenom, changeobservacionesinput, changediscount_part,
    checkTypeClient, indexmuestra
    //860 useaddagremet

  } = props;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 999
    }),
  }
  ////console.log(indexs,"indes");
  return (
    <div>

      <Table className='table table-borderless'>
        <thead className="border-top-info">
          <tr className='border-bottom-info' >
            {/* <th style={{ width: "10px" }}><b>Código</b> </th> */}
            <th style={{ textAlign: "-webkit-center" }}> <b>Determinación</b> </th>
            {checkTypeClient == 1 ? <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Observaciones</b> </th> : ''}

            <th style={{ width: "10px" }}><b>Abrev</b> </th>
            <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Costo</b> </th>
            {checkTypeClient == 1 ? <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Aplicar descuento %</b> </th> : ''}
            <th style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Metodología</b> </th>

            <th className={viewButtons ? "" : "d-none"} ><b>Acciones</b> </th>
          </tr>
        </thead>
        <tbody className="f-13 text-left border-bottom-info" style={{ paddingBottom: 30 }}>
          {
            indexs.length >= 1 && indexs.map((item, index) => {
              if (item.is_curve) {
                return (
                  <Fragment key={"keyQuoter" + index}>
                    <tr key={index} className='border-bottom-info'>
                      {/* <td
                        style={{ verticalAlign: "middle", width: "10px" }}
                        className={(item.is_urgent === true ? "text-danger" : "")}
                      >
                        {item.code}
                      </td> */}
                      <td
                        style={{ verticalAlign: "middle", width: "100px" }}
                        className={(item.is_urgent === true ? "text-danger" : "")}
                      >
                        {item.name}
                        <br />
                        <select className='form-control form-control-sm input-air-primary' onChange={(e) => changeSelectSamplings(e, item.id_profile)}>
                          <option value="0">Selecciona una opción de toma</option>
                          {
                            item.curves.map(x => {
                              return <option value={x.id_profile_curve_price}>{x.number_sampling} TIEMPOS</option>
                            })
                          }
                        </select>
                      </td>
                      <td style={{ verticalAlign: "middle", width: "10px" }}
                        className={(item.is_urgent === true ? "text-danger" : "")}
                      >
                        {item.id_examProfile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(indexmuestra, item.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""}
                        &nbsp;&nbsp;
                        {item.abbreviation}
                      </td>
                      <td>
                        {item.delivery_time}
                      </td>
                      <td style={{ textAlign: "-webkit-center", width: "10px" }} className={(item.is_urgent === true ? "text-danger" : "")}>
                        {
                          item.curves_aux !== undefined && item.curves_aux.length > 0 && `$${(item.price_discount.toFixed(2))}`
                        }
                      </td>

                      <td style={{ verticalAlign: "middle", width: "1%" }}>
                        <ButtonGroup>
                          <Button
                            className=" btn-air-danger "
                            outline color="danger"
                            size="xs"
                            key={index}
                            onClick={() => deleteExamProfile(indexmuestra, item.id_exam, item.id_profile)}><i className="fa fa-trash-o"></i></Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                    {
                      item.curves_aux !== undefined && item.curves_aux.length > 0 &&
                      item.curves_aux.map((curva, llave) => {
                        return (<tr key={"curva-" + llave} style={{ height: 60 }} className='border-bottom-info'>
                          {/* <td
                            style={{ verticalAlign: "middle", width: "10px" }}
                          >
                            {item.code}-{llave + 1}
                          </td> */}
                          <td
                            style={{ verticalAlign: "middle", }}
                          >
                            Configura el tiempo de la toma
                          </td>
                          <td colSpan={2} style={{ textAlignLast: "center" }}>
                            <Select
                              classNamePrefix="select"
                              options={times}
                              name="--"
                              value={curva.time}
                              placeholder={`Selecciona un tiempo`}
                              onChange={(e) =>
                                changeValueTimeSampling(e, item.id_profile, curva.id_profile_curve_price, curva.number_sampling_aux)
                              }
                              styles={customStyles}
                              theme={
                                (theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#898989'
                                  }
                                })
                              }
                            />
                          </td>
                        </tr>)
                      })
                    }
                  </Fragment>
                )

              } else {
                return (
                  <tr key={index} className='border-bottom-info'>
                    {/* <td
                      style={{ verticalAlign: "middle", width: "8%" }}
                      className={(item.is_urgent === true ? "text-danger" : "")}
                    >{item.code}</td> */}
                    <td
                      style={{ verticalAlign: "middle", width: "18%" }}
                      className={(item.is_urgent === true ? "text-danger" : "")}
                    >{item.name}</td>
                    {checkTypeClient == 1 ?
                      <td>
                        <textarea
                          name="observations_det"
                          className='form-control form-control-sm input-air-primary'
                          rows="2"
                          onChange={(e) => changeobservacionesinput(e, item.id_exam, item.id_profile, id_type_sample)}
                          value={item.observations_det}
                        >
                        </textarea>
                      </td>
                      : ""}
                    <td style={{ verticalAlign: "middle", width: "4%" }}
                      className={(item.is_urgent === true ? "text-danger" : "")}
                    > {item.abbreviation}
                      {
                        item.id_profile !== null ?
                          <>
                            {
                              item.id_profile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(indexmuestra, item.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""
                            }

                          </>
                          :
                          <>
                            {item.abbreviation}
                          </>

                      }
                    </td>

                    <td style={{ textAlign: "center", width: "8%" }} className={(item.is_urgent === true ? "text-danger" : "")}>${item.price_discount.toFixed(2)}</td>

                    {checkTypeClient == 1 ?
                      <td style={{ textAlign: "center", width: "8%" }} className={(item.is_urgent === true ? "text-danger" : "")}>
                        <Input
                          className="form-control"
                          name="discount_par"
                          type="number"
                          value={item.discount_par}
                          min={0} // Reemplaza 'valor_minimo' con el valor mínimo deseado
                          max={100} // Reemplaza 'valor_maximo' con el valor máximo deseado
                          onChange={(e) => changediscount_part(e, item.id_exam, item.id_profile, indexmuestra)}
                        />
                      </td>
                      : ""}
                    <td style={{ textAlign: "-webkit-center", width: "10px" }} className={(item.is_urgent === true ? "text-danger" : "")}>

                      <MultiSelect
                        className="basic-multi-select input-air-primary form-control-sm "
                        value={item.list_nom}
                        options={listNom}
                        onChange={(e) => changenom(e, item.id_exam, item.id_profile, indexmuestra)}
                        optionLabel="name"
                        placeholder={"Selecciona una Norma"}
                        maxSelectedLabels={3}
                        selectedItemsLabel="Secciones Agregadas"
                        filter />
                      {/* <Select
                        isMulti
                        name="list_nom_det"
                        value={list_nom_det}
                        onChange={(e) => handleValuePeriod(e)}
                        options={listNom}
                        className="basic-multi-select form-control form-control-plaintext"
                        classNamePrefix="select"
                        placeholder="Selecciona una o varias opciones"
                        noOptionsMessage={() => "Sin opciones"}
                        isSearchable={true}
                        styles={
                          { placeholder: () => ({color: "#b6bdc4" })}}
                      /> */}
                    </td>
                    <td style={{ verticalAlign: "middle", width: "1%" }}>
                      <ButtonGroup>
                        <Button
                          className={viewButtons ? "btn-air-danger" : "d-none"}

                          // className=" btn-air-danger "
                          outline color="danger"
                          size="xs"
                          key={index}
                          onClick={() => deleteExamProfile(indexmuestra, item.id_exam, item.id_profile, id_type_sample)}><i className="fa fa-trash-o"></i></Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                )

              }
            })

          }

        </tbody>

      </Table>


    </div>
  )


}
