import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss'
import jwt_decode from "jwt-decode";
import '../../assets/css/loginCaptura.css'
import { useLogin } from '../../hooks/tomaMuestra/useLogin';
//import { useSweetAlert } from '../../sweetAlerts/useSweetAlert';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';


const LoginTomaDeMuestra = (props) => {

    const {

        departamento

    } = useLogin();


    const [loading, setLoading] = useState(false);
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    const [loged, setLoged] = useState(false);
    const jwt_token = localStorage.getItem('token');
    const tomaMuestra = localStorage.getItem('tomaMuestra');
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
    const [togglePassword, setTogglePassword] = useState(false)
    const [show, setShow] = useState(true);

    //#region view
    const [cardView, setCardView] = useState(false);

    const handleViewCard = () => {
        setCardView(true);

    }

    const handleCloseCard = () => {
        setCardView(!cardView);

    }


    //#endregion view


    useEffect(() => {
        if (loged === true) {
            window.location.href = `${process.env.PUBLIC_URL}`;
        }
    }, [loged]);

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }



    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_departament: "",
        id_area: 19,
        card_code: "",
        userName: "",
        password: "",

    });

    const [setFocusCard, setSetFocusCard] = useState(true);

    const { id_departament, card_code } = formValues;

    const [showText, setShowText] = useState(false);

    const [shown, setShown] = React.useState(false);

    const switchShown = () => setShown(!shown);

    const iniciarSesion = async (e) => {
        e.preventDefault();
        setShowText(false);
        setLoading(true);

        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Users/LoginSampling");

        if (respuesta.code === 200) {

            localStorage.setItem('depW', formValues.id_departament);


            let use_consent = departamento.find(x => x.id_departament == parseInt(formValues.id_departament))

            if (respuesta.data.msg !== "Éxito") {
                createSweet("", "warning", "Alerta", respuesta.data.msg, "warning");
                setLoading(false);
            }
            else {

                localStorage.setItem("token", respuesta.data.token);
                localStorage.setItem("id", respuesta.data.id_user);
                localStorage.setItem("flebotomistaUser", respuesta.data.username);
                localStorage.setItem("tomaMuestra", 2);
                localStorage.setItem("consentimientoInformado", (use_consent ? use_consent.use_consent : false));
                localStorage.setItem("flebotomisaNombre", respuesta.data.name);
                localStorage.setItem("willson", respuesta.data.nameDepartamento);
                localStorage.setItem("maquila", respuesta.data.maquila);

                setLoged(true);
            }

            handleUpdateValues({
                card_code: "",
                id_area: 19,
                id_departament: "",
                userName: "",
                password: "",
            })
        }
        else {

            handleUpdateValues({
                card_code: "",
                id_area: 19,
                id_departament: "",
                userName: "",
                password: "",
            })

            setShowText(true);
            setLoading(false);

        }
    }


    const [passwordd, setPasswordd] = React.useState('');
    const onChange = ({ currentTarget }) => setPasswordd(currentTarget.value);


    const cardAutoFocus = () => {
        if (setFocusCard) {
            if (document.getElementById("inputFocusCard") !== null) {
                document.getElementById("inputFocusCard").focus();
            }
        }
    }

    useEffect(() => {

        cardAutoFocus()

    }, [setFocusCard]);

    function onKeyPressCard(event) {

        if (event.keyCode === 13) {

            let mValue = event.target.value;

            setLoading(true);

            if (mValue.length > 0) {

                iniciarSesion();
            }
            else {
                createSweett("", "Debes de ingresar una credencial valida", "warning");

                handleUpdateValues({
                    card_code: "",
                    id_area: 19,
                    id_departament: "",
                    userName: "",
                    password: "",
                });

                setSetFocusCard(true);
                setLoading(false);
            }
        }
    }



    useEffect(() => {

        if (jwt_token !== null && tomaMuestra !== null) {
            props.history.replace(`${process.env.PUBLIC_URL}/dashboard/defaultTomaDeMuestra`);
        }

    }, [props.history])

    ////console.log(departamento);


    return (
        <Container className="login-main-container-toma bg-toma" fluid={true} style={{ backgroundColor: "#ffffff", boxSizing: "border-box" }}>
            <Row>
                <Col className="center-container-toma" xs="12" sm="8" md="12" lg="12" xl="12" style={{ padding: "0px" }}>
                    <div className="login-card-toma p-0">
                        <div className="login-main-toma">
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                <img src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LogoHelenLabs2.svg")} alt="LogoDNA" className={cardView ? "imgCardT2" : "imgCartT"} />   <br />   <br />
                            </Col>
                            <Form onSubmit={iniciarSesion} >
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                                        <FormGroup>
                                            <select
                                                className="tamañoinput"
                                                style={{ border: "#FFF 2px solid" }}
                                                name="id_departament"
                                                onChange={handleInputChange}
                                                value={id_departament}
                                            >
                                                <option value={0}>{"Elige tu área"} </option>
                                                {
                                                    departamento.length >= 1 && departamento.map((obj, index) => {
                                                        return <option value={obj.id_departament} key={index} >{obj.name}</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                        <FormGroup className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                            <input id="pnombre" placeholder='tarjeta' className='input-field' name={`card_code`} type="text" style={{ border: "#FFF 2px solid" }} onChange={handleInputChange} required="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${!cardView ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={cardView}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                        <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                            <input
                                                id='inputFocusCard'
                                                autoFocus={setFocusCard}
                                                value={card_code}
                                                onChange={(e) => handleSelectValues(e.target.value, "card_code")}
                                                onKeyDown={onKeyPressCard}
                                                onBlur={cardAutoFocus}
                                            />
                                        </div>
                                        <img className="" src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/CardLogin.gif")} alt="LogoDNA" style={{ width: "15%" }} />  <br />
                                        <p className={`incorrect-loginn ${!showText && "d-none"}`}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo</p>
                                    </Col>
                                </Row>
                                {/* className="imgFlecha" pendiente esta clase  */}
                                <Row className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                        <Button size="xs" type="submit" color="" className={"tamañoButton" + (loading && " disabled progress-bar-animated progress-bar-striped")} >
                                            <img style={{ width: "55%" }} src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LoginIn.svg")} /> </Button> <br />
                                        <p className={`incorrect-loginn ${!showText && "d-none"}`}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo</p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
                <div className={cardView ? "d-none" : "tap-top-B"} style={{ display: "block" }} onClick={() => handleViewCard()} >
                    Inicia Seción Manual
                </div>

                <div className={cardView ? "tap-top-regresar" : "d-none"} style={{ display: "block" }} onClick={() => handleCloseCard()} >
                    Regresar
                </div>
            </Row>
        </Container >
    );

}

export default LoginTomaDeMuestra;


