import React, { Fragment, useState, useEffect } from 'react';
import { LogIn,ArrowLeftCircle,Calendar,Bell,X} from 'react-feather';
import { useNotification } from '../../hooks/notification/useNotification';
import { Col} from 'reactstrap'



import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';


import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from '../../data/config';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();


const Rightbar = (props) => {
  const [moonlight, setMoonlight] = useState(false)
  const [find, setFind] = useState(false)
  const history = useHistory();
  const [notificationDropDown, setNotificationDropDown] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
      setTimeout(() => {
        document.body.className = "dark-only";
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("finance_view") === "False") {
      setFind(true)
    }
  }, []);

  //*CONFIGURATION MOONLIGHT
  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  //*CONFIGURATION FINANCE
  //!VARIABLES GLOBALES
  let _finance = localStorage.getItem('finance');
  let _admin_finance = localStorage.getItem('admin_finance');
  let _configuration_role = localStorage.getItem('configuration_role');

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  //*CONFIGURATION LOG OUT
  const cerrarSesion = () => {
    let url = localStorage.getItem("url_helen");
    localStorage.clear();

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(url + "/logout", requestOptions)
      .then(response => response.text())
      .then(result => {
        setTimeout(() => {
          window.location.href = url;
        }, 500);
      })
      .catch(error => console.log('error', error));
  }

  //*CONFIGURATION LOG OUT HELEN
  const backToHELEN = () => {
    let username = localStorage.getItem("username");
    let base64 = btoa(username);
    let url = localStorage.getItem("url_helen");
    window.location.href = url + "/validacionHtds/" + base64;
  }

  //*CONFIGURATION FINANCE
  const financeMethod = (light) => {
    if (light) {
     setFind(!light)

      localStorage.setItem("financeView", "False");
      localStorage.setItem("finance_view", "True");
      window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`


    } else {
      setFind(!light)
      localStorage.setItem('finance_view', 'False');
      window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`

  }
}


  const [nameUser, setNameUser] = useState("User");
  const [roleUser, setRoleUser] = useState("Normal")

  useEffect(() => {
    localStorage.getItem("nameUser") !== null && setNameUser(localStorage.getItem("nameUser").toUpperCase());
    localStorage.getItem("roleUser") !== null && setRoleUser(localStorage.getItem("roleUser").toUpperCase());
  }, []);

  //*NOTIFICATIONS
  const {navigateUpdate,notificationInvoices} = useNotification();




  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0 text-white">
        <ul className="nav-menus">
          {
            //*NOTIFICATIONS
          }
          {
            _finance === "True" && 
            _configuration_role === "True" && 
            _admin_finance === "True" &&
            <li className="onhover-dropdown text-white">
            <div className="notification-box text-white  " onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-danger">{notificationInvoices.message && notificationInvoices.message.length}</span></div>
            <ul className={`notification-dropdown onhover-show-div    ${notificationDropDown ? "active " : ""}`}>
              <Col sm="12" className="p-l-0 p-r-0 p-t-0 p-b-0"  style={{textAlign : "center"}}> <br />
              <label className='f-w-500 p-2 f-12 '> Autorizar Pacientes a Facturar</label>

              </Col>
              <hr />
             
               <li className="overflow-auto p-l-1 p-r-1" >
                
               </li >
               {
                 notificationInvoices.message && notificationInvoices.message.map((item, index) => {
                   return (
                     <li key={index} className='b-l-primary border-4'>
                       <p><i   onClick={() => navigateUpdate(notificationInvoices.id_workOrder[index],notificationInvoices.id[index])}
                          className={`fa fa-circle mr-3 font-primary ${notificationInvoices.titleAlert[index]}`} >  </i>{item}
                         <span className="pull-right"  > {notificationInvoices.time[index]} </span>
                       </p>
                     </li>
                   )
                 })
               }
               <li>
              </li>
             </ul>
           </li>
          }
           <li>
              {
                _finance === "True" &&    
                <div className="mode text-white" onClick={() => financeMethod(find)} ><i className={`icofont ${find ? 'icofont-restaurant-search ' : 'icofont-search-alt-1'}`}></i>  
                 </div>   
               } 
          </li>         
          <li>
            <div className="mode text-white" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li>
            <div onClick={backToHELEN} className="mode text-white"><ArrowLeftCircle className='text-white' /></div>
          </li>
          <li className='p-t-0 p-b-0' >
            <div className="media profile-media">
            <img className="b-r-10  "
                src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                style={{ width: "100%" }}
                alt="" />
            </div>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div> 
              <div className="media-body p-2 text-white"><span>{nameUser} <i className="middle fa fa-angle-down"></i> <br />  </span>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              {
                _finance === "True" && 
                _configuration_role === "True" && 
                _admin_finance === "True" &&
                <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/visualizador/calender`)}  ><Calendar /><span>{"Configuración"}</span></li>
              }
              <li onClick={cerrarSesion}><LogIn /><span>Cerrar sesión</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
export default translate(Rightbar);