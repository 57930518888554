import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap'
import { ModalButtonCancel } from '../../constant';

export const DeleteInvoiceSettings = (props) => {

    const {
        code, serie, initial_folio, loading, toggle, saveDelete
    } = props;

    return (
        <Form className='theme-form' role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className='h6 txt-danger'>¿Esta seguro de que desea eliminar la serie y folio?</p>
                <b>Código: { code }</b>
                <p>{ 'Serie: '+ serie + ", Folio: " + initial_folio }</p>
                <p>Esta operación no se puede deshacer</p>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' color='primary' type='submit' className={loading ? "disabled progress-bar-animated progress-bar-striped": ""} disabled={loading}>
                    Eliminar
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Form>
    )
}
