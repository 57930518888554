import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormSelectSecctions = (props) => {

    const {
        toggle, loading, sections, selectSecctionsInput, listExamProfile, saveSelectExamProfile, handleSelect, handleSelectAll
    } = props;

    return (
        <>
            <ModalBody>
                <Row>
                    <Col md="4" className='p-l-0 p-r-0'>
                        <Card>
                            <CardBody className="filter-cards-view animate-chk">
                                <h6>Secciones/Areas</h6>
                                {/* checkbox_animated */}
                                <div className="checkbox-animated">
                                    {
                                        sections.length >= 1 && sections.map((obj, index) => {
                                            return (<Label className="d-block" htmlFor={ index } key={index}>
                                                <Input className="checkbox_animated" id={index } value={ obj.id_section } type="checkbox" defaultChecked={ obj.state } onChange={ (e) => selectSecctionsInput(e) } />
                                                {obj.name}
                                            </Label>)
                                        })
                                    }
                                    <Label className="d-block" htmlFor="perfil">
                                        <Input className="checkbox_animated" value="Perfil" type="checkbox" onChange={ (e) => selectSecctionsInput(e) } />
                                        PERFILES
                                    </Label>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card>
                            <CardBody>
                                <h6>Lista de exámenes y perfiles</h6>
                                <div className="table-responsive">
                                {/* <Table className="table-bordered table-xs"> */}
                                    <Table className="table-xs">
                                        <thead>
                                            <tr className='border-bottom-info'>
                                                <th>
                                                    <Label className="" htmlFor="ch_all">
                                                        <Input className="checkbox_animated" id="ch_all" type="checkbox" onChange={ (e) => handleSelectAll(e) } />
                                                    </Label>
                                                </th>
                                                <th>Código</th>
                                                <th>Nombre</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listExamProfile.length >= 1 && listExamProfile.map( (obj, ind) => {
                                                    return (
                                                        <tr key={ind} className='border-bottom-info'>
                                                            <td>
                                                                <Label className="" htmlFor={ ind }>
                                                                    <Input className="checkbox_animated" id={ ind } value={ ind } type="checkbox" onChange={ (e) => handleSelect(e) } checked={ obj.status } />
                                                                </Label>
                                                            </td>
                                                            <td>{ obj.code }</td>
                                                            <td>{ obj.name }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> 
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="primary" type="button" className={ loading ? "disabled progress-bar-animated progress-bar-striped" : "" } onClick={ saveSelectExamProfile }>Importar</Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </>
    )
}
