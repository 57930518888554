import React from 'react'
import {Button, Modal, ModalBody, CardBody, Table,Col, Form, Input, FormFeedback,Row } from 'reactstrap';

export const ModalCashView = (props) => {

    const {modalPreview,toggleModalPreview,typeAmoun,formatter,wilso,number_format,formCash,sumPayMethods,discountT,cancell} = props;

    //*VARIABLES GLOBALES
    let _commercialLine = localStorage.getItem('CommercialLineShort');
    let _nameUser= localStorage.getItem('nameUser');
    let _branchName= localStorage.getItem('branchName');

    //!FECH NEW
    const now = new Date();
    const fecha = now.toLocaleDateString(); 
    const hora = now.toLocaleTimeString(); 

    let _new = (fecha + " " + hora)


    return (
        <Modal isOpen={modalPreview} toggle={toggleModalPreview} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    {
                        <>
                        <Col sm="12">
                        <Row>
                            <Col sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                            <label className='f-14 f-w-600'>{_commercialLine} </label> <br />
                            <label style={{fontSize:"11px"}} className=' f-w-600'>{_branchName} </label> <br />
                            <div className='preview-cash-div' >
                           </div> <br /> 
                            </Col>
                       
                        </Row>
                        <Row>
                            <Col sm="12">
                            <Col sm="12" style={{textAlign:"center"}}>
                            <label style={{fontSize :"10px"}} >DETALLE</label>

                            </Col>
                            <br />
                            <div className="table-responsive">
                                        <Table className='table-sm table-stripped' >
                                            <thead >
                                                <tr style={{backgroundColor :"#46AFE5"}}>
                                                <td colSpan={2} style={{color : "#fff", textAlign:"center", fontSize:"14px"}} >Detalle</td>
                                                </tr>
                                                <tr>
                                                    <th style={{color : "#000", textAlign:"center"}} > <label className='f-w-600 f-12'>Fecha de inicio</label></th>
                                                    <th style={{color : "#000", textAlign:"center"}} ><label className='f-w-600 f-12'>Fecha de corte</label></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               <tr className='text-center f-w-500' style={{fontSize: "11px"}}>
                                                <td>{_new ?? "---------"}</td>
                                                <td>{_new ?? "---------"}</td>           
                                              </tr> 
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br />
                                    <br />
                            <div className="table-responsive">
                                        <Table className='table-sm table-stripped' >
                                            <thead >
                                                <tr style={{backgroundColor :"#46AFE5"}}>
                                                <td colSpan={2} style={{color : "#fff", textAlign:"center" , fontSize:"14px"}} >Detalle</td>
                                                </tr>
                                                <tr>
                                                    <th style={{color : "#000", textAlign:"center"}} ><label className='f-w-600 f-12'>MÉTODO DE PAGO</label></th>
                                                    <th style={{color : "#000", textAlign:"center"}} ><label className='f-w-600 f-12'>TOTAL</label></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    typeAmoun?.length > 0 && typeAmoun?.sort() && typeAmoun?.map((_find,index) => {
                                                        return <tr key={_find} className='text-center f-w-500' style={{fontSize: "11px"}}>
                                                           
                                                            <td>{_find ?? "----"}</td>
                                                            <td><label className='f-12 f-w-600 '>{formatter.format(wilso[_find].Amount !== undefined && wilso[_find].Amount)}</label></td>
                                                        </tr>
                                                    })
                                                } 
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br /> <br />
                                    <div className="table-responsive">
                                        <Table className='table-sm table-stripped' >
                                            <thead >
                                                <tr style={{backgroundColor :"#46AFE5"}}>
                                                <td colSpan={2} style={{color : "#fff", textAlign:"center", fontSize:"14px"}} >Detalle de movimientos</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className='f-12'> <b className='f-12'>Descuentos</b></td>
                                                <td className="text-center"><b className='f-12'>$ {number_format(discountT ?? 0, 2)}</b></td>
                                            </tr>
                                            <tr>
                                                <td className='f-12'> <b className='f-12'>Cancelaciones</b></td>
                                                <td className="text-center "><b className='f-12'>$ {number_format(cancell ?? 0, 2)}</b></td>
                                            </tr>
                                            <tr>
                                                <td className='f-12'> <b className='f-12'>Total</b></td>
                                                <td className="text-center "><b className='f-12'>$ {number_format(sumPayMethods ?? 0, 2)}</b></td>
                                            </tr>
                                            <tr>
                                                <td className='f-12'> <b className='f-12'>Efectivo registrado</b></td>
                                                <td className="text-center "><b className='f-12'>$ {number_format(formCash.capture_amount ?? 0, 2)}</b></td>
                                            </tr>
                                            </tbody>
                                            
                                        </Table>
                                        <br />
                                        <b htmlFor="">{formCash.comments ?? "--------"}</b>
                                    </div> <br /> <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}}>
                            <div className='preview-cash-div' >
                            </div> 
                            <label style={{fontSize:"11px"}} className=' f-w-600'>{_nameUser} </label> <br />
                            </Col>
                        </Row>
                        </Col>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                        <Row>
                            <Col sm="8">
                            </Col>
                            <Col sm="3">
                            <Button color="primary text-center" type="button" onClick={toggleModalPreview}>{"Aceptar"}</Button>
                            </Col>
                        </Row>
                        </Col>
                        </>
                    }
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
