import React from 'react';
import Select from 'react-select';
import { Card, CardBody, Col, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';

export const TabRelatedInvoice = (props) => {

    const {
        handleChangeInvoice, handleChangePaymentAmount,
        filterInvoices, iconCoin,
        listNims, listInvoices,date_payment,handleInputChange,disabledSaveButton
    } = props;

    return (
        <Row>
            <Col sm="12" md="12" lg="12">
                <Card>
                    <CardBody className='p-2'>
                        <div className='theadInvoice'><br /></div> 
                        {
                          disabledSaveButton === true ?
                          <div>
                              <Row className='mt-2'>
                            <Col sm="12" md="12" lg="12" className='text-center'>
                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'130px' }} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="3" md="3" lg="3" className='text-center'></Col>
                            <Col sm="3" md="3" lg="3" style={{textAlign: 'center'}} >
                                <p className='f-24 text-primary'><b>Generando</b></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p> 
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                        </Row>

                          </div>
                          :
                          <div>
                             <Row>
                        <Col sm="12" className='' > <br />
                            <Row>
                                <Col sm="2" md="2" lg="2"> <br />
                                    <label className='txt-secondary'>Fecha de Pago:</label>
                                </Col>
                                <Col sm="2" md="2" lg="2" className='p-l-0' >
                                    <FormGroup>
                                    <Input type='date'  value={date_payment} onChange={handleInputChange} name='date_payment' className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                    </FormGroup>
                                </Col>
                                <Col sm="1" md="1" lg="1">
                                </Col>
                                <Col sm="2" md="2" lg="2" className='p-l-0 p-r-0' > <br />
                                    <label className='txt-secondary'>Selecciona el documento:</label>
                                </Col>
                                <Col sm="4" md="4" lg="4">
                                <FormGroup>
                                    <Select
                                        className="input-air-primary input-xs"
                                        classNamePrefix="select"
                                        placeholder={PlaceHolderOption}      
                                        options={listNims}  
                                        value={filterInvoices}        
                                        onChange={(e) => handleChangeInvoice(e)}                        
                                    />
                                </FormGroup>
                            </Col>
                            </Row>   
                        </Col>
                        </Row>

                      
                        <Row>
                            <Col sm="12" md="12" lg="12"> <br />
                                <div className='table-responsive'> <br />
                                    <Table cclassName='table-xs table-stripped'>
                                        <thead className="theadInvoice">
                                           
                                            <tr>
                                                <th className='text-center labeltheadInvoice'>ID documento</th>
                                                <th className='text-center labeltheadInvoice'>Total</th>
                                                <th className='text-center labeltheadInvoice'>Moneda</th>
                                                <th className='text-center labeltheadInvoice'>Parcialidad</th>
                                                <th className='text-center labeltheadInvoice'>Saldo anterior</th>
                                                <th className='text-center labeltheadInvoice'>Monto a depositar</th>
                                                <th className='text-center labeltheadInvoice '>Saldo pendiente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listInvoices.length > 0 && listInvoices.map((_invoice, _index) => {
                                                    return (
                                                        <tr key={`td-invoice-id-${_invoice.uuid}`}>
                                                            <td className='text-center'>
                                                                <span>{_invoice.uuid}</span>
                                                                <br />
                                                                <span><b>{_invoice.folio}</b></span>
                                                            </td>
                                                            {/* <td className='text-center'>{_invoice.name_type_method}</td> */}
                                                            <td className='text-center'>{_invoice.total}</td>
                                                            <td className='text-center'>{_invoice.code_coin}</td>
                                                            <td className='text-center'>{_invoice.parciality_number}</td>
                                                            <td className='text-center'>{_invoice.previous_amount}</td>
                                                            <td className='text-center'>
                                                                <FormGroup className='p-1' style={{ width: '150px' }}>
                                                                    <InputGroup size='sm'>
                                                                        <InputGroupAddon addonType='prepend'>
                                                                            <InputGroupText><i className={iconCoin}></i></InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input type='number' step="any" autoComplete='off' value={_invoice.payment_amount}  
                                                                            className={_invoice.alert_payment_amount ? 'form-control form-control-sm input-air-primary text-danger' : 'form-control form-control-sm input-air-primary'} min={0} max={_invoice.static_payment_amount} onChange={(e) => handleChangePaymentAmount(e, _invoice.id_invoice)}
                                                                        />
                                                                    </InputGroup>
                                                                    <FormText color='danger' hidden={!_invoice.alert_payment_amount}>Ingresa una cantidad valida desde 1 hasta { _invoice.static_payment_amount }</FormText>
                                                                </FormGroup>
                                                            </td>
                                                            <td className='text-center'><b>{_invoice.outside_balance}</b></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>

                          </div>

                        }
                       
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
