import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { ModalButtonCancel, ModalButtonDelete } from '../../constant';

export const UpdateStatusAgreemet = (props) => {

    const {
        toggle, loading, name_company, contract_number, validity, saveUpdateStatus, button
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={ saveUpdateStatus }>
            <ModalBody>
                <p className="h6 txt-danger">Deseas cambiar el estatus del convenio</p>
                <b>{ `${name_company}` }</b>
                <p>{ `Con número de contrato: ${ contract_number }, con vigencia: ${ validity }` }</p>
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>{ ModalButtonCancel }</Button>
            </ModalFooter>
        </Form>
    )
}
