import React from 'react'
import { Button, Card, CardBody, Tooltip, Input } from 'reactstrap';
import { useBrands } from '../../../hooks/analytes/useBrands';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalBrand } from '../../../components/brands/ModalBrand';

export const IndexBrands = () => {

    const {
        brands, method, validaciones,
        name,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearch

    } = useBrands();

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de marcas
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        {/* <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button> */}
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={false}
                            cabeceras={["Nombre"]}
                            items={brands}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />

                    </div>
                </CardBody>
            </Card>
            <ModalBrand
                {
                ...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name,
                    handleInputChange, loading,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </>
    )
}
