import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Label, Button } from 'reactstrap';
export const ModalAccept = (props) => {

    const {
        toggle6, flebotomiaRefused = "FlebotomiaRefused", modal6, pendingSample, name2, name5, patien, nim
    } = props;

    ////console.log(pendingSample);


    return (
        <Modal isOpen={modal6} toggle={toggle6} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            <div className="" style={{ textAlignLast: "center", backgroundColor: "#0079C7" }}>
                <Col sm="12">
                    <Row>

                        <Col sm="10">
                            <Label className="labelModal p-1"> &nbsp;{"Estudios Rechazados"}</Label>
                        </Col>
                        <Col sm="2" className='p-2' >
                            <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "30%" }} alt="" onClick={toggle6} />

                        </Col>


                    </Row>
                </Col>
            </div>
            {
                flebotomiaRefused === "FlebotomiaRefused" ?
                    <Col sm="12" style={{ border: "#0079C7 3px solid" }}> <br />

                        <Col sm="12" className='text-center'>
                            <div className="user-image">
                                <div className="avatar">
                                    {
                                        // <label >gatos</label>
                                    }
                                </div>
                                <div className="icon-wrapper" data-intro="Change Profile image here">
                                </div>
                            </div>
                            <div className="info">
                                <Row>
                                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                        <Row >
                                            <Col md="12">
                                                <div className="user-designation">
                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Paciente"}</a></div>
                                                    <div className="desc  detailPatienModal f-14">
                                                        {
                                                            patien
                                                        }
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                        <div className="user-designation">
                                            <div className="title"><b target="_blank" >{"Responsable"}</b></div>
                                            <div className="desc mt-2 detailPatienModal">{name2}</div>
                                        </div>
                                    </Col>
                                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                        <Row>
                                            <Col md="6">
                                                <div className="user-designation">
                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Nim"}</a></div>
                                                    <div className="desc detailPatienModal f-14">
                                                        {nim}
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        </Col>












                        <label className="f-w-600 f-12 badge badge-light-danger">Estudios que no cumplen el proceso de toma:</label>
                        <br /> <br />
                        <div>
                            {
                                pendingSample.map((o, i) => {

                                    if (o.entra === 0) {
                                        return false;

                                    }
                                    ////console.log(o);
                                    return (
                                        <div key={`list-${i}`} >
                                            {
                                                o.list_refusedTracking.map((obj, key) => {
                                                    return (
                                                        <div key={`list-${key}`} className="txt-secondary" >
                                                            {
                                                                obj.refused_check_specimen === true ?
                                                                    <div>
                                                                        <i className="icofont icofont-laboratory f-16 txt-danger"></i><label className='txt-danger f-14'>{obj.text}</label>


                                                                    </div>
                                                                    :
                                                                    ""

                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <br /> <br /> <br />

                    </Col>
                    : ""
            }
        </Modal>
    )
}