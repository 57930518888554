import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormPayMethods } from './FormPayMethods';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeletePayMethods } from './DeletePayMethods';

export const ModalPayMethods = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        name, abbreviation, handleInputChange, validaciones,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormPayMethods button={ModalButtonSave} {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }} />
                    : (method === "update")
                        ? <FormPayMethods button={ModalButtonUpdate} {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, abbreviation }} />
                        : <DeletePayMethods {...{ toggle, loading, name, abbreviation, saveDelete }} />
            }
        </Modal>
    )
}
