import React from 'react'
import { Button, Card, CardBody, Col, Input } from 'reactstrap';
import { ModalSampling } from '../../components/samplings/ModalSampling';
import { TableStriped } from '../../components/tables/TableStriped';
import { useSampling } from '../../hooks/samplings/useSampling';

export const IndexSampling = ({ activeTab }) => {

    const {
        samplings, samplingItems, validaciones,
        name, code, items,
        method, handleInputChange, handleSelectValues,
        toggle, modal, modalTitle, loading,
        saveCreate, handleCreate,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchSampling2

    } = useSampling();

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Tomas de muestra
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    {/* <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSampling2} />
                                </div>
                    </div> */}



                    <div className="table-responsive">
                        {
                            samplings.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                    <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se a registadro ningún paquete de toma</p>
                                </div>
                                :
                                <TableStriped
                                    notMaped={["visibleTable", "id_external", "id_sampling_items_category"]}
                                    methodsActions={false}
                                    cabeceras={["Nombre", "Código", "Insumos", "Costo total"]}
                                    items={samplings}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />

                        }

                    </div>
                </CardBody>
            </Card>
            <ModalSampling
                {...{
                    samplingItems,
                    modal, toggle, modalTitle, method, loading,
                    handleInputChange, handleSelectValues, validaciones,
                    name, code, items,
                    saveCreate,
                    // saveCreate, saveDelete, saveUpdate,
                }}
            />
        </>
    )
}
