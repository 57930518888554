import React, { useState } from 'react'
import { Button, Tooltip } from 'reactstrap';

export const ButtonValidate = ({ ready, id_profile, id_work_order, id_work_order_profile, validateProfile }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <div>
            {
                ready ?
                    <Button tabIndex="-1" onClick={() => validateProfile(id_profile, id_work_order, id_work_order_profile)} color='success'>Validar todo el perfil</Button>
                    :
                    <>
                        <Button tabIndex="-1" id='tooltipValidate' className='d-none' color='success'>Validar todo el perfil</Button>
                        <Tooltip
                            isOpen={tooltipOpen}
                            target="tooltipValidate"
                            toggle={toggleTooltip}
                        >
                            Guarde todos los resultados de las pruebas del perfil que estan marcados en rojo
                        </Tooltip>
                    </>
            }
        </div>
    )
}
