import React from 'react'
import { Button, Col, ModalBody, ModalFooter, Row, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';

// components-cards- ViewListprofile

export const ViewListprofile = (props) => {

    const {
        toggle2, infoData
    } = props;

    return (
        <>
            <ModalBody>
                <Row>
                    <Col xl="12" className="box-col-6  pr-0 file-spacing  p-0 m-0 t-0 ">
                        <div className="file-sidebar  p-0 m-0  t-0  ">
                            <ul>
                                <li>
                                    <div className=" p-2">
                                        <Row>
                                            <Col className="col-md-12 p-0  text-center text-secondary ">
                                                <h5  className='txt-primary'>{" Exámenes que conforman el Convenio"}</h5>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Col className='col-md-12 p-0'>
                                            <Col sm="12">

                                                <div className="pricingtable-header">
                                                    {
                                                        (infoData.listAgreementTests !== undefined && infoData.listAgreementTests.length >= 1) && infoData.listAgreementTests.map((obj, index) => {
                                                            return (
                                                                <ListGroup key={index}>
                                                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">{obj.name}<span className="badge badge-info counter digits">$ {(obj.price).toFixed(2)}</span></ListGroupItem>
                                                                </ListGroup>

                                                            )
                                                        })
                                                    }
                                                    <br /> <br />
                                                </div>

                                            </Col>
                                        </Col>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle2}>{"Cerrar"}</Button>
            </ModalFooter>

        </>

    )
}
