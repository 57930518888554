import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const FormInfoAnalyte = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, code, is_interfaced, button, is_analyte, method
    } = props;
    
    
    const {
        name_valid,
        code_valid,
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                {
                    is_analyte === true ?
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label className="col-form-label">Nombre del analito <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                    	className="form-control form-control-sm input-air-primary"
                                        type="text"
                                       // placeholder="Nombre del analito"
                                        name="name"
                                        onChange={handleInputChange}
                                        value={name}
                                        autoComplete="nope"
                                        invalid={name_valid}
                                    />
                                    <FormFeedback>Minimo 3 caracteres</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="8">
                                <FormGroup>
                                    <Label className="col-form-label">Clave del analito <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                    	className="form-control form-control-sm input-air-primary"
                                        type="text"
                                        //placeholder="Código del analito"
                                        name="code"
                                        onChange={handleInputChange}
                                        value={code}
                                        autoComplete="off"
                                        invalid={code_valid}
                                    />
                                    <FormFeedback>Minimo 2 caracteres</FormFeedback>
                                </FormGroup>
                            </Col>
                            {
                                method == 'create'
                                    ?
                                        <Col md="4">
                                            <FormGroup>
                                                <Label className="col-form-label">¿Esta interfazado? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="is_interfaced" onChange={handleInputChange} checked={is_interfaced} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    :
                                        ""
                            }   
                        </Row>
                        :
                        <Row>
                            <Col md="8">
                                <FormGroup>
                                    <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                    	className="form-control form-control-sm input-air-primary"
                                        type="text"
                                       // placeholder="Nombre"
                                        name="name"
                                        onChange={handleInputChange}
                                        value={name}
                                        autoComplete="off"
                                        invalid={name_valid}
                                    />
                                    <FormFeedback>Minimo 3 caracteres</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Clave <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                    	className="form-control form-control-sm input-air-primary"
                                        type="text"
                                        name="code"
                                        onChange={handleInputChange}
                                        value={code}
                                        autoComplete="off"
                                        invalid={code_valid}
                                    />
                                    <FormFeedback>Minimo 2 caracteres</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                }

            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color="danger" type="button" onClick={toggle}>
                    {"Cancelar"}
                </Button>
            </ModalFooter>
        </Form>
    );
};
