// dashbaord
import Default from '../components/dashboard/defaultMicrositio'
//defaultMicrositio

//import { IndexMicrositio } from "../components/micrositioIndex/IndexMicrositio"

//import Default from '../components/micrositio/defaultMicrositio'
//import Default from '../components/micrositio/index'




export const routesMicrositio = [

            //{ path: `${process.env.PUBLIC_URL}/micrositio/defaultMicrositio/`, Component: Default },



          { path: `${process.env.PUBLIC_URL}/dashboard/defaultMicrositio/`, Component: Default },
        // { path: `${process.env.PUBLIC_URL}/micrositioIndex/`, Component: IndexMicrositio },






]