import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, ModalBody, ModalFooter, Row } from 'reactstrap'
import Select from 'react-select';

export const FormSpecialDiscount = (props) => {

    const {
        readOnly, button, methodAction,
        toggleSpecialDiscount, loading, 
        handleInputChange, handleChangeTypeDiscount, percentage, percentage_global, 
        id_exam_include, id_profile_include,
        listExams, listProfiles, listSelectedExams, listSelectedProfiles, handleInputPercentageIndividual,
        handleExamsIncludes, handleProfileIncludes,
        validationsSpecialDiscount, 
        handleRemoveTest
    } = props;

    const {
        global_special_discount_valid
    } = validationsSpecialDiscount;

    let total_exams = 1;
    let total_profiles = 1; 

    return (
        <>
            <ModalBody>
                <Form role={'form'}>
                    <Row>
                        <FormGroup className='mb-3 col-4'>
                            <Label>¿Descuento individual por examen/perfil?</Label>
                            <br />
                            <Label className='switch'>
                                <Input readOnly={readOnly} type="checkbox" name='percentage_global' checked={percentage_global} value={percentage_global} onChange={(e) => handleChangeTypeDiscount(e)} />
                                <span className='slider round'></span>
                            </Label>
                        </FormGroup>
                        {
                            percentage_global
                            ?
                                <FormGroup className='mb-3 col-8'>
                                    <p>Cada examen o prueba seleccionado debe tener su descuento, <b>debes de seleccionar mínimo un examen o prueba</b></p>
                                </FormGroup>
                            :
                                <>
                                    <FormGroup className='mb-3 col-4'>
                                        <p>Este porcentaje se aplicara para todos los examenes y pruebas seleccionados</p>                                        
                                    </FormGroup>
                                    <FormGroup className='mb-3 col-4'>
                                        <Label>Porcentaje de descuento</Label>
                                        <InputGroup className='mt-1'>
                                            <Input
                                                min={1}
                                                max={20}
                                                name="percentage"
                                                value={percentage}
                                                onChange={handleInputChange}
                                                readOnly={readOnly}     
                                                invalid={global_special_discount_valid}                                           
                                            />
                                            <InputGroupAddon addonType='append'>
                                                <InputGroupText>{"%"}</InputGroupText>
                                            </InputGroupAddon>
                                            <FormFeedback>Ingresa un descuento valido de 1 - 100</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                </>
                        }                        
                    </Row>
                    <Row>
                        <Col sm="6" md="6" lg="6" xl="6">
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <FormGroup>
                                        <Label>Selección de exámenes</Label>
                                        <Select
                                            classNamePrefix={'select'}
                                            name="id_exam_include"
                                            options={listExams}
                                            value={id_exam_include}
                                            placeholder="Selección de exámenes"
                                            isClearable={false}
                                            onChange={(e) => handleExamsIncludes(e)}
                                            isDisabled={readOnly}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12">
                                    {
                                        listSelectedExams.length > 0
                                        ?
                                            <h6>Exámenes incluidos dentro de la promoción</h6>
                                        :   
                                            <h6>Todos los exámenes estan incluidos</h6>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12" className='p-2'>
                                    {                                       
                                        listSelectedExams.length > 0 && listSelectedExams.map((exam, key) => {
                                            return <FormGroup key={`listExams-${key}`} className={exam.is_deleted ? 'd-none' : 'row'}>
                                                <Col sm="7">
                                                    <Label className='f-12'>{exam.is_deleted ? 0 : (total_exams++)}.- { exam.name}</Label>
                                                </Col>
                                                <Col sm="5">
                                                    <InputGroup size='sm'>
                                                        {
                                                            percentage_global &&
                                                            <>
                                                                <Input 
                                                                    className='form-control'
                                                                    type='number'
                                                                    min={1}
                                                                    max={100}
                                                                    defaultValue={exam.percentage}  
                                                                    onChange={(e) => handleInputPercentageIndividual(exam.id_exam, e.target.value, "Exam")}
                                                                    invalid={exam.valid_percentage}                                                                  
                                                                />                                                                
                                                            </>
                                                        }
                                                        <InputGroupAddon addonType='append'> 
                                                            {
                                                                percentage_global && 
                                                                <InputGroupText>{"%"}</InputGroupText>
                                                            }
                                                            {
                                                                !readOnly &&
                                                                <InputGroupText
                                                                    onClick={() => handleRemoveTest(exam.id_exam, "Exam")}
                                                                    style={{cursor:'pointer'}}
                                                                    className="bg-danger"
                                                                >                        
                                                                <span className='fa fa-trash'></span>                                                
                                                                </InputGroupText>
                                                            }
                                                        </InputGroupAddon>                                                        
                                                        {
                                                            percentage_global &&
                                                            <FormFeedback>Ingresa un porcentaje de 1 - 100</FormFeedback>
                                                        }
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                       })
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6">
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <FormGroup>
                                        <Label>Selección de perfiles</Label>
                                        <Select
                                             classNamePrefix={'select'}
                                             name="id_profile_include"
                                             options={listProfiles}
                                             value={id_profile_include}
                                             placeholder="Selección de perfiles"
                                             isClearable={false}
                                             onChange={(e) => handleProfileIncludes(e)}
                                             isDisabled={readOnly}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12">
                                    {
                                        listSelectedProfiles.length ? <h6>Perfiles incluidos dentro de la promoción</h6> : <h6>Ningún perfil está incluido</h6>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12" className='p-2'>
                                    {
                                        listSelectedProfiles.length > 0 && listSelectedProfiles.map((profile, key) => {
                                            return <FormGroup key={`listprofile-${key}`} className={profile.is_deleted ? 'd-none' : 'row'}>
                                                <Col sm="7" md="7" lg="7">
                                                    <Label className='f-12'>{profile.is_deleted ? 0 : (total_profiles++)}.- { profile.name }</Label>
                                                </Col>
                                                <Col sm="5" md="5" lg="5">
                                                    <InputGroup size='sm'>
                                                        {
                                                            percentage_global &&
                                                            <>
                                                                <Input
                                                                    className='form-control'
                                                                    type='number'
                                                                    readOnly={readOnly}
                                                                    min={1}
                                                                    max={100}
                                                                    defaultValue={profile.percentage}
                                                                    onChange={(e) => handleInputPercentageIndividual(profile.id_profile, e.target.value, "Profile")}
                                                                    invalid={profile.valid_percentage}
                                                                    />
                                                            </>
                                                        }
                                                        <InputGroupAddon addonType='append'>
                                                            {
                                                                percentage_global &&
                                                                <InputGroupText>{"%"}</InputGroupText>
                                                            }
                                                            {
                                                                !readOnly &&
                                                                <InputGroupText
                                                                    onClick={() => handleRemoveTest(profile.id_profile, "Profile")}
                                                                    style={{cursor:'pointer'}}
                                                                    className='bg-danger'
                                                                >
                                                                    <i className='fa fa-trash'></i>
                                                                </InputGroupText>
                                                            }
                                                        </InputGroupAddon>
                                                        {
                                                            percentage_global &&
                                                            <FormFeedback>Ingresa un descuento valido de 1 - 100</FormFeedback>
                                                        }
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    !readOnly &&
                    <Button
                        onClick={methodAction}
                        outline
                        color='primary'
                        type='button'
                        className={loading ? 'disabled progress-bar-animated progress-bar-striped ': ''}
                    >
                        { button }
                    </Button>
                }
                <Button className="btn-air-light" outline color="danger" type="button" onClick={toggleSpecialDiscount}>{readOnly ? "Cerrar visualización" : "Cancelar"}</Button>
            </ModalFooter>
        </>
    )
}
