import React, { Fragment, useState } from 'react'
import { useGraphBar } from '../../hooks/graph/useGraphBar'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Container, Nav, NavLink, NavItem, TabContent, TabPane, Button, Collapse } from 'reactstrap'
import Chart from 'react-apexcharts'
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
//import {UseSignalR} from '../../hooks/signalR/UseSignalR';
import Breadcrumb from '../../layout/breadcrumb'
import { useRef } from 'react';
import './StyleGraph.css'


export const IndexGraphBar = () => {

	const {

		from, to, options, handlePickerValues, validInputDate, validToInputDate,
        obtenerDatos,
        series, optionss, seriess,
        fromm, too, validInputDateP, validToInputDateP, obtenerDatosProfile,
        maximo, nameExam, maximoProfile, nameProfile, loading

	} = useGraphBar();

	const [filterDate, setFilterDate] = useState(true);

	const [activeTab, setActiveTab] = useState("1");

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}

	const first = useRef(null);

	return (
		<Fragment>
			{/* <Breadcrumb parent="Reportes" title="Exámenes / Perfiles más vendidos" className="m-b-0" /> */}
			<Container fluid={true}>
				<Row>
					<Col md="12">
						<Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
							<NavItem>
								<NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}> <i className="icofont icofont-blood-drop"></i>  Exámenes</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="icofont icofont-blood"></i>Perfiles</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab} >
							<TabPane className="fade show " tabId="1">
								<Col sm="12">
									<Row>
										<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="3" xl="3">
											<Row className="position-sticky" style={{ top: 140 }}>
												<Col xl="12">
													<Card>
														<br />
														<CardHeader className=' bg-primary '>
															<h5 className="mb-0">
																<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
																	data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="icofont icofont-laboratory"></i> &nbsp; &nbsp; &nbsp; &nbsp;Exámenes más vendidos</Button>
															</h5>
														</CardHeader>
														<Collapse isOpen={filterDate}>
															<CardBody className="p-3">
																<Form className=''>
																	<Row>
																		<FormGroup className="col-md-12  ">
																			<Label>Desde </Label>
																			<Datetime
																				key={"from"}
																				inputProps={{
																					placeholder: 'DD-MM-YYYY',
																					name: 'from',
																					autoComplete: "off",
																					className: "form-control form-control-sm input-air-primary"
																				}}
																				timeFormat={false}
																				value={from}
																				locale="es"
																				isValidDate={validInputDate}
																				closeOnSelect={true}
																				onChange={(e) => handlePickerValues(e, "from")}
																				dateFormat="DD-MM-YYYY"
																			/>
																		</FormGroup>
																		<br />
																		<Label className='col-md-12'>Hasta</Label>
																		<FormGroup className="col-md-12  ">
																			<Datetime
																				key={"to"}
																				inputProps={{
																					placeholder: 'DD-MM-YYYY',
																					name: 'to',
																					autoComplete: "off",
																					className: "form-control form-control-sm input-air-primary"
																				}}
																				timeFormat={false}
																				initialValue={to}
																				locale="es"
																				isValidDate={validToInputDate}
																				closeOnSelect={true}
																				onChange={(e) => handlePickerValues(e, "to")}
																				dateFormat="DD-MM-YYYY"
																			/>
																		</FormGroup>
																		<Col sm="12">
																			<br />
																			<Button
																				className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																				outline
																				color="info-2x"
																				style={{ "textAlign": "-webkit-center" }}
																				onClick={() => obtenerDatos()}
																			>
																				{"Buscar"}
																			</Button>
																		</Col>
																	</Row>
																</Form>
															</CardBody>
														</Collapse>
													</Card>
												</Col>
											</Row>
										</Col>
										<Col xs="12" sm="12" md="12" lg="9" xl="9">
											<br />
											<Col sm="12">
												<Row>
													<Col xs="2" sm="2" md="2" lg="4" xl="4">
													</Col>
													<Col xs="3" sm="3" md="3" lg="3" xl="3">
													</Col>
													
													<Col xs="7" sm="7" md="7" lg="5" xl="5" className="box-col-5">
														<div className="prooduct-details-box ribbon-wrapper-bottom m-b-0 m-t-0 p-t-0 p-b-0  " >
															<Card>
																<CardBody className="p-1 media b-t-info border-4">
																	<Row >
																		<Col xs="6" sm="5" md="6" lg="6" xl="6">
																			<div>
																			<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMaximo.svg")} alt="" />
																			</div>
																		</Col>
																		<Col  xs="6" sm="5" md="6" lg="6" xl="6" className="text-center p-0">
																		<label className='labelvendido f-14'>Exámen más vendido</label>
																		<label >{nameExam}</label> <br />
																		<h6 className="text-left"> {maximo}</h6>
																		</Col>
																	</Row>
																</CardBody>
															</Card>
														</div>
													</Col>		
												</Row>
											</Col>
											<Col sm="12">
												<div id="chart-Examenes+Vendidos">
													{
														series.length === 0 ?
														<div style={{ textAlign: "-webkit-center" }}>
														<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/AlertChart.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
														<br /> <p className="f-18">{"No hay Registrado Ninguna Busqueda."}</p>
														</div>
														:
														<Chart options={options} series={series} type="bar" height={290} />
													}
												</div>
											</Col>
										</Col>
									</Row>
								</Col>
							</TabPane>

							<TabPane className="fade show" tabId="2">
								<Col sm="12">
									<Row>
										<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="3" xl="3">
											<Row className="position-sticky" style={{ top: 140 }}>
												<Col xl="12">
													<Card>
														<br />
														<CardHeader className='bg-secondary'>
															<h5 className="mb-0">
																<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
																	data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="icofont icofont-laboratory"></i> &nbsp; &nbsp; &nbsp; &nbsp;Perfiles más vendidos</Button>
															</h5>
														</CardHeader>
														<Collapse isOpen={filterDate}>
															<CardBody className="p-3">
																<Row>
																	<Label className='col-12'>Desde </Label>
																	<FormGroup className="col-md-12  ">
																		<Datetime
																			key={"fromm"}
																			inputProps={{
																				placeholder: 'DD-MM-YYYY',
																				name: 'fromm',
																				autoComplete: "off",
																				className: "form-control form-control-sm input-air-primary"
																			}}
																			timeFormat={false}
																			value={fromm}
																			locale="es"
																			isValidDate={validInputDateP}
																			closeOnSelect={true}
																			onChange={(e) => handlePickerValues(e, "fromm")}
																			dateFormat="DD-MM-YYYY"
																		/>
																	</FormGroup>
																	<br />
																	<Label className='col-md-12'>Hasta</Label>
																	<FormGroup className="col-md-12  ">
																		<Datetime
																			key={"too"}
																			inputProps={{
																				placeholder: 'DD-MM-YYYY',
																				name: 'too',
																				autoComplete: "off",
																				className: "form-control form-control-sm input-air-primary"
																			}}
																			timeFormat={false}
																			value={too}
																			locale="es"
																			isValidDate={validToInputDateP}
																			closeOnSelect={true}
																			onChange={(e) => handlePickerValues(e, "too")}
																			dateFormat="DD-MM-YYYY"
																		/>
																	</FormGroup>
																	<Col sm="12">
																		<br />
																		<Button
																			className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																			outline
																			color="primary"
																			style={{ "textAlign": "-webkit-center" }}
																			onClick={() => obtenerDatosProfile()}
																		>
																			{"Buscar"}
																		</Button>
																	</Col>
																</Row>
															</CardBody>
														</Collapse>
														<br /> <br />
													</Card>
												</Col>
											</Row>
										</Col>

										<Col xs="12" sm="12" md="12" lg="9" xl="9">
											<br />
											<Col sm="12">
												<Row>
													<Col xs="2" sm="2" md="2" lg="4" xl="4">
													</Col>
													<Col xs="3" sm="3" md="3" lg="3" xl="3">
													</Col>
													<Col xs="7" sm="7" md="7" lg="5" xl="5"  className="box-col-5">
														<div className="prooduct-details-box ribbon-wrapper-bottom m-b-0 m-t-0 p-t-0 p-b-0  " >
															<Card>
																<CardBody className="p-1 media b-t-primary border-4">
																	<Row >
																		<Col  xs="6" sm="5" md="6" lg="6" xl="6">
																			<div>
																			<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMaximo.svg")} alt="" />
																			</div>
																		</Col>
																		<Col xs="6" sm="5" md="6" lg="6" xl="6" className="text-center p-0">
																		<label className='labelvendido f-14'>Perfiles más vendido</label>
																		<label >{nameProfile}</label> <br />
																		<h6 className="text-left"> {maximoProfile}</h6>
																		</Col>
																	</Row>
																</CardBody>
															</Card>
														</div>
													</Col>	
									
												</Row>
											</Col>
											<Col sm="12">
												<div id="chart-Examenes+Vendidos">
													{
														seriess.length === 0 ?
														<div style={{ textAlign: "-webkit-center" }}>
														<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/AlertChart.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
														<br /> <p className="f-18">{"No hay Registrado Ninguna Busqueda."}</p>
														</div>
														:
														<Chart options={optionss} series={seriess} type="bar" height={290} />
													}			
												</div> 
											</Col>
										</Col>
									</Row>
								</Col>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
}
