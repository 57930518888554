import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

export const ModalCodeCsv = (props) => {

    const { modalListEgress,toggleModalListEgress,listCode,handlemove,listRepit,priceTarifarion,profileT} = props;



    return (
        <Modal isOpen={modalListEgress} toggle={toggleModalListEgress} centered={true} size='lg'>
            <ModalHeader toggle={toggleModalListEgress} className='bg-primary'>
                {"Estudios no encontrados"}
            </ModalHeader>
            <ModalBody>
                <Col sm="12" className='p-l-0'  style={{ height: '300px', overflowY: "scroll", scrollBehavior: "smooth" }}>
                    <Row>
                        <Col sm="4" className='p-r-0'> 
                            <div style={{ backgroundColor: "#033d5b", textAlign: "center" }} className='b-r-csv' >
                            <label className='labeltheadInvoice f-14'>Claves no encontradas</label>
                            </div>
                          {
                            listCode?.length > 0 && listCode?.map((_find) => {
                                return(
                                  
                                    <li className='b-r-primary ' >
                                        <label className='txt-secondary f-14'>{_find?.code}</label>

                                    </li>
                                )
                            })
                          }
                        </Col>
                        
                        <Col sm="4" className='p-l-0 p-r-0'>
                            <div style={{ backgroundColor: "#033d5b" , textAlign: "center" }} className='b-r-csv' >
                            <label className='labeltheadInvoice f-14'>Claves repetidas</label>
                            </div>
                        {
                            listRepit?.length > 0 && listRepit?.map((_find) => {
                                return(
                                    <li className='b-r-primary ' >
                                        <label className='txt-secondary f-14'>{_find?.code}</label>
                                    </li>
                                )
                            })
                          }
                        </Col>

                        <Col sm="4" className='p-l-0'>
                        <div style={{ backgroundColor: "#033d5b", textAlign: "center" }}>
                            <label className='labeltheadInvoice f-14'>Sin Tarifario</label>
                        </div>
                        {
                            priceTarifarion?.length > 0 && priceTarifarion?.map((_find1, index1) => {
                                return (
                                    <li key={index1} >
                                        <label className='txt-secondary f-14'>{_find1?.code}</label>
                                    </li>
                                );
                            })
                        }
                        {
                            profileT?.length > 0 && profileT?.map((_find2, index2) => {
                                return (
                                    <li key={index2} >
                                        <label className='txt-secondary f-14'>{_find2?.code}</label>
                                    </li>
                                );
                            })
                        }
                    </Col>
                    </Row>
                </Col>
            </ModalBody>

            <ModalFooter>
            <Button size='sm' outline color='primary' onClick={ () => handlemove() } >Aceptar </Button>
            </ModalFooter>
        </Modal>
    )
}
