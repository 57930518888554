import React from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row, Table ,Input} from 'reactstrap';
import 'animate.css';
import { ConfigServer } from '../../data/config';


export const ModalPreviewInvoice = (props) => {
    
    const {
        loadingPreview, modalPreviewInvoice, toggleModalPreviewInvoice, formInvoice,
        informationPatient, informationBusinessName, filterTypeClient, listExamsInvoice,
        subtotalInvoice, totalTaxInvoice, totalInvoice,number_format,listInvoices,subtotalC,totalTaxC,totalC,subtotalN,totalTaxN,totalN
    } = props;

    const {
        type_document,
        id_commercial_line,
        id_pay_form,
        id_method_pay,
        id_coin,
        payment_conditions,
        expedition_place,
        information_general_public,      
        comments_invoice,
        id_type_releationship

    } = formInvoice;



    return (
        <Modal isOpen={modalPreviewInvoice} toggle={toggleModalPreviewInvoice} centered={true} size={loadingPreview ? '' : 'xl'} scrollable={true}>
            <ModalBody className='border-3 b-primary '>
                {
                    loadingPreview === true && <>
                        <Row className='mt-2'>
                            <Col sm="12" md="12" lg="12" className='text-center'>
                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'150px' }} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                            <Col sm="3" md="3" lg="3" className='text-left'>
                                <p className='f-24 text-primary'><b>Cargando</b></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p> 
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                        </Row>
                    </>                    
                }
                {
                    loadingPreview === false && <>
                    {
                        type_document === "I" ?
                        <div>
                        <Col sm="12">
                            <Row>
                                <Col sm="3" className='p-r-0' >
                                <div className="media profile-media" >  <br /> <br />
                                <img className="b-r-10  "
                                    src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                                    style={{ width: "70%" }}
                                    alt="Invoice" />
                                </div>
                                </Col>

                                <Col sm="9" className='p-r-0' style={{textAlign : "center"}} >
                                <label className='txt-secondary f-20' style={{textAlign : 'center'}}> { id_commercial_line.label }</label>
                                <br />
                                <b className='f-16' style={{textAlign : 'center'}}>RFC:&nbsp;{id_commercial_line.rfc }</b>
                                <br />
                                <label htmlFor="">{id_commercial_line.name_tax_regime} </label>
                            </Col>
                            </Row>
                        </Col>
                        <br />
                        <br />
                        <Col sm="12">
                            <Row>
                                <Col sm="7" className='p-l-0' >
                                    <Row>
                                        <Col sm="12">
                                            <label htmlFor="">*Método de pago:&nbsp;{ id_method_pay.label}</label>
                                            <br />
                                            <label htmlFor="">*Forma de pago:&nbsp;{ id_pay_form.label }</label>
                                            <br />
                                            <b className='f-14'>Receptor:</b>
                                            <br />
                                            <label htmlFor="">{filterTypeClient === "1" ? informationPatient.business_name : filterTypeClient === "2" ? informationBusinessName.business_name : information_general_public.name_general_public}</label>
                                            <br />
                                            <label htmlFor="">RFC:&nbsp; {filterTypeClient === "1" ? informationPatient.rfc_patient : filterTypeClient === "2" ? informationBusinessName.rfc_business_name : information_general_public.rfc_general_public}</label>
                                            <br />
                                            <label htmlFor="">Domicilio:&nbsp;{filterTypeClient === "1" ? informationPatient.cp_patient : filterTypeClient === "2" ? informationBusinessName.cp_business_name: information_general_public.cp_general_public }</label>
                                            <br />
                                            <label htmlFor="">Regimen fiscal:&nbsp;{ filterTypeClient === "1" ? informationPatient.tax_regime_patient : filterTypeClient === "2" ? informationBusinessName.tax_regime_business_name : information_general_public.tax_regime_general_public }</label>
                                            <br />
                                            <label htmlFor="">*Uso:&nbsp;{formInvoice.id_use_cfdi.label}</label>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm="5" style={{textAlign : "end"}}>
                                <Row>
                                        <Col sm="12" className='p-r-0' >
                                            <b htmlFor="">FACTURA # F-#Consecutivo</b>
                                            <br />
                                            <label htmlFor="">*Tipo de comprobante:&nbsp;{type_document === "I" ? "I - Ingreso" : ( type_document === "E" ? "E - Egreso" : "P - Pago" ) }</label>
                                            <br />
                                            <label htmlFor="">Versión 4.0</label>
                                            <br />
                                            <label className='txt-primary'>Fecha y hora de emisión:</label><br />
                                            <label className='txt-primary'>No. de Serie del Certificado:</label><br />
                                            <label htmlFor="">Moneda MXN - Peso Mexicano</label>
                                            <br />
                                        </Col>
                                      
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                   
                        <br />
                        <br /> <br /> <br />
                             <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0'>
                               <table className='table table-striped table-hover table-sm'>
                                   <thead className='table-primary'>
                                       <tr>
                                           <th>Cantidad</th>
                                           <th>Clave de producto</th>
                                           <th>Clave unidad</th>
                                           <th>Descripción</th>
                                           <th>Valor unitario</th>
                                           <th>Importe</th>
                                          <th>Descuento</th>
                                        </tr>
                                   </thead>
                                    <tbody>
                                       {
                                           listExamsInvoice?.length > 0 && listExamsInvoice?.map(test => {
                                               return (
                                                   <tr key={`tr-test-${test.id_work_order_exam === null ? test.id_work_order_profile : test.id_work_order_exam}`}>
                                                       <td style={{textAlign:"center"}}>1</td>
                                                       <td style={{textAlign:"center"}}>{ test.is_general_public ? test.product_code_service : test.class_product_code_service }</td>
                                                       <td>{ test.code_unit + ' - ' + test?.name_unit }</td>
                                                       <td>{ test.name }</td>
                                                       <td style={{textAlign:"center"}}><span className={ id_coin.icon }></span> { number_format((test.price + test.price_urgent), id_coin.decimals) }</td>
                                                       <td style={{textAlign:"center"}}><span className={ id_coin.icon }></span> { number_format((test.price + test.price_urgent), id_coin.decimals) }</td>
                                                       <td style={{textAlign:"center"}}><span className={ id_coin.icon }></span> { number_format((test.total_discount), id_coin.decimals) }</td>
                                                     </tr>
                                                 )
                                           })
                                        }
                                   </tbody>
                                </table>
                             </Col> <br /> <br /> <br />
                        <Row>
                            <Col sm="12">
                                <Row>
                                    <Col sm="4">
                                    <Col sm="12" className=' mb-2' style={{backgroundColor :"#b8daff" , textAlign:"center"}}  >
                                    <h7 className='m-1'>Terminos y condiciones</h7>
                                    </Col>
                                    <Col sm="12" className='text-justify b-r-5 '>
                                       <p>{ comments_invoice }</p>
                                    </Col>
                                    </Col>
                                    <Col sm="5">
                                    </Col>
                                    <Col sm="3" >
                                    <Col sm="12" className=' mb-2' style={{backgroundColor :"#b8daff" , textAlign:"center"}}>
                                    <h6 className='m-1'>Total</h6>
                                    </Col>
                                        <Row>
                                            <Col sm="6" style={{textAlign : "end"}}  >
                                                <label className='txt-secondary f-14 '>Subtotal</label>
                                                <br />
                                                <label className='txt-secondary f-14'>Iva</label>
                                                <br />
                                                <label className='txt-secondary f-14'>Total</label>
                                            </Col>
                                            <Col sm="5" className='p-l-0' >
                                            <label className='f-14'>${ number_format(subtotalInvoice, id_coin.decimals) }</label>
                                                <br />
                                                <label className='f-14'>${number_format(totalTaxInvoice, id_coin.decimals)}</label>
                                                <br />
                                                <label className='f-14'>${number_format(totalInvoice, id_coin.decimals) }</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="12">
                            <br />
                            <br />
                            <label className='txt-secondary'>Folio Fiscal:</label>
                            <br />
                            <label className='txt-secondary'>Fecha y hora de certificación:</label>
                            <br />
                            <label className='txt-secondary'>Lugar de expedición:</label>
                            </Col>

                        </Row>
                        <br />
                        <br />
                        <Col sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}}>
                        <label className='txt-secondary'>*Este Documento es una Representación  de un CFDI</label>
                        <br />
                        <label className='txt-secondary'>*Impuesto Retenido de Conformidad con el Impuesto al Valor Agregado</label>
                        </Col>
                    </div>
                        :
                        type_document === "P" 
                        ?
                        <div>
                            <Col sm="12">
                                <Row>
                                    <Col sm="3" className='p-r-0' >
                                    <div className="media profile-media" >  <br /> <br />
                                    <img className="b-r-10  "
                                        src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                                        style={{ width: "70%" }}
                                        alt="Invoice" />
                                    </div>
                                    </Col>

                                    <Col sm="9" className='p-r-0' style={{textAlign : "center"}} >
                                    <label className='txt-secondary f-20' style={{textAlign : 'center'}}> { id_commercial_line.label }</label>
                                    <br />
                                    <b className='f-16' style={{textAlign : 'center'}}>RFC:&nbsp;{id_commercial_line.rfc }</b>
                                    <br />
                                    <label htmlFor="">{id_commercial_line.name_tax_regime} </label>
                                </Col>
                                </Row>
                            </Col>
                            <br />
                            <br />
                            <Col sm="12">
                                <Row>
                                    <Col sm="6" className='p-l-0' >
                                        <Row>
                                            <Col sm="6">
                                                <label htmlFor="">Lugar de expedición:</label>
                                                <br />
                                                <label htmlFor="">Fecha de expedición:</label>
                                                <br />
                                                <label htmlFor="">Tipo:</label>
                                                <br />
                                                <label htmlFor="">Version CFDI:</label>
                                            </Col>
                                            <Col sm="6" style={{textAlign : "end"}}>
                                                <label htmlFor="">{expedition_place}</label>
                                                <br />
                                                <label htmlFor="">{formInvoice.date_payment}</label>
                                                <br />
                                                <label htmlFor="">P</label>
                                                <br />
                                                <label htmlFor="">4.0</label>
                                               
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm="6">
                                    <Row>
                                            <Col sm="4" className='p-r-0' >
                                                <label htmlFor="">Uso CFDI:</label>
                                                <br />
                                                <label htmlFor="">Moneda:</label>
                                                <br />
                                                <label htmlFor="">Serie y Folio:</label>
                                                <br />
                                            </Col>
                                            <Col sm="8" style={{textAlign : "end"}}>
                                                <label htmlFor="">CPO1-Pagos</label>
                                                <br />
                                                <label htmlFor="">XXX-Los códigos asignados para las transacciones</label>
                                                <br />
                                                <label htmlFor="">F-Consecutivo:</label>
                                                <br />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="12" className='p-l-0' >
                             <b>RECEPTOR: &nbsp;  {informationBusinessName.rfc_business_name  }&nbsp; { informationBusinessName.business_name }</b>
                             <br /> <br />
                             <label htmlFor="">Domicilio:&nbsp;{informationBusinessName.cp_business_name}&nbsp;&nbsp;Régimen:&nbsp;{informationBusinessName.tax_regime_business_name}</label>
                            </Col>
                            <br />
                            <div className='theadInvoice'><br /></div>
                            <br />
                            <Col sm="12"  className='bg-secondary mb-2' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px', textAlign : "center"}}>
                                <h6 className='m-1'>Complemento de Pagos Versión 2.0 </h6>
                            </Col>
                            <Col sm="12" style={{textAlign: "center"}} className='b-complemento'>
                                <Row>
                                <Col sm="3">
                                    <label htmlFor="">Fecha de Pago:</label>
                                </Col>
                                <Col sm="3">
                                    <label htmlFor="">{formInvoice.date_payment}</label>
                                </Col>
                                <Col sm="3" >
                                    <label htmlFor="">Forma de pago:</label>
                                </Col>
                                {/* className='b-t-primar b-b-primary b-l-primary b-r-primary' */}
                                <Col sm="3">
                                    <label htmlFor="">01</label>
                                </Col>
                                </Row>
                            </Col>
                            <br /> <br />
                            <b className='txt-secondary'></b>
                            <Row>
                                {
                                    listInvoices.map((_invoice) => {
                                        //console.log(_invoice);
                                        return(
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="6" className='b-complemento' style={{textAlign : "center" }} >
                                                    <label htmlFor="">UUID Documento Relacionado: </label>

                                                    </Col>
                                                    <Col sm="6" style={{textAlign : "center"}} className='b-complemento'>
                                                    <label htmlFor="">{_invoice.uuid}</label>


                                                    </Col>
                                                </Row> <br />
                                                <Row>
                                                    <Col sm="2" className='b-complemento'> 
                                                        <label htmlFor="">Serie</label>
                                                        <br />
                                                        <label htmlFor="">{_invoice.serie}</label>

                                                    </Col>
                                                    <Col sm="2" className='b-complemento'>
                                                    <label htmlFor="">Folio</label>
                                                    <br />
                                                    <label htmlFor="">{_invoice.folio}</label>


                                                    </Col>
                                                    <Col sm="2" className='b-complemento'>
                                                    <label htmlFor="">Moneda</label>
                                                    <br />
                                                    <label htmlFor="">{_invoice.code_coin}</label>

                                                    </Col>
                                                    <Col sm="2" className='b-complemento'>
                                                    <label htmlFor="">T.C</label>
                                                    <br />
                                                    <label htmlFor="">{"1"}</label>

                                                    </Col>
                                                    <Col sm="2" className='b-complemento'>
                                                    <label htmlFor="">Saldo Ant</label>
                                                    <br />
                                                    <label htmlFor="">{_invoice.previous_amount}</label>

                                                    </Col>
                                                    <Col sm="2" className='b-complemento'>
                                                    <label htmlFor="">Pagado</label>
                                                    <br />
                                                    <label htmlFor="">{_invoice.payment_amount}</label>


                                                    </Col>


                                                </Row>
                                                <br /> <br /> <br />
                                            </Col>
                                        )
                                    })

                                }
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3" >
                                        <div className='theadInvoice'><br /></div>


                                            <Row>
                                                <Col sm="6" style={{textAlign : "end"}} >
                                                    <label className='txt-secondary f-14'>Subtotal</label>
                                                    <br />
                                                    <label className='txt-secondary f-14'>Iva</label>
                                                    <br />
                                                    <label className='txt-secondary f-14'>Total</label>
                                                </Col>
                                                <Col sm="5" className='p-l-0' >
                                                <label className='f-14'>{subtotalC}</label>
                                                    <br />
                                                    <label className='f-14'>{totalTaxC}</label>
                                                    <br />
                                                    <label className='f-14'>{totalC}</label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="12">
                                <br />
                                <br />
                                <label className='txt-secondary'>Folio Fiscal:</label>
                                <br />
                                <label className='txt-secondary'>Fecha y hora de certificación:</label>
                                <br />
                                <label className='txt-secondary'>Lugar de expedición:</label>
                                </Col>

                            </Row>

                        
                        </div>
                        :
                        type_document === "E" 
                        ?
                        <div>
                        <Col sm="12">
                            <Row>
                                <Col sm="3" className='p-r-0' >
                                <div className="media profile-media" >  <br /> <br />
                                <img className="b-r-10  "
                                    src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                                    style={{ width: "70%" }}
                                    alt="Invoice" />
                                </div>
                                </Col>

                                <Col sm="9" className='p-r-0' style={{textAlign : "center"}} >
                                    <label className='txt-secondary f-20' style={{textAlign : 'center'}}> { id_commercial_line.label }</label>
                                    <br />
                                    <b className='f-16' style={{textAlign : 'center'}}>RFC:&nbsp;{id_commercial_line.rfc }</b>
                                    <br />
                                    <label htmlFor="">{id_commercial_line.name_tax_regime} </label>
                                </Col>
                            </Row>
                        </Col>
                        <br />
                        <br />
                        <Col sm="12">
                            <Row>
                                <Col sm="6" className='p-l-0' >
                                    <Row>
                                        <Col sm="10">
                                            <label htmlFor="">*Método de pago:&nbsp;{id_method_pay.label}</label>
                                            <br />
                                            <label htmlFor="">*Forma de Pago:&nbsp;{id_pay_form.label}</label>
                                            <br />
                                            <b className='f-14'>RECEPTOR:</b>
                                            <br />
                                            <label htmlFor=""> { informationBusinessName.business_name }</label>
                                            <br />
                                            <label htmlFor="">RFC:&nbsp;{informationBusinessName.rfc_business_name}</label>
                                            <br />
                                            <label htmlFor="">Domicilio:&nbsp;{informationBusinessName.cp_business_name}</label>
                                            <br />
                                            <label htmlFor="">Régimen:{informationBusinessName.tax_regime_business_name}</label>
                                            <br />
                                            <label className='f-14'  >*Uso: G02 - Devoluciones, descuentos obonificaciones</label>
                                        </Col>
                                        <Col sm="6" style={{textAlign : "end"}}>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm="6">
                                <Row>
                                        <Col sm="4" className='p-r-0' >
                                        </Col>
                                        <Col sm="8" style={{textAlign : "end"}}>
                                            <b htmlFor=""># Consecutivo</b>
                                            <br />
                                            <label htmlFor="">*Tipo de comprobante: E - Egreso </label>
                                            <br />
                                            <label htmlFor="">Versión 4.0</label>
                                            <br />
                                            <label className='txt-secondary'>Fecha y hora de emisión:</label>
                                            <br />
                                            <label className='txt-secondary'>No. de Serie del Certificado:</label>
                                            <br />
                                            <label htmlFor="">Moneda MXN - Peso Mexicano </label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                      
                        <br />
                        <div className='theadInvoice'><br /></div>
                        <br />
                        <br /> 
                        <div className='table-responsive'>
                            <Table className='table-sm table-stripped'>
                            <thead className="theadInvoice">
                                <tr>
                                <th className='text-center labeltheadInvoice'>Cantidad</th>
                                <th className='text-center labeltheadInvoice'>Código</th>
                                <th className='text-center labeltheadInvoice'>Clave unidad</th>
                                <th className='text-center labeltheadInvoice'>Concepto</th>
                                <th className='text-center labeltheadInvoice'>P. Unitario</th>
                                <th className='text-center labeltheadInvoice'>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                listInvoices.length > 0 && listInvoices.map(_invoice => {
                                    return (
                                        <tr className='text-center' key={'concept-'+_invoice.id_invoice}>
                                            <td>1</td>
                                            <td>377</td>
                                            <td>ACT ACTIVIDAD</td>
                                            <td>
                                                <Input disabled size={'sm'} value={_invoice.concept}  />
                                            </td>
                                            <td>{_invoice.discount}</td>
                                            <td>{_invoice.discount}</td>
                                        </tr>
                                    )
                                })
                            }
                         

                            </tbody>
                            </Table> 

                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Row>
                            <Col sm="12">
                             <Row>
                                <Col sm="9">
                                </Col>
                                <Col sm="3" >
                                    <div className='theadInvoice'><br /></div>
                                        <Row>
                                            <Col sm="6" style={{textAlign : "end"}} >
                                                <label className='txt-secondary f-14'>Subtotal</label>
                                                <br />
                                                <label className='txt-secondary f-14'>Iva</label>
                                                <br />
                                                <label className='txt-secondary f-14'>Total</label>
                                            </Col>
                                            <Col sm="5" className='p-l-0' >
                                                <label className='f-14'>{subtotalN}</label>
                                                <br />
                                                <label className='f-14'>{totalTaxN}</label>
                                                <br />
                                                <label className='f-14'>{totalN}</label>
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        <br />
                        <br />
                        <br />
                        <label htmlFor="">Tipo de Relacion: {id_type_releationship.label}</label>
                        <br />
                        <label htmlFor="">UUID Relacionado(s): </label>
                        <br />
                        <br />
                        <label className='txt-secondary'>Folio Fiscal:</label>
                        <br />
                        <label className='txt-secondary'>Fecha y hora de certificación:</label>
                        <br />
                        <label className='txt-secondary'>Lugar de expedición:</label>
                        </div>
                        :
                        ""
                    }
                    </>
                }
            </ModalBody>
            {
                loadingPreview === false && <ModalFooter>
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleModalPreviewInvoice}>Cerrar</Button>
                </ModalFooter>
            }                
        </Modal>
    )
}
