import React from 'react';
import { Modal, Col, Row, Label, Button, Input, Media, ModalBody, Table } from 'reactstrap';
import 'animate.css';
import '../../../../assets/css/analiticaCards.css'
import Select from 'react-select';




export const ModalCancellaPatient = (props) => {

    const {




        typeModalCan = "can", modalCan, toggleCancell, closedModalCancell, dataStudy, loadingPatient,
        reason, id_return_reason, handleSelectValuesR, obj, userSelected, headerSelected, refusedPatientSection


    } = props;






    return (
        <Modal isOpen={modalCan} toggle={closedModalCancell} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalCan === "can" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Rechazar Estudio"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closedModalCancell} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadingPatient === false ?
                                        <>
                                            <Row className='mt-2'>
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>


                                                    <p className='f-24 text-primary'><b>Cargando</b></p>


                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <Col sm="12" className='text-center p-l-0 p-r-0'>
                                            <Col sm="12" style={{ textAlign: "left" }} >
                                                <Row>
                                                    <Col sm="2" className='p-r-0' >
                                                        <img style={{ width: "90%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />

                                                    </Col>
                                                    <Col sm="9" className='p-l-0' > <br />
                                                        <label className='txt-secondary f-14'>Responsable:</label>&nbsp;&nbsp;<b className='f-12'>{obj.name}</b>
                                                        <br />
                                                        <label className='txt-secondary f-14'>Proceso:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.proceso}</b>
                                                        <br />
                                                        <label className='txt-secondary f-14'>Sección:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.seccion}</b>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <br /> <br />
                                            <Col sm="12" style={{ textAlign: "initial" }} >
                                                <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                                <br /><br />
                                                <div>

                                                    <Select
                                                        classNamePrefix="select"
                                                        name="id_return_reason"
                                                        value={id_return_reason}
                                                        options={reason}
                                                        onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                                        isClearable={true}
                                                        placeholder={"Selecciona un motivo..."}
                                                    />
                                                </div>
                                            </Col>
                                            <br />
                                            <Col sm="12" style={{ textAlign: "initial" }}>
                                                <label className='txt-secondary f-14'>Estudios:</label>
                                                {
                                                    dataStudy.map((_obj) => {
                                                        return (
                                                            <Row>
                                                                <Col sm="6" style={{ textAlign: "initial" }} >
                                                                    <Input id={`checkbox-${_obj.id_exam}`} value={_obj.id_exam} checked={_obj.check_specimen}
                                                                        disabled={_obj.check_specimen === true ? true : false}
                                                                        type="checkbox"
                                                                        className='checkbox_animated'
                                                                    />
                                                                    <Label className='f-w-600  modal-label-modal badge badge-light-secondary' for={`checkbox-${_obj.id_exam}`}>{_obj.name}</Label>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </Col>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "end" }} >
                                                <Button outline color='danger' size='sm' onClick={() => refusedPatientSection()} >Rechazar Muestra</Button>
                                            </Col>
                                        </Col>
                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }


        </Modal >
    )
}