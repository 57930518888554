import React, { useState } from 'react';
import { Modal, Button, Form, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Row, Col, Input, Container, FormFeedback, } from 'reactstrap';
import { ListOrderingItem } from '../../../components/lists/ListOrderingItem';

export const ModalOrderPrintAnalytes = (props) => {

    const {
        analytesGroupPrint, handleModalOrderPrint: toggle, modalOrderPrint, loadingOrderPrint, saveOrderPrintAnalytes
    } = props;

    const [preview, setPreview] = useState(false);

    return (
        <Modal isOpen={modalOrderPrint} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            {/* <ModalHeader toggle={toggle}className="pb-1 pl-2 pr-2"> */}
            <div className="modal-header align-items-center pb-1">
                <Col sm="8">
                    Ordenar analitos para su impresión
                </Col>
                <Col sm="4" className="text-right ">
                    <Button className="btn-neutral btn btn-default btn-sm" onClick={() => setPreview(!preview)}>
                        {
                            preview === true ?
                                "Edición" : "Vista previa"
                        }
                    </Button>
                </Col>
            </div>
            <ModalBody>
                {
                    preview === false ?
                        <>
                            <label className="text-gray text-sm">Arrastre y suelte los grupos y analitos para su <b>orden de impresión</b></label>
                            <ListOrderingItem itemsOrdered={analytesGroupPrint} {...{ modalOrder: modalOrderPrint }} />
                        </>
                        :
                        <>
                            <ul className="list-circle pl-3">
                                {
                                    analytesGroupPrint.map((obj, key) => {
                                        return <li key={key}>{obj.content}
                                            <ul className="list-circle pl-3">
                                                {
                                                    obj.subItems.map((sub, key2) => {
                                                        return <li key={key2}>
                                                            {sub.content}
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    })
                                }
                            </ul>
                        </>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => saveOrderPrintAnalytes(analytesGroupPrint)}
                    color="primary"
                    type="submit"
                    className={loadingOrderPrint ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Ordenar analitos para su impresión
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
