import React, {Fragment} from 'react';
import { Button, Card, CardBody, Container, Row, Col, Input, } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalMicSample } from '../../../components/micSample/ModalMicSample';
import { useMicSample } from '../../../hooks/micSample/useMicSample';

export const IndexMicSample = () => {
    const{
        samplesMic, name, validaciones,
        method, 
        handleInputChange, handleSearchSample, loading, 
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    } = useMicSample();

    return(
        <>
            <Fragment>
                <Container fluid={true}>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody className="p-1">
                                    <div className='ribbon ribbon-clip ribbon-primary'>
                                        {"Listado de Muestras"}
                                    </div>
                                    <div className="text-right" style={{margin: 5, paddingBottom: 10}}>
                                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate} ><span className="fa fa-plus-circle"></span></Button>
                                    </div>
                                    <div className="row">
                                        <div className="offset-8 col-4 pt-1 pb-2">
                                            <Input placeholder='Búsqueda...' className='form-control' onChange={handleSearchSample}/>
                                        </div>
                                    </div>
                                    {
                                        samplesMic.length === 0 ?
                                        <div style={{textAlign:"-webkit-center"}}>
                                            <img className='img-fluid' src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style ={{width:"25%"}} alt=""/> <br/>
                                            <br/>
                                            <p className='f-18'>{"No se ha registrado sitios"}</p>
                                        </div>
                                        :
                                        <div className="table-responsive">
                                            <TableStriped
                                                notMaped={["visibleTable"]}
                                                methodsActions={true}
                                                cabeceras={["Nombre"]}
                                                items={samplesMic}
                                                {...{ handleUpdate,handleDelete}}
                                                nextPage={nextPage}
                                                previousPage={previousPage}
                                                totalPageCount={totalPageCount}
                                                currentPage={currentPage}
                                                goToPage={goToPage}
                                            />
                                        </div> 
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalMicSample
                {
                    ...{
                        samplesMic, name, validaciones,
                        method, 
                        handleInputChange, handleSearchSample, loading, 
                        toggle, modal, modalTitle,
                        saveCreate, saveUpdate, saveDelete,
                        nextPage, previousPage, goToPage, totalPageCount, currentPage,
                    }
                }
                />
            </Fragment>
        </>
    )
}
