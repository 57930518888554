import React from 'react';
import { Button, Card, CardBody,Input } from 'reactstrap';
import { ModalSpecialty } from '../../components/specialties/ModalSpecialty';
import { TableStriped } from '../../components/tables/TableStriped';
import { TabSpeciality } from '../../constant';
import { useSpecialty } from '../../hooks/specialties/useSpecialty';

export const IndexSpecialties = ({activeTab}) => {

    const {
        specialties, method, handleInputChange, validaciones,
        name,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchSpecial


    } = useSpecialty(activeTab);


    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        {TabSpeciality}
                    </div>
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSpecial} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        {
                            specialties.length === 0 ?

                            <div style={{ textAlign: "-webkit-center" }}>
                            <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "24%" }} alt="" />   <br />                                         
                            <br /> <p className="f-18">{"No se ha registrado una Especialidad aún."}</p>
                            </div>
                            :
                            <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={true}
                            cabeceras={["Nombre"]}
                            items={specialties}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />

                        }
                       


                      
                    </div>
                </CardBody>
            </Card>
            <ModalSpecialty
                {
                    ...{
                        method, handleInputChange, validaciones,
                        name, toggle, modal, loading,
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
