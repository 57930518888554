import React from 'react'
import {Button, Modal, ModalBody, Row,Col, Form, CardBody } from 'reactstrap';


export const ModalSendWhatsapp = (props) => {

    const {modalPreviewSend,toggleModalSend,validNumber,id_patient,sendResultsWhatsapp} = props;

    return (
        <Modal isOpen={modalPreviewSend} toggle={toggleModalSend} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                        <Col sm="12" className='p-l-0 p-r-0 '>
                        <Row>
                            <Col sm="2">
                            <i className='icofont icofont-brand-whatsapp f-52 txt-success'></i>
                            </Col>
                            <Col sm="10" className='p-l-0'>
                            <b className='txt-secondary f-14'>Enviar resultados al paciente por whatsapp al:</b> <b className='f-14' >{validNumber}</b>
                            </Col>
                        </Row>
                        <br />
                         <br />
                         <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                         <Row>
                            <Col sm="6">
                            </Col>
                            <Col sm="3">
                            <Button color="danger text-center" size='sm' type="button" onClick={toggleModalSend}>{"Cancelar"}</Button>
                            </Col>
                            <Col sm="3">
                            <Button color="success text-center" size='sm' type="button" onClick={() => sendResultsWhatsapp(id_patient) }>{"Enviar"}</Button>
                            </Col>
                         </Row>
                        </Col>
                        </Col>       
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
