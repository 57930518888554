import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';

export const ModalTestAlerts = (props) => {

    const {
        isOpen, toggle, size, data, modalTitle, 
    } = props;

    return (    
        <Modal isOpen={isOpen} toggle={toggle} backdrop='static' keyboard={false} centered={true} size={size} scrollable={true}>
            <ModalHeader toggle={toggle} className="bg-primary">{modalTitle}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        {
                            data.length > 0 &&
                                <div className='pricingtable'>
                                    <div className='pricingtable-header'>
                                        <h6 className='title'>Rangos alfanumericos</h6>
                                    </div>
                                    <div className='pricingtable-body'>
                                        <ul className='pricing-content'>
                                            {
                                                data.length > 0 && data.map( obj => {
                                                    return (
                                                        <li key={obj.text_alert}>
                                                            { obj.text_alert }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div> 
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className='btn-air-light' color='light' type='button' onClick={toggle}>Cerrar</Button>
            </ModalFooter>
        </Modal>        
    )
}
