import React from 'react'
import { Button ,Modal, ModalBody, ModalHeader, ModalFooter,Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

export const ModalReleaseExam = (props) => {

    const {
        releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
        saveUpdateStatusComplete
    } = props;


    return (
        <Modal isOpen={modalReleaseExam} toggle={toggleRelease} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleRelease} className="bg-primary">Liberar examen</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <h6>Liberar examen para su venta</h6>
                    </Col>
                    <Col md="12">
                        <b className='text-danger'>Indicaciones criticas</b>                        
                        <ListGroup>
                            {
                                (releaseExam.exams_transports || releaseExam.production_cost || releaseExam.valid_method_default || releaseExam.valid_analyte || releaseExam.valid_analyte_age_gender_range || releaseExam.valid_number_projections) 
                                    ? "" 
                                    : 
                                        <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                            No hay indicaciones criticas
                                            <i className="fa fa-check text-success"></i>
                                        </ListGroupItem>
                            }
                            {  //!releaseExam.enable_analyte_configuration modifique esta parte
                                (releaseExam.is_imagenology && releaseExam.enable_analyte_configuration)
                                ?
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_number_projections ?  "Falta añadir el número de proyecciones para este examen" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                : ""
                            }
                            {
                                releaseExam.exams_transports ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.exams_transports ? "Falta añadir un tipo de transporte" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                            {
                                releaseExam.production_cost ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.production_cost ? "Falta añadir un costo de producción" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                            {
                                releaseExam.valid_method_default ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_method_default ? "Falta añadir un metodo por defecto" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                            {
                                releaseExam.valid_analyte ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_analyte ? "Falta añadir al menos un analito" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                            {
                                releaseExam.valid_analyte_age_gender_range ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_analyte_age_gender_range ? "Falta añadir al menos un rango de edad" : "" }
                                        <i className="fa fa-exclamation-triangle text-danger"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                        </ListGroup>
                        <br />
                        <b className='text-warning'>Indicaciones preventivas</b>
                        <ListGroup>
                            {
                                releaseExam.valid_reference_values ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_reference_values ? "Falta añadir al menos un valor de referencia" : "" }
                                        <i className="fa fa-exclamation-triangle text-warning"></i>
                                    </ListGroupItem>
                                    : ""
                            }
                            {
                                releaseExam.valid_indications ? 
                                    <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">
                                        { releaseExam.valid_indications ? "Falta añadir al menos una indicación" : "" }
                                        <i className="fa fa-exclamation-triangle text-warning"></i>
                                    </ListGroupItem>
                                    : ""
                            }   
                        </ListGroup>                                                 
                    </Col>
                </Row>  
                <br />
                <Row>
                    <Col md="12">
                        <b>* Para librar el examen para su venta, completa primero las indicaciones criticas</b>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="primary" type="button" className={loadingReleaseExam ? "disabled progress-bar-animated progress-bar-striped" : ""} onClick={saveUpdateStatusComplete}
                    disabled={ (releaseExam.exams_transports || releaseExam.production_cost || releaseExam.valid_method_default || releaseExam.valid_analyte || releaseExam.valid_analyte_age_gender_range || releaseExam.valid_number_projections ) ? true : false }
                >
                    Liberar examen
                </Button>
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggleRelease}>Cancelar</Button>
            </ModalFooter>
        </Modal>        
    ) 
}
