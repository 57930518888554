import React from 'react';
import { Button, Card, CardBody, Input, Row, Col } from 'reactstrap';
import { ModalAppCode } from '../../../components/appCodes/ModalAppCode';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useAppCodes } from '../../../hooks/analytes/useAppCodes';

export const IndexAppCodes = () => {

    const {
        validacionAnalyte, appCodes, brands, machineModels, infoAnalytes, methods,
        validaciones,
        id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids,
        modal, modalTitle, toggle, method,
        handleInputChange, handleValueSelect, handleMethods, loading, metodosSelect,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        loadingAppCodes,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearch,handleDelete,saveDelete
    } = useAppCodes();

    return ( 
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Analitos vinculados
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                     <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                        </div>
                    </div>
                    <Row className={loadingAppCodes ? '' : 'd-none'}>
						<Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                            <div className="loader-box primary" style={{ width: "150px", height: "150px", marginLeft: "45%", marginRight: "50%" }}>
                                <div className="loader-9" color='primary' style={{ height: "150px", width: "150px" }}></div>
                            </div>
							<br />
							<p><b>Cargando analitos vinculados...</b></p>
                        </Col>						
					</Row>
                    <div  className={loadingAppCodes ? 'd-none' : 'table-responsive'}>
                        <TableStriped
                            isResponsive={true}
                            notMaped={["id_brand", "id_analyzer", "analyzer_name", "id_machine_model", "id_info_analyte", "methods_arrays", "", "visibleTable"]}
                            methodsActions={true}
                            cabeceras={["Marca", "Modelo", "Analito", "App Code", "F. de conversión", "Métodos disponibles"]}
                            items={appCodes}
                            {...{ handleUpdate,handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalAppCode
                {...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    brands, machineModels, infoAnalytes, methods,
                    id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids,
                    handleInputChange, handleValueSelect, handleMethods, loading, metodosSelect,
                    saveCreate, saveUpdate,
                    validacionAnalyte, saveDelete
                }}
            />
        </>
    );
};
