import React, { Fragment } from 'react';
import { Card, Col, Container, Row, Button, CardBody,Input } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { useListCompaniesAgreement } from '../../hooks/agreements/useListCompaniesAgreement';
import Breadcrumbs from '../../layout/breadcrumb';

export const ListCompaniesAgreements = () => {

    const { agreementClients, navigateDetail,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchAgreement

    } = useListCompaniesAgreement();
    ////console.log(agreementClients);


    return (
        <Fragment>
            {/* <Breadcrumbs parent="Catálogos" title="Listado de clientes" className="m-b-0" /> */}
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de Clientes
                                </div>
                                <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchAgreement} />
                        </div>
                    </div>
                            </CardBody>
                            <div className="table-responsive">
                                {
                                    agreementClients.length === 0 ?
                                    <div style={{ textAlign: "-webkit-center" }}>
                                    <img className="img-fluid" src={require("../../assets/images/login/title.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
                                    <br /> <p className="f-18">{"No se tiene ninguna información ya que no hay un Convenio Registrado"}</p>
                                    </div>
                                    :
                                    <TableStriped
                                    notMaped={["agreementViews", "visibleTable"]}
                                    methodsActions={true}
                                    cabeceras={["Código", "Nombre del cliente", "RFC", "# convenios activos"]}
                                    items={agreementClients}
                                    methodsAbsolutes={
                                        [
                                            {
                                                type: "linkId",
                                                method: navigateDetail,
                                                icon: "fa fa-eye",
                                                backgroundColor: "#0079C7",
                                                color: "#fff",
                                                tooltip:"Visualizar"
                                            },
                                        ]
                                    }
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />
                                }   
                            </div>
                        </Card> <br />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
