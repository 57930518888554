import { async } from 'q';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router'
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useSpecialDiscount = () => {
    
    const history = useHistory();

    //#region DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion

    //#region MODALS 
    const [modalTitle, setModalTitle] = useState("");

    const [modalSpecialDiscount, setModalSpecialDiscount] = useState(false);
    const [modalInformation, setModalInformation] = useState(false);

    const [methodSpecialDiscount, setMethodSpecialDiscount] = useState("");
    const [methodInformation, setMethodInformation] = useState("");

    const toggleSpecialDiscount = () => {
        setModalSpecialDiscount(!modalSpecialDiscount);
    }

    const toggleInformation = () => {
        setModalInformation(!modalInformation);
    }
    //#endregion

    //#region Special discounts

        //#region SPECIAL DISCOUNTS MODEL
        const [idSpecialDiscount, setIdSpecialDiscount] = useState(0);
        const [listSpecialDiscounts, setListSpecialDiscounts] = useState([]);

        const [listExams, setListExams] = useState([]);
        const [listProfiles, setListProfiles] = useState([]);

        const [listSelectedExams, setListSelectedExams] = useState([]);
        const [listSelectedProfiles, setListSelectedProfiles] = useState([]);
        const [listTest, setListTest] = useState([]);

        const [loading, setLoading] = useState(false);

        const [validCode, setValidCode] = useState("");

        const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
            id_user: "",
            code: "",
            id_exam_profile: 0,
            percentage: 0,
            percentage_global: false,
            id_exam_include: 0,
            id_profile_include: 0,
            password: ""
        });

        const {
            id_user, code, id_exam_profile, percentage, percentage_global, id_exam_include, id_profile_include, password
        } = formValues;

        //#endregion

        //#region VALIDATIONS SPECIAL DISCOUNTS 
        const [validationsSpecialDiscount, setValidationsSpecialDiscount] = useState({
            global_special_discount_valid: false
        });
        //#endregion

        //#region SPECIAL DISCOUNTS EFFECT
        useEffect(() => {
            
            getSpecialDiscounts();
            getProfiles();
            getExams();
            
        }, []);
        
        //#endregion

        //#region SPECIAL DISCOUNTS METHODS
        const getSpecialDiscounts = async () => {
            let listTmp = await handleRequest("GET", "SpecialDiscount/List", "Descuentos especiales");

            ////console.log(listTmp);

            if (listTmp !== null && listTmp.length > 0) {
                
                let list = [];
                
                listTmp.forEach(obj => {
                    obj.visibility = false;
                    list.push(obj);                    
                });

                setListSpecialDiscounts(list);
            }
        }

        const getProfiles = async () => {
            let listTmp = await handleRequest("GET", "Profiles", "Perfiles");

            if (listTmp !== null && listTmp.length > 0) {
                let list = [];

                listTmp.forEach(obj => {
                    list.push({
                        label: obj.code+" "+obj.name,
                        value: obj.id_profile
                    });                                    
                });

                setListProfiles(list);
            }
        }

        const getExams = async () => {
            let listTmp = await handleRequest("GET", "Exam/List?show_also_micro=true", "Exámenes");

            if (listTmp !== null) {
                let list = [];

                listTmp.results.forEach(obj => {
                    list.push({
                        label: "( "+obj.code+" ) "+obj.name,
                        value: obj.id_exam
                    });
                });

                setListExams(list);
            }
        }   

        const handleExamsIncludes = ({ label, value}) => {
            handleUpdateValues({
                ...formValues,
                id_exam_include: value
            });

            let listSelectedTest = listSelectedExams;

            let findSelectedExam = listSelectedTest.find(x => x.id_exam === value);
            let findExam = listSelectedTest.find(x => x.id_exam === value);

            if (findSelectedExam) {
                findSelectedExam.is_deleted = false;
            }
            else if (!findExam) {
                listSelectedTest.push({
                    id_special_discount_test: null,
                    id_exam: value,
                    id_profile: null,
                    name: label,
                    percentage: 0,
                    valid_percentage: true,
                    is_deleted: false
                });
            }

            let listTmpExams = listExams;
            listTmpExams = listTmpExams.filter(x => x.value !== value);

            setListExams(listTmpExams);
            setListSelectedExams(listSelectedTest);
        }

        const handleProfileIncludes = ({label, value}) => {
            handleUpdateValues({
                ...formValues,
                id_profile_include: value
            });

            let listSelectedTest = listSelectedProfiles;

            let findProfileSelected = listSelectedTest.find(x => x.id_profile === value);
            let findProfile = listSelectedTest.find(x => x.id_profile === value);

            if (findProfileSelected) {
                findProfileSelected.is_deleted = false;
            }
            else if (!findProfile) {
                listSelectedTest.push({
                    id_special_discount_test: null,
                    id_exam: null,
                    id_profile: value,
                    name: label,
                    percentage: 0,
                    valid_percentage: true,
                    is_deleted: false
                });
            }

            let listTmpProfiles = listProfiles;
            listTmpProfiles = listTmpProfiles.filter(x => x.value !== value);

            setListProfiles(listTmpProfiles);
            setListSelectedProfiles(listSelectedTest);
        }

        const handleInputPercentageIndividual = (id, percentage, type) => {

            if (type === "Profile") {
                let auxListProfiles = listSelectedProfiles;

                auxListProfiles.map(obj => {
                    if (obj.id_profile === parseInt(id)) {
                        obj.percentage = percentage;

                        if (percentage === "" || percentage === "0") {
                            obj.valid_percentage = true;
                        }
                        else{
                            obj.valid_percentage = false;
                        }
                    }
                    return obj;
                });

                setListSelectedProfiles([...auxListProfiles]);
            }
            else if (type === "Exam") {
                let auxListExams = listSelectedExams;

                auxListExams.map(obj => {
                    if (obj.id_exam ===  parseInt(id)) {
                        obj.percentage = percentage;

                        if (percentage === "" || percentage === "0") {
                            obj.valid_percentage = true;
                        }
                        else {
                            obj.valid_percentage = false;
                        }
                    }

                    return obj;
                });

                setListSelectedExams([...auxListExams]);
            }
        }

        const handleChangeTypeDiscount = (e) => {
            //handleInputChange(e);

            handleUpdateValues({
                ...formValues,
                percentage: 0,
                percentage_global: e.target.checked
            });

            let listTmpSelectedExams = listSelectedExams;
            let listTmpSelectedProfiles = listSelectedProfiles;

            listTmpSelectedExams.forEach(obj => {
                obj.percentage = 0;
                obj.valid_percentage = true;
            });

            listTmpSelectedProfiles.forEach(obj => {
                obj.percentage = 0;
                obj.valid_percentage = true;
            });

            setListSelectedExams([...listTmpSelectedExams]);
            setListSelectedProfiles([...listSelectedProfiles]);
        }

        const handleCreate = () => {
            handleUpdateValues({
                id_user: "",
                code: "",
                id_exam_profile: 0,
                percentage: 0,
                percentage_global: false,
                id_exam_include: 0,
                id_profile_include: 0
            });

            setListTest([]);
            setMethodSpecialDiscount("create");
            setModalTitle("Crear descuento especial");
            toggleSpecialDiscount();
            setListSelectedProfiles([]);
            setListSelectedExams([]);
        }

        const saveCreate = async (e) => {
            e.preventDefault();
            setLoading(true);

            if (validationSpecialDiscount()) {
                setLoading(false);
                return;
            }

            let list_test = [];

            list_test = [
                ...listSelectedExams,
                ...listSelectedProfiles
            ];

            
            const body = {
                percentage: formValues.percentage,
                percentage_global: !formValues.percentage_global,
                ExamProfileInclude: list_test
            };

            let listTmp = await handleRequest("POST", "SpecialDiscount/Create", "Crear descuento especial", body);

            if (listTmp !== null) {
                getSpecialDiscounts();
                toggleSpecialDiscount();
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }

        const handleRemoveTest = (id, type) => {
            if (type === "Exam") {
                
                let list_exam_tmp = listSelectedExams;
                let list_tmp = listExams;

                let objectExam = {};
                let list = [];

                list_exam_tmp.forEach(obj => {
                    if (obj.id_exam === parseInt(id)) {
                        
                        objectExam = obj;                        
                        obj.is_deleted = true;
                    }

                    list.push(obj);
                });

                if (objectExam.id_exam !== undefined) {
                    list_tmp.push({
                        label: objectExam.name,
                        value: objectExam.id_exam
                    });
                }

                setListSelectedExams([...list]);
                setListExams(list_tmp);
            }
            else if (type === "Profile") {
                let list_profile_tmp = listSelectedProfiles;
                let list_tmp = listProfiles;

                let objectProfile = {};
                let list = [];

                list_profile_tmp.forEach(obj => {
                    if (obj.id_profile === parseInt(id)) {
                        objectProfile = obj;
                        obj.is_deleted = true;
                    }


                    list.push(obj);
                });

                if (objectProfile.id_profile !== undefined) {
                    list_tmp.push({
                        label: objectProfile.name,
                        value: objectProfile.id_profile
                    });
                }

                setListSelectedProfiles([...list]);
                setListProfiles(list_tmp);
            }
        }

        const handleAuthorized = (id_special_discount) => {
            setMethodSpecialDiscount("auth");
            setIdSpecialDiscount(id_special_discount);
            toggleSpecialDiscount();
            setModalTitle("Autorizar descuento especial");
        }

        const saveAuthorized = async (e) => {
            e.preventDefault();

            setLoading(true);

            let listTmp = await handleRequest('GET', `SpecialDiscount/AuthorizedSpecialDiscount/${idSpecialDiscount}`);

            if (listTmp !== null) {
                getSpecialDiscounts();
                toggleSpecialDiscount();
                createSweet("info", "info", "Se autorizo con exito!");
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }

        const handleUpdate = (id_special_discount, type = "edit") => {
            setIdSpecialDiscount(id_special_discount);

            if (type === "edit") {
                setMethodSpecialDiscount("update");
                setModalTitle('Editar descuento especial');
            }
            else {
                setMethodSpecialDiscount("view");
                setModalTitle('Visualizar descuento especial');
            }

            let special_discount = listSpecialDiscounts.find(x => x.id_special_discount === parseInt(id_special_discount));
            
            handleUpdateValues({
                id_user: "",
                code: "",
                id_exam_profile: 0,
                percentage: (special_discount.percentage !== null && special_discount.percentage > 0) ? special_discount.percentage : 0,
                percentage_global: (special_discount.percentage !== null && special_discount.percentage > 0) ? false : true,
                id_exam_include: 0,
                id_profile_include: 0,
                password: ""
            });

            let listTmpExams = listExams;
            let listTmpProfiles = listProfiles;

            let exams_selected = [];
            let profiles_selected = [];

            if (special_discount.listTest.length > 0) {
                
                let filterExams = special_discount.listTest.filter(x => x.id_exam !== null);
                let filterProfiles = special_discount.listTest.filter(x => x.id_profile !== null);

                filterExams.forEach(obj => {

                    listTmpExams = listTmpExams.filter(x => x.value !== obj.id_exam);

                    exams_selected.push({
                        id_special_discount_test: obj.id_special_discount_test,
                        id_exam: obj.id_exam,
                        id_profile: null,
                        name: obj.name_test,
                        percentage: obj.percentage,
                        valid_percentage: obj.percentage === 0 ? true : false,
                        is_deleted: obj.is_deleted
                    });
                });

                filterProfiles.forEach(obj => {

                    listTmpProfiles = listTmpProfiles.filter(x => x.value !== obj.id_profile);

                    profiles_selected.push({
                        id_special_discount_test: obj.id_special_discount_test,
                        id_exam: null,
                        id_profile: obj.id_profile,
                        name: obj.name_test,
                        percentage: obj.percentage,
                        valid_percentage: obj.percentage === 0 ? true : false,
                        is_deleted: obj.is_deleted
                    });
                });
            }                  

            setListExams(listTmpExams);
            setListProfiles(listTmpProfiles);

            setListSelectedExams(exams_selected);
            setListSelectedProfiles(profiles_selected);

            toggleSpecialDiscount();
        }

        const saveUpdate = async (e) => {
            e.preventDefault();

            setLoading(true);

            if (validationSpecialDiscount()) {
                setLoading(false);
                return;
            }

            let list_test = [];

            list_test = [
                ...listSelectedExams,
                ...listSelectedProfiles
            ];

            const body = {
                id_special_discount: idSpecialDiscount,
                percentage: formValues.percentage,
                percentage_global: !formValues.percentage_global,
                ExamProfileInclude: list_test
            };

            let listTmp = await handleRequest("PUT", "SpecialDiscount/Update", "Editar descuento especial", body);

            if (listTmp !== null) {
                setIdSpecialDiscount(0);
                getSpecialDiscounts();
                toggleSpecialDiscount();
                setLoading(false);
                createSweet("info", "info", "Se actualizo con exito!");
            }
            else {
                setLoading(false);
            }
        }

        const handleDelete = (id_special_discount) => {
            setMethodSpecialDiscount("delete");
            setIdSpecialDiscount(id_special_discount);
            toggleSpecialDiscount();
            setModalTitle("Eliminar descuento especial");
        }

        const saveDelete = async (e) => {
            e.preventDefault();
            setLoading(false);

            let listTmp = handleRequest("DELETE", `SpecialDiscount/Delete/${idSpecialDiscount}`, "Eliminar descuento");

            if (listTmp !== null) {
                setIdSpecialDiscount(0);
                getSpecialDiscounts();
                toggleSpecialDiscount();
                createSweet("info", "info", "Se elimino con exito!");
            }
        }
        const [validName, setValidName] = useState("")
        const [valid_percentage, setValid_percentage] = useState("")

        const handleShowValidCode = (id_special_discount) => {
            let findSpecialDiscount = listSpecialDiscounts.find(x => x.id_special_discount === parseInt(id_special_discount));
            //console.log(findSpecialDiscount);

            if (findSpecialDiscount) {
                setValidCode("COD-00"+findSpecialDiscount.id_special_discount);
                setValidName(findSpecialDiscount.name_user_authorized)
                setValid_percentage(findSpecialDiscount.percentage)
                toggleInformation();
                setModalTitle("Validación");
                setIdSpecialDiscount(id_special_discount);
            }
        }
   ////gatos
   
   
   const [dataCode, setDataCode] = useState("")
   
        const getInformationSpecialDiscount = async () => {
            const body = {
                id_user: 0,
                password: formValues.password,
                id_special_discount: idSpecialDiscount
            };

            let listTmp = await handleRequest("POST", "SpecialDiscount/GetInformation", "Liberar información", body);
          
            if (listTmp !== null) { 
                if (listTmp === 0) {
                    createSweet("error", "error", "No cuenta con los privilegios para visualizar el código");
                    
                }
                else {
                    let listDiscounts = listSpecialDiscounts;
                    listDiscounts.map(obj => {

                        if (obj.id_special_discount === listTmp.id_special_discount) {
                            obj.visibility = true;
                            obj.code = listTmp.code;
                        }
                        return obj;
                    });
    
                    setListSpecialDiscounts(listDiscounts);
                    toggleInformation();
                    setIdSpecialDiscount(0);
                    createSweet("info", "info", "La informacion quedo liberada");
                }
            }
        }

        //#endregion

    //#endregion

    //#region GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);
        //console.log(response);

        if (response.code === 200) {
            //console.log(response.data);
            return response.data;

        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    //#endregion

    //#region VALIDATIONS METHODS
    const validationSpecialDiscount = () => {
        let statusValidation = false;
        let newValidations = {};

        if (!formValues.percentage_global && (formValues.percentage === "0" || formValues.percentage === 0)) {
            newValidations = {
                ...newValidations,
                global_special_discount_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                global_special_discount_valid: false
            };
        }

        if (listSelectedProfiles.length > 0) {
            let statusSelectedProfile = false;

            listSelectedProfiles.forEach(obj => {
                if (formValues.percentage_global && (obj.valid_percentage && obj.is_deleted === false)) {
                    statusSelectedProfile = true;
                }
            });

            if (statusSelectedProfile) {
                
                statusValidation = true;
            }
        }

        if (listSelectedExams.length > 0) {
            let statusSelectedExams = false;

            listSelectedExams.forEach(obj => {
                if (formValues.percentage_global && (obj.valid_percentage && obj.is_deleted === false)) {
                    statusSelectedExams = true;
                }
            });

            if (statusSelectedExams) {
                statusValidation = true;
            }
        }

        setValidationsSpecialDiscount(newValidations);

        return statusValidation;
    }
    //#endregion

    return {
        //MODALS
        modalTitle,
        modalInformation, toggleInformation, methodInformation, 
        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount,
        
        //SPECIAL DISCOUNT MODEL        
        loading, listSpecialDiscounts, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
        id_user, code, id_exam_profile, percentage, percentage_global, 
        id_exam_include, id_profile_include, password,
        validationsSpecialDiscount, idSpecialDiscount,
        validCode,

        //SPECIAL DISCOUNTS METHODS
        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes,
        handleInputPercentageIndividual, 
        handleCreate, saveCreate,
        handleRemoveTest,
        handleAuthorized, saveAuthorized,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleShowValidCode, getInformationSpecialDiscount,dataCode,validName,valid_percentage
    }
}
