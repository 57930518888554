import React from 'react'
import { Button, Card, CardBody,Input } from 'reactstrap';
import { ModalProcess } from '../../../components/processes/ModalProcess';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useProcess } from '../../../hooks/sections/useProcess';

export const IndexProcess = () => {

    const {
        processes, method, validaciones,
        name, code,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete ,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchSection
    
    } = useProcess();

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de procesos
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSection} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        {
                            processes.length === 0 ?
                            <div style={{ textAlign: "-webkit-center" }}>
                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "26%" }} alt="" />   <br />                                         
                            <br /> <p className="f-18">{"No se a registrado ningún proceso aún."}</p>
                            </div>
                            :
                             <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={true}
                            cabeceras={["Nombre", "Código", "¿Es operativo?"]}
                            items={processes}
                            {...{ handleUpdate, handleDelete }}
                             nextPage={nextPage}
                             previousPage={previousPage}
                             totalPageCount={totalPageCount}
                             currentPage={currentPage}
                             goToPage={goToPage}

                        /> 
                        }
                     
                    </div>
                </CardBody>
            </Card>
            <ModalProcess
                {
                ...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, code,
                    handleInputChange, loading,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </>
    )
}

  