import React, { Fragment } from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Container, FormFeedback, FormGroup, FormText, Input, Label, Row, Table,CardHeader } from 'reactstrap';
import { useConfigExams } from '../../../hooks/maquilaInterna/useConfigExams';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import './styleConfigExams.css';


export const IndexConfigExams = () => {
  
    const {        
        branchInformation, examsMaquila, branchList, 
         
        //EXAMS MODEL
        searchExamState, validationExams,
        //EXAMS METHODS
        handleSelectExamChange, handleInputChange, handleDelete,
        handleSaveOrUpdate
    } = useConfigExams();

    const {
        validBranch, listBranch
    } = validationExams;


    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col xs="12" sm="12" md="3" lg="3" xl="3">
                        
                        <Col sm="12">
                            <Card className='shadow'>
                                <CardHeader className='p-2 bg-secondary' >
                                    <label className=''>Origen</label>
                                    
                                </CardHeader>
                                <Col sm="12">
                                    <i className="icofont icofont-building-alt f-30"></i> <br />
                                    <h6 className='f-14'>{ branchInformation.name_branch }</h6>
                                    <br />
                                    <Col sm="12" md="12" lg="12" xl="12">
                                                <Label>Búsqueda de exámenes</Label>
                                                <SearchAsync
                                                    loincState={searchExamState}
                                                    name="id_search_exam"
                                                    method={handleSelectExamChange}
                                                    url="Profiles/SearchProfileExam"
                                                    maxLenght={3}
                                                />
                                                <FormText color='danger' hidden={!listBranch}>Debes seleccionar al menos un examen</FormText>
                                            </Col> <br />
                                            <Col sm="12" className='text-center'>
                                            <Button size='sm' color='primary'   outline onClick={handleSaveOrUpdate}>
                                                    Guardar
                                                </Button>

                                              
                                            </Col>
                                    </Col>

                            </Card>
                        </Col>




                            {/* <Card className='accordion'>
                            <i className="icofont icofont-building-alt f-30"></i>
                           
                           <h6 className='f-14'>{ branchInformation.name_branch }</h6>
                               <label className='accordion__link'>click <input type="checkbox" className='inputt' />  </label>
                               <div className='accordion__item' >
                                <div className='PP' >
                               
                                </div>
                               
                                    
                               </div>

                            </Card> */}
                       


                        
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="9" xl="9">
                        <Card className="shadow">
                            <CardHeader className="p-2 bg-secondary">
                                <label className="f-16 " >Estudios a maquilar</label>
                            </CardHeader>
                            <Col sm="12">
                            {
                                examsMaquila.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }} >
									<img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
								    <label className='f-w-600 f-14 badge badge-light-primary'>No se a registrado ningun estudio</label>
								</div>
                                :
                                <Col sm="12" md="12" lg="12" xl="12"> <br /> <br />
                                        <div className='table-responsive' style={{ textAlign: "-webkit-center" }}>
                                            <FormText color='danger' hidden={!validBranch}>Falta seleccionar una sucursal</FormText>
                                            <Table className="table table-xs">
                                                <thead lassName=" text-center">
                                                    <tr className='border-bottom-info text-center'>
                                                        <th scope="col"><b>Código</b></th>
                                                        <th scope="col"><b>Descripción</b></th>
                                                        <th scope="col"><b>Sucursal a maquilar</b></th>
                                                        <th scope="col"><b>Acciones</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="f-13 text-center">
                                                    {
                                                        examsMaquila.length > 0 && examsMaquila.map((obj, indexBody) => {
                                                            return (
                                                                <tr key={`tr-${indexBody}`} className=" border-bottom-info">
                                                                    <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.code}</td>
                                                                    <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{ obj.name }</td>
                                                                    <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                        <FormGroup> <br />
                                                                            <Input type='select' className="form-control form-control-sm input-air-primary" value={obj.id_branch_destination} invalid={obj.valid} onChange={(e) => handleInputChange(e, obj.id_exam)}>
                                                                                <option value={'0'}>Selecciona una opción</option>
                                                                                {
                                                                                    branchList.length > 0 && branchList.map((branch, branchIndex) => {
                                                                                        return ( 
                                                                                            <option value={branch.id_branch} key={`optionIndex-${branchIndex}`}>{branch.name}</option>                                                                                        
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                            <FormFeedback>Selecciona una opción</FormFeedback>
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                        <ButtonGroup className='btn-sm'>
                                                                            <Button size='xs' outline color='danger' onClick={() => handleDelete(obj.id_exam)}><i className="icofont icofont-delete-alt"></i></Button>
                                                                        </ButtonGroup>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table> <br /> <br /> <br />
                                        </div> 
                                    </Col>
                                
                            }
                        </Col>
                        </Card>
                       
                    </Col>
                </Row>





                {/* <Row>
                    <Col sm="12" md="3" lg="3" xl="3">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12">
                                <Card>
                                    <CardBody className='p-1'>
                                        <div className='ribbon ribbon-clip ribbon-primary'>
                                            Origen
                                        </div>
                                        <Row className='mt-3'>
                                            <Col className='col-md-3 offset-md-4 text-center'>
                                                <div className='bg-primary shadow-showcase' style={{ borderRadius: '25% 25%' }}>
                                                    <i className="icofont icofont-building-alt f-30"></i>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <h6 className='f-14'>{ branchInformation.name_branch }</h6>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='pl-1 pr-1 pb-3'>
                                            <Col sm="12" md="12" lg="12" xl="12">
                                                <Label>Búsqueda de exámenes</Label>
                                                <SearchAsync
                                                    loincState={searchExamState}
                                                    name="id_search_exam"
                                                    method={handleSelectExamChange}
                                                    url="Profiles/SearchProfileExam"
                                                    maxLenght={3}
                                                />
                                                <FormText color='danger' hidden={!listBranch}>Debes seleccionar al menos un examen</FormText>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>    
                            </Col>
                        </Row>  
                        <Row className='mt-2'>
                            <Col sm="12" md="12">
                                <Card>
                                    <CardBody className='p-1'>
                                        <div className='ribbon ribbon-clip ribbon-danger'>
                                            Acciones
                                        </div>
                                        <Row className='mt-5 mb-2'>
                                            <Col sm="12" className='text-center'>
                                                <Button size='sm' color='primary' outline onClick={handleSaveOrUpdate}>
                                                    Guardar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>                      
                    </Col>
                    <Col sm="12" md="9" lg="9" xl="9">
                        <Card>
                            <CardBody className='p-1'>
                                <div className='ribbon ribbon-clip ribbon-primary'>
                                    Listado de pruebas a maquilar
                                </div>
                                <Row className='mt-5 p-2'>
                                    
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </Container>
        </Fragment>
    )
    
}
