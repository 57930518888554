import React from 'react'
import './styleImage.css';


export const ViewsImagen = ({item}) => {
  return (
  
        <img 
        style={{  width: "100%"}}
        src={item}
        ></img>
  )
}

