import React, { useState } from 'react';
import { Button, Col, ListGroup, ListGroupItem, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

export const ModalViewView = (props) => {

    const {
        toggleRol, listRolesData
    } = props;

    const [verticalTab, setVerticalTab] = useState('1')

    return (
        <>
            <ModalBody>
                <Row>

                    <Col sm="4" md="4" lg="4">
                        <p className='text-center'><b>Módulo</b></p>
                        <Nav className='nav flex-column nav-pills'>
                        {
                            listRolesData.length > 0 && listRolesData.map((obj, index) => {
                                let tabId = (index + 1);
                                tabId = tabId.toString();
                                return (
                                    <NavItem key={`navItem-${index}`}>
                                        <NavLink href='#javascript' className={verticalTab === tabId ? 'active' : ''} onClick={() => setVerticalTab(tabId)}>
                                            {obj.name}
                                        </NavLink>
                                    </NavItem>
                                )
                            })
                        }
                        </Nav>                        
                    </Col>
                    <Col sm="8" md="8" lg="8" xl="8">
                        <p className='text-center'><b>Vistas</b></p>
                        <TabContent activeTab={verticalTab} className="m-10">
                            {
                                listRolesData.length > 0 && listRolesData.map((obj, index) => {
                                    let tabId = (index + 1);
                                    tabId = tabId.toString();

                                    return (
                                        <TabPane className={verticalTab === tabId ? 'fade show' : ''} tabId={tabId} key={`list-${index}`}>                                                
                                            <ListGroup >
                                            {
                                                obj.views.length > 0 && obj.views.map((view, keyView) => {
                                                    return (
                                                        <ListGroupItem style={{ verticalAlign: "middle" }} key={`sublist-${keyView}`}>
                                                            <i className="fa fa-angle-double-right text-primary"></i> {view.name}
                                                        </ListGroupItem>
                                                    )
                                                })
                                            }
                                            </ListGroup>
                                        </TabPane>
                                    )
                                })
                            }
                        </TabContent>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' className='btn-air-light' outline color='light' type='button' onClick={toggleRol}>
                    Cerrar
                </Button>
            </ModalFooter>
        </>
    )
}
