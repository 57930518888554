import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const FormBrand = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, button
    } = props;

    const {
        name_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row> 
                    <Col sm="1"></Col>
                    <Col md="10">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="text"
                                //placeholder="Nombre de la marca"
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color='primary' type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    {"Cancelar"}
                </Button>
            </ModalFooter>
        </Form>
    )
};
