import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, CardHeader } from 'reactstrap';
import { IndexSampling as ComponentSampling } from '../../samplings/IndexSampling';
import { IndexSamplingItems } from '../../samplings/IndexSamplingItems';
import { IndexUser } from '../users/IndexUser';
import { IndexAreas } from '../../areas/IndexAreas';
import { IndexRooms } from '../../areas/IndexRooms';
import { IndexDepartaments } from '../../areas/IndexDepartaments';

export const IndexSampling = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Listado de insumos de toma de muestra" /> */}
            <Container className="mt--7 p-l-0 p-r-0" fluid>
                <Row>
                    <Col sm="3" className="tabs-responsive-side">
                        <Col sm="12">
                            <Card className='shadow'>
                                <CardHeader className="p-3 bg-primary">
                                    <h6>Indicaciones</h6>
                                </CardHeader>
                                <nav className="flex-column nav-pills border-tab nav-left">
                                    <NavItem>
                                        <NavLink
                                            href="#insumos"
                                            className={activeTab === "1" ? "active" : ""}
                                            onClick={() => toggleTab("1")}
                                        >
                                            <i className="icofont icofont-first-aid-alt"></i>&nbsp;
                                            {`Insumos`}
                                        </NavLink>
                                        <NavLink
                                            href="#tm"
                                            className={activeTab === "2" ? 'active' : ''}
                                            onClick={() => toggleTab("2")}
                                        >
                                            <i className="fa fa-building-o"></i>&nbsp;
                                            {`Toma de Muestra`}
                                        </NavLink>
                                        <NavLink
                                            href="#areas"
                                            className={activeTab === "4" ? 'active' : ''}
                                            onClick={() => toggleTab("4")}
                                        >
                                            <i className="icofont icofont-drug-pack"></i>&nbsp;
                                            {`Areás`}
                                        </NavLink>
                                        <NavLink
                                            href="#departamentos"
                                            className={activeTab === "5" ? 'active' : ''}
                                            onClick={() => toggleTab("5")}
                                        >
                                            <i className="icofont icofont-stretcher"></i>&nbsp;
                                            {`Departamentos`}
                                        </NavLink>
                                        <NavLink
                                            href="#cuartos"
                                            className={activeTab === "6" ? 'active' : ''}
                                            onClick={() => toggleTab("6")}
                                        >
                                            <i className="icofont icofont-heartbeat"></i>&nbsp;
                                            {`Cuartos`}
                                        </NavLink>
                                        <NavLink
                                            href="#cuartos"
                                            className={activeTab === "3" ? 'active' : ''}
                                            onClick={() => toggleTab("3")}
                                        >
                                            <i className="icofont icofont-doctor-alt"></i>&nbsp;
                                            {`Usuarios`}
                                        </NavLink>
                                    </NavItem>
                                </nav>

                            </Card>
                        </Col>
                    </Col>
                    <Col sm="9" className='p-r-0 p-l-0'>
                        <TabContent activeTab={activeTab}>
                            <TabPane className="fade show" tabId="1">
                                <IndexSamplingItems
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="2">
                                <ComponentSampling
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="3">
                                <IndexUser
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="4">
                                <IndexAreas
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="5">
                                <IndexDepartaments
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="6">
                                <IndexRooms
                                    {...{ ...props, activeTab }}
                                />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>



            </Container>
        </Fragment>
    )
}
