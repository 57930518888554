import React from 'react'
import Select from 'react-select';
import { Modal, ModalBody, ModalHeader, Row, Col, Label, Input, Table, ModalFooter, Button, FormText } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';

export const ModalDevolution = (props) => {

    const {
        toggleDevolutions, modalTitle,
		devolutionsState, modalDevolutions, number_format, listReturnReason, handleChangeReturnReason, validateDevolutions,
        saveReturnReason,canceldevolution,showMessageW,loading
    } = props;

    const {
        devolutionValid, testDevolution
    } = validateDevolutions;

    let totalDevolution = 0;
    let versionLayout = localStorage.getItem('layout_version');

    return (
        <Modal isOpen={modalDevolutions} toggle={toggleDevolutions} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <Label>Selecciona un motivo de devolución <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Select
							classNamePrefix="select"
							name="id_return_reason"
							value={devolutionsState.id_return_reason}
							options={listReturnReason}
							placeholder={PlaceHolderOption}
							isClearable={true}
                            onChange={(e) => handleChangeReturnReason(e)}
							theme={
								(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary25: (versionLayout === 'dark-only') ? '#4c9aff' : "#deebff"
									}
								})
							}
						/>
                        <FormText color='danger' hidden={!devolutionValid}>Debes seleccionar una opción</FormText>
                    </Col>
                </Row>
                <Row className='m-t-25'>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <div className='table-responsive'>
                            <Table className='table-sm'>
                                <thead>
                                    <tr className='text-center'>
                                        <th><b>Examen / Perfil</b></th>
                                        <th><b>Monto</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devolutionsState.listTestDevolution.length > 0
                                        ?
                                            devolutionsState.listTestDevolution.map( (d, dIndex) => {

                                                totalDevolution += (d.amount + d.extra + d.iva);
                                                return (
                                                    <tr key={d.id_work_order_exam === null ? d.id_work_order_profile : d.id_work_order_exam}>
                                                        <td>{ d.code+" "+d.name }</td>
                                                        <td className='text-right'><b>$ { `${number_format(d.amount, 2)}` }</b></td>
                                                    </tr>
                                                )
                                            })
                                        :
                                            <tr>
                                                <td className='text-center p-1' colSpan={2}>
                                                    <p>No se ha registrado ninguna devolución</p>
                                                    <p className='text-danger'><i className="fa fa-times-circle"></i></p>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className='text-right'><b>Devolución con I.V.A: </b></td>
                                        <td className='text-right'><b>${Math.round(totalDevolution)}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                    size="sm"
                    outline color="primary"
                    type="button"
                    onClick={saveReturnReason}
                >
                    {'Confirmar devolución'}
                </Button>
                <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={canceldevolution}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
