import React from 'react'
import { Fragment } from 'react'
import { Container, Row, Col, Card, CardHeader, Button, CardBody, Collapse, Form, Label, FormGroup, Input } from 'reactstrap'
import { ModalAnalizerInterfaces } from '../../../components/analyzerInterfacex/ModalAnalizerInterfaces'
import { TableStriped } from '../../../components/tables/TableStriped'
import { useAnalyzerInterface } from '../../../hooks/analyzerInterface/useAnalyzerInterface'

export const IndexAnalyzerInterface = () => {

    const {
        filterAnalyzer, setFilterAnalyzer,
        handleChangeBranch,
        listMachineModels, listBranch,
        analyzerInterface,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleGetAnalyzer,
        loading, handleSearchAppCode,
        toggle, modal, handleUpdate,
        appCode, handleInputChange, saveUpdate
    } = useAnalyzerInterface();

    ////console.log(analyzerInterface);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="3">
                        <Row className='position-sticky' style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className='bg-primary'>
                                        <h5 className='mb-0'>
                                            <Button color='link pl-0' data-toggle="collapse" onClick={() => setFilterAnalyzer(!filterAnalyzer)} data-target="#collapseicon" aria-controls="collapseicon">
                                                <i className="fa fa-filter"></i>
                                                &nbsp;&nbsp;&nbsp;&nbsp; Filtros
                                            </Button>
                                        </h5>
                                    </CardHeader>
                                    <CardBody className='p-3'>
                                        <Collapse isOpen={filterAnalyzer}>
                                            <Row>
                                                <Col sm="12">
                                                    <Form>
                                                        <Row>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <FormGroup>
                                                                    <Label>Sucursal</Label>
                                                                    <select className='form-control digits input-air-primary form-control-sm' name='selectBranch' onChange={(e) => handleChangeBranch(e)}>
                                                                        <option value={0}>Selecciona una sucursal</option>
                                                                        {
                                                                            listBranch.length > 0 && listBranch.map(obj => {
                                                                                return (
                                                                                    <option key={obj.label} value={obj.id_branch}>{obj.label}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <FormGroup>
                                                                    <Label>Analizador</Label>
                                                                    <select className='form-control digits input-air-primary form-control-sm' name='selectAnalyzer' onChange={(e) => handleChangeBranch(e)}>
                                                                        <option value={0}>Selecciona un analizador</option>
                                                                        {
                                                                            listMachineModels.length > 0 && listMachineModels.map(obj => {
                                                                                return (
                                                                                    <option key={obj.id_machine_model} value={obj.id_machine_model}>{obj.label}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <Button outline color='primary' block={true} type={"button"} className={loading ? "disabled progress-bar-animated progress-bar-striped text-center" : "text-center"} onClick={handleGetAnalyzer}>
                                                                    Filtrar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9">
                        <Card>
                            <div className='ribbon ribbon-clip ribbon-primary'>
                                Tabla de App codes para interfaz
                            </div>
                            <br />
                            <Row>
                                <Col className='offset-8 col-4 pt-1 pb-2'>
                                    <Input placeholder='Búsqueda...' className='form-control' onChange={handleSearchAppCode} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12" xl="12">
                                    {
                                        analyzerInterface.length === 0
                                            ?
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "60%" }} alt="" />
                                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >0 resultados para tu busqueda</p>


                                                {/* <img className='img-fluid' src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "38%" }} alt="" /> */}
                                            </div>
                                            :
                                            <div className='table-responsive'>
                                                <br />
                                                <TableStriped
                                                    notMaped={[
                                                        "visibleTable", "id_brand", "id_info_analyte"
                                                    ]}
                                                    methodsActions={true}
                                                    cabeceras={["APPCODE", "Nombre corto", "Examen", "Resultado", "T. Resultado"]}
                                                    items={analyzerInterface}
                                                    {...{
                                                        handleUpdate
                                                    }}
                                                    nextPage={nextPage}
                                                    previousPage={previousPage}
                                                    totalPageCount={totalPageCount}
                                                    currentPage={currentPage}
                                                    goToPage={goToPage}
                                                />
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalAnalizerInterfaces
                {
                ...{
                    modal, toggle, loading,
                    handleInputChange, appCode, saveUpdate
                }
                }
            />
        </Fragment>
    )
}
