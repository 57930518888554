import React, { Fragment , useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row ,Collapse} from 'reactstrap'
import { useUsedDiscounts } from '../../hooks/discounts/useUsedDiscounts'
import Breadcrumbs from '../../layout/breadcrumb'
import Datetime from 'react-datetime';
import { TableStriped } from '../../components/tables/TableStriped';

export const IndexUsedDiscounts = () => {

    const {
        discounts, getWorkOrdersDiscounts, date_from, date_to, type_discount, nim, handlePickerValues, handleInputChange,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount

    } = useUsedDiscounts();

    const [filterSections, setFilterSections] = useState(true);



    return (
        <Fragment>
            {/* <Breadcrumbs parent="Descuentos" title="Listado de descuentos utlizados" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="3">
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12">
                            <Card>
                                    <CardHeader className=' bg-primary '>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterSections(!filterSections)}
                                                data-target="#collapseicon" aria-expanded={filterSections} aria-controls="collapseicon"><i className="fa fa-filter"></i>&nbsp; &nbsp; &nbsp; &nbsp; Filtro</Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filterSections}>
                                        <Col sm="12">  <br />
                                        <Form>
                                            <p>Selecciona Fecha a consultar</p>
                                            <Row>
                                                <Col sm="6">
                                                    <Row>
                                                        <Label className="col-12">Fecha inicial</Label>
                                                        <FormGroup className="col-md-12">
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: 'dd-mm-aaaa',
                                                                    name: "date_from",
                                                                    autoComplete: "false",
                                                                    className: "form-control digits input-air-primary form-control-sm",
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat={true}
                                                                value={date_from}
                                                                locale="es"
                                                                initialValue={new Date()}
                                                                onChange={(e) => handlePickerValues(e, "date_from")}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                                <Col sm="6">
                                                    <Row>
                                                        <Label className="col-12">Fecha final</Label>
                                                        <FormGroup className="col-md-12">
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: 'dd-mm-aaaa',
                                                                    name: "date_to",
                                                                    autoComplete: "false",
                                                                    className: "form-control digits input-air-primary form-control-sm",
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat={true}
                                                                value={date_to}
                                                                locale="es"
                                                                initialValue={new Date()}
                                                                onChange={(e) => handlePickerValues(e, "date_to")}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                                <Col sm="6">
                                                    <Row>
                                                        <Label className="col-12">Tipo de descuento</Label>
                                                        <FormGroup className="col-md-12">
                                                            <Input
                                                                className="form-control"
                                                                bsSize="sm"
                                                                type="select"
                                                                name="type_discount"
                                                                value={type_discount}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value={0}>Todos</option>
                                                                <option value={1}>Especial</option>
                                                                <option value={2}>Común</option>
                                                                <option value={3}>Convenio</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                                <Col sm="6">
                                                    <Row>
                                                        <Label className="col-12">NIM</Label>
                                                        <FormGroup className="col-md-12">
                                                            <Input
                                                                className="form-control"
                                                                bsSize="sm"
                                                                type="text"
                                                                name="nim"
                                                                placeholder="01012110150001"
                                                                value={nim}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{ textAlignLast: "center" }}>
                                                <Col sm="12">
                                                    <Button outline={true} block={true} type="button" onClick={getWorkOrdersDiscounts}>
                                                        Filtrar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        </Col> <br /> 
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9">
                        <Card>
                            <div className="ribbon ribbon-clip ribbon-primary">Listado de Descuentos Utilizados</div> <br />
                            <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchDiscount} />
                        </div>
                    </div>
                            <Col sm="12"> <br /> 
                            <div>
                                {
                                    discounts.length === 0 ? 
                                    <div style={{textAlign: "-webkit-center"  }}>
                                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "38%" }} alt="" />    
                                    <br /> <p className="f-18">{"No se ha registrado algún Descuento Utilizado aún."}</p>
                                    </div>
                                    :
                                    <div className="table-responsive"> <br />
                                        <TableStriped
                                        notMaped={["visibleTable", "id_exam", "id_profile", "name_exam", "name_profile",]}
                                        cabeceras={["Fecha", "Tipo descuento", "NIM", "Examen/Perfil", "Paciente", "% descuento", "Precio", "Descuento", "Precio descuento"]}
                                        methodsActions={false}
                                        items={discounts}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />

                                    </div>
                                }
                            </div>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
