import React, { Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useAgreement } from '../../../hooks/agreements/useAgreement';
import { ModalAgreement } from '../../../components/agreement/ModalAgreement';
import { ListCardAgreement } from '../../../components/cards/ListCardAgreement';
import { ModalViewProfiles } from '../../../components/cards/ModalViewProfiles';
import { ModalLimitCredit } from '../../../components/cards/ModalLimitCredit';
import './StyleAgreementCard.css';


export const IndexAgreements = () => {

    const {
        modal, modalTitle, toggle, method, loading,
        name_company, contract_number, validity, status,
        navigateNewAgreement, navigateUpdate, agreements,
        handleDelete, saveDelete,
        handleUpdateStatus, saveUpdateStatus, handleListProfile,
        toggle2, modal2, infoData,
        toggle3, modal3, updateCategoryLimit, modalTitle1,
        percentage_credit_limit, handleInputChange,
        handleUpdateCategory, credit_limit,

        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchAgreement,downdloadExcel

    } = useAgreement();

    //console.log(agreements);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={() => navigateNewAgreement()}>
                                        <span className="fa fa-plus-circle"></span>
                                    </Button>
                                </div>
                                <Row className=''>
                                        <Col className='offset-md-9 col-md-3'>
                                            <input className='form-control form-control-sm search' placeholder='Búscar...' onChange={(e) => handleSearchAgreement(e)} />
                                        </Col>
                                    </Row>
                        <Row>
                            <Col md="12" className='p-1 '>
                                <CardBody>
                                   
                                    {
                                        agreements.length === 0
                                        ?
                                            <div style={{ textAlign: "-webkit-center" }}> 
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}  style={{ width: "30%" }} alt="" />   <br /> 

                                            
                                                <br />
                                                <p className='f-18 txt-secondary'>{"Ningún convenio registrado."}</p>
                                            </div>
                                        :
                                            <ListCardAgreement
                                                methodsActions={true}
                                                items={agreements}
                                                {...{ handleDelete, handleUpdateStatus, navigateUpdate, handleListProfile, handleUpdateCategory,handleSearchAgreement, downdloadExcel}}                                            
                                                nextPage={nextPage}
                                                previousPage={previousPage}
                                                totalPageCount={totalPageCount}
                                                currentPage={currentPage}
                                                goToPage={goToPage}
                                            />
                                    }
                                </CardBody>                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalAgreement
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    name_company, contract_number, validity, status,
                    saveDelete, saveUpdateStatus
                }
                }
            />

            <ModalViewProfiles
                {
                ...{
                    modal2, modalTitle, toggle2, method, loading, infoData

                }
                }
            />

            <ModalLimitCredit
                {
                ...{
                    modal3, modalTitle1, toggle3, method, loading, updateCategoryLimit, percentage_credit_limit, handleInputChange, agreements,
                    credit_limit

                }
                }
            />
        </Fragment>
    )
}
