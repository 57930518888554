import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteBranch } from './DeleteBranch';
import { FormBranch } from './FormBranch';

export const ModalBranch = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        states, municipalities, commercialLines,
        handleInputChange, validaciones,
        id_commercial_line, name, code, number_phone, email, id_state, id_municipality,
        address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma,
        imageLogo, imageFirm, imageBackground,
        saveCreate, saveDelete, saveUpdate,
        handleChangeState,
        previewLogo, previewFirm, previewBackground, imageHandler,roleUser_responsible
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} className={(method !== "delete") ? "modal-lg" : ""} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormBranch
                        button="Guardar"
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, states, municipalities, commercialLines, handleChangeState, previewLogo, previewFirm, previewBackground, imageHandler,roleUser_responsible }}
                    />
                    : (method === "update")

                        ?
                        <FormBranch
                            button="Actualizar"
                            {...{
                                toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, states, municipalities, commercialLines, handleChangeState, previewLogo, previewFirm, previewBackground, imageHandler, id_commercial_line, name, code,
                                number_phone, email, id_state, id_municipality,
                                address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma, imageLogo, imageFirm, imageBackground,roleUser_responsible
                            }}
                        />
                        :
                        <DeleteBranch
                            {...{ toggle, loading, saveDelete, name, code }}
                        />
            }
        </Modal>
    )
}
