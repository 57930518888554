import React from 'react'
import { Modal, ModalHeader, Media, ModalBody, ModalFooter, Col, Button, Row } from 'reactstrap';

export const ModalConfirm = (props) => {

    const {
        modalRelease, toggleRelease, loadingRelease, authorize
    } = props;

    return (
        <Modal isOpen={modalRelease} toggle={toggleRelease} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            <ModalHeader toggle={toggleRelease} className="bg-primary">
                ADVERTENCIA
            </ModalHeader>
            <ModalBody>
                <h5>
                    Al aceptar, esta acción va a <b>AUTORIZAR</b> a los pacientes visibles.
                </h5>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="button" onClick={() => authorize()} size="sm" className={loadingRelease ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Aceptar
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggleRelease}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
