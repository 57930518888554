// export class ConfigDB {
// 	static data = {
// 		settings: {
// 			layout_type: 'ltr',
// 			sidebar: {
// 				type: 'compact-wrapper',
// 				body_type: 'sidebar-icon'
// 			},
// 			sidebar_setting: 'default-sidebar',
// 		},
// 		color: {
// 			layout_version: 'light',
// 			color: 'color-1',
// 			primary_color: '#7366ff',
// 			secondary_color: '#f73164',
// 			mix_background_layout: 'light-only',
// 		},
// 		router_animation: 'fadeIn'
// 	}
// }
// export default ConfigDB;

export class ConfigDB {
	static data = {
		"settings": {
			"layout_type": "ltr",
			"sidebar": {
				"type": "horizontal-wrapper enterprice-type"
			},
			"sidebar_setting": "default-sidebar"
		},
		"color": {
			// "layout_version": "dark-only",
			"layout_version": "light",
			"color": "color-1",
			"primary_color": "#46AFE5",
			"secondary_color": "#0079C7",
			"danger_color": "##ff0018",

			"after_color": "#204887",
			// "mix_background_layout": "dark-only"
			"mix_background_layout": "light-only"
		},
		"router_animation": "fadebottom"
	}
}
export default ConfigDB;