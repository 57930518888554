//useprofile
//
import React, { useRef, useState } from 'react'
import { Button, FormFeedback, FormGroup, Input, Label, Row, Col, Card, CardBody, Container, CardHeader, Collapse } from 'reactstrap'
import { useProfiles } from '../../../hooks/profiles/useProfiles'
import './StyleProfile.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { InformationPriceComercialLine } from './InformationPriceComercialLine';
import { useHistory } from 'react-router-dom';
import { IndexCosts } from "../exams/IndexCosts";
import { ModalOrderExam } from './ModalOrderExam';
import { ModalPrice } from './Prices/ModalPrice';


export const IndexPriceExam = () => {

    const history = useHistory();

    const {
        name, handleInputChange, abbreviation, code, is_critic, is_curve, sampling_cost, loading,
        id_exam, exam, searchPrice, rangeval, totalCommercialLines, method,
        handleChange, handleChangeExam, saveCreate, handleDeleteExamGlobal,
        saveUpdate, handleChangeSinToma, handleChangeConToma,
        validationsProfile,
        sendKitSampling, id_profile, listFirstPrice,
        //OrderByExam
        handleOrderByExam,toggleOrderByExam,methodOrder,titleOrder,modalOrder,
        data,changeOrder,cambiar, handleSaveOrderExamsAnalyte,
        //modal Prices
        titlePrice,typeprice,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuento,resul,porcentaje,arrayExams,llavecard


    } = useProfiles();

    const {
        nameValid,
        abbreviationValid,
        codeValid,
    } = validationsProfile;

    const childCompRef = useRef();

    const [filterDate, setFilterDate] = useState(true);


    return (
        <Container fluid={true}> 
            {/* <Button color="light" className=" btn-air-light m-4" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button> */}
            <Row className=''>
                <Col sm="12">
                    <Row>
                        <Col xs="12" sm="12" md="10" lg="4" xl="4"> <br />
                            <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
                                <Row className="position-sticky" style={{ top: 140 }}>
                                    <Col xl="12">
                                        <Card className="">
                                            <CardHeader className='bg-secondary'>
                                                <h5 className='mb-0'>
                                                    <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)} data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon">
                                                        <i className="icofont icofont-prescription f-22"></i> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Datos del Perfil
                                                    </Button>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={filterDate}>
                                                <CardBody className='p-3'>
                                                    <Row>
                                                        <Col xs="12" sm="12" md="10" lg="12" xl="12">
                                                            <FormGroup className='mb-3'>
                                                                <label className=''>Nombre del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></label>
                                                                <Input
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    name='name'
                                                                    value={name}
                                                                    required={true}
                                                                    autoComplete="nope"
                                                                    onChange={handleInputChange}
                                                                    invalid={nameValid}
                                                                />
                                                                <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="5" lg="7" xl="7">
                                                            <FormGroup>
                                                                <Label className='col-form-label text-sm'>Abreviatura del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                                <Input
                                                                    type='text'
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    name='abbreviation'
                                                                    value={abbreviation}
                                                                    required={true}
                                                                    autoComplete="nope"
                                                                    onChange={handleInputChange}
                                                                    invalid={abbreviationValid}
                                                                />
                                                                <FormFeedback>Debe contener mas de 3 caracteres</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="5" lg="5" xl="5">
                                                            <FormGroup>
                                                                <Label className='col-form-label text-sm'>Clave del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                                <Input
                                                                    type='text'
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    name='code'
                                                                    value={code}
                                                                    required={true}
                                                                    autoComplete="nope"
                                                                    onChange={handleInputChange}
                                                                    invalid={codeValid}
                                                                />
                                                                <FormFeedback>Debe contener minimo 1 caracter</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="5" sm="5" md="5" lg="6" xl="6">
                                                            <FormGroup>
                                                                <Label className='col-form-label text-sm'>Perfil Critico</Label>
                                                                <div className="checkbox checkbox-solid-danger pl-3">
                                                                    <Input
                                                                        id="solid4"
                                                                        type="checkbox"
                                                                        name='is_critic'
                                                                        onChange={handleInputChange}
                                                                        checked={is_critic}
                                                                    />
                                                                    <Label for="solid4">¿Es crítico?</Label>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="5" sm="5" md="5" lg="6" xl="6">
                                                            <FormGroup>
                                                                <Label className='col-form-label text-sm'>Curvas</Label>
                                                                <div className="checkbox checkbox-solid-info pl-3">
                                                                    <Input
                                                                        id="lis_curve"
                                                                        type="checkbox"
                                                                        name='is_curve'
                                                                        onChange={handleInputChange}
                                                                        checked={is_curve}
                                                                    />
                                                                    <Label for="lis_curve">¿Usa curvas?</Label>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="10" lg="12" xl="12">
                                                            <Row>
                                                                <Col xs="5" sm="5" md="5" lg="6" xl="6" className='p-3'> <br />
                                                                <Button color="light" className=" btn-air-light " onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                                                                </Col>
                                                                <Col xs="5" sm="5" md="5" lg="6" xl="6" className='p-3'><br />
                                                                    {
                                                                        searchPrice.length >= 1 &&
                                                                        <Button
                                                                            outline color="primary"
                                                                            style={{ textAlign: "-webkit-center" }}
                                                                            //color="primary"
                                                                            type="submit"
                                                                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                                                                            onClick={
                                                                                (method === 'create' ? saveCreate : saveUpdate)
                                                                            } > {method === 'create' ? "Crear" : "Actualizar"}
                                                                        </Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs="12" sm="12" md="10" lg="8" xl="8"><br />
                            <IndexCosts ref={childCompRef} examId={null} profileId={id_profile} sendKitSampling={sendKitSampling} />
                        </Col>
                    </Row><br />
                </Col>
                <Col sm="12">
                    <Row>
                        {/* <Col sm="1">
                        </Col> */}
                        <Col sm="10">
                            <h6 className='f-w-600 txt-secondary'>Exámen a incluir en el perfil<span className='fa fa-search primary float-right '></span>
                            </h6>
                            <FormGroup>
                                <SearchAsync
                                    value={id_exam}
                                    name="id_exam"
                                    method={handleChangeExam}
                                    loincState={exam}
                                    url="Profiles/SearchProfileExam"
                                    maxLenght={3}
                                    placeholder="Búsqueda de exámen"
                                    className="form-control-sm input-air-primary"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="2" className='p-2'> <br />
                             {/* <br />  */}
                        <Button outline color="primary" size='sm' onClick={handleOrderByExam} ><span className="icofont icofont-drag"></span> Ordenar Exámenes</Button>
                        </Col>
                        
                    </Row>
                </Col>
                <Col sm="12"><br /><hr />
                    {
                        searchPrice.length >= 1 && searchPrice.map((objj, index) => {


                            let findFirstPrice = listFirstPrice.find(x => x.id_commercial_line == objj.id_commercial_line);

                            return (
                                <InformationPriceComercialLine
                                    key={'d'+index}
                                    sampling_cost={childCompRef.current !== undefined ?
                                        (sampling_cost <= childCompRef.current.getTotal()) ? childCompRef.current.getTotal() : sampling_cost : sampling_cost}
                                    {
                                    ...{
                                        objj, handleChange, rangeval, index, handleDeleteExamGlobal, totalCommercialLines, handleChangeSinToma, handleChangeConToma, totalCommercialLines, findFirstPrice,
                                        titlePrice,typeprice,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard
                                     
                                    }
                                    }
                                />
                            )
                        })
                    }
                </Col>
                {/* <div className='tap-top-profile' style={{ display: "block" }} ><i className="icon-angle-double-up f-24"></i>
              </div> */}
                
            </Row>
            {
                <ModalOrderExam
                {
                    ...{  handleOrderByExam, toggleOrderByExam, titleOrder, modalOrder,
                        data, cambiar, handleSaveOrderExamsAnalyte,method
                    }
                }
                /> 
            }
            {
                <ModalPrice
                key={llavecard}
                {
                    ...{titlePrice,typeprice,handleprices,togglePrice,modalPrice,totalCommercialLines,calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard}
                }


                />
            }

            <br />
        </Container>
       

    )
}