import React from 'react'
import '../../../../assets/css/pantalla1.css'
import { Row, Col, Button, Card } from 'reactstrap';
import 'animate.css';
import { ModalRefused } from './ModalRefused';
import { ModalInfoPatient } from './ModalInfoPatient';
import { ModalCancellaPatient } from './ModalCancellaPatient';


export const ComponentUser = ({ obj, changeUser, userSelected,
    enableNim, objj, key, typeModalRefused, modalRefused, toggleRefused, refused, reason, id_return_reason,
    handleSelectValuesR, headerSelected,
    refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
    inputAndCard, handleInputAndCard,
    focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
    handlerefusedManual, show10, closedModalRefused,
    typeModalInfo, modalInfo, toggleInfoPatient, handlePatientInfo, closedModalInfo,
    dataPatient, dataStudy, loadingPatient,
    namepatien, age, curp, phone, gender, datanim, loadingRefused,
    //cancell
    typeModalCan, modalCan, toggleCancell, closedModalCancell, handlePatientCancell, refusedPatientSection


}) => {

    return (
        <>
            {
                obj != null ?
                    <Col key={`user-${key}`} sm="12" onClick={() => changeUser(obj.id_user)} style={{ cursor: "pointer" }}>
                        <Row className={`${!enableNim ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={enableNim}>
                            <Col xs="3" sm="3" md="3" lg="4" xl="3">
                            </Col>

                            <Col sm="6">
                                <div className=''>
                                    <div className='userTracking  imagen ' >
                                        <div>
                                            {
                                                userSelected !== null && userSelected.id_user === obj.id_user ?
                                                    <div>
                                                        <img style={{ width: "80%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                                        <br />

                                                    </div>
                                                    :
                                                    <div>
                                                        <img style={{ width: "80%", filter: "blur(1px) grayscale(1) ", }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                                        <br />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="3">
                            </Col>
                            <Col sm="12"> <br />
                                {
                                    userSelected !== null && userSelected.id_user === obj.id_user ?
                                        <label className='f-14 txt-secondary'>{obj.name}</label>
                                        :
                                        <label className='f-14'>{obj.name}</label>
                                }
                            </Col>
                            <Col sm="12">
                                {
                                    userSelected !== null && userSelected.id_user === obj.id_user ?
                                        <Card style={{ backgroundColor: "#f0f8fd" }}>
                                            <Col sm="12" className='colorRefused' >
                                                <Row>
                                                    <Col xs="1" sm="1" md="1" lg="1" xl="2">
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="7" xl="6" style={{ textAlign: "end" }} className='p-1'>
                                                        <label className='f-w-600 f-14 txt-secondary '>Registros</label>
                                                    </Col>
                                                    <Col xs="3" sm="3" md="3" lg="3" xl="4" className='p-l-0 p-2' >
                                                        {/* {
                                                            userSelected !== null && userSelected.id_user === obj.id_user && obj.id_process === "0" || obj.id_process === 0 ?
                                                                <Button type='button' size='xs' outline color='danger' onClick={() => refused(obj.id_user)}  >
                                                                    Rechazar
                                                                </Button>
                                                                :
                                                                ""
                                                        } */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <br />
                                            {
                                                objj.length === 0 ?
                                                    <div>
                                                        <b className=''>No a registrado ninguno proceso</b>
                                                        <br />
                                                        <img src={require("../../../../assetsToma/imagenes/Frascos.gif")} style={{ width: "15%", filter: "brightness(0.5)" }} alt="" />
                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            userSelected !== null && userSelected.id_user === obj.id_user && objj.map((o, i) => {
                                                                ////console.log(o);
                                                                return (
                                                                    <div className="treeview-item-example">
                                                                        <label className={o.complete === false ? 'f-w-600 f-12 badge badge-light-danger'
                                                                            : 'f-w-600 f-12 badge badge-light-primary'}>{o.nim}</label> &nbsp;&nbsp;&nbsp;
                                                                        <Button outline color={o.complete === false ? "danger" : "primary"} size='xs' onClick={() => handlePatientInfo(o.nim, userSelected.id_process, userSelected.id_section, userSelected.id_user)} >
                                                                            <i className={o.complete === false ? "icofont icofont-laboratory txt-danger f-14"
                                                                                : "icofont icofont-laboratory txt-primary f-14"}></i>
                                                                        </Button>&nbsp;&nbsp;&nbsp;
                                                                        <Button outline color='danger' size='xs' onClick={() => handlePatientCancell(o.nim, userSelected.id_process, userSelected.id_section, userSelected.id_user)}>
                                                                            X

                                                                        </Button>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </Card>
                                        :
                                        ""
                                }
                            </Col>
                        </Row >
                        {
                            userSelected !== null && userSelected.id_user === obj.id_user ?
                                < ModalRefused
                                    key={`userM-${key}`}
                                    {
                                    ...{
                                        modalRefused, toggleRefused, refused, typeModalRefused, reason,
                                        id_return_reason, handleSelectValuesR, obj, userSelected, headerSelected,
                                        refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
                                        inputAndCard, handleInputAndCard,
                                        focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
                                        handlerefusedManual, show10, closedModalRefused,


                                    }
                                    }
                                />
                                :
                                ""
                        }
                        <br />
                        {
                            userSelected !== null && userSelected.id_user === obj.id_user ?
                                < ModalInfoPatient

                                    {
                                    ...{
                                        typeModalInfo, modalInfo, toggleInfoPatient, closedModalInfo, key,
                                        dataPatient, dataStudy, loadingPatient, userSelected,
                                        namepatien, age, curp, phone, gender, datanim, loadingRefused



                                    }
                                    }
                                />
                                :
                                ""
                        }
                        {
                            userSelected !== null && userSelected.id_user === obj.id_user ?
                                < ModalCancellaPatient

                                    {
                                    ...{
                                        userSelected, typeModalCan, modalCan, toggleCancell, closedModalCancell, dataStudy, loadingPatient,
                                        reason, id_return_reason, handleSelectValuesR, obj, userSelected, headerSelected, refusedPatientSection

                                    }
                                    }
                                />
                                :
                                ""
                        }
                    </Col >
                    :
                    ""
            }
        </>
    )
}

