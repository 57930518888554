import React from 'react';
import MaskedInput from 'react-text-mask';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

export const ModalPatient = (props) => {
    const {
        modal, toggleModal,
        formPatient, handleInputChange, handleDatePatient, savePatient
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggleModal} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleModal} className="bg-primary">
                Crear paciente
            </ModalHeader>
            <div>
                <Form className="form theme-form" role="form">
                    <ModalBody>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                        type="text"
                                        name="name_patient"
                                        value={formPatient.name_patient}
                                        required={true}
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        className="form-control form-control-sm input-air-primary"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                        type="text"
                                        name="paternal_surname"
                                        value={formPatient.paternal_surname}
                                        required={true}
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        className="form-control form-control-sm input-air-primary"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Apellido materno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input
                                        type="text"
                                        name="maternal_surname"
                                        value={formPatient.maternal_surname}
                                        required={true}
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        className="form-control form-control-sm input-air-primary"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label">Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <br />
                                        <label style={{ color: "#0079C7" }} > (Dia/Mes/Año)</label>
                                    </Label>
                                    <MaskedInput
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,]}
                                        className="form-control form-control-sm input-air-primary"
                                        name='birthday'
                                        value={formPatient.birthday}
                                        guide={false}
                                        onChange={(e) => handleDatePatient(e, "birthday")}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Género <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label> <br />
                                    <br />
                                    <Input
                                        type="select"
                                        name="id_gender"
                                        required={true}
                                        onChange={handleInputChange}
                                        className="form-control form-control-sm input-air-primary p-1"
                                    >
                                        <option value={1} >Femenino</option>
                                        <option value={2} >Masculino</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            size="sm"
                            outline color="primary"
                            type="button"
                            onClick={savePatient}
                        >
                            Guardar paciente
                        </Button>
                        <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleModal}>Cancelar</Button>
                    </ModalFooter>
                </Form>
            </div>
        </Modal>
    )
}