import React from 'react'
import { Button,Col, ModalBody, ModalFooter, Row,ListGroup,ListGroupItem,Label,FormGroup,Input, Form,InputGroupAddon,InputGroupText,InputGroup } from 'reactstrap';



export const ViewListLimitCredit = (props) => {

    const {
        toggle3,loading,updateCategoryLimit,percentage_credit_limit,handleInputChange,agreements,
        credit_limit
        } = props;

     
  
  return ( 
            <>
                <Form className="theme-form" role="form" onSubmit={updateCategoryLimit}>
                    <ModalBody>
                        <Row>
                            <Col  xl="12" className="box-col-6  pr-0 file-spacing  p-0 m-0 t-0 ">
                                <div className="file-sidebar  p-0 m-0  t-0  ">
                                    <ul>
                                        <li>
                                            <div className=" p-2">
                                                <Row>
                                                    <Col className="col-md-12 p-0  text-center text-secondary ">
                                                        <p className='txt-primary'><h5>{"Linea de Credito"}</h5> </p>
                                                        <hr/> 

                                                        <Col sm="12">
                                                          <Row>
                                                              <Col sm="2">
                                                              </Col>
                                                              <Col sm="8">
                                                                  {
                                                                      percentage_credit_limit == null   &&
                                                                      <FormGroup>
                                                                      <Label>Linea de crédito otorgada</Label>
                                                                         <br />
                                                                         <InputGroup className="mt-1">
                                                                            <Input className="form-control" type="number"
                                                                            name="credit_limit" autoComplete="nope"  onChange={handleInputChange} value={credit_limit}
                                                                            />
                                                                            <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                                            </InputGroup>
                                                                      </FormGroup> 

                                                                  }
                                                              
                                                              </Col>
                                                              <Col sm="2">
                                                              </Col>

                                                              <Col sm="2">
                                                              </Col>


                                                              <Col sm="8">
                                                              {
                                                                      percentage_credit_limit != null  &&
                                                                      <FormGroup>
                                                                      <Label>Linea de crédito otorgada</Label>
                                                                         <br />
                                                                         <InputGroup className="mt-1">
                                                                            <Input className="form-control" type="number"
                                                                            name="credit_limit" autoComplete="nope"  onChange={handleInputChange} value={credit_limit}
                                                                            />
                                                                            <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                                            </InputGroup>

                                                                            <Label>Porcentaje sobre la linea de crédito</Label>
                                                <br />
                                                <InputGroup className="mt-1">
                                                    <Input className="form-control" type="number"
                                                     name="percentage_credit_limit" autoComplete="nope"  onChange={handleInputChange} value={percentage_credit_limit}
                                                    />
                                                    <InputGroupAddon addonType="append"><InputGroupText>{"%"}</InputGroupText></InputGroupAddon>
                                                </InputGroup>
                                                                      </FormGroup>  


                                                                  }
                                                              
                                                              </Col>
                                                          </Row>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col> 
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="primary"
                            type="submit"
                            size="sm"
                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            {"Actualizar"}
                        </Button>
                        <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle3}>{"Cerrar"}</Button>
                    </ModalFooter>
                </Form>
            </>
   
  )
}
