import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { ExportInvoiceDefaultValues } from '../../data/const';

export const ModalAddEgress = (props) => {

    const {
        modalTitle, modalListEgress,
        listNims,
        toggleModalListEgress,
        handleSelectInvoice
    } = props;

    return (
        <Modal isOpen={modalListEgress} toggle={toggleModalListEgress} centered={true} size='xl'>
            <ModalHeader toggle={toggleModalListEgress} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" >
                        <div className='table-responsive'>
                            <Table size='xs' className='table-bordered'>
                                <thead className="theadInvoice">
                                    <tr>
                                        <th className='text-center labeltheadInvoice'>Acciones</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thFolio}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thDate}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thCoin}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thPayMethod}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thTotal}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thUuid}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thPaid}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listNims.length > 0 && listNims.map(_invoice => {
                                            return (
                                                <tr className='text-center' key={'trInvoice-'+_invoice.value}>
                                                    <td>
                                                        <Button color='primary' outline size='sm' onClick={() => handleSelectInvoice(_invoice.value)}>Seleccionar</Button>
                                                    </td>
                                                    <td>{_invoice.folio}</td>
                                                    <td>{_invoice.date}</td>
                                                    <td>{_invoice.code_coin}</td>
                                                    <td>{_invoice.code_pay_form +" "+ _invoice.name_pay_form}</td>
                                                    <td>{_invoice.total}</td>
                                                    <td>{_invoice.uuid}</td>
                                                    <td>{_invoice.is_paid ? 'PAGADA' : 'PENDIENTE DE PAGO'}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}
