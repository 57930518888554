import React, { Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, Row, Spinner } from 'reactstrap';
import { TableStriped } from '../../../../components/tables/TableStriped';
// import { useExamMethod } from '../../../../hooks/exams/useExamMethod';
// import { ModalExamMethods } from '../../../../components/examMethods/ModalExamMethods';
import Breadcrumb from '../../../../layout//breadcrumb'
import { useHistory } from 'react-router-dom';
import { useExamMethodMicrobiology } from '../../../../hooks/micro/useExamMethodMicrobiology';
import { ModalExamMethodsMicrobiology } from '../../../../components/examMethodsMicrobiology/ModalExamMethodsMicrobiology';


export const IndexExamMethodMicrobiology = () => {

    const history = useHistory();


    const {
        nameExam, examMethods, method,
        name, code, id_machine_models, id_method, handleInputChange, validaciones, handleSelectValues,
        toggleModal, modal, loadingModal,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        changeMethodDefault, methodDefault,
        machineModels, selectMachineModels,
        methods
    } = useExamMethodMicrobiology();


    return (
        <Fragment>
            {/* <Breadcrumb parent="Diseño de examen" title="Listado de examenes" /> */}
            <Container fluid={true}>
                <Row>
                    <Col sm="3"> 
                    <Col sm="12"><br /> 
                    </Col>
                        <Card className="shadow">
                            <CardHeader className="p-3 bg-primary ">
                                    <h6>{"Acciones"}</h6>
                            </CardHeader>
                            <br />
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="1"></Col>
                                            <Col sm="8">
                                            <Button color="light" className="float-right mr-1 ml-1" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                            <br />
                        </Card>
                    </Col>

                    <Col sm="9">
                        <Card className="shadow">
                            <CardHeader className="p-3 bg-secondary ">
                                <h6>{" Métodos del examen"} </h6>
                            </CardHeader>
                             <CardBody className="p-1"> 
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-secondary" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div> 
                                {
                                    examMethods.length === 0 ?
                                    <div className="search-not-found text-center">
                                    <div>
                                    <img className="img-fluid" src={require("../../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "30%" }} alt="" /> <br />
                                    <p className="f-18"><br />  {"Se requiere Crear un Método"}</p>
                                    </div>
                                </div>
                                    :
                                    <CardBody>
                                          <div className="table-responsive">   
                                            <TableStriped
                                            methodsActions={true}
                                            notMaped={["id_exam", "name_exam", "firstOption", "machine_models_arrays"]}
                                            cabeceras={["Método", "Código", "Equipos asignados"]}
                                            items={examMethods}
                                            handleUpdate={handleUpdate}
                                            handleDelete={handleDelete}
                                            radioButton={
                                                {
                                                    headRadioButton: "Método default",
                                                    radioButtonChecked: methodDefault,
                                                    methodRadioButton: changeMethodDefault
                                                }
                                            }
                                        />
                                    </div>
                                    </CardBody>
                                }    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalExamMethodsMicrobiology
                {...{
                    modal, toggleModal, method, loadingModal,
                    name, code, id_machine_models, id_method, machineModels, selectMachineModels, methods, handleInputChange, validaciones, handleSelectValues,
                    saveCreate, saveUpdate, saveDelete
                }}
            />
        </Fragment>
    )
}
