import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllCommercialLines ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `CommercialLine/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAllBranches ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Branch/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}