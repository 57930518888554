import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteMachine } from './DeleteMachine';
import { FormMachine } from './FormMachine';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';

export const ModalMachine = (props) => {

    const {
        method, handleInputChange, validaciones, loading,
        brand, model,
        toggle, modal, modalTitle,
        saveCreate, saveUpdate, saveDelete
    } = props;
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormMachine
                        button={ModalButtonSave}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")

                        ?
                        <FormMachine
                            button={ModalButtonUpdate}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, brand, model }}
                        />
                        :
                        <DeleteMachine
                            {...{ toggle, loading, saveDelete, brand, model }}
                        />
            }
        </Modal>
    )
}
