import React, { Fragment } from 'react';
import { Container, Col, Row, Card, CardBody, Button, Table, ButtonGroup, Input } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useQuatation } from '../../hooks/quoter/useQuatation'
import { ModalViewStudies } from './ModalViewStudies';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { TableStriped } from '../../components/tables/TableStriped';



export const IndexQuatation = () => {

	const {
		quoter, handleListStudies,
		//MODAL STUDIES
		modal, toggle, modalTitle, method, studieQ,
		//create
		navigateUpdate, colorHEX,
		printPdf, sentPdf, downdloadpdf,
		codeCard, quoterCard,
		totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearchQuoter


	} = useQuatation();

	return (
		<Fragment>
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody className="p-1">
								<div className="ribbon ribbon-clip ribbon-primary">Listado de Cotizaciones</div>
								<div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
									<Button className="btn btn-pill btn-air-success" outline color="secondary" size="sm" onClick={() => navigateUpdate()} >
										<span className="fa fa-file-text"></span> Crear Cotización
									</Button> <br /> <br />
									<div className="row">
										<div className="offset-8 col-4 pt-1 pb-2">
											<Input placeholder='Busqueda...' className='form-control' onChange={handleSearchQuoter} />
										</div>
									</div>
									<br /> <br /> <br />
									<div className="table-responsive">
										{
											quoter.length === 0 ?
												<div style={{ textAlign: "-webkit-center" }}>
													<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "25%" }} alt="" />   <br />
													<br /> <p className="f-18">{"No se a registrado ninguna Cotización aún."}</p>
												</div>
												:
												<TableStriped
													notMaped={["visibleTable", "id_user", "íd_patient", "id_branch", "branch", "observations","name_user"]}
													methodsActions={true}
													cabeceras={["Código", "Nombre", "Apellido Paterno","Apellido materno" ,"Fecha de uso" ,"Status" ]}
													items={quoter}
													nextPage={nextPage}
													previousPage={previousPage}
													totalPageCount={totalPageCount}
													currentPage={currentPage}
													goToPage={goToPage}

													methodsAbsolutes={[
														{
															type: "linkId",
															method: sentPdf,
															icon: "icofont icofont-envelope f-10",
															backgroundColor: "#46AFE5",
															color: "#fff",
															name: "sentPdf",
															tooltip: "Enviar"
														},
														{
															type: "linkId",
															method: downdloadpdf,
															icon: "icofont icofont-download-alt f-10",
															backgroundColor: "#0079C7",
															color: "#fff",
															name: "downdloadpdf",
															tooltip: "Descargar"
														},
														{
															type: "linkId",
															method: printPdf,
															icon: "icofont icofont-print f-10",
															backgroundColor: "#0079C7",
															color: "#fff",
															name: "printPdf",
															tooltip: "Imprimir"
														},
													]}
												/>
										}

									</div>

								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12" className='p-3'>
					</Col>
				</Row>
			</Container>
			<ModalViewStudies
				{
				...{
					modal, toggle, modalTitle, method, studieQ, quoter, codeCard
				}
				}
			/>
		</Fragment>

	)
}
