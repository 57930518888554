import React, { Fragment } from 'react';
import { Button, Card, CardBody, Container, Row, Col,Input } from 'reactstrap';
import { RibbonTransports } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalTransports } from '../../components/transports/ModalTransports';
import { useTransport } from '../../hooks/transports/useTransport';
import Breadcrumb from '../../layout/breadcrumb';

export const IndexTransports = () => {

    const {
        transports, method,
        name, abbreviation, code, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchTransport
    } = useTransport();

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Tipos de transporte" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {RibbonTransports}
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Búsqueda...' className='form-control' onChange={handleSearchTransport} />
                                </div>
                            </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        orderTable={true}
                                        methodsActions={true}
                                        cabeceras={["Nombre", "Abreviación", "Código"]}
                                        items={transports}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <ModalTransports
                            {
                            ...{
                                modal, modalTitle, toggle, method,
                                loading, validaciones,
                                name, abbreviation, code, handleInputChange,
                                saveCreate, saveUpdate, saveDelete
                            }
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
