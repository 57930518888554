import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteProcess = (props) => {

    const {
        toggle, loading, name, code, saveDelete
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el siguiente proceso:</p>
                <b>{name + '(' + code + ")"}</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="danger" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Si, eliminar
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
