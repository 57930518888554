import React from 'react'
import { Button,Col, ModalBody, ModalFooter, Row,Card } from 'reactstrap';
import './StyleProfile.css';



export const ViewListprofile = (props) => {

    const {
            toggle, listProfile
        } = props;


  
  return ( 
            <>
            <div className='cardExam '> <br />
            <h5 class="disableLabel" for="u">Exámenes que conforman el Perfil</h5> 
            <ul className="cardExam-content">
                {
                    listProfile.length === 0 ? 
                    <div> <br />
                        <label>No se cuenta con ningún exámen armado</label> <br />
                    </div> 
                    :
                    <div>
                         {
                            listProfile.length >= 1 && listProfile.map( (obj, index) =>{
                            return (
                            <li key={index}>
                            {obj.name} 
                            </li>
                            )
                            })
                        }
                    </div>
                }   
            </ul>
            </div>          
                <ModalFooter>
            <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>{"Cerrar"}</Button>
            </ModalFooter>
            </>
   
     )
    }
