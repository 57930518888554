import React from 'react';
import { Col, Form, FormGroup, FormText, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormCommercialLines = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, 
        methodAction, theme_color, long_name, short_name, rfc, button
    } = props;

    const {
        long_name_valid, short_name_valid, rfc_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Razón social <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="text" 
                            name="long_name"
                             value={long_name} 
                             invalid={long_name_valid} 
                             onChange={handleInputChange}
                             className="form-control form-control-sm input-air-primary" 
                             />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label"> Nombre Comercial <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="text" 
                            name="short_name" 
                            value={short_name} 
                            invalid={short_name_valid} 
                            onChange={handleInputChange} 
                            className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label"> RFC <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="text" 
                            name="rfc" 
                            value={rfc}
                             invalid={rfc_valid}
                              onChange={handleInputChange} 
                              className="form-control form-control-sm input-air-primary" 
                              />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label"> Selecciona un color <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            style={{ textAlign : "-webkit-center"}}
                            type="color" 
                            className="form-control form-control-sm input-air-primary" 
                            value={theme_color} 
                            name="theme_color" 
                            onChange={handleInputChange} 
                            //style={{ height: '35px' }}
                             />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Form>
    )
}
