import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeletePrinterPoint } from './DeletePrinterPoint';
import { FormPrinterPoint } from './FormPrinterPoint';


export const ModalPrinterPoint = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        name, username, password, password_confirmation, handleInputChange, handleSelectValues, validaciones,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={ toggle } className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create" ?
                    <FormPrinterPoint
                        button="Crear nuevo punto de impresión"
                        {...{ toggle, loading, name, username, password, password_confirmation, handleInputChange, handleSelectValues, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update") ?
                        <FormPrinterPoint
                            button="Actualizar punto de impresión"
                            {...{ toggle, loading, name, username, password, password_confirmation, handleInputChange, handleSelectValues, validaciones, methodAction: saveUpdate }}
                        />
                        : <DeletePrinterPoint {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>   
    )
}
