import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeleteProductionCost } from './DeleteProductionCost';
import { FormProductionCost } from './FormProductionCost';

export const ModalProductionCost = (props) => {

    const {
        method, handleInputChange, validationsProduction, 
        cost, 
        toggle, modal, modalTitle,
        loading, 
        saveCreate,
    } = props;    

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className="modal-sm">
            <ModalHeader toggle={toggle} className="bg-primary">
                { modalTitle }
            </ModalHeader>
            {
                method === "create"
                    ?
                        <FormProductionCost
                            button={ ModalButtonSave }
                            {
                                ...{
                                    toggle, loading, handleInputChange, validationsProduction, methodAction: saveCreate,
                                    cost,
                                }
                            }
                        />
                    : ""
                            
                        // (method === "update")
                        //     ?
                        //         <FormProductionCost
                        //             button={ ModalButtonUpdate }
                        //             {
                        //                 ...{
                        //                     toggle, loading, handleInputChange, validationsProduction, methodAction: saveUpdate,
                        //                     cost
                        //                 }
                        //             }
                        //         />
                        //     :
                        //         <DeleteProductionCost
                        //             { ...{ toggle, loading, saveDelete, cost, from , to, number_format }}
                        //         />
            }
        </Modal>
    )
}
