import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormSendCSV } from './FormSendCSV';

export const ModalSendCSV = (props) => {

    const {

        modal2, method2, modalTitle2,loading,toggle2,file,handleInputChange,handleOnChange,
        ExamIndication

        
    }= props;

    return (
        <Modal isOpen={modal2} toggle={toggle2}  backdrop='static' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle2}  className="bg-primary">
            {modalTitle2}
        </ModalHeader>
        {
            method2 === "listCSV"
                ? <FormSendCSV
                    button="Guardar"
                    {...{  modal2, method2, modalTitle2,loading,toggle2,file,handleInputChange,
                        handleOnChange,methodAction:ExamIndication}}
                />
                : (method2 === "update")       
        }
    </Modal>
       
    )
}
