import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ExportInvoiceDefaultValues } from '../../data/const';

export const useInputTypeClient = (props) => {

    const {
        typeClient, formInvoice, handleInputChange, handleUpdateValues, informationBusinessName, setInformationBusinessName, validSelectBusinessName,
        informationPatient, setInformationPatient, validSelectPatient, setViewSearchPatient, setViewSearchClient, handleChangePayMethodGeneralPublic,
        handleChangePayMethodPatient,

        id_patient_r,_id_company_r,setId_company_filter,request_id_invoice,
    } = props;

    const history = useHistory();

    //#region SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion

    //#region  TYPE CLIENT

    //#region INVOICE SERVICE MODELS
    const [listCompanies, setListCompanies] = useState([]);
    const [listCompaniesBussinessName, setListCompaniesBussinessName] = useState([]);

    const [viewButtonEditBussinessName, setViewButtonEditBussinessName] = useState(false);
    const [viewButtonEditPatient, setViewButtonEditPatient] = useState(false);

    //#endregion

    //#region INVOICE SERVICE USE EFFECTS

    useEffect(() => {

        if (typeClient === "1") {
            handleChangePayMethodPatient();

            setInformationBusinessName({});

            setInformationPatient({});

            setViewSearchClient(true);
        }
        else if (typeClient === "2") {
            handleGetClients();

            setInformationBusinessName({});
            setInformationPatient({});

            let tmp_id_company = 0;
            if (_id_company_r !== undefined && parseInt(id_patient_r) > 0) {
                tmp_id_company = {
                    label: 'id_company',
                    value: parseInt(_id_company_r)
                };
            }

            handleUpdateValues({
                ...formInvoice,
                id_patient: 0,
                id_company: tmp_id_company,
                id_company_business_name: 0,
                id_coin: 0,
                id_use_cfdi: 0,
                id_periodicity: 0,
                id_month: 0,
                id_type_releationship: ExportInvoiceDefaultValues.defaultTypeReleationship,
                id_method_pay: 0,
                id_pay_form: 0,
                payment_conditions: "",
                is_general_public: false,
                comments_invoice: "",
                information_general_public: {
                    name_general_public: "PUBLICO EN GENERAL",
                    rfc_general_public: "XAXX010101000",
                    cp_general_public: "01000",
                    id_tax_regime_general_public: 0,
                    code_tax_regime_general_public: "",
                    tax_regime_general_public: ""
                }
            });

            setViewSearchPatient(true);
           // setId_company_filter(tmp_id_company)
        }
        else if (typeClient === "3") {
            handleChangePayMethodGeneralPublic();

            setInformationBusinessName({});

            setInformationPatient({});
        }

    }, [typeClient]);

    useEffect(() => {
        if (id_patient_r !== undefined) {
            setViewSearchPatient(false)

            handleChangePatient({
                target: {
                    name: 'id_patient',
                    value: id_patient_r
                }
            })
        }
    }, [id_patient_r]);

    useEffect(() => {

        if (informationBusinessName.business_name !== undefined) {

            validSelectBusinessName();
            setViewButtonEditBussinessName(true);
        }

    }, [informationBusinessName]);

    useEffect(() => {

        if (informationPatient.rfc_patient !== undefined) {

            validSelectPatient();
            setViewButtonEditPatient(true);
        }

    }, [informationPatient]);

    useEffect(() => {
                    
        if (_id_company_r !== undefined) {


            handleGetCompanyBussinessName(_id_company_r, true);
            setInformationBusinessName({});
        }
       
    }, []);


    // useEffect(() => {


    //     ////console.log("No se debe de activar efecto");
               
       


    //         handleGetCompanyBussinessName(array1, true);
    //         setInformationBusinessName({});
        
    // }, [ array1 !==0]);

    //#endregion

    //#region INVOICE SERVICE METHODS

    const handleGetClients = async () => {
        let listTmp = await handleRequest("GET", "Company/List", "Clientes");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(company => {
                list.push({
                    value: company.id_company,
                    label: company.code + " " + company.name
                });
            });

            setListCompanies(list);
        }
    }

    const handleChangeClient = (e) => {
        if (e !== null && e.value !== undefined) {           
            handleUpdateValues({
                ...formInvoice,
                id_company: e
            });

            handleGetCompanyBussinessName(e.value, (_id_company_r !== undefined && parseInt(_id_company_r) > 0 ? true : false));
            setInformationBusinessName({});
            setId_company_filter(e.value)
        }    
    }    

    const handleGetCompanyBussinessName = async (id_company, isPreloadedValues = false) => {

   

        if (parseInt(id_company) > 0) {

            const listTmp = await handleRequest("GET", `Company/ListBusinessNames/${id_company}`, "Razones sociales");

            if (listTmp !== null && listTmp.length > 0) {
                let list = [];
                
                    listTmp.forEach(bussinessName => {
                        list.push({
                            "id_company_business_name": bussinessName.id_company_business_name,
                            "id_company": bussinessName.id_company,
                            "id_tax_regime": bussinessName.id_tax_regime,
                            "rfc": bussinessName.rfc,
                            "business_name": bussinessName.business_name,
                            "address": bussinessName.address,
                            "colony": bussinessName.colony,
                            "cp": bussinessName.cp,
                            "phone": bussinessName.phone,
                            "email": bussinessName.email,
                            "code_tax_regime_business_name": bussinessName.code_tax_regime,
                            "name_tax_regime": bussinessName.name_tax_regime,
                            "type_person": bussinessName.type_person,
                       });
                    });
    
                setListCompaniesBussinessName([...list]);

                if (isPreloadedValues) {  
                    setInformationBusinessName({
                        id_tax_regime_business_name: listTmp[0].id_tax_regime,
                        business_name: listTmp[0].business_name,
                        rfc_business_name: listTmp[0].rfc,
                        cp_business_name: listTmp[0].cp,
                        email_business_name: listTmp[0].email,
                        tax_regime_business_name: listTmp[0].name_tax_regime,
                        type_person_business_name: listTmp[0].type_person,
                        code_tax_regime_business_name: listTmp[0].code_tax_regime,
                    });

                    handleUpdateValues({
                        ...formInvoice,
                        id_company: {
                            label: 'id_company',
                            value: parseInt(_id_company_r)
                        },
                        id_company_business_name: listTmp[0].id_company_business_name
                    });                
                }
            }
        } 
    }    
    
    const handleChangeCompanyBussinessName = (e) => {

        handleInputChange(e);

        let companyBussinessName = e.target.value;

        let findCompanyBussinessName = listCompaniesBussinessName.find(c => c.id_company_business_name === parseInt(companyBussinessName));

        if (findCompanyBussinessName !== undefined) {

            setInformationBusinessName({
                id_tax_regime_business_name: findCompanyBussinessName.id_tax_regime,
                business_name: findCompanyBussinessName.business_name,
                rfc_business_name: findCompanyBussinessName.rfc,
                cp_business_name: findCompanyBussinessName.cp,
                email_business_name: findCompanyBussinessName.email,
                tax_regime_business_name: findCompanyBussinessName.name_tax_regime,
                type_person_business_name: findCompanyBussinessName.type_person,
                code_tax_regime_business_name: findCompanyBussinessName.code_tax_regime,
            });
        }
    }

    const handleChangePatient = async (e) => {
        handleInputChange(e);

        let patient = e.target.value;
    

        if (patient !== 0 && patient !== null && patient !== ''  && patient !== "0" ) {
            const listTmp = await handleRequest("GET", `Patient/FindPatientId/${patient}`, "Paciente");
            //console.log('listTmp: -> ', listTmp);
            if (listTmp !== null) {

                setInformationPatient({
                    id_tax_regime_patient: listTmp.id_tax_regime === null ? 0 : listTmp.id_tax_regime,
                    id_use_cfdi: listTmp.id_use_cfdi === null ? 0 : listTmp.id_use_cfdi,
                    name_patient: listTmp.name_patient,
                    business_name: (listTmp.business_name !== "" && listTmp.business_name !== null) ? listTmp.business_name : listTmp.name_patient,
                    type_person_patient: listTmp.type_person,
                    cp_patient: listTmp.cp,
                    rfc_patient: listTmp.rfc === null ? "" : listTmp.rfc,
                    email: listTmp.email === null ? "" : listTmp.email,
                    code_tax_regime: listTmp.code_tax_regime,
                    tax_regime_patient: listTmp.tax_regime,
                    code_use_cfdi: listTmp.code_use_cfdi === null ? "" : listTmp.code_use_cfdi,
                    name_use_cfdi: listTmp.name_use_cfdi === null ? "" : listTmp.name_use_cfdi,
                });

                if (listTmp.id_use_cfdi !== "" && listTmp.id_use_cfdi !== null) {
                    handleUpdateValues({
                        ...formInvoice,
                        id_use_cfdi: listTmp.id_use_cfdi,
                        id_patient: patient
                    });
                }
            }
        }
        else {

            setInformationPatient({});

            handleUpdateValues({
                ...formInvoice,
                id_patient: 0
            });
        }
    }

    //#endregion

    //#endregion    

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        //MODEL
        listCompanies, listCompaniesBussinessName, viewButtonEditBussinessName,
        viewButtonEditPatient,

        //METHODS
        handleChangeClient, handleChangeCompanyBussinessName, handleChangePatient,
        setListCompaniesBussinessName
    }
}
