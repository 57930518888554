import { sendRequest } from '../../hooks/requests/useRequest'

export async function getDetailCash (_id_branch)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `WorkOrder/DetailAmount/${_id_branch}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function createCash(_comments,_notAmount,_valid,_captureAmount,_first,_last,_branch)
{

    let _body = {
        comments: _comments,
        amount: _notAmount === undefined ? 0 : _valid,
        capture_amount: _captureAmount,
        id_work_order_first: _first,
        id_work_order_last: _last,
        id_branch: _branch,
    };

    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'POST',
        body: data

    };

    const response = await sendRequest(requestOptions, "CashFlow/Create");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}