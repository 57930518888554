import React, { Fragment } from 'react'
import { Container, Card, } from 'reactstrap';
import './stylePbi.css';
import { usePowerBi } from '../../../hooks/Pbi/usePowerBi';

export const IndexPbi3 = () => {

    const {
        dataLink
    } = usePowerBi();


    return (
        <Container fluid={true} >
            {
                dataLink[2] === undefined ?
                    <Card className='shadow' >
                        <div style={{ textAlign: "-webkit-center" }} >
                            <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                            <label className='f-w-600 f-14 badge badge-light-primary'>No se ha Cargado algún reporte que mostrar</label>
                        </div>
                    </Card>
                    :
                    <div className='embed-container'>
                        <iframe
                            id="inlineFrameExample"
                            src={dataLink.length > 0 && dataLink[2].link}
                            frameborder="0"
                        >
                        </iframe>
                    </div>
            }
        </Container>

    )
}

