import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export const TextComputedForm = (props) => {
    const {//dAatos mostrados 
        activeTab, isSimple, validarFormula, resetValidarFormula,
        id_type_analyte, compute, check_compute,
        handleInputChange, validateFormula, handleModalTest, vCompute, handleFullModal, modalFullRef
    } = props;
    return (
        <>
            <FormGroup check className={`mb-3 col-12 text-right ${(id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5") ? "" : "d-none"}`}>
                <Label className="text-sm" check>
                    <Input disabled={activeTab === 2} type="checkbox" checked={check_compute} name="check_compute" onChange={handleInputChange} />
                    Activar fórmula
                </Label>
            </FormGroup>

            <FormGroup className={`mb-1 col-12 text-right ${((id_type_analyte === "5" || id_type_analyte === "7")) ? "" : "d-none"}`}>
                <a style={{ cursor: "pointer" }} className="link text-sm badge badge-light-primary f-12" ref={modalFullRef} onClick={() => handleFullModal("modalComplex")}>Gestionar fórmula</a>
            </FormGroup>
            <FormGroup className={`mb-1 col-12 ${((id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5") && check_compute) ? "" : "d-none"}`}>
                <Label className="text-sm">Fórmula</Label>
                <Input
                    readOnly={activeTab === 2}
                    bsSize="sm"
                    placeholder="Fórmula"
                    type="text"
                    name="compute"
                    value={compute}
                    autoComplete="off"
                    onChange={handleInputChange}
                    invalid={vCompute}
                    onBlur={validarFormula}
                    onFocus={resetValidarFormula}
                />
                <FormFeedback>El texto debe tener 3 o más caracteres </FormFeedback>
            </FormGroup>

            <FormGroup className={`mb-1 col-6 text-left ${((id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5") && check_compute && validateFormula.validacion) ? "" : "d-none"}`}>
                <h6 onClick={handleModalTest} style={{ cursor: "pointer" }} className="link text-primary">Probar fórmula</h6>
            </FormGroup>
            <FormGroup className={`mb-1 col-12 text-right ${((id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5") && check_compute && !validateFormula.validacion) ? "" : "d-none"}`}>
                <h6 className="text-danger">{validateFormula.msg}</h6>
            </FormGroup>
        </>
    )
}
