import React, { Fragment } from 'react'
import { Container, Col, Row, Button, Card, CardBody, Input } from 'reactstrap';
import { ModalMorphology } from '../../../components/micro/morphology/ModalMorphology';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useMorphology } from '../../../hooks/micro/useMorphology';

export const IndexMorphology = () => {

    const {
        morphologys, method, validaciones,
        morphology,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch,
    } = useMorphology();

    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de morfologias
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                    </div>
                                </div>
                                <div className="table-responsive"> 
                                    {
                                        morphologys.length === 0 ?
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "24%" }} alt="" />   <br />                                         
                                            <br /> <p className="f-18">{"No se ha registrado ninguna morfologia aún."}</p>
                                        </div>
                                        :
                                        <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Morfologias"]}
                                        items={morphologys}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />   
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <ModalMorphology
                            {...{
                                modal, modalTitle, toggle, method,
                                validaciones,
                                morphology,
                                handleInputChange, loading,
                                saveCreate, saveUpdate, saveDelete
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
