import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteInvoiceSettings } from './DeleteInvoiceSettings';
import { FormInvoiceSettings } from './FormInvoiceSettings';

export const ModalInvoiceSettings = (props) => {

    const {
        modal, modalTitle, method, toggle, loading,
        id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
        listTypeDocuments,
        handleInputChange, saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                { modalTitle }
            </ModalHeader>
            {
                (method === "create" || method === "update")
                ?
                    <FormInvoiceSettings
                        button={method === "create" ? "Guardar" : "Actualizar"}
                        {
                            ...{
                                toggle, loading, 
                                id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
                                listTypeDocuments, 
                                handleInputChange, 
                                methodAction : (method === "create" ? saveCreate : saveUpdate)
                            }
                        }
                    />
                : 
                    <DeleteInvoiceSettings
                        {
                            ...{
                                code, serie, initial_folio, loading, toggle, saveDelete
                            }
                        }
                    />
            }
        </Modal>
    )
}
