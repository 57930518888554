import React from 'react'
import { ViewListLimitCredit } from './ViewListLimitCredit'
import { Modal} from 'reactstrap';


export const ModalLimitCredit = (props) => {

    const {

     
        modal3, modalTitle1, toggle3, method, loading,updateCategoryLimit,percentage_credit_limit,handleInputChange,agreements,
        credit_limit

    } = props;

    return (
        <Modal isOpen={modal3} toggle={toggle3} keyboard={false} centered={true}  className='sm'  >
  
        {
            method === "list"
            ?
                <ViewListLimitCredit
                { ...{toggle3,loading,updateCategoryLimit,modalTitle1,percentage_credit_limit,handleInputChange,agreements,credit_limit} }
                />
            :
            ""
        }
      </Modal>
    )
}
