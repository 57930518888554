import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteExamMethods } from './DeleteExamMethods';
import { FormExamMethods } from './FormExamMethods';

export const ModalExamMethods = (props) => {

    const {
        modal, toggleModal, method, loadingModal,
        name, code, id_machine_models, id_method, machineModels, selectMachineModels, methods, handleInputChange, validaciones, handleSelectValues,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggleModal} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleModal}>
                {
                    method === "create" ?
                        "Creación de método"
                        : (method === "update") ?
                            "Actualización de método"
                            : "Eliminar método"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormExamMethods
                        button="Crear nuevo método"
                        {...{ toggleModal, loadingModal, name, code, id_machine_models, id_method, machineModels, selectMachineModels, methods, handleInputChange, handleSelectValues, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update") ?
                        <FormExamMethods
                            button="Actualizar método"
                            {...{ toggleModal, loadingModal, name, code, id_machine_models, machineModels, selectMachineModels, handleInputChange, handleSelectValues, validaciones, methodAction: saveUpdate }}
                        />
                        : <DeleteExamMethods {...{ toggleModal, loadingModal, name, saveDelete }} />
            }
        </Modal>
    )
}
