import React from 'react';
import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, ModalBody, ModalFooter, Row } from 'reactstrap';
import { ModalButtonClose } from '../../constant';

export const ViewListExamProf = (props) => {

    const {
        toggle, listExam, listProfile

    } = props;

    ////console.log(listExam);
    ////console.log(listProfile);

    return (
        <>
            <ModalBody>
                <Row >
                    <Col md="12" sm="6">
                        {
                            listExam.length >= 1 &&
                            <div className="pricingtable">
                                <div className="pricingtable-header">
                                    <h6 className="title">Exámenes individuales</h6>
                                </div>
                                <ul className="pricing-content">
                                    {
                                        listExam.length >= 1 && listExam.map((exam, index) => {
                                            return (
                                                <li key={index}>
                                                    {/* <p>{exam.name}</p> */}


                                                    <p className={exam.is_maquila ? "text-danger mb-0" : ""}>
                                                        {exam.is_maquila ? <i className="icofont icofont-ui-travel"></i> : ""}
                                                        {`(${exam.abbreviation}) ` + exam.name}
                                                    </p>
                                                    {exam.is_maquila ? <small>* Sucursal a maquilar: {exam.branch_maquila}</small> : ""}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            listProfile.length >= 1 && listProfile.map((profile, index) => {
                                return (
                                    <div className="pricingtable" key={index}>
                                        <div className="pricingtable-header">
                                            <h6 className="title">{profile.name_profile}</h6>
                                        </div>
                                        <ul className="pricing-content">
                                            {
                                                profile.listExam.length >= 1 && profile.listExam.map((examList, indexExam) => {
                                                    return (
                                                        <li key={indexExam}>{`(${examList.name}`}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>{ModalButtonClose}</Button>
            </ModalFooter>
        </>
    )
}
