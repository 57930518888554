import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useUsers } from '../../../hooks/users/useUsers';
import { ModalUser } from '../../../components/users/ModalUser';
import { ModalSectionUser } from './ModalSectionUser';
import { ModalArea } from './ModalArea';


export const IndexUser = () => {

    const {
        users,
        handleInputChange, handleSelectValues, validaciones,
        modal, modalTitle, toggle, method, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        id_external, name, paternal_surname, maternal_surname, username, job, card_code, areas, departaments,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearchUser,
        //list Section and Area
        handleListSection, dataSections, typeModal, modalSection, toggle2, handleListArea, dataAreas, typeModal2, modalArea, toggle3


    } = useUsers();

    ////console.log(users);



    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12" className='p-l-0 p-r-0' >
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado &nbsp;
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <input placeholder='Busqueda...' className='form-control' onChange={handleSearchUser} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        users.length === 0 ?
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se a Cargado correctamente los usuarios.</p>
                                            </div>
                                            :
                                            <div className="table-responsive">
                                                <TableStriped
                                                    notMaped={["visibleTable", "id_helen_users", "ids_roles", "ids_sections", "id_external", "id_job", "job", "areas", "sections", "name_areas", "username", "roles"]}
                                                    methodsActions={true}
                                                    methodsModal={true}
                                                    cabeceras={["Nombre", "Apellido paterno", "Apellido materno", "Código de Tarjeta"]}
                                                    items={users}
                                                    {...{ handleUpdate, handleDelete }}
                                                    nextPage={nextPage}
                                                    previousPage={previousPage}
                                                    totalPageCount={totalPageCount}
                                                    currentPage={currentPage}
                                                    goToPage={goToPage}
                                                    methodActiveModal={[
                                                        {
                                                            type: "ModIdM",
                                                            method: handleListSection,
                                                            icon: "icofont icofont-stretcher f-14",
                                                            name: "handleListSection",
                                                            color: 'dark',
                                                            tooltip: "Secciones",
                                                        },
                                                        {
                                                            type: "ModIdM",
                                                            method: handleListArea,
                                                            icon: "icofont icofont-heartbeat f-14",
                                                            name: "handleListArea",
                                                            color: 'info',
                                                            tooltip: "Areas",
                                                        },

                                                    ]}
                                                />
                                            </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalUser
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    id_external, name, paternal_surname, maternal_surname, username, job, card_code, areas, departaments,
                    handleInputChange, handleSelectValues, validaciones,
                    saveCreate, saveUpdate, saveDelete,
                }
                }
            />
            <ModalSectionUser
                {
                ...{
                    dataSections, typeModal, modalSection, toggle2
                }
                }
            />
            <ModalArea
                {
                ...{
                    dataAreas, typeModal2, modalArea, toggle3

                }
                }

            />
        </Fragment>
    )
}
