import React from 'react'
import { useForm } from '../forms/useForm';
import { useState, useEffect } from 'react';
import { LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useCancellationReazonSample = () => {


    const [createSweet] = useSweetAlert();
    const history = useHistory();
    const [listCancellation, setListCancellation] = useState([]);
    const [indicationIdC, setIndicationIdC] = useState(0);
    const [loading, setLoading] = useState(false);
    const [typeModalLogin, setTypeModalLogin] = useState("cancellationCsV");

 

    useEffect(() => {

        getCancellationReazonSample();

    }, [history.location]);




    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({

        id_cancellation_reazon_sample: 0,
        cancellation_reazon: "",

    });

    const { id_cancellation_reazon_sample, cancellation_reazon } = formValues;

    const getCancellationReazonSample = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CancellationReazonSample/List");

        if (respuesta.code === 200) {

            let listcancell1 = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >=1) {
                respuesta.data.forEach((element,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listcancell1.push({
                        id_cancellation_reazon_sample: element.id_cancellation_reazon_sample,
                        cancellation_reazon: element.cancellation_reazon,
                        visibleTable: visibleTable

                    })
                });
                setListCancellation(listcancell1);
                setCancellationData(listcancell1);
                
            }
           // setListCancellation(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCancellationReazonSample);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Toma de Muestra", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Toma de Muestra", respuesta.data.msg);

        }
    };


    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        let mData = formValues;

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(mData)
        };

        const respuesta = await sendRequest(requestOptions, "CancellationReazonSample/Create");
        if (respuesta.code === 200) {

            getCancellationReazonSample();
            reset();
            createSweet("create", "success", "Exito!", "Motivos de Cancelación de la Toma de Muestra creada exitosamente");
            setLoading(false);
            toggle3();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Toma de Muestra", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Toma de Muestra", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_cancellation_reazon_sample) => {

        let reason = listCancellation.find(b => b.id_cancellation_reazon_sample === parseInt(id_cancellation_reazon_sample));

        handleUpdateValues({
            id_cancellation_reazon_sample: reason.id_cancellation_reazon_sample,
            cancellation_reazon: reason.cancellation_reazon
        });

        setIndicationIdC(id_cancellation_reazon_sample);
        toggle3();
        setMethod3("update");
        setMo3dalTitle3("Actualizar Motivo de Cancelación de Toma de Muestra");
    }


    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);


        let body = {
            id_cancellation_reazon_sample: indicationIdC,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "CancellationReazonSample/Update");

        if (respuesta.code === 200) {
            getCancellationReazonSample();
            createSweet("create", "success", "Exito!", "Motivación de Cancelación Actualizado");
            setLoading(false);
            toggle3();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Toma de Muestra", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Toma de Muestra", respuesta.data.msg);
        }
    };

    const handleDelete = (id_cancellation_reazon_sample) => {

        let reason = listCancellation.find(b => b.id_cancellation_reazon_sample === parseInt(id_cancellation_reazon_sample));

        handleUpdateValues({
            id_cancellation_reazon_sample: reason.id_cancellation_reazon_sample
        });

        setIndicationIdC(id_cancellation_reazon_sample);
        toggle3();
        setMethod3("delete");
        setMo3dalTitle3("Motivo de cancelación de toma de muestra");
    };


    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `CancellationReazonSample/Delete/${indicationIdC}`);

        if (respuesta.code === 200) {
            getCancellationReazonSample();
            setLoading(false);
            createSweet("create", "success", "Exito!", "Motivo de Cancelación eliminado");
            toggle3();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Toma de Muestra", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Toma de Muestra", respuesta.data.msg);
        }
    };


    const [file, setFile] = useState("");


    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };


    const CancellationReazonSample = async (e) => {
        
        e.preventDefault();
        setLoading(true);

        var formData = new FormData();

        formData.append('csvFile', file);
        formData.append('type', 0);


        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const respuesta = await sendRequest(requestOptions, "CancellationReazonSample/UploadCancellationReazon", 'multipart/form-data');

        if (respuesta.code === 200) {
            getCancellationReazonSample();
            createSweet("create", "info", "Exito!", "Motivo de Cancelaciones de Toma de Muestra Actualizado");
            setLoading(false);
            toggle3();

        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, CancellationReazonSample);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Indicaciones", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Indicaciones", respuesta.data.msg);
        }
    }


    const [method3, setMethod3] = useState("");
    const [modal3, setModal3] = useState(false);
    const [modalTitle3, setMo3dalTitle3] = useState("");

    const toggle3 = () => {
        setModal3(!modal3);
    }

    const handleCreateTM = () => {

        handleUpdateValues({
            id_cancellation_reazon_sample: 0,
            cancellation_reazon: ""
        });

        setIndicationIdC(0);
        toggle3();
        setMethod3("create");
        setMo3dalTitle3("Motivo de Cancelación de Toma de Muestra");

    };

    const [method4, setMethod4] = useState("");
    const [modal4, setModal4] = useState(false);
    const [modalTitle4, setModalTitle4] = useState("");
    const [typeModalCsv, setTypeModalCsv] = useState("createCSV");


    const toogle4 = () =>{
        setModal4(!modal4);
    }


    const handleCreateCSV = () =>{

        toogle4();
        setTypeModalCsv("createCSV")
        setModalTitle4("Cargar archivo CSV")

    }

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listCancellation.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listCancell = [];
        listCancellation.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listCancell.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListCancellation(listCancell);

    }, [totalPageCount, currentPage]);

    const [cancellationData, setCancellationData] = useState([]);

    const handleSearchCancellation = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        cancellationData.forEach(element => {            

            let cadena = element.cancellation_reazon.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setListCancellation(newMethod);
    }



    return {

        listCancellation, id_cancellation_reazon_sample, cancellation_reazon, handleInputChange,
        loading, handleDelete, saveUpdate, handleUpdate,
        typeModalLogin, handleOnChange,  CancellationReazonSample,
        handleCreateTM,toggle3,method3,modalTitle3,modal3,
        saveCreate,saveUpdate,saveDelete,handleCreateCSV,method4,modal4,toogle4,typeModalCsv,
        modalTitle4,file,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchCancellation


    }
}


