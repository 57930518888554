import React from 'react';
import { Collapse } from 'reactstrap';

export const DataCollapsed = ({ isOpen, objCollapsed, longitud }) => {
    return (
        <tr>
            <td colSpan={longitud}>
                <Collapse isOpen={isOpen}>
                    {objCollapsed}
                </Collapse>
            </td>
        </tr>
    )
}
