import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';

export const useProcess = () => {
    const history = useHistory();

    const [processes, setProcesses] = useState([]);
    const [processId, setProcessId] = useState(0);
    const txtsection = localStorage.getItem('TextAS');
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        code: ""
    });

    const { name, code } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        code_valid: false
    });

    const getProcesses = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/List");

        if (respuesta.code === 200) {

            let listProcesses = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;
           

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }  

                    listProcesses.push({
                        id_process: data.id_process,
                        name: data.name,
                        code: data.code,
                        is_operative: data.is_operative,
                        visibleTable: visibleTable
                    });
                });

                setProcesses(listProcesses);
                setProcessData(listProcesses);
            }
            else {
                setProcesses([]);
            }
        }
        else {
            validarSesion(history, respuesta.code, getProcesses);
        }
    }

    useEffect(() => {
        getProcesses();
    }, []);

    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: ""
        });

        setValidaciones({
            name_valid: false,
            code_valid: false
        });

        setProcessId(0);
        setMethod("create");
        setModalTitle("Creación de proceso");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Processes");

        if (respuesta.code === 200) {
            getProcesses();
            reset();
            createSweet("create", "success", "Exito!", "Proceso creado con exito");
            setLoading(false);
            toggle();
        }
        else {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
    }

    const handleUpdate = (id_process) => {
        let process = processes.find(s => s.id_process === parseInt(id_process));

        handleUpdateValues({
            name: process.name,
            code: process.code
        });

        setValidaciones({
            name_valid: false,
            code_valid: false
        });

        setProcessId(id_process);
        setMethod("update");
        setModalTitle("Actualizar proceso");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_process: processId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Processes/Update");

        if (respuesta.code === 200) {
            getProcesses();
            createSweet("create", "info", "Exito!", txtsection+" actualizada");
            setLoading(false);
            toggle();
        }
        else {
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_process) => {
        let process = processes.find(s => s.id_process === id_process);

        handleUpdateValues({
            name: process.name,
            code: process.code
        });

        setProcessId(id_process);
        setMethod("delete");
        setModalTitle("Eliminar proceso");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Processes/${processId}`);
        if (respuesta.code === 200) {
            getProcesses();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Proceso eliminado");
            toggle();
        }
        else {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.code === undefined || formValues.code.length < 2) {
            newValidations = {
                ...newValidations,
                code_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(processes.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listProcess = [];
        processes.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listProcess.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setProcesses(listProcess);

    }, [totalPageCount, currentPage]);


    const [processData, setProcessData] = useState([]);

    const handleSearchSection = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        processData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setProcesses(newMethod);
    }


    return {
        processes, method, validaciones,
        name, code,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchSection
    }
}