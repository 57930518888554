import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';


export const useListCompaniesAgreement = () => {
    const history = useHistory();

    useEffect(() => {
        getAgreementsClients();
    }, [history.location])

    const [agreementClients, setAgreementClients] = useState([]);

    const [createSweet] = useSweetAlert();
    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(agreementClients.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listAgrements = [];
        agreementClients.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAgrements.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAgreementClients(listAgrements);

    }, [totalPageCount, currentPage]);






    const getAgreementsClients = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/ListClientsAgreements");

        if (respuesta.code === 200) {
            let listdocto1 = [];
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;
    
                endItems = aux;
                startItems = endItems - numberItems;
            if (respuesta.data.length >=1) {
                respuesta.data.forEach((element,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listdocto1.push({
                        id_company: element.id_company,
                        code: element.code,
                        name: element.name,
                        rfc: element.rfc,
                        cont_agreements: element.cont_agreements,
                        visibleTable:visibleTable

                    })   
                });     
            }
            setAgreementClients(listdocto1);
            setDataAgreement(listdocto1)
            
        }
     

        // } 
        // else if (respuesta.code === 401) {
        //     validarSesion(history, respuesta.code, getAgreementsClients);
        // }
        // else if(respuesta.code === 500){
        //     createSweet("error", "error", "Actualizar convenio", "Ocurrio un error en el servidor");
        // }
        // else{
        //     createSweet("error", "warning", "Actualizar convenio", respuesta.data.msg);
        // }
    }


    const [dataAgreement, setDataAgreement] = useState([]);


    const handleSearchAgreement = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataAgreement.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setAgreementClients(newMethod);
    }








    const navigateDetail = (id_agreement) => {
        history.push(`../clientes/convenios/${id_agreement}`);
    }

    return {
        agreementClients, navigateDetail,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchAgreement

    }
}
