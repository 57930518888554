import React, { useRef } from 'react'
import CanvasDraw from 'react-canvas-draw';

export const CanvasComponent = (props) => {
    const {
        handleChange, width, height, brushColor, brushRadius, lazyRadius, canvasRef, previousImage = ""
    } = props;

    // const canvasRef = useRef(null);
    // const c = canvasRef.current;

    return (
        <>
            <CanvasDraw
                className='border'
                ref={canvasRef}
                onChange={() => handleChange(canvasRef)}
                canvasWidth={width}
                canvasHeight={height}
                brushColor={brushColor}
                brushRadius={brushRadius}
                lazyRadius={lazyRadius}
                saveData={previousImage} //DESCOMENTAR SI SE QUIERE MOSTRAR LA FIRMA PREVIA Y BLOQUEAR
                // disabled={previousImage !== "" ? true : false}
                loadTimeOffset={0.1}
                immediateLoading={true}
            />
        </>
    )
}
