import React, { useState } from 'react';
import { Collapse, Card, Col, CardBody,Row,Label,Button } from 'reactstrap';

export const CollapseTransaction = ({ items,methodCreate,selected,agre}) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);




    return (
        <div>
            <Card className="shadow">
                <div onClick={toggle} className="p-4" style={{ cursor: "pointer" }}>
                    <div className="align-items-center row">
                        <div className="col-12">
                            <h5 className="mb-0">Historial de transacciones</h5>
                        </div>
                        <div className="text-right col-12">
                        <i className={`icofont icofont-arrow-${isOpen ? "up" : "down"}`}></i>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={isOpen}>
                    <CardBody className="pt-0 pl-4 pr-4">
                        <Col style={{"text-align": "-webkit-right"}}>
                        <Label>Nueva Devolución / Cancelación</Label>
                        <Row className='p-1'>
                            <div className="text-right col-sm-12"  style={{ margin: 5, paddingBottom: 10  }}>
                                <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={() => methodCreate(selected,agre)} ><span className="fa fa-plus-circle"></span></Button>
                            </div>
                        </Row>
                        </Col>
                        <hr />
                        
                      <Col sm="12">
                          <Row>
                              <Col sm="1">
                              </Col>
                              <Col sm="3" className='p-0'> 
                              <h6>{"Motivos del cargo"}</h6>
                              </Col>
                              <Col sm="6" className='p-0'> 
                              <h6>{"Descripción del Cargo"}</h6>
                              </Col>
                              <Col sm="2" className='p-0 text-right'>
                              <h6>&nbsp;&nbsp;&nbsp;{"Monto"}</h6>
                              </Col>
                          </Row>
                      </Col>
                        {
                            items.length > 0 &&
                            items.map((obj, key) => {//pasar a tabla ya que puede crecer 
                                return (
                                    <div key={key} className="align-items-center row pb-2 pt-2 border-bottom">
                                        <Col sm="1">
                                            {
                                                obj.movement === "ingreso" ?
                                                    <i className="fa fa-2x fa-arrow-right text-success"></i>
                                                    :
                                                    <i className="fa fa-2x fa-arrow-left text-danger"></i>
                                            }
                                        </Col>
                                        <Col sm="3">
                                            <h6 className="mb-0">{obj.type}</h6>
                                            <p className="mb-0">{obj.date}</p>
                                        </Col>
                                        <Col sm="6">
                                            <h6 className="mb-0 font-weight-light">{obj.name_concept}</h6>
                                        </Col>
                                        <Col sm="2">
                                            {
                                                obj.movement === "ingreso" ?
                                                    <h5 className="mb-0 text-success f-right p-l-2">$ {obj.amount.toFixed(2)}</h5>
                                                    :
                                                    <h5 className="mb-0 text-danger f-right p-l-2">$ {obj.amount.toFixed(2)}</h5>
                                            }
                                        </Col>
                                    </div>
                                )
                            })
                        }
                    </CardBody>
                </Collapse>
            </Card>
        </div>
    )
}
