import React from 'react'
import { Modal, ModalHeader, Row, Col } from 'reactstrap';
import '../../../assets/css/pantalla1.css'
import { FormEndCancell } from './FormEndCancell';


export const ModalEndCancell = (props) => {

  const {
    method2, toggle2, modal2, loading, navigateInicio, getEncuestaCancell, surnames, nameModal, loading2, modalPrueba, surname, modalPrueba2, loadingPatient
  } = props;


  return (
    <Modal isOpen={modal2} toggle={toggle2} backdrop='static' keyboard={false} centered={true} >

      <div
        className="colorModal-End-muestra"
        style={{ "text-align-last": "center" }}
      >
        <Col sm="12">
          <Row>
            <Col sm="2"></Col>
            <Col sm="8" className="p-2">
              <label className="labelModal-end p-1">
                {" "}
                &nbsp;{"Gracias"}
              </label>
            </Col>
            <Col sm="2" className="p-3">
              <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={toggle2} />

              {/* <i
                className="icofont icofont-error  iconModal-End pointer "
                onClick={toggle}
              ></i> */}
            </Col>
          </Row>
        </Col>
        <br />
      </div>

      {
        method2 === "listEnd"
          ? <FormEndCancell
            button="Guardar"
            {...{ toggle2, loading2, navigateInicio, getEncuestaCancell, surnames, nameModal, modalPrueba, surname, modalPrueba2, loadingPatient }}
          />
          :
          ""
      }
    </Modal>
  )
}
