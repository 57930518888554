import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Select from 'react-select';
import Chart from 'react-apexcharts'
import { CollapseTransaction } from '../../components/agreement/CollapseTransaction';
import { Button, Col, Label, Row,Card} from 'reactstrap';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { CollapseRecordTransaction } from './CollapseRecordTransaction';
//import { useDetailAgreementCompany } from '../../hooks/agreements/useDetailAgreementCompany';

export const InformationAgreement = ({ obj, modal, methodCreate, reloadInformationAgreement }) => {

   


    const { history } = useHistory();
    const [info, setInfo] = useState(obj);

   


    useEffect(() => {
        setInfo(obj);
    }, [])

    const [valuePeriod, setValuePeriod] = useState(obj.selected)
    const [optionsPeriod, setOptionsPeriod] = useState(obj.options)
    const id_agreement = obj.id_agreement;

    const handleValuePeriod = ({ label, value }) => {

        setValuePeriod({
            value: value,
            label: label
        });
        getData(value);
    }

    const getData = async (value) => {
       
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'

        };

        const respuesta = await sendRequest(requestOptions, "Agreement/UpdateFromPeriod/" + id_agreement + "/" + value);
        if (respuesta.code === 200) {
            let element = respuesta.data;

            let percentage = 0;
            if (element.id_financing_methods === 3) {
                percentage = (element.used * 100 / element.limit).toFixed(2);
            } else {
                if (element.limit === 0) {
                    percentage = 100;
                } else {
                    percentage = (element.used * 100 / element.limit).toFixed(2);
                }
            }

            element.options = element.periods;

            element.selected = {
                value: element.number_period,
                label: "Periodo " + element.number_period
            }

            element.graph = {
                series: [percentage],
                options: {
                    chart: {
                        height: 200,
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 15,
                                size: "70%"
                            },
//datos para mostraer em ña ñome a 
                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: true
                                }
                            }
                        }
                    },
                    fill:{
                        type: 'gradient',
                         gradient: {
                          shade: 'dark',
                          type: 'horizontal',
                          shadeIntensity: 0.5,
                          gradientToColors: ['#a6cde7'],
                          inverseColors: true,
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 50]
                           }
                       },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Gastado'],
                }
            }

            element.used = element.used.toFixed(2)
            setInfo(element);
          
        } else {
            validarSesion(history, respuesta.code, getData);
        }
    }//datos mnostrados

    useEffect(() => {
        
        if (reloadInformationAgreement) {
            getData(valuePeriod.value);
        }

    }, [reloadInformationAgreement])

    return (
        <>
            {
                info === null ?
                    <Col md="12">
                        <h6 className="sub-title mb-0 text-center">{"Loader 3"}</h6>
                        <div className="loader-box">
                            <div className="loader-3"></div>
                        </div>
                    </Col>
                    :
                    <Col sm="12" className='p-1 '>
                        <Col xs="12" style={{"text-align": "-webkit-left"}} className ="p-2">
                            <Row>
                                <Col sm="6" style={{"text-align": "right"}} className="p-r-0 p-l-0">
                                 <h4>-Número de contrato: </h4>
                                </Col>
                                <Col  sm="6" className=''>
                                <h3 className='txt-primary'>{info.contract_number}</h3>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="12" className='p-1'>
                            <Row>
                                <Col sm="4" className=" left_side_earning ">
                                    <Card style={{height:"100px"}} className='p-2'>
                                        <Row>
                                            <Col className='col-md-4 p-2' style={{"text-align": "-webkit-right"}}>
                                            <img className="bg-img " src={require("../../assets/images/loginHelenLabs/Agreement/CardAvaible.svg")}/>
                                            </Col>
                                            <Col className='col-md-8 p-2' style={{"text-align-last": "start"}}>
                                            {
                                                    info.id_financing_methods === 3 ?
                                                    <h6 className='text-secondary'><center>Crédito disponible</center> </h6> :
                                                    <h6 className='text-secondary'> <center>Saldo disponible </center> </h6>
                                            }
                                            <center><h4 className='text-success'>$ {(info.limit - info.used).toFixed(2)}</h4></center>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col sm="4">
                                    <Card style={{height:"100px"}} className='p-2'>
                                        <Row>
                                            <Col className='col-md-4 p-2' style={{"text-align": "-webkit-right"}}>
                                            <img className="bg-img " src={require("../../assets/images/loginHelenLabs/Agreement/CardUsed.svg")} style={{ width: "90%" }} alt="" />
                                            </Col>
                                            <Col className='col-md-8 p-2' style={{"text-align-last": "start"}}>
                                                {
                                                    info.id_financing_methods === 3 ?
                                                    <h6 className='text-secondary'><center>Crédito usado</center> </h6> :
                                                    <h6 className='text-secondary'> <center>Saldo usado </center> </h6>
                                                }
                                                <center><h4 className='text-danger'>$ {info.used}</h4></center>
                                            </Col>
                                        </Row>
                                    </Card> 
                                </Col>

                                <Col sm="4" className=" left_side_earning ">
                                    <Card style={{height:"100px"}} className='p-2'>
                                        <Row>
                                            <Col className='col-md-4 p-2' style={{"text-align": "-webkit-right"}}>
                                            <img className="bg-img " src={require("../../assets/images/loginHelenLabs/Agreement/CardInitial.svg")} style={{ width: "85%" }} alt="" />
                                            </Col>
                                            <Col className='col-md-8 p-2' style={{"text-align-last": "start"}}>
                                                {
                                                     info.id_financing_methods === 3 ?
                                                     <h6 className='text-secondary'><center>Crédito inicial</center> </h6> :
                                                     <h6 className='text-secondary'> <center>Saldo inicial </center> </h6>
                                                }
                                                <center><h4 className='text-muted'>$ {(info.limit).toFixed(2)}</h4></center>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col sm="12">
                                    <Row> 
                                        <Col sm="4"> 
                                              
                                            <Card style={{height:"440px"}}>
                                                <Col xs="12" className="text-center">
                                                    <div id="chart-widget5">
                                                        <Chart options={info.graph.options} series={info.graph.series} height="300" type="radialBar" />
                                                    </div>
                                                    <Col xs="12">
                                                        <hr />
                                                    </Col>
                                                 
                                                    <Col xs="12" style={{"text-align": "-webkit-left"}} className="p-1">
                                                    <i className="fa fa-credit-card f-20 text-secondary"></i> &nbsp;
                                                    Tipo de convenio: 
                                                        {
                                                            info.id_financing_methods === 3 ?
                                                                "CRÉDITO" :
                                                                "SALDO"
                                                        } 
                                                    </Col>
                                                    <Col xs="12" style={{"text-align": "-webkit-left"}} className ="p-1">
                                                    <i className= "icon-money f-22 text-warning"></i> &nbsp;
                                                         Método de pago: {info.name_pay_method}
                                                    </Col>
                                                    <Col  xs="12" style={{"text-align": "-webkit-left"}} className="p-1">
                                                    <i className="fa fa-calendar f-20 text-info">  </i> &nbsp;
                                                        Válido hasta: {info.validity}
                                                    </Col>
                                                </Col>
                                            </Card>
                                        </Col>

                                        <Col sm="8">
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="6">
                                                    </Col>
                                                    <Col sm="12"> <br />
                                                    <Select
                                                        classNamePrefix="select"
                                                        name="id_exam_include"
                                                        options={optionsPeriod}
                                                        value={valuePeriod}
                                                        placeholder={`Selección de examenes`}
                                                        isClearable={false}
                                                        onChange={(e) => handleValuePeriod(e)}
                                                    />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <br /><br /> <br /> 
                                            <Col sm="12">
                                            <CollapseTransaction
                                                items={info.listTransactions}
                                                selected={info.selected.value}
                                                agre={info.id_agreement}
                                                {
                                                    ...{methodCreate}
                                                }
                                                />
                                            </Col>
                                            <Col sm="12">
                                            <CollapseRecordTransaction
                                                 items={info.listPaymentPeriods}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>                              
                                </Col>    
                            </Row>
                        </Col>
                        <hr />
                        <br /> <br /> 
                    </Col>       
            }
        </>
    )
}
