import React, { useState } from 'react';
import { Modal, Col, Row, Label, Button, Alert } from 'reactstrap';
import Select from 'react-select';
import 'animate.css';


export const ModalRefusedSample = (props) => {

    const {//refused Sample
        handleSelectValuesR, id_cancellation_reazon_sample, reason, typeModalRefused = "refusedSample",
        modalRefused, toggleRefused, name5, name2, nim, patient, refusedPatient, validationsSG
    } = props;

    const AlertItem = props => {
        const { item } = props;
        const [Open, setOpen] = useState(true);

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={Open}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }

    return (
        <Modal isOpen={modalRefused} toggle={toggleRefused} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalRefused === "refusedSample" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Rechazar Toma del Paciente"}</Label>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <div>

                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className='m-t-10 animate__animated animate__fadeIn'>
                                    {
                                        (validationsSG.id_return_reasonV) &&
                                        <AlertItem
                                            item={
                                                {
                                                    alerttxt: "Debes de seleccionar el motivo por el cual estás rechazando la toma del Paciente.",
                                                    alertcolor: 'danger inverse',
                                                    btnclose: 'default',
                                                    icon: <i className="icon-alert"></i>
                                                }
                                            }
                                        />
                                    }
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="2" className='p-r-0' >
                                                <img style={{ width: "90%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                            </Col>
                                            <Col sm="9" className='p-l-0' > <br />
                                                <label className='txt-secondary f-14'>Responsable:</label>&nbsp;&nbsp;<b className='f-12'>{name2}</b>
                                                <br />
                                                <label className='txt-secondary f-14'>Sección:</label>&nbsp;&nbsp;<b className='f-12'>{"Toma de Muestra"}</b>
                                                <br />
                                                <label className='txt-secondary f-14'>Area:</label>&nbsp;&nbsp;<b className='f-12'>{name5}</b>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <br /> <br />
                                    <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la Toma del paciente:</b>
                                    <br /><br />
                                    <div>

                                        <Select
                                            classNamePrefix="select"
                                            name="id_cancellation_reazon_sample"
                                            value={id_cancellation_reazon_sample}
                                            options={reason}
                                            onChange={(e) => handleSelectValuesR(e, "id_cancellation_reazon_sample")}
                                            isClearable={true}
                                            placeholder={"Selecciona un motivo..."}
                                        />
                                    </div>
                                    <br />
                                    <Row >
                                        <div className='m-t-10 animate__animated animate__flipInX'>
                                            &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Paciente:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{patient}</label>
                                            <br />
                                            &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Nim:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{nim}</label>
                                        </div>
                                    </Row>
                                </div>

                            </Col>
                        </div> <br /> <br />
                        <div>
                            <Col sm="12">
                                <Row>
                                    <Col sm="6">
                                    </Col>
                                    <Col sm="6">
                                        <Row>
                                            <Col sm="6" style={{ textAlign: "end" }} className='p-r-0' onClick={toggleRefused} >
                                                <Button type="button" outline color="danger" size="sm" >Cancelar Rechazo</Button>
                                            </Col>
                                            <Col sm="6" style={{ textAlign: "end" }}>
                                                <Button type="button" outline color="primary" size="sm" onClick={() => refusedPatient()} >Rechazar Muestra</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <br />
                    </>
                    : ""
            }
        </Modal >
    )
}