import React from 'react'
import { useState, useEffect } from "react";
import 'moment/locale/es';
import moment from 'moment';
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from "react-router";
import { LostConnection } from "../../constant";
import { useForm } from '../forms/useForm';


export const useReports = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});
    //CARDS
    const [inactiveCompany, setInactiveCompany] = useState({});
    const [newCompany, setNewCompany] = useState([]);
    const [reactivateCpmany, setReactivateCpmany] = useState({});
    const [n, setN] = useState([]);
    const [i, setI] = useState([]);
    const [r, setR] = useState([]);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues ,handlePickerValues] = useForm({
        date_ini: "",
        date_end: ""
    });

    const {
        date_ini, date_end
    } = formValues;

    const getReportCompany = async () => {

        if (date_ini === '' || date_end === '') {
            return;
        }

        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (date_ini === '') ? moment().format("YYYY-MM-DD") : moment(date_ini).format("YYYY-MM-DD");
        let mto = (date_end === '') ? moment().format("YYYY-MM-DD") : moment(date_end).format("YYYY-MM-DD");

        const respuesta = await sendRequest(requestOptions, `Company/ReportCompany/${mfrom}/${mto}`);
        if (respuesta.code === 200) {

            let copy = series
            let a = (respuesta.data.companiesNewCompanies.length);
            let b = (respuesta.data.companiesInactivatedCompanies.length);
            let c = (respuesta.data.companiesReactivatedCompanies.length);

            let newCompanies = respuesta.data.companiesNewCompanies;
            let inactiveCompanies = respuesta.data.companiesInactivatedCompanies;
            let reactivateCompanies = respuesta.data.companiesReactivatedCompanies;

            copy = [
                {
                    "data": [a, b, c]
                }
            ];

            setOptions(
                {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            radius: 10,
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        colors: ['transparent'],
                        curve: 'smooth',
                        lineCap: 'butt'
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    fill: {
                        colors: [
                            function ({ dataPointIndex }) {
                                let colores = (["#0DC356",
                                    "#D8263E",
                                    "#DC9019",
                                ])
                                return colores[(dataPointIndex + 3) % colores.length];
                            }
                        ],
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            type: 'vertical',
                            shadeIntensity: 0.1,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0.9,
                            stops: [0, 100]
                        }
                    },

                    xaxis: {
                        categories: ["Empresas Nuevas", "Empresas Inactivadas", "Empresas reactivadas"]

                    },
                }
            );

            setSeries(copy)
            setInactiveCompany(inactiveCompanies);
            setNewCompany(newCompanies)
            setReactivateCpmany(reactivateCompanies)
            // setN(copyNuevas)
            // setI(copyInactivas)
            // setR(copyReacivas)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getReportCompany);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Reportes", respuesta.data.msg);

        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        //     validarSesion(history, respuesta.code, getReportCompany);
        // }
    }

    const validInputDate = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }


    const validToInputDate = (current) => {
        
        let date = moment(date_ini).subtract(1, "day");

        return current.isAfter(date);
    }    


    const handleCreate = () => {
        handleUpdateValues({
            date_ini: new Date(),
            date_end: "",
        });
    }


    const obtenerDatos = () => {
        getReportCompany();
    }


    return {
        date_ini, date_end, series, options, handleCreate, obtenerDatos,
        validInputDate, validToInputDate,
        inactiveCompany, newCompany, reactivateCpmany,
        n, i, r,
        handlePickerValues
    }
}