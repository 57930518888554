import React from 'react'
import { Button, Form, ModalBody, ModalFooter,Modal ,ModalHeader,Label,FormGroup,Col,Input} from 'reactstrap';


export const ModalUploadCSV = (props) => {

  const {
    method4,modal4,toogle4,typeModalCsv = "createCSV",CancellationReazonSample,loading,modalTitle4,handleOnChange,file

  } = props;


  return (
    <Modal isOpen={modal4} toggle={toogle4} backdrop='static' keyboard={false} centered={true} onSubmit={CancellationReazonSample}>
      {
        typeModalCsv ==="createCSV" ?
            <>
             <ModalHeader toggle={toogle4} className="bg-primary">
                {"Subir CSV"}
            </ModalHeader>
              <ModalBody>
              <FormGroup>
                <Label className="col-sm-6 coll-form-label">{modalTitle4}</Label>
                <Col sm="12">
                    <Input 
                      className="form-control"
                      accept={".csv"}
                      onChange={handleOnChange}
                      type="file"
                    />
                </Col>
            </FormGroup>
             </ModalBody>
             <ModalFooter>
                 <Button
                     outline
                     color="primary"
                     type="submit"
                     className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                 >
                     Cargar
                 </Button>
                 <Button outline color="danger" type="button" onClick={toogle4}>Cancelar</Button>
             </ModalFooter> 
            </>
            : ""
        }
    </Modal>
  )
}
