import React from 'react'
import { Form, ModalBody,Button,Col,Row,Input,InputGroup,InputGroupAddon,InputGroupText } from 'reactstrap';


export const FormCost = (props) => {

    const {

        loading,handleEditPrice,
        saveCreateCost,price,cost,producction,handleEditPrice2,
        handleMargin


    }= props;


    return (
        <Form className="theme-form" role="form" onSubmit={saveCreateCost}  >
            <Col sm="12">
              <br />
                    <Col sm="12">
                        <Row>
                            <Col sm="4"> <br />
                                <label htmlFor="">Costo de Producción</label> <br /> 
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{"$"}</InputGroupText>
                                </InputGroupAddon>
                                <Input className="form-control" name="production_cost" value={producction} onChange={(e) => handleEditPrice(e)}  />
                                </InputGroup>
                            </Col>

                            <Col sm="4"> <br />
                                <label htmlFor="">Margen de Ganancia</label> <br /> 
                                <InputGroup className="">
                                <Input className="form-control" name="margin_gain" value={cost} onChange={(e) => handleMargin(e)}  /> 
                                <InputGroupAddon addonType="append"><InputGroupText>{"%"}</InputGroupText></InputGroupAddon>
                                </InputGroup>
                            </Col>

                            <Col sm="4"> <br />
                                <label htmlFor="">Precio de Venta</label> <br />  
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{"$"}</InputGroupText>
                                </InputGroupAddon>
                                <Input className="form-control" name="sell_price" value={price}  onChange={(e) => handleEditPrice2(e)} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                </Col>   <br />    <br />
                        <ModalBody  style={{textAlign: "-webkit-right"}}>
                        <Button
                           outline
                           color="primary"
                           type="submit"
                           className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            {"Guardar"}
                        </Button> &nbsp;
                        </ModalBody>
                        </Form>
            
    )
}
