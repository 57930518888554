import React from 'react';
import { Modal, Col, Row, Label, Button, ModalBody,ButtonGroup,Table,FormGroup,Input } from 'reactstrap';
import Select from 'react-select';
import 'animate.css';
import DeniReactTreeView from "deni-react-treeview"
import Checkbox from '@mui/material/Checkbox';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { ToolTips } from '../../../tooltips/ToolTips';

export const ModalSendRefused = (props) => {

    const {
        typeModalRefused = "refused", modalRefused, closeModalCancell, reason, handleSelectValuesR, id_return_reason, loadinginfo,
        flebo, namepatien, datanim, treeviewRef, itemsTree, onRenderItem, refusedPatient,
         idWorkOrder, saveSendSample,validateSpecimen, pruebas,handleView,itemsDontSend,handleDontSend,handleDontSendAll,handlePendingAll, valueDontSend,phlebotomist_name,list_analytes,


    } = props;


   


    return (
        <Modal isOpen={modalRefused} toggle={closeModalCancell} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalRefused === "refused" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Rechazar Muestra"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closeModalCancell} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadinginfo === false ?
                                        <>
                                            <Row className='mt-2' style={{ textAlign: "center" }} >
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    <p className='f-24 text-primary'><b>Cargando</b></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        
                                        <div className='m-t-10 animate__animated animate__fadeIn'>
                                            <Col sm="12" className='p-l-0 p-r-0 mb-3' >
                                            
                                                <Row>
                                                    <Col sm="8" className='p-l-0 p-1' >
                                                        <label className='txt-secondary f-14'>Estudios:</label>
                                                        <ul class="list-group" style={{display:'-webkit-box'}}>
                                                        <li class="list-group-item list-group-item-success">Con muestra</li>
                                                            <li class="list-group-item list-group-item-primary">Remitida</li>
                                                            <li style={{backgroundColor:'#FF8F00'}} class="list-group-item">Sin muestra</li>
                                                            <li class="list-group-item list-group-item-danger">Rechazo</li>
                                                        </ul>
                                                        <DeniReactTreeView
                                                            ref={treeviewRef}
                                                            style={{ marginRight: '10px', marginBottom: '10px', maxHeight: '180px', width: "100%", textAlign: "center" }}
                                                            showCheckbox={true}
                                                            showIcon={false}
                                                            items={itemsTree}
                                                            selectRow={false}
                                                            onRenderItem={onRenderItem}
                                                            className="deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected"
                                                        />
                                                    </Col>
                                                    <Col sm="4" className='p-r-0' > <br /> <br /> <br />
                                                        <label className='txt-secondary f-14'>Paciente:</label>&nbsp;&nbsp;<b className='f-12'>{namepatien}</b> <br />
                                                        <label className='txt-secondary f-14'>NIM:</label>&nbsp;&nbsp;<b className='f-12'>{datanim}</b> <br />
                                                    </Col>

                                                </Row>
                                            </Col>
                                            


                                      
                                  
                                            <div className='m-t-10 animate__animated animate__fadeIn' >
                                                <Col sm="12">
                                                <Row>
                                           <Col sm="7" className='p-l-0 p-r-4 mt-2 mb-1'  style={{border: 'solid 1px', borderColor:'#a5c7e3', maxHeight: '300px', overflowY: 'auto'}}>
                                                  
                                                  {
                                                                                          
                                                                                          itemsDontSend.length > 0 && itemsDontSend.some(item => item.children.some(child => child.state === 2)) || itemsDontSend.some(item => item.state === 2) ? 
                                                                                          <div>
                                                                                              <Table className='b-r-5 mt-5' id="analyteTable">
                                                                                                  <thead className='b-r-5'>
                                                                                                      <tr>
                                                                          
                                                                                                          <th>Exámenes</th>
                                                                                                          <th>Acciones</th>
                                                                                                      </tr>
                                                                                                  </thead>
                                                                                              
                                                                                                  <tbody>
                                                                                                  <tr>
                                                                                                                  <td></td>
                                                                                                                  <td>
                                                                                                                  
                                                                                                                  <Col sm="6">

                                                                                                                    <Row>
                                                                                                                    <Col sm="2">
                                                                                                                            <Checkbox
                                                                                                                            id='tooltip-refused-all'
                                                                                                                            style={{fontSize:'30px'}}
                                                                                                                            icon={< FilterListIcon/>}
                                                                                                                            checkedIcon={< FilterListOffIcon />}
                                                                                                                            onChange={(e) => handleDontSendAll(e)}
                                                                                                                            value={3} 
                                                                                                                            color='error'
                                                                                                                            dataText="Rechazar"
                                                                                                                            
                                                                                                                        />
                                                                                                                        </Col>
                                                                                                                        <Col sm="2">
                                                                                                                        <ToolTips
                                                                                                                                placement="top"
                                                                                                                                dataTarget='tooltip-refused-all'
                                                                                                                                dataText='Rechazar todo'
                                                                                                                            />
                                                                                                                        
                                                                                                                            <Checkbox
                                                                                                                            id='tooltip-pending-all'
                                                                                                                            style={{fontSize:'30px'}}
                                                                                                                            icon={< MoreTimeIcon/>}
                                                                                                                            checkedIcon={< ScheduleIcon />}
                                                                                                                            onChange={(e) => handlePendingAll(e)}
                                                                                                                            value={3} 
                                                                                                                            color='error'
                                                                                                                            dataText="Pendiente"
                                                                                                                            
                                                                                                                        />
                                                                                                                        <ToolTips
                                                                                                                                placement="top"
                                                                                                                                dataTarget='tooltip-pending-all'
                                                                                                                                dataText='Pendientes'
                                                                                                                            />
                                                                                                                            </Col>
                                                                                                                            </Row>
                                                                                                                    </Col>
                                                                                                                  
                                                                                                                  </td>
                                                                                                              </tr>
                                                                                                            
                                                                                                      {
                                                                                                          itemsDontSend.map((_analyte, _index) => {
                                                                          
                                                                                                          const rows = [];
                                                                                                         
                                                                          
                                                                                                          if (_analyte.children && _analyte.children.length > 0) {
                                                                                                             
                                                                                                              _analyte.children.forEach((_a, k) => {
                                                                                                                  if (_a.state === 2) {
                                                                                                                      rows.push(
                                                                                                                          
                                                                                                                          <tr key={k}>
                                                                                                                              <td style={{fontWeight:"bold"}}>{_a.text}</td>
                                                                                                                              <td>
                                                                                                                              
                                                                                                                             
                                                                                                                                  
                                                                                                                                  <Col sm="6">
                                                                                                                                      
                                                                                                                                      <Row>
                                                                                                                                      <Col sm="2">
                                                                                                                                      <Checkbox
                                                                                                                                          id={`tooltip-refused-${k}`}
                                                                                                                                          style={{fontSize:'15px'}}
                                                                                                                                          icon={< RemoveCircleOutlineRoundedIcon/>}
                                                                                                                                          checkedIcon={< BlockRoundedIcon />}
                                                                                                                                          onChange={(e) => handleDontSend(e, _a.id)}
                                                                                                                                          value={1} 
                                                                                                                                          checked={_a.valueDontSend === 1}
                                                                                                                                          color='error'
                                                                                                                                          
                                                                                                                                      />
                                                                                                                                      <ToolTips
                                                                                                                                            placement="top"
                                                                                                                                            dataTarget={`tooltip-refused-${k}`}
                                                                                                                                            dataText='Rechazo'
                                                                                                                                        />
                                                                                                                                      </Col>
                                                                                                                                  
                                                                                                                                      <Col sm="2">
                                                                                                                                      <Checkbox
                                                                                                                                          id={`tooltip-pending-${k}`}
                                                                                                                                          style={{fontSize:'15px'}}
                                                                                                                                          icon={<MoreTimeIcon  />}
                                                                                                                                          checkedIcon={< ScheduleIcon />}
                                                                                                                                          onChange={(e) => handleDontSend(e, _a.id)}
                                                                                                                                          value={2} 
                                                                                                                                          checked={_a.valueDontSend === 2}
                                                                                                                                          color='error'
                                                                                                                                      />
                                                                                                                                      <ToolTips
                                                                                                                                            placement="top"
                                                                                                                                            dataTarget={`tooltip-pending-${k}`}
                                                                                                                                            dataText='Pendiente'
                                                                                                                                        />
                                                                                                                                      </Col>
                                                                                                                                          </Row>
                                                                                                                                          {/* <Col sm="8" className='sizeCol' >
                                                                                                                                              <FormGroup tag="fieldset">
                                                                                                                                                  <FormGroup check>
                                                                                                                                                      <Input
                                                                                                                                                          onChange={(e) => handleDontSend(e, _a.id)}
                                                                                                                                                          name={`radio-${_a.id}`}
                                                                                                                                                          type="checkbox"
                                                                                                                                                          value={1} 
                                                                                                                                                          checked={_a.valueDontSend === 1}
                                                                                                                                                      />
                                                                                                                                                      {'Re '}
                                                                                                                                                      
                                                                                                                                                  </FormGroup>
                                                                                                                                              </FormGroup>
                                                                                                                                          </Col> */}
                                                                                                                                          {
                                                                                                                                          /* <Col sm="6"  className='sizeCol d-none'>
                                                                                                                                              <FormGroup tag="fieldset">
                                                                                                                                                  <FormGroup check>
                                                                                                                                                      <Input
                                                                                                                                                          onChange={(e) => handleDontSend(e, _a.id)}
                                                                                                                                                          name={`radio-${_a.id}`}
                                                                                                                                                          type="number"
                                                                                                                                                          value={2} 
                                                                                                                                                          checked={_a.valueDontSend === 2}
                                                                                                                                                      />
                                                                                                                                                      {' '}
                                                                                                                                                      <Label check>
                                                                                                                                                          Pendiente
                                                                                                                                                      </Label>
                                                                                                                                                  </FormGroup>
                                                                                                                                              </FormGroup>
                                                                                                                                          </Col> */}
                                                                                                                                      
                                                                                                                                  </Col>
                                                                                                                  
                                                                                                                              </td>
                                                                                                                          </tr>
                                                                                                                      );
                                                                                                                  }
                                                                                                                  
                                                                                                              });
                                                                                                          }
                                                                                                      
                                                                                                          if (_analyte.state === 2 && _analyte.children.length === 0) {
                                                                                                              rows.push(
                                                                                                                  <tr key={_analyte.id}>
                                                                                                                      <td className='mt-2' style={{fontWeight:"bold"}}>{_analyte.text}</td>
                                                                                                                      <td>
                                                                                                                      <Col sm="6">
                                                                                                                                      {/* <Row>
                                                                                                                                          <Col sm="8" className='sizeCol' > */}
                                                                                                                                              {/* <FormGroup tag="fieldset">
                                                                                                                                                  <FormGroup check> */}
                                                                                                                                                      {/* <Input
                                                                                                                                                          onChange={(e) => handleDontSend(e, _analyte.id)}
                                                                                                                                                          name={`radio-${_analyte.id}`}
                                                                                                                                                          type="checkbox"
                                                                                                                                                          value={1} 
                                                                                                                                                          checked={_analyte.valueDontSend === 1}
                                                                                                                                                      /> */}
                                                                                                                                          <Row>
                                                                                                                                      <Col sm="2">
                                                                                                                                      <Checkbox
                                                                                                                                          id = {`tooltip-refused-${_analyte.id}`}
                                                                                                                                          style={{fontSize:'15px'}}
                                                                                                                                          icon={< RemoveCircleOutlineRoundedIcon/>}
                                                                                                                                          checkedIcon={< BlockRoundedIcon />}
                                                                                                                                          onChange={(e) => handleDontSend(e, _analyte.id)}
                                                                                                                                          value={1} 
                                                                                                                                          checked={_analyte.valueDontSend === 1}
                                                                                                                                          color='error'
                                                                                                                                          
                                                                                                                                      />
                                                                                                                                       <ToolTips
                                                                                                                                            placement="top"
                                                                                                                                            dataTarget={`tooltip-refused-${_analyte.id}`}
                                                                                                                                            dataText='Rechazo'
                                                                                                                                        />
                                                                                                                                      
                                                                                                                                      </Col>
                                                                                                                                  
                                                                                                                                      <Col sm="2">
                                                                                                                                      <Checkbox
                                                                                                                                         id = {`tooltip-pending-${_analyte.id}`}
                                                                                                                                          style={{fontSize:'15px'}}
                                                                                                                                          icon={<MoreTimeIcon  />}
                                                                                                                                          checkedIcon={< ScheduleIcon />}
                                                                                                                                          onChange={(e) => handleDontSend(e, _analyte.id)}
                                                                                                                                          value={2} 
                                                                                                                                          checked={_analyte.valueDontSend === 2}
                                                                                                                                          color='error'
                                                                                                                                      
                                                                                                                                          
                                                                                                                                      />
                                                                                                                                      <ToolTips
                                                                                                                                            placement="top"
                                                                                                                                            dataTarget={`tooltip-pending-${_analyte.id}`}
                                                                                                                                            dataText='Pendiente'
                                                                                                                                        />
                                                                                                                                      </Col>
                                                                                                                                          </Row>
                                                                                                                                                      
                                                                                                                                                  {/* </FormGroup>
                                                                                                                                              </FormGroup> */}
                                                                                                                                          </Col>
                                                                                                                                          {/* <Col sm="6"  className='sizeCol'>
                                                                                                                                              <FormGroup tag="fieldset">
                                                                                                                                                  <FormGroup check>
                                                                                                                                                      <Input
                                                                                                                                                          onChange={(e) => handleDontSend(e, _analyte.id)}
                                                                                                                                                          name={`radio-${_analyte.id}`}
                                                                                                                                                          type="radio"
                                                                                                                                                          value={2} 
                                                                                                                                                          checked={_analyte.valueDontSend === 2}
                                                                                                                                                      />
                                                                                                                                                      {' '}
                                                                                                                                                      <Label check>
                                                                                                                                                          Pendiente
                                                                                                                                                      </Label>
                                                                                                                                                  </FormGroup>
                                                                                                                                              </FormGroup>
                                                                                                                                          </Col> */}
                                                                                                                                      {/* </Row>
                                                                                                                                  </Col> */}
                                                                                                                      </td>
                                                                                                                  </tr>
                                                                                                              );
                                                                                                          }
                                                                                                      
                                                                                                      
                                                                                                          return rows;
                                                                                                      })
                                                                                                      
                                                                                                      }
                                                                                                  </tbody>
                                                                                              </Table>
                                                                                  
                                                                                              
                                                                                             
                                                                          
                                                                                                              
                                                                                                  
                                                                                  
                                                                              </div>
                                                                              : ""
                                                                          }
                                                                          
                                                                               
                                                                              
                                                  </Col>
                                    
      
                                  
      
                                     
                                                  <Col sm="5" className='mt-2 mb-5' style={{display:'flex'}}>
                                                                 {itemsDontSend.length > 0 && itemsDontSend.some(item => item.children.some(child => child.valueDontSend === 1)) || itemsDontSend.some(item => item.valueDontSend === 1) ? (
                                                                          <div>
                                                                          <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                                                          <br /><br />
                                                                          <div>
                                                                              <Select
                                                                                  classNamePrefix="select"
                                                                                  name="id_return_reason"
                                                                                  value={id_return_reason}
                                                                                  options={reason}
                                                                                  onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                                                                  isClearable={true}
                                                                                  placeholder={"Selecciona un motivo..."}
                                                                                  //selected={itemsDontSend.id_return_reason}
                                                                              />
                                                                          </div>
                                                                          <br />
                                                                          <Col sm="12" className='p-l-0 p-r-0' >
                                                                              <b className='txt-danger f-14'>Responsable:</b>&nbsp;&nbsp;{phlebotomist_name}
                                                                          </Col>
                                                                          <br />
                                                                      </div>
                                                                          ) : null}
                                                                  </Col>    
                                            </Row>                
                              

                               
                                        
                                                </Col>
                                            </div>



                                          
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                               
                                                <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: 'right' }} >
                                                    <Button outline color='primary' size='sm' onClick={() => saveSendSample()} >Aceptar</Button>
                                                    {/* <Button type="button" outline color="danger" size="sm" onClick={() => refusedPatient(idWorkOrder)}>Rechazar</Button> */}
                                                </Col>
                                            </Col>
                                            
                                            <br />
                                           
                                        </div>
                                        

                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }





        </Modal >
    )
}



