import React, { Fragment, useState } from 'react'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Table, Button, Collapse, Progress, Badge } from 'reactstrap'
import { UseListSampling } from '../../../hooks/sanitary/useListSampling';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { useQuatation } from '../../../hooks/quoter/useQuatation';
import { ModalListSamplings } from './ModalListSamplings';

export const IndexListSampligs = () => {

    const [filterDate, setFilterDate] = useState(true);

    const {
        date_from, date_to, obtenerDatos, handlePickerValues, validInputDate, validToInputDate, loading, listQuoter,
        countTotal, countUsed, navigateUpdate,handleListSampling,toggleModalViewListSampling, modalViewListSampling,listSamplesTypeTemp,
        code_Quoter_Temp,downdloadpdf
    } = UseListSampling(); 
//console.log(listQuoter);
    return (
        <Fragment>
            <Col sm="12" className='p-l-0' >
                <Row>
                    <Col sm="3">
                        
                        <Col className="default-according style-1 faq-accordion job-accordion " id="accordionoc" xs="12" sm="12" md="12" lg="12" xl="12">
                            <Row className="position-sticky" style={{ top: 120 }}>
                                <Col xl="12">
                                    <Card>
                                        <br />
                                        <CardHeader className=' bg-primary '>
                                            <h5 className="mb-0">
                                                <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
                                                    data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="fa fa-filter fIconFilter"></i>&nbsp; &nbsp; &nbsp; &nbsp;Filtros</Button>
                                            </h5>
                                        </CardHeader>
                                        <Collapse isOpen={filterDate}> 
                                            <CardBody className="p-3">
                                                <Form className=''>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
                                                                <Datetime
                                                                    key={"date_ini"}
                                                                    inputProps={{
                                                                        placeholder: 'DD-MM-YYYY',
                                                                        name: 'date_ini',
                                                                        autoComplete: "off",
                                                                        className: "form-control form-control-sm input-air-primary"
                                                                    }}
                                                                    timeFormat={false}
                                                                    value={date_from}
                                                                    locale="es"
                                                                    isValidDate={validInputDate}
                                                                    closeOnSelect={true}
                                                                    onChange={(e) => handlePickerValues(e, "date_from")}
                                                                    dateFormat="DD-MM-YYYY"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <br />
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Label className='col-md-12'>Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                                <Datetime
                                                                    key={"date_end"}
                                                                    inputProps={{
                                                                        placeholder: 'DD-MM-YYYY',
                                                                        name: 'date_end',
                                                                        autoComplete: "off",
                                                                        className: "form-control form-control-sm input-air-primary"
                                                                    }}
                                                                    timeFormat={false}
                                                                    value={date_to}
                                                                    locale="es"
                                                                    isValidDate={validToInputDate}
                                                                    closeOnSelect={true}
                                                                    onChange={(e) => handlePickerValues(e, "date_to")}
                                                                    dateFormat="DD-MM-YYYY"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="12">
                                                            <br />
                                                            <Button
                                                                type='button'
                                                                className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
                                                                outline
                                                                color="primary"
                                                                style={{ textAlign: "-webkit-center" }}
                                                                onClick={() => obtenerDatos()}
                                                                >
                                                                {"Buscar"}
                                                            </Button>
                                                            
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col xs="9" sm="9" md="9" lg="9" xl="9">
                        <br />
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {"Detalle"}
                                </div>
                                <div className="table-responsive">
                                    {
                                        listQuoter.length === 0 && listQuoter === null?
                                            <div style={{ textAlign: "-webkit-center" }} >
                                                <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "50%" }} alt="" /> <br />
                                                <label className='f-w-600 f-14 badge badge-light-primary'>0 resultados de su busqueda</label>
                                            </div>
                                            :
                                            <div style={{ textAlign: "-webkit-center" }}> <br /> <br /> <br />
                                                <Table>
                                                    <thead className=" text-center">
                                                        <tr className='border-bottom-info'>
                                                            <th scope="col" >#</th>
                                                            <th scope="col" >Código</th>
                                                            <th scope="col" >Particular/Compañia</th>
                                                            <th scope="col" >Responsable de Muestreo</th>
                                                            <th scope="col" >Autorizo</th>
                                                            <th scope="col" >Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="f-13 text-center">
                                                        {
                                                            listQuoter.length >= 0 && listQuoter.map((obj, key) => {
                                                                return (
                                                                    <tr key={key} className=" border-bottom-info">
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{(key + 1)}</td>
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.code}</td>
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                            {
                                                                                obj.client_type == 1 ?
                                                                                <p>Particular/{obj.name_client}</p>
                                                                                :
                                                                                <p>Compañia/{obj.name_client}</p>
                                                                            }
                                                                        </td>
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.name_responsible}</td>
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.name_person_supervising}</td>

                                                                      
                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                            <Badge
                                                                                id={`tooltip-ticket-${key}`}
                                                                              
                                                                                color={"success"}
                                                                                className='f-12 pointer'
                                                                                onClick={() => handleListSampling(obj.id_quoter)}
                                                                            >
                                                                                <i className="icofont icofont-eye-alt "></i>
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-ticket-${key}`}
                                                                                    dataText="Lista de Muestras"
                                                                                />
                                                                            </Badge>
                                                                            <Badge
                                                                                id={`tooltip-ticket-${key}`}
                                                                              
                                                                                color="dark"
                                                                                className='f-12 pointer'
                                                                                onClick={() => downdloadpdf(obj.id_quoter)}
                                                                            >
                                                                                <i className="icofont icofont-download-alt "></i>
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-ticket-${key}`}
                                                                                    dataText="Hoja de Muestreo/Campo"
                                                                                />
                                                                            </Badge>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <ModalListSamplings
				{
				...{
					toggleModalViewListSampling, modalViewListSampling,listSamplesTypeTemp,code_Quoter_Temp
				}
				}
			/>
        </Fragment>
        
    )
}
