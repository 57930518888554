import React from 'react';
import { useState, useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { useHistory, useParams } from 'react-router-dom';

import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { exam, LostConnection } from '../../constant';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { find } from 'highcharts';

export const useInvoiceClient = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);


    //list
    const [commercialLine, setCommercialLine] = useState([]);
    const [branch, setBranch] = useState({});


    const [patient, setPatient] = useState([]);
    const [cdfi, setCdfi] = useState([]);
    const [dateNims, setDateNims] = useState({});

    //operaciones
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [priceIVA, setPriceIVA] = useState(0);
    const [priceTotal, setPriceTotal] = useState(0);


    useEffect(() => {

        getCdfi();//get cfdi
        getCommercialLines();
        getListBranchCommercialLine();
        // getBranches(); 

    }, [history.location]);


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({

        id_patient: 0,
        id_use_cfdi: 0,
        id_work_order: 0,
        rfc: "",
        examsProfiles: [],
        id_exam: [],
        examProfile: [],
        email: "",
        phone: "",
        id_commercial_line: 0,
        id_branch: 0,
        id_company: 0,
        id_user: 0,
        checkPrint: false,
        checkSend: false,
        name_business_name: ""
    });



    const {
        id_patient, id_use_cfdi, id_work_order, rfc, examsProfiles, id_exam, examProfile, email, phone, commercial_line,
        id_commercial_line, id_branch, id_company, id_user, checkPrint, checkSend, name_business_name
    } = formValues;

    const [validationsInvoice, setValidationsInvoice] = useState({
        name_business_nameValid: false,
        rfc_valid: false,
        email_valid: false,
        phone_valid: false,
        id_commercial_line_valid: false,
        id_branch_valid: false,
        id_use_cfdi_valid: false,
        id_patient_valid: false,
        id_work_order_valid: false
    });

    const getCommercialLines = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListCommercialLine = [];

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListCommercialLine.push({
                        id_commercial_line: value.id_commercial_line,
                        long_name: value.long_name,
                    });
                });
                setCommercialLine(ListCommercialLine);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron las lines sucursales");
                setCommercialLine([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCommercialLines);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }


    const handleChangeCommercial = (e) => {

        handleInputChange(e)

        let p = e.target.value

        getListBranchCommercialLine(p)

    }


    const getListBranchCommercialLine = async (id_commercial_line) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Branch/ListBranchCommercialLine/" + id_commercial_line);

        if (respuesta.code === 200) {
            setBranch(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getListBranchCommercialLine);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }

    const handleChangePatient = (e) => {
        handleInputChange(e)
        let p = e.target.value


        if (p !== 0 && p != null && p !== "") {
            handleGetInformationPatient(p)
            getInfoWorkOrderInvoice(p)
        }
        else {
            handleUpdateValues({
                ...formValues,
                id_patient: 0

            })
        }
    }

    const handleGetInformationPatient = async (id_patient) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Patient/FindPatientId/${id_patient}`);
        if (respuesta.code === 200) {

            handleUpdateValues({
                ...formValues,

                id_patient: id_patient
            })
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, handleGetInformationPatient);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }

    const getCdfi = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListCdfi = [];

        const respuesta = await sendRequest(requestOptions, "UseCfdi/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListCdfi.push({
                        id_use_cfdi: value.id_use_cfdi,
                        description: value.description,
                    });
                });
                setCdfi(ListCdfi);
            }
            else {
                createSweet("error", "warning", "Facturación", "No se encontro ningun Cdfi");
                setCdfi([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCdfi);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }


    const handleChangeStatePatient = (e) => {

        let mValue = e.target.value

        getInfoWorkOrderInvoice(mValue)

        let findPatient = patient.find(p => p.id_patient == mValue)

        if (findPatient) {

            handleUpdateValues({
                ...formValues,
                id_patient: e.target.value
            });
        }
        else {

            handleUpdateValues({
                ...formValues,
                id_patient: e.target.value
            });
        }
    }


    const getInfoWorkOrderInvoice = async (id_patient) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrder/GetInfoWorkOrderInvoice/" + id_patient);

        if (respuesta.code === 200) {
            setDateNims(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInfoWorkOrderInvoice);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }



    const handleChangeExams = (e) => {


        let mValue = e.target.value
        let findExams = dateNims.find(p => p.id_work_order == mValue)

        if (findExams) {

            let exams = examProfile;

            let temp = {};

            temp = {
                nim: findExams.nim,
                name: findExams.name + " " + findExams.paternal_surname + " " + findExams.maternal_surname,
                id_work_order: findExams.id_work_order,
                test: findExams.examsProfiles
            };

            exams.push(temp);
            handleUpdateValues({
                ...formValues,
                examProfile: [...exams],
                id_work_order: e.target.value
            });
            amount();


        }
        else {

            handleUpdateValues({
                ...formValues,
                id_work_order: e.target.value,
            });
        }
    }


    const handleChangeExamsProfiles = () => {

        let array = id_exam;

        let arrays = examProfile;

        if (array.length >= 1) {

            array.forEach(obj => {

                if (!arrays.find(x => x.type == obj.type && x.value == obj.value)) {
                    arrays.push(obj);
                }
            });
        }
        else {

            createSweet("error", "warning", "Facturación", "No se pueden agregar más exámenes de este NIM");
        }

        handleUpdateValues({
            ...formValues,
            examProfile: arrays,
            id_exam: [],

        })
        amount();
    }


    const removeExamProfile = (indice) => {


        let find = examProfile;
        find.splice(indice, 1)

        handleUpdateValues({
            ...formValues,
            examProfile: [...find]
        })
        amount();
    }



    const amount = () => {

        let data = examProfile;

        if (data.length >= 1) {

            let granSubtotal = 0;
            let granDiscount = 0;
            let granIVA = 0;
            let granTotal = 0;

            data.forEach((obj) => {

                obj.test.forEach((i) => {

                    let sub = (i.price / 1.16);

                    //subtotal 
                    granSubtotal += sub;

                    //descuento 
                    granDiscount += parseFloat(i.discount);

                    if (i.discount > 0) {
                        sub = sub - i.discount;
                    }

                    //IVA
                    granIVA += ((sub * 16) / 100);
                })

                //Total 
                granTotal = granSubtotal + granIVA;


                setTotal(granSubtotal)
                setDiscount(granDiscount)
                setPriceIVA(granIVA)
                setPriceTotal(granTotal)
            })
        }
    }

    const saveCreate = async () => {


        if (validarVoice()) {
            setLoading(false);
            return;
        }

        let examenes = [];

        let listExams = examProfile;

        if (listExams.length > 0) {
            listExams.forEach(obj => {
                obj.test.forEach(o => {
                    examenes.push({
                        id_work_order_profile: o.id_work_order_profile,
                        id_work_order_exam: o.id_work_order_exam
                    })
                })
            })
        }

        let body = {
            id_commercial_line: formValues.id_commercial_line,
            id_branch: formValues.id_branch,
            id_patient: formValues.id_patient,
            id_company: formValues.id_company,
            id_use_cfdi: formValues.id_use_cfdi,
            name_business_name: formValues.name_business_name,
            rfc: formValues.rfc,
            email: formValues.email,
            phone: formValues.phone,
            listInvoiceDetails: examenes
        }


        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Invoice/Create");

        if (respuesta.code === 200) {

            let idInvoive = respuesta.data.id_invoice;

            if (idInvoive != 0 && formValues.checkPrint) {
                sendDownloadInvoice(idInvoive);
            }
            else if (idInvoive != 0 && formValues.checkSend) {
                sendDownloadInvoice(idInvoive, formValues.checkSend);
            }
            createSweet("create", "success", "Se genero de manera correcta la factura");
            setLoading(false);

            handleUpdateValues({

                id_patient: 0,
                id_use_cfdi: 0,
                id_work_order: 0,
                rfc: "",
                examsProfiles: [],
                id_exam: [],
                examProfile: [],
                email: " ",
                phone: "",
                id_commercial_line: 0,
                id_branch: 0,
                id_company: 0,
                id_user: 0,
                checkPrint: false,
                checkSend: false,
                name_business_name: ""

            })

            setTotal(0);
            setDiscount(0);
            setPriceIVA(0);
            setPriceTotal(0);
            setPatient(0);
            setDateNims([])
            setCommercialLine([]);
            setBranch([]);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, handleGetInformationPatient);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveCreate);
        //     setLoading(false);
        // }

    };


    const sendDownloadInvoice = async (idInvoive, sendEmail = false) => {

        if (sendEmail) {

            let data = {
                "id_invoice": idInvoive,
                "send_email": sendEmail,
            };

            let requestOptions = {
                method: 'POST',
                body: JSON.stringify(data)
            };

            const respuesta = await sendRequest(requestOptions, `Invoice/CreateInvoiceDocument`);

            if (sendEmail) {
                if (respuesta.code == 200) {
                    createSweet("create", "success", "Se envio correctamente");

                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, sendDownloadInvoice);
                }
                else if(respuesta.code === 500){
                    createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
                }
                else{
                    createSweet("error", "warning", "Facturación", respuesta.data.msg);
                }
            }
        }
        else {

            let data = {
                "id_invoice": idInvoive,
                "send_email": sendEmail,
            };

            let raw = JSON.stringify(data);

            let token = localStorage.getItem("token");

            await fetch(`/api/Invoice/CreateInvoiceDocument`, {
                method: 'POST',
                body: raw,
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "CFDI_ELECTRONICO.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }



    const validarVoice = () => {

        let statusValidation = false;
        let newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValues.name_business_name === "" || formValues.name_business_name === undefined) {
            newValidations = {
                ...newValidations,
                name_business_nameValid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_business_nameValid: false
            };
        }

        if (formValues.rfc.length < 12 || formValues.rfc === "") {
            newValidations = {
                ...newValidations,
                rfc_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                rfc_valid: false
            };
        }

        if (!pattern.test(formValues.email)) {
            newValidations = {
                ...newValidations,
                email_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                email_valid: false
            };
        }

        if (formValues.phone.length < 10 || formValues.phone === "") {
            newValidations = {
                ...newValidations,
                phone_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                phone_valid: false
            };
        }

        if (formValues.id_use_cfdi === undefined || formValues.id_use_cfdi === 0) {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: false
            };
        }

        if (formValues.id_commercial_line === undefined || formValues.id_commercial_line === 0) {
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: false
            };
        }

        if (formValues.id_branch === undefined || formValues.id_branch === 0) {
            newValidations = {
                ...newValidations,
                id_branch_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_branch_valid: false
            };
        }

        setValidationsInvoice(newValidations);


        return statusValidation;




    }


    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }


    return {
        //patient
        patient, id_patient, cdfi, id_use_cfdi, id_work_order, rfc,
        handleInputChange, handleSelectValues, handleUpdateValues,
        handleChangeStatePatient, handleChangeExams, handleChangeExamsProfiles, removeExamProfile,
        examsProfiles, id_exam, examProfile, dateNims,
        email, phone, commercial_line, handleChangePatient, number_format,
        //opereraciones que son mostradas
        total, discount, priceIVA, priceTotal,
        //datos de lineas comerciales
        commercialLine, id_commercial_line, branch, id_branch,
        //commercial linehandleChangeCommercial
        handleChangeCommercial, saveCreate, id_company, id_user, loading,
        //validations 
        validationsInvoice,
        //print Invoice
        checkPrint, checkSend, name_business_name
        //delete Global






    }
}
