import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormUser } from './FormUser';
import { DeleteUser } from './DeleteUser';
import { UpdateRoles } from './UpdateRoles';


export const ModalUser = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        id_helen_users, id_external, name, paternal_surname, maternal_surname, departaments, username, job, card_code, areas,
        handleInputChange, handleSelectValues, validaciones,
        saveCreate, saveUpdate, saveDelete,
        listRol, listRoles, saveUpdatePatient,
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={true} centered={false}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ?
                    <FormUser button='Guardar' {...{ toggle, loading, handleInputChange, handleSelectValues, name, paternal_surname, maternal_surname, job, card_code, departaments, username, validaciones, methodAction: saveCreate, areas }} />
                    :
                    (method === "update")
                        ?
                        <FormUser button={"Actualizar"} {...{ toggle, loading, handleInputChange, handleSelectValues, name, paternal_surname, maternal_surname, job, card_code, departaments, username, areas, validaciones, methodAction: saveUpdate, id_external, name, paternal_surname, maternal_surname, job, card_code, departaments, username, areas }} />
                        :
                        (method === "update_roles")
                            ?
                            <UpdateRoles
                                {
                                ...{
                                    toggle, loading, handleSelectValues,
                                    validaciones, saveUpdatePatient,
                                    name, listRol, listRoles,
                                }
                                }
                            />
                            :
                            <DeleteUser {...{ toggle, loading, username, name, paternal_surname, maternal_surname, saveDelete }} />
            }
        </Modal>
    )
}
