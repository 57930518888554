import React from 'react'
import { Card, Col, Row, Input, Button, Label } from 'reactstrap';
import './StyleProfile.css';
import { ModalPrice } from './Prices/ModalPrice'; 


export const InformationPriceComercialLine = (props) => {

    const {
        objj, handleChange, index, handleDeleteExamGlobal, handleChangeSinToma, handleChangeConToma, totalCommercialLines, sampling_cost, findFirstPrice,        
        titlePrice,typeprice,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard

    } = props;
    //datosmostrados

  

   

    return (
        <>
            {
                objj !== null ?
                    <Col sm="12" className="p-0" >
                        <Card className="p-0" >
                            <div className={objj.color_commercial_line !== "" ? "nameBrowser ribbon ribbon-clip ribbon-left " : "nameBrowser ribbon ribbon-clip ribbon-left ribon-success"} style={{ backgroundColor: objj.color_commercial_line }}>
                                {objj.name_commercial_line}
                            </div>
                            <Row className='pt-5 pb-3' style={{ textAlignLast: "right"}} >
                            </Row>
                            <Row sm="12" className="align-items-center pb-2 pt-2 border-bottom" >
                                <Col sm="12" md="12" lg="12">
                                    <Row>
                                        {
                                            totalCommercialLines.length >= 1 && totalCommercialLines.map((obj, key) => {
                                                if (obj.id_commercial_line == objj.id_commercial_line) {

                                                  //  let gato = ((obj.total_venta_publico_general + ( sampling_cost !== undefined && sampling_cost)).toFixed(2))
                                                    let priceG = (obj.total_venta_publico_general.toFixed(2))

                                                    localStorage.setItem("Perfil", priceG);
                                                  
                                                    return (
                                                        <>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b  className='labelCommercialLine badge-light-primary-Profiles'>Precio de Venta Individual</b> <br /> <br />
                                                                <h6 className="mb-0 text-secondary ">$ {(obj.total_venta_publico_general).toFixed(2)}</h6>

                                                                {/* <h6 className="mb-0 text-secondary ">$ {(obj.total_venta_publico_general + (sampling_cost !== undefined && sampling_cost)).toFixed(2)}</h6> */}
                                                            </Col>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b className='labelCommercialLine badge-light-primary-Profiles'>Costo de toma de muestra</b> <br /> <br />
                                                                <h6 className="mb-0 text-secondary">$ {(sampling_cost !== undefined && sampling_cost.toFixed(2))} </h6>
                                                            </Col>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b className='labelCommercialLine badge-light-primary-Profiles'>Precio Minimo del Perfil</b> <br /> <br />
                                                                <h6 className="mb-0 text-secondary">$ {(obj.minimoProduccion).toFixed(2)}</h6>
                                                            </Col>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b className='labelCommercialLine badge-light-primary-Profiles'>Precio del Perfil al Publico</b>  <br /> <br />
                                                                <h6 className="mb-0  "> <b>$ {obj.descuento_Publico.toFixed(2)} </b> <b>&nbsp;

                                                                {/* <h6 className="mb-0  "> <b>$ {(obj.descuento_Publico + (sampling_cost !== undefined && sampling_cost)).toFixed(2)} </b> <b>&nbsp; */}
                                                                    <Button outline color='success' size='xs' onClick={() => handleprices(key, priceG)} > <i className='icofont icofont-cur-dollar f-14'  ></i> </Button></b>  </h6>                                                                 

                                                                <div className='p-1'>
                                                                {
                                                                    porcentaje === undefined ? ""
                                                                    :
                                                                    <label className='badge-light-primary-PriceFinally '>Nuevo Precio: &nbsp; ${porcentaje}</label>
                                                                }
                                                                </div>
                                                            </Col>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b className='labelCommercialLine badge-light-primary-Profiles'>Precio de Maquila con Toma de Muestra</b> <br />
                                                                 <h6 className="mb-0 ">$ <b> {(obj.descuento_Maquila_sin_toma).toFixed(2)} </b></h6>

                                                                {/* <h6 className="mb-0 ">$ <b> {(obj.descuento_Maquila_sin_toma + (sampling_cost !== undefined && sampling_cost)).toFixed(2)} </b></h6> */}
                                                            </Col>
                                                            <Col sm="2" style={{ textAlign: "-webkit-center" }}>
                                                                <b className='labelCommercialLine badge-light-primary-Profiles'>Precio de Maquila sin Toma de Muestra</b> <br />
                                                                <h6 className="mb-0 "><b> $ {(obj.descuento_Maquila_con_toma).toFixed(2)} </b> </h6>
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <Col>
                                {
                                    objj.exams.length >= 1 && objj.exams.map((o, i) => {
                                        //publico general
                                        let array1 = ((o.price * o.percentage) / 100).toFixed(2);

                                        //maquila sin toma
                                        let array2 = ((o.price * o.percentage_without_toma) / 100).toFixed(2);

                                        //maquila con toma  
                                        let array3 = ((o.price * o.percentage_with_toma) / 100).toFixed(2);

                                        let maxPercentage = (100 - (Math.ceil((o.production_price / o.price) * 1000) / 10)).toFixed(2);

                                        //precio de producción minimo 
                                        let tope = (o.production_price > o.total);
                                        return (

                                            <Row key={i}>
                                                <br /> <br /> <br /> 
                                                <Col sm="12">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <br />
                                                            <Row>
                                                                <Col xs="12" sm="12" md="3" lg="3" xl="3" className='text-center'>
                                                                <div className='ecommerce-widgets media' style={{textAlign : "-webkit-left"}} >
                                                                    <Col sm="2"> 
                                                                        <div className='ecommerce-box light-bg-primary' >
                                                                            <img src={require("../../../assets/images/loginHelenLabs/Profile/PriceProfile.svg")} style={{ width: "85%" }} alt="" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="9">
                                                                    <b className='txt-secondary'>{o.name_exam} </b>&nbsp;
                                                                    </Col>
                                                                    <Col sm="1" className='p-l-0 p-r-0' >
                                                                    <Button outline color="danger" className="btn btn-xs" onClick={() => handleDeleteExamGlobal(o.id_profile_exam, o.id_exam, i)}  ><i className="icofont icofont-bin"></i></Button>
                                                                    </Col>
                                                                </div> <br />
                                                                <Col sm="12">
                                                                <label className="text-secondary">{"Precio de Venta al Público"} </label>
                                                                <label >$ {(o.price).toFixed(2)}</label>
                                                                </Col>
                                                                </Col>
                                                                <Col className='col-md-3 text-center'>
                                                                    <label className="text-secondary">{"Público:"} </label>  <br />
                                                                    <label>{"Descuento"}:&nbsp; {o.percentage} % = <label className="text-danger"> $ {(array1)}</label></label>
                                                                    <Input
                                                                        //disabled={tope}
                                                                        //type="range"
                                                                        className="form-control form-control-sm input-air-primary"
                                                                        min="0"
                                                                        max={maxPercentage}
                                                                        name="resul"
                                                                        //  step="0.1"
                                                                        value={o.percentage }
                                                                        onChange={(e) => handleChange(e, o.id_exam, index, i)}
                                                                    />
                                                                    <label className="text-secondary" >{"Precio Final"} :  $ {(o.total).toFixed(2)}  </label>  <br />
                                                                </Col>
                                                                <Col xs="12" sm="12" md="3" lg="3" xl="3" className='text-center'>
                                                                    <label className="text-secondary">{"Maquila Sin T.M."} </label>  <br />
                                                                    <label>{"Descuento"};&nbsp; {o.percentage_without_toma}  % = <label className="text-danger"> $ {(array2)}</label> </label>
                                                                    <Input
                                                                      //  className='input'
                                                                      //  disabled={tope}
                                                                      //  type="range"
                                                                        className="form-control form-control-sm input-air-primary"
                                                                        min="0"
                                                                        max={maxPercentage}
                                                                        //step="0.1"
                                                                        value={o.percentage_without_toma}
                                                                        onChange={(e) => handleChangeSinToma(e, o.id_exam, index, i)}
                                                                    />
                                                                    <label className="text-secondary" >{"Precio Final"} :  $ {(o.total_without_toma).toFixed(2)}  </label>  <br />
                                                                </Col>
                                                                <Col xs="12" sm="12" md="3" lg="3" xl="3" className='text-center'>
                                                                    <label className="text-secondary">{"Maquila Con T.M."} </label>  <br />
                                                                    <label>{"Descuento"};&nbsp; {o.percentage_with_toma}  % = <label className="text-danger"> $ {(array3)}</label> </label>
                                                                    <Input
                                                                    	className="form-control form-control-sm input-air-primary"
                                                                       // className='input'
                                                                       // disabled={tope}
                                                                       // type="range"
                                                                        min="0"
                                                                        max={maxPercentage}
                                                                        //  step="0.1"
                                                                        value={o.percentage_with_toma}
                                                                        onChange={(e) => handleChangeConToma(e, o.id_exam, index, i)}
                                                                    />
                                                                    <label className="text-secondary" >{"Precio Final"} :  $ {(o.total_with_toma).toFixed(2)}  </label>  <br />
                                                                </Col>
                                                            </Row>
                                                            <br /> <hr />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </Col>
                                                <br /> <br /> <br /> <br /> <br /> <br />
                                                <hr />
                                            </Row>
                                        )
                                    })
                                }
                            </Col>

                            <br /> <br />
                            < >
                                {
                                    findFirstPrice !== undefined 
                                        ?
                                        <div className='tap-top-profile' style={{ display: "block" }} >
                                           <label className='f-14'>{"Precio inicial del perfil $ "+findFirstPrice.price}</label>
                                        </div>
                                        :
                                            ""
                                }
                             </>
                        </Card>
                        {/* {
                            <ModalPrice
                            key={llavecard}
                            {
                                ...{titlePrice,typeprice,handleprices,togglePrice,modalPrice,totalCommercialLines,calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard}
                            }


                            />
                        } */}
                    </Col>
                    
                    :
                    ""
                    // <div>
                    // </div>
                // ""
            }

        </>
        
    )
}
