import React from 'react'
import { useState, useEffect } from 'react'

export const useDebounce = (value, timeOut) => {

    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {

        const timer = setTimeout(() =>
            setDebounceValue(value)
            , timeOut);

        return () => {
            clearTimeout(timer);
        }

    }, [value]);

    return debounceValue;

}
