import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormSpecialty = (props) => {

    const {
        toggle, loading, name,
        handleInputChange, validaciones, methodAction, button
    } = props;
    const {
        name: validacionName,
    } = validaciones;

    return (
        <Form className="form theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-10">
                        <Label className="text-sm">Nombre de la especialidad</Label>
                        <Input
                            type="text"
                            name="name"
                            value={name}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionName}
                            className="form-control form-control-sm input-air-primary" 

                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button 
                size="sm" 
                className="btn-air-light" 
                outline color="danger" 
                type="button" 
                onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
