import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useCSVReader } from 'react-papaparse';
import './StyleAgreement.css';

export const Indexprueba = ({ setDatacsv, handleCsvLoading }) => {


    const { CSVReader } = useCSVReader();

    return (
        <div>
            <CSVReader
                onUploadAccepted={(results) => {
                    setDatacsv(results)
                }}
            >
                {({
                    getRootProps,
                    acceptedFile,
                    ProgressBar,
                    getRemoveFileProps,
                }) => (
                    <>
                        <Row>
                            <Col sm="12" className='p-2' >
                                <Row>
                                    <Col lg="5" xl="5">
                                        <Row>
                                            <Col lg="6" xl="6" className='p-r-0' >
                                                <Button outline size='xs' color='primary' type='button' {...getRootProps()} >
                                                    Cargar csv.
                                                </Button>
                                            </Col>
                                            <Col lg="6" xl="6">
                                                <Button outline size='xs' color='danger' {...getRemoveFileProps()} >
                                                    Remover csv.
                                                </Button>
                                            </Col>
                                        </Row> <br />
                                        <ProgressBar />
                                    </Col>
                                    <Col lg="7" xl="7" className='p-l-0' >
                                        <Row>
                                            <Col lg="6" xl="6">
                                                <b className='text-primary '>{acceptedFile && acceptedFile.name}</b>
                                            </Col>
                                            <Col lg="6" xl="6">
                                                {
                                                    acceptedFile ? <Button outline size='xs' color='primary' onClick={handleCsvLoading}>Cargar Estudios </Button> : ""
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </CSVReader>
            <br />





        </div>
    )
}
