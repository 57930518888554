import React, { Fragment, useState } from 'react'
import { Card, FormGroup, Row, Col, Table, Badge, CardBody, Collapse, Input, Label,CardHeader} from 'reactstrap';
import Select from 'react-select';
import Breadcrumb from '../../../layout/breadcrumb';
import { labelCostumer, labelDescargar, labelEnviar, labelgraph, labelPrint, labelTittle, PlaceHolderOption, } from '../../../constant';
import { useNameCompany } from '../../../hooks/balanceDashboard/useNameCompany';
import 'animate.css';
import Chart from 'react-apexcharts'
import { ModalPrice } from './ModalPrice';
import './styleBalan.css';
import { PaymentsByAgreements } from './PaymentsByAgreements';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { TableStriped } from '../../../components/tables/TableStriped';
import { InformationBalanceCompany } from './InformationBalanceCompany'



export const IndexNameCompany = () => {

    const {
        //COMPONENTS
        id_company, companies, handleSelectCompany, informationCompany, handleCreate,
        //FILES PDF
        downdloadpdf, printPdf, sentPdf,
        //MODAL PRICE AGREEMENTS
        modal, toggle, modalTitle, method, loading, saldoTotal, balancePaid, agreement, amount, saveCreate, handleInputChangeAgreement, total, paymentMethod, pricepaymet,
        //GRAPH AGREEMENT COSTUMER
        series, serie2, option, number_format,
        //TABLE 
        listAgreement, serie3, option3, transaction, cardName, cardPeriodos, cardTotal,
        //VALUES FOR PRINT, DOWNLOAD, SEND PDF
        idAgreementCompany, idPeriodCompany,
        handleChangeAgreement, handleChangePeriod,
        agreementsCompany, periodsAgreement,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchAgrement,
        totalPageCount1,currentPage1, nextPage1, previousPage1, goToPage1



    } = useNameCompany();



    const [isOpen, setIsOpen] = useState(false);

    const togglee = () => setIsOpen(!isOpen);

    return (
        <Fragment>
            {/* <Breadcrumb parent={labelTittle} title="Por Cliente" /> */}
            <Col sm="12">
                <Row  >
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Card className="shadow" >
                        <CardHeader className='bg-primary p-3' >
                            <h6>{labelCostumer}</h6>
                        </CardHeader> <br />
                             <Select
                                    classNamePrefix="select"
                                    name="id_company"
                                    defaultValue={id_company}
                                    options={companies}
                                    placeholder={PlaceHolderOption}
                                    isClearable={true}
                                    onChange={(e) => handleSelectCompany(e, 'id_company')}
                                />
                                <br /> <br />
                        </Card>
                    </Col>
                    <Col  xs="12" sm="12" md="12" lg="6" xl="6" className="p-0 ">
                        <Card style={{ height: "140px" }} className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight ribbon-wrapper shadow p-t-0 p-b-0" : "d-none ribbon-wrapper"} >
                            <CardHeader className="p-3 bg-primary">
                                <h6>Estado de Cuenta</h6>
                            </CardHeader>
                            <Row>
                            <Col xs="4" sm="4" md="4" lg="4" xl="4"  className=' text-center ' style={{ textAlignLast: "center" }}>
                                    <Label>Convenio</Label>
                                    <Input type='select' className="form-control form-control-sm input-air-primary" onChange={(e) => handleChangeAgreement(e)} value={idAgreementCompany}>
                                        <option value="">Selecciona una opción</option>
                                        {
                                            agreementsCompany.length > 0 && agreementsCompany.map( obj => {
                                                return(
                                                    <option value={obj.id_agreement}>{ obj.code+"  ---  "+obj.status }</option>
                                                )
                                            })
                                        }
                                    </Input>
                            </Col>
                            <Col  xs="4" sm="4" md="4" lg="4" xl="4"className=' text-center' style={{ textAlignLast: "center" }}>
                                    <Label>Periodo</Label>
                                    <Input type="select" className="form-control form-control-sm input-air-primary" onChange={(e) => handleChangePeriod(e)} value={idPeriodCompany}>
                                        <option value="">Selecciona una opción</option>
                                        {
                                            periodsAgreement.length > 0 && periodsAgreement.map( obj => {
                                                return (
                                                    <option value={obj.id_agreement_period}>{obj.from_to}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                   </Col>
                                <Col xs="4" sm="4" md="4" lg="4" xl="4" className=' text-center' style={{ textAlignLast : "center" }}>
                                        <Label>Acciones</Label><br />
                                        <button id='btnEmail' color="secondary" class="btn btn-outline-secondary btn-sm buttonlarge" onClick={() => sentPdf()}   >
                                            <i className="icofont icofont-envelope "></i>
                                            <ToolTips
                                                dataPlacement='top'
                                                dataTarget='btnEmail'
                                                dataText='Enviar por correo'
                                            />
                                        </button>      
                                        <button id='btnPdf' color="info" class="btn btn-outline-info btn-sm pagebutton" onClick={() => downdloadpdf()}  >
                                            <i className="icofont icofont-download-alt"> </i>
                                            <ToolTips
                                                dataPlacement='top'
                                                dataTarget='btnPdf'
                                                dataText='Descargar pdf'
                                            />
                                        </button>
                                        <button id="btnPrint" color="info" class="btn btn-outline-primary btn-sm pagebutton" onClick={() => printPdf()}  >
                                            <i className="icofont icofont-print"></i>
                                            <ToolTips
                                                dataPlacement='top'
                                                dataTarget='btnPrint'
                                                dataText='Imprimir estado de cuenta'
                                            />
                                        </button>
                                </Col>

                            <Col>
                        </Col>
                       </Row>                           
                     </Card>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Card className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight ribbon-wrapper shadow " : "d-none ribbon-wrapper"} style={{ height: "350px" }}>
                        <div className="ribbon ribbon-clip ribbon-primary">{"Datos del Cliente"}</div>

                            <div className="theme-form p-2">
                                <Row>
                                    <Col  xs="12" sm="12" md="12" lg="12" xl="12" >
                                        <b>{informationCompany.business_name}</b>
                                    </Col>
                                </Row>
                                <hr /> 
                                <br />
                                <div className="info row">
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                        <div className="ttl-info text-center">
                                            <h6><i className="icofont icofont-id-card f-16 mr-2 arcoiriss"></i>RFC</h6><span>{informationCompany.rfc}</span>
                                        </div>
                                    </Col>
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                        <div className="ttl-info text-center ttl-sm-mb-0">
                                            <h6><i className="icofont icofont-map f-16 mr-2 arcoiriss"></i> Dirección</h6>
                                            <span>
                                                {
                                                    informationCompany.address + " " + informationCompany.colony + " , CP:" + informationCompany.cp
                                                }
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                        <div className="ttl-info text-center ttl-xs-mt">
                                            <h6><i className="icofont icofont-phone f-16 mr-2 arcoiriss"></i>Teléfono</h6><span>{informationCompany.phone}</span>
                                        </div>
                                    </Col>
                                </div>
                                <br />
                                <br />
                                <br />
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Row>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <div className="ttl-info text-center ttl-sm-mb-0">
                                                <h6><i className="icofont icofont-email f-16 mr-2 arcoiriss"></i>Email</h6><span>{informationCompany.contact_email}</span>
                                            </div>
                                        </Col>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <div className="ttl-info text-center ttl-sm-mb-0">
                                                <h6><i className="icofont icofont-book-mark f-16 mr-2 arcoiriss"></i>Giro Comercial</h6><span>{informationCompany.name_company_turn}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Card className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight ribbon-wrapper" : "d-none ribbon-wrapper"} style={{ height: "350px" }}>
                            <div className="ribbon ribbon-clip ribbon-primary" >{"Convenios del Cliente"}</div>
                             {
                              serie3.length === 0 ?
                              <div style={{ textAlign: "-webkit-center"}}>
                                 <img className="bg-img" src={require("../../../assets//images/loginHelenLabs/Alerts/AlertChart.svg")} style={{ width: "38%" }}  alt="" />
                                <br /> <p className="f-16">{"No cuenta con ningún periodo disponible."}</p>
                              </div>
                              :
                              <div style={{ textAlign: "-webkit-center"}}>
                                 <Chart options={option3} series={serie3} type="donut" height={305} style={{ right: "40px" }} />
                                 
                              </div>
                             }
                        </Card>
                    </Col>

                    <Col  xs="12" sm="12" md="12" lg="12" xl="12">
                        <Card className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight ribbon-wrapper shadow p-t-0 p-b-0" : "d-none ribbon-wrapper p-8"} >
                        <CardHeader className='p-3 bg-primary'>
                            <h6>Resúmen</h6>
                        </CardHeader>
                            <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchAgrement} />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {
                                    listAgreement.length === 0 ?
                                    <div style={{ textAlign: "-webkit-center"}}>
                                        <img className="bg-img" src={require("../../../assets//images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "20%" }}  alt="" />
                                        <br /> <p className="f-16">{"No se cuenta con ningún resúmen."}</p>
                                    </div>  
                                        :
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={false}
                                        cabeceras={[ "Convenio", "Emisión", "Vencimiento", "Estado","Saldo","Total"]}
                                        items={listAgreement}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                     />       
                                                    
                                }
                            </div>
                       </Card>
                    </Col>

                     <Col xs="12" sm="12" md="12" lg="12" xl="12" className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight" : "d-none ribbon-wrapper p-2"} >
                        <div className='p-2'>
                            {
                                series.length === 0  ?
                                    <Col sm="6" >
                                    <Card>
                                        <div style={{ textAlign: "-webkit-center"}}>
                                        <img className="bg-img" src={require("../../../assets//images/loginHelenLabs/Alerts/AlertChart.svg")} style={{ width: "35%" }}  alt="" />
                                        <br /> <p className="f-16">{"No hay registrado ningún Saldo en el Convenio."}</p>
                                        </div>
                                        </Card>
                                    </Col>
                                    :
                                    <div>
                                        {
                                        series.map((object, index) => {
                                                return (
                                                    <InformationBalanceCompany
                                                        key={index}
                                                        obj={object}
                                                        {...{ modal, toggle, modalTitle, method, loading, handleCreate }}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </Col>  
                    <Col md="12" sm="12" className={informationCompany.rfc !== undefined ? "animate__animated animate__backInRight" : "d-none ribbon-wrapper p-2"} >
                        {
                            <PaymentsByAgreements
                                listPayments={transaction}
                            />
                        }  
                    </Col>
                </Row>
            </Col>
            <ModalPrice
                balanceTotal={saldoTotal}
                nameAgreement={agreement}
                priceTotal={balancePaid}
                totales={total}
                metodo={paymentMethod}
                id={pricepaymet}
                convertir={number_format}
                {
                ...{
                    modal, toggle, modalTitle, method, loading, amount, handleInputChangeAgreement, saveCreate,
                    method
                }
                }
            />
        </Fragment>
    )
}
