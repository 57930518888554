import React from 'react';
import { Modal, Col, Row, Label, Button } from 'reactstrap';
import Select from 'react-select';
import 'animate.css';


export const ModalRefused = (props) => {

    const { modalRefused, toggleRefused, typeModalRefused = "refused", reason, id_return_reason,
        handleSelectValuesR, obj, key, headerSelected, enableNim, handleViewNim, handleCloseViewNim,
        refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
        inputAndCard, handleInputAndCard,
        focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
        handlerefusedManual, show10, closedModalRefused

    } = props;



    return (
        <Modal key={`refusedM-${key}`} isOpen={modalRefused} toggle={closedModalRefused} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalRefused === "refused" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Rechazar Muestra"}</Label>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <div>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12"  >
                                <Col sm="12" className='p-1' style={{ textAlign: "end" }} >
                                    <Button style={{ textAlignLast: "center" }} outline color={show10 === true ? 'primary' : 'danger'} size='xs' className={refusedPatien.length === 0 ? "" : "d-none"} onClick={handlerefusedManual}  >
                                        <i className="icofont icofont-qr-code p-1"></i>
                                        &nbsp;<label className='labelcreatePatient p-1'>
                                            {
                                                show10 ? "Ingresa Manualmente" : "Regresar"
                                            }
                                        </label>
                                    </Button>
                                </Col>

                                {
                                    show10 === true ?
                                        <Row >
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className={inputAndCard === true ? "d-none" : ""} >
                                                <>
                                                    <div style={{ textAlign: "-webkit-center" }} className="Div-scaner">
                                                        <img style={{ width: "35%" }} src={require("../../../../assetsToma/imagenes/Barras.gif")} />
                                                    </div>
                                                    <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                                        <input
                                                            id='inputFocusRefused'
                                                            autoFocus={setFocusrefused}
                                                            value={refusedNIm}
                                                            onChange={(e) => handleSelectValuesR(e.target.value, "refusedNIm")}
                                                            onKeyDown={onKeyPressRefused}
                                                            onBlur={refusedAutoFocus}
                                                        />
                                                    </div>
                                                    <Col style={{ textAlign: "center" }} >
                                                        <label className='txt-secondary f-16'>Escanea nuevamente la muestra para poder rechazarla</label>
                                                    </Col>
                                                </>
                                            </Col>
                                            {/* <br /> */}
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                {
                                                    refusedPatien.map((objj, inde) => {
                                                        return (
                                                            <div className='m-t-10 animate__animated animate__flipInX'>
                                                                <Col sm="12">
                                                                    <Row>
                                                                        <Col sm="2" className='p-r-0' >
                                                                            <img style={{ width: "90%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />

                                                                        </Col>
                                                                        <Col sm="9" className='p-l-0' > <br />
                                                                            <label className='txt-secondary f-14'>Responsable:</label>&nbsp;&nbsp;<b className='f-12'>{obj.name}</b>
                                                                            <br />
                                                                            <label className='txt-secondary f-14'>Proceso:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.proceso}</b>
                                                                            <br />
                                                                            <label className='txt-secondary f-14'>Sección:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.seccion}</b>
                                                                        </Col>

                                                                    </Row>
                                                                </Col>

                                                                <br /> <br />
                                                                <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                                                <br /><br />
                                                                <div>

                                                                    <Select
                                                                        classNamePrefix="select"
                                                                        name="id_return_reason"
                                                                        value={id_return_reason}
                                                                        options={reason}
                                                                        onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                                                        isClearable={true}
                                                                        placeholder={"Selecciona un motivo..."}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <Row >
                                                                    <div className='m-t-10 animate__animated animate__flipInX'>
                                                                        &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Paciente:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{objj.name}</label>
                                                                        <br />
                                                                        &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Nim:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{objj.nim}</label>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <div>
                                            <Col sm="12" className={refusedPatien.length === 0 ? "" : "d-none"} > <br /> <br />
                                                <Col sm="12" style={{ textAlign: "center" }}>
                                                    <b className='f-14 txt-secondary'>Ingrese de manera manul el nim para poder rechazar la muestra</b>
                                                </Col> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="3">
                                                        </Col>
                                                        <Col sm="6" style={{ textAlign: "center" }} >
                                                            <input
                                                                className="Input-FromCard text-center"
                                                                placeholder="codigo"
                                                                type='text'
                                                                value={refusedNIm}
                                                                name={`refusedNIm`}
                                                                onChange={handleInputChangeR}
                                                            />
                                                            <br /> <br /> <br />
                                                            <Button size='sm' outline color='primary' onClick={() => handleRefusedPatient(obj.id_section, obj.id_process, obj.id_user)}   >
                                                                Buscar
                                                            </Button> <br /> <br /> <br />
                                                        </Col>
                                                        <Col sm="3">
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                                                {
                                                    refusedPatien.map((objj, inde) => {
                                                        return (
                                                            <div className='m-t-10 animate__animated animate__flipInX'>
                                                                <Col sm="12">
                                                                    <Row>
                                                                        <Col sm="2" className='p-r-0' >
                                                                            <img style={{ width: "90%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                                                        </Col>
                                                                        <Col sm="9" className='p-l-0' > <br />
                                                                            <label className='txt-secondary f-14'>Responsable:</label>&nbsp;&nbsp;<b className='f-12'>{obj.name}</b>
                                                                            <br />
                                                                            <label className='txt-secondary f-14'>Proceso:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.proceso}</b>
                                                                            <br />
                                                                            <label className='txt-secondary f-14'>Sección:</label>&nbsp;&nbsp;<b className='f-12'>{headerSelected.seccion}</b>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <br /> <br />
                                                                <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                                                <br /><br />
                                                                <div>

                                                                    <Select
                                                                        classNamePrefix="select"
                                                                        name="id_return_reason"
                                                                        value={id_return_reason}
                                                                        options={reason}
                                                                        onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                                                        isClearable={true}
                                                                        placeholder={"Selecciona un motivo..."}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <Row >
                                                                    <div className='m-t-10 animate__animated animate__flipInX'>
                                                                        &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Paciente:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{objj.name}</label>
                                                                        <br />
                                                                        &nbsp;&nbsp;  &nbsp;&nbsp; <label className='txt-secondary f-16'>Nim:</label> &nbsp;&nbsp;<label className='f-14 text-secondary'>{objj.nim}</label>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>


                                        </div>


                                }

                                <br />
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" > <br /> <br /> <br />
                                    <Row>
                                        <Col xs="6" sm="4" md="6" lg="6" xl="6">
                                        </Col>
                                        <Col xs="4" sm="6" md="4" lg="4" xl="4" style={{ textAlign: "end" }}>
                                            {
                                                refusedPatien.map((a, index) => {
                                                    return (
                                                        <div >
                                                            <Button type="button" outline color="primary" size="sm" onClick={() => refusedPatient(a.id_workOrder)} >Rechazar Muestra</Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>
                                        <Col xs="1" sm="1" md="1" lg="1" xl="1" style={{ textAlign: "end" }}>
                                            <Button type="button" outline color="danger" size="sm" onClick={closedModalRefused} >Cancelar</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </div> <br /> <br />
                    </>
                    : ""
            }


        </Modal >
    )
}