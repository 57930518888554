import React from 'react'
import { Container, Row, Col, Label, Media } from 'reactstrap';


export const ComponentAlternativeQyuestion = (props) => {

    const {
        items, llave, i, saveQuestion
    } = props;



    return (
        <>
            <div >
                {
                    items.alternativeQuestions !== undefined && items.alternativeQuestions.length >= 1 && items.alternativeQuestions.map((o, indice) => {

                        let rowsum = 12
                        let divtam = o.listPaintAlternativeReagents.length
                        let sizeDiv = (rowsum / divtam);
                        return (
                            <div style={{ textAlign: "-webkit-center" }} key={`questionA-${indice}`} > <br /> <br /> <br />
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Row>
                                        {
                                            o.listPaintAlternativeReagents.map((obj, k) => {
                                                return (
                                                    <Col sm={sizeDiv} key={`listQ-${k}`}>
                                                        {
                                                            <Row>
                                                                {
                                                                    obj.background_image === null ?
                                                                        <div>
                                                                            <button
                                                                                className={(obj.selected_response === false ? "confirm-Button-1-encuesta-vacio" : "confirm-Button-1-encuesta-lleno")}
                                                                                value={obj.selected_response}
                                                                                onClick={() => saveQuestion(llave, i, indice, k, 1)} >{obj.reagent}</button>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <label htmlFor="logo" className='avatar'></label>
                                                                            {
                                                                                obj.selected_response === false ?
                                                                                    <Media
                                                                                        body className='img-100 rounded-circle pointer'
                                                                                        src={(obj.cover_background_image !== undefined) ? "data:image/png;base64," + obj.cover_background_image : ""}
                                                                                        onClick={() => saveQuestion(llave, i, indice, k, 1)}
                                                                                    />
                                                                                    :
                                                                                    <Media
                                                                                        body className='img-100 rounded-circle pointer'
                                                                                        src={(obj.background_image !== undefined) ? "data:image/png;base64," + obj.background_image : ""}
                                                                                        onClick={() => saveQuestion(llave, i, indice, k, 1)}
                                                                                    />
                                                                            }
                                                                        </div>
                                                                }

                                                            </Row>
                                                        }
                                                    </Col>
                                                )
                                            })
                                        }

                                    </Row>

                                </Col>

                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
