import React from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import { FormInvoice } from './FormInvoice';

export const ModalInvoice = (props) => {

    const {
        modal, toggle, modalTitle, loading, method, listUseCfdi,
        handleInputChange, validationsInvoice, saveInvoice, cancelInvoice,
        business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice,        
    }= props;



    return (
        <Modal isOpen={modal} toggle={toggle} transparent={true} keyboard={false} centered={true}  className='modal-lg'  >
            <ModalHeader className='bg-primary text-center'>
                {modalTitle}
            </ModalHeader>
           {
            method === "create"
            ?
                <FormInvoice
                    { 
                        ... {
                            toggle, loading, listUseCfdi, handleInputChange, validationsInvoice, saveInvoice,
                            cancelInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice
                        }
                    }
                />
            : (method === "update")
           }
     </Modal>   
    )
}
