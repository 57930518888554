import React from 'react'
import Chart from 'react-apexcharts'
import { Card, Col, Row} from 'reactstrap';
import { labelCondicion, labelTotal, labelVencid,labelVigent} from '../../../constant';
import './styleMicrositio.css';



export const ComponentBalanceCompanyAgreement = ({obj}) => {

    const options1 = {
        chart: {
            type: 'bar',
            height: 150,
            stacked: true,
            toolbar: {
                show: false
            },
            width: '100%',
            stackType: '100%'
        },

        colors: ['#00A878', '#FFFB46', '#f75e25', '#e01410', '#af1a17'],
        plotOptions: {
            showAlways: true,
            bar: {
                horizontal: true,
                vertical: false,
            },
        },
        grid: {
            borderColor: ''
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: [" "],
            labels: {
                show: false
            }
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return "Dias por Vencimiento" + val 
                }
            },
            y: {
                formatter: function (val) {
                    return   "$" + val.toFixed(2)
                }
            }
        },
    };

//conexion de los datos mostrados 
    const handleChangeColorAmount = (amountQuantity, type) => {

        if (type === "totalVencido") {
            return <span style={{ color: "#f00", fontSize: "16px" }}> {amountQuantity}</span>
        }
        else if(type === "totalVigente"){
            return <span style={{ color: "#009624", fontSize: "16px" }}> {amountQuantity}</span>
        }
        else if(type === "saldoTotal"){
            return <span style={{ color: "#000000", fontSize: "16px" }}> {amountQuantity}</span>
        }
        else if(type === "condicionPago"){
            return <span > {amountQuantity}</span>
        }
        else if(type === "metodoPago"){
            return <span style={{ color: "#000000", fontSize: "16px" }}> {amountQuantity}</span>
        }
        else{
            return "";
        }
    }

    return(   
        <>
            {
                obj !== null ? 
                    <Col sm="12" className="p-0" >
                        <Card className="p-0 ">
                            <div className="ribbon ribbon-clip ribbon-primary ">{"Convenio"}: {obj.name_agreement}</div>
                            <br/>
                            <br/>
                            <br/>
                            <Row>
                                <Col className='text-muted' xs="12" sm="12" md="12" lg="3" xl="3" style={{ textAlign: "-webkit-center"}}>
                                    <label className='labelgraph'>{labelCondicion}:<br/> <label className='labelColorAmout'>{obj.payment_condition}</label></label>

                                    </Col> <br />
                                    <Col className='text-muted' xs="12" sm="12" md="12" lg="2" xl="2" style={{textAlign: "-webkit-center" }} >
                                    <label className='labelgraph'>{"Método de Financiación"}: <br/> <label className='labelColorAmout2'>{obj.payment_method}</label></label>
                                </Col> <br />
                                    <Col className="text-muted" xs="12" sm="12" md="12" lg="2" xl="2" style={{ textAlign: "-webkit-center"}} >
                                    <label className='labelgraph'>{labelVencid}:<br/> <label className='labelColorAmount3'> $ {obj.total_overdue.toFixed(2)}</label></label>
                                </Col> <br />
                                <Col className="text-muted" xs="12" sm="12" md="12" lg="2" xl="2" style={{ textAlign: "-webkit-center" }} >
                                    <label className='labelgraph'>{labelVigent}:<br/> <label className='labelColorAmount4'>$ {obj.current_total.toFixed(2)}</label></label>
                                </Col> <br />
                                    <Col className="text-muted" xs="12" sm="12" md="12" lg="2" xl="2" style={{textAlign: "-webkit-center" }} >
                                    <label className='labelgraph'>{labelTotal}:<br/> <label className='labelColorAmount5'> $ {obj.total_balance.toFixed(2)} </label></label>
                                </Col> <br />
                                <Col className='text-muted'>
                                </Col>
                            </Row>
                            <br/>
                            <div>  
                                <Chart options={options1} series={obj.series} type="bar" height={100} />
                            </div>
                            <br /> 
                        </Card>
                        <br />
                    </Col>
                   :
                    <div></div>
            }     
        </>   
    )
}
