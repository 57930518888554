import React, { Fragment } from 'react'
import { Button, CardBody, Col, Container, Row } from 'reactstrap';
import { ListCardDiscount } from './ListCardDiscount';
import { useCommonDiscounts } from '../../hooks/commonDiscounts/useCommonDiscounts';
import { ModalCommonDiscount } from '../../components/commonDiscounts/ModalCommonDiscount';


export const IndexCommonDiscount = () => {

    const {
        //#region MODALS
        modal, toggleModal, loading, method, modalTitle,
        //#endregion
        
        //#region COMMON DISCOUNTS LIST
        listCommonDiscounts, listBranchesTree, listSections,
        listExamsFiltered, listProfiles,    
            
        //#endregion

        //#region AGREEMENTS MODELS

            //FORM
            id_gender,  name, code, valid_from, valid_to, days_of_week,
            percentage, auto_redeem, accumulative, percentage_global, day_from,
            day_to, list_exams_includes, list_exams_excludes, list_profiles_includes,

            //FORM METHODS
            handleInputChange,

            //REFS
            treeViewRef,
        //#endregion

        //#region VALDATIONS
            validationsCommonDiscount,
        //#endregion

        //#region COMMON DISCOUNTS METHODS
        handleCreate, handleChangeSections, handleSelectExamsIncludes, handleChangePercentage,
        handleRemoveTest, handleSelectProfilesIncludes, handleCheckDaysOfWeek, handleDesAuthorized,
        saveCommonDiscount,
        handleUpdate, handleDelete, saveDelete, handleAuthorized, saveAuthorized,
        //#endregion
    } = useCommonDiscounts();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <CardBody className='pl-3 pt-1 pr-3 pb-2'>
                            <div className='ribbon ribbon-clip ribbon-primary'>
                                Listado de campañas
                            </div>
                            <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreate}>
                                    <span className='fa fa-plus-circle'></span>
                                </Button>
                            </div>
                            <Row>
                                <Col md="12" sm="12" lg="12" className='p-1'>
                                    <ListCardDiscount
                                        filterProps={["name"]}
                                        type="list"
                                        items={listCommonDiscounts}
                                        {...{ handleUpdate, handleDelete, handleAuthorized, handleDesAuthorized}}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
            <ModalCommonDiscount
                {
                    ...{
                        modal, toggleModal, modalTitle, method, loading,
                        id_gender,  name, code, valid_from, valid_to, days_of_week,
                        percentage, auto_redeem, accumulative, percentage_global, day_from,
                        day_to, list_exams_includes, list_exams_excludes, list_profiles_includes, 

                        treeViewRef,

                        listBranchesTree, listSections, listExamsFiltered, listProfiles,

                        handleInputChange, handleChangeSections, handleSelectExamsIncludes,
                        handleChangePercentage, handleRemoveTest, handleSelectProfilesIncludes,
                        handleCheckDaysOfWeek, saveCommonDiscount, saveDelete, saveAuthorized,

                        validationsCommonDiscount
                    }
                }
            />
        </Fragment>
    )
}
