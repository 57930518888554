import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { ViewListStudies } from './ViewListStudies';


export const ModalViewStudies = (props) => {

    const {

        modal, toggle, modalTitle, method, studieQ,quoter,codeCard
        
    } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} transparent={true} keyboard={false} centered={true}  className='sm'  >
  
    {
        method === "list"
        ?
            <ViewListStudies
                { ...{toggle, studieQ,quoter,codeCard} }
            />
        :
        ""
    }
</Modal>
   
  )
}
