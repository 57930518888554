import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const AuthorizedCommonDiscount = (props) => {

    const {
        typeAuthorization,
        toggleModal, loading, saveAuthorized, name, code
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={(e) => saveAuthorized(e, typeAuthorization)}>
            <ModalBody> 
                <p className="h6 txt-danger">Estas a punto de { typeAuthorization } la campaña:</p>
                <b>{`${name}(${code})`}</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Sí, { typeAuthorization }
                </Button>
                <Button className="btn-air-light" outline color='danger' type="button" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
