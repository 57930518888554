import React, { useEffect, useState, useRef } from 'react';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';
import { useHistory } from 'react-router-dom';



export const useDashboardExam = () => {

    //#region Prices General

    const history = useHistory();


    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 150);

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])

    const [metaData, setMetaData] = useState([]);
    const [examsData, setExamsData] = useState([]);


    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Price/ListPrice/" + 1,
        history,
        searchQuery
    );

    useEffect(() => {

        let listPrice = [];

        results.forEach((dataa, key) => {

            let total = 0.0;
            let subtotal = 0.0;
            let total_iva = 0.0;

            switch (dataa.config_iva) {
                case true:
                    let price = 0.0;
                    let value_iva = 1 + (dataa.value_iva / 100);

                    price = (dataa.price / value_iva);

                    let with_iva = Math.round((price * dataa.value_iva)) / 100;
                    subtotal += price;
                    total_iva += with_iva;
                    break;
                case false:
                    let simple_price = dataa.price;
                    let iva = (simple_price * dataa.value_iva) / 100;
                    subtotal += simple_price;
                    total_iva += iva;
                    break;
            }

            total = Math.round(subtotal + total_iva);

            total = !isNaN(total) ? Math.round(parseFloat(total.toFixed(2))) : 0;

            let visibleTable = true;

            listPrice.push({
                name_profile: dataa.name_profile + dataa.name_exam,
                name_exam: dataa.name_exam.trim() !== "" ? dataa.name_exam : dataa.name_profile,
                price: formatter.format(total),
                from: dataa.from,
                to: dataa.to,
                curvePrices: dataa.curvePrices,
                visibleTable: visibleTable
            });
        });
        setExamsData(results);
        setMetaData(listPrice);


    }, [results])

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })


    //#endregion Prices Generañ


    return {
        handleSearch, metaData, goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
    }
}
