import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';

export const ModalSectionUser = (props) => {

    const { dataSections, typeModal = "viewP", modalSection, toggle2 } = props;


    return (
        <Modal isOpen={modalSection} toggle={toggle2} backdrop='static' keyboard={false} centered={true} className="modal-sm" >
            <ModalHeader toggle={toggle2} className="bg-primary">
                Secciones
            </ModalHeader>
            {
                typeModal === "viewP" ?
                    <ModalBody>
                        {
                            <div>
                                <ListGroup sm="12">
                                    {
                                        dataSections.map((obj, key) => {
                                            return (
                                                <ListGroupItem key={`Section-${key}`}>
                                                    <i className="fa fa-angle-double-right text-primary"></i>{obj}
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>

                            </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}