import React from 'react'
import { Col, Label, Modal, ModalBody, ModalHeader, Row ,Form,FormGroup,Input,FormFeedback,Button} from 'reactstrap';

export const ModalQuoterFind = (props) => {

    const {
        modalFindQuoter, toggleModalFindQuoter,id_quoter_find,findQuoter,handleInputChangeFind,loading
    } = props;

    return (
        <Modal isOpen={modalFindQuoter} toggle={toggleModalFindQuoter} keyboard={false} centered={true} className="">
            <ModalHeader toggle={toggleModalFindQuoter} className='bg-primary'>
                Buscar Cotización
            </ModalHeader>
            <Form className="form theme-form" role="form" onSubmit="">
                <ModalBody>

                    <Row>
                    <Col md="12">
                            <FormGroup>
                                <Input
                                    type="text" name='id_quoter_find' 
                                    value={id_quoter_find}
                                    required={false}
                                    placeholder='Escribe el código de una cotización'
                                   onChange={handleInputChangeFind}
                                   
                                />
                                <FormFeedback>Elija un metodo de pago</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                        <Button
                            size="sm"
                            outline color="primary"
                            type="submit"
                            onClick={findQuoter}
                            className={loading ? "disabled progress-bar-animated progress-bar-striped text-center" : ""}
                        >
                            Buscar
                        </Button>
                        </Col>

                    </Row>

                </ModalBody>
            </Form>
        </Modal>
    )
}
