import React from 'react'
import Chart from 'react-apexcharts'
import { Card, Col, Row, Button } from 'reactstrap';
import { labelCondicion, labelTotal, labelVencid, labelVigent } from '../../../constant';
import './styleBalan.css';



export const InformationBalanceCompany = ({ obj, handleCreate }) => {


    const options1 = {
        chart: {
            type: 'bar',
            height: 150,
            stacked: true,
            toolbar: {
                show: false
            },
            width: '100%',
            stackType: '100%'
        },

        colors: ['#00A878', '#FFFB46', '#f75e25', '#e01410', '#af1a17'],
        plotOptions: {
            bar: {
                horizontal: true,
                vertical: false,
            },
        },
        grid: {
            borderColor: ''
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },

        xaxis: {
            categories: [" "],
            labels: {
                show: false
            }
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return "Dias por Vencimiento" + val
                }
            },
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }

        },
    };
//movi aqui

    const handleChangeColorAmount = (amountQuantity, type) => {

        if (type === "totalVencido") {
            return <span style={{ color: "#f00", fontSize: "10px" }}> {amountQuantity}</span>
        }
        else if (type === "totalVigente") {
            return <span style={{ color: "#009624", fontSize: "10px" }}> {amountQuantity}</span>
        }
        else if (type === "saldoTotal") {
            return <span style={{ color: "#000000", fontSize: "10px" }}> {amountQuantity}</span>
        }
        else if (type === "condicionPago") {
            return <span style={{ color: "#000000", fontSize: "10px" }}> {amountQuantity}</span>
        }
        else if (type === "metodoPago") {
            return <span style={{ color: "#000000", fontSize: "10px" }}> {amountQuantity}</span>
        }
        else {
            return "";
        }
    }



    return (
        <>
            {
                obj !== null ?
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-0 ribbon-wrapper" >
                        <Card className=''>
                            <div className="ribbon ribbon-clip ribbon-primary ">{"Convenio"}: {obj.name_agreement}</div>
                            <br />
                            <br />
                            <br />
                            <Row className='p-1'>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2" className="text-center text-muted">
                                    <small className="labelAgreementM">{labelCondicion}:<br />{handleChangeColorAmount(obj.payment_condition, 'condicionPago')}</small>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2"  className='text-center text-muted'>
                                    <h7 className="labelAgreementM">{"Método de Financiación"}: <br /> {handleChangeColorAmount(obj.payment_method, 'metodoPago')}</h7>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2"  className="text-center  text-muted">
                                    <h7 className="labelAgreementM">{labelVencid}:<br />$ {handleChangeColorAmount(obj.total_overdue.toFixed(2), 'totalVencido')}</h7>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2"  className=" text-center  text-muted">
                                    <h7 className="labelAgreementM">{labelVigent}:<br />$ {handleChangeColorAmount(obj.current_total.toFixed(2), 'totalVigente')}</h7>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2"  className="text-center  text-muted">
                                    <h7 className="labelAgreementM" >{labelTotal}:<br />$  {handleChangeColorAmount(obj.total_balance.toFixed(2), 'saldoTotal')} </h7>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" xl="2" className=" text-center  text-muted">
                                    <h7 className="labelAgreementM" >{obj.payment_method === "Saldo" ? "Abonar " : "Pagar "} convenio:<br /><Button outline color="primary" className='btnAgreement' onClick={() => handleCreate(obj.total_overdue, obj.name_agreement, obj.id_agreement, obj.balance_paid, obj.total_balance, obj.payment_method, obj.id_financing_method)}  >{obj.payment_method === 'Saldo' ? 'Abonar' : 'Pagar'}  <i className="icofont icofont-bill-alt"></i></Button></h7>
                                </Col>
                            </Row>
                            <br />
                            <div>
                                <Chart options={options1} series={obj.series} type="bar" height={100} />
                            </div>
                        </Card>
                    </Col>
                    :
                    <div></div>
                // ""
            }
        </>
    )
}
