import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button } from 'reactstrap';
export const ModalPreViewMedicalOrder = (props) => {

    const {
        modal, toggle, workMedicalOrderPDF, workMedicalOrderPDFDownload, typeModal, workMedicalOrderPDFLoading = "view",
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggle} className="bg-primary">
                Previsualización PDF
            </ModalHeader>
            {
                typeModal === "view" ?
                    <ModalBody>
                        {
                            workMedicalOrderPDFLoading == true ?
                                <div className="text-center">
                                    Cargando...
                                </div>
                                :
                                <div>
                                    <Col sm="12">
                                        <embed src={workMedicalOrderPDF} title="Previsualizador" type="application/pdf" width="100%" height="600px" />
                                    </Col>
                                    <Col sm="12"> <br />
                                      <Button size="lg" block outline color="success" href={workMedicalOrderPDFDownload} target="_blank" rel="noopener noreferrer">
										<i className="icofont icofont-file-excel" /> Descargar Documento
									  </Button>
                                    </Col>
                                </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}