import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';


export const useListAgreementsPendingPay = () => {
    const history = useHistory();

    const [agreementsPendingPay, setAgreementsPendingPay] = useState([]);

    const numberItems = 3;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(agreementsPendingPay.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listAgreementsPendingPay = [];
        agreementsPendingPay.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAgreementsPendingPay.push({
                ...data,
               
                visibleTable: visibleTable
            });
        });

        setAgreementsPendingPay(listAgreementsPendingPay);

    }, [totalPageCount, currentPage])

    const getAgreementsPendingPay = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/ListAgreementsPendingPay");
        if (respuesta.code === 200) {

            let list1 = []

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >=1 ) {
                respuesta.data.forEach((element,index) => {
                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    list1.push({
                        id_agreement: element.id_agreement,
                        name_client: element.name_client,
                        contract_number: element.contract_number,
                        financing_method: element.financing_method,
                        payment_terms: element.payment_terms,
                        payment_name: element.payment_name,
                        payment_phone: element.payment_phone,
                        payment_email: element.payment_email,
                        amount: element.amount,
                        expiration_date: element.expiration_date,
                        visibleTable: visibleTable
                    })                        
                });
                setAgreementsPendingPay(list1);
                setDatapanding(list1);
            }
        } else {
            validarSesion(history, respuesta.code, getAgreementsPendingPay);
        }
    }

    const cantidad = (amount) => {
        return <span style={{ color: "#f00", fontSize: "12px" }}>$ {amount}</span>
    }


    useEffect(() => {
        getAgreementsPendingPay();
    }, [history.location])

    const [datapanding, setDatapanding] = useState([])


    const handleSearchPanding = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        datapanding.forEach(element => {  

            let cadena = element.contract_number.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setAgreementsPendingPay(newMethod);
    }

    return {
        totalPageCount, currentPage, nextPage, previousPage, goToPage,

        agreementsPendingPay,handleSearchPanding
    }
}
