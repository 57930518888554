import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useBrands = () => {

    const history = useHistory();

    const [brands, setBrands] = useState([]);
    const [brandId, setBrandId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
    });

    const { name } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
    });

    const toggle = () => {
        setModal(!modal);
    }


    const getBrands = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Brand/List");

        if (respuesta.code === 200) {
           // let listBrands = [];
            let listBrands = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >=1) {
                respuesta.data.forEach((data,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
                    
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listBrands.push({
                        id_brand: data.id_brand,
                        name: data.name,
                       
                        visibleTable: visibleTable
                    });
                });
            }

         
            setBrands(listBrands);
            setBrandData(listBrands)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBrands);
            
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Marcas", "Ocurrio un problema en el servidor");
            
        }
        else{
            createSweet("error", "warning", "Marcas", respuesta.data.msg);
            
        }
        // else {
        //     validarSesion(history, respuesta.code, getBrands);
        // }
    }, [history]);


    useEffect(() => {
        getBrands();
    }, [getBrands]);

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(brands.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listBrands = [];
        brands.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listBrands.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setBrands(listBrands);

    }, [totalPageCount, currentPage])

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
        });

        setValidaciones({
            name_valid: false,
        });

        setBrandId(0);
        setMethod("create");
        setModalTitle("Nueva marca");
        toggle();
    }


    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Brand");

        if (respuesta.code === 200) {
            getBrands();
            reset();
            createSweet("create", "success", "Exito!", "Marca creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Marcas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Marcas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_brand) => {
        let brand = brands.find(x => x.id_brand === parseInt(id_brand));

        handleUpdateValues({
            name: brand.name,
        });

        setValidaciones({
            name_valid: false,
        });

        setBrandId(id_brand);
        setMethod("update");
        setModalTitle("Actualizar marca");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_brand: brandId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Brand/Update");

        if (respuesta.code === 200) {
            getBrands();
            createSweet("update", "success", "Exito!", "Marca actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Marcas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Marcas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_brand) => {
        let brand = brands.find(x => x.id_brand === parseInt(id_brand));

        handleUpdateValues({
            name: brand.name,
        });

        setBrandId(id_brand);
        setMethod("delete");
        setModalTitle("Eliminar marca");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Brand/${brandId}`);
        if (respuesta.code === 200) {
            getBrands();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Marca eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Marcas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Marcas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const [brandData, setBrandData] = useState([])

    const handleSearch = (e) => {

       
        let busqueda = e.target.value;
        let newMethod = [];

        brandData.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setBrands(newMethod);
    }





    return {
        brands, method, validaciones,
        name,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearch
    }
};
