import React from 'react';
import { Modal, ModalBody, Col, Row, Button, FormGroup, Card, ModalFooter } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { TimeLineCash } from './TimeLineCash';


export const ModalPreviewCash = (props) => {

    const {
        toggleCash, typeModal = "ViewCas", modalC, dateEnd, dateInit, changeDate, dataHistoric, handleHistoric, formatter, user, branchs
    } = props;


    return (
        <Modal isOpen={modalC} toggle={toggleCash} backdrop='static' keyboard={false} centered={true} size='xl' scrollable={true} >
            <ModalBody className="border-3 b-primary "  >
                {
                    typeModal === "ViewCas" ?
                        <div>
                            <b className='f-16 txt-secondary'>Historial:</b>
                            <hr />
                            <Col sm="12">
                                <Card className='shadow'>
                                    <Row>
                                        <Col sm="6">
                                            <i className="fa fa-circle-o text-info"> </i> <b>Responsable:</b> <label className='text-secondary'>&nbsp;&nbsp;{user}</label>
                                            <br />
                                            <i className="fa fa-circle-o text-info"> </i>  <b>Sucursal:</b><label className='text-secondary'>&nbsp;&nbsp;{branchs}</label>
                                            <br />
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4" className='p-1' >
                                                    <i className="fa fa-circle-o text-info"> </i> <b>Filtrar por fecha:</b>

                                                </Col>
                                                <Col sm="3" className='p-l-0' >
                                                    <FormGroup className="">
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control-sm",
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat="YYYY-MM-DD"
                                                            value={dateInit}
                                                            locale="es"
                                                            initialValue={new moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => changeDate(e, "init")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup >
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control-sm ",
                                                                size: "sm"
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat={"YYYY-MM-DD"}
                                                            locale="es"
                                                            value={dateEnd}
                                                            initialValue={new moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => changeDate(e, "end")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="2" className='p-1' >
                                                    <Button type="button" onClick={() => handleHistoric(true)} outline color="primary" size='xs' className={("text-center mt-1")}> FILTRAR</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                            <Col sm="12" className='p-l-0 p-r-0'>
                                {
                                    <TimeLineCash
                                        items={dataHistoric}
                                        formatter={formatter}
                                    />
                                }
                            </Col>
                        </div>
                        :
                        ""
                }
            </ModalBody>
            {
                <ModalFooter className="border-1 b-primary ">
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleCash}>Cerrar</Button>
                </ModalFooter>
            }
        </Modal>
    )
}
