import React from 'react'
import { useEffect, useState } from 'react';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useHistory } from 'react-router';
import { useForm } from '../forms/useForm';
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';

export const useInvoiceCFDI = () => {

  const [metaData, setMetaData] = useState([]);
  const [examsData, setExamsData] = useState([]);
  const [filters, setfilters] = useState("");
  const [ingress, setIngress] = useState(0);
  const [egress, setEgress] = useState(0);
  const [cCfdi, setCCfdi] = useState(0);
  const [cCancellation, setCCancellation] = useState(0);
  const [tCancellation, setTCancellation] = useState(0);
  const [createSweet] = useSweetAlert();

  const history = useHistory();

  const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
    rfc: "",
    date_start: "",
    date_end: "",
    cancellation: "",
    type_reception: "",

  });

  const { rfc, date_start, date_end, cancellation, type_reception } = formValues;


  const handleFilters = () => {

    let query = "";

    let mfrom = (date_start === '') ? "" : moment(date_start).format("YYYY-MM-DD");
    let mto = (date_end === '') ? "" : moment(date_end).format("YYYY-MM-DD");

    if (rfc !== "") {
      query += `&rfc=${rfc}`;
    }

    if (mfrom !== "") {
      query += `&date_start=${mfrom}`;
    }

    if (mto !== "") {
      query += `&date_end=${mto}`;
    }

    if (cancellation !== "") {
      query += `&cancellation=${cancellation}`;
    }

    if (type_reception !== "") {
      query += `&type_reception=${type_reception}`;
    }

    setfilters(query);
    getIndicators(query)

  }

  useEffect(() => {

    searchAsync();
    getIndicators()

  }, [filters])


  const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
    { method: 'GET' },
    "Metadata/List",
    history,
    "",
    filters
  );

  useEffect(() => {


  
    let listCFDI = [];
    results.forEach((dataa, key) => {

      dataa.total = formatter.format(dataa.total)


      let visibleTable = true;

      listCFDI.push({
        ...dataa,
        visibleTable: visibleTable
      });
    });
    setExamsData(results);
    setMetaData(listCFDI);


  }, [results])

  const getIndicators = async (query) => {

    let requestOptions = {
      method: 'GET'
    };

    const respuesta = await sendRequest(requestOptions, `Metadata/GetIndicators?${query}`);

    if (respuesta.code === 200) {

      setIngress(respuesta.data.total_ingress)
      setEgress(respuesta.data.total_egress)
      setCCfdi(respuesta.data.count_cfdi)
      setCCancellation(respuesta.data.count_cancellation)
      setTCancellation(respuesta.data.total_cancellation)
    }
    else if (respuesta.code === 401) {
      validarSesion(history, respuesta.code, getIndicators);
    }
    else if (respuesta.code === 500) {
      createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
    }
    else {
      createSweet("error", "warning", "Facturación", respuesta.data.msg);
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })


  return {
    metaData,
    goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
    rfc, date_start, date_end, cancellation, type_reception, handleInputChange,
    handleFilters, handlePickerValues,
    //CARDS
    ingress, egress, cCfdi, cCancellation, tCancellation, formatter



  }
}
