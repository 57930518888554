import React, { Fragment } from 'react';
import { Card, Col, Container, Row, Button, CardBody } from 'reactstrap';
import Breadcrumbs from '../../layout/breadcrumb';
import { useDetailAgreementCompany } from '../../hooks/agreements/useDetailAgreementCompany'
import { ButtonGoBack } from '../../constant';
import { useHistory } from 'react-router-dom';
import { InformationAgreement } from '../../components/agreement/InformationAgreement';
import { ModalReturnTransaction } from '../../components/agreement/ModalReturnTransaction';
import './StyleAgreement.css';



export const DetailCompanyAgreements = () => {

    const { company, agreements, toggle, modal, catReason, listAgreementPayments, handleCreateReturn, 
        handleInputChange, method,saveCreate, handleSelectValues, handleChangeNimWorkOrder,
        id_return_reason,amount, reloadInformation,
        nim,id_work_order,
        test,id_work_order_exam,id_work_order_profile,selectWorkOrderTest

        } = useDetailAgreementCompany();
    const history = useHistory();




    return (
        <Fragment>
            {/* <Breadcrumbs parent="Clientesgatos" title="Convenios del cliente ñ listado d elos convenios d emuestra" /> */}
            <Container fluid={true}>
                <Button  className=" btn-air-light m-1"  onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                <Row>
                    <Col xl="5 xl-100">
                        {/* <Card> */}
                            <CardBody>
                                <div className="product-page-details">
                                    <label className='tittle-color f-20'>{company.name}({company.code})</label>
                                </div>
                                <div className="product-price f-18">
                                    RFC: {company.rfc}
                                </div>
                                <hr />
                                <h6>Listado de convenios</h6>
                                <br />

                                {
                                    agreements.length > 0 &&
                                    agreements.map((obj, index) => {
                                        return (
                                            <InformationAgreement
                                           
                                               key={index}
                                                obj={obj}
                                               // obj = {agreements}
                                                modal={modal}
                                                methodCreate={handleCreateReturn}
                                                reloadInformationAgreement={reloadInformation}
                                            />
                                        )
                                    })
                                }
                            </CardBody>
                        {/* </Card> */}
                    </Col>
                </Row>
            </Container>
            <ModalReturnTransaction
                {
                    ...{
                        modal, toggle, catReason, listAgreementPayments, handleInputChange, handleChangeNimWorkOrder, id_return_reason,amount,saveCreate,nim,id_work_order,test,id_work_order_exam,id_work_order_profile, handleSelectValues,selectWorkOrderTest
                    }
                }
            />
        </Fragment>
    )
}

// {
//     "id_agreement": 4,
//     "id_company": 9,
//     "id_pay_method": 1,
//     "id_commercial_line": 1,
//     "id_use_cfdi": 13,
//     "id_financing_methods": 4,
//     "contract_number": "0000001",
//     "validity": "28/12/2021 12:00:00 a. m.",
//     "payment_terms": "PRUEBATP",
//     "sales_name": "LUCERO PEREZ",
//     "sales_phone": "5586965622",
//     "sales_email": "lucero@gmail.com",
//     "payment_name": "PEDRO SIMON",
//     "payment_phone": "5524653622",
//     "payment_email": "pedro@gmail.com",
//     "observations": "SIN OBSERVACIONES",
//     "seller_name": "VENDEDOR",
//     "status": true,
//     "name_company": "CEMEX CONCRETOS ( DIAGONAL )",
//     "code_company": "0000001",
//     "name_pay_method": "EFECTIVO",
//     "abbrevition_pay_method": "0000001",
//     "name_commercial_line": "AEL"
// }