import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useArea = () => {

    const history = useHistory();

    const [areas, setAreas] = useState([]);
    const [areaId, setAreaId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        id_external: ""
    });


    const { name, id_external } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(areas.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listAreas = [];
        areas.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAreas.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAreas(listAreas);
    }, [totalPageCount, currentPage])

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        id_external_valid: false
    });

    const getAreas = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Areas/List");

        if (respuesta.code === 200) {
            let listAreas = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }


                    listAreas.push({
                        ...data,
                        visibleTable: visibleTable
                    });
                });
                setAreas(listAreas);
            }
            else {
                setAreas([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAreas);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);

        }
        // else {
        //     validarSesion(history, respuesta.code, getAreas);
        // }
    }

    useEffect(() => {
        getAreas();
    }, []);


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            id_external: ""
        });

        setValidaciones({
            name_valid: false,
            id_external_valid: false
        });

        setAreaId(0);
        setMethod("create");
        setModalTitle("Crear area");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Areas");

        if (respuesta.code === 200) {
            getAreas();
            reset();
            createSweet("create", "success", "Exito!", "Área creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_area) => {
        let area = areas.find(s => s.id_area === parseInt(id_area));

        handleUpdateValues({
            name: area.name,
            id_external: area.id_external
        });

        setValidaciones({
            name_valid: false,
            id_external_valid: false
        });

        setAreaId(id_area);
        setMethod("update");
        setModalTitle("Actualización de área");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_area: areaId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Areas/Update");

        if (respuesta.code === 200) {
            getAreas();
            createSweet("create", "info", "Exito!", "Área actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Áreas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Áreas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_area) => {
        let area = areas.find(s => s.id_area === id_area);

        handleUpdateValues({
            name: area.name,
            id_external: area.id_external
        });

        setAreaId(id_area);
        setMethod("delete");
        setModalTitle("Eliminar área");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Areas/${areaId}`);
        if (respuesta.code === 200) {
            getAreas();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Área eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }
        ////console.log(parseInt(formValues.id_external));
        if (parseInt(formValues.id_external) === undefined || parseInt(formValues.id_external) <= 0) {
            newValidations = {
                ...newValidations,
                id_external_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_external_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    return {
        areas, method, validaciones,
        name, id_external,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }

}
