import React, { Fragment,useRef,useState} from 'react'
import Chart from 'react-apexcharts'
import {  Card, CardBody, CardHeader,Col,Media, Row,Table ,Container,NavItem,NavLink,Nav,TabContent,TabPane} from 'reactstrap'
import 'animate.css';
import { ToolTips } from '../../components/tooltips/ToolTips';

export const ComponentDetailPatient = (props) => {

    const {patient_excluded,handleClosedInformations,type_filter_person,number_format,patient_Include,patient_Excluded,total_patient,filterCon,setFilterCon,filterSin,setFilterSin,totalP,exportToExcel} = props;

    //*CONFIGURATION GRAPH BAR
    const nims = [];
    const data = [];
    const colors = [];

    patient_excluded.forEach(_find => {

      const amount = parseFloat(_find.amount) || 0;
      const nim = _find.nim ?? "";
      const isTrue = _find.patient_invoice === "True";

      data.push(amount);
      nims.push(nim);
      colors.push(isTrue ? "#51bb25" : "#dc3545");
    });

    const apexMixedCharts = {
      series: [{
        name: 'Total',
        type: 'column',
        data: data
      }],
      options: {
        chart: {
          height: 250,
          type: 'line',
          stacked: false,
          toolbar: {
            show: true
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false 
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        xaxis: {
          labels: {
            show: false 
          }
        },
        labels: nims,
        markers: {
          size: 0
        },
        colors:colors,
        tooltip: {
          shared: false,
          intersect: false,
        }
      }
    };

    //*CONFIGURATION TABLE
    const [activeTab, setActiveTab] = useState('1');

    const totalRow2 = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td ></td>
        <td style={{textAlign: "end"}} className='labeltheadInvoice'>Total:</td>
        <td style={{textAlign: "center"}}  className='labeltheadInvoice'>${number_format(totalP, 2)}</td>
        <td ></td>
    </tr> 
    );

    return (
        <Fragment>
        <Container fluid={true} className='p-l-0 animate__animated animate__fadeInRight ' >
          <Row>
            {
              //!FILTER DETAIL PATIENTS
            }
            <Col xl="3" className="box-col-6 pr-0 file-spacing p-l-0">
              <div className="file-sidebar" >
                <Card className='shadow-detail-patient '  >
                <Col sm="12"> <br /> 
                  <b className=''>Indicadores Globales</b> 
                  </Col> <br />
                <div className='hr-detail-patient' >
                  </div> 
                  <CardBody>
                  <ul>
                  <li onClick={() => {setFilterCon(false); setFilterSin(false);}}>
                    <div className={`btn ${!filterCon && !filterSin ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`}>
                      <i className="icofont icofont-lock f-14"></i> &nbsp; &nbsp;{"Todos los datos"}
                    </div>
                  </li>
                  <li onClick={() => {setFilterCon(true); setFilterSin(false);}}>
                    <div className={`btn ${filterCon ? 'btn-success-gradien' : 'btn-outline-success-2x'}`}>
                      <i className="icofont icofont-lock f-14"></i> &nbsp; &nbsp;{"Pacientes Incluidos"}
                    </div>
                  </li>
                  <li onClick={() => {setFilterSin(true); setFilterCon(false);}}>
                    <div className={`btn ${filterSin ? 'btn-danger-gradien' : 'btn-outline-danger-2x'}`}>
                      <i className="icofont icofont-lock f-14"></i> &nbsp; &nbsp;{"Pacientes Excluidos"}
                    </div>
                  </li>
                </ul>
                    <hr />
                 
                    <hr />
                    <ul>
                
                      <li>
                        <div className="pricing-plan">
                        <h6>{"Acumulado"}</h6> <br />
                        <h5 className='txt-success' >${number_format(patient_Include,2) ?? 0}</h5>
                          <img className="bg-img" src={require("../../assets/images/dashboard/folder.png")} alt="" />
                        </div>
                       
                      </li>
                      <li>
                        <div className="pricing-plan">
                          <h6>{"Diferencia"}</h6> <br />
                          <h5 className='txt-danger' >${number_format(patient_Excluded,2) ?? 0}</h5>
                          <img className="bg-img" src={require("../../assets/images/dashboard/folder1.png")} alt="" />
                        </div>
                        
                      </li>
                    </ul> <br /> <br />
                  </CardBody>
                </Card>
              </div>
            </Col>
            {
              //!DETAIL PATIENTS 
            }
            <Col xl="6" md="12" className="box-col-12 p-r-0">
              <div className="file-content">
                <Card className='shadow-detail-patient-table' style={{ height: "520px" }}  >
                  <CardHeader>
                  <Row>
                        <Col sm="9">
                        </Col>
                        <Col sm="1" className='p-l-0' >
                          <div id='btn-tooltip-excel' className='icon-div-user bg-success pointer' onClick={() => exportToExcel(data)}>   
                          <i className="fa fa-file-excel-o f-18">
                          <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-excel'
                            dataText={"Desargar Excel"}
                          />
                          </i>
                          </div>
                        </Col>
                        <Col sm="1" className='p-l-0' >
                          <div id='btn-tooltip-filter-public' className='icon-div-user bg-warning pointer' >
                          <i className="fa fa-users f-18">
                          <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-filter-public'
                            dataText={"Total:" + " " + total_patient + " " + "Pacientes"}
                          />
                          </i>
                          </div>
                        </Col>
                        <Col sm="1" className='p-l-0' >
                        <div id='btn-tooltip-detail' className='icon-div-user bg-secondary pointer' onClick={handleClosedInformations} >
                        <i className=" icofont icofont-arrow-left bg-secondary f-20 ">
                        <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-detail'
                            dataText='Regresar'
                          />
                        </i>
                        </div>
                        </Col>
                      </Row>
                  </CardHeader>
                  <CardBody  >
                    {
                      type_filter_person ==="P" ?
                      <div className='table-wrapper'>
                        
                         <Table className='b-r-5 table table-sm' >
                                  <thead className='b-r-5' style={{textAlign : "center"}} >
                                   <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
                                     <th>Nim</th>
                                     <th>Paciente</th>
                                     <th>Sucursal</th>
                                     <th>Total</th>
                                     <th>Detalle</th>
                                   </tr>
                                 </thead>
                                 <tbody>
                                  {
                                  patient_excluded.map((_find, _key) => (
                                  
                                    (filterCon && _find.patient_invoice === "True") ||
                                    ((!filterCon && !filterSin) || (_find.patient_invoice === "False" && filterSin)) && (
                                      <tr key={_key}>
                                        <td className='f-w-700 text-warning'>{_find?.nim ?? "----"}</td>
                                        <td>{_find?.patient ?? "------"}</td>
                                        <td style={{textAlign: "center"}}>{_find.name_branch ?? "------"}</td>
                                        <td style={{textAlign: "center"}} className={_find.patient_invoice === "False" ? "f-w-700 txt-danger" : "f-w-700 txt-success"}>
                                          ${number_format(_find?.amount, 2) ?? 0}
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                          {_find.patient_invoice === "False" ? <i className='fa fa-times text-danger'></i> : <i className='fa fa-check txt-success'></i>}
                                        </td>
                                      </tr>
                                    )
                                  ))
                                  }
                                  {totalRow2}
                                  </tbody>
                                  </Table>

                      </div>
                      :
                      <div>
                      </div>

                    }                   
                  </CardBody>
              
                </Card>
              </div>
            </Col>
            {
              //!CONFIGURATION GRAPH AND HISTORIC 
            }
            <Col xl="3" md="12" className="box-col-12 p-r-0">
              <div id="mixedchart">
              <Chart options={apexMixedCharts.options} series={apexMixedCharts.series} type="line" height={250} />
              </div>
              <Card className='shadow' style={{ height: '255px', overflowY: "scroll"}} >
              <Nav tabs className="nav  border-tab nav-success">
                  <NavItem  id="myTab" role="tablist">
                    <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                      {"Historico"}
                    </NavLink>
                  </NavItem>
                  <NavItem  id="myTab" role="tablist">
                    <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                      {"Detallado"}
                    </NavLink>
                  </NavItem>             
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                <div className="people-list">
                    <ul className="list digits custom-scrollbar">
                      {
                      patient_excluded?.map((_invoices,_key) => {
                        if (_invoices.patient_invoice === "True") {
                          return;
                        }
                      return(
                      <li className="clearfix" key={_key} >
                        <div className='contenedor-prueba-1 '>
                        <Media style={{width :"80px" , filter:"grayscale(1)" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                        </div>
                        <div className="about" style={{textAlign : "center"}} >
                          <div className="name">{_invoices?.nim ?? "----"}
                            <div className="status-patient">
                            <i className="fa fa-reply font-danger"></i> {"Paciente que requirio factura"}
                            </div>
                          </div>
                        </div>
                      </li>
                      )
                        } )
                      }    
                      </ul>
                      </div>
                  </TabPane>
                  <TabPane tabId="2">
                      <div className="status">
                      <hr />            
                      <hr />
                      </div>
                  </TabPane> 
                  </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>   
        </Fragment>
    )
}
