import { log } from '@antv/g2plot/lib/utils';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ExampleMultipleSelect } from '../../constant';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useInvoiceCompany = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();

    const [useCfdi, setUseCfdi] = useState([]);
    const [branches, setBranches] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [businessNames, setBusinessNames] = useState([]);
    const [commercialLines, setCommercialLines] = useState([]);

    const [businessNameData, setBusinessNameData] = useState({});

    const [inputTest, setInputTest] = useState([]);
    const [examProfileTest, setExamProfileTest] = useState([]);


    //operaciones
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [priceIVA, setPriceIVA] = useState(0);
    const [priceTotal, setPriceTotal] = useState(0);


    //modal Invoice Company

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    //const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [arrayExamProfileCheck, setArrayExamProfileCheck] = useState([]);



    //#region LOADING FIRST DATA

    const getCompanies = useCallback(async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Company/List");

        if (respuesta.code === 200) {

            let mCompanies = [];

            respuesta.data.forEach(obj => {
                mCompanies.push({
                    value: obj.id_company,
                    label: obj.code + ' ' + obj.name,
                })
            });

            setCompanies(mCompanies);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCompanies);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {
        getCompanies()
    }, [getCompanies]);

    const getCommercialLines = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listCommercialLine = [];

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code == 200) {

            if (respuesta.data.length > 0) {

                respuesta.data.forEach(obj => {
                    listCommercialLine.push({
                        value: obj.id_commercial_line,
                        label: obj.long_name
                    });
                });

                setCommercialLines(listCommercialLine);
            }
            else {
                setCommercialLines([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCommercialLines);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {
        getCommercialLines();
    }, [getCommercialLines]);

    const getUseCfdi = useCallback(async () => {

        let requestOptions = {
            method: 'GET'
        };

        let listUseCfdi = [];

        const respuesta = await sendRequest(requestOptions, "UseCfdi/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length > 0) {

                respuesta.data.forEach(obj => {
                    listUseCfdi.push({
                        label: obj.description,
                        value: obj.id_use_cfdi
                    });
                });

                setUseCfdi(listUseCfdi);
            } else {
                setUseCfdi([]);
            }

        } 
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getUseCfdi);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }

    }, [history]);

    useEffect(() => {
        getUseCfdi();
    }, [getUseCfdi]);



    //#endregion


    const toggle = () => {
        setModal(!modal);
    }


    //#region FORM VALUES
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_company: 0,
        id_company_business_name: 0,
        id_commercial_line: 0,
        id_exam_profile: 0,
        id_branch: 0,
        id_use_cfdi: 0,
        checkPrint: false,
        checkSend: false,
        examProfile: [],
        date_ini: "",
        date_end: "",
        email: "",
        phone: "",
        //check_Invoice: false,
    });

    const {
        id_company, id_company_business_name, id_commercial_line, id_exam_profile,
        id_branch, id_use_cfdi, checkPrint, checkSend, examProfile, date_ini, date_end,
        email, phone

    } = formValues;
    //#endregion


    useMemo(() => {

        let subtotal = 0;


    }, [examProfileTest]);

    const handleChangeCompany = (e) => {

        handleSelectValues(e, "id_company");

        if (e !== undefined) {

            handleUpdateValues({
                ...formValues,
                id_company: e,
                id_company_business_name: 0
            });

            hanldeGetBusinessNames(e.value)
        }
    }

    const hanldeGetBusinessNames = async (id_company) => {
        let requestOptions = {
            method: 'GET'
        };

        const listCompaniesNames = [];

        const respuesta = await sendRequest(requestOptions, `Company/ListBusinessNames/${id_company}`);

        if (respuesta.code === 200) {
            if (respuesta.data.length > 0) {

                let data = respuesta.data;

                data.forEach(obj => {
                    listCompaniesNames.push({
                        label: obj.rfc + " " + obj.business_name,
                        value: obj.id_company_business_name
                    });
                });

                setBusinessNames(listCompaniesNames);
            }
            else {
                setBusinessNames([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, hanldeGetBusinessNames);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }

    const handleChangeCommercialLine = (e, name) => {
        handleSelectValues(e, name);

        if (e != undefined) {

            handleUpdateValues({
                ...formValues,
                id_commercial_line: e,
                id_branch: 0
            });

            handleGetBranch(e.value);
        }
    }

    const handleGetBranch = async (id_commercial_line) => {
        let requestOptions = {
            method: 'GET'
        };

        let listBranches = [];

        const respuesta = await sendRequest(requestOptions, `Branch/ListBranchCommercialLine/${id_commercial_line}`);

        if (respuesta.code === 200) {

            if (respuesta.data.length > 0) {

                respuesta.data.forEach(obj => {
                    listBranches.push({
                        label: (obj.code == null ? "" : obj.code) + " " + obj.name,
                        value: obj.id_branch
                    });
                });

                setBranches(listBranches);

            } else {
                setBranches([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, handleGetBranch);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }

    const handleChangeBusinessName = (e, name) => {
        handleSelectValues(e, name);

        if (e != undefined) {

            handleGetInformationBusinessName(e.value);
        }
    }

    const handleGetInformationBusinessName = async (id_business_name) => {
        if (id_business_name != "") {

            let requestOptions = {
                method: 'GET'
            };

            const respuesta = await sendRequest(requestOptions, `Company/GetDetailBusinessName/${id_business_name}`);

            if (respuesta.code === 200) {
                let mData = {
                    rfc: respuesta.data.rfc,
                    phone: respuesta.data.phone,
                    email: respuesta.data.email
                };

                setBusinessNameData(mData);
            }
            else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code, handleGetInformationBusinessName);
            }
            else if(respuesta.code === 500){
                createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
            }
            else{
                createSweet("error", "warning", "Facturación", respuesta.data.msg);
            }
        }
    }

    const handleFilterInvoice = () => {
        let url_alternative = "";

        if (id_company.value === undefined) {
            createSweet("error", "error", "Selecciona una empresa");
            return;
        }

        if (id_exam_profile !== 0 && id_exam_profile !== null) {

            let test = id_exam_profile.split("-");

            let idTest = test[0];
            let typeTest = test[1];

            if (typeTest === "0") {
                url_alternative = `?id_exam=${idTest}`;
            }
            else {
                url_alternative = `?id_profile=${idTest}`;
            }

            if (date_ini !== "" && date_end !== "") {
                url_alternative += `&date_ini=${date_ini}&date_end=${date_end}`
            }
        }
        else if (date_ini !== "" && date_end !== "") {
            url_alternative = `?date_ini=${date_ini}&date_end=${date_end}`;
        }

        getWorkOrders(id_company.value, url_alternative);

    }

    const getWorkOrders = async (idCompany, alternativeUrl,) => {
        let requestOptions = {
            method: 'GET'
        };


        setArrayExamProfileCheck([]);


        const respuesta = await sendRequest(requestOptions, `WorkOrder/GetInfoWorkOrderInvoiceCompany/${idCompany}${alternativeUrl}`);
        if (respuesta.code === 200) {

            let array2 = examProfile;
            let array1 = respuesta.data;

            if (array2.length >= 0) {
                array2.forEach(element => {

                    let f = array1.find(x => x.id_work_order == element.id_work_order);

                    let fIndex = array1.findIndex(x => x.id_work_order == element.id_work_order);

                    if (f) {

                        f.examsProfiles.forEach((o, i) => {

                            let id_work_order_exam = o.id_work_order_exam;
                            let id_work_order_profile = o.id_work_order_profile;

                            if (id_work_order_exam !== null && id_work_order_profile === null) {

                                let findExam = f.examsProfiles.find(e => e.id_work_order_exam === id_work_order_exam);
                                let findExamIndex = f.examsProfiles.findIndex(e => e.id_work_order_exam === id_work_order_exam);

                                if (findExam) {
                                    f.examsProfiles[findExamIndex].checked = true;
                                }
                            }
                            else if (id_work_order_profile !== null && id_work_order_exam === null) {

                                let findProfile = f.examsProfiles.find(e => e.id_work_order_profile === id_work_order_profile);
                                let findProfileIndex = f.examsProfiles.findIndex(e => e.id_work_order_profile === id_work_order_profile);

                                if (findProfile) {
                                    f.examsProfiles[findProfileIndex].checked = true;
                                }
                            }

                        });
                        array1[fIndex] = f;
                    }

                });
            }
            setArrayExamProfileCheck([...array1])
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getWorkOrders);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }



    const removeExamProfile = (indice) => {

        let copyTest = examProfile;

        copyTest.splice(indice, 1);

        setExamProfileTest([...copyTest]);
        amount();
    }


    const handleCheckInvoice = (e, i, ichildren) => {

        let listWorkOrders = examProfile

        let data = arrayExamProfileCheck[i];

        data.examsProfiles[ichildren].checked = e.target.checked;

        if (listWorkOrders.length == 0) {

            listWorkOrders.push(data);
        }
        else {

            let findWorkOrder = listWorkOrders.find(x => x.id_work_order === data.id_work_order);//esta undefinded
            let findWorkOrderIndex = listWorkOrders.findIndex(x => x.id_work_order === data.id_work_order);///si teniamos que encontrar el indice era eso entonces sip

            if (findWorkOrder) {

                findWorkOrder.examsProfiles[ichildren].checked = e.target.checked;

                data.examsProfiles.forEach((o, i) => {
                    let id_work_order_exam = o.id_work_order_exam;
                    let id_work_order_profile = o.id_work_order_profile;

                    if (id_work_order_exam !== null && id_work_order_profile === null) {

                        let findExam = findWorkOrder.examsProfiles.find(e => e.id_work_order_exam === id_work_order_exam);

                        if (!findExam) {
                            findWorkOrder.examsProfiles.push(o);
                        }
                    }
                    else if (id_work_order_profile !== null && id_work_order_exam === null) {

                        let findProfile = findWorkOrder.examsProfiles.find(e => e.id_work_order_profile === id_work_order_profile);

                        if (!findProfile) {
                            findWorkOrder.examsProfiles.push(o);
                        }
                    }
                });

                listWorkOrders[findWorkOrderIndex] = findWorkOrder;
            }
            else {
                listWorkOrders.push(data);
            }
        }

        handleUpdateValues({
            ...formValues,
            examProfile: [...listWorkOrders]
        })

        amount();
    }

    const amount = () => {

        let data = examProfile;


        if (data.length >= 1) {

            let granSubtotal = 0;
            let granDiscount = 0;
            let granIVA = 0;
            let granTotal = 0;

            data.forEach((obj) => {


                obj.examsProfiles.forEach((i) => {

                    let sub = (i.price / 1.16);

                    granSubtotal += sub;

                    granDiscount += parseFloat(i.discount);

                    if (i.discount > 0) {
                        sub = sub - i.discount;
                    }

                    granIVA += ((sub * 16) / 100);
                })

                granTotal = granSubtotal + granIVA;


                setTotal(granSubtotal)
                setDiscount(granDiscount)
                setPriceIVA(granIVA)
                setPriceTotal(granTotal)
            })
        }
    }

    const handleCreateInvoice = () => {

        setMethod("list");
        toggle();
    }



    const saveCreate = async (e) => {

        e.preventDefault();
        setLoading(true);

        let idC = formValues.id_company.value
        let idCL = formValues.id_commercial_line.value
        let idB = formValues.id_branch.value
        let idCBN = formValues.id_company_business_name.value
        let idCFI = formValues.id_use_cfdi.value


        let examenes = [];

        let listExams = examProfile;


        if (listExams.length > 0) {
            listExams.forEach(obj => {
                obj.examsProfiles.forEach(o => {
                    examenes.push({
                        id_work_order_exam: o.id_work_order_exam,
                        id_work_order_profile: o.id_work_order_profile
                    })
                })
            })
        }


        let body = {
            id_commercial_line: idCL,
            id_branch: idB,
            id_company: idC,
            id_company_business_name: idCBN,
            id_use_cfdi: idCFI,
            email: formValues.email,
            listInvoiceDetails: examenes

        }


        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Invoice/Create");

        if (respuesta.code === 200) {

            let idInvoive = respuesta.data.id_invoice;

            if (idInvoive != 0 && formValues.checkPrint) {
                sendDownloadInvoice(idInvoive);
            }
            else if (idInvoive != 0 && formValues.checkSend) {
                sendDownloadInvoice(idInvoive, formValues.checkSend);
            }
            createSweet("create", "success", "Se genero de manera correcta la factura");
            setLoading(false);
            toggle();

            handleUpdateValues({

                id_company: 0,
                id_company_business_name: 0,
                id_commercial_line: 0,
                id_exam_profile: 0,
                id_branch: 0,
                id_use_cfdi: 0,
                checkPrint: false,
                checkSend: false,
                examProfile: [],
                date_ini: "",
                date_end: "",
                email: "",
                phone: "",
            })

            setBusinessNameData([]);
            setExamProfileTest([]);
            setArrayExamProfileCheck([]);

            setTotal(0);
            setDiscount(0);
            setPriceIVA(0);
            setPriceTotal(0);

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Facturación", respuesta.data.msg);
        }
    }

    const sendDownloadInvoice = async (idInvoive, sendEmail = false) => {

        if (sendEmail) {

            let data = {
                "id_invoice": idInvoive,
                "send_email": sendEmail,
            };

            let requestOptions = {
                method: 'POST',
                body: JSON.stringify(data)
            };

            const respuesta = await sendRequest(requestOptions, `Invoice/CreateInvoiceDocument`);

            if (sendEmail) {
                if (respuesta.code == 200) {
                    createSweet("create", "success", "Se envio correctamente");

                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, sendDownloadInvoice);
                }
                else if(respuesta.code === 500){
                    createSweet("error", "error", "Facturación", "Ocurrio un problema en el servidor");
                }
                else{
                    createSweet("error", "warning", "Facturación", respuesta.data.msg);
                }
            }
        }
        else {

            let data = {
                "id_invoice": idInvoive,
                "send_email": sendEmail,
            };

            let raw = JSON.stringify(data);

            let token = localStorage.getItem("token");

            await fetch(`/api/Invoice/CreateInvoiceDocument`, {
                method: 'POST',
                body: raw,
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "CFDI_ELECTRONICO.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }




    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    return {
        id_company, id_company_business_name, id_commercial_line, id_exam_profile, id_branch, id_use_cfdi, checkPrint, checkSend, date_ini, date_end,
        companies, businessNames, commercialLines, branches, useCfdi, businessNameData,
        handleSelectValues, handleInputChange,
        handleChangeCompany, handleChangeCommercialLine, handleChangeBusinessName, handleFilterInvoice,
        removeExamProfile,
        examProfileTest, inputTest, number_format,
        examProfile, handleCheckInvoice,
        //        check_Invoice
        email, phone, saveCreate,
        total, discount, priceIVA, priceTotal,
        toggle, handleCreateInvoice, method, modal, loading,
        arrayExamProfileCheck

    }
}
