import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row, Collapse, Input, Table } from 'reactstrap';


export const ComponenName = ({analyteCurves,key}) => {
  return (
    <div key={key}>
        {
            <label className=''>{analyteCurves.name}</label>
        }
        
    </div>
  )
}
