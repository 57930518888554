import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';

export const FormPatient = (props) => {
    const {
        button, method, toggle, loading,

        validationsPatient,

        methodAction,

        handleSelectValuesPatient, handleDatePatient, handleInputChangePatient, togglePatient,

        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,

        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,

        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,

        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender,
        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE
    } = props
    
    const {
        id_gender_valid,
        paternal_surname_valid,
        maternal_surname_valid,
        name_valid,
        phone_valid,
        email_valid,
    } = validationsPatient;



    return (
        <>
            <ModalBody className='p-b-0' >
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Información general</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='text' name="name" value={name} autoComplete='off' onChange={handleInputChangePatient} invalid={name_valid} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Nombre inválido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" name='paternal_surname' value={paternal_surname} autoComplete='off' onChange={handleInputChangePatient} invalid={paternal_surname_valid} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Completa este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3" className='p-1' >
                        <FormGroup>
                            <Label className='col-form-label'>Apellido materno </Label>
                            <Input type="text" name='maternal_surname' value={maternal_surname} autoComplete='off' onChange={handleInputChangePatient} className='form-control form-control-sm input-air-primary' />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Género<span className='f-16' style={{color: "#0079C7"}}>*</span></Label>
                            <Input type='select' name="id_gender" onChange={handleInputChangePatient} className='form-control form-control-sm input-air-primary' value={id_gender} invalid={id_gender_valid} >
                                <option value={0}>Selecciona una opción</option>
                                <option value={1}>Femenino</option>
                                <option value={2}>Másculino</option>
                            </Input>
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Teléfono <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='tel' name='phone' value={phone} autoComplete='off' onChange={handleInputChangePatient} invalid={phone_valid} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Completa este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Correo<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='tel' name='email' value={email} autoComplete='off' onChange={handleInputChangePatient} className='form-control form-control-sm input-air-primary' invalid={email_valid} />
                                    <FormFeedback>Ingresa un correo valido</FormFeedback>
                                </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type="button" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} onClick={methodAction}>
                    {button}
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={togglePatient}>Cancelar</Button>
            </ModalFooter>
        </>
    )
}
