import React from 'react'
import { ViewListprofile } from './ViewListprofile'
import { Modal, ModalHeader } from 'reactstrap';


export const ModalViewProfiles = (props) => {

    const {

        modal2, toggle2, method,infoData
    } = props;

    return (
        <Modal isOpen={modal2} toggle={toggle2} keyboard={false} centered={true}  className='sm'  >
  
        {
            method === "list"
            ?
                <ViewListprofile
                { ...{toggle2,infoData} }
                />
            :
            ""
        }
      </Modal>
    )
}
