import React, { Fragment, useState } from 'react'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Container,Button, Collapse,Progress } from 'reactstrap'
import './styleDashboardDoctor.css';
import { useReportDoctor } from '../../hooks/doctorDashboard/useReportDoctor';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";

export const IndexDashboardDoctor = () => {

   
    const [filterDate, setFilterDate] = useState(true);

    const {

        date_ini, date_end,obtenerDatos,handlePickerValues,validInputDate,validToInputDate,loading

    } = useReportDoctor()


    //tr-work-order-doctor
    //32
    //trquoter used at === null - no se usa, !=null ya esta usasdo
  
    return (
    <Fragment>
            <Col sm="12" className='p-l-0' > 
            <Row>
                <Col sm="3">
                </Col>
          
            <Col xs="9" sm="9" md="9" lg="9" xl="9">
                <Card className='shadow' style={{ height: "145px"}}>
                        <div className='ecommerce-widgets media'>
                            <Col sm="12">
                                <Row>
                                <Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
                                    <Row>
                                        <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                            <div className='ecommerce-box light-bg-primary'>
                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <label className='f-20'>{"0"}</label>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label className='txt-secondary'>Ordenes de trabajo Generadas</label>
                                        </Col>
                                        <Col sm="12">
                                        <Progress className="sm-progress-bar" color="primary" value={1} style={{ height: "8px" }} />

                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
                                    <Row>
                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" >
                                            <div className='ecommerce-box light-bg-primary'>
                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <label className='f-20'>${"0.00"}</label>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label className='txt-secondary f-16'>Ganancias Generadas</label>
                                        </Col>
                                        <Col sm="12">
                                        <Progress className="sm-progress-bar" color="primary" value={1} style={{ height: "8px" }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
                                    <Row>
                                        <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                            <div className='ecommerce-box light-bg-primary'>
                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <label className='f-20'>{"0"}</label>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label className='txt-secondary f-16'>Pacientes Totales</label>
                                        </Col>
                                        <Col sm="12">
                                        <Progress className="sm-progress-bar" color="primary" value={1} style={{ height: "8px" }} />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>
                        </div>
                    </Card>
                </Col>
            </Row>
                   
            </Col>
            <Col sm="12">
                <Row>
                <Col className="default-according style-1 faq-accordion job-accordion " id="accordionoc" xs="12" sm="12" md="3" lg="3" xl="3">
								<Row className="position-sticky" style={{ top: 120 }}>
									<Col xl="12">
										<Card>
											<br />
											<CardHeader className=' bg-primary '>
												<h5 className="mb-0">
													<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
														data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="fa fa-building"></i>&nbsp; &nbsp; &nbsp; &nbsp;Doctores</Button>
												</h5>
											</CardHeader>
											<Collapse isOpen={filterDate}>
												<CardBody className="p-3">
													<Form className=''>
														<Row>
															<Col md="12">
																<FormGroup>
																	<Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
																	<Datetime	
																		key={"date_ini"}																										
																		inputProps={{
																			placeholder: 'DD-MM-YYYY',
																			name: 'date_ini',
																			autoComplete: "off",
																			className: "form-control form-control-sm input-air-primary"
																		}}
																		timeFormat={false}
																		value={date_ini}
																		locale="es"
																		isValidDate={validInputDate}
																		closeOnSelect={true}
																		onChange={(e) => handlePickerValues(e, "date_ini")}
																		dateFormat="DD-MM-YYYY"	
																	/>																
																</FormGroup>
															</Col>
															<br />
															<Col md="12">
																<FormGroup>
																	<Label className='col-md-12'>Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																	<Datetime	
																		key={"date_end"}																										
																		inputProps={{
																			placeholder: 'DD-MM-YYYY',
																			name: 'date_end',
																			autoComplete: "off",
																			className: "form-control form-control-sm input-air-primary"
																		}}
																		timeFormat={false}
																		value={date_end}
																		locale="es"
																		isValidDate={validToInputDate}
																		closeOnSelect={true}
																		onChange={(e) => handlePickerValues(e, "date_end")}
																		dateFormat="DD-MM-YYYY"	
																	/>
																</FormGroup>
															</Col>
															<Col sm="12">
																<br />
																<Button
																	type='button'
																	className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																	outline
																	color="secondary"
																	style={{ textAlign: "-webkit-center" }}
																	onClick={() => obtenerDatos()}
																>
																	{"Buscar"}
																</Button>
															</Col>
														</Row>
													</Form>
												</CardBody>
											</Collapse>
										</Card>
									</Col>
								</Row>
		   </Col>
                    <Col xs="9" sm="9" md="9" lg="9" xl="9">
                        <Card>
                        <div style={{ textAlign:"-webkit-center" }} >
                        <img  src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "50%" }} alt="" /> <br />
                        <label className='f-w-600 f-14 badge badge-light-primary'>0 resultados de su busqueda</label>
                        </div>

                        </Card>
                     
                    </Col>

                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        
                    </Col>







                </Row>
            </Col>








    </Fragment>

  )
}
