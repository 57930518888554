import React from 'react'
import { useState, useEffect } from "react";
import 'moment/locale/es';
import moment from 'moment';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useHistory } from 'react-router';
import { useForm } from '../../../hooks/forms/useForm';
import { ConfigServer } from '../../../data/config';


export const useMicroQuoter = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();
    const [listQuoter, setListQuoter] = useState([]);
    const [countTotal, setCountTotal] = useState(0);
    const [countUsed, setCountUsed] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        date_from: "",
        date_to: ""
    });

    const {
        date_from, date_to
    } = formValues;
   

    useEffect(() => {
        getReportQuoter()
    }, [])
 
    
    const getReportQuoter = async () => {
     

        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (date_from === '') ? moment().format("YYYY-MM-DD") : moment(date_from).format("YYYY-MM-DD");
        let mto = (date_to === '') ? moment().format("YYYY-MM-DD") : moment(date_to).format("YYYY-MM-DD");

        
        const respuesta = await sendRequest(requestOptions, `QuoterDeterminations/ListMic?date_from=${mfrom}&date_to=${mto}`);
        


        if (respuesta.code === 200) {
            setListQuoter(respuesta.data) 
            setCountTotal(respuesta.data.total_Quoter)
            setCountUsed(respuesta.data.tota_Quoter_used)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getReportQuoter);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Reportes", respuesta.data.msg);
        }
    }

    const validInputDate = (current) => { 

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }

    const validToInputDate = (current) => {
        let date = moment(date_from).subtract(1, "day");
        return current.isAfter(date);
    }

    const obtenerDatos = () => {
        getReportQuoter();
    }

    const printPdf = async (id_quoter = 0) => {
        let order = listQuoter.find(w => w.id_quoter === parseInt(id_quoter));
        let id = order.id_quoter
        let data = {
            "id_quoter": id,
            "print_result": true,
            "send_email": false,
            "email_client": ""
        }

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Quoter/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {

                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var iframe = document.createElement('iframe');
                iframe.className = 'pdfIframe'
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        URL.revokeObjectURL(url)
                    }, 1);
                };
                iframe.src = url;
            });
    }

    const downdloadpdf = async (id_quoter = 0,code=null) => {
        let order = listQuoter.find(w => w.id_quoter === parseInt(id_quoter));
        let id = order.id_quoter

        let data = {
            "id_quoter": id,
            "print_result": true,
            "send_email": false,
            "email_client": ""
        }

        let raw = JSON.stringify(data);

        let token = localStorage.getItem("token");
        await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download","Cotizacion-"+code+".pdf")
                createSweet("success", "Descargando", "Se descargo el PDF");
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    const navigateUpdate = () => {
        history.push(`../sanitaria/cotizacion`);
    }

    return {
        date_from, date_to, obtenerDatos, handlePickerValues, validInputDate, validToInputDate, listQuoter, 
        countTotal, countUsed, printPdf,
        downdloadpdf, navigateUpdate
    }
}
