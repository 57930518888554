import React from 'react';
import { Button, Card, CardBody,Input } from 'reactstrap';
import { useSpecimens } from '../../hooks/specimens/useSpecimens';
import { RibbonSpecimen } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalSpecimens } from '../../components/specimens/ModalSpecimens';

export const IndexSpecimen = ({activeTab}) => {

    const {
        specimens, method,
        handleInputChange, validaciones,
        name, abbreviation,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchSpecimen

    } = useSpecimens({activeTab});

    return (
        <>
            <Card>
                <CardBody className="p-1">
                   <br />
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSpecimen} />
                                    </div>
                                </div>
                    <br />
                    <div className="table-responsive">
                        <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={true}
                            cabeceras={["Nombre", "Abreviatura"]}
                            items={specimens}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalSpecimens
                {
                    ...{
                        modal, modalTitle, toggle, method, loading,
                        name, abbreviation, handleInputChange,
                        validaciones, saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
