import React from 'react'
import { ComponentAntibitics } from './ComponentAntibitics';


export const ComponentAntibiograma = ( {analyte4, index }  ) => {

  return (
    <div>
        {
            analyte4.antibiotics.map((o,i) => {
                return(
                    <ComponentAntibitics
                    obj={o}
                    index={i}
                    />                    
                )
            } )
        }
    </div>
  )
}
