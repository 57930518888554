import React from 'react'
import { Modal, Col, Row } from 'reactstrap';

export const ModalRack = (props) => {

    const {
        rackInfo, arrayNims, modalRack, toggleRack, initTrackingFromRack,
        rackExist, initChangeRack, changeRackStatus, rackPrevious, rackNext, changeRack,
        loading, removeRackNim
    } = props;
    return (
        <>
            {
                rackInfo !== null &&
                <Modal isOpen={modalRack} toggle={toggleRack} backdrop='static' keyboard={false} centered={true}>
                    <div className="colorModalIDmuestra" style={{ textAlignLast: "center" }}>
                        <Col sm="12">
                            <Row>
                                <Col sm="2">
                                </Col>
                                <Col sm="8" className='p-2'>
                                    <label className='labelModal p-1'>
                                        {
                                            rackInfo.name + "[" + rackInfo.code + "]"
                                        }
                                    </label>
                                </Col>
                                <Col sm="2" className='p-3'>
                                    <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={toggleRack} />
                                </Col>

                            </Row>
                        </Col>
                    </div>
                    {
                        rackExist ?
                            <>
                                {
                                    changeRackStatus ?
                                        <>
                                            <Col sm="12" style={{ border: "#FF00AA 3px solid" }}>
                                                <Row>
                                                    <Col sm="12" style={{ textAlign: "-webkit-center" }}> <br /> 
                                                        <div>
                                                            <label className='label-Modal-gradilla'>Puedes escanear la nueva gradilla para realizar el cambio</label>
                                                            {/* <p><b>Gradilla actual:</b>{rackPrevious !== null && (rackPrevious.name + "[" + rackPrevious.code + "]")}</p>
                                                            <p><b>Gradilla nueva:</b>{rackNext !== null && (rackNext.name + "[" + rackNext.code + "]")}</p> */}
                                                            <label className=''><b>Gradilla Actual:</b>{rackPrevious !== null && (rackPrevious.name + "[" + rackPrevious.code + "]")}</label>
                                                            <label className=''><b>Gradilla Nueva:</b> </label>

                                                            <br />
                                                        </div>
                                                        <Col sm="12"  style={{ textAlign: "-webkit-center" }} >
                                                        <button className='modalButtonChangeGradilla2' type="button" onClick={changeRack} >Confirmar Cambio</button>

                                                        </Col> <br />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                        : <Col sm="12" style={{ border: "#FF00AA 3px solid" }}>
                                            <Row>
                                                <Col sm="12">
                                                <label className='label-Modal-gradilla' >Puedes iniciar esta gradilla en este proceso</label>
                                                <label className='label-Modal-gradilla' >Estos son los NIMS vinculados</label>
                                                <div>
                                                     {
                                                        arrayNims.length > 0
                                                            ? <ul>
                                                                {

                                                                    arrayNims.map(x => {
                                                                        return (
                                                                            <li key={x.nim}>
                                                                                <i className="fa fa-circle statusIcon pointer" ></i>{`${x.nim}`}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            : "0 muestras"
                                                    }
                                                </div>
                                                <Col sm="12" style={{ textAlign: "-webkit-center" }} className="p-l-0 p-r-0"> <br /> <br />
                                                <Row>
                                                    <Col sm="6">
                                                    <button
                                                    className={loading ? "modalButtonChangeGradilla" : "modalButtonChangeGradilla"}
                                                    type="button"
                                                    onClick={() => initTrackingFromRack(false)}
                                                >
                                                    Iniciar esta gradilla
                                                </button>
                                                    </Col>
                                                    <Col sm="6">
                                                    <button size="sm" type="button" className='modalButtonChangeGradilla' onClick={initChangeRack}>Cambiar de gradilla</button> 
                                                    </Col>
                                                </Row>
                                                </Col> <br /> 
                                                </Col>
                                            </Row>
                                        </Col>                        
                                }
                            </>
                            :
                            <>
                                <Col sm="12" style={{ border: "#FF00AA 3px solid" }}>
                                    <Row>
                                        <Col sm="12" > <br />
                                            <label className='label-Modal-gradilla' >Escanea las muestras de esta gradilla</label>
                                            <div>
                                                {
                                                    arrayNims.length > 0
                                                        ?
                                                        <div> <br />
                                                            <ul>
                                                                {
                                                                    arrayNims.map((x, index) => {
                                                                        return (
                                                                            <li key={x.nim}>
                                                                                <i className="icofont icofont-ui-close statusIcon pointer f-12" onClick={() => removeRackNim(index)} ></i>
                                                                                <label className='label-Modal-gradilla-nim'>{`${x.nim}`}</label>&nbsp;&nbsp;
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>  <br /> <br />

                                                        </div>

                                                        :
                                                        <div> <br /> <br />
                                                            <label className='label-Modal-gradilla'>0 Muestras</label>
                                                            <br /><br /><br />

                                                        </div>
                                                }
                                            </div>
                                            <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                                                <button
                                                    type="button"
                                                    className={loading ? "modalButton-gradilla" : "modalButton-gradilla"}
                                                    onClick={() => initTrackingFromRack(false)}
                                                >
                                                    Iniciar esta gradilla
                                                </button>
                                            </Col> <br />
                                        </Col>
                                    </Row>
                                </Col>

                            </>
                    }
                </Modal>
            }
        </>
    )
}
