import React, { useState } from 'react';
import { Modal, Col, Row, Label, Button, Card, CardHeader, Media } from 'reactstrap';
import 'animate.css';
import "../../../assets/css/pantalla1.css";


export const ModalPatientDetaill = (props) => {

    const {
        typeModalPatient = "Detail", modalPatient, toggleDetailPatient, patien, idGender,
        mail, phone, curp, age }
        = props;


    return (
        <Modal isOpen={modalPatient} toggle={toggleDetailPatient} backdrop='static' keyboard={false} centered={true} className="modal-lg"   >
            {
                typeModalPatient === "Detail" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Detalles del Paciente"}</Label>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-3' >
                                        <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "30%" }} alt="" onClick={toggleDetailPatient} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <div>
                            <Col sm="12" className='text-center'>
                                <div className="user-image">
                                    <div className="avatar">
                                        {
                                            idGender === 2 ?
                                                <Media body style={{ width: "15%" }} src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} />
                                                :
                                                <Media body style={{ width: "15%" }} src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} />
                                        }
                                    </div>
                                    <div className="icon-wrapper" data-intro="Change Profile image here">
                                    </div>
                                </div>
                                <div className="info">
                                    <Row>
                                        <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                            <Row >
                                                <Col md="6">
                                                    <div className="user-designation">
                                                        <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Sexo"}</a></div>
                                                        <div className="desc  detailPatienModal f-14">
                                                            {
                                                                idGender === 2 ? "Masculino" : "Femenino"
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="user-designation">
                                                        <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Edad"}</a></div>
                                                        <div className="desc detailPatienModal f-14">
                                                            {age}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                            <div className="user-designation">
                                                <div className="title"><b target="_blank" >{"Nombre"}</b></div>
                                                <div className="desc mt-2 detailPatienModal">{patien}</div>
                                            </div>
                                        </Col>
                                        <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                            <Row>
                                                <Col md="6">
                                                    <div className="user-designation">
                                                        <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Curp"}</a></div>
                                                        <div className="desc detailPatienModal f-12">
                                                            {curp}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    {/* <div className="user-designation">
                                                            <div className="title"><a className='txt-primary'>{"Correo electronico"}</a></div>
                                                            <div className="desc mt-2 txt-secondary">
                                                                {
                                                                    "wilson@gmail.com"}
                                                            </div>
                                                        </div> */}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <div className="social-media step4" data-intro="This is your Social details">
                                        <ul className="list-inline">
                                            <li className="list-inline-item"><a href="#javascript"></a></li>
                                            <li className="list-inline-item"><a href="#javascript"></a></li>
                                            <li className="list-inline-item"><a ><i class="fa fa-circle iconResults f-12"></i></a></li>
                                            <li className="list-inline-item"><a href="#javascript"></a></li>
                                            <li className="list-inline-item"><a href="#javascript"></a></li>
                                        </ul>
                                    </div>
                                    <div className="follow">
                                        <Row>
                                            <Col col="6" className="text-md-right border-right">
                                                <div className="follow-num counter txt-primary f-14">{"Correo"}</div><span className='detailPatienModal f-12'>{mail}</span>
                                            </Col>
                                            <Col col="6" className="text-md-left ">
                                                <div className="follow-num counter txt-primary f-14">{"Telefono"}</div><span className='detailPatienModal f-12'>{phone}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <br /> <br />
                    </>
                    : ""
            }
        </Modal >
    )
}