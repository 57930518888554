import React from 'react'
import { Form, Col, Row } from 'reactstrap';
import '../../../assets/css/pantalla1.css'


export const FormEnd = (props) => {

    const {
        nameModal, getEncuesta, loading, surnames, modalPrueba, modalPrueba2, loadingPatient

    } = props;


    return (
        <Form onSubmit={getEncuesta} >
            <Col sm="12" style={{ border: "#09D4FC 3px solid" }}>

                {
                    loadingPatient === false ?
                        <div>
                            <Row className='mt-2'>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br />
                                    <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                <Col sm="3" md="3" lg="3" className='text-left p-r-0'>
                                    <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                                </Col>
                                <Col sm="1" md="1" lg="1" className='text-center'>
                                    <br />
                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                </Col>
                                <Col sm="1" md="1" lg="1" className='text-center'>
                                    <br />
                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                                </Col>
                                <Col sm="1" md="1" lg="1" className='text-center'>
                                    <br />
                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                </Col>
                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                            </Row>
                        </div>
                        :
                        <div>
                            <Col sm="12" style={{ textAlign: "center" }}>
                                <Row>
                                    <Col sm="4">
                                    </Col>
                                    <Col sm="7">
                                        <div className='circulo-end-encuesta'>
                                            <img className=" " src={require("../../../assetsToma/imagenes/Like2.gif")} style={{ width: "95%" }} alt="" />
                                        </div> <br />
                                    </Col>
                                </Row>


                                <label className='label-modal-name negritaLabel' >Gracias por tu preferencia,&nbsp;{modalPrueba} <br />{modalPrueba2}</label> <br /> <br />
                                <button type="submit" outline color="info" size="xs" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 confirm-Button-end-encuesta text-align: -webkit-right;" : "text-align: -webkit-right;mt-1 confirm-Button-end-encuesta")} >Finalizar</button>
                                <br /><br />
                            </Col>



                        </div>
                }



            </Col>
        </Form>
    )
}


